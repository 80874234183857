import { useIntl } from 'react-intl';
import styled from 'styled-components';
import OriginalLogoComponent from '../../../../../assets/icons/logo/Logo_U-NEXT_Original.svg';
import { COLORS } from '../../../../styles/theme';
import { DailyFreeBadge } from '../../../Common/Badge/DaillyFreeBadge';
import { YomihodaiBadge } from '../../../Common/Badge/PaymentBadge';
import { RoundBadge } from '../../../Common/Badge/RoundBadge';
import RatingStar from '../../../Common/RatingStar';
import PointPriceBadge from '../../../PointPriceBadge';
import { BookMetaInfoProps, getRefinedMetaInfoProps } from './utils';

const MetaInfoContainer = styled.div`
  z-index: 1;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
`;

const HorizontalFlexbox = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  ${({ gap }) => `gap: ${gap}px;`}
`;

const SpaceBetweenLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RatingStarContainer = styled.div`
  display: inline-block;
  width: 58px;
`;

const BookNumText = styled.span`
  font-size: 12px;
  color: ${COLORS.white_75};
`;

const AuthorText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  line-height: 1;
  color: ${COLORS.white_75};
`;

const OriginalLogo = styled(OriginalLogoComponent)`
  width: 44px;
`;

const BookTypeText = styled.div`
  font-size: 12px;
  color: ${COLORS.white_75};
  font-weight: 600;
`;

const BookMetaInfo: React.FC<BookMetaInfoProps> = (props) => {
  const intl = useIntl();
  const {
    bookType,
    numberOfFree,
    minimumPrice,
    hasMultiPrice,
    rate,
    isOnSale,
    isDailyFree,
    isDailyFreePlus,
    isOriginal = false,
    isYomihodai,
    authorText,
    bookTypeText,
    totalBookNumText,
    isEpisodeSpecified,
  } = getRefinedMetaInfoProps(props, intl);

  return (
    <MetaInfoContainer>
      <SpaceBetweenLayout>
        <HorizontalFlexbox gap={4}>
          {(isDailyFree || isDailyFreePlus) && (
            <DailyFreeBadge
              badgeType={isDailyFreePlus ? 'plus' : 'normal'}
              badgeSize="small"
            />
          )}
          {isYomihodai && <YomihodaiBadge badgeSize="small" />}
          {!isYomihodai &&
            typeof minimumPrice === 'number' &&
            bookType !== 'MAGAZINE' && (
              <PointPriceBadge
                isPoint
                badgeSize="small"
                minimumPrice={minimumPrice}
                hasMultiplePrice={!!hasMultiPrice}
              />
            )}
        </HorizontalFlexbox>
        {isOriginal && <OriginalLogo />}
      </SpaceBetweenLayout>
      <HorizontalFlexbox gap={3}>
        <BookTypeText>{bookTypeText}</BookTypeText>
        <BookNumText>
          {bookType !== 'MAGAZINE' && !isEpisodeSpecified && totalBookNumText}
        </BookNumText>
      </HorizontalFlexbox>
      <AuthorText>{authorText}</AuthorText>
      <SpaceBetweenLayout>
        <HorizontalFlexbox gap={3}>
          {isOnSale && (
            <RoundBadge badgeType="white" badgeSize="small">
              SALE
            </RoundBadge>
          )}
          {numberOfFree && (
            <RoundBadge badgeType="white" badgeSize="small">
              {numberOfFree}
            </RoundBadge>
          )}
        </HorizontalFlexbox>
        <RatingStarContainer>
          <RatingStar rate={rate} iconWidth={10} />
        </RatingStarContainer>
      </SpaceBetweenLayout>
    </MetaInfoContainer>
  );
};

export default BookMetaInfo;
