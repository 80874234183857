import Bowser from 'bowser';
import type { Nullable } from '../../utils/Nullable';

// Ideally, we should use assert and throw an error if the condition is false actually.
// However, the kafka client may be called from React's renderer e.g. inside useEffect(),
// If we throw an error, it will stop React's rendering process.
// To avoid it, we need to use this special function to dump diagnostic message.
// See more details: https://github.com/u-next/cosmo/pull/895
function consoleAssert(condition: boolean, message: string): asserts condition {
  // eslint-disable-next-line no-console
  console.assert(condition, message);
}

export class DeviceInformation {
  private _id: Nullable<string>;

  readonly os: string;
  readonly os_version: string;
  readonly user_agent: string;
  readonly browser_name: string;
  readonly browser_version: string;

  constructor(userAgentString: string) {
    const bowser = Bowser.parse(userAgentString);
    this._id = null;

    this.os = bowser.os.name ?? '';
    this.os_version = bowser.os.version ?? '';
    this.user_agent = userAgentString;
    this.browser_name = bowser.browser.name ?? '';
    this.browser_version = bowser.browser.version ?? '';
  }

  get id(): string {
    const id = this._id;
    consoleAssert(id !== null, 'Have not set id to kafka client yet.');
    return id;
  }

  setId(id: string): void {
    this._id = id;
  }
}

export function createDeviceInformation(
  userAgentString: string
): DeviceInformation {
  const info = new DeviceInformation(userAgentString);
  return info;
}
