import React, { MouseEvent, useRef } from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, useIntl } from 'react-intl';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { DEVICE } from '../../styles';
import { COLORS } from '../../styles/theme';
import { Button, ButtonTheme } from '../Common/Button';
import { Icons } from '../Common/Icon';

const messages = defineMessages({
  cancel: {
    id: 'modal.cancel.default',
    defaultMessage: 'キャンセル',
  },
  ok: {
    id: 'modal.ok.default',
    defaultMessage: '確定',
  },
});

const SiteOverlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 18000;
  background-color: ${COLORS.jet_black_overlay_80};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(animated.div)`
  position: relative;
  margin: 20px;
  max-width: 568px;
  width: 100%;
  height: auto;
  max-height: calc(100% - 40px);
  border-radius: 6px;
  padding: 48px;
  box-sizing: border-box;
  overflow-y: auto;

  border-radius: 10px;
  background: ${COLORS.charcoal};
  box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};

  @media ${DEVICE.mobileWide} {
    padding: 32px 16px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media ${DEVICE.exceptMobileWide} {
    &:not(:first-child) {
      margin: 0 16px 0 0;
    }
  }
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 16px;
  @media ${DEVICE.mobileWide} {
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: ${COLORS.white_75};
  margin: 0;
  white-space: pre-wrap;
  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  @media ${DEVICE.exceptMobileWide} {
    margin-top: 56px;
    flex-direction: row-reverse;
  }
`;

export interface ModalProps {
  title?: string;
  onCancel?: (e: MouseEvent<HTMLElement>) => void;
  onOk?: (e: MouseEvent<HTMLButtonElement>) => void;
  cancelText?: string;
  okText?: string;
  okIcon?: Icons;
  cancelButtonType?: ButtonTheme;
  okButtonType?: ButtonTheme;
  visible?: boolean;
  disableScrollLock?: boolean;
  onBackgroundClick?: (e: MouseEvent<HTMLDivElement>) => void;
  immediateTransition?: boolean;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  title,
  onCancel,
  onOk,
  cancelText,
  okText,
  okIcon,
  cancelButtonType,
  okButtonType,
  children,
  visible,
  disableScrollLock,
  onBackgroundClick,
  immediateTransition = false,
}) => {
  const intl = useIntl();
  const modalRef = useRef<HTMLDivElement>(null);
  useLockBodyScroll(!!visible, disableScrollLock, modalRef.current);
  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 500, velocity: 0.04, friction: 60 },
    immediate: immediateTransition,
  });
  const containerTransitions = useTransition(visible, {
    from: { opacity: 0, transform: 'scale(0.5)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.5)' },
    config: { tension: 500, velocity: 0.04, friction: 60 },
    immediate: immediateTransition,
  });

  const cancelType = cancelButtonType ?? 'dark';
  const okType = okButtonType ?? 'dark';

  return (
    <>
      {transitions(
        (props, item) =>
          item &&
          ReactDOM.createPortal(
            <SiteOverlay
              style={props}
              onClick={
                typeof onBackgroundClick === 'function'
                  ? onBackgroundClick
                  : undefined
              }
            >
              {containerTransitions(
                (props, item) =>
                  item && (
                    <Container
                      ref={modalRef}
                      style={props}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {title && <Title>{title}</Title>}
                      {children && <Description>{children}</Description>}
                      {(onCancel || onOk) && (
                        <Buttons>
                          {typeof onOk === 'function' && (
                            <StyledButton
                              buttonTheme={okType}
                              buttonSize="large"
                              buttonSizeMobile="medium"
                              onClick={onOk}
                              text={
                                okText
                                  ? okText
                                  : intl.formatMessage(messages.ok)
                              }
                              icon={okIcon}
                            />
                          )}
                          {typeof onCancel === 'function' && (
                            <StyledButton
                              buttonTheme={cancelType}
                              buttonSize="large"
                              buttonSizeMobile="medium"
                              onClick={onCancel}
                              text={
                                cancelText
                                  ? cancelText
                                  : intl.formatMessage(messages.cancel)
                              }
                            />
                          )}
                        </Buttons>
                      )}
                    </Container>
                  )
              )}
            </SiteOverlay>,
            document.body
          )
      )}
    </>
  );
};

export default Modal;
