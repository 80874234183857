import { isThisYear } from 'date-fns';
import Link from 'next/link';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BookMediaTypeCode,
  cosmo_bookTitleDetailQuery,
} from '../../../__generated__/globalTypes';
import { default as AkamaiImage } from '../../../shared/components/Common/AkamaiImage';
import { RoundBadge } from '../../../shared/components/Common/Badge/RoundBadge';
import { Icons } from '../../../shared/components/Common/Icon';
import { default as RatingStar } from '../../../shared/components/Common/RatingStar';
import { globalMessages } from '../../../shared/constants/messages';
import useMedia from '../../../shared/hooks/useMedia';
import { PreorderContext } from '../../../shared/PreorderContext';
import { DEVICE } from '../../../shared/styles';
import { getTextWithSeparator } from '../../../utils/textHelper';
import type { BookTitleDetailTitleSpotLog } from '../../Log/__types__/bookTitleDetail--titleSpot';
import type { BookTitleDetailStageReadLog } from '../../Log/__types__/bookTitleDetail-stage-read';
import type { BookTitleDetailStageSampleLog } from '../../Log/__types__/bookTitleDetail-stage-sample';
import {
  STAGE_READ,
  STAGE_RESERVE,
  STAGE_SAMPLE,
  TITLE_SPOT,
} from '../../Log/ComponentName/bookTitleDetail';
import { getKafkaClient } from '../../Log/kafkaClient';
import { PreorderButton } from '../shared';
import { KeyartLink } from '../shared/Keyart';
import {
  BookMainImage,
  BookStageButtonArea,
  BookStageLayout,
  CautionText,
  EvaluationContainer,
  FlexColumnsCenter,
  OriginalIcon,
  PlayButton,
  PlayButtonsLayout,
  StageMetaArea,
  StagePromotionArea,
  StageResumeArea,
  StageSection,
  StageSubInfoArea,
  StageSubInfoText,
  StageTitle,
  StageTitleArea,
  TertiaryText,
} from '../shared/StageSection';
import Badges from './Badges';
import BookUserButtonsGroup, {
  BookUserButtonsGroupProps,
} from './BookUserButtonsGroup';
import messages from './messages';
import { getBookLimitDayText } from './utils';

type BookStageSectionProps = {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
} & BookUserButtonsGroupProps;

const BookStageSection: React.FC<BookStageSectionProps> = ({
  bookSakuhin,
  ...bookUserButtonGroupProps
}) => {
  const intl = useIntl();
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );

  const { preorderStatus } = useContext(PreorderContext);

  if (!bookSakuhin?.book) {
    return null;
  }

  const isPreorderable = !!preorderStatus.get(bookSakuhin?.book?.code)
    ?.isPreorderable;
  const akamaiImageProps = {
    src: `//${bookSakuhin.book.thumbnail.standard}`,
    availableSizes: [128, 284],
    sizeConfig: {
      SD: {
        width: 284,
      },
      TABLET: {
        width: 210,
      },
      MOBILE_WIDE: {
        width: 143,
      },
    },
    alt: bookSakuhin.book.name,
  };

  const creditNames = bookSakuhin.book.credits.map((c) => c.penName);
  const publishedIn = bookSakuhin.book.publishedIn.map((c) => c.name);
  const isUnextOriginal = bookSakuhin.isUnextOriginal;
  const isMagazine =
    bookSakuhin.book.mediaType.code === BookMediaTypeCode.MAGAZINE;

  const bookTypeText = getTextWithSeparator(
    [
      intl.formatMessage(globalMessages[bookSakuhin.book.mediaType.code]),
      isMagazine && bookSakuhin.book.publicStartDateTime
        ? `${intl.formatDate(bookSakuhin.book.publicStartDateTime, {
            year: !isThisYear(new Date(bookSakuhin.book.publicStartDateTime))
              ? 'numeric'
              : undefined,
            month: 'long',
            day: 'numeric',
          })}${intl.formatMessage(messages.public)}`
        : undefined,
      (isMagazine && bookSakuhin.book.publisher?.name) ?? undefined,
    ],
    '｜'
  );

  const bookCreditText = getTextWithSeparator(
    [
      creditNames.length > 1 ? `${creditNames[0]}...` : creditNames[0],
      ...(!isMagazine && bookSakuhin.book.publisher?.name
        ? [bookSakuhin.book.publisher.name]
        : []),
      ...publishedIn,
    ],
    '｜'
  );

  const preorderableBookPublicDateText =
    isPreorderable &&
    bookSakuhin.book.publicStartDateTime &&
    `${intl.formatDate(bookSakuhin.book.publicStartDateTime, {
      month: 'long',
      day: 'numeric',
    })}${intl.formatMessage(globalMessages.plannedPublicStart)}`;
  const isSVOD = bookSakuhin.paymentBadgeList.some(
    (badge) => badge.code === 'SVOD'
  );
  const showSampleBookReadButton =
    !!bookSakuhin.book.bookContent?.sampleBookFile;

  const hasRight =
    isSVOD || bookSakuhin.book.isPurchased || bookSakuhin.book.isFree;
  const playButtonText = intl.formatMessage(
    bookSakuhin.book.isFree
      ? messages.bookFreeReadButtonText
      : messages.bookReadButtonText
  );

  const isCompleted = bookSakuhin.book.bookContent?.mainBookFile?.completeFlg;
  const resumePoint =
    bookSakuhin.book.bookContent?.mainBookFile?.resumePoint ?? 0;
  const progressPercent =
    isCompleted && resumePoint === 0 ? 100 : Math.floor(resumePoint * 100);
  const remainingText = progressPercent ? `${progressPercent}%` : undefined;
  const licenseExpiredDatetime = bookSakuhin.book.isPurchased
    ? bookSakuhin.book.rightsExpirationDatetime
    : bookSakuhin.book.bookContent?.mainBookFile?.licenseExpiredDateTime;
  const limitDayText = bookSakuhin.book.isPurchased
    ? getBookLimitDayText(licenseExpiredDatetime, intl)
    : undefined;

  return (
    <BookStageLayout>
      <StageSection>
        <StageMetaArea>
          <StageTitleArea>
            <StagePromotionArea>
              {isUnextOriginal && <OriginalIcon />}
              {isPreorderable && (
                <RoundBadge badgeType="promotion" mobileBadgeSize="small">
                  <FormattedMessage {...messages.preorderable} />
                </RoundBadge>
              )}
              {!isPreorderable && bookSakuhin.book.isNew && (
                <RoundBadge badgeType="promotion" mobileBadgeSize="small">
                  <FormattedMessage {...globalMessages.new} />
                </RoundBadge>
              )}
            </StagePromotionArea>
            <StageTitle>{bookSakuhin.book.name}</StageTitle>
          </StageTitleArea>
          <StageSubInfoArea>
            <FlexColumnsCenter>
              <Badges bookSakuhin={bookSakuhin} />
            </FlexColumnsCenter>
            <FlexColumnsCenter>
              <EvaluationContainer>
                <RatingStar rate={bookSakuhin.rate} iconWidth={12} />
              </EvaluationContainer>
              {bookTypeText && (
                <StageSubInfoText lineClamp={1}>
                  {bookTypeText}
                </StageSubInfoText>
              )}
            </FlexColumnsCenter>
            <StageSubInfoText>{bookCreditText}</StageSubInfoText>
            {preorderableBookPublicDateText && (
              <TertiaryText>{preorderableBookPublicDateText}</TertiaryText>
            )}
          </StageSubInfoArea>
          <StageResumeArea
            progressPercent={progressPercent}
            remainingText={remainingText}
            limitDayText={limitDayText}
          />
        </StageMetaArea>
        <BookStageButtonArea>
          <PlayButtonsLayout>
            {isPreorderable ? (
              <PreorderButton
                bookSakuhinCode={bookSakuhin.sakuhinCode}
                bookCode={bookSakuhin.book.code}
                componentName={STAGE_RESERVE}
              />
            ) : (
              <Link
                href={`/book/view/${bookSakuhin.sakuhinCode}/${bookSakuhin.book.code}`}
                role="button"
                data-ucn="bookStageSection-read-link"
                onClick={async () => {
                  if (bookSakuhin.book) {
                    await getKafkaClient().trackUserClickDimension0<BookTitleDetailStageReadLog>(
                      STAGE_READ,
                      {
                        book_sakuhin_code: bookSakuhin.sakuhinCode,
                        book_code: bookSakuhin.book.code,
                      }
                    );
                  }
                }}
                legacyBehavior={false}
              >
                <PlayButton
                  buttonTheme={hasRight ? 'light' : 'attention'}
                  icon={Icons.READ}
                  buttonSize="large"
                  text={playButtonText}
                />
              </Link>
            )}
            {showSampleBookReadButton && (
              <Link
                href={`/book/view/${bookSakuhin.sakuhinCode}/${bookSakuhin.book.code}?isSample=true`}
                role="button"
                onClick={async () => {
                  await getKafkaClient().trackUserClickDimension0<BookTitleDetailStageSampleLog>(
                    STAGE_SAMPLE,
                    {
                      book_sakuhin_code: bookSakuhin.sakuhinCode,
                      book_code: bookSakuhin.book?.code ?? '',
                    }
                  );
                }}
                legacyBehavior={false}
              >
                <PlayButton
                  buttonTheme="ghost"
                  buttonSize="large"
                  text={intl.formatMessage(messages.readSample)}
                  data-ucn={STAGE_SAMPLE}
                />
              </Link>
            )}
            {isMobile && bookSakuhin.book.saleText && (
              <CautionText>{bookSakuhin.book.saleText}</CautionText>
            )}
          </PlayButtonsLayout>
          <BookUserButtonsGroup
            bookSakuhin={bookSakuhin}
            {...bookUserButtonGroupProps}
          />
        </BookStageButtonArea>
        {!isMobile && bookSakuhin.book.saleText && (
          <CautionText>{bookSakuhin.book.saleText}</CautionText>
        )}
      </StageSection>
      <BookMainImage>
        {!isPreorderable ? (
          <KeyartLink
            href={`/book/view/${bookSakuhin.sakuhinCode}/${bookSakuhin.book.code}`}
            role="button"
            onClick={async () => {
              if (bookSakuhin.book) {
                await getKafkaClient().trackUserClickDimension0<BookTitleDetailTitleSpotLog>(
                  TITLE_SPOT,
                  {
                    book_sakuhin_code: bookSakuhin.sakuhinCode,
                    book_code: bookSakuhin.book.code,
                  }
                );
              }
            }}
            legacyBehavior={false}
            data-ucn="bookStageSection-titleSpot-link"
            iconType="read"
          >
            <AkamaiImage {...akamaiImageProps} />
          </KeyartLink>
        ) : (
          <AkamaiImage {...akamaiImageProps} />
        )}
      </BookMainImage>
    </BookStageLayout>
  );
};

export default BookStageSection;
