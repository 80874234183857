import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import {
  autoSuggestQuery,
  autoSuggestQueryVariables,
} from '../../../__generated__/globalTypes';
import BackgroundPattern from '../../../shared/components/BackgroundPattern';
import { Button } from '../../../shared/components/Common/Button';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { NavigationButton } from '../../../shared/components/Common/NavigationButton';
import { globalMessages } from '../../../shared/constants/messages';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { useSearchInputContext } from '../../../shared/SearchInputContext';
import { DEVICE } from '../../../shared/styles/constants';
import type { SearchAutosuggestDropdownItemLog } from '../../Log/__types__/searchAutosuggest-dropdown-item';
import { getKafkaClient } from '../../Log/kafkaClient';
import { GET_AUTO_SUGGESTION } from '../gql';
import SearchGenres from '../SearchGenres';

const disableAllInputs = (): void => {
  const selects = document.getElementsByTagName('select');
  for (let i = 0; i < selects.length; i++) {
    selects[i].tabIndex = -1;
  }
  const inputs = document.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].tabIndex = -1;
  }
};

const enableAllInputs = (): void => {
  const selects = document.getElementsByTagName('select');
  for (let i = 0; i < selects.length; i++) {
    selects[i].removeAttribute('tabindex');
  }
  const inputs = document.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].removeAttribute('tabindex');
  }
};

const LeftContainer = styled.div`
  display: flex;
  > * {
    margin-right: 16px;
  }
`;

const SearchModal = styled(animated.div)`
  background-color: ${({ theme }) => theme.background.default};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 0;

  @media ${DEVICE.exceptSd} {
    left: 256px;
  }
`;

const SearchIconStyled = styled(Icon)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

const SearchBox = styled.div<{ isSearchOpen: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-left: auto;
  min-height: 44px;
  padding: 0 10px;
  border-radius: 100px;
  backdrop-filter: blur(12px);

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.searchInput.background};
  transition: all 150ms ease-in;
  z-index: 1;
  width: 100%;
  ${({ isSearchOpen }) =>
    isSearchOpen
      ? css`
          max-width: 544px;
          border: 1.5px solid ${({ theme }) => theme.searchInput.borderActive};
        `
      : css`
          border: 1.5px solid ${({ theme }) => theme.searchInput.border};
          max-width: 324px;
          cursor: pointer;
          @media ${DEVICE.sd} {
            max-width: 56px;
            background: transparent;
            border: none;
            > input {
              display: none;
            }
          }
        `}
`;

const SearchInput = styled.input`
  flex: 1;
  width: 100%;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;

  color: ${({ theme }) => theme.text.standard};
  font-size: 16px;
  font-weight: 300;

  &::placeholder {
    color: ${({ theme }) => theme.searchInput.placeholderText};
  }
`;

const CancelButton = styled(animated(Button))`
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: end;
`;

const AutoSuggestDropDown = styled(animated.div)`
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.searchInput.suggestBackground};
  max-width: 544px;
  width: 100%;
  padding-top: 44px;
  border-radius: 22px 22px 0 0;
`;

const SuggestList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 18px 24px 12px;
`;

const SuggestListItem = styled.li<{ active: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  gap: 4px;
  font-size: 14px;
  font-weight: 300;
  cursor: default;
  transition: all 150ms ease-in;

  border-bottom: 0.5px solid ${({ theme }) => theme.searchInput.suggestBorder};
  &:hover {
    background: ${({ theme }) => theme.searchInput.suggestBackgroundHover};
  }
  ${({ active, theme }) =>
    active && `background: ${theme.searchInput.suggestBackgroundHover};`};

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > * {
    flex-grow: 1;
  }
`;

const SuggestListItemIcon = styled(Icon)`
  flex-grow: 0;
  flex-shrink: 0;
  height: 28px;
  width: 28px;
`;

const Search: React.FC<{
  overlayRef: React.RefObject<HTMLDivElement>;
}> = ({ overlayRef }) => {
  const intl = useIntl();
  const router = useRouter();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isComposingRef = useRef<boolean>(false);
  const [isSuggestionEnabled, setIsSuggestionEnabled] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<number>();

  const {
    searchValue,
    searchOpen,
    searchError,
    setIsOpen,
    setSearchValue,
    clear,
    setSearchBoxElement,
    showSearchGenres,
  } = useSearchInputContext();

  useClientQuery<autoSuggestQuery, autoSuggestQueryVariables>(
    GET_AUTO_SUGGESTION,
    {
      skip: !searchValue,
      variables: {
        query: searchValue,
        pageSize: 10,
      },
      onCompleted: (data) => {
        setSuggestions(data.autoSuggest);
      },
      onError: () => setSuggestions([]),
    }
  );

  const onClickClear = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const autoSuggestResult = (searchValue && suggestions) || [];
  const searchOpenHandler = () => {
    searchInputRef.current?.focus();
    setIsSuggestionEnabled(true);
    setIsOpen(true);
  };

  const searchCloseHandler = () => {
    setIsOpen(false);
    clear();
  };

  const modalTransitions = useTransition(showSearchGenres, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 150,
    },
  });

  const cancelButtonTransition = useTransition(searchOpen, {
    from: { opacity: 0, maxWidth: '0px', marginLeft: '0px' },
    enter: { opacity: 1, maxWidth: '500px', marginLeft: '24px' },
    leave: { opacity: 0, maxWidth: '0px', marginLeft: '0px' },
    reverse: true,
    config: {
      duration: 150,
    },
    expires: 1,
  });

  const autoSuggestTransition = useTransition(
    isSuggestionEnabled && autoSuggestResult.length > 0,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      reverse: true,
      config: {
        duration: 150,
      },
      expires: 1,
    }
  );

  const sendSuggestKafkaLog = (
    index: number,
    searchKeyword: string,
    autosuggestList: string[]
  ) => {
    getKafkaClient().trackUserClickDimension1<SearchAutosuggestDropdownItemLog>(
      'searchAutosuggest-dropdown-item',
      {
        index,
        search_kw: searchKeyword,
        autosuggest_item: autosuggestList[index],
        autosuggest_list: autosuggestList.map((item) => ({
          autosuggest_item: item,
        })),
      }
    );
  };

  useEffect(() => {
    if (!searchValue && searchOpen) {
      // When input values are deleted by hand, reset suggestions
      setSuggestions([]);
    }
  }, [searchOpen, searchValue]);

  useEffect(() => {
    // Every time the search value is changed, reset the active suggestion item
    setActiveItemIndex(undefined);
  }, [searchValue]);

  if (searchError) {
    throw searchError;
  }

  const inputDisplayValue =
    activeItemIndex === undefined
      ? searchValue
      : autoSuggestResult[activeItemIndex];

  return (
    <>
      {modalTransitions(
        (props, item) =>
          overlayRef.current &&
          item &&
          ReactDOM.createPortal(
            <SearchModal style={props}>
              <BackgroundPattern />
              <SearchGenres />
            </SearchModal>,
            overlayRef.current
          )
      )}
      <LeftContainer ref={setSearchBoxElement} />
      <SearchContainer>
        <SearchBox
          isSearchOpen={searchOpen}
          onClick={searchOpenHandler}
          data-ucn="search-searchBox-btn"
        >
          <SearchIconStyled name={Icons.SEARCH} />
          <SearchInput
            ref={searchInputRef}
            type="text"
            value={inputDisplayValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onFocus={() => {
              disableAllInputs();
              setIsSuggestionEnabled(searchValue !== '');
              searchOpenHandler();
            }}
            onBlur={() => {
              enableAllInputs();
              setIsSuggestionEnabled(false);
            }}
            onCompositionStart={() => {
              isComposingRef.current = true;
            }}
            onCompositionEnd={() => {
              isComposingRef.current = false;
            }}
            onKeyDown={(e) => {
              if (isComposingRef.current) {
                return false;
              }

              switch (e.key) {
                case 'ArrowDown': {
                  e.preventDefault();
                  setActiveItemIndex((cur) =>
                    cur === undefined
                      ? 0
                      : cur + 1 === suggestions.length
                      ? undefined
                      : cur + 1
                  );
                  break;
                }
                case 'ArrowUp': {
                  e.preventDefault();
                  setActiveItemIndex((cur) =>
                    cur === undefined
                      ? suggestions.length - 1
                      : cur === 0
                      ? undefined
                      : cur - 1
                  );
                  break;
                }
                case 'Enter': {
                  setIsSuggestionEnabled(false);
                  e.currentTarget.blur();
                  activeItemIndex !== undefined &&
                    sendSuggestKafkaLog(
                      activeItemIndex,
                      searchValue,
                      autoSuggestResult
                    );

                  router.push({
                    pathname: '/freeword',
                    query: { query: inputDisplayValue },
                  });
                  break;
                }
              }
            }}
            placeholder={intl.formatMessage(globalMessages.searchPlaceholder)}
            data-ucn="search-searchBox-input"
          />
          {searchValue !== '' && (
            <NavigationButton
              buttonSize="small"
              icon={Icons.CLOSE}
              onClick={onClickClear}
            />
          )}
        </SearchBox>
        {autoSuggestTransition(
          (styles, item) =>
            item && (
              <AutoSuggestDropDown style={styles}>
                <SuggestList>
                  {autoSuggestResult.map((suggestItem, index) => (
                    <SuggestListItem
                      key={suggestItem}
                      active={index === activeItemIndex}
                      onClick={() => {
                        sendSuggestKafkaLog(
                          index,
                          searchValue,
                          autoSuggestResult
                        );
                        router.push({
                          pathname: '/freeword',
                          query: { query: suggestItem },
                        });
                      }}
                    >
                      <SuggestListItemIcon name={Icons.SEARCH} />
                      <span>{suggestItem}</span>
                    </SuggestListItem>
                  ))}
                </SuggestList>
              </AutoSuggestDropDown>
            )
        )}
      </SearchContainer>
      {cancelButtonTransition(
        (styles, item) =>
          item && (
            <CancelButton
              style={styles}
              onClick={searchCloseHandler}
              buttonTheme="text"
              buttonSize="medium"
              text={intl.formatMessage(globalMessages.cancel)}
            />
          )
      )}
    </>
  );
};

export default Search;
