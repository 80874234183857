class AssertionError extends Error {}

// Ideally, we sould use `assert` module but it type definitions still dees not use
// asserts function. So we need define them.
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
export function assert(condition: boolean, message = ''): asserts condition {
  if (!condition) {
    throw new AssertionError(`assertion error: ${message}`);
  }
}
