import { gql } from '@apollo/client';
import { liveCardFragment } from '../../../shared/fragments/lives';
import { pageInfoFragment } from '../../../shared/fragments/pageInfo';
import { titleCardFragment } from '../../../shared/fragments/videos';

export const GET_LIVE_DETAIL = gql`
  query cosmo_getLiveDetail($liveCode: ID!) {
    webfront_getLive(id: $liveCode) {
      id
      name
      description
      attractions
      copyright
      note
      viewableDeviceText
      displayLiveMinute
      venue
      location
      deliveryStartDateTime
      deliveryEndDateTime
      displaySaleEndDateTime
      performanceStartDateTime
      isOnlyOn
      saleTypeCode
      allowsTimeshiftedPlayback
      timeshiftedPlaybackAllowedUntil
      paymentBadgeList {
        name
        code
      }
      subContentList {
        typeCode
        publicStartDateTime
        publicEndDateTime
      }
      notices {
        typeCode
        publicStartDateTime
        thumbnail {
          standard
          secondary
        }
      }
      tickets {
        id
        name
        saleStartDateTime
        saleEndDateTime
        price
      }
      purchased
      productLineupCodeList
      hasPackRights
      testLiveId
      credits {
        personNameCode
        personName
        characterName
        castTypeName
        personCode
      }
      hasPackRights
      caution
      announcements {
        title
        body
        url
      }
    }
  }
`;

export const GET_LIVE_DETAIL_RELATED_STUFFS = gql`
  query cosmo_getLiveDetailRelatedStuffs(
    $liveCode: ID!
    $page: Int!
    $pageSize: Int!
  ) {
    liveRelatedLives(id: $liveCode, page: $page, pageSize: $pageSize) {
      liveList {
        ...LiveCard
      }
      pageInfo {
        ...PageInfo
      }
    }

    liveRelatedVideos(id: $liveCode, page: $page, pageSize: $pageSize) {
      titles {
        ...TitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
  ${liveCardFragment}
`;
