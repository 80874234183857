import React from 'react';
import { useIntl } from 'react-intl';
import { cosmo_bookTitleDetailQuery } from '../../../__generated__/globalTypes';
import { PreorderContextType } from '../../../shared/PreorderContext';
import * as bookTitleDetail from '../../Log/ComponentName/bookTitleDetail';
import CreditSection, { Credit } from '../shared/CreditSection';
import SignupPromotion from '../shared/SignupPromotion';
import BookEpisodeListSection from './BookEpisodeListSection';
import BookInfoSection from './BookInfoSection';
import BookOriginalBooksSection from './BookOriginalBooksSection';
import BookRelatedSection from './BookRelatedSection';
import BookStageSection from './BookStageSection';
import { BookUserButtonsGroupProps } from './BookUserButtonsGroup';
import { KeyArtBackdrop } from './Components';
import messages from './messages';

type BookDetailContentProps = {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
  credits: Credit[];
} & BookUserButtonsGroupProps &
  PreorderContextType;

const BookDetailContent: React.FC<BookDetailContentProps> = ({
  bookSakuhin,
  credits,
  onUpdateBookMark,
  onUpdateBookEvaluation,
  preorderStatus,
  updatePreorderStatus,
}) => {
  const intl = useIntl();
  return (
    <>
      <KeyArtBackdrop
        backgroundImageUrl={`//${bookSakuhin.book?.thumbnail.standard}`}
      />
      <BookStageSection
        bookSakuhin={bookSakuhin}
        onUpdateBookMark={onUpdateBookMark}
        onUpdateBookEvaluation={onUpdateBookEvaluation}
      />
      <SignupPromotion
        btnComponentName={bookTitleDetail.STAGE_SIGNUP}
        type="BOOK"
      />
      <BookInfoSection bookSakuhin={bookSakuhin} />
      {bookSakuhin.isUnextOriginal && (
        <BookOriginalBooksSection bookSakuhin={bookSakuhin} />
      )}
      <BookEpisodeListSection
        bookSakuhin={bookSakuhin}
        preorderStatus={preorderStatus}
        updatePreorderStatus={updatePreorderStatus}
        variant="normal"
      />
      {credits.length > 0 && (
        <CreditSection
          credits={credits}
          sectionHeading={intl.formatMessage(messages.infoHeading)}
          castNameComponentName={bookTitleDetail.CAST_LIST_CASE_NAME}
        />
      )}
      <BookRelatedSection bookSakuhin={bookSakuhin} />
    </>
  );
};

export default BookDetailContent;
