import { defineMessages } from 'react-intl';

const messages = defineMessages({
  subDubText: {
    id: 'title.video.subDub.text',
    defaultMessage: '字・吹',
    description: 'Sub-dub text',
  },
  subText: {
    id: 'title.video.sub.text',
    defaultMessage: '字',
    description: 'Sub text',
  },
  dubText: {
    id: 'title.video.dub.text',
    defaultMessage: '吹',
    description: 'dub text',
  },
  attractionHeaderText: {
    id: 'title.video.attractionHeader.text',
    defaultMessage: '作品情報',
    description: 'attractionHeader text',
  },
  announcementHeaderText: {
    id: 'title.video.announcementHeader.text',
    defaultMessage: 'お知らせ',
    description: 'announcementHeader text',
  },
  storyHeaderText: {
    id: 'title.video.storyHeader.text',
    defaultMessage: 'ストーリー',
    description: 'storyHeader text',
  },
  triviaHeaderText: {
    id: 'title.video.triviaHeader.text',
    defaultMessage: 'ここがポイント！',
    description: 'triviaHeader text',
  },
  featureHeaderText: {
    id: 'title.video.featureHeader.text',
    defaultMessage: '特集 :',
    description: 'featureHeader text',
  },
  exclusiveSVODBadgeText: {
    id: 'title.video.exclusiveSVODBadge.text',
    defaultMessage: '独占見放題',
    description: 'exclusiveSVODBadge text',
  },
  exclusiveBadgeText: {
    id: 'title.video.exclusiveBadge.text',
    defaultMessage: '独占',
    description: 'exclusiveBadge text',
  },
  playTrailerButtonText: {
    id: 'title.video.playTrailerButton.text',
    defaultMessage: '予告編を再生',
    description: 'playTrailerButton text',
  },
  playButtonText: {
    id: 'title.video.playButton.text',
    defaultMessage: '再生',
    description: 'playButton text',
  },
  shareButtonText: {
    id: 'title.video.shareButton.text',
    defaultMessage: 'シェア',
    description: 'shareButton text',
  },
  myListButtonText: {
    id: 'title.video.myListButton.text',
    defaultMessage: 'マイリスト',
    description: 'myListButton text',
  },
  creditSectionHeading: {
    id: 'title.video.creditSection.heading.text',
    defaultMessage: 'キャスト・スタッフ',
    description: 'creditSectionHeading text',
  },
  episodeSectionHeading: {
    id: 'title.video.episodeSection.heading.text',
    defaultMessage: 'エピソード',
    description: 'episodeSectionHeading text',
  },
  episodeSectionOngoingItemsCountText: {
    id: 'title.video.episodeSection.ongoing.items.count.text',
    defaultMessage: '{currentCount}(全{totalCount})話',
    description: 'episodeSection ongoing items-count text',
  },
  episodeSectionFinishedItemsCountText: {
    id: 'title.video.episodeSection.finished.items.count.text',
    defaultMessage: '全{count}話',
    description: 'episodeSection finished items-count text',
  },
  relatedLiveSectionHeading: {
    id: 'title.video.relatedLiveSection.heading.text',
    defaultMessage: '関連ライブ配信',
    description: 'relatedLiveSectionHeading text',
  },
  relatedVideoSectionHeading: {
    id: 'title.video.relatedVideoSection.heading.text',
    defaultMessage: 'シリーズ',
    description: 'relatedVideoSectionHeading text',
  },
  recommendedVideoSectionHeading: {
    id: 'title.video.recommendedTitlesSection.heading.text',
    defaultMessage: 'こちらもオススメ',
    description: 'recommendedTitlesSectionHeading text',
  },
  liveRelatedVideoSectionHeading: {
    id: 'title.video.liveRelatedVideoSection.heading.text',
    defaultMessage: '関連ビデオ',
    description: 'liveRelatedVideoSectionHeading text',
  },
  relatedBookSectionHeading: {
    id: 'title.video.relatedBookSection.heading.text',
    defaultMessage: '原作・関連ブック',
    description: 'relatedBookSectionHeading text',
  },
  liveWatchButtonText: {
    id: 'title.video.liveWatchButton.text',
    defaultMessage: 'ライブ配信を視聴',
    description: 'liveWatchButton text',
  },
  liveWatchCatchupButton: {
    id: 'title.video.liveWatchCatchupButton.text',
    defaultMessage: '見逃し配信を視聴',
    description: 'liveWatchCatchupButton text',
  },
  liveBuyTicketButtonText: {
    id: 'title.video.liveBuyTicketButtonText.text',
    defaultMessage: 'チケットを購入',
    description: 'liveBuyTicketButtonText text',
  },
  livePurchasePackButtonText: {
    id: 'title.video.livePurchasePackButtonText.text',
    defaultMessage: 'パックを購入',
    description: 'livePurchasePackButtonText text',
  },
  playTestButtonText: {
    id: 'title.video.playTestButton.text',
    defaultMessage: 'テスト動画再生',
    description: 'playTestButton text',
  },
  deliveryStartText: {
    id: 'title.video.deliveryStart.text',
    defaultMessage: '{startDate} 配信開始',
    description: 'deliveryStart text',
  },
  performanceStartText: {
    id: 'title.video.performanceStart.text',
    defaultMessage: '{startDate} 開演予定',
    description: 'performanceStart text',
  },
  performanceDurationText: {
    id: 'title.video.performanceDuration.text',
    defaultMessage: '約{minutes}分',
    description: 'performanceDurationText text',
  },
  catchupInformationHeader: {
    id: 'title.video.catchupInformation.header',
    defaultMessage: '見逃し配信期間',
    description: 'catchupInformation header',
  },
  catchupInformationText: {
    id: 'title.video.catchupInformation.text',
    defaultMessage: '{startDate}〜{endDate}',
    description: 'catchupInformation text',
  },
  catchupWhenAvailableText: {
    id: 'title.video.catchupWhenAvailable.text',
    defaultMessage: '見逃し配信準備完了次第',
    description: 'catchupWhenAvailable text',
  },
  livePurchaseAttentionTitle: {
    id: 'title.video.livepurchaseAttention.title',
    defaultMessage: 'ご確認ください',
    description: 'livePurchaseAttention title',
  },
  livePurchaseAttentionText: {
    id: 'title.video.livepurchaseAttention.text',
    defaultMessage:
      'ご利用の端末やネットワーク環境によっては、ライブ配信及び見逃し配信の視聴ができない場合があります。テスト動画が問題なく再生できるかを事前にご確認ください。',
    description: 'livePurchaseAttention text',
  },
  hasCatchup: {
    id: 'title.video.hasCatchup',
    defaultMessage: '見逃しあり',
  },
  liveTicketSaleEndTime: {
    id: 'title.video.liveTicketSaleEndTime.text',
    defaultMessage: 'チケット販売期間 {date}{time}まで',
  },
  liveStartTimeHeader: {
    id: 'title.video.liveStartTime.header',
    defaultMessage: 'ライブ開催日時',
  },
  liveVenueHeader: {
    id: 'title.video.liveVenue.header',
    defaultMessage: 'ライブ開催地',
  },
  liveCompatibleDevices: {
    id: 'title.video.liveCompatibleDevices',
    defaultMessage: '視聴可能デバイス',
  },
  liveTimeShiftedPlayBackAllowedUntil: {
    id: 'title.video.timeShiftedPlayBackAllowedUntil',
    defaultMessage: '{allowedUntil}まで再生可能',
  },
  noCatchup: {
    id: 'title.video.noCatchup',
    defaultMessage: 'なし',
  },
  nextUpdate: {
    id: 'title.video.nextUpdate.text',
    defaultMessage: '次回{date}更新',
  },
  hasTimeShiftedPlayBack: {
    id: 'title.video.hasTimeShiftedPlayBack',
    defaultMessage: '追いかけ再生',
  },
});

export default messages;
