import styled from 'styled-components';
import { COLORS } from '../../../../styles/theme';
import { LIST_VIEW_MOBILE_ROW_GAP } from '../../constants';

const HorizontalContainer = styled.div`
  width: 100%;
  z-index: 100;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid ${COLORS.white_10};
  padding: ${LIST_VIEW_MOBILE_ROW_GAP}px 0;

  box-sizing: border-box;
  transform: scale(1);
  transition: transform 200ms ease-in;

  display: flex;
  align-items: center;
`;

export default HorizontalContainer;
