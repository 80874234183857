import { useToasts } from '@u-next/react-toast-notifications';
import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  networkoffline: {
    id: 'networkoffline.offline',
    defaultMessage: 'インターネットに接続されていません。',
  },
});

const useOfflineCheck = (): void => {
  const { addToast, removeToast } = useToasts();
  const intl = useIntl();
  // If we are able to render at all, the default state is online
  const [isOnline, setIsOnline] = useState(true);
  const toastId = useRef<string | null>(null);
  /* We only want to show notifications after the initial render, if connectivity is lost */
  const isMounted = useRef(false);

  useEffect(() => {
    const onlineHandler = () => setIsOnline(true);
    const offlineHandler = () => setIsOnline(false);
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  useEffect(() => {
    const content = intl.formatMessage(messages.networkoffline);
    const toastCallback = (id: string) => {
      toastId.current = id;
    };

    if (isMounted.current && !isOnline) {
      addToast(
        content,
        { autoDismiss: isOnline, appearance: 'info' },
        toastCallback
      );
    } else if (isMounted.current && !!toastId.current) {
      removeToast(toastId.current);
    } else {
      isMounted.current = true;
    }
    // We do not want to re-run this effect when the langauge changes. Only when
    // connectivity is lost. So we are ignoring the linter here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, addToast, removeToast]);
};

export default useOfflineCheck;
