import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import Icon, { Icons } from '../Icon';

export const navigationButtonSizes = ['large', 'medium', 'small'] as const;
export type NavigationButtonSize = (typeof navigationButtonSizes)[number];

type ContainerProps = {
  buttonSize: NavigationButtonSize;
  buttonSizeMobile?: NavigationButtonSize;
  icon?: Icons;
  // text?: string;
};
interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ContainerProps {}
interface ContainerDivProps
  extends HTMLAttributes<HTMLDivElement>,
    ContainerProps {}

const buttonStyle = css<ButtonProps | ContainerDivProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ buttonSize }) =>
    buttonSize === 'large' ? 40 : buttonSize === 'medium' ? 32 : 24}px;
  height: ${({ buttonSize }) =>
    buttonSize === 'large' ? 40 : buttonSize === 'medium' ? 32 : 24}px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 0.5px solid ${COLORS.white_05};
  background: ${COLORS.stone_70};
  backdrop-filter: blur(10px);
  color: ${COLORS.white};
  transition: background-color 0.15s ease-in;
  cursor: pointer;

  &:hover {
    background: ${COLORS.stone};
  }

  > div {
    /* Icon style */
    width: ${({ buttonSize }) =>
      buttonSize === 'large' ? 30 : buttonSize === 'medium' ? 24 : 18}px;
  }

  @media ${DEVICE.mobileWide} {
    ${({ buttonSizeMobile }) =>
      buttonSizeMobile === 'large'
        ? css`
            width: 40px;
            height: 40px;
            > div {
              /* Icon style */
              width: 32px;
            }
          `
        : buttonSizeMobile === 'medium'
        ? css`
            width: 32px;
            height: 32px;
            > div {
              /* Icon style */
              width: 24px;
            }
          `
        : buttonSizeMobile === 'small' &&
          css`
            width: 24px;
            height: 24px;
            > div {
              /* Icon style */
              width: 18px;
            }
          `};
  }
`;

const Button = styled.button<ButtonProps>`
  ${buttonStyle};
`;

const Container = styled.div<ContainerDivProps>`
  ${buttonStyle};
`;

const ButtonComponent: React.FC<ButtonProps> = (props) => (
  <Button {...props}>{props.icon && <Icon name={props.icon} />}</Button>
);

const DivComponent: React.FC<ContainerProps> = (props) => (
  <Container {...props}>{props.icon && <Icon name={props.icon} />}</Container>
);

export {
  ButtonComponent as NavigationButton,
  DivComponent as NavigationButtonDiv,
};
