import React from 'react';
import styled, { css } from 'styled-components';
import { Nullable } from '../../../../utils/Nullable';
import { DEVICE } from '../../../styles/constants';
import { COLORS } from '../../../styles/theme';
import { YomihodaiBadge } from '../../Common/Badge/PaymentBadge';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';
import Container from './containers/Container';
import BookThumbnail from './Thumbnail';

const StyledContainer = styled(Container)<{ $isFullWidth?: boolean }>`
  width: ${BLOCK_VIEW_TITLE_CARD_SIZE.originalBook.width}px;

  @media ${DEVICE.mobileWide} {
    width: ${({ $isFullWidth }) =>
      $isFullWidth
        ? '100%'
        : `${BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.originalBook.width}px`};
  }
`;

const ThumbnailLayout = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 24px 0;

  @media ${DEVICE.mobileWide} {
    padding: 16px 16px 0;
  }
`;

const StyledThumbnail = styled(BookThumbnail)<{ $isFullWidth?: boolean }>`
  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 150px;
      height: 210px;
      padding-bottom: 0;
      margin: 0 auto;
    `}
`;

const AuthorContainer = styled.div<{ $isFullWidth?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  box-sizing: border-box;
  height: 33px;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ $isFullWidth }) =>
    $isFullWidth
      ? css`
          padding: 0px 24px;
          margin: 8px 0;
        `
      : css`
          padding: 0px 24px;
          margin: 12px 0 16px;

          @media ${DEVICE.mobileWide} {
            padding: 0px 16px;
            margin: 8px 0 16px;
          }
        `}
`;

const AuthorPicture = styled.img`
  margin-right: 8px;
  width: 30px;
  height: 30px;
  border-radius: 9999px;
  border: 1px solid ${COLORS.white_30};
`;

const AuthorInfoContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BadgeContainer = styled.div`
  font-size: 0;
`;

const IntroductionContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px 24px;
  background: ${COLORS.white_05};
  color: ${COLORS.white_50};
`;

const IntroductionText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-size: 12px;
  line-height: 1.4;
  /* for old browsers don't support lh unit. the "-1px" part is for preventing Safari showing the head of next line */
  height: calc(12px * 1.4 * 3 - 1px);
  height: 3lh;
`;

const AuthorName = styled.div`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

type Props = {
  thumbnailUrl: string;
  titleName: string;
  introduction?: string;
  authors?: Nullable<string>[];
  authorThumbnailUrl?: string;
  isFullWidth?: boolean;
  isNew?: boolean;
  isLazy?: boolean;
};

const OriginalNovelTitleCard: React.FC<Props> = ({
  thumbnailUrl,
  titleName,
  introduction,
  authors = [],
  authorThumbnailUrl,
  isFullWidth = false,
  isNew = false,
  isLazy = false,
}) => {
  const thumbnailProps = {
    thumbnailUrl,
    titleName,
    isNew,
    isLazy,
  };

  return (
    <StyledContainer $isFullWidth={isFullWidth}>
      <ThumbnailLayout>
        <StyledThumbnail $isFullWidth={isFullWidth} {...thumbnailProps} />
      </ThumbnailLayout>
      {Array.isArray(authors) && authors.length && (
        <AuthorContainer $isFullWidth={isFullWidth}>
          {authorThumbnailUrl && authors.length < 2 && (
            <AuthorPicture loading="lazy" src={authorThumbnailUrl} />
          )}
          <AuthorInfoContainer>
            <AuthorName>{authors.filter(Boolean).join(' | ')}</AuthorName>
            <BadgeContainer>
              <YomihodaiBadge badgeSize="small" />
            </BadgeContainer>
          </AuthorInfoContainer>
        </AuthorContainer>
      )}
      {introduction && (
        <IntroductionContainer>
          <IntroductionText>{introduction}</IntroductionText>
        </IntroductionContainer>
      )}
    </StyledContainer>
  );
};

export default OriginalNovelTitleCard;
