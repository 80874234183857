import React, { useCallback, type MouseEventHandler } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { COLORS } from '../../styles/theme';
import { EditContext } from './context';

const Container = styled(animated.div)<{ $selected?: boolean }>`
  box-sizing: border-box;
  position: relative;

  --edit-overlay-background: ${COLORS.midnight_overlay_40};
  ${({ $selected: $selected = false }) =>
    $selected
      ? `--edit-overlay-background: ${COLORS.midnight_overlay_80};`
      : ''}

  @media (hover: hover) {
    &:hover,
    &:focus {
      --edit-overlay-background: ${COLORS.midnight_overlay_60};
    }
  }
`;

const EditButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  cursor: pointer;
  /** remove the iOS touch flash, since there's alreay obvious visual cue after touching */
  -webkit-tap-highlight-color: transparent;
  :disabled {
    cursor: not-allowed;
  }
`;

type Props = {
  enabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  selectable?: boolean;
};

const EditContainer: React.FC<Props> = (props) => {
  const {
    enabled = false,
    selected = false,
    selectable = true,
    onClick,
    children,
    ...restProps
  } = props;

  const isSsr = typeof window === 'undefined';

  const springs = useSpring({
    from: { opacity: isSsr ? 1 : 0 },
    to: { opacity: 1 },
  });

  const handleContainerClick = useCallback<MouseEventHandler>(
    (e) => {
      if (!enabled) return;
      e.preventDefault();
      e.stopPropagation();
    },
    [enabled]
  );

  const handleEditButtonClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <Container
      onClick={handleContainerClick}
      $selected={selected}
      style={{ ...springs }}
    >
      {enabled && (
        <EditButton
          disabled={!selected && !selectable}
          type="button"
          onClick={handleEditButtonClick}
          {...restProps}
        />
      )}
      <EditContext.Provider value={{ selected, enabled, selectable }}>
        {children}
      </EditContext.Provider>
    </Container>
  );
};

export default EditContainer;
