import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

export interface SmallBannerOptions {
  triggerHeight: number;
  title?: string;
  subTitle?: string | React.ReactNode;
  controls?: React.ReactNode;
  // If true, SmallBanner is only displayed on SD devices (width >= 1024px). If false, it is also displayed
  // on other devices like mobile.
  displayOnSdOnly?: boolean;
}

interface SmallBannerContextProps {
  options: SmallBannerOptions;
  updateOptions: Dispatch<SetStateAction<SmallBannerOptions>>;
}

export const SmallBannerContext = React.createContext<SmallBannerContextProps>({
  options: { triggerHeight: 1 },
  updateOptions: () => {
    return null;
  },
});

export const SmallBannerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [currentOptions, setOptions] = useState<SmallBannerOptions>({
    triggerHeight: 1,
  });
  return (
    <SmallBannerContext.Provider
      value={{ options: currentOptions, updateOptions: setOptions }}
    >
      {children}
    </SmallBannerContext.Provider>
  );
};
