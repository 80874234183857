export const FONT_FAMILY = {
  GLOBAL: `"Hiragino Sans", "Hiragino Kaku Gothic ProN", "Noto Sans JP", "Noto Sans CJK JP", "BIZ UDPGothic", Meiryo, -apple-system, BlinkMacSystemFont, sans-serif`,
  MONOSPACE: `"SF Pro Text", "Helvetica Neue", "Robot", "Segoe UI", "Arial", sans-serif`,
};

export const STYLE_BREAKPOINTS = {
  MOBILE_NARROW: 320 as const,
  MOBILE: 480 as const,
  MOBILE_WIDE: 640 as const,
  MOBILE_LANDSCAPE: 745 as const,
  TABLET: 850 as const,
  SD: 1024 as const,
  XGA: 1152 as const,
  HD_1250: 1250 as const,
  HD_1366: 1366 as const,
  HD: 1600 as const,
};

export const DEVICE = {
  mobileNarrow: `(max-width: ${STYLE_BREAKPOINTS.MOBILE_NARROW}px)` as const,
  mobile: `(max-width: ${STYLE_BREAKPOINTS.MOBILE}px)` as const,
  mobileWide: `(max-width: ${STYLE_BREAKPOINTS.MOBILE_WIDE}px)` as const,
  mobileLandscape:
    `(max-width: ${STYLE_BREAKPOINTS.MOBILE_LANDSCAPE}px)` as const,
  tablet: `(max-width: ${STYLE_BREAKPOINTS.TABLET}px)` as const,
  sd: `(max-width: ${STYLE_BREAKPOINTS.SD}px)` as const,
  xga: `(max-width: ${STYLE_BREAKPOINTS.XGA}px)` as const,
  hd1250: `(max-width: ${STYLE_BREAKPOINTS.HD_1250}px)` as const,
  hd1366: `(max-width: ${STYLE_BREAKPOINTS.HD_1366}px)` as const,
  hd: `(max-width: ${STYLE_BREAKPOINTS.HD}px)` as const,
  onlyUhd: `(min-width: ${STYLE_BREAKPOINTS.HD + 1}px)`,
  exceptMobileNarrow: `(min-width: ${STYLE_BREAKPOINTS.MOBILE_NARROW + 1}px)`,
  exceptMobile: `(min-width: ${STYLE_BREAKPOINTS.MOBILE + 1}px)`,
  exceptMobileWide: `(min-width: ${STYLE_BREAKPOINTS.MOBILE_WIDE + 1}px)`,
  exceptMobileLandscape: `(min-width: ${
    STYLE_BREAKPOINTS.MOBILE_LANDSCAPE + 1
  }px)`,
  exceptTablet: `(min-width: ${STYLE_BREAKPOINTS.TABLET + 1}px)`,
  exceptSd: `(min-width: ${STYLE_BREAKPOINTS.SD + 1}px)`,
  exceptHd1366: `(min-width: ${STYLE_BREAKPOINTS.HD_1366 + 1}px)`,
  exceptXga: `(min-width: ${STYLE_BREAKPOINTS.XGA + 1}px)`,
  coarsePointer: '(pointer: coarse)',
};

export const HEADER_HEIGHT = 76;
export const HEADER_HEIGHT_SD = 56;

export const RANKING_CARD_IMAGE_CONFIG = {
  SD: {
    width: 256,
  },
  TABLET: {
    width: 256,
  },
  MOBILE_WIDE: {
    width: 343,
  },
};

export const MAIN_PAGE_PADDING_TOP = { desktop: 124, mobile: 80 };
