import {
  type PageInfoHash,
  type PortalPageInfo,
} from '../__generated__/globalTypes';

type NumberBasedPageData = {
  pageInfo: PortalPageInfo;
} & {
  [key: string]: unknown[];
};

type HashBasedPageData = {
  pageInfoHash: PageInfoHash;
} & {
  [key: string]: unknown[];
};

type HashBasedPageArgs = {
  nextPageHash?: string;
};

type TypePolicyOptions = {
  keyArgs: string[] | false;
};

/**
 * Type policy for pagination with number based page.
 * This policy only supports "next page" scenario for now.
 */
export function paginationTypePolicy({ keyArgs }: TypePolicyOptions) {
  return {
    keyArgs,
    merge(
      existing: NumberBasedPageData,
      incoming: NumberBasedPageData
    ): NumberBasedPageData {
      if (!incoming) return existing;
      if (!existing) return incoming;

      const existingPage = existing.pageInfo.page;
      const incomingPage = incoming.pageInfo.page;
      if (existingPage >= incomingPage) {
        return incoming;
      }

      return deepMerge(existing, incoming);
    },
  };
}

/**
 * Type policy for pagination with hash based page.
 */
export function hashBasedPaginationTypePolicy({ keyArgs }: TypePolicyOptions) {
  return {
    keyArgs,
    merge(
      existing: HashBasedPageData,
      incoming: HashBasedPageData,
      { args }: { args: HashBasedPageArgs | null }
    ): HashBasedPageData {
      if (!incoming) return existing;
      if (!existing) return incoming;

      // no nextPageHash means incoming data is the first page and cache needs to be overwritten
      if (!args?.nextPageHash) {
        return incoming;
      }

      const existingNextPageHash = existing.pageInfoHash.nextPageHash;
      const incomingNextPageHash = incoming.pageInfoHash.nextPageHash;
      if (existingNextPageHash === incomingNextPageHash) {
        return incoming;
      }

      return deepMerge(existing, incoming);
    },
  };
}

function deepMerge<T extends Partial<Record<keyof T, unknown>>>(
  existing: T,
  incoming: T
) {
  const result = { ...existing, ...incoming };
  let key: keyof T;

  for (key in result) {
    const existingValue = existing[key];
    const incomingValue = incoming[key];
    if (Array.isArray(incomingValue)) {
      if (Array.isArray(existingValue)) {
        // We always assume that object with same property name have the same type.
        result[key] = [...existingValue, ...incomingValue] as T[keyof T];
      }
    } else if (typeof incomingValue === 'object') {
      if (typeof existingValue === 'object') {
        result[key] = deepMerge(existingValue, incomingValue);
      }
    }
  }
  return result;
}
