export const BLOCK_VIEW_TITLE_CARD_SIZE = {
  video: { width: 256, height: 144 },
  live: { width: 256, height: 195 },
  linear: { width: 256, height: 195 },
  book: { width: 152, height: 213 },
  rankingBook: { width: 200, height: 309 },
  originalBook: { width: 208, height: 309 },
  originalComic: { width: 208, height: 328 },
  resumeVideo: { width: 256, height: 208 },
  resumeBook: { width: 188, height: 301 },
  character: { width: 152, height: 152 },
  label: { width: 178, height: 99 },
  league: { width: 178, height: 99 },
  spotlight: { width: 320, height: 180 },
};

export const BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE = {
  video: { width: 196, height: 110 },
  live: { width: 196, height: 159 },
  linear: { width: 196, height: 159 },
  book: { width: 125, height: 176 },
  rankingBook: { width: 176, height: 272 },
  originalBook: { width: 182, height: 280 },
  originalComic: { width: 182, height: 293 },
  resumeVideo: { width: 224, height: 176 },
  resumeBook: { width: 160, height: 256 },
  character: { width: 118, height: 118 },
  label: { width: 112, height: 63 },
  league: { width: 112, height: 63 },
  spotlight: { width: 320, height: 180 },
};

export const LIST_VIEW_TITLE_CARD_SIZE = {
  ...BLOCK_VIEW_TITLE_CARD_SIZE,
  video: { width: 256, height: 220 },
  featureVideo: { width: 256, height: 244 },
  purchasedVideo: { width: 256, height: 197 },
  live: { width: 256, height: 195 },
  purchasedLive: { width: 256, height: 195 },
  rankingVideo: { width: 256, height: 200 },
  book: { width: 200, height: 326 },
  originalBook: { width: 208, height: 376 },
  purchasedBook: { width: 200, height: 294 },
};

export const LIST_VIEW_MOBILE_TITLE_CARD_SIZE = {
  video: { width: 343, height: 112, imageWidth: 170 },
  purchasedVideo: { width: 343, height: 112 },
  rankingVideo: { width: 343, height: 96 },
  live: { width: 343, height: 97, imageWidth: 170 },
  purchasedLive: { width: 343, height: 97 },
  book: { width: 343, height: 140, imageWidth: 100 },
  rankingBook: { width: 343, height: 152, imageWidth: 100 },
  originalBook: { width: 343, height: 339 },
  purchasedBook: { width: 343, height: 140, imageWidth: 100 },
  character: { width: 118, height: 118 },
};

export const LIST_VIEW_COLUMN_GAP = 16;
export const LIST_VIEW_ROW_GAP = 24;
export const LIST_VIEW_MOBILE_ROW_GAP = 16;

export const IMAGE_ASPECT = {
  video: 16 / 9,
  live: 16 / 9,
  book: 5 / 7,
};
