import React from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles/constants';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';
import Container from './containers/Container';
import BookThumbnail from './Thumbnail';

const StyledContainer = styled(Container)`
  width: ${BLOCK_VIEW_TITLE_CARD_SIZE.originalComic.width}px;

  @media ${DEVICE.mobileWide} {
    width: ${BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.originalComic.width}px;
  }
`;

const ThumbnailLayout = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 24px 0;

  @media ${DEVICE.mobileWide} {
    padding: 16px 16px 0;
  }
`;

const CatchText = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin: 10px 0;
  white-space: normal;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  min-height: 60px;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
    padding: 0 16px;
    margin: 8px 0;
    min-height: 51px;
  }
`;

type Props = {
  thumbnailUrl: string;
  titleName: string;
  catchText?: string;
  isNew?: boolean;
  isLazy?: boolean;
};

const OriginalComicTitleCard: React.FC<Props> = ({
  thumbnailUrl,
  titleName,
  catchText,
  isNew = false,
  isLazy = false,
}) => {
  const thumbnailProps = {
    thumbnailUrl,
    titleName,
    isNew,
    isLazy,
  };

  return (
    <StyledContainer>
      <ThumbnailLayout>
        <BookThumbnail {...thumbnailProps} />
      </ThumbnailLayout>
      <CatchText>{catchText}</CatchText>
    </StyledContainer>
  );
};

export default OriginalComicTitleCard;
