import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { cosmo_getLiveDetailQuery } from '../../../__generated__/globalTypes';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { globalMessages } from '../../../shared/constants/messages';
import { mapNumberToWeekdayKey } from '../../../utils';
import { findCatchup } from '../../Genre/utils';
import {
  FlexColumns,
  InfoContent,
  InfoCopyright,
  InfoHeading,
  InfoSection,
  InfoText,
  ScheduleCopyrightContainer,
} from '../shared/InfoSection';
import messages from '../Video/messages';

const getWeekdayText = (day: number, intl: IntlShape) => {
  const weekdayKey = mapNumberToWeekdayKey(day, {
    short: true,
  });
  const weekdayText = weekdayKey
    ? intl.formatMessage(globalMessages[weekdayKey])
    : '';
  return weekdayText;
};

interface LiveDateSectionProps {
  liveDetail: cosmo_getLiveDetailQuery['webfront_getLive'];
}

const LiveDateSection: React.FC<LiveDateSectionProps> = ({ liveDetail }) => {
  const intl = useIntl();

  const deliveryStart = new Date(liveDetail.deliveryStartDateTime);
  const performanceStart = new Date(liveDetail.performanceStartDateTime);
  const timeShiftedPlayBackAllowedUntil = new Date(
    liveDetail.timeshiftedPlaybackAllowedUntil ?? ''
  );

  const liveCatchup = findCatchup(liveDetail);
  const liveCatchupStart =
    liveCatchup && liveCatchup.publicStartDateTime
      ? new Date(liveCatchup.publicStartDateTime)
      : null;
  const liveCatchupEnd = liveCatchup && new Date(liveCatchup.publicEndDateTime);

  return (
    <InfoSection>
      <FlexColumns>
        <InfoContent>
          <InfoHeading>
            {intl.formatMessage(messages.liveStartTimeHeader)}
          </InfoHeading>
          <InfoText>
            <div>
              {intl.formatDate(deliveryStart, {
                month: 'long',
                day: 'numeric',
              })}
              ({getWeekdayText(deliveryStart.getDay(), intl)})
            </div>
            <div>
              {!!deliveryStart && (
                <span>
                  {intl.formatMessage(messages.deliveryStartText, {
                    startDate: intl.formatTime(deliveryStart),
                  })}
                </span>
              )}
              {!!deliveryStart && !!performanceStart && ' / '}
              {!!performanceStart && (
                <span>
                  {intl.formatMessage(messages.performanceStartText, {
                    startDate: intl.formatTime(performanceStart),
                  })}
                </span>
              )}
            </div>

            {liveDetail.displayLiveMinute ? (
              <div>
                {intl.formatMessage(messages.performanceDurationText, {
                  minutes: liveDetail.displayLiveMinute,
                })}
              </div>
            ) : undefined}
          </InfoText>
        </InfoContent>
        <InfoContent>
          <InfoHeading>
            {intl.formatMessage(messages.catchupInformationHeader)}
          </InfoHeading>
          <InfoText>
            {liveCatchup
              ? intl.formatMessage(messages.catchupInformationText, {
                  startDate: liveCatchupStart
                    ? intl.formatDate(liveCatchupStart, {
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    : intl.formatMessage(messages.catchupWhenAvailableText),
                  endDate:
                    liveCatchupEnd &&
                    intl.formatDate(liveCatchupEnd, {
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    }),
                })
              : liveDetail.allowsTimeshiftedPlayback
              ? intl.formatMessage(
                  messages.liveTimeShiftedPlayBackAllowedUntil,
                  {
                    allowedUntil: intl.formatDate(
                      timeShiftedPlayBackAllowedUntil,
                      {
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      }
                    ),
                  }
                )
              : intl.formatMessage(messages.noCatchup)}
          </InfoText>
        </InfoContent>
      </FlexColumns>
      {liveDetail.caution && (
        <InfoContent hasBorderTop>
          <InfoHeading>
            <Icon name={Icons.CAUTION} />
            <FormattedMessage {...globalMessages.attention} />
          </InfoHeading>
          <InfoText>{liveDetail.caution}</InfoText>
        </InfoContent>
      )}
      {liveDetail.copyright && (
        <ScheduleCopyrightContainer>
          <InfoCopyright>
            <div>{liveDetail.copyright}</div>
          </InfoCopyright>
        </ScheduleCopyrightContainer>
      )}
    </InfoSection>
  );
};

export default LiveDateSection;
