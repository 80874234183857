import React, {
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { DEVICE } from '../../../shared/styles';
import { globalMessages } from '../../constants/messages';
import { COLORS } from '../../styles/theme';
import { RoundBadge } from '../Common/Badge/RoundBadge';
import Icon, { Icons } from '../Common/Icon';
import { placeholderStyle } from '../Common/Placeholder';

export const LEANBACK_WIDTH = 256;

export interface IsOpenProps {
  isOpen?: boolean;
}

interface ItemProps {
  isActive?: boolean;
  isSubItem?: boolean;
}

const Header = styled.div`
  margin: 20px 0 28px;
  padding: 0;
  width: ${LEANBACK_WIDTH}px;
  height: 26px;
  font-size: 18px;
  font-weight: 600;
  vertical-align: middle;
  line-height: 26px;
  position: relative;
  cursor: default;
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  @media ${DEVICE.sd} {
    margin: 0;
    height: 56px;
    line-height: 56px;
    right: 12px;

    > span {
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      left: 75px;
      color: ${({ theme }) => theme.leanback.points.prefixText};
    }

    > div {
      right: 12px;
    }
  }
`;

const Item = styled.li<ItemProps>`
  display: block;
  padding: 0;
  margin: 8px 0 16px;
  position: relative;
  height: 40px;
  line-height: 40px;
  width: ${LEANBACK_WIDTH}px;
  transition: background 0.15s ease-in;
  box-sizing: border-box;

  ${({ isSubItem }) =>
    isSubItem &&
    css`
      margin: 0 0 8px;
    `}

  a {
    text-decoration: none;
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    color: ${({ theme }) => theme.menu.text};
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.15s ease-in;
    padding-left: 28px;
    font-weight: 700;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.menu.textHover};
    }

    ${({ isSubItem }) =>
      isSubItem &&
      css`
        padding-left: 72px;
        font-weight: 300;
      `}
  }

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${({ theme }) => theme.menu.active.background};

          a {
            color: ${({ theme }) => theme.menu.textHover};
          }

          &:hover,
          &:focus-within {
            background-color: ${({ theme }) =>
              theme.menu.active.backgroundHover};
          }
        `
      : css`
          &:hover,
          &:focus-within {
            background-color: ${({ theme }) => theme.menu.backgroundHover};
          }
        `}
`;

const NewIcon = styled(RoundBadge)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background: ${COLORS.primary};
`;

const ItemHeader = styled.li<{ shouldShowActiveState: boolean }>`
  display: block;
  padding: 0;
  margin: 8px 0;
  position: relative;
  height: 40px;
  line-height: 40px;
  width: ${LEANBACK_WIDTH}px;
  transition: background 0.15s ease-in;
  box-sizing: border-box;

  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    height: 100%;
    display: flex;
    color: ${({ theme }) => theme.menu.text};
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.15s ease-in;
    padding-left: 28px;

    ${({ shouldShowActiveState }) =>
      shouldShowActiveState
        ? css`
            color: ${({ theme }) => theme.menu.textHover};
            background-color: ${({ theme }) => theme.menu.active.background};
          `
        : css`
            &:hover,
            &:focus {
              color: ${({ theme }) => theme.menu.textHover};
              background-color: ${({ theme }) => theme.menu.backgroundHover};
            }
          `}
  }
`;

interface AccountItemProps {
  isActive?: boolean;
}
const AccountItem = styled.li<AccountItemProps>`
  display: block;
  padding: 0;
  margin: 0 0 4px 0;
  position: relative;
  height: 32px;
  line-height: 32px;
  width: 100%;
  height: 40px;
  transition: background 0.15s ease-in;
  box-sizing: border-box;
  font-weight: 200;

  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: ${({ theme }) => theme.leanback.accountSetting.text};
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.15s ease-in, background-color 0.15s ease-in;
    border-radius: 8px;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.leanback.accountSetting.textHover};
      background-color: ${({ theme }) => theme.menu.active.background};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.menu.active.background};

      a {
        font-weight: 600;
        color: ${({ theme }) => theme.menu.textHover};
      }

      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.menu.active.backgroundHover};
      }
    `}
`;

const StyledIcon = styled(Icon)`
  width: 28px;
  height: 28px;
  margin-right: 16px;
  flex-shrink: 0;
`;

interface GenreMenuProps {
  isOpen?: boolean;
}

const ItemHeaderArrow = styled(Icon)<GenreMenuProps>`
  margin: 0 16px 0 auto;
  width: 22px;
  height: 22px;
  transition: transform 0.3s ease-in;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

const StyledAccountIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  flex-shrink: 0;
`;

const AccountItemPlaceholder = styled.div`
  position: relative;
  height: 40px;
  margin: 0 8px;

  &:before {
    ${placeholderStyle};
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  &:after {
    ${placeholderStyle};
    content: '';
    position: absolute;
    top: 9px;
    left: 32px;
    right: 0;
    height: 22px;
  }
`;

const MenuItemPlaceholder = styled.div`
  position: relative;
  height: 40px;
  margin: 16px 16px 0 28px;

  &:before {
    ${placeholderStyle};
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 4px;
  }

  &:after {
    ${placeholderStyle};
    content: '';
    position: absolute;
    top: 6px;
    left: 42px;
    right: 0;
    height: 28px;
  }
`;

const AccountSettingContainer = styled.div`
  --sideMargin: 16px;
  width: calc(${LEANBACK_WIDTH}px - var(--sideMargin) * 2);
  margin: 24px var(--sideMargin);
  padding: 12px;
  background-color: ${COLORS.ink};
  border-radius: 12px;
  box-sizing: border-box;
`;

const PlaceHolder: React.FC = () => {
  return (
    <>
      <AccountSettingContainer>
        <AccountItemPlaceholder />
        <AccountItemPlaceholder />
        <AccountItemPlaceholder />
      </AccountSettingContainer>
      <div>
        <MenuItemPlaceholder />
        <MenuItemPlaceholder />
        <MenuItemPlaceholder />
        <MenuItemPlaceholder />
        <MenuItemPlaceholder />
        <MenuItemPlaceholder />
      </div>
    </>
  );
};

const Footer = styled.ul<IsOpenProps>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${({ theme }) => theme.leanback.footer.background};
  border-top: 1px solid ${({ theme }) => theme.leanback.footer.devider};
  margin: auto 0 0;
  width: ${LEANBACK_WIDTH}px;
  box-sizing: border-box;

  @media ${DEVICE.sd} {
    position: fixed;
    bottom: 0;
    z-index: 9001;
    transition: transform 0.15s ease-in;
    transform: translateX(-${LEANBACK_WIDTH}px);

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translateX(0);
      `}
  }
`;

const FooterItem = styled.li`
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  transition: background 0.15s ease-in;
  box-sizing: border-box;

  a {
    text-decoration: none;
    font-size: 12px;
    padding: 4px 4px 4px 16px;
    display: flex;
    color: ${COLORS.white_50};
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.15s ease-in;

    &:hover,
    &:focus {
      color: ${COLORS.white};
    }

    > div {
      width: 18px;
      height: 18px;
      margin-left: auto;
      color: ${COLORS.white};
    }
  }
`;

const GenreContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
`;

const SubItemContainer = styled.ul<GenreMenuProps>`
  padding: 0;
  list-style: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;

  ${({ isOpen }) =>
    // the max height = 48px * item count
    isOpen &&
    css`
      max-height: 768px;
    `}
`;

const TextOverflow = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export enum GroupDisplayName {
  VIDEO = 'videoKana',
  BOOK = 'bookKana',
  LIBRARY = 'library',
}

type GenreGroupProps = PropsWithChildren<{
  groupId: string;
  isSubItemFocused: boolean;
  defaultState?: boolean;
}>;

export const GenreGroup: React.FC<GenreGroupProps> = ({
  groupId,
  isSubItemFocused,
  defaultState = true,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultState);
  const onHeaderClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onHeaderKeyDown = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <ItemHeader shouldShowActiveState={isSubItemFocused && !isOpen}>
        <a
          onClick={onHeaderClick}
          role="menuitem"
          tabIndex={0}
          onKeyDown={onHeaderKeyDown}
        >
          <StyledIcon name={Icons[groupId as keyof typeof Icons]} />
          <FormattedMessage
            {...globalMessages[
              GroupDisplayName[groupId as keyof typeof GroupDisplayName]
            ]}
          />
          <ItemHeaderArrow name={Icons.HALF_ARROW_UP} isOpen={isOpen} />
        </a>
      </ItemHeader>
      <SubItemContainer isOpen={isOpen}>{children}</SubItemContainer>
    </>
  );
};

export default {
  Header,
  Item,
  NewIcon,
  ItemHeader,
  AccountItem,
  Icon: StyledIcon,
  AccountIcon: StyledAccountIcon,
  ItemHeaderArrow,
  PlaceHolder,
  AccountSettingContainer,
  Footer,
  FooterItem,
  GenreContainer,
  GenreGroup,
  TextOverflow,
};
