import { useRouter } from 'next/router';
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useIntl } from 'react-intl';
import {
  BookMediaTypeCode,
  cosmo_bookTitleDetailQuery,
  cosmo_bookTitleDetailQueryVariables,
} from '../../../__generated__/globalTypes';
import { createAkamaiImageUrl } from '../../../shared/components/Common/AkamaiImage';
import MetaTags from '../../../shared/components/MetaTags';
import {
  BookGenreUrl,
  PortalErrorCodes,
  UrlQueryParams,
} from '../../../shared/constants';
import { errorMessages } from '../../../shared/constants/messages';
import metaMessages from '../../../shared/constants/meta/titleDetail';
import { createExtendedApolloError } from '../../../shared/ExtendedApolloError';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import {
  PreorderContext,
  preorderStatusReducer,
} from '../../../shared/PreorderContext';
import { extractGraphQLError } from '../../../utils';
import { bookGenreTitles } from '../../Genre/messages';
import { Credit } from '../shared/CreditSection';
import TitleDetailPlaceholder from '../shared/Placeholder';
import BookDetailContent from './BookDetailContent';
import BookStageSection from './BookStageSection';
import ComicDetailContent from './ComicDetailContent';
import { GET_BOOK } from './gql';

const COMIC_BOOKS_MAX_NUM = 2;
export const COMIC_CHAPTERS_MAX_NUM = 5;

interface BookTitleProps {
  bookSakuhinCode: string;
  bookCode?: string;
  isSsr: boolean;
}

const BookDetail: React.FC<BookTitleProps> = ({
  bookSakuhinCode,
  bookCode,
}) => {
  const intl = useIntl();
  const router = useRouter();

  const bookTitleDetailVariables = useMemo<cosmo_bookTitleDetailQueryVariables>(
    () => ({
      bookSakuhinCode,
      bookCode,
      viewBookCode: router.query[UrlQueryParams.BOOK_VIEW_CODE] as string,
      featurePieceCode: router.query[
        UrlQueryParams.FEATURE_PIECE_CODE
      ] as string,
      bookListPageSize: COMIC_BOOKS_MAX_NUM, // fetch current and after episodes for comic
      bookListChapterPageSize: COMIC_CHAPTERS_MAX_NUM, // fetch current and after chapters for comic
    }),
    [bookSakuhinCode, bookCode, router]
  );

  const {
    data,
    loading: titleLoading,
    error,
  } = useClientQuery<
    cosmo_bookTitleDetailQuery,
    cosmo_bookTitleDetailQueryVariables
  >(GET_BOOK, {
    variables: bookTitleDetailVariables,
    fetchPolicy: 'cache-and-network',
  });

  const onUpdateBookMark: ComponentProps<
    typeof BookStageSection
  >['onUpdateBookMark'] = useCallback(
    (cache, { isFavorite, code }) => {
      const getBookData = cache.readQuery<cosmo_bookTitleDetailQuery>({
        query: GET_BOOK,
        variables: bookTitleDetailVariables,
      });
      cache.writeQuery({
        query: GET_BOOK,
        variables: bookTitleDetailVariables,
        data: {
          bookTitle: {
            ...getBookData?.bookTitle,
            sakuhinCode: code,
            isFavorite,
          },
        },
      });
    },
    [bookTitleDetailVariables]
  );

  const onUpdateBookEvaluation: ComponentProps<
    typeof BookStageSection
  >['onUpdateBookEvaluation'] = useCallback(
    (cache, { code, rate, userRate, __typename }) => {
      const getBookData = cache.readQuery<cosmo_bookTitleDetailQuery>({
        query: GET_BOOK,
        variables: bookTitleDetailVariables,
      });
      cache.writeQuery({
        query: GET_BOOK,
        variables: bookTitleDetailVariables,
        data: {
          bookTitle: {
            ...getBookData?.bookTitle,
            __typename,
            sakuhinCode: code,
            userRate: userRate,
            rate: rate,
          },
        },
      });
    },
    [bookTitleDetailVariables]
  );

  if (error) {
    const [bookTitleErrorCode] = extractGraphQLError('BOOK_TITLE_ERROR', error);
    const extendedErrorData =
      bookTitleErrorCode === PortalErrorCodes.BOOK_TITLE_NOT_FOUND
        ? {
            errorCode: '404',
            customTitle: intl.formatMessage(errorMessages.notFoundTitle),
            customMessage: intl.formatMessage(
              errorMessages.notFoundDescription
            ),
          }
        : {
            errorCode: '500',
          };
    throw createExtendedApolloError(error, extendedErrorData);
  }

  const bookSakuhin = data?.bookTitle;

  const [preorderStatus, updatePreorderStatus] = useReducer(
    preorderStatusReducer,
    new Map()
  );
  useEffect(() => {
    if (!titleLoading && bookSakuhin?.book?.isPreorderable) {
      updatePreorderStatus({
        payload: [
          {
            bookCode: bookSakuhin?.book?.code,
            isPreorderable: bookSakuhin?.book?.isPreorderable,
            isPreordered: !!bookSakuhin?.book?.isPreordered,
          },
        ],
      });
    }
  }, [
    bookSakuhin?.book?.code,
    bookSakuhin?.book?.isPreorderable,
    bookSakuhin?.book?.isPreordered,
    titleLoading,
  ]);

  if (titleLoading || !bookSakuhin?.sakuhinCode) {
    return <TitleDetailPlaceholder type="book" />;
  }

  let credits: Credit[] = [];
  if (bookSakuhin.sakuhinCode && bookSakuhin.book) {
    // Filter if any property missing so we can cast as string after
    const bookCredits = bookSakuhin.book.credits.filter(
      (credit) =>
        credit.bookAuthorType &&
        credit.personCode &&
        credit.penNameCode &&
        credit.penName
    );
    credits = bookCredits.map(
      (credit): Credit => ({
        group: credit.bookAuthorType as string,
        personCode: credit.personCode as string,
        personNameCode: credit.penNameCode as string,
        personName: credit.penName as string,
      })
    );

    // Push publisher into credits list
    if (
      bookSakuhin.book?.publisher &&
      bookSakuhin.book.publisher.code &&
      bookSakuhin.book.publisher.name
    ) {
      credits.push({
        group: '出版社',
        personCode: bookSakuhin.book.publisher.code,
        personName: bookSakuhin.book.publisher.name,
        linkHref: `/browse/publisher/[publisherCode]`,
        linkAs: `/browse/publisher/${bookSakuhin.book.publisher.code}`,
      });
    }

    // Push published in to credits list if it exists
    if (bookSakuhin.book.publishedIn.length > 0) {
      bookSakuhin.book.publishedIn.forEach((magazine) => {
        if (magazine.code && magazine.name)
          credits.push({
            group: '掲載誌',
            personCode: magazine.code,
            personName: magazine.name,
            linkHref: `/browse/magazine/[magazineCode]`,
            linkAs: `/browse/magazine/${magazine.code}`,
          });
      });
    }

    // Push label in to credits list if it exists
    if (bookSakuhin.bookLabel?.code) {
      if (bookSakuhin.bookLabel.name)
        credits.push({
          group: 'レーベル',
          personCode: bookSakuhin.bookLabel.code,
          personName: bookSakuhin.bookLabel.name,
          linkHref: `/browse/label/[bookLabelCode]`,
          linkAs: `/browse/label/${bookSakuhin.bookLabel.code}`,
        });
    }
  }

  const isChapter = !!bookSakuhin.isChapter;
  // Get linked bookSakuhinChapter code for canonical link if current bookSakuhin is not a chapter-sakuhin
  const bookSakuhinChapterLinkCode = !isChapter
    ? bookSakuhin.bookSakuhinChapterLink?.bookSakuhinCode
    : undefined;
  const mediaCode = bookSakuhin.book?.mediaType
    .code as keyof typeof BookGenreUrl;
  const isComic = mediaCode === BookMediaTypeCode.COMIC;
  const hasBookCode = !!bookCode;
  const metaTagTitle = hasBookCode ? bookSakuhin.book?.name : bookSakuhin.name;
  const metaTagThumbnail =
    (hasBookCode
      ? bookSakuhin.book?.thumbnail.standard
      : bookSakuhin.thumbnail.standard) || '';

  const breadcrumbs = [
    { name: 'HOME', pathname: '/' },
    {
      name: intl.formatMessage(bookGenreTitles[mediaCode].title),
      pathname: `/book/genre/${
        BookGenreUrl[mediaCode in BookGenreUrl ? mediaCode : 'BOOK']
      }`,
    },
    {
      name: bookSakuhin.name,
      pathname: `/book/title/${bookSakuhin.sakuhinCode}`,
    },
  ];
  if (hasBookCode && bookSakuhin.book) {
    breadcrumbs.push({
      name: bookSakuhin.book.name,
      pathname: `/book/title/${bookSakuhin.sakuhinCode}/${bookSakuhin.book.code}`,
    });
  }

  return (
    <>
      <MetaTags
        // title for og and twitter is being checked
        title={
          isChapter
            ? intl.formatMessage(metaMessages.book.freeComicTitle, {
                bookSakuhinTitle: bookSakuhin.name,
                bookTitle: bookSakuhin.book?.name,
              })
            : intl.formatMessage(metaMessages.book.defaultTitle, {
                bookTitle: metaTagTitle,
                mediaTypeName: intl.formatMessage(
                  bookGenreTitles[mediaCode].title
                ),
              })
        }
        description={
          isChapter
            ? intl.formatMessage(metaMessages.book.freeComicDescription, {
                bookSakuhinTitle: bookSakuhin.name,
                bookTitle: bookSakuhin.book?.name,
              })
            : intl.formatMessage(metaMessages.book.defaultDescription)
        }
        keywords={intl.formatMessage(metaMessages.book.defaultKeywords, {
          bookTitle: metaTagTitle,
        })}
        image={`https://${createAkamaiImageUrl(
          metaTagThumbnail,
          { width: 1024 },
          'jpg'
        )}`}
        path={`/book/title/${bookSakuhin.sakuhinCode}/${bookSakuhin.book?.code}`}
        ogType="article"
        canonicalLink={`/book/title/${
          bookSakuhinChapterLinkCode || bookSakuhin.sakuhinCode
        }`}
        breadcrumbs={breadcrumbs}
      />
      <PreorderContext.Provider
        value={{
          preorderStatus,
          updatePreorderStatus,
        }}
      >
        {isComic ? (
          <ComicDetailContent
            bookSakuhin={bookSakuhin}
            credits={credits}
            onUpdateBookMark={onUpdateBookMark}
            onUpdateBookEvaluation={onUpdateBookEvaluation}
            preorderStatus={preorderStatus}
            updatePreorderStatus={updatePreorderStatus}
          />
        ) : (
          <BookDetailContent
            bookSakuhin={bookSakuhin}
            credits={credits}
            preorderStatus={preorderStatus}
            updatePreorderStatus={updatePreorderStatus}
            onUpdateBookMark={onUpdateBookMark}
            onUpdateBookEvaluation={onUpdateBookEvaluation}
          />
        )}
      </PreorderContext.Provider>
    </>
  );
};

export default BookDetail;
