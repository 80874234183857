import { createContext } from 'react';

const UserAgentContext = createContext<{
  userAgent: string;
  hasUserCookie: boolean | null;
  isBot: boolean;
}>({
  userAgent: '',
  // WARNING: this value is **only available on SSR** now. If the page is CSRed this value will always be null.
  // We can pass this value to browser through the SSR-generated HTML to make it CSR available as well, but
  // currently it's not needed.
  hasUserCookie: null,
  isBot: false,
});

export default UserAgentContext;
