import { useToasts } from '@u-next/react-toast-notifications';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_getLiveDetailQuery,
  cosmo_getLiveDetailQueryVariables,
  cosmo_getLiveDetailRelatedStuffsQuery,
  cosmo_getLiveDetailRelatedStuffsQueryVariables,
} from '../../../__generated__/globalTypes';
import MetaTags from '../../../shared/components/MetaTags';
import {
  LIST_VIEW_ITEMS_PER_PAGE,
  PortalErrorCodes,
} from '../../../shared/constants';
import { errorMessages } from '../../../shared/constants/messages';
import metaMessages from '../../../shared/constants/meta/titleDetail';
import { createExtendedApolloError } from '../../../shared/ExtendedApolloError';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { useUserInfo } from '../../../shared/hooks/useUserInfo';
import { extractGraphQLError } from '../../../utils';
import type { Nullable } from '../../../utils/Nullable';
import {
  CAST_LIST_NAME,
  RELATED_LIVE_TITLE_LIST_TITLE_CARD,
  RELATED_VIDEO_TITLE_LIST_TITLE_CARD,
  STAGE_SIGNUP,
} from '../../Log/ComponentName/liveTitleDetail';
import CreditSection, { Credit } from '../shared/CreditSection';
import TitleDetailPlaceholder from '../shared/Placeholder';
import RelatedSection from '../shared/RelatedWorkSection/RelatedSection';
import SignupPromotion from '../shared/SignupPromotion';
import messages from '../Video/messages';
import { GET_LIVE_DETAIL, GET_LIVE_DETAIL_RELATED_STUFFS } from './gql';
import LiveAnnouncementSection from './LiveAnnouncementSection';
import LiveAttractionSection from './LiveAttractionSection';
import LiveDateSection from './LiveDateSection';
import LiveNotesSection from './LiveNotesSection';
import LiveSamplePlaySection from './LiveSamplePlaySection';
import LiveStageSection from './LiveStageSection';

interface LiveDetailProps {
  liveCode: string;
}

const LiveDetail: React.FC<LiveDetailProps> = ({ liveCode }) => {
  const { data: userInfoData, loading: userInfoLoading } = useUserInfo();
  const userInfo = userInfoData?.userInfo;
  const intl = useIntl();
  const { loading, data, error } = useClientQuery<
    cosmo_getLiveDetailQuery,
    cosmo_getLiveDetailQueryVariables
  >(GET_LIVE_DETAIL, {
    variables: {
      liveCode,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const { data: relatedStuffsData, error: relatedStuffsError } = useClientQuery<
    cosmo_getLiveDetailRelatedStuffsQuery,
    cosmo_getLiveDetailRelatedStuffsQueryVariables
  >(GET_LIVE_DETAIL_RELATED_STUFFS, {
    variables: {
      liveCode,
      page: 1,
      pageSize: LIST_VIEW_ITEMS_PER_PAGE.DEFAULT,
    },
    errorPolicy: 'all',
  });

  const liveDetail = data?.webfront_getLive;

  if (error) {
    const [liveDetailErrorCode] = extractGraphQLError(
      'LIVE_DETAIL_ERROR',
      error
    );
    const extendedErrorData =
      liveDetailErrorCode === PortalErrorCodes.LIVE_TITLE_NOT_FOUND
        ? {
            errorCode: '404',
            customTitle: intl.formatMessage(errorMessages.notFoundTitle),
            customMessage: intl.formatMessage(
              errorMessages.notFoundDescription
            ),
          }
        : { errorCode: '500' };
    throw createExtendedApolloError(error, extendedErrorData);
  }

  const { addToast, removeToast } = useToasts();
  const relatedStuffsErrorToastId = useRef<Nullable<string>>(null);
  useEffect(() => {
    if (relatedStuffsErrorToastId.current) {
      removeToast(relatedStuffsErrorToastId.current);
      relatedStuffsErrorToastId.current = null;
    }

    if (relatedStuffsError) {
      addToast(
        intl.formatMessage(errorMessages.fetchErrorToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          relatedStuffsErrorToastId.current = id;
        }
      );
    }
  }, [
    addToast,
    intl,
    relatedStuffsError,
    relatedStuffsErrorToastId,
    removeToast,
  ]);

  const relatedLives = relatedStuffsData?.liveRelatedLives;
  const totalLiveCount = relatedLives?.pageInfo.results || 0;
  const hasRelatedLives = totalLiveCount > 0;

  const relatedVideos = relatedStuffsData?.liveRelatedVideos;
  const totalVideoCount = relatedVideos?.pageInfo.results || 0;
  const hasRelatedVideos = totalVideoCount > 0;

  let credits: Credit[] = [];

  if (liveDetail?.credits) {
    credits = liveDetail.credits.map((credit) => ({
      ...credit,
      personName: credit.personName ?? '',
      personCode: credit.personCode ?? '',
      personNameCode: credit.personNameCode ?? '',
      group: credit.castTypeName ?? '',
      characterName: credit.characterName ?? '',
    }));
  }

  if (loading || !liveDetail) {
    return <TitleDetailPlaceholder type="live" />;
  }

  return (
    <>
      <MetaTags
        title={intl.formatMessage(metaMessages.live.defaultTitle, {
          liveName: liveDetail?.name,
        })}
        description={intl.formatMessage(metaMessages.live.defaultDescription)}
        canonicalLink={`/livedetail/${liveCode}`}
        shouldUseStaticSiteforCanonicalUrl
      />
      <LiveStageSection
        isLoggedIn={!userInfoLoading && !!userInfo?.id}
        liveDetail={liveDetail}
      />
      <LiveDateSection liveDetail={liveDetail} />
      <SignupPromotion
        btnComponentName={STAGE_SIGNUP}
        type={
          liveDetail.productLineupCodeList.includes('LNPS_FSCR')
            ? 'FSOCCER'
            : 'GENERAL'
        }
      />
      <LiveSamplePlaySection liveDetail={liveDetail} />
      <LiveAnnouncementSection liveDetail={liveDetail} />
      <LiveAttractionSection liveDetail={liveDetail} />
      {credits.length > 0 && (
        <CreditSection
          credits={credits}
          sectionHeading={intl.formatMessage(messages.creditSectionHeading)}
          castNameComponentName={CAST_LIST_NAME}
        />
      )}
      {relatedLives && hasRelatedLives && (
        <RelatedSection
          sectionName="relatedLive"
          sectionHeading={intl.formatMessage(
            messages.relatedLiveSectionHeading
          )}
          contentType="live"
          items={relatedLives.liveList}
          totalItemCount={totalLiveCount}
          itemComponentName={RELATED_LIVE_TITLE_LIST_TITLE_CARD}
          showMoreLinkProps={{
            href: `/live/browse/relation/${liveCode}/live`,
          }}
        />
      )}
      {relatedVideos && hasRelatedVideos && (
        <RelatedSection
          sectionName="relatedVideo"
          sectionHeading={intl.formatMessage(
            messages.liveRelatedVideoSectionHeading
          )}
          contentType="video"
          items={relatedVideos.titles}
          totalItemCount={totalVideoCount}
          itemComponentName={RELATED_VIDEO_TITLE_LIST_TITLE_CARD}
          showMoreLinkProps={{
            href: `/live/browse/relation/${liveCode}/video`,
          }}
        />
      )}
      <LiveNotesSection liveDetail={liveDetail} />
    </>
  );
};

export default LiveDetail;
