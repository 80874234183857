import Link from 'next/link';
import React, { HTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../styles/theme';

interface TabProps {
  isActive: boolean;
  isLeftActive: boolean;
  hasOnAirLive?: boolean;
}

const commonTabCss = css<TabProps>`
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  color: ${COLORS.white};
  text-align: center;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0);
  ${({ isActive }) => isActive && `background-color: ${COLORS.white_30};`};
  transition: all 0.15s ease-in;
  flex: 1;
  position: relative;

  &:not(:first-child) {
    // divider
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 50%;
      width: 1px;
      background-color: ${({ theme }) => theme.tabs.divider};
      ${({ isActive, isLeftActive }) =>
        (isActive || isLeftActive) &&
        'background-color: rgba(255, 255, 255, 0);'};
    }
  }
`;

const TabLink = styled.a<TabProps>`
  ${commonTabCss};
`;

const TabButton = styled.button<TabProps>`
  ${commonTabCss};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${COLORS.white_10};
  backdrop-filter: blur(12px);
`;

interface TabsProps extends HTMLAttributes<HTMLElement> {
  tabs: TabInfo[];
  defaultActiveIndex?: number;
  onTabItemClick?: (index: number) => void;
  tabComponentName?: string;
}

interface TabInfo {
  text: string;
  href?: string;
  hasOnAirLive?: boolean;
}

interface StatelessTabsProps extends TabsProps {
  activeIndex: number;
  setActiveIndex?: (num: number) => void;
  tabComponentName?: string;
  shadeColor?: string;
}

const CapsuleTabs: React.FC<TabsProps> = ({
  tabComponentName,
  defaultActiveIndex = 0,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  return (
    <StatelessCapsuleTabs
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      {...props}
      tabComponentName={tabComponentName}
    />
  );
};

export const StatelessCapsuleTabs: React.FC<StatelessTabsProps> = ({
  activeIndex,
  setActiveIndex,
  tabs,
  onTabItemClick,
  tabComponentName,
  shadeColor,
  ...props
}) => {
  const clickAction = (index: number) => {
    setActiveIndex && setActiveIndex(index);
    if (typeof onTabItemClick === 'function') {
      onTabItemClick(index);
    }
  };

  return (
    <Wrapper {...props} data-ucn={tabComponentName}>
      {tabs.some((tab) => tab.href !== undefined)
        ? tabs.map((tab: TabInfo, index: number) => (
            <Link
              key={tab.text}
              href={tab.href ?? ''}
              passHref
              scroll={false}
              legacyBehavior
            >
              <TabLink
                onClick={(e) => {
                  e.stopPropagation();
                  clickAction(index);
                }}
                isActive={index === activeIndex}
                isLeftActive={index - 1 === activeIndex}
              >
                {tab.text}
              </TabLink>
            </Link>
          ))
        : tabs.map((tab: TabInfo, index: number) => (
            <TabButton
              data-ucn="capsule-tab-btn"
              key={tab.text}
              onClick={(e) => {
                e.stopPropagation();
                clickAction(index);
              }}
              isActive={index === activeIndex}
              isLeftActive={index - 1 === activeIndex}
              hasOnAirLive={tab.hasOnAirLive}
            >
              {tab.text}
            </TabButton>
          ))}
    </Wrapper>
  );
};

export default CapsuleTabs;
