import {
  ApolloClient,
  ApolloProvider,
  gql,
  NormalizedCacheObject,
} from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { NextPageContext } from 'next';
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { parseCookies } from 'nookies';
import { ComponentType } from 'react';
import { cosmo_getLeanbackGenresQuery } from '../__generated__/globalTypes';
import { GET_LEANBACK_GENRES } from '../shared/components/Leanback/gql';
import { checkIsFromShortcutMode } from '../shared/hooks/useIsFromShortcutMode';
import { isBotRequest } from '../utils';
import { refreshTokenOrMigrate } from '../utils/migration';
import TokenHolder from '../utils/tokenHolder';
import { initializeApollo } from './apolloClient';

export interface WithApolloProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  leanbackData: LeanbackGenre[];
  isBot: boolean;
}

type NextAppComponentType = Omit<typeof NextApp, 'origGetInitialProps'>;
type AppComponentType<P extends AppInitialProps & WithApolloProps> =
  ComponentType<P> & NextAppComponentType;

export interface WithApolloComponentProps {
  apolloState: NormalizedCacheObject;
  leanbackData: LeanbackGenre[];
  apolloClient: ApolloClient<NormalizedCacheObject>;
  isBot: boolean;
}

interface SearchMenu {
  id: string;
  name: string;
  type: string;
}

export interface LeanbackGenre {
  id: string;
  name: string;
  displayCode: string;
  searchMenu: SearchMenu;
}

/**
 * Flattens and reduces leanback data from the server into
 * an expected object format.
 *
 * @param leanbackData Leanback data as provided from the API
 */
const prepareLeanbackData = (
  leanbackData: cosmo_getLeanbackGenresQuery
): LeanbackGenre[] => {
  // Flatten book and video genres into one array of leanback genres
  const allGenres = leanbackData.webfront_leanbackGenres.reduce(
    // @ts-ignore
    (a, v) => [...a, ...v.leanbackGenres],
    [] as cosmo_getLeanbackGenresQuery['webfront_leanbackGenres'][number]['leanbackGenres']
  );

  return allGenres.map((i) => ({
    id: i.id,
    displayCode: i.displayCode,
    name: i.name,
    searchMenu: {
      id: i.searchMenu.id,
      name: i.searchMenu.name,
      type: i.searchMenu.type,
    },
  }));
};

export interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  ctx: NextPageContextApp;
}

type NextPageContextApp = NextPageContextWithApollo & AppContext;

const BASE_CACHE_QUERY = gql`
  query InitializeLocalValues {
    parentalLock @client
    isAeon @client
  }
`;

const withApollo = <P extends AppInitialProps & WithApolloProps>(
  App: AppComponentType<P>
): {
  (props: AppProps & WithApolloComponentProps): JSX.Element;
  getInitialProps(ctx: NextPageContextApp): Promise<{
    apolloState: NormalizedCacheObject;
    leanbackData: LeanbackGenre[];
    pageProps: Record<string, unknown>;
    isBot: boolean;
  }>;
} => {
  const Apollo = (props: AppProps & WithApolloComponentProps) => {
    // on server: initialize apolloClient by initializeApollo() with next's ctx and NO initial state at Apollo.getInitialProps,
    //            and pass the instance to here
    // on client: create a new apolloClient by initializeApollo() with initial state and NO next's context here.
    const apolloClient =
      props.apolloClient ||
      initializeApollo({ initialState: props.apolloState, isBot: props.isBot });

    // It's very difficult to make TypeScript happy here because although we
    // have `origGetInitialProps` in this HOC and the App component,
    // we don't actually build the App components `origGetInitialProps` ourselves
    // (even though we know, for certain, that it's set!)
    // So we will ignore this warning because it's not actually a problem
    return (
      <ApolloProvider client={apolloClient}>
        {/* @ts-ignore */}
        <App {...props} />
      </ApolloProvider>
    );
  };

  Apollo.getInitialProps = async (appContext: NextPageContextApp) => {
    const { Component, router, AppTree, ctx: ctx } = appContext;
    const accessTokenHolder = new TokenHolder();
    const isBot = isBotRequest(ctx);
    const apollo = initializeApollo({
      ctx,
      tokenHolder: accessTokenHolder,
      isBot,
    });
    if (
      ctx?.req &&
      ctx.res &&
      !isBotRequest(ctx) &&
      !/^\/login_callback/.test(ctx.pathname)
    ) {
      await refreshTokenOrMigrate(ctx, apollo, accessTokenHolder);
    }
    // Add apolloClient to NextPageContext & NextAppContext.
    // This allows us to consume the apolloClient inside our
    // custom `getInitialProps({ apolloClient })`.
    const inAppContext = Boolean(ctx);
    appContext.apolloClient = apollo;
    if (inAppContext) {
      ctx.apolloClient = apollo;
    }

    // Write parental lock and isAeon to local cache at top level
    // Clientside resolvers defined in initApollo.ts will query these fields
    // to add them to the userInfo object
    // We put this here because it must run before the leanback query is ran
    if (typeof window === 'undefined') {
      const cookies = parseCookies(ctx);
      const parentalLock = cookies._frc === 'R15';
      const isAeon =
        !!ctx?.req?.headers?.host?.match(/^aeoncinema-video/) ||
        !!cookies._aeon;
      apollo.cache.writeQuery({
        query: BASE_CACHE_QUERY,
        data: {
          parentalLock,
          isAeon,
        },
      });
    }

    let leanbackData: LeanbackGenre[] = [];
    let appProps: AppInitialProps = {
      pageProps: null,
    };

    const isBrowser = typeof window !== 'undefined';

    if (Component.name !== 'ErrorPage') {
      appProps = await App.getInitialProps(appContext);

      const isFromShortcutMode = checkIsFromShortcutMode(router.query);

      /**
       * Fetch and prepare the leanback data before first render.
       *
       * Further requests for leanback data (like from the leanback component itself)
       * will come from the apollo cache.
       */
      if ((isBrowser || ctx.req) && !isFromShortcutMode) {
        const { data } = await apollo.query<cosmo_getLeanbackGenresQuery>({
          query: GET_LEANBACK_GENRES,
        });
        leanbackData = prepareLeanbackData(data);
      }

      // If we are on the server, use getDataFromTree to make necessary API
      // requests for the SSR (only for bots).
      if (!isBrowser && isBot) {
        try {
          await getDataFromTree(
            <AppTree
              {...appProps}
              router={router}
              Component={Component}
              // The pattern below is to work around the strict typing
              // of AppTree
              {...{
                apolloClient: apollo,
                leanbackData,
              }}
            />
          );
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error while running `getDataFromTree`', error);
        }
      }
    }
    const apolloState = apollo.cache.extract();

    return {
      ...appProps,
      apolloState,
      leanbackData,
      isBot,
    };
  };

  return Apollo;
};

export default withApollo;
