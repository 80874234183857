import { useEffect, useRef, useState } from 'react';
import { cosmo_platformInfoQuery } from '../../__generated__/globalTypes';
import { LooseUserInfo } from '../../shared/LLPUserInfoContext';
import TreasureDataClientInterface from './TreasureDataClientInterface';

async function importTreasureDataClient() {
  return await import('./TreasureDataClient');
}

export function useTreasureDataClient(
  {
    userInfo,
    platformInfo,
  }: {
    userInfo?: LooseUserInfo;
    platformInfo?: cosmo_platformInfoQuery['platformInfo'];
  } = {},
  isPending = false
): TreasureDataClientInterface | undefined {
  const mountedRef = useRef(true);
  const [treasureDataClient, setTreasureDataClient] =
    useState<TreasureDataClientInterface>();

  useEffect(() => {
    mountedRef.current = true;
    importTreasureDataClient().then(({ TreasureDataClient }) => {
      if (!isPending) {
        // To make sure it's executed before component gets umounted
        if (mountedRef.current) {
          setTreasureDataClient(new TreasureDataClient(userInfo, platformInfo));
        }
      }
    });

    return () => {
      mountedRef.current = false;
    };
  }, [isPending, platformInfo, userInfo]);

  return treasureDataClient;
}
