import React from 'react';
import styled from 'styled-components';
import { IMAGE_ASPECT } from '../../../../../shared/components/TitleCard/constants';
import { DEVICE } from '../../../../../shared/styles';
import { COLORS } from '../../../../../shared/styles/theme';
import { Nullable } from '../../../../../utils/Nullable';

export type StackedItemType = 'video' | 'book';

export interface StackedItemProps {
  titleText: string;
  contentText: Nullable<string>;
  parentCode?: string;
  isPreorderable?: boolean;
  isPreordered?: boolean;
  isNew?: boolean;
  isFree?: boolean;
  isPurchased?: boolean;
  maxLines?: number;
  saleText?: Nullable<string>;
  publicStartText?: Nullable<string>;
  thumbnailUrl?: Nullable<string>;
  isComplete?: boolean;
  progress?: number;
  limitDay?: Nullable<string>;
  onPlayClick?:
    | ((e: React.KeyboardEvent | React.MouseEvent) => void)
    | ((e: React.KeyboardEvent | React.MouseEvent) => Promise<void>);
  playComponentName?: string;
  detailComponentName?: string;
  hasSample?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  padding: 24px 0;
  @media ${DEVICE.mobileWide} {
    padding: 16px 0;
  }
`;

export const TextContent = styled.div<{ type: StackedItemType }>`
  width: calc(100% - ${({ type }) => (type === 'video' ? 256 : 128)}px);
  padding-right: 3%;
  margin-right: auto;
  font-size: 10px;

  @media ${DEVICE.mobileWide} {
    width: calc(100% - ${({ type }) => (type === 'video' ? 170 : 92)}px);
  }
`;

export const ImageContent = styled.div<{ type: StackedItemType }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
  width: ${({ type }) => (type === 'video' ? 224 : 136)}px;
  font-size: 10px;

  @media ${DEVICE.mobileWide} {
    width: ${({ type }) => (type === 'video' ? 160 : 100)}px;
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 600;

  &:not(:first-child) {
    margin-top: 4px;
  }

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

export const BadgeRow = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`;

export const Introduction = styled.div`
  font-size: 14px;
  color: ${COLORS.white_75};
  font-weight: 300;
  margin-top: 8px;

  @media ${DEVICE.mobileWide} {
    margin-top: 4px;
    font-size: 12px;
  }
`;

export const ImageContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 4px;
`;

export const ImageContentText = styled.div`
  font-size: 12px;
  color: ${COLORS.white_75};
`;

export const FooterText = styled.div`
  font-size: 14px;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

export const TextPlaceholder = styled.div<{ widthPercent: number }>`
  background: ${COLORS.charcoal};
  height: 22px;
  width: ${({ widthPercent }) => widthPercent}%;
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const ImagePlaceholder = styled.div<{ type: StackedItemType }>`
  background: ${COLORS.charcoal};
  padding-bottom: ${({ type }) => 100 / IMAGE_ASPECT[type]}%;
`;
