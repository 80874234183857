import { gql } from '@apollo/client';
import {
  bookDetailFragment,
  bookSakuhinFragment,
  bookTitleCardFragment,
} from '../../../shared/fragments/books';
import { pageInfoFragment } from '../../../shared/fragments/pageInfo';
import { titleCardFragment } from '../../../shared/fragments/videos';

export const GET_BOOK = gql`
  query cosmo_bookTitleDetail(
    $bookSakuhinCode: ID!
    $bookCode: ID
    $viewBookCode: String
    $featurePieceCode: String
    $bookListPageSize: Int
    $bookListChapterPageSize: Int
  ) {
    bookTitle(
      bookSakuhinCode: $bookSakuhinCode
      bookCode: $bookCode
      viewBookCode: $viewBookCode
      featurePieceCode: $featurePieceCode
      bookListPageSize: $bookListPageSize
      bookListChapterPageSize: $bookListChapterPageSize
    ) {
      ...BookSakuhinDetail
      bookList {
        ...BookDetail
        isLatestBook
      }
      bookSakuhinChapterLink {
        bookSakuhinCode
        isNew
      }
      menuList {
        code
        name
      }
    }
  }
  ${bookSakuhinFragment}
  ${bookDetailFragment}
`;

export const GET_BOOK_TITLE_BOOKS = gql`
  query cosmo_bookTitleBooks(
    $bookSakuhinCode: ID!
    $booksPage: Int
    $booksPageSize: Int
  ) {
    bookTitle_books(
      code: $bookSakuhinCode
      page: $booksPage
      pageSize: $booksPageSize
    ) {
      pageInfo {
        ...PageInfo
      }
      books {
        ...BookDetail
      }
    }
  }
  ${pageInfoFragment}
  ${bookDetailFragment}
`;

export const GET_BOOK_RELATED_STUFFS = gql`
  query cosmo_bookTitleDetailRelatedStuffs($bookSakuhinCode: ID!) {
    bookTitle_relatedBooks(code: $bookSakuhinCode, pageSize: 20, page: 1) {
      pageInfo {
        ...PageInfo
      }
      books {
        ...BookTitleCard
      }
    }
    bookTitle_recommendedBooks(
      bookSakuhinCode: $bookSakuhinCode
      pageSize: 20
      page: 1
    ) {
      pageInfo {
        ...PageInfo
      }
      books {
        ...BookTitleCard
      }
      trackingCode
    }
    bookTitle_relatedTitles(code: $bookSakuhinCode, pageSize: 20, page: 1) {
      pageInfo {
        ...PageInfo
      }
      titles {
        ...TitleCard
      }
    }
  }
  ${pageInfoFragment}
  ${bookTitleCardFragment}
  ${titleCardFragment}
`;

export const GET_BOOK_RELATED_TITLES = gql`
  query cosmo_bookTitleDetailRelatedTitles($bookSakuhinCode: ID!) {
    bookTitle_relatedTitles(code: $bookSakuhinCode, pageSize: 20, page: 1) {
      pageInfo {
        ...PageInfo
      }
      titles {
        ...TitleCard
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
`;

export const GET_BOOK_CATEGORY_LIST = gql`
  query cosmo_bookCategoryList(
    $menuCodeList: [ID!]!
    $sortOrder: PortalSortOrder!
    $pageSize: Int!
    $page: Int!
    $excludeBookSakuhinCode: String
  ) {
    bookCategoryList(
      menuCodeList: $menuCodeList
      sortOrder: $sortOrder
      pageSize: $pageSize
      page: $page
      excludeBookSakuhinCode: $excludeBookSakuhinCode
    ) {
      menuCode
      books {
        ...BookTitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${bookTitleCardFragment}
`;

export const GET_BOOK_PERSON_LIST = gql`
  query cosmo_bookPersonList(
    $personCodeList: [ID!]!
    $sortOrder: PortalSortOrder!
    $page: Int!
    $pageSize: Int!
    $excludeBookSakuhinCode: String
    $onlyChapter: Boolean
  ) {
    bookPersonList(
      personCodeList: $personCodeList
      sortOrder: $sortOrder
      page: $page
      pageSize: $pageSize
      excludeBookSakuhinCode: $excludeBookSakuhinCode
      onlyChapter: $onlyChapter
    ) {
      personCode
      books {
        ...BookTitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${bookTitleCardFragment}
`;

export const GET_AUTHOR_ORIGINAL_BOOKS = gql`
  query cosmo_authorOriginalBooks($personCode: String!) {
    originalBooks(personCode: $personCode, page: 1, pageSize: 15) {
      books {
        ...BookTitleCard
      }
    }
  }
  ${bookTitleCardFragment}
`;

export const UPDATE_BOOK_TITLE_BOOKMARK = gql`
  mutation cosmo_updateBookTitleFavorite(
    $bookSakuhinId: ID!
    $type: FavoriteTypes!
  ) {
    updateBookTitleFavorite(bookSakuhinId: $bookSakuhinId, type: $type) {
      code
      isFavorite
    }
  }
`;

export const UPDATE_BOOK_TITLE_EVALUATION = gql`
  mutation cosmo_updateBookTitleEvaluation(
    $bookSakuhinId: ID!
    $type: EvaluationTypes!
  ) {
    updateBookTitleEvaluation(bookSakuhinId: $bookSakuhinId, type: $type) {
      code
      name
      rate
      userRate
    }
  }
`;
