import { gql } from '@apollo/client';

export const UPDATE_VIDEO_FAVORITE = gql`
  mutation cosmo_updateVideoFavorite($titleCode: ID!, $type: FavoriteTypes!) {
    webfront_updateTitleStageFavorite(id: $titleCode, type: $type) {
      id
      bookmarkStatus
    }
  }
`;

export const UPDATE_BOOK_FAVORITE = gql`
  mutation cosmo_updateBookFavorite(
    $bookSakuhinId: ID!
    $type: FavoriteTypes!
  ) {
    updateBookTitleFavorite(bookSakuhinId: $bookSakuhinId, type: $type) {
      code
      isFavorite
    }
  }
`;
