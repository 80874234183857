import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE } from '../../styles';
import AkamaiImage from '../Common/AkamaiImage';
import Icon, { Icons } from '../Common/Icon';
import LegacyIcon, { LegacyIcons } from '../Common/Icon/LegacyIcon';

type PlayCardType = 'video' | 'book';

interface BaseProps {
  thumbnailUrl: string;
  titleName: string;
  type?: PlayCardType;
  isPreorderable?: boolean;
  isPreordered?: boolean;
  isLazy?: boolean;
}

type PlayCardTitleOverlayProps = Pick<BaseProps, 'type'>;

const PlayCardContainer = styled.div<PlayCardTitleOverlayProps>`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: ${({ type }) => (type === 'video' ? 56.25 : 140.2597403)}%;
  color: ${({ theme }) => theme.playCard.text};
  cursor: pointer;
  ${({ type }) =>
    type !== 'book' &&
    css`
      border-radius: 10px;
      overflow: hidden;
    `}
  img {
    position: absolute;
    width: 100%;
  }
`;

const PlayIconContainer = styled.div<PlayCardTitleOverlayProps>`
  text-align: center;
  width: 4.8em;
  height: 4.8em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.15s ease-in;
  color: ${({ theme }) => theme.playCard.text};
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.playCard.playButtonBackground};

  svg {
    top: 0;
  }

  ${PlayCardContainer}:hover &,
  ${PlayCardContainer}:focus & {
    color: ${({ theme }) => theme.playCard.playButtonHovered};
    border: 1px solid ${({ theme }) => theme.playCard.playButtonHovered};
    background-color: ${({ theme }) =>
      theme.playCard.playButtonHoveredBackground};
  }

  @media ${DEVICE.mobileWide} {
    width: 3.2em;
    height: 3.2em;
  }
`;

const LegacyPlayIcon = styled(LegacyIcon)`
  width: 40%;
  height: 40%;
`;
const PlayIcon = styled(Icon)`
  width: 2.8em;
  height: 2.8em;

  @media ${DEVICE.mobileWide} {
    width: 1.87em;
    height: 1.87em;
  }
`;
const PlayCardContainerOverlay = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.15s ease-in;
`;

const PlayCard: React.FC<BaseProps> = ({
  thumbnailUrl,
  titleName,
  type = 'video',
  isPreorderable,
  isPreordered,
  isLazy = false,
}) => {
  const playCardImageAvailableSizes =
    type === 'video' ? [168, 336] : [126, 252];
  const playCardImageConfig = {
    SD: {
      width: type === 'video' ? 282 : 160,
    },
    TABLET: {
      width: type === 'video' ? 231 : 154,
    },
    MOBILE_WIDE: {
      width: type === 'video' ? 168 : 126,
    },
  };

  return (
    <PlayCardContainer type={type}>
      <AkamaiImage
        src={thumbnailUrl}
        alt={titleName}
        availableSizes={playCardImageAvailableSizes}
        sizeConfig={playCardImageConfig}
        letterbox={type === 'book' ? '5:7' : ''}
        isLazy={isLazy}
      />
      <PlayIconContainer type={type}>
        {type === 'video' ? (
          <LegacyPlayIcon name={LegacyIcons.PLAY} />
        ) : isPreorderable ? (
          isPreordered ? (
            <PlayIcon name={Icons.PREORDERED} />
          ) : (
            <PlayIcon name={Icons.PREORDER} />
          )
        ) : (
          <LegacyPlayIcon name={LegacyIcons.READ} />
        )}
      </PlayIconContainer>
      <PlayCardContainerOverlay />
    </PlayCardContainer>
  );
};

export default PlayCard;
