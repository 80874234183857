import React, { ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  BookTitleCardFragment,
  LiveCardFragment,
  TitleCardFragment,
} from '../../../../__generated__/globalTypes';
import Icon, { Icons } from '../../../../shared/components/Common/Icon';
import { isPoint } from '../../../../shared/components/PointPriceBadge';
import { ListBookCardMobile } from '../../../../shared/components/TitleCard/Book';
import TitleCardLite from '../../../../shared/components/TitleCard/Lite';
import LiveTitleCard from '../../../../shared/components/TitleCard/Live';
import { ListVideoCardMobile } from '../../../../shared/components/TitleCard/Video';
import TitleLink from '../../../../shared/components/TitleLink';
import { UrlQueryParams } from '../../../../shared/constants';
import { globalMessages } from '../../../../shared/constants/messages';
import useMedia from '../../../../shared/hooks/useMedia';
import { DEVICE } from '../../../../shared/styles';
import { handleOnlyOnEnter } from '../../../../utils';
import {
  getKafkaLogRowIndex,
  getLiveOnAirStatus,
  hasCatchup,
  selectNotice,
} from '../../../Genre/utils';
import type { BookTitleDetailRelatedBookTitleListTitleCardLog } from '../../../Log/__types__/bookTitleDetail-relatedBookTitleList-titleCard';
import type { BookTitleDetailRelatedVideoTitleListTitleCardLog } from '../../../Log/__types__/bookTitleDetail-relatedVideoTitleList-titleCard';
import type { BookTitleDetailTitleDetailBookBlockMoreLog } from '../../../Log/__types__/bookTitleDetail-titleDetailBookBlock-more';
import type { BookTitleDetailTitleDetailBookBlockTitleCardLog } from '../../../Log/__types__/bookTitleDetail-titleDetailBookBlock-titleCard';
import type { LiveTitleDetailRelatedLiveTitleListTitleCardLog } from '../../../Log/__types__/liveTitleDetail-relatedLiveTitleList-titleCard';
import type { LiveTitleDetailRelatedVideoTitleListTitleCardLog } from '../../../Log/__types__/liveTitleDetail-relatedVideoTitleList-titleCard';
import type { VideoTitleDetailRecommendVideoTitleListTitleCardLog } from '../../../Log/__types__/videoTitleDetail-recommendVideoTitleList-titleCard';
import type { VideoTitleDetailRelatedBookTitleListTitleCardLog } from '../../../Log/__types__/videoTitleDetail-relatedBookTitleList-titleCard';
import type { VideoTitleDetailRelatedVideoTitleListTitleCardLog } from '../../../Log/__types__/videoTitleDetail-relatedVideoTitleList-titleCard';
import { getKafkaClient } from '../../../Log/kafkaClient';
import { PLAYBACK_STATUS_FLAG } from '../../../videoPlayer/constants';
import {
  Heading,
  HeadingContainer,
  RelatedSectionContainer,
  ShowMoreLink,
  TitleListContainer,
} from './Components';

const BLOCK_CLASS_NAME = 'related_section';

const messages = defineMessages({
  relatedWorkShowAllItemsLinkText: {
    id: 'title.relatedWork.showAllItemsLink.text',
    defaultMessage: 'すべて表示',
    description: 'related-work show-all works link-text',
  },
});

const GRID_CONST_VIDEO = {
  [DEVICE.exceptTablet]: {
    COLUMNS: 4,
    ROWS: 2,
  },
  [DEVICE.exceptMobileWide]: {
    COLUMNS: 3,
    ROWS: 2,
  },
  [DEVICE.mobileWide]: {
    COLUMNS: 1,
    ROWS: 5,
  },
};
const GRID_CONST_BOOK = {
  [DEVICE.exceptTablet]: {
    COLUMNS: 6,
    ROWS: 2,
  },
  [DEVICE.exceptMobileWide]: {
    COLUMNS: 4,
    ROWS: 2,
  },
  [DEVICE.mobileWide]: {
    COLUMNS: 1,
    ROWS: 5,
  },
};
const GRID_CONST_CHAPTER = {
  [DEVICE.exceptTablet]: {
    COLUMNS: 6,
    ROWS: 1,
  },
  [DEVICE.exceptMobile]: {
    COLUMNS: 4,
    ROWS: 1,
  },
  [DEVICE.mobile]: {
    COLUMNS: 3,
    ROWS: 1,
  },
};

interface BaseProps {
  sectionHeading: string;
  totalItemCount: number;
  showMoreLinkProps?: ComponentProps<typeof ShowMoreLink>;
  trackingBlockCode?: string;
  sectionName?: string;
}
interface RelatedVideoProps extends BaseProps {
  contentType: 'video';
  items: TitleCardFragment[];
  itemComponentName:
    | VideoTitleDetailRecommendVideoTitleListTitleCardLog['event']['target']
    | VideoTitleDetailRelatedVideoTitleListTitleCardLog['event']['target']
    | BookTitleDetailRelatedVideoTitleListTitleCardLog['event']['target']
    | LiveTitleDetailRelatedVideoTitleListTitleCardLog['event']['target'];
}

interface RelatedBookProps extends BaseProps {
  contentType: 'book';
  items: BookTitleCardFragment[];
  itemComponentName:
    | VideoTitleDetailRelatedBookTitleListTitleCardLog['event']['target']
    | BookTitleDetailRelatedBookTitleListTitleCardLog['event']['target'];
}

interface RecommendedBookProps extends BaseProps {
  sourceBookSakuhinCode: string;
  sourceBookCode: string;
  contentType: 'book';
  items: BookTitleCardFragment[];
  itemComponentName: BookTitleDetailTitleDetailBookBlockTitleCardLog['event']['target'];
  trackingBlockCode: BookTitleDetailTitleDetailBookBlockTitleCardLog['event']['detail']['tracking_block_code'];
}

interface RecommendedChapterProps extends BaseProps {
  sourceBookSakuhinCode: string;
  sourceBookCode: string;
  contentType: 'chapter';
  items: BookTitleCardFragment[];
  itemComponentName: BookTitleDetailTitleDetailBookBlockTitleCardLog['event']['target'];
  trackingBlockCode: '';
}

interface RelatedLiveProps extends BaseProps {
  contentType: 'live';
  items: LiveCardFragment[];
  itemComponentName: LiveTitleDetailRelatedLiveTitleListTitleCardLog['event']['target'];
}

const RelatedSection: React.FC<
  | RelatedVideoProps
  | RelatedBookProps
  | RecommendedBookProps
  | RecommendedChapterProps
  | RelatedLiveProps
> = (props) => {
  const {
    items,
    contentType,
    sectionHeading,
    itemComponentName,
    trackingBlockCode,
    showMoreLinkProps,
    sectionName,
  } = props;
  const intl = useIntl();
  const gridConst =
    contentType === 'video' || contentType === 'live'
      ? GRID_CONST_VIDEO
      : contentType === 'chapter'
      ? GRID_CONST_CHAPTER
      : GRID_CONST_BOOK;
  const gridProp = useMedia(
    Object.keys(gridConst),
    Object.values(gridConst),
    Object.values(gridConst)[0]
  );
  const maxItemsCount = gridProp.COLUMNS * gridProp.ROWS;
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );

  return (
    <RelatedSectionContainer data-ucn={sectionName}>
      <HeadingContainer>
        <Heading>{sectionHeading}</Heading>
        {showMoreLinkProps && (
          <ShowMoreLink
            {...showMoreLinkProps}
            data-ucn="relatedSection-more-btn"
            onClick={() => {
              if ('sourceBookSakuhinCode' in props) {
                const {
                  sourceBookSakuhinCode,
                  sourceBookCode,
                  trackingBlockCode,
                } = props;
                getKafkaClient().trackUserClickDimension1<BookTitleDetailTitleDetailBookBlockMoreLog>(
                  'bookTitleDetail-titleDetailBookBlock-more',
                  {
                    book_sakuhin_code: sourceBookSakuhinCode,
                    book_code: sourceBookCode,
                    // "index" means this RelatedSection's index among all the RelatedSections
                    index: getKafkaLogRowIndex(
                      BLOCK_CLASS_NAME,
                      trackingBlockCode
                    ),
                    tracking_block_code: trackingBlockCode,
                  }
                );
              }
            }}
          >
            {!(isMobile && contentType === 'chapter') &&
              intl.formatMessage(messages.relatedWorkShowAllItemsLinkText)}
            <Icon name={Icons.PUSH_LINK} />
          </ShowMoreLink>
        )}
      </HeadingContainer>
      <TitleListContainer
        className={BLOCK_CLASS_NAME}
        id={trackingBlockCode}
        columnCount={gridProp.COLUMNS}
      >
        {contentType === 'video'
          ? items.slice(0, maxItemsCount).map((title, index) => {
              const trackRelatedOrRecommendedVideoClick = () => {
                getKafkaClient().trackUserClickDimension1<
                  | VideoTitleDetailRelatedVideoTitleListTitleCardLog
                  | VideoTitleDetailRecommendVideoTitleListTitleCardLog
                  | BookTitleDetailRelatedVideoTitleListTitleCardLog
                  | LiveTitleDetailRelatedVideoTitleListTitleCardLog
                >(itemComponentName, { index, sakuhin_code: title.id });
              };

              const query = {
                [UrlQueryParams.PLAYBACK_STATUS_FLAG]:
                  PLAYBACK_STATUS_FLAG.SERIES_RELATED_WORK.toString(),
              };

              return (
                <TitleLink
                  key={`key-${title.id}-${index}`}
                  data-ucn="relatedSection-title-link"
                  type="video"
                  titleCode={title.id}
                  onKeyDown={(e) => {
                    handleOnlyOnEnter(e, trackRelatedOrRecommendedVideoClick);
                  }}
                  onClick={trackRelatedOrRecommendedVideoClick}
                  query={query}
                >
                  {isMobile ? (
                    <ListVideoCardMobile
                      rate={title.rate}
                      catchphrase={title.catchphrase}
                      titleName={title.titleName}
                      thumbnailUrl={`//${title.thumbnail?.standard}`}
                      isNew={title.isNew}
                      isPoint={isPoint(title)}
                      nfreeBadge={title.nfreeBadge}
                      hasDub={title.hasDub}
                      hasSub={title.hasSubtitle}
                      isLazy
                      isOriginal={title.isOriginal}
                      isOnlyOn={title.exclusive.isOnlyOn}
                      price={title.minimumPrice || undefined}
                      hasMultiprice={!!title.hasMultiprice}
                    />
                  ) : (
                    <TitleCardLite
                      type="video"
                      titleName={title.titleName ?? ''}
                      thumbnailUrl={`//${title.thumbnail?.standard}`}
                      isLazy
                      isNew={title.isNew}
                      isPoint={isPoint(title)}
                      isOnlyOn={title.exclusive.isOnlyOn}
                    />
                  )}
                </TitleLink>
              );
            })
          : contentType === 'book' || contentType === 'chapter'
          ? items.slice(0, maxItemsCount).map((bookTitle, index) => {
              if (!bookTitle.book) {
                return <></>;
              }

              const bookCode = bookTitle.book.code;
              const trackRelatedBookClick = () => {
                if ('sourceBookSakuhinCode' in props) {
                  const { sourceBookSakuhinCode, trackingBlockCode } = props;
                  getKafkaClient().trackUserClickDimension2<BookTitleDetailTitleDetailBookBlockTitleCardLog>(
                    'bookTitleDetail-titleDetailBookBlock-titleCard',
                    {
                      source_book_sakuhin_code: sourceBookSakuhinCode,
                      book_code: bookCode,
                      book_sakuhin_code: bookTitle.sakuhinCode,
                      column_index: index,
                      // row_index means this RelatedSection's index among all the RelatedSections
                      row_index: getKafkaLogRowIndex(
                        BLOCK_CLASS_NAME,
                        trackingBlockCode
                      ),
                      tracking_block_code: trackingBlockCode,
                    }
                  );
                } else {
                  getKafkaClient().trackUserClickDimension1<
                    | VideoTitleDetailRelatedBookTitleListTitleCardLog
                    | BookTitleDetailRelatedBookTitleListTitleCardLog
                  >(props.itemComponentName, {
                    index,
                    book_sakuhin_code: bookTitle.sakuhinCode,
                    book_code: bookCode,
                  });
                }
              };

              let author = '';
              if (bookTitle.book.credits.length > 0) {
                author =
                  bookTitle.book.credits.length > 1
                    ? `${bookTitle.book.credits[0].penName}...`
                    : bookTitle.book.credits[0].penName || '';
              }

              const bookMagazine = bookTitle.book.publishedIn.reduce(
                (reduction, value) => {
                  const currentText = value.name || '';
                  return !reduction
                    ? currentText
                    : `${reduction} ${currentText}`;
                },
                ''
              );

              const isEpisodeSpecified = bookTitle.bookViewCode === 'BOOK';
              const titleName = isEpisodeSpecified
                ? bookTitle.book.name
                : bookTitle.name;
              const numberOfFree = bookTitle.freeBookNum
                ? intl.formatMessage(
                    bookTitle.isChapter
                      ? globalMessages.freeChapterNum
                      : globalMessages.freeBookNum,
                    {
                      freeBookNum: bookTitle.freeBookNum,
                    }
                  )
                : '';
              return (
                <TitleLink
                  key={`key-${bookTitle.sakuhinCode}-${index}`}
                  data-ucn="relatedSection-title-link"
                  type="book"
                  titleCode={bookTitle.sakuhinCode}
                  optionalCode={{
                    bookCode: bookTitle.book.code,
                    bookViewCode: bookTitle.bookViewCode,
                    featurePieceCode: bookTitle.featurePieceCode ?? undefined,
                  }}
                  onKeyDown={(e) => {
                    handleOnlyOnEnter(e, trackRelatedBookClick);
                  }}
                  onClick={trackRelatedBookClick}
                >
                  {isMobile && contentType === 'book' ? (
                    <ListBookCardMobile
                      titleName={titleName}
                      thumbnailUrl={`//${bookTitle.book.thumbnail.standard}`}
                      bookType={bookTitle.book.mediaType.code}
                      isNew={!!bookTitle.isNew}
                      isPreorderable={!!bookTitle.book.isPreorderable}
                      numberOfFree={numberOfFree}
                      publicStartDateTime={
                        bookTitle.book.publicStartDateTime
                          ? intl.formatMessage(globalMessages.delivery, {
                              date: intl.formatDate(
                                bookTitle.book.publicStartDateTime,
                                {
                                  month: 'long',
                                  day: 'numeric',
                                }
                              ),
                            })
                          : ''
                      }
                      rate={bookTitle.rate}
                      isOnSale={!!bookTitle.isSale}
                      publisher={bookTitle.book.publisher?.name ?? ''}
                      minimumPrice={bookTitle.minPrice}
                      hasMultiPrice={bookTitle.hasMultiplePrices}
                      totalBookNum={bookTitle.totalBookNum}
                      isOriginal={bookTitle.isUnextOriginal ?? undefined}
                      isYomihodai={
                        !!bookTitle.paymentBadgeList.find(
                          (b) => b.code === 'SVOD'
                        )
                      }
                      author={author}
                      bookMagazine={bookMagazine}
                      isLazy
                      subgenreTagList={bookTitle.subgenreTagList}
                      isDailyFree={!!bookTitle.isBookSakuhinTicketAvailable}
                      isDailyFreePlus={!!bookTitle.isBookPlusTicketAvailable}
                      isChapter={!!bookTitle.isChapter}
                      isEpisodeSpecified={isEpisodeSpecified}
                    />
                  ) : (
                    <TitleCardLite
                      type="book"
                      titleName={titleName}
                      isNew={!!bookTitle.isNew}
                      thumbnailUrl={`//${bookTitle.book.thumbnail.standard}`}
                      isDailyFree={!!bookTitle.isBookSakuhinTicketAvailable}
                      isDailyFreePlus={!!bookTitle.isBookPlusTicketAvailable}
                      numberOfFree={numberOfFree}
                      isLazy
                    />
                  )}
                </TitleLink>
              );
            })
          : items.slice(0, maxItemsCount).map((live, index) => {
              const liveNotice = selectNotice(live);
              return (
                <TitleLink
                  key={`${live.id}-${index}`}
                  data-ucn="relatedSection-title-link"
                  type="live"
                  titleCode={live.id}
                  onClick={() => {
                    getKafkaClient().trackUserClickDimension1<LiveTitleDetailRelatedLiveTitleListTitleCardLog>(
                      'liveTitleDetail-relatedLiveTitleList-titleCard',
                      {
                        index,
                        live_code: live.id,
                      }
                    );
                  }}
                >
                  {isMobile ? (
                    <LiveTitleCard
                      name={live.name}
                      thumbnailUrl={`//${liveNotice?.thumbnail?.standard}`}
                      isPoint={isPoint(live)}
                      deliveryStartDateTime={live.deliveryStartDateTime}
                      isOnlyOn={live.isOnlyOn}
                      minimumPrice={
                        live.tickets.some((ticket) => ticket.price)
                          ? Math.min(
                              ...live.tickets.map((ticket) => ticket.price)
                            )
                          : undefined
                      }
                      hasMultiplePrice={live.tickets.length > 1}
                      hasCatchup={hasCatchup(live)}
                      liveOnAirStatus={getLiveOnAirStatus(live)}
                      isLazy
                      isMobileList
                      componentName={itemComponentName}
                    />
                  ) : (
                    <TitleCardLite
                      type="live"
                      name={live.name}
                      thumbnailUrl={`//${liveNotice?.thumbnail?.standard}`}
                      isLazy
                      isOnlyOn={live.isOnlyOn}
                      isPoint={isPoint(live)}
                    />
                  )}
                </TitleLink>
              );
            })}
      </TitleListContainer>
    </RelatedSectionContainer>
  );
};

export default RelatedSection;
