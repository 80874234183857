import styled from 'styled-components';
import { DEVICE } from '../../styles/constants';

const BackgroundPattern = styled.div`
  position: absolute;
  z-index: -1000;
  background: linear-gradient(
      90deg,
      rgb(0 10 23 / 95%),
      transparent 65%,
      rgb(0 10 23 / 95%)
    ),
    linear-gradient(rgb(0 10 23 / 40%), rgb(0 10 23 / 100%)),
    no-repeat top/cover url('/images/web-bg.webp');
  top: 0;
  width: 100%;
  height: 296px;

  @media ${DEVICE.sd} {
    height: 320px;
    background: linear-gradient(
        90deg,
        rgb(0 10 23 / 95%),
        transparent 65%,
        rgb(0 10 23 / 95%)
      ),
      linear-gradient(rgb(0 10 23 / 40%), rgb(0 10 23 / 100%)),
      no-repeat top/cover url('/images/mobile-bg.webp');
  }
`;

export default BackgroundPattern;
