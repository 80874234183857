/**
 *  @see https://wiki.unext-info.jp/pages/viewpage.action?pageId=57624736
 */
export const HEADER_CLOSE = 'bookTitleDetail-header-close';
export const STAGE_MYLIST = 'bookTitleDetail-stage-mylist';
export const STAGE_RATE = 'bookTitleDetail-stage-rate';
export const STAGE_SHARE = 'bookTitleDetail-stage-share';
export const STAGE_READ = 'bookTitleDetail-stage-read';
export const STAGE_SAMPLE = 'bookTitleDetail-stage-sample';
export const CAST_LIST_CASE_NAME = 'bookTitleDetail-castList-castName';
export const RELATED_VIDEO_TITLE_LIST_TITLE_CARD =
  'bookTitleDetail-relatedVideoTitleList-titleCard';
export const RELATED_BOOK_TITLE_LIST_TITLE_CARD =
  'bookTitleDetail-relatedBookTitleList-titleCard';
export const RECOMMENDED_BOOK_TITLE_LIST_TITLE_CARD =
  'bookTitleDetail-titleDetailBookBlock-titleCard';
export const STAGE_SIGNUP = 'bookTitleDetail-stage-signup';
export const EPISODE_LIST_READ = 'bookTitleDetail-episodeList-read';
export const EPISODE_LIST_PAGER_TAB = 'bookTitleDetail-episodeList-pagerTab';
export const TITLE_SPOT = 'bookTitleDetail--titleSpot';

//TODO: WF-6446 Add below once features are done
export const STAGE_RESERVE = 'bookTitleDetail-stage-reserve';
