import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { useChain, useSpring, useSpringRef } from 'react-spring';
import {
  NavButton,
  SiteOverlay,
  SiteOverlayContent,
  TitleModalContent,
} from '.';
import { UrlQueryParams } from '../../../shared/constants';
import { useLockBodyScroll } from '../../../shared/hooks/useLockBodyScroll';
import { mergeQueryString } from '../../../utils/routeHelper';
import { HEADER_CLOSE as liveHeaderClose } from '../../Log/ComponentName/liveTitleDetail';
import BookDetail from '../Book';
import BookList from '../BookList';
import LiveDetail from '../Live';
import VideoDetail from '../Video';

interface TitleModalProps {
  titleCode?: string;
  liveCode?: string;
  book?: { bookSakuhinCode: string; bookCode?: string };
  isSsr: boolean;
}

const TitleModal: React.FC<TitleModalProps> = ({
  titleCode,
  liveCode,
  book,
  isSsr,
}) => {
  const [isModalOpen, setModalOpen] = useState(
    !!titleCode || !!liveCode || !!book?.bookSakuhinCode
  );
  const router = useRouter();
  const navigateToModalBg = () => {
    if (!(router && router.pathname)) {
      return;
    }

    if (
      router.pathname === '/title/[videoTitleCode]' ||
      router.pathname === '/livedetail/[liveCode]' ||
      router.pathname === '/book/title/[bookSakuhinCode]' ||
      router.pathname === '/book/title/[bookSakuhinCode]/[bookCode]'
    ) {
      router.push('/', '/');
    } else {
      const newRoute = mergeQueryString(router, {
        [UrlQueryParams.TITLE_DETAIL_ID]: undefined,
        [UrlQueryParams.LIVE_CODE]: undefined,
        [UrlQueryParams.EPISODE_TAB]: undefined,
        [UrlQueryParams.BOOK_TITLE_DETAIL_ID]: undefined,
        [UrlQueryParams.BOOK_CODE]: undefined,
        [UrlQueryParams.BOOK_VIEW_CODE]: undefined,
        [UrlQueryParams.BOOK_EPISODE_LIST_FLAG]: undefined,
        [UrlQueryParams.BOOK_ALT_PARAMS]: undefined,
        // Clean up the previous feature related query
        [UrlQueryParams.FEATURE_CODE]: undefined,
        [UrlQueryParams.FEATURE_PIECE_CODE]: undefined,
        [UrlQueryParams.PLAYBACK_STATUS_FLAG]: undefined,
      });
      router.push(newRoute.pathname, newRoute.path, {
        scroll: false,
      });
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const fadeAnimationDisabled = isSsr && isModalOpen;
  const springRef = useSpringRef();
  const springProps = useSpring({
    ref: springRef,
    config: { duration: isModalOpen ? 100 : 10 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    reverse: !isModalOpen,
    onRest: isModalOpen ? undefined : navigateToModalBg,
    immediate: fadeAnimationDisabled,
  });
  const transitionRef = useSpringRef();
  const transitionProps = useSpring({
    ref: transitionRef,
    config: { duration: 200 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    reverse: !isModalOpen,
    immediate: fadeAnimationDisabled,
  });
  useChain(
    isModalOpen ? [springRef, transitionRef] : [transitionRef, springRef]
  );

  const [currentModalElement, setCurrentModalElement] =
    useState<HTMLDivElement | null>(null);

  useLockBodyScroll(true, undefined, currentModalElement);

  const isBookEpisodeList =
    router.query[UrlQueryParams.BOOK_EPISODE_LIST_FLAG] === 'true';

  const onClickBack = useCallback(() => {
    const newRoute = mergeQueryString(router, {
      [UrlQueryParams.BOOK_EPISODE_LIST_FLAG]: undefined,
      [UrlQueryParams.EPISODE_TAB]: undefined,
    });
    router.push(newRoute.pathname, newRoute.path, {
      scroll: false,
    });
  }, [router]);

  return (
    <SiteOverlay
      key={titleCode || liveCode || book?.bookCode || book?.bookSakuhinCode}
      onClick={closeModal}
      style={fadeAnimationDisabled ? {} : springProps}
      ref={(instance) => setCurrentModalElement(instance)}
    >
      <SiteOverlayContent>
        <TitleModalContent
          style={fadeAnimationDisabled ? {} : transitionProps}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
          }}
          data-ucn="TitleModal"
        >
          {isBookEpisodeList ? (
            <NavButton iconType="back" onClick={onClickBack} />
          ) : (
            <NavButton
              iconType="close"
              onClick={() => setModalOpen(false)}
              data-ucn={
                titleCode
                  ? 'titleModal-header-close'
                  : liveCode
                  ? liveHeaderClose
                  : 'titleModal-header-close'
              }
            />
          )}
          {!!titleCode && <VideoDetail titleCode={titleCode} isSsr={isSsr} />}
          {!!liveCode && <LiveDetail liveCode={liveCode} />}
          {!!book?.bookSakuhinCode && !isBookEpisodeList && (
            <BookDetail
              bookSakuhinCode={book.bookSakuhinCode}
              bookCode={book.bookCode}
              isSsr={isSsr}
            />
          )}
          {!!book?.bookSakuhinCode && isBookEpisodeList && (
            <BookList
              bookSakuhinCode={book.bookSakuhinCode}
              bookCode={book.bookCode}
              isSsr={isSsr}
            />
          )}
        </TitleModalContent>
      </SiteOverlayContent>
    </SiteOverlay>
  );
};

export default TitleModal;
