import { useQuery } from '@apollo/client';
import throttle from 'just-throttle';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
  cosmo_getLeanbackGenresQuery,
  cosmo_getMultiAccountUserInfoQuery,
  cosmo_getMultiAccountUserInfoQueryVariables,
} from '../../../__generated__/globalTypes';
import UnextLogo from '../../../assets/icons/logo/Logo_U-NEXT_Horizontal.svg';
import Search from '../../../domain/freeword/Search';
import type { NavigationMenuListMenuLog } from '../../../domain/Log/__types__/navigation-menuList-menu';
import {
  NAVIGATION_MENU_LIST_MENU,
  SIGNUP_BLOCK_LOGIN,
} from '../../../domain/Log/ComponentName/home';
import { getKafkaClient } from '../../../domain/Log/kafkaClient';
import useDelay from '../../../shared/hooks/useDelay';
import { navigateAfterPromiseHandler } from '../../../utils';
import { isActivePath } from '../../../utils/routeHelper';
import { GenreUrl, GlobalConfig, isValidGenreUrl } from '../../constants';
import useClientQuery from '../../hooks/useClientQuery';
import useHostInfo from '../../hooks/useHostInfo';
import {
  SCROLLBAR_WIDTH_PROPERTY,
  useLockBodyScroll,
} from '../../hooks/useLockBodyScroll';
import useLoginUrl from '../../hooks/useLoginUrl';
import useMedia from '../../hooks/useMedia';
import useOfflineCheck from '../../hooks/useOfflineCheck';
import { useUserInfo } from '../../hooks/useUserInfo';
import { useSearchInputContext } from '../../SearchInputContext';
import { DEVICE } from '../../styles';
import { HEADER_HEIGHT, HEADER_HEIGHT_SD } from '../../styles/constants';
import { COLORS } from '../../styles/theme';
import UserAgentContext from '../../UserAgentContext';
import { Button, ButtonLookDiv } from '../Common/Button';
import Icon, { GenreIcons, Icons } from '../Common/Icon';
import Hamburger from '../Hamburger';
import Menu, {
  GenreGroup,
  GroupDisplayName,
  IsOpenProps,
  LEANBACK_WIDTH,
} from '../Menu';
import { SmallBannerContext } from '../SmallBanner/SmallBannerContext';
import { GET_LEANBACK_GENRES, GET_MULTI_ACCOUNT_USERS_INFO } from './gql';
import messages from './messages';
import Points from './Points';

const SiteOverlay = styled.div<IsOpenProps>`
  transition: background-color 0.15s ease-in;
  background-color: transparent;

  @media ${DEVICE.sd} {
    ${({ isOpen, theme }) =>
      isOpen &&
      css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 8000;
        backdrop-filter: blur(5px);
        background-color: ${theme.leanback.overlay};
      `}
  }
`;

const SearchLayout = styled.div<{
  isSearchOpen?: boolean;
  isLocked?: boolean;
}>`
  display: flex;
  align-items: center;

  position: ${({ isLocked }) => (isLocked ? 'absolute' : 'fixed')};
  width: calc(100vw - ${LEANBACK_WIDTH}px);
  height: ${HEADER_HEIGHT}px;
  right: 0;
  top: 0;
  padding: 0 38px;
  padding-right: calc(38px + var(${SCROLLBAR_WIDTH_PROPERTY}, 0px));
  box-sizing: border-box;
  z-index: 9003;

  @media ${DEVICE.sd} {
    position: fixed;
    width: ${({ isSearchOpen }) => (isSearchOpen ? '100%' : 'auto')};
    height: ${HEADER_HEIGHT_SD}px;
    right: 0;
    padding: 0 12px;
    padding-right: calc(12px + var(${SCROLLBAR_WIDTH_PROPERTY}, 0px));
  }
`;

const MobileHeader = styled.div<{
  showBackground?: boolean;
  isMenuOpen?: boolean;
}>`
  ${({ isMenuOpen }) =>
    !isMenuOpen &&
    css`
      background: linear-gradient(
        -180deg,
        ${COLORS.jet_black} 0%,
        transparent 100%
      );
    `};
  transition: background 0.5s ease-in-out;
  position: fixed;
  z-index: 9001;
  top: 0;
  left: 0;
  right: 0;
  width: calc(100% - var(${SCROLLBAR_WIDTH_PROPERTY}, 0px));
  height: ${HEADER_HEIGHT_SD}px;
  display: flex;
  align-items: center;

  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: background-color 0.15s ease-in, backdrop-filter 0.15s ease-in;
    backdrop-filter: blur(0px);
    z-index: -1;
    ${({ showBackground, isMenuOpen }) =>
      !isMenuOpen &&
      showBackground &&
      css`
        background: ${COLORS.charcoal_overlay_90};
        backdrop-filter: blur(12px);
      `};
  }

  @media ${DEVICE.exceptSd} {
    display: none;
  }
`;

const MobileHamburgerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${HEADER_HEIGHT_SD}px;
  height: ${HEADER_HEIGHT_SD}px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9002;
`;

const MobileLogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    max-width: 200px;
    max-height: 32px;
  }
  svg {
    width: 127px;
  }
`;

const LeanbackContainer = styled.div<IsOpenProps>`
  display: flex;
  flex-direction: column;
  width: ${LEANBACK_WIDTH}px;
  min-height: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 9000;
  transform: translateX(0);
  transition: transform 0.15s ease-in;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  border-right: 1px solid ${COLORS.white_05};

  ${({ theme }) => css`
    background: linear-gradient(
      180deg,
      ${theme.leanback.background.gradient_0} 0%,
      ${theme.leanback.background.gradient_100} 100%
    );
  `};

  @media ${DEVICE.sd} {
    transform: translateX(-${LEANBACK_WIDTH}px);

    &:focus-within {
      transform: translateX(0);
    }

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translateX(0);
      `}
  }

  @media ${DEVICE.sd} {
    padding-bottom: 90px;
    padding-top: 56px;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: auto;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  -ms-overflow-style: -ms-autohiding-scrollbar;

  &:hover,
  &.scroll {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) =>
      theme.scrollBar.thumb + ' ' + theme.scrollBar.track};

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollBar.thumb};
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.scrollBar.track};
    }
  }
`;

const LeanbackHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  width: ${LEANBACK_WIDTH}px;
  flex-shrink: 0;

  a {
    display: flex;
    /* Without "align-items: start", this flexbox element will be stretched unexpectedly on safari */
    align-items: start;
  }

  @media ${DEVICE.sd} {
    display: none;
  }
`;

const LeanbackHeaderLogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 160px;
  }
  svg {
    width: 96px;
  }
`;

const MenuButtonGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  width: ${LEANBACK_WIDTH}px;
  flex: 0 0 auto;

  @media ${DEVICE.sd} {
    margin-top: 0px;
  }
`;

const MenuButtonDiv = styled(ButtonLookDiv)`
  width: 200px;
`;

const GhostMenuButton = styled(Button)`
  width: 200px;
  margin-top: 8px;
`;

const HelpContainer = styled.div`
  margin-top: 38px;
`;

const HelpItem = styled.li`
  display: block;
  padding: 0;

  position: relative;
  height: 40px;
  line-height: 40px;
  width: ${LEANBACK_WIDTH}px;
  transition: background 0.15s ease-in;
  box-sizing: border-box;

  a {
    text-decoration: none;
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    color: ${({ theme }) => theme.menu.text};
    box-sizing: border-box;
    cursor: pointer;
    transition: color 0.15s ease-in;
    padding-left: 28px;
    font-weight: 700;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.menu.textHover};
    }
  }
`;

const RightIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  margin: 0 16px 0 auto;
`;

const isValidGroupId = (id: string): id is keyof typeof GroupDisplayName => {
  return id in GroupDisplayName;
};

const Leanback: React.FC = () => {
  useOfflineCheck();
  const bannerContext = useContext(SmallBannerContext);
  const searchOverlayRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const { data: userInfoData, loading: userInfoLoading } = useUserInfo({
    fetchPolicy: 'cache-and-network',
  });
  const { hasUserCookie } = useContext(UserAgentContext);
  const userInfo = userInfoData?.userInfo;
  const hasUserInfo = !!(userInfo && userInfo.id);
  const isMainAccount = userInfo?.accountTypeCode === 'MAIN';

  const { data, loading, error } = useQuery<cosmo_getLeanbackGenresQuery>(
    GET_LEANBACK_GENRES,
    {
      ssr: true,
      fetchPolicy: 'cache-first',
    }
  );

  const { data: multiAccountUserInfoData } = useClientQuery<
    cosmo_getMultiAccountUserInfoQuery,
    cosmo_getMultiAccountUserInfoQueryVariables
  >(GET_MULTI_ACCOUNT_USERS_INFO, {
    variables: {
      isAll: false,
    },
    skip: !hasUserInfo || isMainAccount,
  });

  const isTablet = useMedia([DEVICE.sd, DEVICE.exceptSd], [true, false], false);
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );
  const router = useRouter();
  const [isMainContentScrolled, setIsMainContentScrolled] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { searchOpen } = useSearchInputContext();

  const [isMenuContainerScrolling, setIsMenuContainerScrolling] =
    useState(false);

  const menuContainerRef = useRef<HTMLDivElement>(null);
  // disable scrolling when MainContent is blurred
  useLockBodyScroll(
    isMenuOpen && isTablet,
    !isMenuOpen,
    menuContainerRef.current
  );

  const { isAeon } = useHostInfo();
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  useEffect(() => {
    let localSmall = false;
    const handleScroll = () => {
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (scrollTop > 0 && !localSmall) {
        localSmall = true;
        setIsMainContentScrolled(true);
      }

      if (scrollTop === 0 && localSmall) {
        localSmall = false;
        setIsMainContentScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = (e: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
    if (isMenuOpen) {
      e.currentTarget.blur();
      setMenuOpen(false);
    }
  };

  const handleVideoClick = (
    e: MouseEvent<HTMLAnchorElement | HTMLDivElement>
  ) => {
    handleClick(e);
    getKafkaClient().trackUserClickDimension0<NavigationMenuListMenuLog>(
      NAVIGATION_MENU_LIST_MENU,
      {
        menu_name: 'video',
      }
    );
  };

  const handleBookClick = (
    e: MouseEvent<HTMLAnchorElement | HTMLDivElement>
  ) => {
    handleClick(e);
    getKafkaClient().trackUserClickDimension0<NavigationMenuListMenuLog>(
      NAVIGATION_MENU_LIST_MENU,
      {
        menu_name: 'book',
      }
    );
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.click();
      e.currentTarget.blur();
      setMenuOpen(false);
    }
  };

  // Show Scrollbar for Tablets

  const { hasExpired, delay } = useDelay(1000);
  useEffect(() => {
    if (hasExpired) {
      setIsMenuContainerScrolling(false);
    }
  }, [hasExpired]);

  const throttledHandleMenuContainerScroll = throttle(delay, 500, {
    leading: true,
    trailing: true,
  });

  const handleMenuContainerTouchStart = useCallback(() => {
    setIsMenuContainerScrolling(true);
    throttledHandleMenuContainerScroll();
  }, [throttledHandleMenuContainerScroll]);

  const { getLoginUrl } = useLoginUrl();

  if (error) {
    throw error;
  }

  const semiAdultGenre = data?.webfront_leanbackGenres.find(
    (genre) => genre.id === 'SEMIADULT'
  );

  // 表示するロゴ画像を決定する
  const userPlatformCode = userInfo?.userPlatformCode || '';
  const otherFunctionId = userInfo?.otherFunctionId || '';
  let logoKey = '';
  if (
    userInfo?.isAeon ||
    ((userPlatformCode === 'unext' || userPlatformCode === 'bos') &&
      otherFunctionId === '600')
  ) {
    logoKey = '600';
  } else if (hasUserInfo) {
    logoKey =
      userPlatformCode !== 'unext' ? userPlatformCode : otherFunctionId || '';
  }
  let logoSrc = '';
  if (logoKey === 'yamada') {
    logoSrc = '//metac.nxtv.jp/img/wf/logo/yamada.png';
  } else if (logoKey === '600') {
    if (isTablet) {
      logoSrc = '//metac.nxtv.jp/img/wf/logo/unext600_white.png';
    } else {
      logoSrc = '//metac.nxtv.jp/img/wf/logo/unext600_3lines.png';
    }
  } else if (logoKey === '700' || logoKey === '701') {
    logoSrc = `//metac.nxtv.jp/img/wf/logo/unext${logoKey}.png`;
  }

  const FooterComponent = () => (
    <Menu.Footer isOpen={isMenuOpen}>
      <Menu.FooterItem>
        <a
          href={`${GlobalConfig.URLS.STATIC}/introduction`}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          <FormattedMessage {...messages.leanbackService} />
          <Icon name={Icons.HALF_ARROW_RIGHT} />
        </a>
      </Menu.FooterItem>
      <Menu.FooterItem>
        <a
          href="https://account.unext.jp/usen"
          onClick={(e: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
            // Get href from event target because it has gtag queries
            let href = 'https://account.unext.jp/usen';
            if (e.target instanceof HTMLAnchorElement) {
              href = e.target.href;
            }
            navigateAfterPromiseHandler(async () => {
              await getKafkaClient().trackUserClickDimension0<NavigationMenuListMenuLog>(
                NAVIGATION_MENU_LIST_MENU,
                {
                  menu_name: 'music',
                }
              );
            }, href)(e);
          }}
        >
          SMART USEN
          <Icon name={Icons.HALF_ARROW_RIGHT} />
        </a>
      </Menu.FooterItem>
      <Menu.FooterItem>
        <a
          href="https://www.unext.co.jp/ja/legal/privacy"
          target="_blank"
          rel="noreferrer"
        >
          プライバシーポリシー
          <Icon name={Icons.HALF_ARROW_RIGHT} />
        </a>
      </Menu.FooterItem>
    </Menu.Footer>
  );

  const shouldShowPlaceHolder =
    (hasUserCookie && userInfoLoading && !hasUserInfo) || loading;

  const shouldShowLogin =
    (!hasUserCookie && !hasUserInfo) ||
    (hasUserCookie && !userInfoLoading && !hasUserInfo);

  let focusedGenreGroupId = '';
  if (isActivePath(router, /^\/mylist/)) {
    focusedGenreGroupId = 'LIBRARY';
  } else if (isActivePath(router, /^(\/genre(?!\/semiadult)|\/ranking)/)) {
    focusedGenreGroupId = 'VIDEO';
  } else if (isActivePath(router, /^\/book\/genre/)) {
    focusedGenreGroupId = 'BOOK';
  }

  return (
    <>
      <SiteOverlay isOpen={isMenuOpen} onClick={handleClick}>
        {isTablet && (
          <MobileHeader
            isMenuOpen={isMenuOpen}
            showBackground={isMainContentScrolled}
          >
            {/* Absolutely positioned div for background fade in */}
            <div />
            {!isMenuOpen && !searchOpen && (
              <MobileLogoContainer>
                <Link href="/" passHref>
                  {logoSrc ? <img src={logoSrc} alt="logo" /> : <UnextLogo />}
                </Link>
              </MobileLogoContainer>
            )}
          </MobileHeader>
        )}
        {isTablet && !searchOpen && (
          <MobileHamburgerContainer>
            <Hamburger
              data-ucn="leanback-mobileHamburger-btn"
              onClick={() => toggleMenu()}
              isActive={isMenuOpen}
            />
          </MobileHamburgerContainer>
        )}
        {(!isTablet || !isMenuOpen) && (
          <SearchLayout
            isSearchOpen={searchOpen}
            isLocked={!!bannerContext.options.controls}
          >
            <Search overlayRef={searchOverlayRef} />
          </SearchLayout>
        )}
        <LeanbackContainer
          isOpen={isMenuOpen}
          onFocus={() => setMenuOpen(true)}
          ref={menuContainerRef}
          className={isMenuContainerScrolling ? 'scroll' : undefined}
          onTouchStart={handleMenuContainerTouchStart}
          onScroll={throttledHandleMenuContainerScroll}
          data-ucn="leanback-container"
        >
          <LeanbackHeaderContainer>
            <Link href="/" passHref>
              {logoSrc ? (
                <LeanbackHeaderLogoContainer>
                  <img src={logoSrc} alt="logo" />
                </LeanbackHeaderLogoContainer>
              ) : (
                <UnextLogo />
              )}
            </Link>
          </LeanbackHeaderContainer>
          {shouldShowPlaceHolder ? (
            <Menu.PlaceHolder />
          ) : (
            shouldShowLogin && (
              <MenuButtonGroupDiv>
                <a
                  href={`${GlobalConfig.URLS.STATIC}/welcome${
                    isAeon ? '/aeon' : ''
                  }`}
                  onClick={handleClick}
                  onKeyDown={handleKeyDown}
                >
                  <MenuButtonDiv
                    buttonTheme="attention"
                    buttonSize="large"
                    text={intl.formatMessage(messages.loggedOutBlockTrial)}
                  />
                </a>
                <GhostMenuButton
                  buttonTheme="ghost"
                  buttonSize="large"
                  onClick={() => (location.href = getLoginUrl())}
                  data-ucn={SIGNUP_BLOCK_LOGIN}
                  text={intl.formatMessage(messages.loggedOutBlockLogin)}
                />

                <HelpContainer>
                  <HelpItem>
                    <Link
                      href={`${GlobalConfig.URLS.STATIC}/introduction`}
                      passHref
                      onClick={handleClick}
                      onKeyDown={handleKeyDown}
                      role="menuitem"
                    >
                      <Menu.Icon name={Icons.U_MARK} />
                      <FormattedMessage {...messages.loggedOutBlockService} />
                      <RightIcon name={Icons.NEW_TAB} />
                    </Link>
                  </HelpItem>

                  <HelpItem>
                    <Link
                      href={GlobalConfig.URLS.HELP}
                      passHref
                      onClick={handleClick}
                      onKeyDown={handleKeyDown}
                      role="menuitem"
                    >
                      <Menu.Icon name={Icons.HELP} />
                      <FormattedMessage {...messages.leanbackHelp} />
                      <RightIcon name={Icons.NEW_TAB} />
                    </Link>
                  </HelpItem>
                </HelpContainer>
              </MenuButtonGroupDiv>
            )
          )}
          {hasUserInfo && (
            <>
              <Menu.AccountSettingContainer>
                <Menu.AccountItem>
                  <a href={`${GlobalConfig.URLS.MYACCOUNT}/unext-point`}>
                    <Points points={userInfo.points ?? undefined} />
                  </a>
                </Menu.AccountItem>
                <Menu.AccountItem>
                  <a
                    href={`${GlobalConfig.URLS.MYACCOUNT}/familyaccount/switch`}
                  >
                    <Menu.AccountIcon name={Icons.ACCOUNT} />
                    {isMainAccount ? (
                      <FormattedMessage {...messages.leanbackMainAccount} />
                    ) : multiAccountUserInfoData ? (
                      <Menu.TextOverflow>
                        {
                          multiAccountUserInfoData.multiAccountUsersInfo[0]
                            .loginId
                        }
                      </Menu.TextOverflow>
                    ) : (
                      '...'
                    )}
                  </a>
                </Menu.AccountItem>
                <Menu.AccountItem>
                  <a
                    href={`${GlobalConfig.URLS.MYACCOUNT}/settings`}
                    onClick={(
                      e: MouseEvent<HTMLAnchorElement | HTMLDivElement>
                    ) => {
                      // Get href from event target because it has gtag queries
                      let href = `${GlobalConfig.URLS.MYACCOUNT}/settings`;
                      if (e.target instanceof HTMLAnchorElement) {
                        href = e.target.href;
                      }
                      navigateAfterPromiseHandler(async () => {
                        await getKafkaClient().trackUserClickDimension0<NavigationMenuListMenuLog>(
                          NAVIGATION_MENU_LIST_MENU,
                          {
                            menu_name: 'mypage',
                          }
                        );
                      }, href)(e);
                    }}
                  >
                    <Menu.AccountIcon name={Icons.SETTING} />
                    <FormattedMessage {...messages.leanbackAccountContract} />
                  </a>
                </Menu.AccountItem>
                <Menu.AccountItem>
                  <a href={GlobalConfig.URLS.HELP}>
                    <Menu.AccountIcon name={Icons.HELP} />
                    <FormattedMessage {...messages.leanbackHelp} />
                  </a>
                </Menu.AccountItem>
              </Menu.AccountSettingContainer>
            </>
          )}
          <Menu.GenreContainer data-ucn="leanback-menu-list">
            {!shouldShowPlaceHolder && (
              <Menu.Item isActive={isActivePath(router, /^\/(\?td=[\w]+)?$/)}>
                <Link
                  href="/"
                  passHref
                  onClick={handleClick}
                  onKeyDown={handleKeyDown}
                  role="menuitem"
                >
                  <Menu.Icon name={Icons.LB_HOME} />
                  <FormattedMessage {...messages.leanbackHome} />
                </Link>
              </Menu.Item>
            )}

            {hasUserInfo && (
              <GenreGroup
                groupId="LIBRARY"
                isSubItemFocused={focusedGenreGroupId === 'LIBRARY'}
                defaultState={!isMobile}
              >
                <Menu.Item
                  isActive={isActivePath(router, /^\/mylist\/favorite/)}
                  isSubItem
                >
                  <Link
                    href="/mylist/favorite/video"
                    passHref
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    role="menuitem"
                  >
                    <FormattedMessage {...messages.leanbackMyList} />
                  </Link>
                </Menu.Item>
                <Menu.Item
                  isActive={isActivePath(router, /^\/mylist\/purchase/)}
                  isSubItem
                >
                  <Link
                    href="/mylist/purchase/video"
                    passHref
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    role="menuitem"
                  >
                    <FormattedMessage {...messages.leanbackPurchased} />
                  </Link>
                </Menu.Item>
                <Menu.Item
                  isActive={isActivePath(router, /^\/mylist\/history/)}
                  isSubItem
                >
                  <Link
                    href="/mylist/history/video"
                    passHref
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    role="menuitem"
                  >
                    <FormattedMessage {...messages.leanbackHistory} />
                  </Link>
                </Menu.Item>
              </GenreGroup>
            )}

            {!shouldShowPlaceHolder &&
              data?.webfront_leanbackGenres.map((group, index) =>
                isValidGroupId(group.id) ? (
                  <GenreGroup
                    key={group.id}
                    groupId={group.id}
                    defaultState={!isMobile}
                    isSubItemFocused={focusedGenreGroupId === group.id}
                  >
                    {index === 0 && (
                      <Menu.Item
                        isActive={isActivePath(router, /^\/ranking/)}
                        isSubItem
                      >
                        <Link
                          href="/ranking"
                          passHref
                          onClick={handleVideoClick}
                          onKeyDown={handleKeyDown}
                          role="menuitem"
                        >
                          <FormattedMessage {...messages.leanbackRanking} />
                        </Link>
                      </Menu.Item>
                    )}
                    {group.leanbackGenres.map((genre) =>
                      isValidGenreUrl(genre.displayCode) ? (
                        <Menu.Item
                          key={genre.id}
                          isActive={isActivePath(
                            router,
                            new RegExp(
                              `^(/book)?/genre/${
                                GenreUrl[genre.displayCode]
                              }($|\\?|/)`
                            )
                          )}
                          isSubItem
                        >
                          <Link
                            href={`${
                              group.id === 'BOOK' ? '/book' : ''
                            }/genre/[genre]`}
                            as={`${group.id === 'BOOK' ? '/book' : ''}/genre/${
                              GenreUrl[genre.displayCode]
                            }`}
                            passHref
                            onClick={
                              group.id === 'BOOK'
                                ? handleBookClick
                                : handleVideoClick
                            }
                            onKeyDown={handleKeyDown}
                            role="menuitem"
                          >
                            {genre.name}
                          </Link>
                        </Menu.Item>
                      ) : null
                    )}
                  </GenreGroup>
                ) : null
              )}
            {!userInfo?.parentalLock &&
              semiAdultGenre &&
              !shouldShowPlaceHolder &&
              semiAdultGenre.leanbackGenres.map((genre) =>
                isValidGenreUrl(genre.displayCode) ? (
                  <Menu.Item
                    key={genre.id}
                    isActive={isActivePath(
                      router,
                      new RegExp(`^\\/genre\\/${GenreUrl[genre.displayCode]}`)
                    )}
                  >
                    <Link
                      href="/genre/[genre]"
                      as={`/genre/${GenreUrl[genre.displayCode]}`}
                      passHref
                      onClick={handleClick}
                      onKeyDown={handleKeyDown}
                      role="menuitem"
                    >
                      <Menu.Icon name={GenreIcons.ADULT} />
                      {genre.name}
                    </Link>
                  </Menu.Item>
                ) : null
              )}
          </Menu.GenreContainer>
          {/*
           * A transformed element creates a containing block even for descendants that have been set to position: fixed.
           * In other words, the containing block for a fixed-position descendant of a transformed element is
           * the transformed element, not the viewport. So we move Footer component to outside of LeanbackContainer when
           * it needs to be fixed.
           */}
          {!isTablet && FooterComponent()}
        </LeanbackContainer>
        {isTablet && FooterComponent()}
      </SiteOverlay>
      <div ref={searchOverlayRef} />
    </>
  );
};

export default Leanback;
