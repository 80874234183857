import React, { useContext } from 'react';
import { animated, config, useTransition } from 'react-spring';
import styled from 'styled-components';
import { DEVICE } from '../../styles';
import { COLORS } from '../../styles/theme';
import { CheckboxIcon } from '../Checkbox';
import { EditContext } from './context';

const Container = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
`;

const Overlay = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(
    --edit-overlay-background,
    ${COLORS.midnight_overlay_40}
  );
  transition: background-color 0.15s ease-in;
  z-index: 1000;

  div {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    @media ${DEVICE.mobileWide} {
      width: 24px;
      height: 24px;
    }
  }
`;

const Checkbox = styled(CheckboxIcon)`
  width: 30px;
  height: 30px;

  @media ${DEVICE.mobileWide} {
    width: 24px;
    height: 24px;
  }
`;

export type Props = {
  children?: React.ReactNode;
};

const EditThumbnailOverlay: React.FC<Props> = (props) => {
  const { children } = props;
  const { enabled, selected, selectable } = useContext(EditContext);
  const overlayTransitions = useTransition(enabled, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const iconTransitions = useTransition(selected, {
    from: { opacity: 0, transform: 'scale(0.5)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.5)' },
    config: config.wobbly,
  });

  return (
    <Container>
      {children}
      {overlayTransitions((overlayStyle, enabled) => {
        return (
          enabled && (
            <Overlay style={overlayStyle}>
              {iconTransitions((iconStyle, selected) => (
                <animated.div style={iconStyle}>
                  <Checkbox
                    disabled={!selected && !selectable}
                    type="caution"
                    checked={selected}
                  />
                </animated.div>
              ))}
            </Overlay>
          )
        );
      })}
    </Container>
  );
};

export default EditThumbnailOverlay;
