import React, { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { globalMessages } from '../../../constants/messages';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';

export const paymentBadgeSizes = ['small', 'medium', 'large'] as const;
export type paymentBadgeSize = (typeof paymentBadgeSizes)[number];

interface BadgeContainerProps {
  badgeSize?: paymentBadgeSize;
  mobileBadgeSize?: paymentBadgeSize;
  badgeType: 'light' | 'dark';
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 6px;
  border-radius: 100px;
  background: ${({ badgeType }) =>
    badgeType === 'light' ? COLORS.white : COLORS.white_10};
  color: ${({ badgeType }) =>
    badgeType === 'light' ? COLORS.jet_black : COLORS.white};
  font-weight: ${({ badgeType }) => (badgeType === 'light' ? 600 : 300)};
  letter-spacing: 0.27px;
  font-size: 9px;
  line-height: 18px;

  ${({ badgeSize }) =>
    badgeSize === 'large'
      ? css`
          font-size: 10px;
          line-height: 20px;
          letter-spacing: 0.3px;
        `
      : badgeSize === 'small' &&
        css`
          font-size: 9px;
          line-height: 14px;
          padding: 0 4px;
        `};

  @media ${DEVICE.mobileWide} {
    ${({ mobileBadgeSize }) =>
      mobileBadgeSize === 'large'
        ? css`
            font-size: 10px;
            line-height: 20px;
            letter-spacing: 0.3px;
          `
        : mobileBadgeSize === 'small' &&
          css`
            font-size: 9px;
            line-height: 14px;
            padding: 0 4px;
          `};
  }
`;

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  badgeSize?: paymentBadgeSize;
  mobileBadgeSize?: paymentBadgeSize;
}

export const PaymentBadge: React.FC<BadgeProps> = ({ children, ...props }) => {
  return (
    <BadgeContainer data-ucn="paymentBadge" badgeType="dark" {...props}>
      {children}
    </BadgeContainer>
  );
};

export const YomihodaiBadge: React.FC<BadgeProps> = (props) => {
  const intl = useIntl();
  return (
    <BadgeContainer data-ucn="paymentBadge" badgeType="light" {...props}>
      {intl.formatMessage(globalMessages.yomihodai)}
    </BadgeContainer>
  );
};
