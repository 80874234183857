import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  CookieUtil,
  ITokenHolder,
  MigrateStatus,
  migrateTokens,
} from '@u-next/unextjs-oauth';
import { NextPageContext } from 'next';
import { getCookieDomain } from '.';
import { cosmo_userInfoQuery } from '../__generated__/globalTypes';
import { GlobalConfig } from '../shared/constants';
import { GET_USER_INFO } from '../shared/hooks/useUserInfo/gql';

export async function refreshTokenOrMigrate(
  ctx: Pick<NextPageContext, 'req' | 'res'>,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  tokenHolder: ITokenHolder
) {
  const host = new URL(GlobalConfig.URLS.HOST).hostname;
  const { res } = ctx;
  if (!res) {
    return;
  }
  const checkAccessToken = !!tokenHolder.get();
  let forceMigration = false;
  if (checkAccessToken) {
    // query for access token verification
    // 1. on expired access token the apollo client chain will refresh the token
    // see apolloClient.ts for more details:
    // ```
    // onError(ApolloLinks.createErrorLinkWithOAuthRefresh(...))
    // ```
    // and then `createErrorLinkWithOAuthRefresh` in https://github.com/u-next/unextjs-oauth/blob/master/src/apollo/errorLink.ts#L101
    const { data, error } = await apolloClient.query<cosmo_userInfoQuery>({
      query: GET_USER_INFO,
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    });
    // 2. if invalid access token, OAuth2 migration shall be enforced
    forceMigration = !data.userInfo?.id && !error?.networkError;
  }
  const cookieDomain = getCookieDomain(host);
  const cookiePath = '/';
  // FIXME: The property "accessToken" does not exist on type "MigrateResult".
  // @ts-ignore
  const { status, accessToken } = await migrateTokens(ctx, {
    baseUrl: GlobalConfig.URLS.OAUTH,
    cookieDomain,
    cookiePath,
    cookieTTLSeconds: GlobalConfig.OAUTH_COOKIE_TTL,
    scopes: ['unext'],
    clientId: 'unext',
    clientSecret: process.env.OAUTH2_CLIENT_SECRET ?? '',
    forceMigration,
  });
  if (status === MigrateStatus.SUCCESS && !!accessToken) {
    tokenHolder.set(accessToken);
  } else if (status === MigrateStatus.FAILED) {
    // 3a. if migration failed, all login cookies must be deleted
    CookieUtil.clearSecureCookies(res, [
      { name: '_ut', cookieDomain, cookiePath },
      { name: '_st', cookieDomain, cookiePath },
      { name: '_at', cookieDomain, cookiePath },
      { name: '_rt', cookieDomain, cookiePath },
    ]);
  } else if (status === MigrateStatus.NONE && forceMigration) {
    // 3b. if migration is not possible due to missing _st cookie but forced,
    // it means that _at, _rt are invalid and must be deleted
    CookieUtil.clearSecureCookies(res, [
      { name: '_at', cookieDomain, cookiePath },
      { name: '_rt', cookieDomain, cookiePath },
    ]);
  }
}
