import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from '../Modal';
import messages from './messages';

interface PreorderModalProps {
  visible: boolean;
  onClickOk: () => void;
  onCancel: () => void;
  isPreordered: boolean;
}

const PreorderModal: React.FC<PreorderModalProps> = ({
  visible,
  onClickOk,
  onCancel,
  isPreordered,
}) => {
  const intl = useIntl();

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onClickOk}
      title={intl.formatMessage(
        isPreordered
          ? messages.preorderDialogCancelTitle
          : messages.preorderDialogTitle
      )}
      okText={intl.formatMessage(
        isPreordered
          ? messages.preorderDialogCancelButton
          : messages.preorderDialogButton
      )}
      okButtonType={isPreordered ? 'caution' : 'attention'}
      disableScrollLock
    >
      <FormattedMessage
        {...(isPreordered
          ? messages.preorderDialogCancelText
          : messages.preorderDialogText)}
      />
    </Modal>
  );
};

export default PreorderModal;
