import { css } from 'styled-components';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';

export const mixinSectionHeading = css`
  margin: 0;
  font-size: 20px;
  font-weight: 600;

  @media ${DEVICE.tablet} {
    font-size: 16px;
  }
`;

export const sectionStyle = css`
  width: 100%;
  box-sizing: border-box;
  padding: 32px;
  border-radius: 10px;
  background: ${COLORS.ink};

  @media ${DEVICE.mobileWide} {
    padding: 16px;
  }
`;
