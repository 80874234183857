import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import OnlyOnIconComponent from '../../../../assets/icons/icon_onlyon.svg';
import OriginalIconComponent from '../../../../assets/icons/logo/Logo_U-NEXT_Original.svg';
import type { Nullable } from '../../../../utils/Nullable';
import { globalMessages } from '../../../constants/messages';
import { COLORS } from '../../../styles/theme';
import AkamaiImage from '../../Common/AkamaiImage';
import { RoundBadge } from '../../Common/Badge/RoundBadge';
import { SubDubBadge, subDubType } from '../../Common/Badge/SubDubBadge';
import RatingStar from '../../Common/RatingStar';
import { EditThumbnailOverlay } from '../../Edit';
import PointPriceBadge from '../../PointPriceBadge';
import {
  IMAGE_ASPECT,
  LIST_VIEW_MOBILE_ROW_GAP,
  LIST_VIEW_MOBILE_TITLE_CARD_SIZE,
} from '../constants';

const { width, height } = LIST_VIEW_MOBILE_TITLE_CARD_SIZE.video;

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc(
    ${(height / width) * 100}% + ${LIST_VIEW_MOBILE_ROW_GAP * 2}px
  ); /* 一つのCardが専有する高さは、幅 (100%) ✕ Cardの縦横比 + 上下のpadding（16px * 2） で求められる */
  z-index: 100;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    border-bottom: 1px solid ${COLORS.white_10};

    box-sizing: border-box;
    transform: scale(1);
    transition: transform 200ms ease-in;

    display: flex;
    align-items: center;
  }
`;

const RatingStarContainer = styled.div`
  display: inline-block;
  width: 58px;
`;

const MetaInfoCatchphrase = styled.div<{
  hasUpdateInfo?: boolean;
}>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ hasUpdateInfo }) =>
    hasUpdateInfo
      ? css`
          -webkit-line-clamp: 1;
        `
      : css`
          -webkit-line-clamp: 2;
        `}

  color: ${COLORS.white_75};
  font-size: 12px;
`;

const MetaInfoUpdateInfo = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
`;

const MetaInfoTitle = styled.h3`
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 14px;
`;

const MetaInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 2px 0 0 12px;
  box-sizing: border-box;
  overflow: hidden;
`;

const HorizontalLayout = styled.div<{ gap: number }>`
  & > *:not(:first-child) {
    margin-left: 0.4em;
  }
  display: flex;
  gap: ${({ gap }) => gap}px;
  flex-shrink: 0;
  align-items: center;
`;

const OnlyOnIcon = styled(OnlyOnIconComponent)`
  width: 70px;
`;

const OriginalIcon = styled(OriginalIconComponent)`
  width: 61px;
`;

const HorizontalThumbnailPadding = styled.div`
  // 1. Set Thumbnail area width % by this element
  position: relative;
  width: ${(100 * LIST_VIEW_MOBILE_TITLE_CARD_SIZE.video.imageWidth) /
  LIST_VIEW_MOBILE_TITLE_CARD_SIZE.video.width}%;
  flex-shrink: 0;

  > div {
    // 2. Set Thumbnail area height by padding-bottom to keep its aspect-ratio
    position: relative;
    background-color: ${COLORS.gray_3};
    width: 100%;
    padding-bottom: ${100 / IMAGE_ASPECT.video}%;
    overflow: hidden;
    border-radius: 10px;

    &:before {
      // 3. Set border as an overlay. Set border-radius here too to make the border precise.
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      border: 1px solid ${COLORS.white_10};
      z-index: 1;
    }
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
  }
`;

const EpisodeList = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
`;

const ExpiryDate = styled.div`
  color: ${COLORS.white_75};
  font-size: 10px;
`;

interface ThumbnailProps {
  thumbnailUrl: string;
  isLazy?: boolean;
}

interface VideoMetaInfoProps {
  titleName: Nullable<string>;
  rate: Nullable<number>;
  catchphrase: Nullable<string>;
  updateInfo?: string;
  nfreeBadge?: Nullable<string>;
  hasSub?: Nullable<boolean>;
  hasDub?: Nullable<boolean>;
  isPoint?: boolean;
  isNew?: Nullable<boolean>;
  isOriginal?: boolean;
  isOnlyOn?: boolean;
  price?: number;
  hasMultiprice: boolean;
}

interface PurchasedVideoMetaInfoProps {
  titleName: Nullable<string>;
  closestExpiryDateText: string;
  boughtEpisodeNumberTextList: string[];
}

type MetaInfoProps = VideoMetaInfoProps | PurchasedVideoMetaInfoProps;

const ListVideoCardMobile: React.FC<ThumbnailProps & MetaInfoProps> = ({
  thumbnailUrl,
  isLazy = false,
  titleName,
  ...props
}) => {
  const intl = useIntl();
  const isPurchasedVideo = 'closestExpiryDateText' in props;

  let subDub: subDubType | undefined = undefined;
  if (!isPurchasedVideo) {
    if (props.hasSub && props.hasDub) {
      subDub = 'subdub';
    } else if (props.hasSub) {
      subDub = 'sub';
    } else if (props.hasDub) {
      subDub = 'dub';
    }
  }

  return (
    <Container {...props}>
      <div>
        <HorizontalThumbnailPadding>
          <EditThumbnailOverlay>
            <div>
              <AkamaiImage
                src={thumbnailUrl}
                availableSizes={[168, 336]}
                sizeConfig={{
                  SD: {
                    width: 282,
                  },
                  MOBILE_WIDE: {
                    width: 168,
                  },
                }}
                alt={titleName ?? ''}
                isLazy={isLazy}
              />
            </div>
          </EditThumbnailOverlay>
        </HorizontalThumbnailPadding>
        <MetaInfoContainer>
          {isPurchasedVideo ? (
            <>
              <MetaInfoTitle>{titleName}</MetaInfoTitle>
              {props.boughtEpisodeNumberTextList &&
                props.boughtEpisodeNumberTextList.length > 0 && (
                  <EpisodeList>
                    {props.boughtEpisodeNumberTextList.join('、')}
                  </EpisodeList>
                )}
              <ExpiryDate>{props.closestExpiryDateText}</ExpiryDate>
            </>
          ) : (
            <>
              {(props.isOriginal || props.isOnlyOn || props.isNew) && (
                <HorizontalLayout gap={4}>
                  {props.isOriginal ? (
                    <OriginalIcon />
                  ) : props.isOnlyOn ? (
                    <OnlyOnIcon />
                  ) : null}
                  {props.isNew && (
                    <RoundBadge badgeType="promotion" badgeSize="small">
                      {intl.formatMessage(globalMessages.new)}
                    </RoundBadge>
                  )}
                </HorizontalLayout>
              )}
              <MetaInfoTitle>{titleName}</MetaInfoTitle>
              <HorizontalLayout gap={3}>
                <RatingStarContainer>
                  <RatingStar rate={props.rate ?? 0} iconWidth={10} />
                </RatingStarContainer>

                {subDub && <SubDubBadge badgeSize="small" badgeType={subDub} />}
                <PointPriceBadge
                  isPoint={!!props.isPoint}
                  minimumPrice={props.price}
                  hasMultiplePrice={!!props.hasMultiprice}
                  badgeSize="medium"
                />
              </HorizontalLayout>
              <HorizontalLayout gap={2}>
                {props.nfreeBadge && (
                  <RoundBadge badgeType="ghost_dark" badgeSize="small">
                    {props.nfreeBadge}
                  </RoundBadge>
                )}
                {props.updateInfo && (
                  <MetaInfoUpdateInfo>{`${props.nfreeBadge ? '｜' : ''}${
                    props.updateInfo
                  }`}</MetaInfoUpdateInfo>
                )}
              </HorizontalLayout>
              {props.catchphrase && (
                <MetaInfoCatchphrase hasUpdateInfo={!!props.updateInfo}>
                  {props.catchphrase}
                </MetaInfoCatchphrase>
              )}
            </>
          )}
        </MetaInfoContainer>
      </div>
    </Container>
  );
};

export default ListVideoCardMobile;
