import { useQuery } from '@apollo/client';
import { cosmo_isAeonQuery } from '../../__generated__/globalTypes';
import { getHostname } from '../../utils';
import { GET_IS_AEON } from './useUserInfo/gql';
/**
 * useHostInfo
 * @returns HostInfo
 */
type HostInfo = {
  hostname: string;
  isAeon: boolean;
};

const useHostInfo = (): HostInfo => {
  const { data } = useQuery<cosmo_isAeonQuery>(GET_IS_AEON);

  const hostname = getHostname(!!data?.isAeon);
  return { hostname, isAeon: !!data?.isAeon };
};

export default useHostInfo;
