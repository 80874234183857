import styled, { css } from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
  LIST_VIEW_MOBILE_TITLE_CARD_SIZE,
  LIST_VIEW_TITLE_CARD_SIZE,
} from '../../TitleCard/constants';

export const placeholderStyle = css`
  background-color: ${COLORS.charcoal};
  animation: fadingAnimation 1.5s infinite;
  @keyframes fadingAnimation {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;

const BlockViewTitleCard = styled.div<{
  contentType: keyof typeof BLOCK_VIEW_TITLE_CARD_SIZE;
}>`
  ${placeholderStyle};
  border-radius: 10px;
  overflow: hidden;
  width: ${({ contentType }) =>
    BLOCK_VIEW_TITLE_CARD_SIZE[contentType].width}px;
  height: ${({ contentType }) =>
    BLOCK_VIEW_TITLE_CARD_SIZE[contentType].height}px;

  @media ${DEVICE.mobileWide} {
    width: ${({ contentType }) =>
      BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[contentType].width}px;
    height: ${({ contentType }) =>
      BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[contentType].height}px;
  }
`;

const ListViewTitleCard = styled.div<{
  contentType: keyof typeof LIST_VIEW_TITLE_CARD_SIZE;
}>`
  ${placeholderStyle};
  border-radius: 10px;
  overflow: hidden;
  width: ${({ contentType }) => LIST_VIEW_TITLE_CARD_SIZE[contentType].width}px;
  height: ${({ contentType }) =>
    LIST_VIEW_TITLE_CARD_SIZE[contentType].height}px;
`;

const ListViewTitleCardMobile = styled.div<{
  contentType: keyof typeof LIST_VIEW_MOBILE_TITLE_CARD_SIZE;
}>`
  ${placeholderStyle};
  width: 100%;
  height: unset;
  padding-bottom: ${({ contentType }) =>
    (LIST_VIEW_MOBILE_TITLE_CARD_SIZE[contentType].height * 100) /
    LIST_VIEW_MOBILE_TITLE_CARD_SIZE[contentType].width}%;
  display: block;
  margin-bottom: 16px;
`;

const CoverArea = styled.div<{ bottomMargin: number }>`
  ${placeholderStyle};
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: calc(100% - ${({ bottomMargin }) => bottomMargin}px);
`;

export default {
  BlockViewTitleCard,
  ListViewTitleCard,
  ListViewTitleCardMobile,
  CoverArea,
};
