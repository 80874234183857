import { gql } from '@apollo/client';

export const bookDetailFragment = gql`
  fragment BookDetail on Book {
    code
    name
    isNew
    isPreorderable
    isPreordered
    isFree
    isPurchased
    isSale
    isWebtoon
    isBookSakuhinTicketAvailable
    isBookPlusTicketAvailable
    isInReadingHistory
    thumbnail {
      standard
    }
    saleText
    introduction
    bookNo
    publisher {
      code
      name
    }
    publicStartDateTime
    publicEndDateTime
    originalSaleDate
    rightsExpirationDatetime
    mediaType {
      code
    }
    credits {
      bookAuthorType
      personCode
      penName
      penNameCode
      unextPublishingDetail {
        thumbnail {
          standard
        }
        introduction
      }
    }
    publishedIn {
      code
      name
    }
    bookContent {
      productList {
        salePeriod {
          price
        }
      }
      mainBookFile {
        code
        completeFlg
        resumePoint
        licenseExpiredDateTime
      }
      sampleBookFile {
        code
        completeFlg
        resumePoint
      }
    }
    includedBook {
      code
      name
      thumbnail {
        standard
      }
      sakuhinCode
      bookNumber
    }
  }
`;

// Fragment used for Book Title Detail (or full sakuhin data requests)
export const bookSakuhinFragment = gql`
  fragment BookSakuhinDetail on BookSakuhin {
    sakuhinCode: code
    name
    rate
    userRate
    isFavorite
    totalBookNum
    purchaseBookNum
    totalBookNumIncludingPreorders
    isUnextOriginal
    isNew
    bookNumForTicket
    nextReleaseDatetime
    ticketEndDatetime
    hasUnreadChapter
    isBookSakuhinTicketAvailable
    isBookPlusTicketAvailable
    plusTicketEndDateTime
    bookTickets {
      sakuhinTicket {
        code
        typeCode
        typeName
        availableCount
        receivedCount
        nextDeliveryDatetime
        nextDeliveryCount
      }
      plusTicket {
        code
        typeCode
        typeName
        availableCount
        receivedCount
        nextDeliveryDatetime
        nextDeliveryCount
      }
    }
    bookLabel {
      code
      name
    }
    detail {
      introduction
      catchSentence
    }
    paymentBadgeList {
      code
      name
    }
    minPrice
    hasMultiplePrices
    book {
      ...BookDetail
    }
    isCompleted
    freeBookNum
    isSale
    subgenreTagList {
      name
    }
    isChapter
    thumbnail {
      standard
    }
    freeSaleText
  }
  ${bookDetailFragment}
`;

// Fragments used anywhere we are displaying book title cards
export const bookTitleCardFragment = gql`
  fragment BookCard on Book {
    code
    name
    mediaType {
      code
    }
    thumbnail {
      standard
    }
    publisher {
      code
      name
    }
    credits {
      personCode
      penName
    }
    publishedIn {
      name
    }
    isPreorderable
    publicStartDateTime
  }
  fragment BookTitleCard on BookSakuhin {
    sakuhinCode: code
    name
    rate
    freeBookNum
    isNew
    isSale
    book {
      ...BookCard
    }
    minPrice
    hasMultiplePrices
    totalBookNum
    isUnextOriginal
    paymentBadgeList {
      code
    }
    bookViewCode
    featurePieceCode
    subgenreTagList {
      name
    }
    recentLicenseExpiredDatetime
    isChapter
    hasUnreadChapter
    isBookSakuhinTicketAvailable
    isBookPlusTicketAvailable
  }
`;

export const originalBookTitleCardFragment = gql`
  fragment OriginalBookCard on Book {
    code
    name
    introduction
    thumbnail {
      standard
    }
    credits {
      personCode
      penName
      unextPublishingDetail {
        thumbnail {
          standard
        }
      }
    }
  }
  fragment OriginalBookTitleCard on BookSakuhin {
    sakuhinCode: code
    name
    isNew
    bookViewCode
    featurePieceCode
    detail {
      catchSentence
    }
    book {
      ...OriginalBookCard
    }
  }
`;
