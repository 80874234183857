import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';
import { cosmo_getLiveDetailQuery } from '../../../__generated__/globalTypes';
import { RoundBadge } from '../../../shared/components/Common/Badge/RoundBadge';
import { Icons } from '../../../shared/components/Common/Icon';
import LiveNow from '../../../shared/components/LiveNow';
import PointPriceBadge, {
  isPoint,
} from '../../../shared/components/PointPriceBadge';
import { globalMessages } from '../../../shared/constants/messages';
import { COLORS } from '../../../shared/styles/theme';
import { findCatchup, getLiveOnAirStatus } from '../../Genre/utils';
import type { LiveTitleDetailStagePlayLog } from '../../Log/__types__/liveTitleDetail-stage-play';
import {
  STAGE_PLAY,
  TITLE_SPOT,
} from '../../Log/ComponentName/liveTitleDetail';
import { getKafkaClient, shouldKafkaIgnore } from '../../Log/kafkaClient';
import KeyArt, { KeyartLayout, KeyartLink } from '../shared/Keyart';
import { PaymentBadgeList } from '../shared/PaymentBadgeList';
import {
  FlexColumnsCenter,
  LiveNowContainer,
  OnlyOnIcon,
  PlayButton,
  PlayButtonsLayout,
  StageButtonArea,
  StageDescription,
  StageMetaArea,
  StagePromotionArea,
  StageSubInfoArea,
  StageSubTitle,
  StageTitle,
  StageTitleArea,
  VideoStageSection,
} from '../shared/StageSection';
import messages from '../Video/messages';

interface LiveStageSectionProps {
  isLoggedIn: boolean;
  liveDetail: cosmo_getLiveDetailQuery['webfront_getLive'];
}

const LiveStageSection: React.FC<LiveStageSectionProps> = ({ liveDetail }) => {
  const intl = useIntl();
  const metaInfoTextArray = [];
  const isForeignSoccer =
    liveDetail.productLineupCodeList.includes('LNPS_FSCR');

  if (findCatchup(liveDetail)) {
    metaInfoTextArray.push(intl.formatMessage(messages.hasCatchup));
  }
  if (liveDetail.allowsTimeshiftedPlayback) {
    metaInfoTextArray.push(intl.formatMessage(messages.hasTimeShiftedPlayBack));
  }

  const metaInfoLineText = metaInfoTextArray.length
    ? metaInfoTextArray.join('｜')
    : null;

  const deliveryStart = new Date(liveDetail.deliveryStartDateTime);

  const saleEndTime =
    liveDetail.displaySaleEndDateTime &&
    new Date(liveDetail.displaySaleEndDateTime);

  const hasPlayRight =
    liveDetail.purchased ||
    liveDetail.hasPackRights ||
    liveDetail.saleTypeCode === 'FREE' ||
    liveDetail.paymentBadgeList.some(
      (paymentBadge) => paymentBadge.code === 'SVOD'
    );
  const didDeliveryEnd = new Date(liveDetail.deliveryEndDateTime) < new Date();

  const liveOnAirStatus = getLiveOnAirStatus(liveDetail);

  const time = Date.now();
  const liveNotice = [...liveDetail.notices]
    .sort((notice1, notice2) =>
      notice1.publicStartDateTime > notice2.publicStartDateTime ? -1 : 1
    )
    .find((notice) => time >= Date.parse(notice.publicStartDateTime));
  const thumbnailUrl = liveNotice?.thumbnail?.secondary ?? '';

  const trackTitleSpot = async () => {
    const componentName = TITLE_SPOT;
    if (!shouldKafkaIgnore(componentName))
      await getKafkaClient().trackUserClickDimension0(componentName, {
        sakuhinCode: liveDetail.id,
      });
  };

  return (
    <>
      <KeyartLayout>
        <KeyartLink
          href={`/live/${liveDetail.id}`}
          role="button"
          data-ucn={TITLE_SPOT}
          onClick={trackTitleSpot}
          legacyBehavior={false}
          iconType="play"
        >
          <KeyArt backgroundImage={`//${thumbnailUrl}`} />
        </KeyartLink>
      </KeyartLayout>
      <VideoStageSection>
        <StageMetaArea>
          <StageTitleArea>
            <StagePromotionArea>
              {liveDetail.isOnlyOn && <OnlyOnIcon />}
            </StagePromotionArea>
            <StageSubTitle>
              <div>
                {intl.formatMessage(messages.deliveryStartText, {
                  startDate: `${intl.formatDate(deliveryStart, {
                    month: 'narrow',
                    day: 'numeric',
                  })} ${intl.formatTime(deliveryStart)}`,
                })}
              </div>
              {liveOnAirStatus && (
                <LiveNowContainer>
                  <LiveNow
                    isCatchup={liveOnAirStatus === 'CATCHUP_NOW'}
                    active={liveOnAirStatus === 'LIVE_NOW'}
                    iconLayout="left"
                  />
                </LiveNowContainer>
              )}
            </StageSubTitle>
            <StageTitle>{liveDetail.name}</StageTitle>
          </StageTitleArea>
          <StageSubInfoArea>
            <FlexColumnsCenter>
              <PointPriceBadge
                isPoint={isPoint(liveDetail)}
                minimumPrice={
                  liveDetail.tickets.some((ticket) => ticket.price)
                    ? Math.min(
                        ...liveDetail.tickets.map((ticket) => ticket.price)
                      )
                    : undefined
                }
                hasMultiplePrice={liveDetail.tickets.length > 1}
                badgeSize="medium"
              />
              {liveDetail.paymentBadgeList.length > 0 && (
                <PaymentBadgeList list={liveDetail.paymentBadgeList} />
              )}
              {isForeignSoccer && (
                <RoundBadge badgeType="ghost" badgeSize="medium">
                  {intl.formatMessage(globalMessages.foreignSoccerBadge)}
                </RoundBadge>
              )}
            </FlexColumnsCenter>
            {metaInfoLineText && <div>{metaInfoLineText}</div>}
          </StageSubInfoArea>
          <StageDescription>{liveDetail.description}</StageDescription>{' '}
          {saleEndTime && (
            <StageSubInfoArea
              style={
                saleEndTime.getTime() <= deliveryStart.getTime()
                  ? {
                      color: COLORS.caution,
                    }
                  : undefined
              }
            >
              {intl.formatMessage(messages.liveTicketSaleEndTime, {
                date: intl.formatDate(saleEndTime, {
                  month: 'narrow',
                  day: 'numeric',
                }),
                time: intl.formatTime(saleEndTime),
              })}
            </StageSubInfoArea>
          )}
        </StageMetaArea>
        <StageButtonArea>
          <PlayButtonsLayout>
            <Link
              href={`/live/${liveDetail.id}`}
              onClick={async () => {
                await getKafkaClient().trackUserClickDimension0<LiveTitleDetailStagePlayLog>(
                  STAGE_PLAY,
                  {
                    live_code: liveDetail.id,
                  }
                );
              }}
              role="button"
              legacyBehavior={false}
            >
              <PlayButton
                buttonSize="large"
                buttonTheme={hasPlayRight ? 'light' : 'attention'}
                icon={hasPlayRight ? Icons.PLAY : Icons.TO_PURCHASE}
                text={intl.formatMessage(
                  hasPlayRight
                    ? didDeliveryEnd
                      ? messages.liveWatchCatchupButton
                      : messages.liveWatchButtonText
                    : isForeignSoccer
                    ? messages.livePurchasePackButtonText
                    : messages.liveBuyTicketButtonText
                )}
                data-ucn={STAGE_PLAY}
              />
            </Link>
          </PlayButtonsLayout>
        </StageButtonArea>
      </VideoStageSection>
    </>
  );
};

export default LiveStageSection;
