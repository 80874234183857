import { useMemo } from 'react';

// spec: https://wiki.unext-info.jp/x/WB9U
const getEpisodePageSize = (totalItemCount: number) => {
  switch (true) {
    case totalItemCount <= 140:
      return 20;
    case totalItemCount <= 280:
      return 40;
    case totalItemCount <= 420:
      return 60;
    case totalItemCount <= 560:
      return 80;
    case totalItemCount <= 700:
      return 100;
    case totalItemCount <= 1400:
      return 200;
    case totalItemCount <= 2100:
      return 300;
    default:
      return 400;
  }
};

interface UseEpisodePagingTabListReturnValue {
  showPagingIndicator: boolean;
  pagingTabList: string[];
  itemsPerPage: number;
}

const useEpisodePagingTabList = (
  totalItemCount: number
): UseEpisodePagingTabListReturnValue => {
  const { showPagingIndicator, pagingTabList, itemsPerPage } = useMemo(() => {
    const itemsPerPage = getEpisodePageSize(totalItemCount);
    const showPagingIndicator = !!(totalItemCount > itemsPerPage);
    const episodeListPages = Math.ceil(totalItemCount / itemsPerPage);
    const pagingTabList: string[] = [];
    for (let j = 0; j < episodeListPages; j += 1) {
      const firstItemNum = j * itemsPerPage + 1;
      pagingTabList.push(firstItemNum.toString());
    }
    return {
      showPagingIndicator,
      pagingTabList,
      itemsPerPage,
    };
  }, [totalItemCount]);
  return {
    showPagingIndicator,
    pagingTabList,
    itemsPerPage,
  };
};

export default useEpisodePagingTabList;
