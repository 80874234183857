import React from 'react';
import styled from 'styled-components';
import { DEVICE, FONT_FAMILY } from '../../../styles/constants';
import { COLORS } from '../../../styles/theme';
import Icon, { Icons } from '../../Common/Icon';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';
import Container from './containers/Container';
import BookThumbnail from './Thumbnail';

export type RankingMeta = {
  rankDiff: number;
  rank: number;
};

const StyledContainer = styled(Container)`
  width: ${BLOCK_VIEW_TITLE_CARD_SIZE.rankingBook.width}px;

  @media ${DEVICE.mobileWide} {
    width: ${BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.rankingBook.width}px;
  }
`;

const ThumbnailLayout = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 24px 0;
`;

const MetaInfoContainer = styled.div`
  width: 100%;
  padding: 16px 28px 9px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
`;

const TitleLayout = styled.div`
  min-height: 45px;
`;

const Title = styled.h3<{ lineClamp?: number }>`
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 16px;
  line-height: 1;
  color: ${COLORS.white};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lineClamp || 2};
  white-space: normal;
  line-height: 1.4;

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

const FreeText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: ${COLORS.primary};
  margin: 4px 0 0;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

const RankingLayout = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0;
  z-index: -1;
`;

const RankingNumber = styled.div`
  text-indent: -2px;
  font-family: ${FONT_FAMILY.MONOSPACE};
  box-sizing: border-box;
  font-size: 86px;
  text-align: left;
  font-weight: 900;
  line-height: 75%;
  color: rgba(255, 255, 255, 0.5);

  @media ${DEVICE.mobileWide} {
    font-size: 80px;
  }

  @supports (background-clip: text) or (-webkit-background-clip: text) {
    color: transparent;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

const RankingDiffIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 4px;
  left: 0px;
  fill: ${COLORS.white};
`;

type Props = {
  titleName: string;
  thumbnailUrl: string;
  numberOfFree?: string;
  rankingMeta: RankingMeta;
  isNew?: boolean;
  isDailyFree: boolean;
  isDailyFreePlus: boolean;
  isLazy?: boolean;
};

const RankingBookTitleCard: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  numberOfFree,
  rankingMeta,
  isNew = false,
  isDailyFree,
  isDailyFreePlus,
  isLazy = false,
}) => {
  const thumbnailProps = {
    thumbnailUrl,
    isNew,
    isLazy,
    isDailyFree,
    isDailyFreePlus,
  };

  return (
    <StyledContainer>
      <ThumbnailLayout>
        <BookThumbnail {...thumbnailProps} />
      </ThumbnailLayout>
      <MetaInfoContainer>
        <TitleLayout>
          <Title lineClamp={numberOfFree ? 1 : 2}>{titleName}</Title>
          {numberOfFree && <FreeText>{numberOfFree}</FreeText>}
        </TitleLayout>
        <RankingLayout>
          <RankingNumber>{rankingMeta.rank}</RankingNumber>
          {rankingMeta.rankDiff > 0 && (
            <RankingDiffIcon name={Icons.FULL_ARROW_UP} />
          )}
        </RankingLayout>
      </MetaInfoContainer>
    </StyledContainer>
  );
};

export default RankingBookTitleCard;
