import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import { DailyFreeBadge } from '../../Common/Badge/DaillyFreeBadge';
import { EditThumbnailOverlay } from '../../Edit';
import { LIST_VIEW_MOBILE_TITLE_CARD_SIZE } from '../constants';
import HorizontalContainer from './containers/HorizontalContainer';
import BookThumbnail, { ThumbnailProps } from './Thumbnail';

const MobileBookMetaInfoContainer = styled.div`
  width: 100%;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

const MobileBookThumbnailLayout = styled.div`
  --mobile-thumbnail-width: calc(
    100% * ${LIST_VIEW_MOBILE_TITLE_CARD_SIZE.purchasedBook.imageWidth} /
      ${LIST_VIEW_MOBILE_TITLE_CARD_SIZE.purchasedBook.width}
  );
  width: var(--mobile-thumbnail-width);
  flex-shrink: 0;
`;

const PurchasedInfo = styled.div`
  font-size: 12px;
`;

const LimitDate = styled.div`
  font-size: 10px;
  color: ${COLORS.white_75};
  min-height: 14px; // need to explicitly declare height, to keep the height while there's no content
`;

const MetaInfoTitle = styled.h3`
  box-sizing: border-box;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
`;

type Props = ThumbnailProps & {
  purchasedInfo: string;
  limitDayText: string;
  isDailyFree: boolean;
  isDailyFreePlus: boolean;
};

const PurchasedBookCardMobile: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  isDailyFree,
  isDailyFreePlus,
  isLazy = false,
  purchasedInfo,
  limitDayText,
}) => {
  const thumbnailProps = {
    thumbnailUrl,
    isLazy,
    titleName,
    // isNew, Do not show new/preorderable badge for purchased book
    // isPreorderable,
  };

  return (
    <HorizontalContainer>
      <MobileBookThumbnailLayout>
        <EditThumbnailOverlay>
          <BookThumbnail {...thumbnailProps} />
        </EditThumbnailOverlay>
      </MobileBookThumbnailLayout>

      <MobileBookMetaInfoContainer>
        <MetaInfoTitle>{titleName}</MetaInfoTitle>
        <PurchasedInfo>{purchasedInfo}</PurchasedInfo>
        <LimitDate>{limitDayText}</LimitDate>
        {(isDailyFree || isDailyFreePlus) && (
          <DailyFreeBadge
            badgeType={isDailyFreePlus ? 'plus' : 'normal'}
            badgeSize="small"
          />
        )}
      </MobileBookMetaInfoContainer>
    </HorizontalContainer>
  );
};

export default PurchasedBookCardMobile;
