import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import Icon, { Icons } from '../../../../shared/components/Common/Icon';
import { globalMessages } from '../../../../shared/constants/messages';
import { useUserInfo } from '../../../../shared/hooks/useUserInfo';
import { COLORS } from '../../../../shared/styles/theme';
import { useTreasureDataClient } from '../../../Log/useTreasureDataClient';

const messages = defineMessages({
  videoText: {
    id: 'share.text.video',
    defaultMessage: '「{name}」をU-NEXTで視聴',
  },
  bookChapterText: {
    id: 'share.text.bookChapter',
    defaultMessage: '「{sakuhinName} ({name})」をU-NEXTで閲覧',
  },
  bookVolumeText: {
    id: 'share.text.bookVolume',
    defaultMessage: '「{name}」をU-NEXTで閲覧',
  },
});

const ShareTag = styled.a`
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background: ${COLORS.white_10};
  cursor: pointer;

  :hover,
  :focus {
    background: ${COLORS.white_30};
  }

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);

  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const SHARE_TITLE_SUFFIX = '・ユーネクスト';
const FACEBOOK_SHARE = 'フェイスブック';
const TWITTER_SHARE = 'tweetwindow';

const onClickTwitter = ({
  shareUrl,
  multiAccountId,
  shareText,
}: {
  shareUrl: string;
  multiAccountId: string;
  shareText: string;
}) => {
  const encodeTwitterText = encodeURIComponent(shareText);
  window.open(
    `http://twitter.com/intent/tweet?url=${shareUrl}?utm_source=twitter%26utm_medium=social%26utm_campaign=nonad-sns${
      multiAccountId ? `%26rid=${multiAccountId}` : ''
    }&text=${encodeTwitterText}&count=horizontal&via=watch_UNEXT&hashtags=UNEXT`,
    TWITTER_SHARE,
    'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1, resizable=1'
  );
  return false;
};

const onClickFacebook = ({
  shareUrl,
  name,
  multiAccountId,
}: {
  shareUrl: string;
  name: string;
  multiAccountId: string;
}) => {
  const facebookText = `${name}${SHARE_TITLE_SUFFIX}`;
  const encodeFacebookText = encodeURIComponent(facebookText);
  window.open(
    `http://www.facebook.com/sharer.php?u=${shareUrl}?utm_source=facebook%26utm_medium=social%26utm_campaign=nonad-sns${
      multiAccountId ? `%26rid=${multiAccountId}` : ''
    }&t=${encodeFacebookText}`,
    FACEBOOK_SHARE,
    'width=450,height=300'
  );
  return false;
};

const getLineShareUrl = ({
  shareUrl,
  multiAccountId,
  shareText,
}: {
  shareUrl: string;
  multiAccountId: string;
  shareText: string;
}) => {
  const lineText = `${shareUrl}?utm_source=lineshare&utm_medium=social&utm_campaign=nonad-sns${
    multiAccountId ? `&rid=${multiAccountId}` : ''
  } ${shareText}`;
  const encodeLineText = encodeURIComponent(lineText);
  return `https://social-plugins.line.me/lineit/share?url=${encodeLineText}`;
};

const getHatenaShareUrl = ({
  shareUrl,
  name,
}: {
  shareUrl: string;
  name: string;
}) => {
  const hatenaText = `${name}${SHARE_TITLE_SUFFIX}`;
  const encodeHatenaText = encodeURIComponent(hatenaText);
  return `http://b.hatena.ne.jp/add?mode=confirm&title=${encodeHatenaText}&url=${shareUrl}`;
};

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const onClickCopyButton = ({
  shareUrl,
  multiAccountId,
  shareText,
}: {
  shareUrl: string;
  multiAccountId: string;
  shareText: string;
}) => {
  const text = `${shareText} ${shareUrl}?utm_source=copy&utm_medium=social&utm_campaign=nonad-sns${
    multiAccountId ? `&rid=${multiAccountId}` : ''
  }`;
  navigator.clipboard.writeText(text);
};

interface ShareLinkProps {
  type: 'facebook' | 'twitter' | 'hatena' | 'line' | 'copy';
  contentType: 'video' | 'book';
  shareUrl?: string;
  name: string;
  sakuhinName?: string;
  multiAccountId?: string;
  titleCode?: string;
}

const ShareLink: React.FC<ShareLinkProps> = ({
  type,
  contentType,
  shareUrl = '',
  multiAccountId = '',
  name,
  sakuhinName = '',
  titleCode,
}) => {
  let iconName = null;
  let href;
  let onClick: () => void;
  let linkText = null;
  const intl = useIntl();

  const { data: userInfoData, loading: userInfoLoading } = useUserInfo({
    fetchPolicy: 'cache-first',
  });
  const treasureDataClient = useTreasureDataClient(
    {
      userInfo: userInfoData?.userInfo,
    },
    userInfoLoading
  );

  const shareText =
    contentType === 'video'
      ? intl.formatMessage(messages.videoText, { name })
      : sakuhinName
      ? intl.formatMessage(messages.bookChapterText, { name, sakuhinName })
      : intl.formatMessage(messages.bookVolumeText, { name });

  switch (type) {
    case 'facebook':
      iconName = Icons.SNS_FACEBOOK;
      onClick = () => {
        onClickFacebook({ shareUrl, name, multiAccountId });
      };
      linkText = intl.formatMessage(globalMessages.facebook);
      break;
    case 'twitter':
      linkText = intl.formatMessage(globalMessages.twitter);
      iconName = Icons.SNS_X;
      onClick = () => {
        onClickTwitter({ shareUrl, shareText, multiAccountId });
      };
      break;
    case 'hatena':
      linkText = intl.formatMessage(globalMessages.hatena);
      iconName = Icons.SNS_HATENA;
      href = getHatenaShareUrl({ shareUrl, name });
      break;
    case 'line':
      linkText = intl.formatMessage(globalMessages.line);
      iconName = Icons.SNS_LINE;
      href = getLineShareUrl({ shareUrl, shareText, multiAccountId });
      break;
    case 'copy':
      linkText = intl.formatMessage(globalMessages.copyUrl);
      iconName = Icons.COPY;
      onClick = () => {
        onClickCopyButton({ shareUrl, shareText, multiAccountId });
      };
      break;
    default:
      // eslint-disable-next-line no-console
      console.error('ShareLink type doesnot exist');
      break;
  }

  return (
    <ShareTag
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      onClick={() => {
        if (treasureDataClient && titleCode) {
          treasureDataClient.trackAdhocWebEvent({
            scenarioName: `video title detail ${type}`,
            freeText: titleCode,
          });
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {iconName && <StyledIcon name={iconName} />}
      <Title>{linkText}</Title>
    </ShareTag>
  );
};

export default ShareLink;
