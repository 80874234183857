import { cosmo_getBookCategoryBlockQuery } from '../../__generated__/globalTypes';
import { GenreUrl } from '../../shared/constants';
import { Nullable } from '../../utils/Nullable';

export const PAGE_SIZE = 5;

export const MAX_CHARACTER_BLOCK_SIZE = 24;

export const TITLES_PER_BLOCK = 15;
export const LIVES_PER_BLOCK = 20;

export const BLOCKS_MARGIN = 48; // ブロック間の垂直マージン
export const BLOCKS_MARGIN_MOBILE = 40; // ブロック間の垂直マージン for mobile

export const HEADING_MARGIN_BOTTOM = 16; // SliderとHeading間の垂直マージン
export const HEADING_MARGIN_BOTTOM_MOBILE = 10; // SliderとHeading間の垂直マージン for mobile

export interface GenreProps {
  genreDisplayCode?: Nullable<keyof typeof GenreUrl>;
  pageNumber?: number;
}

export enum GenreUrlPrefix {
  VIDEO = '',
  SEMIADULT = '',
  BOOK = '/book',
  LIVE = '/live',
}

export const mapBookSearchCategoryBlockToSearchGenre = (
  block?: Nullable<cosmo_getBookCategoryBlockQuery['bookSearchCategoryBlock']>
):
  | {
      id: string;
      name: string;
      menuTypeCode: string;
      searchCategories: { id: string; name: string }[];
    }
  | undefined => {
  if (!block) return undefined;
  return {
    id: block.code,
    name: '',
    menuTypeCode: 'BOOK',
    searchCategories: block.menuList.map((menu) => ({
      id: menu.code,
      name: menu.name,
    })),
  };
};
