/**
 * Helps type objects with an abitrary number of properties that are
 * usually being defined at export.
 *
 * @param component Main object you want to apply properties to
 * @param properties Object of properties you want to type on the main component
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function withProperties<A extends {}, B extends {}>(
  component: A,
  properties: B
): A & B {
  (Object.keys(properties) as (keyof B)[]).forEach((key) => {
    Object.assign(component, { [key]: properties[key] });
  });
  return component as A & B;
}

/**
 * A helper function to discriminate between union types
 * Useful for union types that have a common property, for instance, response from GraphQL API
 * @source https://stackoverflow.com/questions/59050071/narrow-return-type-of-find-from-discriminated-union-array
 * @param discriminantKey e.g. __typename
 * @param discriminantValue
 * @example list: Array<{ type: 'A } | { type: 'B' }>; list.filter(discriminate('type', 'A')) -> list become Array<{ type: 'A' }>
 */
export function discriminate<
  K extends PropertyKey,
  V extends string | number | boolean
>(discriminantKey: K, discriminantValue: V) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <T extends Record<K, any>>(
    obj: T & Record<K, V extends T[K] ? T[K] : V>
  ): obj is Extract<T, Record<K, V>> =>
    obj[discriminantKey] === discriminantValue;
}
