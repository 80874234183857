import { ApolloError } from '@apollo/client';

interface ExtendedApolloErrorData {
  errorCode?: string;
  customTitle?: string;
  customMessage?: string;
  withoutUI?: boolean;
}

class ExtendedApolloError extends ApolloError {
  public errorCode?: string;
  public customTitle?: string;
  public customMessage?: string;
  public withoutUI?: boolean;

  constructor(
    apolloErrorOptions: ConstructorParameters<typeof ApolloError>[0],
    extendedData: ExtendedApolloErrorData
  ) {
    super(apolloErrorOptions);
    this.name = 'ExtendedApolloError';
    this.errorCode = extendedData.errorCode;
    this.customTitle = extendedData.customTitle;
    this.customMessage = extendedData.customMessage;
    this.withoutUI = extendedData.withoutUI;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).__proto__ = ExtendedApolloError.prototype;
  }
}

export function createExtendedApolloError(
  originalError: ApolloError,
  extendedData: ExtendedApolloErrorData
): ExtendedApolloError {
  return new ExtendedApolloError(originalError, extendedData);
}

export function isExtendedApolloError(
  error: unknown
): error is ExtendedApolloError {
  return (error as ExtendedApolloError).name === 'ExtendedApolloError';
}
