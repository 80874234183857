import React from 'react';
import styled from 'styled-components';
import Icon, { Icons } from '../Icon';

type RatingStarType = 'primary' | 'static' | 'dynamic';

interface StyledRatingProps {
  type: RatingStarType;
}

const StyledRating = styled(Icon)<StyledRatingProps>`
  color: ${({ theme, type }) => {
    switch (type) {
      case 'static':
        return theme.ratingStar.static;
      case 'dynamic':
        return theme.ratingStar.dynamic;
      case 'primary':
        return theme.ratingStar.primary;
    }
  }};
`;

const RatingStarContainer = styled.div<{ iconWidth: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    width: ${({ iconWidth }) => iconWidth}px;
  }
`;

interface RatingStarProps {
  type?: RatingStarType;
  rate?: number;
  iconWidth: number;
}

const RatingStar: React.FC<RatingStarProps> = ({
  type = 'static',
  rate: rateProp = 0,
  iconWidth,
}) => {
  const rate = Math.min(50, Math.max(0, rateProp));
  const numberOfStars = Math.floor(rate / 10);
  const numberOfBlanks = Math.floor((50 - rate) / 10);
  const hasHalf = 5 - numberOfStars - numberOfBlanks > 0;

  const star = [];
  for (let i = 0; i < numberOfStars; ++i) {
    star.push(<StyledRating key={i} name={Icons.STAR_FULL} type={type} />);
  }

  const half = hasHalf ? (
    <StyledRating name={Icons.STAR_HALF} type={type} />
  ) : null;

  const blank = [];
  for (let i = 0; i < numberOfBlanks; ++i) {
    blank.push(<Icon key={i} name={Icons.STAR_BLANK} />);
  }

  return (
    <RatingStarContainer iconWidth={iconWidth}>
      {star}
      {half}
      {blank}
    </RatingStarContainer>
  );
};

export default RatingStar;
