import { ApolloCache, useMutation } from '@apollo/client';
import { useToasts } from '@u-next/react-toast-notifications';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_bookTitleDetailQuery,
  cosmo_updateBookTitleEvaluationMutation,
  cosmo_updateBookTitleEvaluationMutationVariables,
  cosmo_updateBookTitleFavoriteMutation,
  cosmo_updateBookTitleFavoriteMutationVariables,
  EvaluationTypes,
  FavoriteTypes,
} from '../../../__generated__/globalTypes';
import { SNS_SHARE_HOST } from '../../../shared/constants';
import { errorMessages } from '../../../shared/constants/messages';
import { useUserInfo } from '../../../shared/hooks/useUserInfo';
import UserAgentContext from '../../../shared/UserAgentContext';
import { Nullable } from '../../../utils/Nullable';
import { useTreasureDataClient } from '../../Log/useTreasureDataClient';
import { GET_FAVORITES_BOOK } from '../../MyList/gql';
import UserButtonsGroup from '../shared/UserButtonsGroup';
import {
  UPDATE_BOOK_TITLE_BOOKMARK,
  UPDATE_BOOK_TITLE_EVALUATION,
} from './gql';

export interface BookUserButtonsGroupProps {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
  onUpdateBookMark: (
    cache: ApolloCache<cosmo_updateBookTitleFavoriteMutation>,
    favorite: cosmo_updateBookTitleFavoriteMutation['updateBookTitleFavorite']
  ) => void;
  onUpdateBookEvaluation: (
    cache: ApolloCache<cosmo_updateBookTitleEvaluationMutation>,
    evaluation: cosmo_updateBookTitleEvaluationMutation['updateBookTitleEvaluation']
  ) => void;
}

const BookUserButtonsGroup: React.FC<BookUserButtonsGroupProps> = ({
  bookSakuhin,
  onUpdateBookMark,
  onUpdateBookEvaluation,
}) => {
  const intl = useIntl();
  const [updateTitleBookmarkFailed, setUpdateTitleBookmarkFailed] =
    useState(false);
  const { data: userInfoData, loading: userInfoLoading } = useUserInfo({
    fetchPolicy: 'cache-first',
  });
  const userInfo = userInfoData?.userInfo;
  const { hasUserCookie } = useContext(UserAgentContext);
  const isLoggedIn = !!((!userInfoLoading && !!userInfo?.id) || hasUserCookie);
  const treasureDataClient = useTreasureDataClient(
    {
      userInfo,
    },
    userInfoLoading
  );
  const { addToast, removeToast } = useToasts();
  const [updateBookTitleFavorite] = useMutation<
    cosmo_updateBookTitleFavoriteMutation,
    cosmo_updateBookTitleFavoriteMutationVariables
  >(UPDATE_BOOK_TITLE_BOOKMARK, {
    update(cache, { data }) {
      if (!data || !data.updateBookTitleFavorite) {
        setUpdateTitleBookmarkFailed(true);
        return null;
      }
      onUpdateBookMark(cache, data.updateBookTitleFavorite);
    },
    onCompleted: () => setUpdateTitleBookmarkFailed(false),
    onError: () => setUpdateTitleBookmarkFailed(true),
    refetchQueries: [GET_FAVORITES_BOOK],
  });
  const [evaluationFailed, setEvaluationFailed] = useState(false);
  const [updateBookTitleEvaluation] = useMutation<
    cosmo_updateBookTitleEvaluationMutation,
    cosmo_updateBookTitleEvaluationMutationVariables
  >(UPDATE_BOOK_TITLE_EVALUATION, {
    update(cache, { data }) {
      if (!data || !data.updateBookTitleEvaluation) {
        setEvaluationFailed(true);
        return null;
      }
      onUpdateBookEvaluation(cache, data.updateBookTitleEvaluation);
    },
    onCompleted: () => setEvaluationFailed(false),
    onError: () => setEvaluationFailed(true),
  });

  const mylistToastId = useRef<Nullable<string>>(null);
  useEffect(() => {
    if (!updateTitleBookmarkFailed && mylistToastId.current) {
      removeToast(mylistToastId.current);
      mylistToastId.current = null;
    }
    if (updateTitleBookmarkFailed) {
      addToast(
        intl.formatMessage(errorMessages.mylistUpdateToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          mylistToastId.current = id;
        }
      );
    }
  }, [updateTitleBookmarkFailed, addToast, removeToast, mylistToastId, intl]);

  const evaluationToastId = useRef<Nullable<string>>(null);
  useEffect(() => {
    if (!evaluationFailed && evaluationToastId.current) {
      removeToast(evaluationToastId.current);
      evaluationToastId.current = null;
    }
    if (evaluationFailed) {
      addToast(
        intl.formatMessage(errorMessages.evaluationUpdateToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          evaluationToastId.current = id;
        }
      );
    }
  }, [evaluationFailed, addToast, removeToast, evaluationToastId, intl]);
  return (
    <UserButtonsGroup
      contentType="book"
      isLoggedIn={isLoggedIn}
      shareUrl={`${SNS_SHARE_HOST}/book/title/${bookSakuhin.sakuhinCode}/${bookSakuhin.book?.code}`}
      name={bookSakuhin.book?.name ?? ''}
      sakuhinName={bookSakuhin.isChapter ? bookSakuhin.name : undefined}
      multiAccountId={userInfo?.multiAccountId ?? undefined}
      userRate={bookSakuhin.userRate || undefined}
      setEvaluation={(newUserRate) => {
        updateBookTitleEvaluation({
          variables: {
            bookSakuhinId: bookSakuhin.sakuhinCode,
            type: (newUserRate
              ? `RATE_${newUserRate / 10}`
              : 'RATE_0') as EvaluationTypes,
          },
          optimisticResponse: {
            updateBookTitleEvaluation: {
              __typename: 'BookSakuhin',
              code: bookSakuhin.sakuhinCode,
              userRate: newUserRate,
              rate: bookSakuhin.rate,
              name: bookSakuhin.name,
            },
          },
        }).then((response) => {
          if (response?.data && treasureDataClient) {
            treasureDataClient.trackBookEvaluation(
              {
                bookSakuhinCode: bookSakuhin.sakuhinCode,
                bookSakuhinName: bookSakuhin.name,
              },
              newUserRate / 10
            );
          }
        });
      }}
      isFavorite={bookSakuhin.isFavorite}
      toggleFavorite={() => {
        updateBookTitleFavorite({
          variables: {
            bookSakuhinId: bookSakuhin.sakuhinCode,
            type: bookSakuhin.isFavorite
              ? FavoriteTypes.REMOVE
              : FavoriteTypes.ADD,
          },
          optimisticResponse: {
            updateBookTitleFavorite: {
              __typename: 'BookSakuhin',
              code: bookSakuhin.sakuhinCode,
              isFavorite: !bookSakuhin.isFavorite,
            },
          },
        });
      }}
      mylistBtnComponentName="bookUserButtonsGroup-mylist-btn"
      rateBtnComponentName="bookUserButtonsGroup-rate-btn"
      shareBtnComponentName="bookUserButtonsGroup-share-btn"
    />
  );
};

export default BookUserButtonsGroup;
