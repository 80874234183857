import { ButtonHTMLAttributes, FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Icon, { Icons } from '../../../../shared/components/Common/Icon';
import { globalMessages } from '../../../../shared/constants/messages';
import { COLORS } from '../../../../shared/styles/theme';
import { Nullable } from '../../../../utils/Nullable';

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  transition: all 250ms ease-in;
`;

const IconArea = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`;

const Container = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  transition: all 0.15s ease-in;

  color: ${COLORS.white};
  font-size: 12px;

  &:hover {
    background-color: ${COLORS.white_15};
  }
`;

interface MylistButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isFavorite: Nullable<boolean>;
}

export const MylistButton: FC<MylistButtonProps> = ({
  isFavorite = false,
  ...props
}) => {
  const intl = useIntl();
  const addIconStyle = isFavorite
    ? {
        opacity: 0,
        transform: 'scale(0.6) rotate(50deg)',
        transitionTimingFunction: 'ease-out',
      }
    : {
        opacity: 1,
        transform: 'scale(1) rotate(0deg)',
        transitionDelay: '200ms',
        transitionDuration: '150ms',
      };
  const checkIconStyle = !isFavorite
    ? {
        opacity: 0,
        transform: 'scale(0.6) rotate(-50deg)',
        transitionDuration: '150ms',
        transitionTimingFunction: 'ease-out',
      }
    : {
        opacity: 1,
        transform: 'scale(1) rotate(0deg)',
        transitionDelay: '300ms',
        transitionDuration: '200ms',
      };
  return (
    <Container {...props}>
      <IconArea>
        <StyledIcon name={Icons.ADDED} style={checkIconStyle} />
        <StyledIcon name={Icons.ADD} style={addIconStyle} />
      </IconArea>
      <span>{intl.formatMessage(globalMessages.mylist)}</span>
    </Container>
  );
};

export const ShareButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  const intl = useIntl();
  return (
    <Container {...props}>
      <IconArea>
        <StyledIcon name={Icons.SHARE} />
      </IconArea>
      <span>{intl.formatMessage(globalMessages.share)}</span>
    </Container>
  );
};

interface RateButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  rate: number;
}

export const RateButton: FC<RateButtonProps> = ({ rate, ...props }) => {
  const intl = useIntl();
  const enterStyle = { transitionDelay: '200ms' };
  const exitStyle = { transform: 'scale(0, 1)' };
  const fillIconStyle = rate ? enterStyle : exitStyle;
  const ghostIconStyle = rate ? exitStyle : enterStyle;
  return (
    <Container {...props}>
      <IconArea>
        <StyledIcon name={Icons.RATED} style={fillIconStyle} />
        <StyledIcon name={Icons.RATE} style={ghostIconStyle} />
      </IconArea>
      <span>
        {rate > 0 ? `${rate}/5` : intl.formatMessage(globalMessages.rate)}
      </span>
    </Container>
  );
};
