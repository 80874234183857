import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  genreContainerTitle: {
    id: 'search.genreContainer.title',
    defaultMessage: '一覧から探す',
  },
  backText: {
    id: 'search.freeword.back',
    defaultMessage: '「{query}」との一致',
  },
  creditTitle: {
    id: 'freeword.credit.title',
    defaultMessage: '人物',
  },
});
