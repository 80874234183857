import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { ComponentProps, Fragment, ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, useIntl } from 'react-intl';
import {
  cosmo_bookTitleBooksQuery,
  cosmo_getVideoTitleEpisodesQuery,
} from '../../../../__generated__/globalTypes';
import AkamaiImage from '../../../../shared/components/Common/AkamaiImage';
import { PurchasedBadge } from '../../../../shared/components/Common/Badge/PurchasedBadge';
import Icon, { Icons } from '../../../../shared/components/Common/Icon';
import { FLOATING_FOOTER_ID } from '../../../../shared/components/Layout';
import { StatelessCapsuleTabs } from '../../../../shared/components/Tabs/Capsule';
import TitleLink from '../../../../shared/components/TitleLink';
import { UrlQueryParams } from '../../../../shared/constants';
import useElementById from '../../../../shared/hooks/useElementById';
import useEpisodePagingTabList from '../../../../shared/hooks/useEpisodePagingTabList';
import useMedia from '../../../../shared/hooks/useMedia';
import { DEVICE } from '../../../../shared/styles';
import { Maybe, Nullable } from '../../../../utils/Nullable';
import { mergeQueryString } from '../../../../utils/routeHelper';
import {
  EpisodeListContainer,
  EpisodeListItemContainer,
  EpisodeSection,
  FooterTabsContainer,
  GroupGrid,
  GroupImageContainer,
  GroupInfo,
  GroupInfoActiveIcon,
  GroupInfoIcon,
  GroupInfoLink,
  GroupInfoTitle,
  GroupItems,
  HeaderContainer,
  Heading,
  HeadingContainer,
  HeadingTextContainer,
  ItemsCount,
  MissingEpisodeText,
  NextUpdateText,
  ShowAllButton,
  TabsContainer,
} from './Components';

const messages = defineMessages({
  readVolumeBook: {
    id: 'bookList,readVolume',
    defaultMessage: '単行本で読む',
  },
});

type BookItem = NonNullable<
  cosmo_bookTitleBooksQuery['bookTitle_books']
>['books'][number];
type VideoItem = NonNullable<
  cosmo_getVideoTitleEpisodesQuery['webfront_title_titleEpisodes']
>['episodes'][number];
type Item = BookItem | VideoItem | NextChapterItem;

type NextChapterItem = { updateText: string };
interface CellRenderArgs<T> {
  item: Maybe<T>;
  index: number;
  loading: boolean;
}

export type StackedPagerPagingStyle = 'topBottomTabs' | 'footerTabs' | 'none';

type StackedEpisode<T> = T & { id?: string; code?: string };
type StackedGroup<T> = {
  code?: string;
  info: {
    image: string;
    title: string;
    titleLinkProps: ComponentProps<typeof TitleLink>;
  } | null;
  items: StackedEpisode<T>[];
};

export type StackedBookItem = StackedEpisode<BookItem | NextChapterItem>;
export type StackedBookGroup = StackedGroup<BookItem | NextChapterItem>;

interface CommonProps<T> {
  items?: StackedEpisode<T>[];
  groups?: StackedGroup<T>[];
  page?: number;
  cellRender: ({ item, index, loading }: CellRenderArgs<T>) => ReactNode;
  headingText: string;
  showItemsCount?: boolean;
  itemsCountText?: string;
  missingAlertText?: Nullable<string>;
  nextUpdateText?: Nullable<string>;
  postCellRender?: ReactNode;
  componentName?: string;
  totalItemsCount: number;
  purchasedItemsCount?: number;
  isBookChapter?: boolean;
  pagingStyle?: StackedPagerPagingStyle;
  showAllLinkProps?: {
    text: string;
  } & LinkProps;
  // Set true to hide round box
  hideBoxUI: boolean;
  paginationQueryName?:
    | UrlQueryParams.EPISODE_TAB
    | UrlQueryParams.ED_EPISODE_TAB;
}

interface StackedItemPagerProps<T> extends CommonProps<T> {
  items: StackedEpisode<T>[];
}

interface StackedGroupPagerProps<T> extends CommonProps<T> {
  groups: StackedGroup<T>[];
}

type StackedPagerProps<T> =
  | StackedItemPagerProps<T>
  | StackedGroupPagerProps<T>;

const StackedPager: <T extends Item>(
  p: StackedPagerProps<T>
) => React.ReactElement<StackedPagerProps<T>> = ({
  items,
  groups,
  page = 1,
  totalItemsCount,
  purchasedItemsCount,
  isBookChapter,
  cellRender,
  headingText,
  showItemsCount = true,
  itemsCountText,
  missingAlertText,
  nextUpdateText,
  postCellRender,
  pagingStyle = 'topBottomTabs',
  showAllLinkProps,
  componentName,
  hideBoxUI,
  paginationQueryName = UrlQueryParams.EPISODE_TAB,
}) => {
  const intl = useIntl();
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );
  const sectionTopRef = useRef<HTMLDivElement>(null);
  const activeIndex = page - 1;

  const { pagingTabList, showPagingIndicator } =
    useEpisodePagingTabList(totalItemsCount);

  const router = useRouter();

  const scrollToTop = () => sectionTopRef.current?.scrollIntoView();

  const TabsProps = {
    activeIndex: activeIndex,
    tabs: pagingTabList.map((text, index) => ({
      text,
      href: mergeQueryString(router, {
        [paginationQueryName]: String(index),
      }).path,
    })),
    defaultActiveIndex: activeIndex,
  };
  const hasGroupInfoColumn = !!groups?.some(({ info }) => !!info); // infoのあるgroupが一つでも存在すれば、info用columnを表示する
  const footerContainer = useElementById(FLOATING_FOOTER_ID);

  return (
    <>
      <EpisodeSection ref={sectionTopRef} hideBoxUI={hideBoxUI}>
        {pagingStyle !== 'footerTabs' && (
          <HeaderContainer>
            <HeadingTextContainer>
              <HeadingContainer>
                <Heading>{headingText}</Heading>
                {showItemsCount && itemsCountText && (
                  <ItemsCount>{itemsCountText}</ItemsCount>
                )}
              </HeadingContainer>
              {missingAlertText && (
                <MissingEpisodeText>{missingAlertText}</MissingEpisodeText>
              )}
              {nextUpdateText && (
                <NextUpdateText>
                  <Icon name={Icons.INFO} />
                  {nextUpdateText}
                </NextUpdateText>
              )}
              {!!purchasedItemsCount && purchasedItemsCount > 0 && (
                <PurchasedBadge
                  meta={{ purchasedNum: purchasedItemsCount }}
                  isChapter={isBookChapter}
                />
              )}
            </HeadingTextContainer>
            {showPagingIndicator && pagingStyle === 'topBottomTabs' && (
              <TabsContainer data-ucn={componentName}>
                <StatelessCapsuleTabs {...TabsProps} />
              </TabsContainer>
            )}
          </HeaderContainer>
        )}

        <EpisodeListContainer>
          {groups && (
            <GroupGrid hasInfoColumn={hasGroupInfoColumn}>
              {groups.map((group, index) => {
                return (
                  <Fragment key={index}>
                    {(isMobile ? group.info : hasGroupInfoColumn) && (
                      <GroupInfo>
                        {group.info && (
                          <>
                            <GroupImageContainer>
                              <AkamaiImage
                                src={group.info.image}
                                availableSizes={[128, 256]}
                                sizeConfig={{
                                  SD: {
                                    width: 160,
                                  },
                                  MOBILE_WIDE: {
                                    width: 126,
                                  },
                                }}
                                alt={group.info.title}
                                letterbox="5:7"
                              />
                            </GroupImageContainer>
                            {isMobile && (
                              <GroupInfoTitle>
                                {group.info.title}
                              </GroupInfoTitle>
                            )}
                            <GroupInfoLink>
                              <TitleLink {...group.info.titleLinkProps}>
                                <div>
                                  {isMobile
                                    ? intl.formatMessage(
                                        messages.readVolumeBook
                                      )
                                    : group.info.title}
                                </div>
                                <GroupInfoIcon name={Icons.PUSH_LINK} />
                                <GroupInfoActiveIcon
                                  name={Icons.PUSH_LINK_ACTIVE}
                                />
                              </TitleLink>
                            </GroupInfoLink>
                          </>
                        )}
                      </GroupInfo>
                    )}
                    <div>
                      <GroupItems>
                        {group.items.map((item, index) => (
                          <EpisodeListItemContainer
                            key={item.code || item.id || index}
                          >
                            {cellRender({
                              item,
                              index,
                              loading: !item,
                            })}
                          </EpisodeListItemContainer>
                        ))}
                      </GroupItems>
                    </div>
                  </Fragment>
                );
              })}
            </GroupGrid>
          )}
          {items?.map((item, index) => {
            return (
              <EpisodeListItemContainer key={item.code || item.id || index}>
                {cellRender({
                  item,
                  index,
                  loading: !item,
                })}
              </EpisodeListItemContainer>
            );
          })}
          {postCellRender && page === pagingTabList.length && (
            <EpisodeListItemContainer>
              {postCellRender}
            </EpisodeListItemContainer>
          )}
          {showAllLinkProps && (
            <Link {...showAllLinkProps} scroll={false}>
              <ShowAllButton
                buttonSize="large"
                buttonSizeMobile="medium"
                buttonTheme="ghost"
                icon={Icons.EPISODE}
                text={showAllLinkProps.text}
              />
            </Link>
          )}
        </EpisodeListContainer>
        {showPagingIndicator && pagingStyle === 'topBottomTabs' && (
          <TabsContainer>
            <StatelessCapsuleTabs {...TabsProps} onTabItemClick={scrollToTop} />
          </TabsContainer>
        )}
      </EpisodeSection>
      {showPagingIndicator &&
        pagingStyle === 'footerTabs' &&
        footerContainer &&
        ReactDOM.createPortal(
          <FooterTabsContainer>
            <StatelessCapsuleTabs {...TabsProps} onTabItemClick={scrollToTop} />
          </FooterTabsContainer>,
          footerContainer
        )}
    </>
  );
};

export default StackedPager;
