import { useEffect, useState } from 'react';

const useElementById = (id: string): HTMLElement | undefined => {
  const [element, setElement] = useState<HTMLElement>();

  useEffect(() => {
    const element = document.getElementById(id);
    setElement(element || undefined);
  }, [id]);
  return element;
};

export default useElementById;
