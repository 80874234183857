import Head from 'next/head';
import Link from 'next/link';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import UnextLogo from '../../../assets/icons/logo/Logo_U-NEXT_Horizontal.svg';
import { withProperties } from '../../../utils/typeHelper';
import { GlobalConfig } from '../../constants';
import { DEVICE, GlobalStyle } from '../../styles';
import { HEADER_HEIGHT, HEADER_HEIGHT_SD } from '../../styles/constants';
import { COLORS } from '../../styles/theme';
import BackgroundPattern from '../BackgroundPattern';
import { ButtonLookDiv } from '../Common/Button';
import { Icons } from '../Common/Icon';

const messages = defineMessages({
  defaultTitle: {
    id: 'error.default.title',
    defaultMessage: 'エラーが発生しました',
  },
  defaultDescription: {
    id: 'error.default.description',
    defaultMessage:
      'サーバへのアクセスが混み合っているか、\n メンテナンス中の可能性があります。\n しばらく時間をおいてからもう一度アクセスしてください。',
  },
  home: {
    id: 'error.home',
    defaultMessage: 'ホームへ',
    description: 'Text for the home link in the error page',
  },
  help: {
    id: 'error.search',
    defaultMessage: 'ヘルプセンター',
    description: 'Text for the help link in the error page',
  },
  account: {
    id: 'error.account',
    defaultMessage: 'アカウント設定',
    description: 'Text for the account link in the error page',
  },
  points: {
    id: 'error.points',
    defaultMessage: 'U-NEXTポイント',
    description: 'Text for the points link in the error page',
  },
});

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding-top: ${HEADER_HEIGHT}px;

  @media ${DEVICE.sd} {
    padding-top: ${HEADER_HEIGHT_SD}px;
  }
`;

const LogoContainer = styled.a`
  @media ${DEVICE.sd} {
    display: none;
  }

  svg {
    height: 34px;
    width: auto;
  }
`;

const ErrorContent = styled.div`
  flex: 1;
  margin-top: 128px;
  margin-bottom: 64px;
  width: 100%;
  max-width: 468px;
  padding: 0 32px;
  box-sizing: border-box;
`;

const Content = withProperties(ErrorContent, {
  Title: styled.div`
    color: ${COLORS.white};
    font-size: 24px;
    font-weight: 600;
    align-self: flex-start;
  `,
  Description: styled.div`
    margin-top: 32px;
    font-size: 14px;
    align-self: flex-start;
    white-space: pre-line;
    color: ${COLORS.white};
    @media ${DEVICE.mobileWide} {
      white-space: normal;
    }
  `,
  Code: styled.div`
    margin-top: 24px;
    color: ${COLORS.white_50};
    font-size: 16px;
  `,
});

const LinksContainer = styled.div`
  margin-top: 24px;
`;

const StyledButtonLookDiv = styled(ButtonLookDiv)`
  width: 100%;
  margin-bottom: 16px;
`;

export interface ErrorComponentProps {
  errorTitle?: string;
  errorMessage?: string;
  errorCode?: string | number;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  errorTitle,
  errorMessage,
  errorCode,
}) => {
  const intl = useIntl();
  let title = errorTitle;
  let message = errorMessage;

  if (errorTitle === undefined) {
    title = intl.formatMessage(messages.defaultTitle);
    message = intl.formatMessage(messages.defaultDescription);
  }

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <GlobalStyle />
      <BackgroundPattern />
      <ErrorContainer>
        <Link href="/" passHref legacyBehavior>
          <LogoContainer>
            <UnextLogo alt="U-Next Logo" />
          </LogoContainer>
        </Link>
        <Content>
          <Content.Title>{title}</Content.Title>
          {message && <Content.Description>{message}</Content.Description>}
          {errorCode && <Content.Code>({errorCode})</Content.Code>}
          <LinksContainer>
            <a href={GlobalConfig.URLS.HELP}>
              <StyledButtonLookDiv
                buttonTheme="light"
                buttonSize="large"
                icon={Icons.HELP}
                text={intl.formatMessage(messages.help)}
              />
            </a>
            <a href={GlobalConfig.URLS.MYACCOUNT}>
              <StyledButtonLookDiv
                buttonTheme="light"
                buttonSize="large"
                icon={Icons.ACCOUNT}
                text={intl.formatMessage(messages.account)}
              />
            </a>
            <a href={`${GlobalConfig.URLS.MYACCOUNT}/unext-point`}>
              <StyledButtonLookDiv
                buttonTheme="light"
                buttonSize="large"
                icon={Icons.POINT_CHARGE}
                text={intl.formatMessage(messages.points)}
              />
            </a>
            <Link href="/" passHref>
              <StyledButtonLookDiv
                buttonTheme="ghost"
                buttonSize="large"
                icon={Icons.U_MARK}
                text={intl.formatMessage(messages.home)}
              />
            </Link>
          </LinksContainer>
        </Content>
      </ErrorContainer>
    </>
  );
};

export default ErrorComponent;
