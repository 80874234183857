import { createContext, useContext } from 'react';

const defaultRemoteConfig: WebFrontRemoteConfig = {
  maintenanceMode: false,
  skipUserRecommend: false,
  floodGateControl: undefined,
  livePlaybackFailsafeConfigurations: undefined,
  adultBannerABTest: undefined,
  skipMoviePart: undefined,
  CMAFEnabled: undefined,
};

const RemoteConfigContext =
  createContext<WebFrontRemoteConfig>(defaultRemoteConfig);
RemoteConfigContext.displayName = 'RemoteConfigContext';

export const RemoteConfigProvider = RemoteConfigContext.Provider;

export const useRemoteConfig = (): WebFrontRemoteConfig => {
  const remoteConfig = useContext(RemoteConfigContext);
  return remoteConfig;
};
