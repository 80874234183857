import React from 'react';
import styled from 'styled-components';

interface CurrentProgressBarProps {
  percentage: number;
}

const ProgressBarContainer = styled.div`
  position: relative;
  height: 2px;
  background-color: ${({ theme }) => theme.progressBar.background};
  z-index: 999;
  border-radius: 100px;
`;

const CurrentProgressBar = styled.div<CurrentProgressBarProps>`
  position: absolute;
  width: ${({ percentage }) => percentage * 100}%;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.progressBar.currentBackground};
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-top-right-radius: ${({ percentage }) =>
    percentage === 1 ? 100 : 0}px;
  border-bottom-right-radius: ${({ percentage }) =>
    percentage === 1 ? 100 : 0}px;
`;

const ProgressBar: React.FC<{
  percentage: number;
  className?: string;
}> = ({ percentage = 0, className }) => {
  return (
    <ProgressBarContainer className={className}>
      <CurrentProgressBar percentage={percentage} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
