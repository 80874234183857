import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';
import { PurchasedBadge } from '../../../../../shared/components/Common/Badge/PurchasedBadge';
import { RoundBadge } from '../../../../../shared/components/Common/Badge/RoundBadge';
import ProgressBar from '../../../../../shared/components/Common/ProgressBar';
import TextEllipsis from '../../../../../shared/components/Common/TextEllipsis';
import PlayCard from '../../../../../shared/components/PlayCard';
import PointPriceBadge from '../../../../../shared/components/PointPriceBadge';
import { globalMessages } from '../../../../../shared/constants/messages';
import { handleOnlyOnEnter } from '../../../../../utils';
import { NullableArray } from '../../../../../utils/Nullable';
import {
  BadgeRow,
  Container,
  FooterText,
  ImageContent,
  ImageContentFooter,
  ImageContentText,
  ImagePlaceholder,
  Introduction,
  TextContent,
  TextPlaceholder,
  Title,
  type StackedItemProps,
} from './Components';

interface Props extends StackedItemProps {
  itemCode: string;
  minimumPrice: number | null;
  hasMultiprice?: boolean | null;
  isEpisodeDetail: boolean;
  showPointBadge?: boolean;
  episodeNotices?: NullableArray<string>;
  durationText?: string;
}

const StackedVideo: React.FC<Props> = (props) => {
  const {
    itemCode,
    parentCode,
    isPurchased,
    isNew,
    isPreorderable,
    isFree,
    minimumPrice,
    hasMultiprice,
    durationText = '',
    maxLines = 3,
    showPointBadge,
    titleText,
    contentText,
    saleText,
    episodeNotices,
    thumbnailUrl,
    isComplete,
    progress = 0,
    limitDay,
    onPlayClick,
    playComponentName,
    detailComponentName,
  } = props;

  const intl = useIntl();

  const readMoreText = [contentText, saleText]
    .concat(episodeNotices)
    .filter(Boolean)
    .join('\n\n');

  const href = `/play/${parentCode}/${itemCode}`;

  return (
    <Container>
      <TextContent type="video" data-ucn={detailComponentName}>
        {(isNew || isPreorderable) && (
          <RoundBadge badgeType="promotion" mobileBadgeSize="small">
            {intl.formatMessage(
              isPreorderable ? globalMessages.preorder : globalMessages.new
            )}
          </RoundBadge>
        )}
        <Title>
          <Link
            href="/episode/[episodeVideoTitleCode]/[episodeCode]"
            as={`/episode/${parentCode}/${itemCode}`}
            data-ucn="stackedVideo-title-link"
          >
            {titleText}
          </Link>
        </Title>
        <BadgeRow>
          <PointPriceBadge
            isPoint={!!showPointBadge}
            hasMultiplePrice={!!hasMultiprice}
            minimumPrice={minimumPrice ?? undefined}
            badgeSize="medium"
            mobileBadgeSize="small"
          />
          {isFree && (
            <RoundBadge badgeType="ghost_dark" mobileBadgeSize="small">
              {intl.formatMessage(globalMessages.freeBadge)}
            </RoundBadge>
          )}
          {isPurchased && <PurchasedBadge mobileBadgeSize="small" />}
        </BadgeRow>
        {readMoreText && (
          <Introduction>
            <TextEllipsis
              text={readMoreText}
              maxLine={maxLines}
              additionalLine={
                durationText ? (
                  <FooterText>{durationText}</FooterText>
                ) : undefined
              }
            />
          </Introduction>
        )}
      </TextContent>
      <ImageContent type="video" data-ucn={playComponentName}>
        <Link
          style={{
            display: 'block',
            position: 'relative',
          }}
          role="button"
          tabIndex={-1}
          onKeyDown={(e) => {
            handleOnlyOnEnter(e, onPlayClick);
          }}
          onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
            onPlayClick?.(e)
          }
          legacyBehavior={false}
          href={href}
          data-ucn="stackedVideo-thumbnail-link"
        >
          <PlayCard
            type="video"
            thumbnailUrl={thumbnailUrl || ''}
            titleName={titleText}
            isLazy
          />
        </Link>
        <ImageContentFooter>
          {(progress > 0 || isComplete) && (
            <ProgressBar
              percentage={isComplete && progress === 0 ? 1 : progress}
            />
          )}
          {limitDay && <ImageContentText>{limitDay}</ImageContentText>}
        </ImageContentFooter>
      </ImageContent>
    </Container>
  );
};

export default StackedVideo;

export const StackedVideoPlaceholder = () => {
  return (
    <Container>
      <TextContent type="video">
        <TextPlaceholder widthPercent={80} />
        <TextPlaceholder widthPercent={60} />
      </TextContent>
      <ImageContent type="video">
        <ImagePlaceholder type="video" />
      </ImageContent>
    </Container>
  );
};
