import { Cache } from 'swr';

type cacheValue = {
  data: {
    long_lived_playtoken: string;
  };
};
// TODO: remove before prod release
const DEBUG_DISABLE_LOCAL_STORAGE = false;

function localStorageProvider({ storageKey }: { storageKey?: string }) {
  if (
    storageKey &&
    typeof window !== 'undefined' &&
    !DEBUG_DISABLE_LOCAL_STORAGE
  ) {
    // Read from LocalStorage and convert to SWR cache structure
    const localStorageValue = JSON.parse(
      window.localStorage.getItem('llp-cache') || '{}'
    ) as {
      storageKey: string;
      llp: {
        [key: string]: string;
      };
    };

    let map: Map<string, cacheValue>;
    if (localStorageValue.storageKey === storageKey) {
      const swrArray = Object.entries(localStorageValue.llp).map(
        ([key, value]) => [
          key,
          {
            data: { long_lived_playtoken: value },
            isValidating: false,
            isLoading: false,
          },
        ]
      );
      map = new Map<string, cacheValue>(swrArray as [string, cacheValue][]);
    } else {
      map = new Map();
    }

    // Write to LocalStorage before the component is unload
    window.addEventListener('beforeunload', () => {
      if (storageKey) {
        // cache llp in LocalStorage
        const appCache = JSON.stringify({
          storageKey,
          llp: Object.fromEntries(
            Array.from(map.entries())
              .filter(
                ([key, value]) =>
                  key.startsWith('LIV') && value?.data?.long_lived_playtoken
              )
              .map(([key, value]) => [key, value.data.long_lived_playtoken])
          ),
        });
        window.localStorage.setItem('llp-cache', appCache);
      }
    });

    return map as Cache;
  }

  return new Map();
}

export default localStorageProvider;
