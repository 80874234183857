import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

/**
 * Returns true or false depending on if the current nextjs
 * route is matching the passed in route regex
 *
 * @param router Nextjs router instance
 * @param route Route as a regular expression
 */
export const isActivePath = (router: NextRouter, route: RegExp): boolean => {
  if (router && router.asPath.match(route)) {
    return true;
  }

  return false;
};

/**
 * Returns a filtered object containing only key/value pairs that don't exist in the current
 * router path.
 *
 * @param router Nextjs router instance
 * @param filters Object containing key value pairs for filter items that should be cleaned
 */
const filterQueryString = (
  router: NextRouter,
  filters: ParsedUrlQuery
): ParsedUrlQuery => {
  const cleanedFilters: ParsedUrlQuery = {};

  Object.keys(filters).forEach((key) => {
    if (!router.pathname.match(new RegExp(`${key}`))) {
      cleanedFilters[key] = filters[key];
    }
  });

  return cleanedFilters;
};

interface MergedQueryString {
  pathname: string;
  path: string;
}

/**
 * Takes a query paramter object and returns a new pathname and path
 * with the new values appended.
 *
 * - If the value already exists, it is updated.
 * - If a key with the value of null is passed, it will be removed from
 *   the current query paramters
 *
 * ## Example usage:
 *
 * If the current URL is `/foo?bar=test` and you want to add a new query parameter, you
 * can do the following:
 *
 * ```
 * const newRoute = mergeQueryString(router, { newParam: 'value' });
 * ```
 * NewRoute will become
 *
 * ```
 * {
 *  pathName: '/foo?bar=test&newParam=value',
 *   path: '/foo?bar=test&newParam=value'
 * }
 * ```
 *
 * @param router Nextjs router instance
 * @param query Key/value pair object containing query paramters
 */
export const mergeQueryString = (
  router: NextRouter,
  query: ParsedUrlQuery
): MergedQueryString => {
  const cleanedQuery = filterQueryString(router, router.query);

  const mergedQuery = Object.assign({}, cleanedQuery, query);

  const queryArray: string[] = [];

  Object.keys(mergedQuery).map((key) => {
    if (mergedQuery[key]) {
      queryArray.push(`${key}=${mergedQuery[key]}`);
    }
  });

  const pathWithoutQuery = router.asPath.split('?')[0];
  const asPath = pathWithoutQuery ? pathWithoutQuery : router.asPath;

  const pathname = `${router.pathname}${
    queryArray.length > 0 ? `?${encodeURI(queryArray.join('&'))}` : ''
  }`;
  const path = `${asPath}${
    queryArray.length > 0 ? `?${encodeURI(queryArray.join('&'))}` : ''
  }`;

  return { pathname, path };
};
