import { lighten, linearGradient, mix, transparentize } from 'polished';

/**
 * Base colors are absolute solid colors. Variations of the base colors
 * should be done lower down in the colors object.
 */
const BASE_COLORS = {
  // Key colors
  primary: 'rgba(0, 128, 222, 1)',
  promotion: 'rgba(251, 85, 35, 1)',
  caution: 'rgba(241, 34, 34, 1)',
  download: 'rgba(2, 212, 149, 1)',
  base: 'rgba(34, 34, 44, 1)',

  // Mono colors
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 1)',
  stone: 'rgba(64, 83, 104, 1)',
  charcoal: 'rgba(35, 45, 58, 1)',
  ink: 'rgba(20, 30, 43, 1)',
  midnight: 'rgba(10, 20, 33, 1)',
  jet_black: 'rgba(0, 10, 23, 1)',

  // colors not defined in Mirai UI
  gray_overlay: 'rgba(1, 6, 13, 1)',
  gray_3: 'rgba(15, 25, 38, 1)',
  gray_6: 'rgba(140, 150, 160, 1)',
  gray_7: 'rgba(45, 56, 68, 1)',
  gray_8: 'rgba(27, 35, 47, 1)',
  pink: 'rgb(237, 66, 109)',
};

/**
 * All variations of the base colors belong in the below colors object
 */
export const COLORS = {
  primary: BASE_COLORS.primary,
  primary_light: lighten(0.15, BASE_COLORS.primary),
  promotion: BASE_COLORS.promotion,
  promotion_light: lighten(0.15, BASE_COLORS.promotion),
  caution: BASE_COLORS.caution,
  caution_light: lighten(0.15, BASE_COLORS.caution),
  download: BASE_COLORS.download,

  base: BASE_COLORS.base,
  base_0: transparentize(1, BASE_COLORS.base),
  base_10: transparentize(1 - 0.1, BASE_COLORS.base),
  base_20: transparentize(1 - 0.2, BASE_COLORS.base),
  base_60: transparentize(1 - 0.6, BASE_COLORS.base),

  white: BASE_COLORS.white,
  white_0: transparentize(1, BASE_COLORS.white),
  white_05: transparentize(1 - 0.05, BASE_COLORS.white),
  white_10: transparentize(1 - 0.1, BASE_COLORS.white),
  white_15: transparentize(1 - 0.15, BASE_COLORS.white),
  white_20: transparentize(1 - 0.2, BASE_COLORS.white),
  white_25: transparentize(1 - 0.25, BASE_COLORS.white),
  white_30: transparentize(1 - 0.3, BASE_COLORS.white),
  white_40: transparentize(1 - 0.4, BASE_COLORS.white),
  white_45: transparentize(1 - 0.45, BASE_COLORS.white),
  white_50: transparentize(1 - 0.5, BASE_COLORS.white),
  white_60: transparentize(1 - 0.6, BASE_COLORS.white),
  white_75: transparentize(1 - 0.75, BASE_COLORS.white),
  white_80: transparentize(1 - 0.8, BASE_COLORS.white),
  white_90: transparentize(1 - 0.9, BASE_COLORS.white),

  stone: BASE_COLORS.stone,
  stone_70: transparentize(1 - 0.7, BASE_COLORS.stone),
  gray_overlay: BASE_COLORS.gray_overlay,
  gray_overlay_0: transparentize(1 - 0, BASE_COLORS.gray_overlay),
  gray_overlay_30: transparentize(1 - 0.3, BASE_COLORS.gray_overlay),
  gray_overlay_50: transparentize(1 - 0.5, BASE_COLORS.gray_overlay),
  gray_overlay_60: transparentize(1 - 0.6, BASE_COLORS.gray_overlay),
  gray_overlay_75: transparentize(1 - 0.75, BASE_COLORS.gray_overlay),
  gray_overlay_80: transparentize(1 - 0.8, BASE_COLORS.gray_overlay),
  gray_overlay_85: transparentize(1 - 0.85, BASE_COLORS.gray_overlay),
  gray_overlay_90: transparentize(1 - 0.9, BASE_COLORS.gray_overlay),
  jet_black: BASE_COLORS.jet_black,
  jet_black_light: lighten(0.1, BASE_COLORS.jet_black),
  jet_black_overlay_0: transparentize(1 - 0, BASE_COLORS.jet_black),
  jet_black_overlay_20: transparentize(1 - 0.2, BASE_COLORS.jet_black),
  jet_black_overlay_25: transparentize(1 - 0.25, BASE_COLORS.jet_black),
  jet_black_overlay_30: transparentize(1 - 0.3, BASE_COLORS.jet_black),
  jet_black_overlay_40: transparentize(1 - 0.4, BASE_COLORS.jet_black),
  jet_black_overlay_50: transparentize(1 - 0.5, BASE_COLORS.jet_black),
  jet_black_overlay_60: transparentize(1 - 0.6, BASE_COLORS.jet_black),
  jet_black_overlay_70: transparentize(1 - 0.7, BASE_COLORS.jet_black),
  jet_black_overlay_80: transparentize(1 - 0.8, BASE_COLORS.jet_black),
  jet_black_overlay_88: transparentize(1 - 0.88, BASE_COLORS.jet_black),
  jet_black_overlay_90: transparentize(1 - 0.9, BASE_COLORS.jet_black),
  jet_black_overlay_96: transparentize(1 - 0.96, BASE_COLORS.jet_black),
  midnight: BASE_COLORS.midnight,
  midnight_light_05: lighten(0.05, BASE_COLORS.midnight),
  midnight_overlay_0: transparentize(1 - 0, BASE_COLORS.midnight),
  midnight_overlay_40: transparentize(1 - 0.4, BASE_COLORS.midnight),
  midnight_overlay_60: transparentize(1 - 0.6, BASE_COLORS.midnight),
  midnight_overlay_80: transparentize(1 - 0.8, BASE_COLORS.midnight),
  midnight_overlay_90: transparentize(1 - 0.9, BASE_COLORS.midnight),
  midnight_light: lighten(0.1, BASE_COLORS.midnight),
  gray_3: BASE_COLORS.gray_3,
  gray_mix_white_05: mix(0.05, BASE_COLORS.white, BASE_COLORS.gray_3),
  gray_3_light: lighten(0.1, BASE_COLORS.gray_3),
  ink: BASE_COLORS.ink,
  ink_light: lighten(0.1, BASE_COLORS.ink),
  ink_overlay_0: transparentize(1, BASE_COLORS.ink),
  ink_overlay_80: transparentize(1 - 0.8, BASE_COLORS.ink),
  ink_overlay_90: transparentize(1 - 0.9, BASE_COLORS.ink),
  ink_line_gradient: linearGradient({
    colorStops: [`${BASE_COLORS.charcoal} 0%`, `${BASE_COLORS.ink} 100%`],
  }),
  charcoal: BASE_COLORS.charcoal,
  charcoal_light: lighten(0.1, BASE_COLORS.charcoal),
  charcoal_overlay_50: transparentize(1 - 0.5, BASE_COLORS.charcoal),
  charcoal_overlay_80: transparentize(1 - 0.8, BASE_COLORS.charcoal),
  charcoal_overlay_90: transparentize(1 - 0.9, BASE_COLORS.charcoal),
  gray_6: BASE_COLORS.gray_6,
  gray_7: BASE_COLORS.gray_7,
  gray_6_light: lighten(0.1, BASE_COLORS.gray_6),
  gray_8_overlay_0: transparentize(1, BASE_COLORS.gray_8),
  black: BASE_COLORS.black,
  black_0: transparentize(1, BASE_COLORS.black),
  black_20: transparentize(1 - 0.2, BASE_COLORS.black),
  black_30: transparentize(1 - 0.3, BASE_COLORS.black),
  black_75: transparentize(1 - 0.75, BASE_COLORS.black),

  pink: BASE_COLORS.pink,
  pink_overlay_80: transparentize(1 - 0.8, BASE_COLORS.pink),
  pink_light: lighten(0.1, BASE_COLORS.pink),
};

/**
 * All theme items used to reference the colors object and never the base colors object
 * But, not any more.
 * Please do not import theme at styled components. Import COLORS instead.
 */
const theme = {
  icon: {
    background: COLORS.primary,
  },
  text: {
    standard: COLORS.white,
    faded: COLORS.white_50,
    linkHover: COLORS.white_75,
    accent: COLORS.primary,
  },
  background: {
    default: COLORS.jet_black,
    black: COLORS.black,
    placeholder: COLORS.gray_3,
    gradient_0: COLORS.jet_black_overlay_0,
    gradient_30: COLORS.jet_black_overlay_30,
    gradient_40: COLORS.jet_black_overlay_40,
    gradient_50: COLORS.jet_black_overlay_50,
    gradient_60: COLORS.jet_black_overlay_60,
    gradient_70: COLORS.jet_black_overlay_70,
    gradient_88: COLORS.jet_black_overlay_88,
    gradient_96: COLORS.jet_black_overlay_96,
    error: COLORS.caution,
  },
  footer: {
    background: COLORS.gray_3,
    backgroundSecondary: COLORS.gray_overlay_30,
    spacer: COLORS.white_10,
  },
  overlay: {
    default: COLORS.gray_overlay,
    gradient_0: COLORS.gray_overlay_0,
    gradient_30: COLORS.gray_overlay_30,
    gradient_50: COLORS.gray_overlay_50,
    gradient_60: COLORS.gray_overlay_60,
    gradient_75: COLORS.gray_overlay_75,
    gradient_80: COLORS.gray_overlay_80,
  },
  button: {
    standard: {
      text: COLORS.white,
      background: COLORS.white_30,
      backgroundHover: COLORS.white_50,
      textDisabled: COLORS.white_20,
      backgroundDisabled: COLORS.white_10,
    },
    standardGray: {
      text: COLORS.white,
      background: COLORS.charcoal,
      backgroundHover: COLORS.white_50,
      textDisabled: COLORS.white_20,
      backgroundDisabled: COLORS.white_10,
      border: COLORS.white_05,
    },
    accent: {
      text: COLORS.white,
      background: COLORS.primary,
      backgroundHover: COLORS.primary_light,
      textDisabled: COLORS.white_15,
      backgroundDisabled: COLORS.white_10,
    },
    attention: {
      text: COLORS.white,
      background: COLORS.caution,
      backgroundHover: COLORS.caution_light,
      textDisabled: COLORS.white_15,
      backgroundDisabled: COLORS.white_10,
    },
    ghost: {
      text: COLORS.white_75,
      textHover: COLORS.white,
      border: COLORS.white_75,
      borderHover: COLORS.white,
      textDisabled: COLORS.white_15,
      backgroundDisabled: COLORS.white_10,
    },
    category: {
      text: COLORS.white,
      border: COLORS.white_30,
      borderHover: COLORS.white,
      backgroundHover: COLORS.white_30,
    },
    sliderNav: {
      text: COLORS.white,
      background: COLORS.midnight_overlay_60,
      backgroundHover: COLORS.midnight,
    },
    // TODO: Possibly remove if the design for App Guidance buttons change.
    appGuidance: {
      background: COLORS.charcoal,
      text: COLORS.white,
    },
    leanbackWelcome: {
      background: COLORS.primary,
      text: COLORS.white,
      backgroundHover: COLORS.primary_light,
      textDisabled: COLORS.white_20,
      backgroundDisabled: COLORS.white_10,
    },
  },
  actionButton: {
    icon: COLORS.white,
    iconEmpty: COLORS.white_0,
  },
  miraiButton: {
    text: {
      text: COLORS.white,
      textDisabled: COLORS.white_60,
    },
    light: {
      background: COLORS.white_90,
      text: COLORS.jet_black,
      textHover: COLORS.charcoal,
      backgroundHover: COLORS.white,
      textDisabled: COLORS.jet_black,
      backgroundDisabled: COLORS.white_50,
    },
    dark: {
      background: COLORS.white_30,
      text: COLORS.white,
      backgroundHover: COLORS.white_60,
      textDisabled: COLORS.white_50,
      backgroundDisabled: COLORS.white_20,
    },
    attention: {
      background: COLORS.primary,
      text: COLORS.white,
      backgroundHover: COLORS.primary_light,
      textDisabled: COLORS.white_50,
      backgroundDisabled: COLORS.white_20,
    },
    caution: {
      background: COLORS.caution,
      text: COLORS.white,
      backgroundHover: COLORS.caution_light,
      textDisabled: COLORS.white_50,
      backgroundDisabled: COLORS.white_20,
    },
    ghost: {
      background: COLORS.white_05,
      text: COLORS.white_75,
      textHover: COLORS.white,
      border: COLORS.white_75,
      borderHover: COLORS.white,
      backgroundHover: COLORS.white_15,
      textDisabled: COLORS.white_20,
      borderDisabled: COLORS.white_20,
      backgroundDisabled: COLORS.white_10,
    },
    delete: {
      background: COLORS.caution,
      text: COLORS.white,
      textHover: COLORS.white,
      border: COLORS.white_30,
      borderHover: COLORS.white,
      backgroundHover: COLORS.caution_light,
      textDisabled: COLORS.white_75,
      borderDisabled: COLORS.white_30,
      backgroundDisabled: COLORS.white_05,
    },
    deleteAll: {
      text: COLORS.caution,
      textHover: COLORS.caution_light,
      border: COLORS.caution,
      borderHover: COLORS.caution_light,
      backgroundHover: COLORS.white_15,
      textDisabled: COLORS.white_20,
      borderDisabled: COLORS.white_20,
      backgroundDisabled: COLORS.white_10,
    },
  },
  input: {
    text: COLORS.white,
    placeholderText: COLORS.white_50,
    background: COLORS.white_10,
    outline: COLORS.primary,
    errorBorder: COLORS.caution,
    checkBoxBorder: COLORS.white,
  },
  searchInput: {
    text: COLORS.white,
    placeholderText: COLORS.white_75,
    border: COLORS.white_75,
    borderActive: COLORS.white,
    background: COLORS.jet_black_overlay_60,
    activeBackground: COLORS.primary,
    suggestBorder: COLORS.white_30,
    suggestBackground: COLORS.charcoal,
    suggestBackgroundHover: COLORS.white_05,
    clearBorder: COLORS.white_05,
    clearBackground: COLORS.stone_70,
    clearBackgroundHover: COLORS.stone,
  },
  announcement: {
    background: COLORS.white_15,
    backgroundHover: COLORS.white_20,
    border: COLORS.white_05,
    dateColor: COLORS.white_75,
    activeButton: COLORS.primary,
  },
  tabs: {
    text: COLORS.white_90,
    textHovered: COLORS.white,
    divider: COLORS.white_30,
  },
  playCard: {
    text: COLORS.white,
    overlay: COLORS.gray_overlay_75,
    hoverOverlay: COLORS.gray_overlay_30,
    playingOverlay: COLORS.gray_overlay_60,
    playButtonBackground: COLORS.charcoal_overlay_50,
    playButtonHoveredBackground: COLORS.white,
    playButtonHovered: COLORS.charcoal,
    episodeNumber: COLORS.gray_overlay_60,
    background: COLORS.jet_black,
  },
  resumeCard: {
    background: COLORS.ink,
    border: COLORS.white_10,
  },
  progressBar: {
    background: COLORS.white_50,
    currentBackground: COLORS.primary,
  },
  rankingCard: {
    background: COLORS.ink,
    rankBackground: COLORS.ink_light,
  },
  genreTile: {
    text: COLORS.white,
    background: COLORS.ink,
    backgroundHover: COLORS.ink_light,
  },
  leanback: {
    background: {
      gradient_0: COLORS.charcoal,
      gradient_100: COLORS.ink,
    },
    backgroundMobileSearch: COLORS.charcoal,
    backgroundMobileSearchHover: COLORS.charcoal_light,
    overlay: COLORS.gray_overlay_60,
    points: {
      text: COLORS.white,
      subtext: COLORS.white_75,
      background: COLORS.white_10,
      prefixText: COLORS.white_75,
    },
    mobileHeader: {
      background: COLORS.ink_overlay_90,
      gradient_100: COLORS.gray_overlay_0,
      gradient_41: COLORS.gray_overlay_60,
      gradient_0: COLORS.gray_overlay,
    },
    menuButton: {
      background: COLORS.white_05,
    },
    accountSetting: {
      background: COLORS.ink,
      text: COLORS.white_75,
      textHover: COLORS.white,
      devider: COLORS.white_10,
    },
    footer: {
      background: COLORS.ink,
      text: COLORS.white_50,
      textHover: COLORS.white,
      devider: COLORS.white_10,
    },
    helpLink: {
      text: COLORS.white_50,
      textHover: COLORS.white,
    },
  },
  menu: {
    text: COLORS.white_75,
    textHover: COLORS.white,
    background: COLORS.midnight,
    backgroundHover: COLORS.white_05,
    secondary: {
      text: COLORS.white_50,
      background: COLORS.gray_3,
      backgroundHover: COLORS.ink_light,
    },
    active: {
      background: COLORS.white_15,
      backgroundHover: COLORS.white_20,
      activeBar: COLORS.primary,
    },
  },
  simpleList: {
    text: COLORS.white,
    backgroundHover: COLORS.white_10,
    placeholder: {
      background: COLORS.white_15,
    },
  },
  popup: {
    wrapper: COLORS.ink_line_gradient,
    wrapperShadow: COLORS.midnight,
    background: COLORS.white_05,
    backgroundActive: COLORS.white_15,
    text: COLORS.white,
    scrollbarThumb: COLORS.white_50,
    dropdownItemBackground: COLORS.white_10,
    dropdownItemBorder: COLORS.white_75,
    hover: COLORS.white_15,
    border: COLORS.white_05,
    triangleBorder: COLORS.gray_7,
  },
  ratingStar: {
    base: COLORS.white_75,
    static: COLORS.white,
    dynamic: COLORS.primary,
    primary: COLORS.white,
  },
  badge: {
    default: {
      text: COLORS.white_50,
      border: COLORS.white_50,
    },
    white: {
      text: COLORS.white,
      border: COLORS.white,
    },
    primary: {
      text: COLORS.white_50,
      background: COLORS.white_10,
    },
    accent: {
      text: COLORS.white,
      background: COLORS.primary,
    },
    promotion: {
      text: COLORS.white,
      background: COLORS.promotion,
    },
    announcement: {
      text: COLORS.jet_black,
      background: COLORS.white,
    },
    newExclusive: {
      background: COLORS.promotion,
      text: COLORS.white,
    },
    subDub: {
      background: COLORS.white_10,
      text: COLORS.white_50,
    },
  },
  titleCard: {
    background: COLORS.gray_3,
    thumbnail: COLORS.midnight_light_05,
    catchphrase: COLORS.white_50,
    catchPhraseMobile: COLORS.gray_6,
    titleName: COLORS.white,
    updateInfo: COLORS.white_75,
    shadow: COLORS.black_75,
    border: COLORS.white_10,
    edit: {
      background: COLORS.midnight_overlay_40,
      backgroundHover: COLORS.midnight_overlay_60,
      backgroundSelected: COLORS.midnight_overlay_80,
    },
    logoBackground: COLORS.jet_black_overlay_50,
    logoBackground2: COLORS.white_0,
  },
  liveTitleCard: {
    background: COLORS.gray_3,
    border: COLORS.white_10,
    date: COLORS.white,
    time: COLORS.white,
    catchup: COLORS.white_50,
    price: COLORS.white_75,
    liveNowText: COLORS.white,
    liveNowIcon: COLORS.white_75,
    liveNowIconActive: COLORS.promotion,
    thumbnailShadow: COLORS.jet_black_overlay_20,
  },
  channelTitleCard: {
    button: COLORS.white_75,
    shadow: COLORS.black_75,
    background: COLORS.gray_3,
  },
  simpleCard: {
    border: COLORS.white_05,
  },
  genre: {
    leadText: COLORS.white_50,
    titleNumber: COLORS.white_75,
    banner: {
      leadText: COLORS.white_50,
      smallBackground: COLORS.charcoal_overlay_90,
      shadow: COLORS.jet_black_overlay_60,
      socialsText: COLORS.white_50,
      descriptionText: COLORS.white_75,
    },
    placeholder: {
      background: COLORS.white_15,
    },
    template: {
      background: COLORS.white_15,
      overlay: COLORS.jet_black_overlay_30,
      text: COLORS.white_50,
      infoBackground: COLORS.white_15,
      infoBackgroundHovered: COLORS.white_30,
      labelBackground: COLORS.primary,
      saleText: COLORS.white_50,
    },
    infoIcon: COLORS.white_50,
    infoIconHover: COLORS.white_75,
  },
  slider: {
    arrow: {
      color: COLORS.white,
      background: COLORS.gray_overlay_30,
      backgroundActive: COLORS.gray_overlay_60,
      backgroundHover: COLORS.gray_overlay_75,
    },
  },
  titleDetail: {
    color: COLORS.white_50,
    shadow: COLORS.black_20,
    background: {
      primary: COLORS.midnight,
      secondary: COLORS.white_05,
      tertiary: COLORS.white_10,
      overlay: COLORS.gray_overlay_75,
      shadow: COLORS.gray_overlay_90,
      relatedSection: COLORS.gray_overlay_30,
      mobile: COLORS.white_0,
    },
    text: {
      primary: COLORS.white,
      secondary_1: COLORS.white_50,
      secondary_2: COLORS.white,
      secondary_3: COLORS.white_30,
      secondary_4: COLORS.white_75,
    },
    keyArt: {
      background: COLORS.charcoal,
      gradient_0: COLORS.midnight_overlay_0,
      gradient_40: COLORS.midnight_overlay_40,
      gradient_60: COLORS.midnight_overlay_60,
      gradient_80: COLORS.midnight_overlay_80,
      gradient_90: COLORS.midnight_overlay_90,
      gradient_100: COLORS.midnight,
    },
    metaInfo: {
      color: {
        standard: COLORS.white_50,
        attention: COLORS.promotion,
        accent: COLORS.primary,
        price: COLORS.white_75,
      },
    },
    closeButton: {
      background: COLORS.gray_overlay_30,
      backgroundHover: COLORS.jet_black_overlay_30,
    },
    signupPromotion: {
      text: COLORS.white_50,
      background: COLORS.white_10,
      border: COLORS.white_20,
    },
    placeholder: {
      background: COLORS.charcoal,
    },
  },
  search: {
    header: {
      text: COLORS.white,
      textSecondary: COLORS.white_45,
    },
    placeholder: {
      background: COLORS.white_15,
    },
    genreButton: {
      text: COLORS.white,
      background: COLORS.white_15,
      backgroundHover: COLORS.white_30,
      border: COLORS.white_05,
    },
  },
  filter: {
    dropdown: {
      titleText: COLORS.white_50,
      iconTextMobile: COLORS.white,
    },
  },
  mediaOverlay: {
    header: {
      buttonBackground: COLORS.ink_overlay_80,
      hoveredButtonBackground: COLORS.charcoal_light,
      title: COLORS.white,
      subtitle: COLORS.white_75,
    },
    progressBar: {
      background: COLORS.white_50,
      fillTrack: COLORS.primary,
      buffer: COLORS.white_75,
      thumb: COLORS.white,
    },
    volume: {
      background: COLORS.white_30,
      fillTrack: COLORS.white_75,
      thumb: COLORS.white,
    },
    controls: {
      background: COLORS.white_15,
      text: COLORS.white_75,
      boxShadow: COLORS.gray_overlay_90,
      gradient_0: COLORS.gray_overlay_0,
      gradient_65: COLORS.gray_overlay_50,
      gradient_100: COLORS.gray_overlay_90,
    },
    videoSelect: {
      normal: COLORS.white_75,
      hovered: COLORS.white,
      border: COLORS.white_05,
    },
  },
  purchase: {
    messages: {
      text: COLORS.white_50,
    },
    productItem: {
      background: COLORS.gray_3,
      checkedBackground: COLORS.charcoal,
      disabledText: COLORS.white_75,
      radioBorder: COLORS.white,
      radioBackground: COLORS.white,
      radioBoxShadow: COLORS.primary,
      validUntilText: COLORS.white_50,
      subLabelText: COLORS.white_75,
      originalPriceStrike: COLORS.white,
      originalPriceText: COLORS.white_75,
    },
    saleBadge: {
      text: COLORS.jet_black,
      background: COLORS.white,
    },
    contractFooter: {
      text: COLORS.white_75,
    },
    rentalFooter: {
      text: COLORS.white_75,
    },
    bulkPurchase: {
      itemBackground: COLORS.gray_3,
      labelSub: COLORS.white_75,
      selectAllShadow: COLORS.black_75,
      hasRightBadgeText: COLORS.white,
      hasRightBadgeBackground: COLORS.white_20,
    },
    bulkListItem: {
      background: COLORS.gray_3,
      backgroundHovered: COLORS.gray_mix_white_05,
    },
    bulkItemCount: {
      background: COLORS.white_20,
    },
    point: {
      border: COLORS.white_20,
      background: COLORS.white_20,
    },
    PayContainer: {
      background: COLORS.charcoal,
      boxShadow: COLORS.black_75,
    },
    pointBackRow: {
      background: COLORS.white_10,
    },
    pointBackDetail: {
      background: COLORS.white_10,
    },
    error: {
      background: COLORS.caution,
    },
    packContract: {
      text: COLORS.white_75,
      subTitle: COLORS.white,
    },
    dialog: {
      overlay: COLORS.black,
      title: COLORS.white,
      background: COLORS.midnight,
    },
    pointBack: {
      footnote: COLORS.white_50,
      dialogButtonText: COLORS.white,
      dialogButtonBackground: COLORS.white_30,
    },
    taxIncluded: {
      text: COLORS.white_75,
    },
  },
  panel: {
    headerBackground: COLORS.white_05,
    backgroundColor: COLORS.charcoal,
    hoverBackground: COLORS.white_10,
  },
  selectButton: {
    background: COLORS.white_05,
    hoveredBackground: COLORS.white_30,
    text: COLORS.white_50,
    hoveredText: COLORS.white,
  },
  modal: {
    overlay: COLORS.gray_overlay_60,
    background: COLORS.charcoal,
  },
  searchDropdown: {
    input: {
      background: COLORS.midnight,
    },
    dropdown: {
      background: COLORS.charcoal_overlay_80,
      genre: {
        hoverBackground: COLORS.white_05,
        placeholderBackground: COLORS.white_10,
      },
    },
  },
  shortcut: {
    delimiter: COLORS.white_05,
    text: COLORS.white,
    shortcutText: COLORS.primary,
  },
  toast: {
    background: COLORS.charcoal,
    shadow: COLORS.black_75,
  },
  notice: {
    title: COLORS.white,
    message: COLORS.white,
  },
  semiAdult: {
    text: {
      color: COLORS.white,
    },
    button: {
      background: COLORS.pink,
      color: COLORS.white,
    },
    buttonHover: {
      background: COLORS.pink_overlay_80,
      color: COLORS.white_75,
    },
    artwork: {
      background: COLORS.white_10,
      border: COLORS.white_20,
    },
  },
  banner: {
    background: COLORS.ink,
  },
  readMore: {
    background: {
      primary: {
        gradient_0: COLORS.gray_8_overlay_0,
        gradient_42: BASE_COLORS.gray_8,
      },
      campaign: {
        gradient_0: COLORS.ink_overlay_0,
        gradient_42: COLORS.ink,
      },
      episodeList: {
        gradient_0: COLORS.midnight_overlay_0,
        gradient_42: COLORS.midnight,
      },
      episodeDetail: {
        gradient_0: COLORS.jet_black_overlay_0,
        gradient_42: COLORS.jet_black,
      },
    },
  },
  scrollBar: {
    thumb: COLORS.white_50,
    track: COLORS.charcoal,
  },
  sceneSearch: {
    background: COLORS.charcoal,
    boxShadow: COLORS.gray_overlay_90,
  },
  postPlay: {
    nextEpisode: {
      background: COLORS.ink,
    },
    recommend: {
      background: COLORS.black,
      mainCast: COLORS.white_50,
      shadow: COLORS.jet_black,
    },
  },
};

export default theme;
