import { type IntlShape } from 'react-intl';
import { getTextWithSeparator } from '../../../../../utils/textHelper';
import { globalMessages } from '../../../../constants/messages';
import { BookType } from '../ListBookCard';

export interface BookMetaInfoProps {
  titleName: string;
  bookType: BookType;
  numberOfFree: string;
  minimumPrice: number | null;
  hasMultiPrice?: boolean | null;
  totalBookNum: number;
  author: string;
  publisher: string;
  bookMagazine: string;
  rate: number;
  isOnSale: boolean;
  isPreorderable?: boolean;
  isNew?: boolean;
  isDailyFree: boolean;
  isDailyFreePlus: boolean;
  isOriginal?: boolean;
  isYomihodai: boolean;
  isChapter?: boolean;
  hasUnreadChapter?: boolean;
  subgenreTagList?: { name: string }[];
  publicStartDateTime?: string;
  isEpisodeSpecified: boolean;
}

export interface RefinedMetaInfoProps {
  titleName: string;
  bookType: BookType;
  numberOfFree: string;
  minimumPrice: number | null;
  hasMultiPrice?: boolean | null;
  rate: number;
  isOnSale: boolean;
  isPreorderable?: boolean;
  isNew?: boolean;
  isOriginal?: boolean;
  isDailyFree?: boolean;
  isDailyFreePlus?: boolean;
  isYomihodai: boolean;
  authorText: string;
  bookTypeText: string;
  totalBookNumText: string;
}

export const getRefinedMetaInfoProps = (
  props: BookMetaInfoProps,
  intl: IntlShape
) => {
  const {
    bookType,
    author,
    publisher,
    bookMagazine,
    publicStartDateTime = null,
    subgenreTagList = [],
    totalBookNum,
    isChapter = false,
    ...rest
  } = props;

  const authorText = getTextWithSeparator(
    [
      bookType === 'MAGAZINE' ? publicStartDateTime : null,
      author,
      publisher,
      bookMagazine,
    ],
    '｜'
  );

  const bookTypeText =
    intl.formatMessage(globalMessages[bookType]) +
    (bookType === 'COMIC' && subgenreTagList[0]
      ? `(${subgenreTagList[0].name})`
      : '');

  let totalBookNumText = '';
  if (typeof totalBookNum === 'number' && totalBookNum > 1) {
    totalBookNumText =
      '｜' +
      intl.formatMessage(
        isChapter
          ? globalMessages.totalChapterNum
          : globalMessages.totalBookNum,
        {
          totalBookNum,
        }
      );
  }

  return {
    bookType,
    authorText,
    bookTypeText,
    totalBookNumText,
    ...rest,
  };
};
