import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Modal from '../../../../shared/components/Modal';
import { globalMessages } from '../../../../shared/constants/messages';
import useLoginUrl from '../../../../shared/hooks/useLoginUrl';
import { DEVICE } from '../../../../shared/styles';
import type { Nullable } from '../../../../utils/Nullable';
import { MylistButton, RateButton, ShareButton } from './ActionButton';
import { Popup, PopupWrapper } from './Popup';
import ShareLink from './ShareLinkModal';
import UserEvaluation from './UserEvaluationModal';

export const UserButtonsGroupContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 265px;

  @media ${DEVICE.mobileWide} {
    width: 100%;
  }
`;

const DropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
`;

interface UserButtonsGroupProps {
  isLoggedIn?: boolean;
  userRate?: number;
  setEvaluation: (newUserRate: number) => void;
  shareUrl: string;
  multiAccountId?: string;
  name: string;
  sakuhinName?: string;
  isFavorite: Nullable<boolean>;
  toggleFavorite: () => void;
  contentType: 'video' | 'book';
  mylistBtnComponentName?: string;
  rateBtnComponentName?: string;
  shareBtnComponentName?: string;
  titleCode?: string;
}

const UserButtonsGroup: React.FC<UserButtonsGroupProps> = ({
  isLoggedIn = false,
  userRate = 0,
  setEvaluation,
  shareUrl,
  multiAccountId,
  name,
  sakuhinName,
  isFavorite = false,
  toggleFavorite,
  contentType,
  mylistBtnComponentName,
  rateBtnComponentName,
  shareBtnComponentName,
  titleCode,
}) => {
  const intl = useIntl();
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isShareDropdownVisible, setShareDropdown] = useState(false);
  const [isRateDropdownVisible, setRateDropdown] = useState(false);
  const { getLoginUrl } = useLoginUrl();

  const handleLoginRedirect = useCallback(() => {
    setLoginModalVisible(false);
    window.location.href = getLoginUrl(window.location.pathname);
  }, [setLoginModalVisible, getLoginUrl]);

  const shareLinkProps = {
    name,
    sakuhinName,
    shareUrl,
    multiAccountId,
    contentType,
    titleCode,
  };

  return (
    <>
      <UserButtonsGroupContainer>
        <MylistButton
          isFavorite={isFavorite}
          onClick={() => {
            if (isLoggedIn) {
              toggleFavorite();
            } else {
              setLoginModalVisible(true);
            }
          }}
          data-ucn={mylistBtnComponentName}
        />
        <PopupWrapper>
          <RateButton
            rate={userRate / 10}
            onClick={() => {
              if (isLoggedIn) {
                setRateDropdown(!isRateDropdownVisible);
              } else {
                setLoginModalVisible(true);
              }
            }}
            data-ucn={rateBtnComponentName}
          />
          <Popup show={!!isRateDropdownVisible}>
            <UserEvaluation
              key="user-eval"
              userRate={userRate}
              setEvaluation={(newUserRate) => {
                if (typeof setEvaluation === 'function') {
                  if (newUserRate != userRate) {
                    setEvaluation(newUserRate);
                  }

                  setTimeout(() => {
                    setRateDropdown(false);
                  }, 150);
                }
              }}
            />
          </Popup>
        </PopupWrapper>
        <PopupWrapper>
          <ShareButton
            onClick={() => {
              setShareDropdown(!isShareDropdownVisible);
            }}
            data-ucn={shareBtnComponentName}
          />
          <Popup show={!!isShareDropdownVisible}>
            <ShareLink key="twitter" type="twitter" {...shareLinkProps} />
            <ShareLink key="facebook" type="facebook" {...shareLinkProps} />
            <ShareLink key="hatena" type="hatena" {...shareLinkProps} />
            <ShareLink key="line" type="line" {...shareLinkProps} />
            <ShareLink key="copyUrl" type="copy" {...shareLinkProps} />
          </Popup>
        </PopupWrapper>
      </UserButtonsGroupContainer>
      {(isShareDropdownVisible || isRateDropdownVisible) && (
        <DropdownOverlay
          onClick={() => {
            setShareDropdown(false);
            setRateDropdown(false);
          }}
        />
      )}
      <Modal
        visible={isLoginModalVisible}
        onCancel={() => setLoginModalVisible(false)}
        onOk={() => handleLoginRedirect()}
        okButtonType="light"
        okText={intl.formatMessage(globalMessages.login)}
        // We disable the scroll lock for this modal as the title detail
        // will have already locked it
        disableScrollLock
        title={intl.formatMessage(globalMessages.loginRequiredModalTitle)}
      >
        {intl.formatMessage(globalMessages.loginRequiredModalText)}
      </Modal>
    </>
  );
};

export default UserButtonsGroup;
