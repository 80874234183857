import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { globalMessages } from '../../../constants/messages';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import { EditThumbnailOverlay } from '../../Edit';
import Container from './containers/Container';
import BookMetaInfo from './MetaInfo/BookMetaInfo';
import { BookMetaInfoProps } from './MetaInfo/utils';
import BookThumbnail, { ThumbnailProps } from './Thumbnail';

export type BookType =
  | 'COMIC'
  | 'MAGAZINE'
  | 'BOOK'
  | 'RANOBE'
  | 'PHOTO_BOOK'
  | 'PICBOOK';

type Props = ThumbnailProps & BookMetaInfoProps;

export const BookThumbnailPadding = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px 24px 0;
`;

export const BookMetaInfoContainer = styled.div`
  width: 100%;
  padding: 10px 12px 12px;
  box-sizing: border-box;
  overflow: hidden;

  @media ${DEVICE.mobileWide} {
    padding: 0 0 0 12px;
  }
`;

const UnreadChapter = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  color: ${COLORS.primary};
  font-size: 10px;
  font-weight: 600;
`;

const ListBookCard: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  bookType,
  numberOfFree,
  minimumPrice,
  hasMultiPrice,
  totalBookNum,
  author,
  publisher,
  bookMagazine,
  rate,
  isNew,
  isPreorderable,
  isDailyFree,
  isDailyFreePlus,
  isOnSale,
  isOriginal = false,
  isYomihodai,
  isChapter = false,
  hasUnreadChapter = false,
  isLazy = false,
  subgenreTagList = [],
  publicStartDateTime,
  isEpisodeSpecified,
}) => {
  const intl = useIntl();
  const thumbnailProps = {
    isNew,
    isPreorderable,
    thumbnailUrl,
    isLazy,
    titleName,
  };

  const metaInfoProps = {
    titleName,
    bookType,
    numberOfFree,
    minimumPrice,
    hasMultiPrice,
    totalBookNum,
    author,
    publisher,
    bookMagazine,
    rate,
    isOnSale,
    isNew,
    isPreorderable,
    isDailyFree,
    isDailyFreePlus,
    isOriginal,
    isYomihodai,
    isChapter,
    subgenreTagList,
    publicStartDateTime,
    isEpisodeSpecified,
  };

  return (
    <Container>
      {hasUnreadChapter && (
        <UnreadChapter>
          {intl.formatMessage(globalMessages.hasUnread)}
        </UnreadChapter>
      )}
      <BookThumbnailPadding>
        <EditThumbnailOverlay>
          <BookThumbnail {...thumbnailProps} />
        </EditThumbnailOverlay>
      </BookThumbnailPadding>
      <BookMetaInfoContainer>
        <BookMetaInfo {...metaInfoProps} />
      </BookMetaInfoContainer>
    </Container>
  );
};

export default ListBookCard;
