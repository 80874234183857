import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { globalMessages } from '../../../constants/messages';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import AkamaiImage from '../../Common/AkamaiImage';
import { DailyFreeBadge } from '../../Common/Badge/DaillyFreeBadge';
import { RoundBadge } from '../../Common/Badge/RoundBadge';
import { IMAGE_ASPECT } from '../constants';

const Container = styled.div`
  position: relative;
  background-color: ${COLORS.gray_3};
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  padding-bottom: ${100 / IMAGE_ASPECT.book}%;

  &:before {
    // To keep the image size precisely, set border-style to this pseudo element
    // instead of the actual element
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${COLORS.white_10};
    z-index: 1;
  }

  img {
    width: 100%;
    height: auto;
    position: absolute;
  }
`;

const NewBadge = styled(RoundBadge)`
  position: absolute;
  bottom: 4px;
  left: 4px;
  z-index: 140;
`;

const StyledDailyFreeBadge = styled(DailyFreeBadge)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 140;
`;

export const Shadow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  position: absolute;
  padding-bottom: 5px;
  bottom: 0;
  z-index: 150;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  background: linear-gradient(
    -180deg,
    ${COLORS.jet_black_overlay_0} 0%,
    ${COLORS.jet_black_overlay_80} 100%
  );
`;

const MetaInfoTitle = styled.h3`
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 16px;
  color: ${COLORS.white};

  @media ${DEVICE.mobileWide} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    line-height: 1.5;
  }
`;

export interface ThumbnailProps {
  isNew?: boolean;
  isPreorderable?: boolean;
  isDailyFree?: boolean;
  isDailyFreePlus?: boolean;
  thumbnailUrl: string;
  isLazy?: boolean;
  titleName?: string;
  className?: string;
}

const BookThumbnail: React.FC<ThumbnailProps> = ({
  isNew = false,
  isPreorderable = false,
  isDailyFree,
  isDailyFreePlus,
  thumbnailUrl,
  isLazy = false,
  titleName,
  className,
}) => {
  const intl = useIntl();

  return (
    <Container className={className}>
      {(isDailyFree || isDailyFreePlus) && (
        <StyledDailyFreeBadge
          badgeType={isDailyFreePlus ? 'plus' : 'normal'}
          badgeSize="large"
          mobileBadgeSize="small"
        />
      )}
      {(isNew || isPreorderable) && (
        <NewBadge badgeType="promotion" badgeSize="small">
          {isPreorderable
            ? intl.formatMessage(globalMessages.preorder)
            : intl.formatMessage(globalMessages.new)}
        </NewBadge>
      )}
      <AkamaiImage
        src={thumbnailUrl}
        availableSizes={[128, 256]}
        sizeConfig={{
          SD: {
            width: 160,
          },
          TABLET: {
            width: 154,
          },
          MOBILE_WIDE: {
            width: 126,
          },
        }}
        letterbox="5:7"
        alt={titleName ?? ''}
        isLazy={isLazy}
      />
      {titleName && (
        <Shadow>
          <MetaInfoTitle>{titleName}</MetaInfoTitle>
        </Shadow>
      )}
    </Container>
  );
};

export default BookThumbnail;
