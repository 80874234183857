import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { ToastProvider } from '@u-next/react-toast-notifications';
import { SearchInputProvider } from '../../SearchInputContext';
import { DEVICE, GlobalStyle } from '../../styles';
import BackgroundPattern from '../BackgroundPattern';
import Footer from '../Footer';
import Leanback from '../Leanback';
import SmallBanner from '../SmallBanner';
import { SmallBannerProvider } from '../SmallBanner/SmallBannerContext';
import Toast from '../Toast';

export const FLOATING_FOOTER_ID = 'FLOATING_FOOTER'; // up front of any layers
export const MAIN_CONTENT_FOOTER_ID = 'MAIN_CONTENT_FOOTER'; // up front of main content but behind modals and category search
export const FOOTER_ITEMS_ORDER = {
  TICKET_STATUS: 1,
  PAGER: 2,
}; // Define the order of items inside FLOATING_FOOTER_ID

type LayoutProps = PropsWithChildren<{
  showLeanback?: boolean;
  showBackgroundPattern?: boolean;
  showDefaultFooter?: boolean;
  alwaysShowScrollbar?: boolean;
}>;

const MainContent = styled.div<Pick<LayoutProps, 'showLeanback'>>`
  ${({ showLeanback }) =>
    showLeanback
      ? css`
          position: relative;
          top: 0;
          left: 256px;
          width: calc(100% - 256px);
          min-height: 100%;
        `
      : css`
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        `};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  @media ${DEVICE.sd} {
    left: 0;
    width: 100%;
  }
`;

const MainComponent = styled.div`
  flex-grow: 1;
`;

const MainContentFooter = styled.div`
  position: fixed;
  bottom: 16px;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
`;

const FloatingFooter = styled.div`
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  z-index: 20000;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${DEVICE.mobileWide} {
    gap: 8px;
  }
`;

const Layout: React.FC<LayoutProps> = ({
  showBackgroundPattern,
  showLeanback,
  showDefaultFooter,
  alwaysShowScrollbar,
  children,
}) => {
  return (
    <ToastProvider components={{ Toast }}>
      <>
        <GlobalStyle alwaysShowScrollbar={alwaysShowScrollbar} />
        <SearchInputProvider>
          <SmallBannerProvider>
            {showLeanback && <Leanback />}
            <MainContent showLeanback={showLeanback}>
              {showLeanback && <SmallBanner />}
              {showBackgroundPattern && <BackgroundPattern />}
              <MainComponent>{children}</MainComponent>
              {showDefaultFooter && <Footer />}
            </MainContent>
            <MainContentFooter id={MAIN_CONTENT_FOOTER_ID} />
            <FloatingFooter id={FLOATING_FOOTER_ID} />
          </SmallBannerProvider>
        </SearchInputProvider>
      </>
    </ToastProvider>
  );
};

export default Layout;
