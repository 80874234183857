import { gql } from '@apollo/client';

// Fragment used for requesting all necessary data for video title cards
export const titleCardFragment = gql`
  fragment TitleCard on Title {
    id
    titleName
    isNew
    catchphrase
    rate
    productionYear
    updateOfWeek
    lastEpisode
    nfreeBadge
    hasSubtitle
    hasDub
    paymentBadgeList {
      code
    }
    productLineupCodeList
    thumbnail {
      standard
      secondary
    }
    hasPackRights
    hasMultiprice
    minimumPrice
    isOriginal
    exclusive {
      isOnlyOn
      typeCode
    }
  }
`;

export const curationBlockFragment = gql`
  fragment CurationBlock on VideoRecommendTitleBlock {
    code
    type
    trackingCode
    name
    total
    list {
      ...TitleCard
    }
  }
  ${titleCardFragment}
`;

export const featureBlockFragment = gql`
  fragment FeatureBlock on RecommendBlock {
    id
    featureName
    featureTypeCode
    featurePickupFlag
    leadText
    titleResults
    titles {
      ...TitleCard
      thumbnail {
        standard
        secondary
      }
    }
    titleComments {
      titleCode
      comment
    }
  }
`;
