import React, { ButtonHTMLAttributes } from 'react';

import styled, { css } from 'styled-components';
import { DEVICE } from '../../styles';

interface IconProps {
  isActive?: boolean;
}

// * For actual layout result, the current code design interlocks
//   the normal mode icon's width size and the closed mode's line length.
//   Thus we change values in/out their mode change.
// * These values are approximation to match an actual calculated layout size to
//   design spec.
// * Perhaps, we can do this more straight way by fixing https://jira.unext-info.jp/browse/WF-5462
const ON_NORMAL_ICON_WIDTH = '27.3px';
const ON_CLOSE_ICON_WIDTH = '30px';

const IconContainer = styled.div`
  position: relative;
  top: 50%;
`;

const InnerStyle = css`
  display: block;
  margin-top: -8px;
  width: ${ON_NORMAL_ICON_WIDTH};
  height: 3.5px;
  background-color: ${({ theme }) => theme.text.standard};
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
`;

const Inner0 = styled.span<IconProps>`
  ${InnerStyle}

  ${({ isActive }) =>
    isActive &&
    css`
      width: ${ON_CLOSE_ICON_WIDTH};
      transform: translate3d(0, 10px, 0) rotate(45deg);
    `};
`;

const Inner1 = styled.span<IconProps>`
  ${InnerStyle}
  top: 10px;
  transition-property: transform, opacity;

  ${({ isActive }) =>
    isActive &&
    css`
      width: ${ON_CLOSE_ICON_WIDTH};
      transform: rotate(-45deg);
      opacity: 0;
    `};
`;

const Inner2 = styled.span<IconProps>`
  ${InnerStyle}
  top: 20px;
  bottom: -10px;

  ${({ isActive }) =>
    isActive &&
    css`
      width: ${ON_CLOSE_ICON_WIDTH};
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    `};
`;

const Icon: React.FC<HamburgerProps> = ({ isActive }) => (
  <IconContainer>
    <Inner0 isActive={isActive} />
    <Inner1 isActive={isActive} />
    <Inner2 isActive={isActive} />
  </IconContainer>
);

interface HamburgerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
}

const HamburgerContainer = styled.button`
  display: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: ${({ theme }) => theme.text.standard};
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  /*
    FIXME:
      This transform:scale make specifying geometries complex.
      If it's possible, we should remove this.
      https://jira.unext-info.jp/browse/WF-5462
  */
  transform: scale(0.7);
  outline: 0 none;

  @media ${DEVICE.sd} {
    display: inline-block;
  }

  &:hover {
    opacity: 0.7;
  }

  > span {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }
`;

const Hamburger: React.FC<HamburgerProps> = ({ isActive, ...props }) => (
  <HamburgerContainer {...props}>
    <span>
      <Icon isActive={isActive} />
    </span>
  </HamburgerContainer>
);

export default Hamburger;
