export const STAGE_MYLIST = 'videoTitleDetail-stage-mylist';
export const STAGE_RATE = 'videoTitleDetail-stage-rate';
export const STAGE_PLAY = 'videoTitleDetail-stage-play';
export const STAGE_SHARE = 'videoTitleDetail-stage-share';
export const CAST_LIST_CASE_NAME = 'videoTitleDetail-castList-name';
export const RELATED_VIDEO_TITLE_LIST_TITLE_CARD =
  'videoTitleDetail-relatedVideoTitleList-titleCard';
export const RELATED_VIDEO_TITLE_LIST_VIDEO_MORE =
  'videoTitleDetail-relatedVideoTitleList-videoMore';
export const RELATED_BOOK_TITLE_LIST_TITLE_CARD =
  'videoTitleDetail-relatedBookTitleList-titleCard';
export const RELATED_BOOK_TITLE_LIST_BOOK_MORE =
  'videoTitleDetail-relatedBookTitleList-bookMore';
export const RECOMMEND_VIDEO_TITLE_LIST_TITLE_CARD =
  'videoTitleDetail-recommendVideoTitleList-titleCard';
export const STAGE_SIGNUP = 'videoTitleDetail-stage-signup';
export const EPISODE_LIST_PLAY = 'videoTitleDetail-episodeList-play';
export const EPISODE_LIST_EPISODE_DETAIL =
  'videoTitleDetail-episodeList-episodeDetail';
export const EPISODE_LIST_PAGER_TAB = 'videoTitleDetail-episodeList-pagerTab';

export const TITLE_SPOT = 'videoTitleDetail--titleSpot';
