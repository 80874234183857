import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cosmo_bookTitleDetailQuery } from '../../../__generated__/globalTypes';
import { default as AkamaiImage } from '../../../shared/components/Common/AkamaiImage';
import { RoundBadge } from '../../../shared/components/Common/Badge/RoundBadge';
import { default as RatingStar } from '../../../shared/components/Common/RatingStar';
import TitleLink from '../../../shared/components/TitleLink';
import { UrlQueryParams } from '../../../shared/constants';
import { globalMessages } from '../../../shared/constants/messages';
import useMedia from '../../../shared/hooks/useMedia';
import { DEVICE } from '../../../shared/styles';
import { getTextWithSeparator } from '../../../utils/textHelper';
import {
  ComicMainImage,
  ComicStageButtonArea,
  ComicStageContainer,
  ComicStageLayout,
  ComicStageMetaArea,
  ComicStageTitle,
  ComicStarTextContainer,
  ComicTab,
  ComicTabNewBadge,
  ComicTabsLayout,
  EvaluationContainer,
  OriginalIcon,
  SaleStatusContainer,
  StageCatch,
  StagePromotionArea,
  StageSection,
  StageSubInfoArea,
  StageSubInfoText,
  StageTitleArea,
} from '../shared/StageSection';
import Badges, { ChapterComicBadges } from './Badges';
import BookUserButtonsGroup, {
  BookUserButtonsGroupProps,
} from './BookUserButtonsGroup';
import messages from './messages';

type ComicStageSectionProps = {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
} & BookUserButtonsGroupProps;

const ComicStageSection: React.FC<ComicStageSectionProps> = ({
  bookSakuhin,
  ...bookUserButtonGroupProps
}) => {
  const intl = useIntl();
  const router = useRouter();
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );

  if (!bookSakuhin?.book) {
    return null;
  }
  const creditNames = bookSakuhin.book.credits.map((c) => c.penName);
  const publishedIn = bookSakuhin.book.publishedIn.map((c) => c.name);
  const isUnextOriginal = bookSakuhin.isUnextOriginal;
  const isWebtoon = bookSakuhin.book.isWebtoon;
  const bookTitle = bookSakuhin.name;

  const bookTypeText = getTextWithSeparator(
    [
      `${intl.formatMessage(globalMessages[bookSakuhin.book.mediaType.code])}${
        bookSakuhin.subgenreTagList[0]?.name
          ? `（${bookSakuhin.subgenreTagList[0].name}）`
          : ''
      }`,
      isWebtoon ? intl.formatMessage(messages.webtoon) : undefined,
      ...(bookSakuhin.isCompleted
        ? [intl.formatMessage(messages.completed)]
        : []),
    ],
    '｜'
  );

  const bookCreditText = getTextWithSeparator(
    [
      creditNames.length > 1 ? `${creditNames[0]}...` : creditNames[0],
      ...(bookSakuhin.book.publisher?.name
        ? [bookSakuhin.book.publisher.name]
        : []),
      ...publishedIn,
    ],
    '｜'
  );

  const akamaiImageProps = {
    src: `//${bookSakuhin.thumbnail.standard}`,
    availableSizes: [128, 284],
    sizeConfig: {
      SD: {
        width: 284,
      },
      TABLET: {
        width: 210,
      },
      MOBILE_WIDE: {
        width: 143,
      },
    },
    alt: bookTitle,
  };

  const isChapter = bookSakuhin.isChapter;
  const tabItems = bookSakuhin.bookSakuhinChapterLink
    ? [
        {
          name: intl.formatMessage(globalMessages.bookChapter),
          isActive: isChapter,
          sakuhinCode: isChapter
            ? bookSakuhin.sakuhinCode
            : bookSakuhin.bookSakuhinChapterLink.bookSakuhinCode,
        },
        {
          name: intl.formatMessage(globalMessages.bookVolume),
          isActive: !isChapter,
          sakuhinCode: !isChapter
            ? bookSakuhin.sakuhinCode
            : bookSakuhin.bookSakuhinChapterLink.bookSakuhinCode,
        },
      ]
    : undefined;

  // During tab (chapter/volume) switching, keep the url params so that a user can come back to the same page
  const titleDetailOptionParams = {
    [UrlQueryParams.BOOK_CODE]: router.query[UrlQueryParams.BOOK_CODE],
    [UrlQueryParams.BOOK_VIEW_CODE]:
      router.query[UrlQueryParams.BOOK_VIEW_CODE],
    [UrlQueryParams.FEATURE_PIECE_CODE]:
      router.query[UrlQueryParams.FEATURE_PIECE_CODE],
  };
  // Encode params so as not to affect the other page (ex. Volume page's params should not affect Chapter page)
  const optionQuery = JSON.stringify(titleDetailOptionParams);
  const altQuery = router.query[UrlQueryParams.BOOK_ALT_PARAMS];
  const altTabQuery =
    typeof altQuery === 'string'
      ? JSON.parse(altQuery) // Decode and pass params back to the other tab
      : { [UrlQueryParams.BOOK_ALT_PARAMS]: optionQuery }; // Encode and leave its own params with the other tab

  const ComicBadgesAndButtons = () => (
    <>
      {tabItems && (
        <ComicTabsLayout>
          {tabItems.map((item, index) => (
            <TitleLink
              key={`key-${bookSakuhin.sakuhinCode}-${index}`}
              type="book"
              titleCode={item.sakuhinCode}
              query={altTabQuery}
              disabled={item.isActive}
            >
              <ComicTab isActive={item.isActive}>
                {item.name}
                {(item.isActive
                  ? bookSakuhin.isNew
                  : bookSakuhin.bookSakuhinChapterLink?.isNew) && (
                  <ComicTabNewBadge badgeType="promotion" badgeSize="small">
                    {intl.formatMessage(globalMessages.new)}
                  </ComicTabNewBadge>
                )}
              </ComicTab>
            </TitleLink>
          ))}
        </ComicTabsLayout>
      )}
      <SaleStatusContainer>
        {isChapter ? (
          <ChapterComicBadges bookSakuhin={bookSakuhin} />
        ) : (
          <Badges bookSakuhin={bookSakuhin} />
        )}
      </SaleStatusContainer>
      <BookUserButtonsGroup
        bookSakuhin={bookSakuhin}
        {...bookUserButtonGroupProps}
      />
    </>
  );

  return (
    <ComicStageContainer>
      <ComicStageLayout>
        <StageSection>
          <ComicStageMetaArea>
            <StageTitleArea>
              <StagePromotionArea>
                {isUnextOriginal && <OriginalIcon />}
                {!tabItems && bookSakuhin.isNew && (
                  <RoundBadge badgeType="promotion" mobileBadgeSize="small">
                    <FormattedMessage {...globalMessages.new} />
                  </RoundBadge>
                )}
              </StagePromotionArea>
              <ComicStageTitle>{bookTitle}</ComicStageTitle>
              {bookSakuhin.detail.catchSentence && (
                <StageCatch>{bookSakuhin.detail.catchSentence}</StageCatch>
              )}
            </StageTitleArea>
            <StageSubInfoArea>
              <ComicStarTextContainer>
                <EvaluationContainer>
                  <RatingStar rate={bookSakuhin.rate} iconWidth={12} />
                </EvaluationContainer>
                {bookTypeText && (
                  <StageSubInfoText>{bookTypeText}</StageSubInfoText>
                )}
              </ComicStarTextContainer>
              <StageSubInfoText>{bookCreditText}</StageSubInfoText>
            </StageSubInfoArea>
          </ComicStageMetaArea>
          {!isMobile && (
            <ComicStageButtonArea>
              <ComicBadgesAndButtons />
            </ComicStageButtonArea>
          )}
        </StageSection>
        <ComicMainImage>
          <AkamaiImage {...akamaiImageProps} />
        </ComicMainImage>
      </ComicStageLayout>
      {isMobile && (
        <ComicStageButtonArea>
          <ComicBadgesAndButtons />
        </ComicStageButtonArea>
      )}
    </ComicStageContainer>
  );
};

export default ComicStageSection;
