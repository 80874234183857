import { useCallback, useRef, useState } from 'react';

interface UseDelayReturnedValue {
  hasExpired: boolean;
  delay: () => void;
}

/**
 * A hook to start a timer.
 * The useDelay hook requires an arugument, ms, as a value of time to delay in millisecond,
 * and it returns an object including two values.
 *   - hasExpired   (boolean) indicates that whether the timer is expired.
 *   - delay        (function) extends the timer.
 *
 * @param {number} ms  milliseconds to delay
 * @returns {object}
 *
 **/
const useDelay = (ms: number): UseDelayReturnedValue => {
  const timerRef = useRef<number>();
  const [hasExpired, setHasExpired] = useState(true);

  const delay = useCallback(() => {
    setHasExpired(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setHasExpired(true);
    }, ms);
  }, [ms]);

  return { hasExpired, delay };
};

export default useDelay;
