import React from 'react';
import styled, { css } from 'styled-components';
import type { Nullable } from '../../../../utils/Nullable';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import BookThumbnail, { Shadow as BookShadow } from '../Book/Thumbnail';
import LiveThumbnail, { Shadow as LiveShadow } from '../Live/LiveThumbnail';
import VideoThumbnail, { Shadow as VideoShadow } from '../Video/VideoThumbnail';

type CardType = 'book' | 'video' | 'live';

type VideoProps = {
  type: 'video';
  thumbnailUrl: string;
  titleName: string;
  isLazy: boolean;
  isOnlyOn: boolean;
  isNew: Nullable<boolean>;
  isPoint: boolean;
};

type BookProps = {
  type: 'book';
  thumbnailUrl: string;
  titleName: string;
  isLazy: boolean;
  isNew: boolean;
  isDailyFree: boolean;
  isDailyFreePlus: boolean;
  numberOfFree: string;
};

type LiveProps = {
  type: 'live';
  thumbnailUrl: string;
  name: string;
  isLazy: boolean;
  isOnlyOn: boolean;
  isPoint: boolean;
};

type Props = VideoProps | BookProps | LiveProps;

const FreeBookNum = styled.div`
  margin-top: 2px;
  color: ${COLORS.primary};
  font-size: 12px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    margin-top: 1px;
    font-size: 10px;
  }
`;

const Layout = styled.div<{ type: CardType }>`
  width: 100%;
  ${({ type }) =>
    type !== 'book' &&
    css`
      border-radius: 10px;
      overflow: hidden;
    `};
  border: 1px solid ${COLORS.white_10};
  box-sizing: border-box;
  transition: transform 200ms ease-in;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      ${VideoShadow}, ${BookShadow}, ${LiveShadow} {
        opacity: 1;
      }
    }
  }
`;

const TitleCardLite: React.FC<Props> = ({ ...props }) => {
  const hasNumberOfFree = 'numberOfFree' in props;
  return (
    <>
      <Layout type={props.type}>
        {props.type === 'video' ? (
          <VideoThumbnail {...props} />
        ) : props.type === 'book' ? (
          <BookThumbnail {...props} />
        ) : (
          <LiveThumbnail {...props} />
        )}
      </Layout>
      {hasNumberOfFree && <FreeBookNum>{props.numberOfFree}</FreeBookNum>}
    </>
  );
};

export default TitleCardLite;
