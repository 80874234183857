var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"20240919-a1c6d053530224260f10632619530eb4a3416ec9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_CONFIG } from './configs/sentry';
import { GlobalConfig } from './shared/constants';

Sentry.init({
  ...SENTRY_CONFIG,

  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['cosmo'],
      behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
    }),
  ],
  // we disable replay for now but will re-evaluate in the future
  // replaysOnErrorSampleRate: GlobalConfig.APP_ENV === 'prod' ? 0 : 1.0,
  // replaysSessionSampleRate: GlobalConfig.APP_ENV === 'prod' ? 0 : 1.0,
  // integrations: [
  //   Sentry.replayIntegration({
  //     maskAllText: true,
  //     blockAllMedia: true,
  //   }),
  // ],
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,
  beforeBreadcrumb: (breadcrumb) => {
    // exclude fetch requests to graphql server from breadcrumbs
    // because graphql breadcrumbs are already created by `apollo-link-sentry`
    // see app/lib/apolloClient.ts
    if (breadcrumb.category === 'fetch') {
      const url: string = breadcrumb.data?.url ?? '';
      if (url.includes(GlobalConfig.URLS.NEXUS)) {
        return null;
      }
    }
    return breadcrumb;
  },
});
