import React, { ForwardRefRenderFunction, HTMLAttributes } from 'react';
import styled from 'styled-components';

// English
// This is "Icon" component
// This component is for Icon with bounding-box which you can take from figma.
// If you are using icon without bounding-box which you can take from abstract, please use 'LegacyIcon' component.

// 日本語
// このコンポーネントは"Icon"です
// figmaから取得することができるアイコン(bounding-box付き)で使うためのコンポーネントです
// abstractから取得することができるアイコン(bouding-boxなし)を使う場合には'LegacyIcon'を使ってください。

const icons = require.context(
  '../../../../assets/iconsWithBoundingBox',
  true,
  /\.svg$/
);

// When you import new icon SVGs from Figma, edit SVG code as below
// 1. Delete "width" and "height" attributes of "svg" tag
// 2. Change fill color ("fill" attribute) to "currentColor"
export enum Icons {
  // for Leanback
  LB_HOME = 'Home.svg',
  LIBRARY = 'Library.svg',
  VIDEO = 'Video.svg',
  BOOK = 'Book.svg',
  SETTING = 'Settings.svg',

  POINT = 'Points.svg',
  POINT_CHARGE = 'PointCharge.svg',
  ACCOUNT = 'Account.svg',
  HELP = 'Help.svg',
  U_MARK = 'Mark.svg',
  INFO = 'Info.svg',
  CAUTION = 'Caution.svg',
  DAILY_FREE_PLUS = 'daily-free-x-price.svg',
  DAILY_FREE_PLUS_INACTIVE = 'daily-free-x-price-inactive.svg',
  EDIT = 'Edit.svg',
  DELETE = 'Delete.svg',
  PURCHASE = 'Purchase.svg',
  TO_PURCHASE = 'ToPurchase.svg',
  EPISODE = 'Episode.svg',
  PUSH_LINK = 'Pushlink.svg',
  PUSH_LINK_ACTIVE = 'Pushlink_Active.svg',
  ADD = 'Add.svg',
  ADDED = 'Added.svg',
  RATE = 'Rate.svg',
  RATED = 'Rated.svg',
  SHARE = 'Share.svg',
  COPY = 'Copy.svg',
  CLOSE = 'Close.svg',
  SEARCH = 'Search.svg',
  SORT = 'Sort.svg',
  FILTER = 'Filter.svg',
  CALENDAR = 'Calendar.svg',
  NEW_TAB = 'NewTab.svg',

  STAR_FULL = 'StarFull.svg',
  STAR_HALF = 'StarHalf.svg',
  STAR_BLANK = 'StarBlank.svg',

  PLAY = 'Play.svg',
  REPLAY = 'Replay.svg',
  READ = 'Read.svg',
  PREORDER = 'icon_media_reservation.svg',
  PREORDERED = 'icon_notification_reserved.svg',

  FULL_ARROW_UP = 'Full_Arrow_Up.svg',
  FULL_ARROW_DOWN = 'Full_Arrow_Down.svg',
  FULL_ARROW_RIGHT = 'Full_Arrow_Right.svg',
  FULL_ARROW_LEFT = 'Full_Arrow_Left.svg',

  HALF_ARROW_RIGHT = 'Half_Arrow_Right.svg',
  HALF_ARROW_LEFT = 'Half_Arrow_Left.svg',
  HALF_ARROW_UP = 'Half_Arrow_Up.svg',
  HALF_ARROW_DOWN = 'Half_Arrow_Down.svg',

  SNS_FACEBOOK = 'sns/icon_facebook.svg',
  SNS_X = 'sns/icon_x.svg',
  SNS_HATENA = 'sns/icon_hatena.svg',
  SNS_LINE = 'sns/icon_line.svg',

  FONT_SIZE = 'Fontsize.svg',
  CHAPTER = 'Chapter.svg',
  HELP_BOOK = 'Help_Book.svg',
  FULL_SCREEN_ON = 'Fullscreen_On.svg',
  FULL_SCREEN_OFF = 'Fullscreen_Off.svg',
  ZOOM_IN = 'Zoom_In.svg',
  ZOOM_OUT = 'Zoom_Out.svg',
}

export enum GenreIcons {
  ADULT = 'genres/Adult.svg',
  LIVE = 'genres/Live.svg',
  LIVE_MUSIC_STAGE_EVENT = 'genres/Music.svg',
  LIVE_MARTIAL_ARTS = 'genres/Martial.svg',
  LIVE_SOCCER = 'genres/Soccer.svg',
  LIVE_GOLF = 'genres/Golf.svg',
  LIVE_BASEBALL = 'genres/Baseball.svg',
  LIVE_OTHER_SPORTS = 'genres/OtherSports.svg',
  MNU0000001 = 'genres/Anime.svg',
  MNU0000063 = 'genres/KoreanAsia.svg',
  BOOK = 'genres/Novel.svg',
  COMIC = 'genres/Comic.svg',
  DOCUMENT = 'genres/Documentary.svg',
  MNU0000050 = 'genres/ForeignDramaTV.svg',
  MNU0000117 = 'genres/Hollywood.svg',
  MNU0000035 = 'genres/LocalCinema.svg',
  MNU0000076 = 'genres/LocalDrama.svg',
  MNU0000090 = 'genres/Kids.svg',
  RANOBE = 'genres/LightNovel.svg',
  MAGAZINE = 'genres/Magazine.svg',
  MNU0000018 = 'genres/WorldCinema.svg',
  MUSIC_IDOL = 'genres/Music.svg',
  NEWS_SPORTS = 'genres/NewsSports.svg',
  MNU0000124 = 'genres/TVChannels.svg',
  MNU0000102 = 'genres/VarietyCulture.svg',
  STAGE_PLAY = 'genres/StageTheater.svg',
  SOCCER = 'genres/Soccer.svg',
  GOLF = 'genres/Golf.svg',
  MARTIAL = 'genres/Martial.svg',
  OTH_SPORTS = 'genres/Baseball.svg',
  KIDS_BOOK = 'genres/BookKids.svg',
  UNKNOWN = 'genres/Null.svg',

  // Genre icons which are imported but not used currently
  // 'genres/Basketball.svg'
  // 'genres/Futsal.svg'
  // 'genres/Hockey.svg'
  // 'genres/IceHockey.svg'
}

const IconContainer = styled.div`
  display: inline-flex;
  text-align: center;
  align-self: center;
  > svg {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

interface IconProps extends HTMLAttributes<HTMLDivElement> {
  name: Icons | GenreIcons;
}

const Icon: ForwardRefRenderFunction<HTMLDivElement, IconProps> = (
  { name, ...props },
  ref
) => {
  const LoadedIcon = icons(`./${name}`).default;
  return (
    <IconContainer {...props} ref={ref}>
      <LoadedIcon />
    </IconContainer>
  );
};

export const IconWithoutContainer = ({
  name,
}: {
  name: Icons | GenreIcons;
}) => {
  const LoadedIcon = icons(`./${name}`).default;
  return <LoadedIcon />;
};

export default React.forwardRef<HTMLDivElement, IconProps>(Icon);
