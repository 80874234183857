import Link from 'next/link';
import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';

interface KeyArtProps {
  backgroundImage?: string;
}

// This code could be written with `picture` element, ` object-position`, and `object-fit`.
// It may be able to be more straight code and allow to select multiple image format.
// but IE11 does not support it.
const KeyArtImage = styled.div<KeyArtProps>`
  position: relative;
  width: 100%;
  padding-bottom: ${(9 / 16) * 100}%;
  background-size: cover;
  background-position: 50% 30%;
  background-color: ${({ theme }) => theme.titleDetail.keyArt.background};
  background-image: ${({ backgroundImage }) =>
    backgroundImage
      ? `url(${backgroundImage}?output-format=jpg&output-quality=70&resize=1600:*)`
      : ''};

  @media ${DEVICE.tablet} {
    background-image: ${({ backgroundImage }) =>
      backgroundImage
        ? `url(${backgroundImage}?output-format=jpg&output-quality=70&resize=1024:*)`
        : ''};
  }

  @media ${DEVICE.mobileWide} {
    padding-bottom: 65%;
  }
`;

const OverlayBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180px;
  background: linear-gradient(
    0deg,
    rgb(10, 20, 33) 0%,
    rgba(10, 20, 33, 0) 50%
  );

  @media ${DEVICE.mobileWide} {
    height: 50%;
    background: linear-gradient(
      0deg,
      rgb(10, 20, 33) 13.52%,
      rgba(10, 20, 33, 0.4) 55.98%,
      rgba(10, 20, 33, 0) 100%
    );
  }
`;

const OverlayLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 256px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgb(10, 20, 33) 0%,
    rgba(10, 20, 33, 0.5) 50%,
    rgba(10, 20, 33, 0) 100%
  );

  @media ${DEVICE.mobileWide} {
    display: none;
  }
`;

export const KeyartLayout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  @media ${DEVICE.mobileWide} {
    pointer-events: none; // モバイルUIではリンクを無効化する
    width: 100%;
  }
`;

const KeyartContainer = styled.div`
  width: 640px;

  @media ${DEVICE.sd} {
    width: calc(100vw - 320px - 24px);
  }

  @media ${DEVICE.mobileWide} {
    width: 100%;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  box-sizing: border-box;

  opacity: 0;
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.black};
  color: ${COLORS.black};
`;

const StyledIcon = styled(Icon)`
  width: 28px;
`;

const StyledLink = styled(Link)`
  outline: none;
  &:hover > ${IconContainer} {
    opacity: 1;
  }
`;

export const KeyartLink: React.FC<
  ComponentProps<typeof StyledLink> & { iconType: 'play' | 'read' }
> = ({ children, iconType, ...props }) => {
  return (
    <StyledLink {...props}>
      {children}
      <IconContainer>
        <StyledIcon name={iconType === 'play' ? Icons.PLAY : Icons.READ} />
      </IconContainer>
    </StyledLink>
  );
};

const KeyArt: React.FC<KeyArtProps> = ({ backgroundImage }) => {
  return (
    <KeyartContainer>
      <KeyArtImage backgroundImage={backgroundImage} />
      <OverlayBottom />
      <OverlayLeft />
    </KeyartContainer>
  );
};

export default KeyArt;
