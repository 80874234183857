import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';
import SideButton from '../SideButton';

const ItemContainer = styled.button`
  width: 100%;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: 64px;
  box-sizing: border-box;
  padding: 0 16px;

  cursor: pointer;
  transition: background-color 0.15s ease-in;
  color: ${({ theme }) => theme.simpleList.text};
  border-radius: 3px;

  @media ${DEVICE.mobileWide} {
    height: 56px;
  }
  &:focus {
    background-color: ${({ theme }) => theme.simpleList.backgroundHover};
  }

  @media (hover) {
    &:hover {
      outline: none;
      background-color: ${({ theme }) => theme.simpleList.backgroundHover};
    }
  }
`;

const Text = styled.span`
  margin-right: auto;
  font-size: 18px;
  font-weight: 300;
  text-align: left;

  @media ${DEVICE.mobileWide} {
    font-size: 16px;
  }
`;

const StyledSideButton = styled(SideButton)`
  &&& {
    margin-left: auto;
    width: 24px;
    height: 24px;

    div {
      width: 24px;
      height: 24px;

      svg {
        top: 0;
      }
    }
    @media ${DEVICE.mobileWide} {
      width: 20px;
      height: 20px;

      div {
        width: 20px;
        height: 20px;
      }
    }

    @media (hover) {
      ${ItemContainer}:hover & {
        div {
          opacity: 1;
        }
      }
    }
    ${ItemContainer}:focus & {
      div {
        opacity: 1;
      }
    }
  }
`;

const SimpleListItem: React.FC<ButtonHTMLAttributes<HTMLElement>> = ({
  children,
  ...props
}) => {
  return (
    <ItemContainer {...props}>
      <Text>{children}</Text>
      <StyledSideButton />
    </ItemContainer>
  );
};

export default SimpleListItem;
