import Link from 'next/link';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { cosmo_getSearchGenresQuery } from '../../../__generated__/globalTypes';
import Icon, { GenreIcons } from '../../../shared/components/Common/Icon';
import SimpleListItem from '../../../shared/components/Common/SimpleListItem';
import MainContentHeader from '../../../shared/components/MainContentHeader';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { useLockBodyScroll } from '../../../shared/hooks/useLockBodyScroll';
import { useSearchInputContext } from '../../../shared/SearchInputContext';
import { DEVICE } from '../../../shared/styles';
import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_SD,
} from '../../../shared/styles/constants';
import { GenreUrlPrefix } from '../../Genre/constants';
import { GENRE_LIST_GENRE } from '../../Log/ComponentName/search';
import { GET_SEARCH_GENRES } from '../gql';
import { messages } from '../messages';

const Placeholders = [
  { width: 148, num: 10 },
  { width: 148, num: 1 },
  { width: 124, num: 4 },
];

const containerCss = css`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: ${HEADER_HEIGHT_SD}px;
  padding: 48px 16px 16px;
  box-sizing: border-box;
  transition: transform 0.15s ease-in;

  @media ${DEVICE.exceptSd} {
    margin-top: ${HEADER_HEIGHT}px;
    padding-right: 10%;
    padding-left: 10%;
  }
`;

const GenreMain = styled.div<{ isSubActive?: boolean }>`
  ${containerCss};
  transform: ${({ isSubActive }) =>
    isSubActive ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, 0, 0)'};
`;

const GenreSub = styled.div<{ isSubActive?: boolean }>`
  ${containerCss};
  transform: ${({ isSubActive }) =>
    isSubActive ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
`;

const GenreMainTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const GenreGroupName = styled.div`
  margin-top: 26px;
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.text.faded};
`;

const GenreGroupNamePlaceholder = styled.div`
  width: 108px;
  height: 22px;
  margin-top: 26px;
  background-color: ${({ theme }) => theme.search.genreButton.background};
`;

const GenreButtonsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
`;

const GenreButtonCommonCss = css`
  background-color: ${({ theme }) => theme.search.genreButton.background};
  height: 44px;
  border-radius: 22px;
  border: 0.5px solid ${({ theme }) => theme.search.genreButton.border};

  @media ${DEVICE.mobile} {
    flex-grow: 1;
    width: calc(50% - 2px);
    max-width: 50%;
  }
`;

const GenreButtonPlaceholder = styled.div<{ width: number }>`
  ${GenreButtonCommonCss};
  width: ${({ width }) => width}px;
`;

const GenreButton = styled.button`
  ${GenreButtonCommonCss};
  display: flex;
  padding: 0 15px 0 10px;
  align-items: center;
  color: ${({ theme }) => theme.search.genreButton.text};
  transition: background-color 0.15s ease-in, color 0.15s ease-in;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      theme.search.genreButton.backgroundHover};
  }

  > span {
    font-weight: bold;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > div:first-child {
    margin-right: 2px;
    width: 24px;
    height: 24px;
  }
`;
const SubGenreTitle = styled.div`
  position: relative;
  margin: 8px 0;
  padding: 0 16px;
  font-weight: bold;
  font-size: 16px;

  @media ${DEVICE.sd} {
    text-align: center;
    > div {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;

const SearchGenres: React.FC = () => {
  const intl = useIntl();
  const [scrollTarget, setScrollTarget] = useState<HTMLDivElement>();
  const { searchBoxElement, isSearchMobileUI, searchOpen } =
    useSearchInputContext();
  const [genreCategories, setGenreCategories] = useState<
    | {
        genre: cosmo_getSearchGenresQuery['webfront_searchGenres'][number]['searchGenres'][number];
        categories: cosmo_getSearchGenresQuery['webfront_searchGenres'][number]['searchGenres'][number]['searchCategories'];
      }
    | undefined
  >(undefined);
  const { loading, data, error } =
    useClientQuery<cosmo_getSearchGenresQuery>(GET_SEARCH_GENRES);

  useLockBodyScroll(searchOpen, !scrollTarget, scrollTarget);

  if (error) {
    throw error;
  }

  let genreGroups: cosmo_getSearchGenresQuery['webfront_searchGenres'] = [];

  if (data?.webfront_searchGenres) {
    genreGroups = data.webfront_searchGenres;
  }

  const genreUrlPrefix =
    GenreUrlPrefix[
      genreCategories?.genre.menuTypeCode as keyof typeof GenreUrlPrefix
    ];

  return (
    <>
      {!isSearchMobileUI &&
        genreCategories &&
        searchBoxElement &&
        searchOpen &&
        ReactDOM.createPortal(
          <MainContentHeader.CircleButton
            text={intl.formatMessage(messages.genreContainerTitle)}
            onClick={() => {
              setGenreCategories(undefined);
            }}
          />,
          searchBoxElement
        )}
      <GenreMain
        isSubActive={!!genreCategories}
        ref={(ref) => {
          if (!genreCategories && ref) setScrollTarget(ref);
        }}
      >
        <GenreMainTitle data-ucn="searchGenres-title-text">
          <FormattedMessage {...messages.genreContainerTitle} />
        </GenreMainTitle>
        {loading
          ? Placeholders.map((group, index) => (
              <React.Fragment key={index}>
                <GenreGroupNamePlaceholder />
                <GenreButtonsContainer>
                  {[...Array(group.num)].map((_, index) => (
                    <GenreButtonPlaceholder key={index} width={group.width} />
                  ))}
                </GenreButtonsContainer>
              </React.Fragment>
            ))
          : genreGroups
              .filter(
                (group) =>
                  group.displayCode === 'VIDEO' ||
                  group.displayCode === 'LIVE' ||
                  group.displayCode === 'BOOK'
              )
              .map((group) => {
                return (
                  <React.Fragment
                    key={`search-genre-item-${group.displayCode}`}
                  >
                    <GenreGroupName>{group.name}</GenreGroupName>
                    <GenreButtonsContainer
                      data-ucn={`searchGenres-${group.displayCode.toLowerCase()}-container`}
                    >
                      {group.searchGenres.map((sg) => (
                        <GenreButton
                          data-ucn={GENRE_LIST_GENRE}
                          key={`genre-${sg.id}`}
                          onClick={() =>
                            setGenreCategories({
                              genre: sg,
                              categories: sg.searchCategories,
                            })
                          }
                        >
                          <Icon
                            name={
                              GenreIcons[
                                sg.displayCode as keyof typeof GenreIcons
                              ] || GenreIcons.UNKNOWN
                            }
                          />
                          <span>{sg.name}</span>
                        </GenreButton>
                      ))}
                    </GenreButtonsContainer>
                  </React.Fragment>
                );
              })}
      </GenreMain>
      <GenreSub
        isSubActive={!!genreCategories}
        ref={(ref) => {
          if (genreCategories && ref) setScrollTarget(ref);
        }}
      >
        {!!genreCategories && (
          <>
            <SubGenreTitle>
              {isSearchMobileUI && (
                <MainContentHeader.CircleButton
                  onClick={() => {
                    setGenreCategories(undefined);
                  }}
                />
              )}
              {genreCategories.genre.name}
            </SubGenreTitle>
            {genreCategories.categories.map(
              (
                category: cosmo_getSearchGenresQuery['webfront_searchGenres'][number]['searchGenres'][number]['searchCategories'][number]
              ) => (
                <Link
                  key={`category-${category.id}`}
                  href={`${genreUrlPrefix}/browse/genre/[genreCode]/[categoryCode]`}
                  as={`${genreUrlPrefix}/browse/genre/${genreCategories.genre.id}/${category.id}`}
                  tabIndex={-1}
                >
                  <SimpleListItem>{category.name}</SimpleListItem>
                </Link>
              )
            )}
          </>
        )}
      </GenreSub>
    </>
  );
};

export default React.memo(SearchGenres);
