import React, { useMemo } from 'react';
import CastListItem, {
  CreditProps as CastListItemBaseProps,
} from './CastListItem';
import {
  CreditHeading,
  CreditListBlockHeading,
  CreditListBlocksContainer,
  CreditSectionContainer,
} from './Components';

interface BaseProps extends CastListItemBaseProps {
  group: string;
}

interface CastCredits extends BaseProps {
  personNameCode: string;
}

interface PublisherCredits extends BaseProps {
  linkHref: string;
  linkAs: string;
}

export type Credit = CastCredits | PublisherCredits;

interface Props {
  credits: Credit[];
  sectionHeading: string;
  mainGenreId?: string;
  castNameComponentName?: string;
  sakuhinCode?: string;
}

const CreditSection: React.FC<Props> = ({
  credits,
  sectionHeading,
  mainGenreId,
  castNameComponentName,
  sakuhinCode,
}) => {
  const [leftColumnItems, rightColumnItems] = useMemo(() => {
    let castListBlocks: {
      [key: string]: JSX.Element[];
    } = {};
    credits.forEach((credit, index) => {
      const { group, ...creditProps } = credit;
      if (!castListBlocks[group]) {
        castListBlocks = {
          ...castListBlocks,
          [group]: [
            <CreditListBlockHeading key={`${group}_heading`}>
              {group}
            </CreditListBlockHeading>,
          ],
        };
      }
      castListBlocks[credit.group].push(
        <CastListItem
          index={index}
          sakuhinCode={sakuhinCode}
          key={`${credit.personCode}_${credit.personNameCode}`}
          mainGenreId={mainGenreId}
          componentName={castNameComponentName}
          {...creditProps}
        />
      );
    });
    const leftColumnItems: (JSX.Element | JSX.Element[])[] = [];
    const rightColumnItems: (JSX.Element | JSX.Element[])[] = [];

    // The total number of items is equal to the number of items
    // in each castListBlock
    let numberOfItems = Object.keys(castListBlocks).length;
    Object.values(castListBlocks).forEach(
      (block) => (numberOfItems += block.length)
    );

    let leftColumnItemsNumber = 0;
    Object.entries(castListBlocks).forEach(([_, value], index) => {
      // Add to left column until it's about the half of the number of items
      // but since the first block is 出演 and it's long enough,
      // keep the left column smaller or equal than the right column.
      if (
        index === 0 ||
        leftColumnItemsNumber + value.length < Math.floor(numberOfItems / 2)
      ) {
        leftColumnItemsNumber += value.length;
        leftColumnItems.push(value);
      } else {
        rightColumnItems.push(value);
      }
    });
    return [leftColumnItems, rightColumnItems];
  }, [credits, sakuhinCode, mainGenreId, castNameComponentName]);

  return (
    <CreditSectionContainer>
      <CreditHeading>{sectionHeading}</CreditHeading>
      <CreditListBlocksContainer>
        <div>{leftColumnItems}</div>
        <div>{rightColumnItems}</div>
      </CreditListBlocksContainer>
    </CreditSectionContainer>
  );
};

export default React.memo(CreditSection);
