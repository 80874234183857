import type { BrowserOptions } from '@sentry/nextjs';
import { GlobalConfig } from '../shared/constants';

const isProd = GlobalConfig.APP_ENV === 'prod';

export const SENTRY_CONFIG: Partial<BrowserOptions> = {
  enabled: true,
  dsn: GlobalConfig.SENTRY_DSN,
  environment: isProd ? 'production' : GlobalConfig.APP_ENV,
  sampleRate: isProd ? 0.01 : 1,
  tracesSampleRate: isProd ? 0.0005 : 1,
  // consider the graphql error structure, depth:5 is enough
  normalizeDepth: 5,

  beforeSend: (event, hint) => {
    const error = hint.originalException;
    if (!error) {
      return event;
    }
    if (!(error instanceof Error)) {
      return event;
    }

    const message = error.message;
    if (!error.message) {
      return event;
    }

    if (message.includes('TOKEN_EXPIRED')) {
      event.fingerprint = ['{{ default }}', 'token-expired'];
    }

    // errors that are from ubookview
    // https://github.com/u-next/ubookview/blob/3d2c8a18eccd86bb6c0760f969a5b653f718cbee/src/common/errors.ts
    const ubookviewErrorMessages = [
      'コンテンツ読み込みでエラーが発生しました',
      'ダウンロードされたコンテンツにデータエラーが見つかりました',
      'ご希望のコンテンツが配信されていません。配信期限が切れた可能性があります',
      'サーバーエラーが発生しました',
      'ネットワークに接続できませんでした。読むには、通信が必要です',
      'ライセンス取得エラーが発生しました',
    ];
    if (ubookviewErrorMessages.some((msg) => message.includes(msg))) {
      event.fingerprint = ['{{ default }}', 'ubook-view-error'];
    }

    // TODO: investigate why this error is happening after we have source maps
    if (message.includes('The operation is insecure')) {
      event.fingerprint = ['{{ default }}', 'insecure-operation'];
    }

    return event;
  },

  // This is a list of errors that we want to ignore
  ignoreErrors: [
    // TreasureData Client Log Error
    'TreasureData network error',
    // A classic error from the react-dom, usually caused by browser extensions or google translate
    "Failed to execute 'removeChild' on 'Node'",
  ],
  /**
   * Note: if you want to override the automatic release value, do not set a
   * `release` value here - use the environment variable `SENTRY_RELEASE`, so
   * that it will also get attached to your source maps
   */
  // release: process.env.SENTRY_RELEASE,
};
