import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  BookDetailFragment,
  cosmo_bookTitleDetailQuery,
} from '../../../../../__generated__/globalTypes';
import { DailyFreeBadge } from '../../../../../shared/components/Common/Badge/DaillyFreeBadge';
import { PurchasedBadge } from '../../../../../shared/components/Common/Badge/PurchasedBadge';
import { RoundBadge } from '../../../../../shared/components/Common/Badge/RoundBadge';
import ProgressBar from '../../../../../shared/components/Common/ProgressBar';
import PointPriceBadge from '../../../../../shared/components/PointPriceBadge';
import { globalMessages } from '../../../../../shared/constants/messages';
import { useUserInfo } from '../../../../../shared/hooks/useUserInfo';
import { DEVICE } from '../../../../../shared/styles';
import { COLORS } from '../../../../../shared/styles/theme';

const StyledLink = styled.a`
  cursor: pointer;
  > div:hover {
    background-color: ${COLORS.white_10};
  }
`;

const Container = styled.div`
  display: flex;
  height: 80px;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  transition: background-color 200ms ease-in;

  @media ${DEVICE.mobileWide} {
    height: 56px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 320px;
  @media ${DEVICE.mobileWide} {
    width: 160px;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
`;

const FreeSaleText = styled.div`
  color: ${COLORS.white_90};
  font-size: 12px;
`;

const Title = styled.h3<{ faded?: boolean }>`
  color: ${({ faded }) => (faded ? COLORS.white_50 : COLORS.white)};
  margin: 0;
  font-size: 16px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

const BadgeRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const SubText = styled.div`
  color: ${COLORS.white_50};
  font-size: 12px;
  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

const StackedChapter: React.FC<
  | {
      book: BookDetailFragment;
      tickets: cosmo_bookTitleDetailQuery['bookTitle']['bookTickets'];
      sakuhinCode: string;
      price: number;
      isComplete?: boolean;
      progress: number;
      limitDay?: string;
    }
  | { nextUpdateText: string }
> = (props) => {
  const intl = useIntl();
  const { data: userInfoData } = useUserInfo();
  const isLoggedIn = !!userInfoData?.userInfo?.id;

  if ('nextUpdateText' in props) {
    return (
      <Container>
        <LeftContent>
          <Title>{intl.formatMessage(globalMessages.nextUpdate)}</Title>
          <SubText>{props.nextUpdateText}</SubText>
        </LeftContent>
      </Container>
    );
  }

  const { book, sakuhinCode, price, isComplete, progress, limitDay, tickets } =
    props;
  const hasRead = isComplete || progress > 0;

  return (
    <Link href={`/book/view/${sakuhinCode}/${book.code}`} legacyBehavior>
      <StyledLink data-ucn="stackedChapter-episode-link">
        <Container>
          <LeftContent>
            {(book.isNew || book.isPreorderable) && (
              <RoundBadge badgeType="promotion" mobileBadgeSize="small">
                {intl.formatMessage(
                  book.isPreorderable
                    ? globalMessages.preorder
                    : globalMessages.new
                )}
              </RoundBadge>
            )}
            <Title faded={hasRead}>{book.name}</Title>
            {hasRead && (
              <StyledProgressBar
                percentage={isComplete && progress === 0 ? 1 : progress}
              />
            )}
            {limitDay && <SubText>{limitDay}</SubText>}
          </LeftContent>
          <RightContent>
            {book.isFree && (
              <RoundBadge
                componentName="roundBadge-free"
                badgeType="white"
                mobileBadgeSize="small"
              >
                {intl.formatMessage(globalMessages.freeBadge)}
              </RoundBadge>
            )}
            <BadgeRow>
              {book.isBookSakuhinTicketAvailable && (
                <DailyFreeBadge
                  componentName="dailyFreeBadge-normal"
                  badgeType="normal"
                  mobileBadgeSize="small"
                  faded={
                    isLoggedIn && tickets?.sakuhinTicket?.availableCount === 0 // Don't fade if not logged in
                  }
                />
              )}
              {book.isBookPlusTicketAvailable && (
                <DailyFreeBadge
                  componentName="dailyFreeBadge-plus"
                  badgeType="plus"
                  mobileBadgeSize="small"
                  faded={
                    isLoggedIn && tickets?.plusTicket?.availableCount === 0 // Don't fade if not logged in
                  }
                />
              )}
            </BadgeRow>
            {!book.isBookSakuhinTicketAvailable &&
              !book.isBookPlusTicketAvailable && (
                <PointPriceBadge
                  isPoint={!book.isPreorderable && price > 0}
                  hasMultiplePrice={false}
                  minimumPrice={price}
                  badgeSize="medium"
                  mobileBadgeSize="small"
                />
              )}
            {book.isPurchased && <PurchasedBadge mobileBadgeSize="small" />}
            {book.saleText && <FreeSaleText>{book.saleText}</FreeSaleText>}
          </RightContent>
        </Container>
      </StyledLink>
    </Link>
  );
};

export default StackedChapter;
