import { createContext } from 'react';

interface PreorderStatus {
  isPreordered: boolean;
  isPreorderable: boolean;
}

export interface UpdatePreorderStatusAction {
  payload: {
    bookCode: string;
    isPreordered: boolean;
    isPreorderable: boolean;
  }[];
}

export const preorderStatusReducer = (
  state: Map<string, PreorderStatus>,
  action: UpdatePreorderStatusAction
): Map<string, PreorderStatus> => {
  const newState = new Map(state);
  action.payload.forEach((item) => {
    const bookCode = item.bookCode;
    const isPreordered = item.isPreordered;
    const isPreorderable = item.isPreorderable;
    newState.set(bookCode, { isPreordered, isPreorderable });
  });
  return newState;
};

export interface PreorderContextType {
  preorderStatus: Map<string, PreorderStatus>;
  updatePreorderStatus: React.Dispatch<UpdatePreorderStatusAction>;
}

export const PreorderContext = createContext<PreorderContextType>({
  preorderStatus: new Map(),
  updatePreorderStatus: () => {
    return null;
  },
});
