import type { IntlShape } from 'react-intl';
import {
  cosmo_genreQuery,
  cosmo_getLiveDetailQuery,
  LiveCardFragment,
} from '../../__generated__/globalTypes';
import type { LiveOnAirStatus } from '../../shared/components/LiveNow';
import { GlobalConfig } from '../../shared/constants';
import { LooseUserInfo } from '../../shared/LLPUserInfoContext';
import { messages } from './messages';

export const isActive = (
  number: number,
  activeRange: [number, number]
): boolean => number >= activeRange[0] && number <= activeRange[1];

export const getWarningProps = (
  userInfo: LooseUserInfo,
  intl: IntlShape
): {
  title: string;
  message: string;
  buttonText?: string;
  buttonUrl?: string;
} | null => {
  // Return null if there is no id (if the user is not logged in)
  if (userInfo && !userInfo.id) return null;
  if (userInfo?.blockInfo?.isBlocked) {
    const blockScore = userInfo.blockInfo.score;
    if (blockScore === 1) {
      return {
        title: intl.formatMessage(messages.passwordResetWarningTitle),
        message: intl.formatMessage(messages.passwordResetWarningMessage),
        buttonText: intl.formatMessage(messages.passwordResetWarningButtonText),
        buttonUrl: `${GlobalConfig.URLS.MYACCOUNT}/reset/login-info/start`,
      };
    } else if (blockScore === 2) {
      return {
        title: intl.formatMessage(messages.unusualUseWarningTitle),
        message: intl.formatMessage(messages.unusualUseWarningMessage),
        buttonText: intl.formatMessage(messages.unusualUseWarningButtonText),
        buttonUrl: `${GlobalConfig.URLS.CONTACT}/video-inquiry/LQ0001/MQ0071/SQ0201`,
      };
    } else if (blockScore === 3) {
      return {
        title: intl.formatMessage(messages.accountLockWarningTitle),
        message: intl.formatMessage(messages.accountLockWarningMessage),
        buttonText: intl.formatMessage(messages.accountLockWarningButtonText),
        buttonUrl: `${GlobalConfig.URLS.CONTACT}/video-inquiry/LQ0001/MQ0071/SQ0201`,
      };
    }
  }
  if (userInfo?.billingCaution) {
    const { title, description, linkUrl, suggestion } = userInfo.billingCaution;
    return {
      title,
      message: description,
      buttonText: suggestion ?? undefined,
      buttonUrl: linkUrl ?? undefined,
    };
  }
  if (userInfo && !userInfo.hasRegisteredEmail)
    return {
      title: intl.formatMessage(messages.emailWarningTitle),
      message: intl.formatMessage(messages.emailWarningMessage),
      buttonText: intl.formatMessage(messages.emailWarningButtonText),
      buttonUrl: `${GlobalConfig.URLS.SUPPORT}/mail/input`,
    };
  return null;
};

export const hasCatchup = (live: {
  subContentList:
    | cosmo_getLiveDetailQuery['webfront_getLive']['subContentList']
    | cosmo_genreQuery['liveBlocks']['blockList'][number]['list'][number]['liveList'][number]['subContentList'];
}): boolean => {
  return (
    live?.subContentList?.some(
      (subContent) => subContent?.typeCode === 'CATCHUP'
    ) || false
  );
};

export const findCatchup = (live: {
  subContentList:
    | cosmo_getLiveDetailQuery['webfront_getLive']['subContentList']
    | LiveCardFragment['subContentList'];
}):
  | NonNullable<
      cosmo_getLiveDetailQuery['webfront_getLive']['subContentList']
    >[number]
  | NonNullable<LiveCardFragment['subContentList']>[number]
  | null => {
  return (
    live?.subContentList?.find(
      (subContent) => subContent?.typeCode === 'CATCHUP'
    ) || null
  );
};

export const getLiveOnAirStatus = (
  live: LiveCardFragment
): LiveOnAirStatus | undefined => {
  const now = Date.now();
  const liveStartDate = new Date(live.deliveryStartDateTime).getTime();
  const liveEndDate = new Date(live.deliveryEndDateTime).getTime();
  const isLiveOnAir = liveStartDate <= now && now <= liveEndDate;

  if (isLiveOnAir) return 'LIVE_NOW';

  const catchup = findCatchup(live);
  if (!catchup) return undefined;

  const catchupStartDate =
    catchup.publicStartDateTime &&
    new Date(catchup.publicStartDateTime).getTime();
  const catchupEndDate =
    catchup.publicEndDateTime && new Date(catchup.publicEndDateTime).getTime();
  const allowTimeShiftedPlayback = live.allowsTimeshiftedPlayback;

  if (allowTimeShiftedPlayback) {
    // 巻き戻し再生が可の場合、ライブ終了〜見逃し終了は「見逃し配信中」
    return catchupEndDate && liveEndDate <= now && now <= catchupEndDate
      ? 'CATCHUP_NOW'
      : undefined;
  } else {
    // 巻き戻し再生が不可の場合、見逃し開始〜見逃し終了は「見逃し配信中」
    return catchupStartDate &&
      catchupEndDate &&
      catchupStartDate <= now &&
      now <= catchupEndDate
      ? 'CATCHUP_NOW'
      : undefined;
  }
};

export const selectNotice = (
  live: LiveCardFragment
): LiveCardFragment['notices'][number] | null => {
  const now = new Date();
  const nowTime = now.getTime();
  let notice: LiveCardFragment['notices'][number] | null = null;

  for (let i = live.notices.length - 1; i >= 0; i -= 1) {
    const noticeDate = new Date(live.notices[i].publicStartDateTime);
    if (noticeDate.getTime() < nowTime) {
      notice = live.notices[i];
    }
  }

  return notice;
};

export const getKafkaLogRowIndex = (
  countBlockClassName: string,
  targetId: string
) => {
  if (typeof document === 'object') {
    const blockList = Array.from(
      document.querySelectorAll(`.${countBlockClassName}`)
    );
    return blockList.findIndex((block) => block.id === targetId);
  }
  return -1;
};
