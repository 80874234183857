import React from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import KeyArt, { KeyartLayout } from './Keyart';
import {
  BookMainImage,
  BookStageButtonArea,
  BookStageLayout,
  StageMetaArea,
  VideoStageSection,
} from './StageSection';
import { UserButtonsGroupContainer } from './UserButtonsGroup';

const Base = styled.div`
  background: ${COLORS.charcoal};
`;

const PlayButton = styled(Base)`
  width: 240px;
  height: 50px;
  border-radius: 8px;

  @media ${DEVICE.mobileWide} {
    width: 100%;
    height: 48px;
  }
`;

const Title = styled(Base)`
  margin-top: 16px;
  height: 50px;
  width: 100%;

  @media ${DEVICE.mobileWide} {
    margin-top: 0;
  }
`;

const SubtitleLine1 = styled(Base)`
  margin-top: 24px;
  height: 16px;
  width: 90%;

  @media ${DEVICE.mobileWide} {
    margin-top: 22px;
  }
`;

const SubtitleLine2 = styled(Base)`
  margin-top: 8px;
  height: 16px;
  width: 80%;

  @media ${DEVICE.mobileWide} {
    margin-top: 6px;
  }
`;

const Description = styled(Base)`
  margin-top: 16px;
  height: 24px;
  width: 100%;

  @media ${DEVICE.mobileWide} {
    height: 20px;
  }
`;

const SocialLogo = styled(Base)`
  height: 24px;
  width: 24px;
  margin: 0 auto;
  @media ${DEVICE.mobileWide} {
    height: 30px;
    width: 30px;
  }
`;

const SocialTitle = styled(Base)`
  margin-top: 10px;
  height: 12px;
  width: 58px;
  @media ${DEVICE.mobileWide} {
    margin-top: 7px;
    height: 14px;
    width: 60px;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 15px;
`;

const Social: React.FC = () => (
  <SocialContainer>
    <SocialLogo />
    <SocialTitle />
  </SocialContainer>
);

const SocialGroup: React.FC = () => (
  <UserButtonsGroupContainer>
    <Social />
    <Social />
    <Social />
  </UserButtonsGroupContainer>
);

const MainArea = styled(StageMetaArea)`
  gap: 0;
`;

const ButtonArea = styled(BookStageButtonArea)`
  margin-top: 121px;
  @media ${DEVICE.mobileWide} {
    margin-top: 35px;
  }
`;

const BookImage = styled(Base)``;

const StageMain = ({ hideSocial }: { hideSocial?: boolean }) => {
  return (
    <>
      <MainArea>
        <Title />
        <SubtitleLine1 />
        <SubtitleLine2 />
        <Description />
      </MainArea>
      <ButtonArea>
        <PlayButton />
        {!hideSocial && <SocialGroup />}
      </ButtonArea>
    </>
  );
};

const TitleDetailPlaceholder = ({
  type,
}: {
  type: 'video' | 'book' | 'live';
}) => {
  return type === 'book' ? (
    <BookStageLayout>
      <div>
        <StageMain />
      </div>
      <BookMainImage>
        <BookImage />
      </BookMainImage>
    </BookStageLayout>
  ) : (
    <>
      <KeyartLayout>
        <KeyArt backgroundImage={undefined} />
      </KeyartLayout>
      <VideoStageSection>
        <StageMain hideSocial={type === 'live'} />
      </VideoStageSection>
    </>
  );
};

export default TitleDetailPlaceholder;
