import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

const IS_FROM_SHORT_CUT_MODE_QUERY = {
  key: 'short',
  value: '1',
};

export function checkIsFromShortcutMode(query: ParsedUrlQuery) {
  return (
    query[IS_FROM_SHORT_CUT_MODE_QUERY.key] ===
    IS_FROM_SHORT_CUT_MODE_QUERY.value
  );
}

function useIsFromShortcutMode() {
  const router = useRouter();

  return checkIsFromShortcutMode(router.query);
}

export default useIsFromShortcutMode;
