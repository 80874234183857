import { useMutation } from '@apollo/client';
import { useToasts } from '@u-next/react-toast-notifications';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_updateBookFavoriteMutation,
  cosmo_updateBookFavoriteMutationVariables,
  cosmo_updateVideoFavoriteMutation,
  cosmo_updateVideoFavoriteMutationVariables,
  FavoriteTypes,
} from '../../../__generated__/globalTypes';
import { useTreasureDataClient } from '../../../domain/Log/useTreasureDataClient';
import { GET_FAVORITES, GET_FAVORITES_BOOK } from '../../../domain/MyList/gql';
import { errorMessages } from '../../constants/messages';
import { useUserInfo } from '../useUserInfo';
import { UPDATE_BOOK_FAVORITE, UPDATE_VIDEO_FAVORITE } from './gql';

export const useVideoFavoriteMutation = (): ((args: {
  newFavoriteStatus: boolean;
  titleCode: string;
  titleName: string;
  needsOptimisticResponse: boolean;
  onSuccess?: (newFavoriteStatus: boolean) => void;
}) => void) => {
  const intl = useIntl();
  const { data: userInfoData } = useUserInfo({
    fetchPolicy: 'cache-first',
  });
  const treasureDataClient = useTreasureDataClient({
    userInfo: userInfoData?.userInfo,
  });
  const [updateVideoFavoriteFailed, setUpdateVideoFavoriteFailed] =
    useState(false);
  const { addToast, removeToast } = useToasts();
  const [updateVideoFavorite] = useMutation<
    cosmo_updateVideoFavoriteMutation,
    cosmo_updateVideoFavoriteMutationVariables
  >(UPDATE_VIDEO_FAVORITE, {
    onCompleted: () => setUpdateVideoFavoriteFailed(false),
    onError: () => setUpdateVideoFavoriteFailed(true),
    refetchQueries: [GET_FAVORITES],
  });

  const updateVideoFavoriteToastId = useRef<string | null>(null);
  useEffect(() => {
    if (updateVideoFavoriteToastId.current) {
      removeToast(updateVideoFavoriteToastId.current);
      updateVideoFavoriteToastId.current = null;
    }
    if (updateVideoFavoriteFailed) {
      addToast(
        intl.formatMessage(errorMessages.mylistUpdateToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          updateVideoFavoriteToastId.current = id;
        }
      );
    }
  }, [
    intl,
    addToast,
    removeToast,
    updateVideoFavoriteToastId,
    updateVideoFavoriteFailed,
  ]);

  const mutateVideoFavorite = useCallback(
    ({
      newFavoriteStatus,
      titleCode,
      titleName,
      needsOptimisticResponse,
      onSuccess,
    }: {
      newFavoriteStatus: boolean;
      titleCode: string;
      titleName: string;
      needsOptimisticResponse: boolean;
      onSuccess?: (newFavoriteStatus: boolean) => void;
    }) => {
      updateVideoFavorite({
        variables: {
          titleCode,
          type: newFavoriteStatus ? FavoriteTypes.ADD : FavoriteTypes.REMOVE,
        },
        optimisticResponse: needsOptimisticResponse
          ? {
              webfront_updateTitleStageFavorite: {
                __typename: 'TitleStage',
                id: titleCode,
                bookmarkStatus: newFavoriteStatus,
              },
            }
          : undefined,
      }).then((response) => {
        if (response.data) {
          if (treasureDataClient) {
            treasureDataClient.trackFavorite(
              {
                titleCode,
                titleName,
              },
              response.data.webfront_updateTitleStageFavorite?.bookmarkStatus
                ? 1
                : 0
            );
          }
          if (typeof onSuccess === 'function') {
            onSuccess(
              !!response.data.webfront_updateTitleStageFavorite?.bookmarkStatus
            );
          }
        }
      });
    },
    [treasureDataClient, updateVideoFavorite]
  );

  return mutateVideoFavorite;
};

export const useBookFavoriteMutation = (): ((args: {
  newFavoriteStatus: boolean;
  bookSakuhinCode: string;
  needsOptimisticResponse: boolean;
  onSuccess?: (newFavoriteStatus: boolean) => void;
}) => void) => {
  const intl = useIntl();
  const [updateBookFavoriteFailed, setUpdateBookFavoriteFailed] =
    useState(false);
  const { addToast, removeToast } = useToasts();
  const [updateBookFavorite] = useMutation<
    cosmo_updateBookFavoriteMutation,
    cosmo_updateBookFavoriteMutationVariables
  >(UPDATE_BOOK_FAVORITE, {
    onCompleted: () => setUpdateBookFavoriteFailed(false),
    onError: () => setUpdateBookFavoriteFailed(true),
    refetchQueries: [GET_FAVORITES_BOOK],
  });

  const updateBookFavoriteToastId = useRef<string | null>(null);
  useEffect(() => {
    if (updateBookFavoriteToastId.current) {
      removeToast(updateBookFavoriteToastId.current);
      updateBookFavoriteToastId.current = null;
    }
    if (updateBookFavoriteFailed) {
      addToast(
        intl.formatMessage(errorMessages.mylistUpdateToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          updateBookFavoriteToastId.current = id;
        }
      );
    }
  }, [
    intl,
    addToast,
    removeToast,
    updateBookFavoriteToastId,
    updateBookFavoriteFailed,
  ]);

  const mutateBookFavorite = useCallback(
    ({
      newFavoriteStatus,
      bookSakuhinCode,
      needsOptimisticResponse,
      onSuccess,
    }: {
      newFavoriteStatus: boolean;
      bookSakuhinCode: string;
      needsOptimisticResponse: boolean;
      onSuccess?: (newFavoriteStatus: boolean) => void;
    }) => {
      updateBookFavorite({
        variables: {
          bookSakuhinId: bookSakuhinCode,
          type: newFavoriteStatus ? FavoriteTypes.ADD : FavoriteTypes.REMOVE,
        },
        optimisticResponse: needsOptimisticResponse
          ? {
              updateBookTitleFavorite: {
                __typename: 'BookSakuhin',
                code: bookSakuhinCode,
                isFavorite: newFavoriteStatus,
              },
            }
          : undefined,
      }).then((response) => {
        if (response.data) {
          if (typeof onSuccess === 'function') {
            onSuccess(!!response.data.updateBookTitleFavorite?.isFavorite);
          }
        }
      });
    },
    [updateBookFavorite]
  );

  return mutateBookFavorite;
};
