import { gql } from '@apollo/client';

// Fragment used for live cards
export const liveCardFragment = gql`
  fragment LiveCard on Live {
    id
    name
    deliveryStartDateTime
    deliveryEndDateTime
    performanceStartDateTime
    isOnlyOn
    saleTypeCode
    allowsTimeshiftedPlayback
    subContentList {
      typeCode
      publicStartDateTime
      publicEndDateTime
    }
    tickets {
      price
    }
    notices {
      publicStartDateTime
      thumbnail {
        standard
      }
    }
    hasPackRights
    productLineupCodeList
    paymentBadgeList {
      code
    }
  }
`;

// Fragment used for live blocks
export const liveBlockFragment = gql`
  fragment LiveBlock on LiveBlock {
    code
    name
    structureType
    trackingCode
    list {
      code
      name
      total
      liveList {
        ...LiveCard
      }
    }
  }
  ${liveCardFragment}
`;
