import React from 'react';
import { useIntl } from 'react-intl';
import { cosmo_getLiveDetailQuery } from '../../../__generated__/globalTypes';
import {
  FlexColumns,
  InfoContent,
  InfoHeading,
  InfoSection,
  InfoText,
} from '../shared/InfoSection';
import messages from '../Video/messages';

interface LiveAttractionSectionProps {
  liveDetail: cosmo_getLiveDetailQuery['webfront_getLive'];
}

const LiveAttractionSection: React.FC<LiveAttractionSectionProps> = ({
  liveDetail,
}) => {
  const intl = useIntl();

  return liveDetail.attractions || liveDetail.venue ? (
    <InfoSection>
      <FlexColumns>
        {liveDetail.attractions && (
          <InfoContent>
            <InfoHeading>
              {intl.formatMessage(messages.attractionHeaderText)}
            </InfoHeading>
            <InfoText>{liveDetail.attractions}</InfoText>
          </InfoContent>
        )}
        {liveDetail.venue && (
          <InfoContent>
            <InfoHeading>
              {intl.formatMessage(messages.liveVenueHeader)}
            </InfoHeading>
            <InfoText>{liveDetail.venue}</InfoText>
          </InfoContent>
        )}
      </FlexColumns>
    </InfoSection>
  ) : null;
};

export default LiveAttractionSection;
