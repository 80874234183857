import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import Icon, { Icons } from '../../../../shared/components/Common/Icon';
import { UrlQueryParams } from '../../../../shared/constants';
import { DEVICE } from '../../../../shared/styles';
import { COLORS } from '../../../../shared/styles/theme';
import type { VideoTitleDetailCastListNameLog } from '../../../Log/__types__/videoTitleDetail-castList-name';
import { CAST_LIST_CASE_NAME as VIDEO_CAST_LIST_CASE_NAME } from '../../../Log/ComponentName/videoTitleDetail';
import { getKafkaClient } from '../../../Log/kafkaClient';

const CharacterName = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  color: ${COLORS.white_75};

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
    font-weight: 600;
  }
`;

const PersonName = styled.div`
  font-size: 14px;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

const CreditLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 8px 0 16px;
  border-radius: 8px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
  &:hover,
  &:focus {
    background: ${COLORS.white_10};
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
  width: 24px;
`;

export interface CreditProps {
  personCode: string;
  personName: string;
  personNameCode?: string;
  characterName?: string;
  linkHref?: string;
  linkAs?: string;
  componentName?: string;
}

interface ComponentProps extends CreditProps {
  mainGenreId?: string;
  index: number;
  sakuhinCode?: string;
}

interface CastListItemProps extends ComponentProps {
  personNameCode: string;
}

interface PublisherCastListItemProps extends ComponentProps {
  linkHref: string;
  linkAs: string;
}

const CastListItem: React.FC<
  CastListItemProps | PublisherCastListItemProps
> = ({
  personCode,
  personName,
  personNameCode,
  characterName,
  linkHref,
  linkAs,
  mainGenreId,
  componentName,
  index,
  sakuhinCode,
}) => {
  return (
    <Link
      key={`${personCode}_${personNameCode}`}
      href={{
        pathname: linkHref || '/browse/credit/[personCode]/[personNameCode]',
        query: {
          ...(mainGenreId && {
            [UrlQueryParams.MAIN_GENRE_ID]: mainGenreId,
          }),
        },
      }}
      as={{
        pathname: linkAs || `/browse/credit/${personCode}/${personNameCode}`,
        query: {
          ...(mainGenreId && {
            [UrlQueryParams.MAIN_GENRE_ID]: mainGenreId,
          }),
        },
      }}
      passHref
      legacyBehavior
    >
      <CreditLink
        data-ucn="castListItem-link"
        onClick={() => {
          if (componentName === VIDEO_CAST_LIST_CASE_NAME) {
            getKafkaClient().trackUserClickDimension1<VideoTitleDetailCastListNameLog>(
              componentName,
              {
                index,
                name_code: personNameCode ?? '',
                person_code: personCode ?? '',
                sakuhin_code: sakuhinCode ?? '',
              }
            );
          }
        }}
      >
        <div>
          {characterName && <CharacterName>{characterName}</CharacterName>}
          <PersonName>{personName}</PersonName>
        </div>
        <StyledIcon name={Icons.HALF_ARROW_RIGHT} />
      </CreditLink>
    </Link>
  );
};

export default CastListItem;
