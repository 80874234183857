import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // pointback
  pointBackPercentage: {
    id: 'purchase.pointBackPercentage',
    defaultMessage: '{percentage}%ポイントバック',
  },
  pointBackDescription: {
    id: 'purchase.pointBackDescription',
    defaultMessage:
      'ポイントで足りない分をご登録の決済で購入すると、決済分の{percentage}%が{validityDays}日後にポイントで還元されます。',
  },
  noPointBack: {
    id: 'purchase.noPointBack',
    defaultMessage: 'ポイントバックの対象外です。',
  },
  creditCardPaymentPromotion: {
    id: 'purchase.creditCardPaymentPromotion',
    defaultMessage:
      'お支払い方法をクレジットカードに変更するとさらにお得な{percentage}%ポイントバックプログラムが適用されます。',
  },
  videoSubscriptionPromotion: {
    id: 'purchase.videoSubscriptionPromotion',
    defaultMessage:
      '有効な月額プラン会員になると最大{percentage}%のポイントバックプログラムが適用されます。',
  },
  pointBackTitle: {
    id: 'purchase.pointBackTitle',
    defaultMessage: 'ポイントバックについて',
  },
  pointBackAddCreditCard: {
    id: 'purchase.pointBackAddCreditCard',
    defaultMessage: 'お支払い方法を変更',
  },
  pointBackNewSubscription: {
    id: 'purchase.pointBackNewSubscription',
    defaultMessage: '月額プラン登録',
  },
  details: {
    id: 'purchase.details',
    defaultMessage:
      '※詳しいご利用規約は<linkText>こちら</linkText>からご確認ください。',
  },
  hasRead: {
    id: 'purchase.notice.hasRead',
    defaultMessage: 'このエピソードは閲覧したことがあります。',
  },
  waitingTicketRecovery: {
    id: 'purchase.notice.waitingTicketRecovery',
    defaultMessage: '現在は毎日無料の回復待ちです。',
  },

  // payment
  now: {
    id: 'purchase.now',
    defaultMessage: '現在: ',
  },
  afterUse: {
    id: 'purchase.afterUse',
    defaultMessage: '利用後: ',
  },
  priceHint: {
    id: 'purchase.priceHint',
    defaultMessage: '料金: ',
  },
  currentPoint: {
    id: 'purchase.currentPoint',
    defaultMessage: '{points} ポイント',
  },
  price: {
    id: 'purchase.price',
    defaultMessage: '{price}<span>円</span>',
  },
  monthlyPayment: {
    id: 'purchase.monthlyPayment',
    defaultMessage: '月額',
  },
  agreeToTerms: {
    id: 'purchase.agreeToTerms',
    defaultMessage: '規約に同意し',
  },
  taxIncluded: {
    id: 'purchase.taxIncluded',
    defaultMessage: '（税込）',
  },
  conditions: {
    id: 'purchase.conditions',
    defaultMessage:
      '※商品の決済時、および{validityDays}日後に有効な月額プラン会員であることがポイントバックの条件です。',
  },
  purchasePrice: {
    id: 'purchase.purchasePrice',
    defaultMessage: '{price} 円で',
  },
  purchaseFree: {
    id: 'purchase.purchaseFree',
    defaultMessage: '無料で',
  },
  purchase: {
    id: 'purchase.purchase',
    defaultMessage: '購入',
  },
  rental: {
    id: 'purchase.rental',
    defaultMessage: 'レンタル',
  },
  subscribe: {
    id: 'purchase.subscribe',
    defaultMessage: 'パック登録に進む',
  },
  modalTitle: {
    id: 'purchase.modalTitle',
    defaultMessage: 'ご注意',
  },
  priceLabel: {
    id: 'purchase.priceLabel',
    defaultMessage: '価格',
  },
  viewerPoint: {
    id: 'purchase.point',
    defaultMessage: '{point}<span>ポイント</span>',
  },
  pointLabel: {
    id: 'purchase.pointLabel',
    defaultMessage: '利用ポイント',
  },
  pointBackInfo: {
    id: 'purchase.pointBackInfo',
    defaultMessage: '{day}日後 {percentage}%還元',
  },
  purchaseInfo: {
    id: 'purchase.purchaseInfo',
    defaultMessage:
      '※支払方法、サービス提供時期、及び撤回・解除については<ma>こちら</ma>を、支払時期は<ta>こちら</ta>をご確認下さい。',
  },
  autoRenewal: {
    id: 'purchase.autoRenewal',
    defaultMessage: '自動更新',
  },

  // video
  rentalFootnote: {
    id: 'play.purchase.rentalFootnote',
    defaultMessage:
      '※ 「レンタル」とは、NHKオンデマンド利用規約における単品購入となります。',
  },
  termsSuffix: {
    id: 'play.purchase.termsSuffix',
    defaultMessage: '（利用規約）',
  },
  saleEndDate: {
    id: 'play.purchase.saleEndDate',
    defaultMessage: 'SALE {endDate}まで',
  },

  // live
  liveStart: {
    id: 'play.purchase.liveStart',
    defaultMessage: '{startDateTime}配信分',
  },
  livePurchaseStart: {
    id: 'play.purchase.livePurchaseStart',
    defaultMessage: '{purchaseStartDateTime}〜販売開始',
  },
  livePurchasePeriodOverError: {
    id: 'play.purchase.error.livePeriodOver',
    defaultMessage: 'このライブは販売終了しました。',
  },

  // book
  bookAvailableUntil: {
    id: 'viewer.purchase.bookAvailableUntil',
    defaultMessage: '{dateTime} まで閲覧可',
  },
  deviceSupportNotice: {
    id: 'viewer.purchase.deviceSupportNotice',
    defaultMessage:
      'この作品はWebサイトでの再生に対応していません。U-NEXTアプリ（iOS・Android・FireOS）でお楽しみいただけます。',
  },
  bulkPurchase: {
    id: 'viewer.purchase.bulkPurchase',
    defaultMessage: '選択して購入',
  },
  totalBookNumber: {
    id: 'viewer.purchase.totalBookNumber',
    defaultMessage: '全{totalNumber}冊',
  },
  bulkPurchaseAlert: {
    id: 'viewer.purchase.bulkPurchaseAlert',
    defaultMessage:
      '選択された{bulkItemCount}冊を<point>ポイントを利用して</point>{price}円で購入しますか？',
  },
  selectAll: {
    id: 'viewer.purchase.selectAll',
    defaultMessage: 'すべてを選択 (全{totalNumber}冊)',
  },
  cancelAll: {
    id: 'viewer.purchase.cancelAll',
    defaultMessage: '選択を解除',
  },
  selectingStatus: {
    id: 'viewer.purchase.selectingStatus',
    defaultMessage: '{selectedNumber}冊選択中',
  },
  selected: {
    id: 'viewer.purchase.selected',
    defaultMessage: '選択済み',
  },
  hasRights: {
    id: 'viewer.purchase.hasRights',
    defaultMessage: '閲覧権利あり',
  },

  // error
  pointsNotEnough: {
    id: 'purchase.error.pointsNotEnough',
    defaultMessage: 'ポイント不足',
  },
  updateCreditCard: {
    id: 'purchase.error.updateCreditCard',
    defaultMessage: 'クレジットカード情報を登録・変更',
  },
  pointCharge: {
    id: 'purchase.error.pointCharge',
    defaultMessage: 'ポイントをチャージする',
  },
  purchaseError: {
    id: 'purchase.error.purchaseError',
    defaultMessage: '決済エラー',
  },
  yamadaPointHint: {
    id: 'purchase.error.yamadaPointHint',
    defaultMessage: 'ヤマダポイントも利用しますか？',
  },
  yamadaPoint: {
    id: 'purchase.error.yamadaPoint',
    defaultMessage: 'ヤマダポイント',
  },
  useYamadaPoint: {
    id: 'purchase.error.useYamadaPoint',
    defaultMessage: 'ポイント利用で{price}円',
  },
  use: {
    id: 'purchase.error.use',
    defaultMessage: '利用する',
  },
  notUse: {
    id: 'purchase.error.notUse',
    defaultMessage: '利用しない',
  },
});

export default messages;
