import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  fromPromise,
  gql,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { ApolloLinks, ITokenHolder } from '@u-next/unextjs-oauth';
import { SentryLink } from 'apollo-link-sentry';
import ApolloLinkTimeout from 'apollo-link-timeout';
import merge from 'just-merge';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import getConfig from 'next/config';
import { parseCookies } from 'nookies';
import generatedIntrospection from '../__generated__/fragmentTypes';
import {
  getIsAeonQuery,
  getParentalLockQuery,
} from '../__generated__/globalTypes';
import { GlobalConfig } from '../shared/constants';
import { getCookieDomain } from '../utils';
import TokenHolder from '../utils/tokenHolder';
import {
  hashBasedPaginationTypePolicy,
  paginationTypePolicy,
} from './paginationTypePolicy';

const { serverRuntimeConfig } = getConfig();

const GET_PARENTAL_LOCK = gql`
  query getParentalLock {
    parentalLock @client
  }
`;

// Local Type Defs
const typeDefs = gql`
  extend type UserInfo {
    parentalLock: Boolean!
    isAeon: Boolean!
  }
  extend type Query {
    parentalLock: Boolean!
    isAeon: Boolean!
  }
`;

let apolloClient: ApolloClient<NormalizedCacheObject>;

const APOLLO_LINK_TIMEOUT_MS_SERVER = 10000;
const APOLLO_LINK_TIMEOUT_MS_CLIENT = 30000;

const PAGE_FLOODGATE = '_flog';
let requestNotBefore: Date | null = null;
const now = Date.now();

if (typeof document !== 'undefined' && window.__REMOTE_CONFIG__) {
  const simpleCheckSum = (values: number[]) =>
    values.reduce(
      (prevVal, currentValue) =>
        (prevVal * (2 ** 7 - 1) + (currentValue ?? 0)) & 0xffffff
    );
  const remoteConfig = window.__REMOTE_CONFIG__;
  const parseFloodGateThreshold = () => {
    const cookies = document.cookie.split('; ');
    const flog = cookies.find((cookie) =>
      cookie.startsWith(`${PAGE_FLOODGATE}=`)
    );
    if (flog) {
      const m = flog.match(/=(\d+).(\d+).(\d+)/);
      if (m) {
        const budget = Number(m[1]);
        const notBefore = Number(m[2]);
        const checkSum = Number(m[3]);
        if (!isNaN(budget) && !isNaN(notBefore)) {
          return [budget, notBefore, checkSum];
        }
      }
    }
    return [null, null];
  };

  const [budget, notBefore, checkSum] = parseFloodGateThreshold();
  if (remoteConfig.floodGateControl && remoteConfig.floodGateControl.enable) {
    const { reloadBudget, reloadInterval, requestDelay } =
      remoteConfig.floodGateControl;
    const configCheckSum = simpleCheckSum([
      reloadInterval,
      requestDelay,
      reloadBudget,
    ]);
    if (budget !== null && notBefore !== null && checkSum === configCheckSum) {
      if (now < notBefore) {
        if (budget <= 0) {
          requestNotBefore = new Date(now + requestDelay);
        } else {
          document.cookie = `${PAGE_FLOODGATE}=${
            budget - 1
          }.${notBefore}.${configCheckSum};path=/`;
        }
      } else {
        document.cookie = `${PAGE_FLOODGATE}=;path=/`;
      }
    } else {
      // ignores previous cookie values if remote config changed
      document.cookie = `${PAGE_FLOODGATE}=${reloadBudget}.${
        now + reloadInterval
      }.${configCheckSum};path=/`;
    }
  } else if (budget !== null) {
    // cleanup after feature got switched off via remote config
    document.cookie = `${PAGE_FLOODGATE}=;path=/`;
  }
}

function createApolloClient({
  ctx,
  tokenHolder,
  isBot,
}: {
  ctx?: GetServerSidePropsContext | NextPageContext;
  tokenHolder?: ITokenHolder;
  isBot?: boolean;
}) {
  const isBrowser = typeof window !== 'undefined';
  const userAgent = ctx?.req?.headers['user-agent'];

  // If we are on the server we use the NEXUS_SERVER environment variable
  // for all requests
  const apiEndpoint = !isBrowser
    ? serverRuntimeConfig.NEXUS_SERVER
    : GlobalConfig.URLS.NEXUS;

  const cookies =
    !isBrowser && ctx?.req ? parseCookies({ req: ctx.req }) : undefined;
  const accessTokenHolder = tokenHolder ?? new TokenHolder();
  if (cookies?._at) {
    accessTokenHolder.set(cookies._at);
  }

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser && isBot,
    // skip force-fetching "network-only & cache-and-network" queries during initialization on SSR
    // reference: https://www.apollographql.com/docs/react/performance/server-side-rendering/#overriding-fetch-policies-during-initialization
    ssrForceFetchDelay: isBot ? 100 : 0,
    name: 'cosmo',
    version: process.env.IMAGE_TAG,
    link: from([
      ApolloLinks.createRetryLink({
        delay: {
          initial: 800,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 3,
          retryIf: (error, _operation) => {
            if (
              !!error &&
              !_operation.query.definitions.some(
                (node) =>
                  node.kind === 'OperationDefinition' &&
                  node.operation === 'mutation'
              )
            ) {
              return true;
            }
            return false;
          },
        },
      }),
      onError(({ operation, graphQLErrors, networkError }) => {
        Sentry.withScope((scope) => {
          scope.setTransactionName(operation.operationName);
          scope.setContext('apolloGraphQLOperation', {
            operationName: operation.operationName,
            variables: operation.variables,
            extensions: operation.extensions,
          });

          if (Array.isArray(graphQLErrors)) {
            graphQLErrors.forEach((error) => {
              Sentry.captureMessage(error.message, {
                level: 'error',
                fingerprint: ['{{ default }}', '{{ transaction }}'],
                contexts: {
                  apolloGraphQLError: {
                    error,
                    message: error.message,
                    extensions: error.extensions,
                  },
                },
              });
            });
          }

          if (networkError) {
            Sentry.captureMessage(networkError.message, {
              level: 'error',
              contexts: {
                apolloNetworkError: {
                  error: networkError,
                },
              },
            });
          }
        });
      }),
      onError(
        ApolloLinks.createErrorLinkWithOAuthRefresh(
          isBrowser,
          accessTokenHolder,
          !isBrowser
            ? {
                baseUrl: GlobalConfig.URLS.OAUTH,
                clientId: 'unext',
                clientSecret: process.env.OAUTH2_CLIENT_SECRET ?? '',
                cookieDomain: getCookieDomain(
                  new URL(GlobalConfig.URLS.HOST).hostname
                ),
                cookiePath: '/',
                cookieTTLSeconds: GlobalConfig.OAUTH_COOKIE_TTL,
                refreshToken: cookies?._rt,
              }
            : undefined,
          ctx?.res
        )
      ),
      ApolloLinks.createAuthLink(isBrowser, accessTokenHolder),
      new SentryLink({
        setTransaction: false,
        setFingerprint: false,
        attachBreadcrumbs: { includeVariables: true, includeError: true },
      }),
      new ApolloLinkTimeout(
        isBrowser
          ? APOLLO_LINK_TIMEOUT_MS_CLIENT
          : APOLLO_LINK_TIMEOUT_MS_SERVER
      ),
      new ApolloLink((operation, forward) => {
        // Get server date from response headers
        return forward(operation).map((response) => {
          if (typeof window !== 'undefined') {
            const context = operation.getContext();
            const serverDate = context.response.headers.get('Date');
            window.CLIENT_DATE_DELAY = serverDate
              ? new Date(serverDate).getTime() - Date.now()
              : 0;
          }
          return response;
        });
      }),
      new ApolloLink((operation, forward) => {
        if (!requestNotBefore) {
          return forward(operation);
        }
        // insert delay before HttpLink sends out the request
        return fromPromise(
          new Promise<void>((resolve) => {
            const now = Date.now();
            if (requestNotBefore && requestNotBefore.getTime() > now) {
              setTimeout(() => resolve(), requestNotBefore.getTime() - now);
            } else {
              resolve();
            }
          })
        ).flatMap(() => forward(operation));
      }),
      new HttpLink({
        uri: apiEndpoint,
        headers: userAgent
          ? {
              'user-agent': userAgent,
            }
          : {},
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: {
        Thumbnail: { merge: true },
        TitleRelationGroup: { keyFields: false },
        Feature: { keyFields: false },
        BookSakuhin: {
          fields: {
            books: paginationTypePolicy({
              keyArgs: ['bookSakuhinCode', 'pageSize'],
            }),
          },
        },
        Query: {
          fields: {
            webfront_searchVideo: paginationTypePolicy({
              keyArgs: [
                'categoryCode',
                'sortOrder',
                'dubSubFilter',
                'filterSaleType',
                'sakuhinGroupCode',
                'personCode',
                'personNameCode',
                'mainGenreId',
                'pageSize',
              ],
            }),
            webfront_feature: paginationTypePolicy({
              keyArgs: ['id', 'genreId', 'pageSize'],
            }),
            webfront_recommendBlocks: paginationTypePolicy({
              keyArgs: ['genreId', 'sakuhinsPerFeature', 'pageSize'],
            }),
            videoRecommendBlocks: paginationTypePolicy({
              keyArgs: ['genreCode', 'numberOfContentPerBlock', 'pageSize'],
            }),
            titleBlockDetail: paginationTypePolicy({
              keyArgs: ['recommendBlockCode', 'pageSize'],
            }),
            webfront_title_relatedBooks: paginationTypePolicy({
              keyArgs: ['id', 'pageSize'],
            }),
            liveBlockDetail: paginationTypePolicy({
              keyArgs: ['liveBlockCode', 'genreCode', 'pageSize'],
            }),
            liveCategorySearch: paginationTypePolicy({
              keyArgs: ['menuCode', 'pageSize'],
            }),
            liveGroup: paginationTypePolicy({
              keyArgs: ['liveGroupCode', 'genreCode', 'pageSize'],
            }),
            liveRelatedVideos: paginationTypePolicy({
              keyArgs: ['id', 'pageSize'],
            }),
            liveRelatedLives: paginationTypePolicy({
              keyArgs: ['id', 'pageSize'],
            }),
            personLives: paginationTypePolicy({
              keyArgs: ['personCode', 'pageSize'],
            }),
            webfront_bookRecommendBlocks: paginationTypePolicy({
              keyArgs: ['genreId', 'booksPerBlock', 'pageSize'],
            }),
            webfront_bookMetaSearch: paginationTypePolicy({
              keyArgs: [
                'metaType',
                'code',
                'filterSaleType',
                'sortOrder',
                'pageSize',
              ],
            }),
            bookRanking: paginationTypePolicy({
              keyArgs: ['targetCode', 'tagCode', 'pageSize'],
            }),
            webfront_freeBooks: paginationTypePolicy({
              keyArgs: ['tagCode', 'pageSize'],
            }),
            webfront_newBooks: paginationTypePolicy({
              keyArgs: ['tagCode', 'pageSize'],
            }),
            originalBooks: paginationTypePolicy({
              keyArgs: ['tagCode', 'pageSize'],
            }),
            originalComic: paginationTypePolicy({
              keyArgs: ['tagCode', 'pageSize'],
            }),
            webfront_preorderableBooks: paginationTypePolicy({
              keyArgs: ['tagCode', 'pageSize'],
            }),
            webfront_bookCategory: paginationTypePolicy({
              keyArgs: [
                'menuCode',
                'filterSaleType',
                'excludeBookSakuhinCode',
                'sortOrder',
                'pageSize',
              ],
            }),
            webfront_bookFeatures: paginationTypePolicy({
              keyArgs: ['bookFeatureCode', 'tagCode', 'pageSize'],
            }),
            bookTitle_recommendedBooks: paginationTypePolicy({
              keyArgs: ['bookSakuhinCode', 'pageSize'],
            }),
            bookTitle_relatedTitles: paginationTypePolicy({
              keyArgs: ['code', 'pageSize'],
            }),
            bookTitle_relatedBooks: paginationTypePolicy({
              keyArgs: ['code', 'pageSize'],
            }),
            webfront_bookPerson: paginationTypePolicy({
              keyArgs: [
                'personCode',
                'personNameCode',
                'filterSaleType',
                'excludeBookSakuhinCode',
                'onlyChapter',
                'sortOrder',
                'pageSize',
              ],
            }),
            webfront_bookFreewordSearch: paginationTypePolicy({
              keyArgs: ['query', 'filterSaleType', 'sortOrder', 'pageSize'],
            }),
            webfront_personFreewordSearch: paginationTypePolicy({
              keyArgs: ['query', 'pageSize'],
            }),
            liveFreewordSearch: paginationTypePolicy({
              keyArgs: ['query', 'pageSize'],
            }),
            webfront_videoFreewordSearch: paginationTypePolicy({
              keyArgs: ['query', 'filterSaleType', 'sortOrder', 'pageSize'],
            }),
            webfront_favoriteBooks: hashBasedPaginationTypePolicy({
              keyArgs: [
                'volumeGroupType',
                'searchQuery',
                'sortOrder',
                'pageSize',
              ],
            }),
            bookHistory: hashBasedPaginationTypePolicy({
              keyArgs: ['searchWord', 'volumeGroupType', 'pageSize'],
            }),
            webfront_purchasedBooks: hashBasedPaginationTypePolicy({
              keyArgs: [
                'volumeGroupType',
                'searchQuery',
                'sortOrder',
                'pageSize',
              ],
            }),
            webfront_favoriteTitles: paginationTypePolicy({
              keyArgs: ['searchQuery', 'sortOrder', 'pageSize'],
            }),
            webfront_historyTitles: paginationTypePolicy({
              keyArgs: ['pageSize'],
            }),
            purchasedLives: paginationTypePolicy({
              keyArgs: ['pageSize'],
            }),
          },
        },
      },
      dataIdFromObject: (o) => {
        // If the object returned has a code field, use that as the cache key
        // TODO: remove it after featurePieceCode test-case confirmation
        // if (o.code) {
        //   return `${o.__typename}:${o.code}`;
        // }
        return defaultDataIdFromObject(o);
      },
    }),
    typeDefs,
    resolvers: {
      UserInfo: {
        parentalLock: (user, _args, { cache }) => {
          const data: getParentalLockQuery = cache.readQuery({
            query: GET_PARENTAL_LOCK,
          });
          const age: number | null | undefined = user?.age;
          return ((age != null && age < 18) || data?.parentalLock) ?? false;
        },
        isAeon: (_user, _args, { cache }) => {
          const data: getIsAeonQuery = cache.readQuery({
            query: gql`
              query getIsAeon {
                isAeon @client
              }
            `,
          });
          return data?.isAeon ?? false;
        },
      },
    },
  });
}

export function initializeApollo({
  initialState,
  ctx,
  tokenHolder,
  isBot,
}: {
  initialState?: NormalizedCacheObject;
  ctx?: GetServerSidePropsContext | NextPageContext;
  tokenHolder?: ITokenHolder;
  isBot?: boolean;
}): ApolloClient<NormalizedCacheObject> {
  const _apolloClient =
    apolloClient ?? createApolloClient({ ctx, tokenHolder, isBot });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache) as typeof initialState &
      typeof existingCache;

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  if (!apolloClient) {
    // Write parental lock and isAeon to local cache at top level
    // Client side resolvers defined in initApollo.ts will query these fields
    // to add them to the userInfo object
    // We put this here because it must run before the leanback query is ran
    if (typeof window === 'undefined') {
      const cookies = parseCookies(ctx);
      const parentalLock = cookies._frc === 'R15';
      const isAeon =
        !!ctx?.req?.headers?.host?.match(/^aeoncinema-video/) ||
        !!cookies._aeon;
      _apolloClient.cache.writeQuery({
        query: GET_PARENTAL_LOCK,
        data: {
          parentalLock,
          isAeon,
        },
      });
    }
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
