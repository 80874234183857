import { useCallback } from 'react';
import { GlobalConfig } from '../constants';
import useHostInfo from './useHostInfo';
/**
 * useLoginUrl
 * @returns active hostname from GlobalConfig
 */
const getLoginUrlUtil = (
  backUrl: string,
  hostname: string,
  isAeon: boolean
): string => {
  if (GlobalConfig.USE_OAUTH2_LOGIN) {
    const nonce = Math.floor(Math.random() * (2 ** 31 - 1));
    const encodedOAuth2LoginCallbackUrl = encodeURIComponent(
      `${hostname}/login_callback`
    );
    const encodedState = encodeURIComponent(
      `${isAeon ? 'aeon' : ''}backUrl_${backUrl}`
    );
    return `${GlobalConfig.URLS.OAUTH}/oauth2/auth?client_id=${GlobalConfig.OAUTH_CLIENT_ID}&redirect_uri=${encodedOAuth2LoginCallbackUrl}&response_type=code&scope=unext&state=${encodedState}&nonce=${nonce}`;
  } else {
    const encodedLoginCallbackUrl = encodeURIComponent(`${hostname}${backUrl}`);
    return `${GlobalConfig.URLS.LOGIN}?&backurl=${encodedLoginCallbackUrl}`;
  }
};

const useLoginUrl = (): {
  getLoginUrl: (backUrl?: string) => string;
  hostname: string;
} => {
  const { hostname, isAeon } = useHostInfo();

  const getLoginUrl = useCallback(
    (backUrl = '/') => getLoginUrlUtil(backUrl, hostname, isAeon),
    [hostname, isAeon]
  );

  return { getLoginUrl, hostname };
};

export default useLoginUrl;
