import React from 'react';
import styled from 'styled-components';
import LegacyIcon, { LegacyIcons } from '../Icon/LegacyIcon';

const HoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in;
`;

const SideButtonContainer = styled.div`
  height: 16px;
  position: relative;

  &:hover {
    ${HoverContainer} {
      opacity: 1;
    }
  }
`;

interface SideButtonProps {
  className?: string;
  // TODO: clarify the type
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;
}

const SideButton: React.FC<SideButtonProps> = ({ className, style }) => {
  return (
    <SideButtonContainer className={className} style={style}>
      <LegacyIcon name={LegacyIcons.BUTTON_SIMPLE} />
      <HoverContainer>
        <LegacyIcon name={LegacyIcons.BUTTON_SIMPLE_HOVER} />
      </HoverContainer>
    </SideButtonContainer>
  );
};

export default SideButton;
