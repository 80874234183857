import { gql } from '@apollo/client';
import { bookTitleCardFragment } from '../../shared/fragments/books';
import { liveCardFragment } from '../../shared/fragments/lives';
import {
  pageInfoFragment,
  pageInfoHashFragment,
} from '../../shared/fragments/pageInfo';
import { titleCardFragment } from '../../shared/fragments/videos';

export const GET_FAVORITES = gql`
  query cosmo_getFavoriteTitles(
    $page: Int
    $pageSize: Int
    $searchQuery: String
    $sortOrder: PortalSortOrder!
  ) {
    favoriteTitles: webfront_favoriteTitles(
      page: $page
      pageSize: $pageSize
      searchQuery: $searchQuery
      sortOrder: $sortOrder
    ) {
      pageInfo {
        ...PageInfo
      }
      titles {
        ...TitleCard
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
`;

export const GET_HISTORY_VIDEO = gql`
  query cosmo_historyTitles($page: Int, $pageSize: Int) {
    historyTitles: webfront_historyTitles(page: $page, pageSize: $pageSize) {
      titles {
        ...TitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
`;

export const GET_PURCHASED_VIDEO = gql`
  query cosmo_purchasedTitles(
    $searchQuery: String
    $sortOrder: PortalSortOrder!
  ) {
    purchasedTitles: webfront_purchasedTitles(
      searchQuery: $searchQuery
      sortOrder: $sortOrder
    ) {
      titles {
        ...TitleCard
        closestExpiryDateText
        boughtEpisodeNumberTextList
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
`;

export const REMOVE_VIDEO_FAVORITE = gql`
  mutation cosmo_removeTitleFavorites($id: ID!) {
    updateTitleFavorite: webfront_updateTitleStageFavorite(
      id: $id
      type: REMOVE
    ) {
      id
    }
  }
`;

export const GET_PURCHASED_LIVE = gql`
  query cosmo_purchasedLiveList($page: Int, $pageSize: Int) {
    purchasedLives(page: $page, pageSize: $pageSize) {
      liveList {
        ...LiveCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${liveCardFragment}
`;

export const GET_FAVORITES_BOOK = gql`
  query cosmo_bookFavorites(
    $nextPageHash: ID
    $pageSize: Int
    $searchQuery: String
    $sortOrder: PortalSortOrder!
    $volumeGroupType: BookVolumeGroupTypeCode
  ) {
    favoriteBooks: webfront_favoriteBooks(
      nextPageHash: $nextPageHash
      pageSize: $pageSize
      searchQuery: $searchQuery
      sortOrder: $sortOrder
      volumeGroupType: $volumeGroupType
    ) {
      pageInfoHash {
        ...PageInfoHash
      }
      books {
        ...BookTitleCard
      }
    }
  }
  ${pageInfoHashFragment}
  ${bookTitleCardFragment}
`;

export const REMOVE_BOOK_FAVORITES = gql`
  mutation cosmo_removeBookFavorites($ids: [ID!]!) {
    bulkRemoveBookTitleFavorite(bookSakuhinIds: $ids)
  }
`;

export const GET_HISTORY_BOOK = gql`
  query cosmo_bookHistory(
    $nextPageHash: ID
    $pageSize: Int
    $searchWord: String
    $volumeGroupType: BookVolumeGroupTypeCode
  ) {
    bookHistory(
      nextPageHash: $nextPageHash
      pageSize: $pageSize
      searchWord: $searchWord
      volumeGroupType: $volumeGroupType
    ) {
      pageInfoHash {
        ...PageInfoHash
      }
      books {
        ...BookTitleCard
      }
    }
  }
  ${pageInfoHashFragment}
  ${bookTitleCardFragment}
`;

export const GET_PURCHASED_BOOK = gql`
  query cosmo_bookPurchased(
    $nextPageHash: ID
    $pageSize: Int
    $searchQuery: String
    $sortOrder: PortalSortOrder!
    $volumeGroupType: BookVolumeGroupTypeCode
  ) {
    bookPurchased: webfront_purchasedBooks(
      nextPageHash: $nextPageHash
      pageSize: $pageSize
      searchQuery: $searchQuery
      sortOrder: $sortOrder
      volumeGroupType: $volumeGroupType
    ) {
      books {
        purchasedBooks {
          bookCode
        }
        ...BookTitleCard
      }
      pageInfoHash {
        ...PageInfoHash
      }
    }
  }
  ${pageInfoHashFragment}
  ${bookTitleCardFragment}
`;

export const REMOVE_VIDEO_HISTORY = gql`
  mutation cosmo_removeVideoHistory($id: ID!) {
    removeVideoHistory(id: $id) {
      removed
    }
  }
`;

export const REMOVE_VIDEO_HISTORY_ALL = gql`
  mutation cosmo_removeAllVideoHistory {
    removeAllVideoHistory {
      removed
    }
  }
`;

export const REMOVE_BOOK_HISTORY = gql`
  mutation cosmo_removeBookHistory($bookSakuhinCodes: [ID!]!) {
    removeBookHistory(bookSakuhinCodes: $bookSakuhinCodes) {
      bookSakuhinCodes
    }
  }
`;

export const REMOVE_ALL_BOOK_HISTORY = gql`
  mutation cosmo_removeAllBookHistory {
    removeAllBookHistory {
      removedAll
    }
  }
`;

export const REMOVE_BOOK_PURCHASE = gql`
  mutation cosmo_removeBookPurchase($bookSakuhinCodes: [ID!]!) {
    removeBookPurchase(bookSakuhinCodes: $bookSakuhinCodes) {
      bookSakuhinCodes
    }
  }
`;
