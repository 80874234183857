export function maskToken(token?: string) {
  if (!token) return '';

  const xorMask = new TextEncoder()
    .encode(process.env.TOKEN_MASK_64_BIT)
    .slice(0, 8);

  const maskedBuffer = new TextEncoder().encode(token);

  for (let i = 0; i < maskedBuffer.length; i += xorMask.length) {
    for (
      let j = i;
      j < Math.min(maskedBuffer.length, i + xorMask.length);
      j++
    ) {
      maskedBuffer[j] = maskedBuffer[j] ^ xorMask[j - i];
    }
  }

  return btoa(new TextDecoder().decode(maskedBuffer));
}
