import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { assert } from '../../../../utils/assert';
import { DEVICE } from '../../../styles';
import Icon, { Icons } from '../Icon';

export const buttonTheme = [
  'text',
  'light',
  'dark',
  'attention',
  'caution',
  'ghost',
  'delete',
  'deleteAll',
] as const;
export type ButtonTheme = (typeof buttonTheme)[number];
export const buttonSizes = ['large', 'medium', 'small'] as const;
export type ButtonSize = (typeof buttonSizes)[number];
type ButtonContent = 'IconOnly' | 'IconText' | 'TextOnly';

interface ButtonStyle {
  background?: string;
  text: string;
  textHover?: string;
  border?: string;
  borderHover?: string;
  backgroundHover?: string;
  textDisabled: string;
  borderDisabled?: string;
  backgroundDisabled?: string;
}

function getButtonPadding({
  buttonSize,
  buttonContent,
}: {
  buttonSize: ButtonSize;
  buttonContent: ButtonContent;
}) {
  switch (buttonContent) {
    case 'IconText':
      return '0 22px 0 18px';
    case 'IconOnly':
      switch (buttonSize) {
        case 'large':
          return '0 8px';
        case 'medium':
          return '0 6px';
        case 'small':
          return '0 4px';
      }
      break;
    case 'TextOnly':
      switch (buttonSize) {
        case 'large':
          return '0 32px';
        case 'medium':
          return '0 30px';
        case 'small':
          return '0 26px';
      }
  }
}

function getIconSize({
  buttonSize,
  buttonContent,
}: {
  buttonSize: ButtonSize;
  buttonContent: Exclude<ButtonContent, 'TextOnly'>;
}) {
  switch (buttonContent) {
    case 'IconText':
      switch (buttonSize) {
        case 'large':
          return '24px';
        case 'medium':
          return '20px';
        case 'small':
          return '20px';
      }
      break;
    case 'IconOnly':
      switch (buttonSize) {
        case 'large':
          return '32px';
        case 'medium':
          return '28px';
        case 'small':
          return '24px';
      }
  }
}

function getButtonStyle(
  bag: Record<ButtonTheme, ButtonStyle>,
  theme: ButtonTheme | undefined
): ButtonStyle {
  assert(!!theme);
  const p = bag[theme];
  return p;
}

type ContainerProps = {
  buttonTheme: ButtonTheme;
  buttonSize: ButtonSize;
  buttonSizeMobile?: ButtonSize;
  icon?: Icons;
  text?: string;
  subText?: string;
};
interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ContainerProps {}
interface ContainerDivProps
  extends HTMLAttributes<HTMLDivElement>,
    ContainerProps {}

const buttonStyle = css<ButtonProps | ContainerDivProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;

  border: 0;
  padding: ${({ buttonSize, icon, text }) =>
    getButtonPadding({
      buttonSize,
      buttonContent: icon && text ? 'IconText' : icon ? 'IconOnly' : 'TextOnly',
    })};
  ${({ buttonTheme }) => buttonTheme === 'text' && 'padding:0;'};
  height: ${({ buttonSize }) =>
    buttonSize === 'large'
      ? '48px'
      : buttonSize === 'medium'
      ? '40px'
      : '32px'};
  border-radius: 8px;
  font-size: ${({ buttonSize }) => (buttonSize === 'large' ? '16px' : '14px')};
  font-weight: 600;
  transition: all 0.15s ease-in;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  box-sizing: border-box;

  background-color: ${({ theme, buttonTheme }) =>
    getButtonStyle(theme.miraiButton, buttonTheme).background};
  color: ${({ theme, buttonTheme }) =>
    getButtonStyle(theme.miraiButton, buttonTheme).text};
  ${({ theme, buttonTheme }) => {
    const borderColor = getButtonStyle(theme.miraiButton, buttonTheme).border;
    if (borderColor) return `border: 0.75px solid ${borderColor}`;
  }};

  > div {
    /* Icon style */
    flex-shrink: 0;
    width: ${({ text, buttonSize }) =>
      getIconSize({
        buttonSize,
        buttonContent: text ? 'IconText' : 'IconOnly',
      })};
  }

  > span > span {
    /* Sub text style */
    margin-left: 2px;
    font-size: 12px;
    font-weight: 300;
  }

  &:hover {
    color: ${({ theme, buttonTheme }) =>
      getButtonStyle(theme.miraiButton, buttonTheme).textHover};
    background-color: ${({ theme, buttonTheme }) =>
      getButtonStyle(theme.miraiButton, buttonTheme).backgroundHover};
    ${({ theme, buttonTheme }) => {
      const borderColor = getButtonStyle(
        theme.miraiButton,
        buttonTheme
      ).borderHover;
      if (borderColor) return `border: 0.75px solid ${borderColor}`;
    }};
  }

  &:disabled {
    color: ${({ theme, buttonTheme }) =>
      getButtonStyle(theme.miraiButton, buttonTheme).textDisabled};
    background-color: ${({ theme, buttonTheme }) =>
      getButtonStyle(theme.miraiButton, buttonTheme).backgroundDisabled};
    ${({ theme, buttonTheme }) => {
      const borderColor = getButtonStyle(
        theme.miraiButton,
        buttonTheme
      ).borderDisabled;
      if (borderColor) return `border: 0.75px solid ${borderColor}`;
    }};
    cursor: not-allowed;
  }

  @media ${DEVICE.mobileWide} {
    padding: ${({ buttonSizeMobile, icon, text }) =>
      buttonSizeMobile &&
      getButtonPadding({
        buttonSize: buttonSizeMobile,
        buttonContent:
          icon && text ? 'IconText' : icon ? 'IconOnly' : 'TextOnly',
      })};
    height: ${({ buttonSizeMobile }) =>
      buttonSizeMobile === 'large'
        ? '48px'
        : buttonSizeMobile === 'medium'
        ? '40px'
        : buttonSizeMobile === 'small' && '32px'};
    border-radius: 8px;
    font-size: ${({ buttonSizeMobile }) =>
      buttonSizeMobile === 'large' ? '16px' : buttonSizeMobile && '14px'};

    > div {
      /* Icon style */
      width: ${({ text, buttonSizeMobile }) =>
        buttonSizeMobile &&
        getIconSize({
          buttonSize: buttonSizeMobile,
          buttonContent: text ? 'IconText' : 'IconOnly',
        })};
    }
  }
`;

const Button = styled.button<ButtonProps>`
  ${buttonStyle};
`;

const Container = styled.div<ContainerDivProps>`
  ${buttonStyle};
`;

const ButtonComponent: React.FC<ButtonProps> = (props) => (
  <Button {...props}>
    {props.icon && <Icon name={props.icon} />}
    <span>
      {props.text}
      {props.subText && <span>{props.subText}</span>}
    </span>
  </Button>
);

const DivComponent: React.FC<ContainerProps> = (props) => (
  <Container {...props}>
    {props.icon && <Icon name={props.icon} />}
    <span>
      {props.text}
      {props.subText && <span>{props.subText}</span>}
    </span>
  </Container>
);

export { ButtonComponent as Button, DivComponent as ButtonLookDiv };
