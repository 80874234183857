import { ITokenHolder } from '@u-next/unextjs-oauth';

export default class TokenHolder implements ITokenHolder {
  private tokenValue: string | undefined;
  set(newToken: string): void {
    this.tokenValue = newToken;
  }
  get(): string | undefined {
    return this.tokenValue;
  }
}
