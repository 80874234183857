import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import type { NextPageContext } from 'next';
import {
  GenreUrl,
  GlobalConfig,
  LabelCode,
  LabelUrl,
} from '../shared/constants';
import { Nullable } from './Nullable';

type WeekdayType =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | '';

type ShortWeekdayType =
  | 'sundayShort'
  | 'mondayShort'
  | 'tuesdayShort'
  | 'wednesdayShort'
  | 'thursdayShort'
  | 'fridayShort'
  | 'saturdayShort'
  | '';

/**
 * Maps API number for weekday to the actual key. These keys match values
 * that are used for localization.
 *
 * @param number Number between 0 and 6. Matches data returned from the API
 */
export const mapNumberToWeekdayKey = (
  number: Nullable<number>,
  options: { short?: boolean } = {}
): WeekdayType | ShortWeekdayType => {
  let weekdayKey: WeekdayType = '';
  const { short } = options;
  switch (number) {
    case 0:
      weekdayKey = 'sunday';
      break;
    case 1:
      weekdayKey = 'monday';
      break;
    case 2:
      weekdayKey = 'tuesday';
      break;
    case 3:
      weekdayKey = 'wednesday';
      break;
    case 4:
      weekdayKey = 'thursday';
      break;
    case 5:
      weekdayKey = 'friday';
      break;
    case 6:
      weekdayKey = 'saturday';
      break;
    default:
      return '';
  }
  if (short) {
    return (weekdayKey + 'Short') as ShortWeekdayType;
  }
  return weekdayKey;
};

/**
 * Takes a display code and returns the matching key from the GenreUrl constant
 *
 * @param displayCode Display code string passed through the URL usually
 */
export const getGenreDisplayCodeByName = (
  displayCode: string
): keyof typeof GenreUrl | null => {
  const key = Object.keys(GenreUrl).find(
    (k) => GenreUrl[k as keyof typeof GenreUrl] === displayCode
  ) as keyof typeof GenreUrl;
  if (!key) {
    return null;
  }

  return key;
};

/**
 * Takes a label key and returns the matching label code from the LabelUrl constant
 *
 * @param labelKey Label key string passed through the URL usually
 */
export const getLabelCodeByUrlKey = (labelKey: string): LabelCode | null => {
  const labelCode = Object.entries(LabelUrl).find(
    ([_, value]) => value === labelKey
  )?.[0] as LabelCode;
  if (!labelCode) {
    return null;
  }

  return labelCode;
};

export const extractGraphQLError = (
  targetMessage: string | string[],
  error?: ApolloError
): [string, GraphQLError, string] | [] => {
  if (!error) {
    return [];
  }

  const targetError = error?.graphQLErrors.filter((err) => {
    if (Array.isArray(targetMessage)) {
      return targetMessage.includes(error?.message);
    }
    return err?.message === targetMessage;
  });

  return [
    targetError?.[0]?.extensions?.errorCode,
    targetError?.[0],
    targetError?.[0]?.extensions?.errorMessage,
  ];
};

export const getCookieDomain = (host: string): string => {
  return host?.split('.').slice(-2).join('.');
};

// Use it to avoid triggering the event when a key different than 'Enter' is pressed.
export const handleOnlyOnEnter = (
  event: React.KeyboardEvent<Element>,
  handler?: (e: React.KeyboardEvent<Element>) => void
): void => {
  event.preventDefault();
  if (event.key === 'Enter' && handler) {
    handler(event);
  }
};

export const getHostname = (isAeon: boolean): string => {
  if (isAeon) {
    return GlobalConfig.URLS.AEON_HOST;
  }
  return GlobalConfig.URLS.HOST;
};

export const getScrollbarWidth = (): number => {
  if (typeof document !== 'undefined' && typeof window !== 'undefined') {
    const documentWidth = document.documentElement.clientWidth;
    const windowWidth = window.innerWidth;
    return windowWidth - documentWidth;
  }
  return 0;
};

type AsyncFunction = () => Promise<void>;

export const navigateAfterPromiseHandler = (
  callback: AsyncFunction | AsyncFunction[],
  href: string
): ((e: React.MouseEvent | React.KeyboardEvent) => Promise<void>) => {
  return async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    if (Array.isArray(callback)) {
      await Promise.all(callback.map((c) => c()));
    } else {
      await callback();
    }
    window.location.href = href;
  };
};

export const isBotRequest = (context?: {
  query?: NextPageContext['query'];
  req?: NextPageContext['req'];
}): boolean => {
  if (!context) return false;

  // debug mode
  if (context.query?.__DEBUG_BOT__ === 'true') return true;

  const userAgent = context.req?.headers['user-agent'] || '';
  if (!userAgent) return false;

  const BOT_USER_AGENT_LIST = [
    'facebookexternalhit',
    'facebot',
    'twitterbot',
    'phantomjs',
    'googlebot',
    'bingbot',
    'msnbot',
    'slurp',
    'duckduckbot',
    'slack',
  ];

  return !!BOT_USER_AGENT_LIST.find((bot) =>
    userAgent.match(new RegExp(bot, 'i'))
  );
};
