import { useToasts } from '@u-next/react-toast-notifications';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_bookTitleDetailQuery,
  cosmo_bookTitleDetailRelatedStuffsQuery,
  cosmo_bookTitleDetailRelatedStuffsQueryVariables,
} from '../../../__generated__/globalTypes';
import { errorMessages } from '../../../shared/constants/messages';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { Nullable } from '../../../utils/Nullable';
import * as bookTitleDetail from '../../Log/ComponentName/bookTitleDetail';
import RelatedSection from '../shared/RelatedWorkSection/RelatedSection';
import { GET_BOOK_RELATED_STUFFS } from './gql';
import messages from './messages';

interface BookRelatedSectionProps {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
}

const BookRelatedSection: React.FC<BookRelatedSectionProps> = ({
  bookSakuhin,
}) => {
  const intl = useIntl();

  const { data: relatedStuffsData, error: relatedStuffsError } = useClientQuery<
    cosmo_bookTitleDetailRelatedStuffsQuery,
    cosmo_bookTitleDetailRelatedStuffsQueryVariables
  >(GET_BOOK_RELATED_STUFFS, {
    variables: {
      bookSakuhinCode: bookSakuhin.sakuhinCode,
    },
  });

  const { addToast, removeToast } = useToasts();
  const relatedStuffsErrorToastId = useRef<Nullable<string>>(null);
  useEffect(() => {
    if (relatedStuffsErrorToastId.current) {
      removeToast(relatedStuffsErrorToastId.current);
      relatedStuffsErrorToastId.current = null;
    }

    if (relatedStuffsError) {
      addToast(
        intl.formatMessage(errorMessages.fetchErrorToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          relatedStuffsErrorToastId.current = id;
        }
      );
    }
  }, [
    addToast,
    removeToast,
    intl,
    relatedStuffsError,
    relatedStuffsErrorToastId,
  ]);

  const relatedBooks = relatedStuffsData?.bookTitle_relatedBooks;
  const totalRelatedBookCount = relatedBooks?.pageInfo?.results || 0;
  const hasRelatedBooks = totalRelatedBookCount > 0;

  const recommendedBooks = relatedStuffsData?.bookTitle_recommendedBooks;
  const totalRecommendedBookCount = recommendedBooks?.pageInfo?.results || 0;
  const hasRecommendedBooks = totalRecommendedBookCount > 0;

  const relatedTitles = relatedStuffsData?.bookTitle_relatedTitles;
  const totalTitleCount = relatedTitles?.pageInfo?.results || 0;
  const hasRelatedTitles = totalTitleCount > 0;

  return (
    <>
      {hasRelatedBooks && relatedBooks?.books && (
        <RelatedSection
          sectionName="relatedBook"
          sectionHeading={intl.formatMessage(messages.relatedBooksHeading)}
          contentType="book"
          items={relatedBooks?.books}
          totalItemCount={totalRelatedBookCount}
          itemComponentName={bookTitleDetail.RELATED_BOOK_TITLE_LIST_TITLE_CARD}
          showMoreLinkProps={{
            href: `/book/browse/relation/${bookSakuhin.sakuhinCode}/book`,
          }}
        />
      )}
      {hasRecommendedBooks && recommendedBooks?.books && (
        <RelatedSection
          sectionName="recommendedBook"
          sourceBookSakuhinCode={bookSakuhin.sakuhinCode}
          sourceBookCode={bookSakuhin.book?.code ?? ''}
          sectionHeading={intl.formatMessage(messages.recommendedBooksHeading)}
          contentType="book"
          items={recommendedBooks?.books}
          totalItemCount={totalRecommendedBookCount}
          itemComponentName={
            bookTitleDetail.RECOMMENDED_BOOK_TITLE_LIST_TITLE_CARD
          }
          showMoreLinkProps={{
            href: `/book/browse/recommend/${bookSakuhin.sakuhinCode}/book`,
          }}
          trackingBlockCode={recommendedBooks.trackingCode}
        />
      )}
      {hasRelatedTitles && relatedTitles?.titles && (
        <RelatedSection
          sectionName="relatedVideo"
          sectionHeading={intl.formatMessage(messages.relatedVideosHeading)}
          contentType="video"
          items={relatedTitles.titles}
          totalItemCount={totalTitleCount}
          itemComponentName={
            bookTitleDetail.RELATED_VIDEO_TITLE_LIST_TITLE_CARD
          }
          showMoreLinkProps={{
            href: `/book/browse/relation/${bookSakuhin.sakuhinCode}/video`,
          }}
        />
      )}
    </>
  );
};

export default BookRelatedSection;
