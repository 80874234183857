import { gql } from '@apollo/client';

export const GET_PLATFORM_INFO = gql`
  query cosmo_platformInfo {
    platformInfo {
      deviceType
      deviceName
      screenType
    }
  }
`;
