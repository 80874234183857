import React, { ButtonHTMLAttributes, FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { Icons } from '../../../shared/components/Common/Icon';
import { NavigationButton } from '../../../shared/components/Common/NavigationButton';
import Preorder from '../../../shared/components/Preorder';
import { PreorderContext } from '../../../shared/PreorderContext';
import { DEVICE, STYLE_BREAKPOINTS } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import type { BookTitleDetailStageReserveLog } from '../../Log/__types__/bookTitleDetail-stage-reserve';
import { STAGE_RESERVE } from '../../Log/ComponentName/bookTitleDetail';
import { getKafkaClient } from '../../Log/kafkaClient';
import { messages } from './messages';
import { PlayButton } from './StageSection';

export const SiteOverlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Can't just set 100vh because the weird calculation of vh on mobile browsers.
     Since our main target mobile browsers(iOS Safari & Android Chrome) are all webkit based,
     we could use -webkit-fill-available(the non-standard name of "stretch") for them.
     100vh is used as a fallback here for non-webkit browsers.
  */
  min-height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  z-index: 12000;
  overflow-y: auto;
  /** disable the bounce effect on Safari */
  overscroll-behavior: none;
`;

export const SiteOverlayContent = styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 16px 0;
  background-color: ${COLORS.gray_overlay_75};

  @media ${DEVICE.sd} {
    padding: 0;
  }
`;

export const TitleModalContent = styled(animated.div)`
  position: relative;
  z-index: 0;

  min-height: 600px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 48px;

  box-sizing: border-box;
  margin: 0 auto;
  max-width: ${STYLE_BREAKPOINTS.SD}px;
  padding: 72px 56px 200px;
  border-radius: 16px;
  border: 1px solid ${COLORS.white_10};
  background: ${COLORS.midnight};

  @media ${DEVICE.sd} {
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 64px 24px 200px;
    border-radius: unset;
    border: none;
  }
  @media ${DEVICE.mobileWide} {
    gap: 40px;
    padding: 40px 16px 160px;
  }
  overflow-x: hidden;
  outline: none;
  -webkit-overflow-scrolling: touch;
`;

const CloseButtonLayout = styled.div`
  position: fixed;
  z-index: 12001;
`;

const CircleButton = styled(NavigationButton)`
  position: absolute;
  top: -56px;
  left: -40px;

  @media ${DEVICE.sd} {
    top: -48px;
    left: -8px;
    width: 32px;
    height: 32px;
    > div {
      /* Icon style */
      width: 24px;
    }
  }

  @media ${DEVICE.mobileWide} {
    top: -24px;
    left: 0px;
  }
`;

export const NavButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & { iconType: 'close' | 'back' }
> = ({ iconType, ...props }) => {
  return (
    <CloseButtonLayout>
      <CircleButton
        buttonSize="large"
        buttonSizeMobile="medium"
        icon={iconType === 'close' ? Icons.CLOSE : Icons.HALF_ARROW_LEFT}
        {...props}
      />
    </CloseButtonLayout>
  );
};

interface BaseButtonProps {
  componentName?: string;
}
interface PreorderButtonProps extends BaseButtonProps {
  bookSakuhinCode: string;
  bookCode: string;
}

export const PreorderButton: React.FC<PreorderButtonProps> = (props) => {
  const { componentName, bookSakuhinCode, bookCode } = props;
  const intl = useIntl();
  const { preorderStatus } = useContext(PreorderContext);
  const isPreordered = !!preorderStatus.get(bookCode)?.isPreordered;

  const buttonText = isPreordered
    ? intl.formatMessage(messages.preorderedButtonText)
    : intl.formatMessage(messages.preorderButtonText);

  const onClick: React.MouseEventHandler = () => {
    getKafkaClient().trackUserClickDimension0<BookTitleDetailStageReserveLog>(
      STAGE_RESERVE,
      {
        book_sakuhin_code: bookSakuhinCode,
        book_code: bookCode,
      }
    );
  };

  return (
    <Preorder bookCode={bookCode} data-ucn={componentName} onClick={onClick}>
      <PlayButton
        buttonTheme="light"
        icon={isPreordered ? Icons.PREORDERED : Icons.PREORDER}
        text={buttonText}
        buttonSize="large"
      />
    </Preorder>
  );
};
