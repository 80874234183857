import { useToasts } from '@u-next/react-toast-notifications';
import { ForwardRefRenderFunction, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_getVideoTitleEpisodesQuery,
  cosmo_getVideoTitleEpisodesQueryVariables,
  cosmo_getVideoTitleFeatureQuery,
  cosmo_getVideoTitleFeatureQueryVariables,
  cosmo_getVideoTitleQuery,
  cosmo_getVideoTitleQueryVariables,
  cosmo_getVideoTitleRelatedStuffsQuery,
  cosmo_getVideoTitleRelatedStuffsQueryVariables,
} from '../../../__generated__/globalTypes';
import { createAkamaiImageUrl } from '../../../shared/components/Common/AkamaiImage';
import MetaTags from '../../../shared/components/MetaTags';
import {
  GenreUrl,
  isValidGenreUrl,
  PortalErrorCodes,
  SALE_TYPE_CODE,
  UrlQueryParams,
} from '../../../shared/constants';
import { globalMessages } from '../../../shared/constants/messages';
import metaMessages from '../../../shared/constants/meta/titleDetail';
import { extractGraphQLError } from '../../../utils';
import { Nullable, Undefinable } from '../../../utils/Nullable';
import { genreTitles, isValidKeyForGenreTitles } from '../../Genre/messages';
import * as videoTitleDetail from '../../Log/ComponentName/videoTitleDetail';
import { getKafkaClient } from '../../Log/kafkaClient';
import CreditSection, { Credit } from '../shared/CreditSection';
import RelatedSection from '../shared/RelatedWorkSection/RelatedSection';
import StackedPager from '../shared/StackedPager';
import ComingSoon from '../shared/StackedPager/ComingSoon';
import StackedVideo, {
  StackedVideoPlaceholder,
} from '../shared/StackedPager/StackedItem/StackedVideo';
import {
  GET_VIDEO_TITLE,
  GET_VIDEO_TITLE_EPISODES,
  GET_VIDEO_TITLE_FEATURE,
  GET_VIDEO_TITLE_RELATED_STUFFS,
} from './gql';
import messages from './messages';
import VideoStageSection from './VideoStageSection';

import { useRouter } from 'next/router';
import { isPoint } from '../../../shared/components/PointPriceBadge';
import { errorMessages } from '../../../shared/constants/messages';
import { createExtendedApolloError } from '../../../shared/ExtendedApolloError';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import useEpisodePagingTabList from '../../../shared/hooks/useEpisodePagingTabList';
import { useUserInfo } from '../../../shared/hooks/useUserInfo';
import type { VideoTitleDetailEpisodeListPlayLog } from '../../Log/__types__/videoTitleDetail-episodeList-play';
import TitleDetailPlaceholder from '../shared/Placeholder';
import SignupPromotion from '../shared/SignupPromotion';
import VideoInfoSection from './VideoInfoSection';

interface VideoDetailProps {
  titleCode: string;
  isSsr: boolean;
}

const VideoDetail: ForwardRefRenderFunction<
  HTMLDivElement,
  VideoDetailProps
> = ({ titleCode }) => {
  const { data: userInfoData, loading: userInfoLoading } = useUserInfo();
  const userInfo = userInfoData?.userInfo;
  const intl = useIntl();
  const router = useRouter();
  const feid = router.query[UrlQueryParams.FEATURE_CODE];
  const featureCode = Array.isArray(feid) ? feid[0] : feid ?? '';

  const { loading, data, error } = useClientQuery<
    cosmo_getVideoTitleQuery,
    cosmo_getVideoTitleQueryVariables
  >(GET_VIDEO_TITLE, {
    variables: {
      code: titleCode,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: featureData } = useClientQuery<
    cosmo_getVideoTitleFeatureQuery,
    cosmo_getVideoTitleFeatureQueryVariables
  >(GET_VIDEO_TITLE_FEATURE, {
    variables: {
      code: titleCode,
      featureCode,
    },
  });

  const { data: relatedStuffsData, error: relatedStuffsError } = useClientQuery<
    cosmo_getVideoTitleRelatedStuffsQuery,
    cosmo_getVideoTitleRelatedStuffsQueryVariables
  >(GET_VIDEO_TITLE_RELATED_STUFFS, {
    variables: {
      code: titleCode,
      creditsPage: 1,
      creditsPageSize: 100,
    },
  });

  const {
    title,
    credits,
    relatedTitle,
    relatedBooks,
    recommendedTitles,
    hasRelatedTitles,
    hasRelatedBooks,
    hasRecommendedTitles,
    totalBookCount,
    totalRecommendedTitles,
  } = useMemo(() => {
    let title: Undefinable<
      cosmo_getVideoTitleQuery['webfront_title_stage'] &
        cosmo_getVideoTitleFeatureQuery['webfront_title_stage']
    > = undefined;
    let credits: Credit[] = [];
    const relatedTitle = relatedStuffsData?.webfront_title_relatedTitles;
    const relatedBooks = relatedStuffsData?.webfront_title_relatedBooks;
    const recommendedTitles =
      relatedStuffsData?.webfront_title_recommendedTitles;
    let hasRelatedTitles = false;
    let hasRelatedBooks = false;
    let hasRecommendedTitles = false;
    let totalBookCount = 0;
    let totalRecommendedTitles = 0;
    if (data?.webfront_title_stage && featureData?.webfront_title_stage) {
      title = {
        ...data.webfront_title_stage,
        ...featureData?.webfront_title_stage,
      };

      if (relatedStuffsData?.webfront_title_credits?.titleCredits) {
        credits = relatedStuffsData?.webfront_title_credits?.titleCredits?.map(
          (credit) => ({
            group: credit.castTypeName ?? '',
            characterName: credit.characterName ?? '',
            personCode: credit.personCode ?? '',
            personNameCode: credit.personNameCode ?? '',
            personName: credit.personName ?? '',
          })
        );
      }

      totalBookCount =
        relatedStuffsData?.webfront_title_relatedBooks?.pageInfo?.results ?? 0;
      hasRelatedBooks = totalBookCount > 0;
      hasRelatedTitles =
        (relatedStuffsData?.webfront_title_relatedTitles?.length ?? 0) > 0;

      totalRecommendedTitles =
        relatedStuffsData?.webfront_title_recommendedTitles?.titles?.length ??
        0;
      hasRecommendedTitles = totalRecommendedTitles > 0;
    }
    return {
      title,
      credits,
      relatedTitle,
      relatedBooks,
      recommendedTitles,
      hasRelatedBooks,
      hasRelatedTitles,
      hasRecommendedTitles,
      totalBookCount,
      totalRecommendedTitles,
    };
  }, [
    data?.webfront_title_stage,
    featureData?.webfront_title_stage,
    relatedStuffsData?.webfront_title_credits?.titleCredits,
    relatedStuffsData?.webfront_title_recommendedTitles,
    relatedStuffsData?.webfront_title_relatedBooks,
    relatedStuffsData?.webfront_title_relatedTitles,
  ]);

  const pageQuery = router.query[UrlQueryParams.EPISODE_TAB]
    ? Number(router.query[UrlQueryParams.EPISODE_TAB]) + 1
    : 1;

  const publicMainEpisodeCount = title?.publicMainEpisodeCount ?? 0;
  const { itemsPerPage: episodesPageSize } = useEpisodePagingTabList(
    publicMainEpisodeCount
  );

  const { data: episodesData, previousData: episodesPreviousData } =
    useClientQuery<
      cosmo_getVideoTitleEpisodesQuery,
      cosmo_getVideoTitleEpisodesQueryVariables
    >(GET_VIDEO_TITLE_EPISODES, {
      variables: {
        code: titleCode,
        page: pageQuery,
        pageSize: episodesPageSize,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const episodes =
    episodesData?.webfront_title_titleEpisodes?.episodes ||
    episodesPreviousData?.webfront_title_titleEpisodes?.episodes;
  const pageInfo = episodesData?.webfront_title_titleEpisodes?.pageInfo ||
    episodesPreviousData?.webfront_title_titleEpisodes?.pageInfo || {
      results: 0,
      page: 1,
      pageSize: 1,
      pages: 1,
    };

  const hasEpisodeToShow =
    !!episodes?.length && !!title?.currentEpisode?.existsRelatedEpisode;

  const showItemsCountText = !!(
    title?.publicMainEpisodeCount && title?.publicMainEpisodeCount > 0
  );

  const { addToast, removeToast } = useToasts();
  const toastId = useRef<Nullable<string>>(null);
  useEffect(() => {
    if (relatedStuffsError) {
      addToast(
        intl.formatMessage(errorMessages.fetchErrorToast),
        { showCloseButton: true, appearance: 'warning' },
        (id: string) => {
          toastId.current = id;
        }
      );
    } else if (toastId.current) {
      removeToast(toastId.current);
    }
  }, [intl, addToast, removeToast, relatedStuffsError]);

  if (error) {
    const [videoTitleErrorCode] = extractGraphQLError(
      'VIDEO_TITLE_ERROR',
      error
    );
    const extendedData =
      videoTitleErrorCode === PortalErrorCodes.VIDEO_TITLE_NOT_FOUND
        ? {
            errorCode: '404',
            customTitle: intl.formatMessage(errorMessages.notFoundTitle),
            customMessage: intl.formatMessage(
              errorMessages.notFoundDescription
            ),
          }
        : {
            errorCode: '500',
          };
    throw createExtendedApolloError(error, extendedData);
  }

  let tailoredGenreId = title?.mainGenreId ?? '';
  if (tailoredGenreId === 'SEMIADULT') tailoredGenreId = 'ADULT';

  if (loading || !title?.currentEpisode?.id) {
    return <TitleDetailPlaceholder type="video" />;
  }

  return (
    <>
      <MetaTags
        // title for og and twitter is being checked
        title={intl.formatMessage(metaMessages.video.defaultTitle, {
          titleName: title.titleName,
          genreName: isValidKeyForGenreTitles(tailoredGenreId)
            ? intl.formatMessage(genreTitles[tailoredGenreId].title)
            : '',
          productionYear: title.productionYear,
        })}
        description={intl.formatMessage(metaMessages.video.defaultDescription, {
          titleName: title.titleName,
        })}
        keywords={intl.formatMessage(metaMessages.video.defaultKeywords, {
          titleName: title.titleName,
        })}
        image={`https://${createAkamaiImageUrl(
          title.thumbnail?.standard ?? '',
          { width: 1024 },
          'jpg'
        )}`}
        path={`/title/${title.id}`}
        ogType="article"
        twitterCatchPhrase={title.catchphrase ?? undefined}
        canonicalLink={`/title/${title.id}`}
        breadcrumbs={[
          { name: 'HOME', pathname: '/' },
          ...(isValidKeyForGenreTitles(tailoredGenreId) &&
          isValidGenreUrl(tailoredGenreId)
            ? [
                {
                  name: intl.formatMessage(genreTitles[tailoredGenreId].title),
                  pathname: `/genre/${GenreUrl[tailoredGenreId]}`,
                },
              ]
            : []),
          ...(title.titleName
            ? [
                {
                  name: title.titleName,
                  pathname: `/title/${title.id}`,
                },
              ]
            : []),
        ]}
      />
      <VideoStageSection
        isLoggedIn={!userInfoLoading && !!userInfo?.id}
        title={title}
        multiAccountId={userInfo?.multiAccountId ?? undefined}
      />
      <SignupPromotion
        btnComponentName={'videoDetail-stage-signup'}
        type={
          title.productLineupCodeList.includes('LNPS_FSCR')
            ? 'FSOCCER'
            : 'GENERAL'
        }
      />
      <VideoInfoSection title={title} />
      {(hasEpisodeToShow || title?.nextUpdateDateTime) && episodes && (
        <StackedPager
          items={episodes}
          page={pageQuery}
          hideBoxUI={false}
          totalItemsCount={pageInfo.results}
          missingAlertText={title.missingAlertText}
          nextUpdateText={
            title.nextUpdateDateTime &&
            intl.formatMessage(messages.nextUpdate, {
              date: intl.formatDate(title.nextUpdateDateTime, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }),
            })
          }
          headingText={intl.formatMessage(messages.episodeSectionHeading)}
          showItemsCount={showItemsCountText}
          itemsCountText={
            title.comingSoonMainEpisodeCount &&
            title.comingSoonMainEpisodeCount > 0
              ? intl.formatMessage(
                  messages.episodeSectionOngoingItemsCountText,
                  {
                    totalCount:
                      publicMainEpisodeCount + title.comingSoonMainEpisodeCount,
                    currentCount: publicMainEpisodeCount,
                  }
                )
              : intl.formatMessage(
                  messages.episodeSectionFinishedItemsCountText,
                  {
                    count: publicMainEpisodeCount,
                  }
                )
          }
          componentName={videoTitleDetail.EPISODE_LIST_PAGER_TAB}
          cellRender={({ item: episode, index, loading }) => {
            if (episode && !loading) {
              const trackEpisodePlay = async () => {
                await getKafkaClient().trackUserClickDimension1<VideoTitleDetailEpisodeListPlayLog>(
                  'videoTitleDetail-episodeList-play',
                  {
                    index,
                    episode_code: episode.id,
                  }
                );
              };
              return (
                <StackedVideo
                  isEpisodeDetail={false}
                  contentText={episode.introduction}
                  parentCode={title.id}
                  itemCode={episode.id}
                  titleText={`${episode.displayNo} ${episode.episodeName}`}
                  thumbnailUrl={`//${episode.thumbnail?.standard}`}
                  isNew={!!episode.isNew}
                  isPurchased={!!episode.isPurchased}
                  isFree={episode.saleTypeCode === SALE_TYPE_CODE.FOD}
                  maxLines={episode.purchaseEpisodeLimitday ? 4 : undefined}
                  minimumPrice={episode.minimumPrice}
                  hasMultiprice={episode.hasMultiplePrices}
                  showPointBadge={isPoint(episode)}
                  isComplete={!!episode.completeFlag}
                  progress={
                    typeof episode.duration === 'number' &&
                    typeof episode.interruption === 'number' &&
                    episode.duration !== 0
                      ? episode.interruption / episode.duration
                      : undefined
                  }
                  limitDay={episode.purchaseEpisodeLimitday}
                  saleText={episode.saleText}
                  episodeNotices={episode.episodeNotices}
                  durationText={
                    episode.duration
                      ? `${Math.max(
                          1,
                          Math.floor(episode.duration / 60)
                        )}${intl.formatMessage(globalMessages.minute)}`
                      : ''
                  }
                  onPlayClick={trackEpisodePlay}
                  playComponentName={'videoDetail-episodeList-play'}
                  detailComponentName={'videoDetail-episodeList-episodeDetail'}
                />
              );
            } else {
              return <StackedVideoPlaceholder />;
            }
          }}
          postCellRender={
            title?.nextUpdateDateTime ? (
              <ComingSoon nextUpdateDateTime={title.nextUpdateDateTime} />
            ) : null
          }
        />
      )}
      {credits?.length > 0 && (
        <CreditSection
          credits={credits}
          sectionHeading={intl.formatMessage(messages.creditSectionHeading)}
          mainGenreId={title.mainGenreId ?? undefined}
          castNameComponentName={videoTitleDetail.CAST_LIST_CASE_NAME}
          sakuhinCode={titleCode}
        />
      )}
      {hasRelatedTitles && relatedTitle && (
        // Show the first group only because only one group is used practically.
        <RelatedSection
          key={`related-section-${relatedTitle[0].id}`}
          sectionName="relatedVideo"
          sectionHeading={intl.formatMessage(
            messages.relatedVideoSectionHeading
          )}
          contentType="video"
          items={relatedTitle[0].titles}
          totalItemCount={relatedTitle[0].titles.length}
          itemComponentName={
            videoTitleDetail.RELATED_VIDEO_TITLE_LIST_TITLE_CARD
          }
          showMoreLinkProps={{
            href: `/browse/relation/${title.id}/video`,
          }}
        />
      )}
      {hasRecommendedTitles && recommendedTitles && (
        <RelatedSection
          key={`related-section-recommended-title`}
          sectionName="recommendedVideo"
          sectionHeading={intl.formatMessage(
            messages.recommendedVideoSectionHeading
          )}
          contentType="video"
          items={recommendedTitles.titles}
          totalItemCount={totalRecommendedTitles}
          itemComponentName={
            videoTitleDetail.RECOMMEND_VIDEO_TITLE_LIST_TITLE_CARD
          }
        />
      )}
      {hasRelatedBooks && relatedBooks && (
        <RelatedSection
          sectionName="relatedBook"
          sectionHeading={intl.formatMessage(
            messages.relatedBookSectionHeading
          )}
          contentType="book"
          items={relatedBooks.books}
          totalItemCount={totalBookCount}
          itemComponentName={
            videoTitleDetail.RELATED_BOOK_TITLE_LIST_TITLE_CARD
          }
          showMoreLinkProps={{
            href: `/browse/relation/${title.id}/book`,
          }}
        />
      )}
    </>
  );
};

export default VideoDetail;
