import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
  LIST_VIEW_TITLE_CARD_SIZE,
} from '../constants';
import BookMetaInfo from './MetaInfo/BookMetaInfo';
import { BookMetaInfoProps } from './MetaInfo/utils';
import BookThumbnail, { Shadow, ThumbnailProps } from './Thumbnail';

const desktopSize = BLOCK_VIEW_TITLE_CARD_SIZE.book;
const mobileSize = BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.book;
const hoveredSize = LIST_VIEW_TITLE_CARD_SIZE.book;
const hoveredPaddingTop = 24;

const BookTitleCardContainer = styled.div`
  width: ${desktopSize.width}px;
  transition: transform 200ms ease-in;
  cursor: pointer;

  @media ${DEVICE.mobileWide} {
    width: ${mobileSize.width}px;
  }
`;

const Background = styled.div`
  pointer-events: none;
  position: absolute;
  width: ${hoveredSize.width}px;
  height: ${hoveredSize.height}px;
  background: linear-gradient(
    180deg,
    ${COLORS.charcoal} 0%,
    ${COLORS.ink} 100%
  );
  top: -${hoveredPaddingTop}px;
  left: -${(hoveredSize.width - desktopSize.width) / 2}px;
  border: 1px solid ${COLORS.white_10};
  box-sizing: border-box;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 200ms ease-in;
`;

const MetaInfoContainer = styled.div`
  position: absolute;
  width: ${hoveredSize.width}px;
  left: -${(hoveredSize.width - desktopSize.width) / 2}px;
  padding: 10px 12px 6px;
  box-sizing: border-box;

  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease-in;
`;

const FreeBookNum = styled.div`
  margin-top: 2px;
  color: ${COLORS.primary};
  font-size: 12px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    margin-top: 1px;
    font-size: 10px;
  }
`;

const Container = styled.div`
  cursor: default;
  position: relative;
  width: 100%;
  z-index: 100;
  transition: z-index 100ms 100ms linear;

  @media (hover: hover) {
    &:hover {
      z-index: 200;

      @media ${DEVICE.exceptMobileWide} {
        ${Background} {
          opacity: 1;
          pointer-events: auto;
        }

        ${BookTitleCardContainer} {
          transform: translateY(-32px);
        }

        ${MetaInfoContainer} {
          pointer-events: auto;
          opacity: 1;
        }

        ${Shadow} {
          opacity: 1;
        }

        ${FreeBookNum} {
          opacity: 0;
        }
      }
    }
  }
`;

type Props = ThumbnailProps &
  BookMetaInfoProps & {
    isSimplified?: boolean;
  };

const ExpandableBookCard: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  bookType,
  numberOfFree,
  minimumPrice,
  hasMultiPrice,
  totalBookNum,
  publicStartDateTime,
  author,
  publisher,
  bookMagazine,
  rate,
  isNew,
  isPreorderable,
  isOnSale,
  isDailyFree,
  isDailyFreePlus,
  isEpisodeSpecified,

  isChapter = false,
  isOriginal = false,
  isYomihodai,
  isLazy = false,
  subgenreTagList = [],

  isSimplified,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const containerHoverHandlers = useMemo(() => {
    return {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
      onFocus: () => setIsHovered(true),
      onBlur: () => setIsHovered(false),
    };
  }, []);

  const thumbnailProps = {
    isNew,
    isPreorderable,
    thumbnailUrl,
    isLazy,
    titleName,
    isDailyFree,
    isDailyFreePlus,
  };

  const metaInfoProps = {
    titleName,
    bookType,
    numberOfFree,
    publicStartDateTime,
    minimumPrice,
    hasMultiPrice,
    totalBookNum,
    author,
    publisher,
    bookMagazine,
    rate,
    isOnSale,
    isOriginal,
    isYomihodai,
    subgenreTagList,
    isDailyFree,
    isDailyFreePlus,
    isChapter,
    isEpisodeSpecified,
  };

  return (
    <Container {...containerHoverHandlers}>
      <BookTitleCardContainer>
        <Background />
        <BookThumbnail {...thumbnailProps} />
        <MetaInfoContainer>
          {
            // only render MetaInfo on hover if this component is marked as "isSimplified"
            // otherwise MetaInfo will always be rendered
            (!isSimplified || isHovered) && <BookMetaInfo {...metaInfoProps} />
          }
        </MetaInfoContainer>
      </BookTitleCardContainer>
      {numberOfFree && <FreeBookNum>{numberOfFree}</FreeBookNum>}
    </Container>
  );
};

export default ExpandableBookCard;
