import React, { useMemo } from 'react';
import { PaymentBadgeCode } from '../../../__generated__/globalTypes';
import { PaymentBadge } from '../../../shared/components/Common/Badge/PaymentBadge';
import { PurchasedBadge } from '../../../shared/components/Common/Badge/PurchasedBadge';

interface BadgeProps {
  code: PaymentBadgeCode;
  name: string;
}

interface PaymentBadgeProps<T> {
  list: T[];
}

export function PaymentBadgeList<T extends BadgeProps>({
  list,
}: PaymentBadgeProps<T>): React.ReactElement<PaymentBadgeProps<T>> {
  const isBuy = list.some((badgeItem: T) => badgeItem.code === 'BUY');
  const badgeText = useMemo(
    () =>
      list.reduce((prevText, currentBadgeItem) => {
        if (currentBadgeItem.code === 'BUY') return prevText;
        const currentBadgeText = currentBadgeItem.name;
        return !prevText
          ? `${currentBadgeText}`
          : `${prevText} / ${currentBadgeText}`;
      }, ''),
    [list]
  );
  return (
    <>
      {badgeText && (
        <PaymentBadge badgeSize="large" mobileBadgeSize="small">
          {badgeText}
        </PaymentBadge>
      )}
      {isBuy && <PurchasedBadge mobileBadgeSize="small" />}
    </>
  );
}
