import Head from 'next/head';
import React from 'react';
import { GlobalConfig } from '../../constants';

interface MetaTagsProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  ogType?: string;
  twitterCatchPhrase?: string;
  path?: string;
  canonicalLink?: string;
  // video.unext.jp/livedetail/ --> www.video.unext.jp/livedetail/
  // background: https://wiki.unext-info.jp/pages/viewpage.action?pageId=193669664
  shouldUseStaticSiteforCanonicalUrl?: boolean;
  // For pages with pagination
  link?: string;
  pages?: number;
  pageNumber?: number;
  noIndex?: boolean;
  breadcrumbs?: {
    name: string;
    pathname: string;
  }[];
  pageLinkCreator?: (pageNumber: number) => string;
}

const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  description,
  keywords,
  ogType,
  twitterCatchPhrase,
  path,
  canonicalLink,
  link,
  pages,
  pageNumber,
  image,
  noIndex,
  breadcrumbs,
  shouldUseStaticSiteforCanonicalUrl = false,
  pageLinkCreator,
}) => {
  const hostname = GlobalConfig.URLS.HOST;
  const prefixhostname = GlobalConfig.URLS.WWW_PREFIX_HOST;

  return (
    <>
      <Head>
        {/* Next.js only handles <title> and <meta name /> duplicates */}
        {/* to handle duplicates, key is required for tags other than <title> and <meta name /> */}
        {title && [
          <title key="title">{title}</title>,
          <meta key="og:title" property="og:title" content={title} />,
        ]}
        {/* description can be blank */}
        {typeof description === 'string' && [
          <meta key="description" name="description" content={description} />,
          <meta
            property="og:description"
            content={description}
            key="og:description"
          />,
        ]}
        {/* keywords can be blank */}
        {typeof keywords === 'string' && (
          <meta name="keywords" content={keywords} />
        )}
        {ogType && <meta property="og:type" content={ogType} key="og:type" />}
        {image && [
          <meta property="og:image" content={image} key="og:image" />,
          <meta name="twitter:image" content={image} key="twitter:image" />,
        ]}
        {path && [
          <meta
            property="og:url"
            content={`${hostname}${path}`}
            key="og:url"
          />,
          <meta
            name="twitter:url"
            content={`${hostname}${path}`}
            key="twitter:url"
          />,
        ]}
        {twitterCatchPhrase && (
          <meta name="twitter:catchphrase" content={twitterCatchPhrase} />
        )}
        {canonicalLink && (
          <link
            rel="canonical"
            href={`${
              shouldUseStaticSiteforCanonicalUrl ? prefixhostname : hostname
            }${canonicalLink}`}
            key="canonical"
          />
        )}
        {link && pageNumber && pageNumber > 1 && (
          <link
            rel="prev"
            href={
              pageLinkCreator
                ? pageLinkCreator(pageNumber - 1)
                : `${link}/${pageNumber - 1}`
            }
          />
        )}
        {link && pageNumber && pages && pageNumber + 1 <= pages && (
          <link
            rel="next"
            href={
              pageLinkCreator
                ? pageLinkCreator(pageNumber + 1)
                : `${link}/${pageNumber + 1}`
            }
          />
        )}
        {noIndex && <meta name="robots" content="noindex" />}
        {breadcrumbs && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: breadcrumbs.map((breadcrumb, index) => ({
                  '@type': 'ListItem',
                  position: index + 1,
                  item: {
                    '@id': `${hostname}${breadcrumb.pathname}`,
                    '@type': 'Thing',
                    name: breadcrumb.name,
                  },
                })),
              }),
            }}
          />
        )}
      </Head>
    </>
  );
};

export default MetaTags;
