import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import { IMAGE_ASPECT, LIST_VIEW_TITLE_CARD_SIZE } from '../constants';

const { width, height } = LIST_VIEW_TITLE_CARD_SIZE.purchasedVideo;

const EpisodeList = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
`;

const ExpiryDate = styled.div`
  text-align: center;
  color: ${COLORS.white_75};
  font-size: 10px;
`;

const Container = styled.div`
  height: ${height -
  width / IMAGE_ASPECT.video}px; // カードの高さから画像の高さを引いた値
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  padding: 0 12px;
  box-sizing: border-box;
`;

export interface PurchasedVideoMetaInfoProps {
  closestExpiryDateText: string;
  boughtEpisodeNumberTextList: string[];
}

const PurchasedVideoMetaInfo: React.FC<PurchasedVideoMetaInfoProps> = ({
  closestExpiryDateText,
  boughtEpisodeNumberTextList,
}) => {
  return (
    <Container>
      {boughtEpisodeNumberTextList &&
        boughtEpisodeNumberTextList.length > 0 && (
          <EpisodeList>{boughtEpisodeNumberTextList.join('、')}</EpisodeList>
        )}
      <ExpiryDate>{closestExpiryDateText}</ExpiryDate>
    </Container>
  );
};

export default PurchasedVideoMetaInfo;
