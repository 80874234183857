import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { useContext } from 'react';
import UserAgentContext from '../UserAgentContext';

const useClientQuery = <
  TData,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> | undefined
): QueryResult<TData, TVariables> => {
  const { isBot } = useContext(UserAgentContext);
  return useQuery<TData, TVariables>(query, { ssr: isBot, ...options });
};

export default useClientQuery;
