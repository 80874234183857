/**
 *  @see https://wiki.unext-info.jp/pages/viewpage.action?pageId=57624726
 */

export const GENRE_LIST_GENRE = 'search-genreList-genre';
export const CATEGORY_LIST_CATEGORY = 'searchCategoryList--category';

export const VIDEO_CATEGORY_TITLE_LIST_TITLE_CARD =
  'videoSearchCategoryTitleList--titleCard';
export const VIDEO_CATEGORY_TITLE_LIST_CONTROL_POINT =
  'videoSearchCategoryTitleList-control-point';

export const BOOK_CATEGORY_TITLE_LIST_TITLE_CARD =
  'bookSearchCategoryTitleList--titleCard';

export const CAST_TITLE_LIST_VIDEO_TITLE_CARD =
  'searchCastTitleList-video-titleCard';
export const CAST_TITLE_LIST_BOOK_TITLE_CARD =
  'searchCastTitleList-book-titleCard';
export const CAST_TITLE_LIST_VIDEO_MORE = 'searchCastTitleList--videoMore';
export const CAST_TITLE_LIST_BOOK_MORE = 'searchCastTitleList--bookMore';

export const CAST_VIDEO_TITLE_LIST_TITLE_CARD =
  'searchCastVideoTitleList--titleCard';
export const CAST_VIDEO_TITLE_LIST_CONTROL_POINT =
  'searchCastVideoTitleList-control-point';

export const CAST_BOOK_TITLE_LIST_TITLE_CARD =
  'searchCastBookTitleList--titleCard';

export const PUBLISHER_BOOK_TITLE_LIST_TITLE_CARD =
  'searchPublisherBookTitleList--titleCard';
export const MAGAZINE_BOOK_TITLE_LIST_TITLE_CARD =
  'searchMagazineBookTitleList--titleCard';
export const LABEL_BOOK_TITLE_LIST_TITLE_CARD =
  'searchLabelBookTitleList--titleCard';

export const FREEWORD_TITLE_LIST_VIDEO_TITLE_CARD =
  'searchFreewordTitleList-video-titleCard';
export const FREEWORD_TITLE_LIST_BOOK_TITLE_CARD =
  'searchFreewordTitleList-book-titleCard';
export const FREEWORD_TITLE_LIST_PERSON_NAME =
  'searchFreewordTitleList--personName';
export const FREEWORD_TITLE_LIST_VIDEO_MORE =
  'searchFreewordTitleList--videoMore';
export const FREEWORD_TITLE_LIST_BOOK_MORE =
  'searchFreewordTitleList--bookMore';
export const FREEWORD_TITLE_LIST_PERSON_MORE =
  'searchFreewordTitleList--personMore';

export const FREEWORD_VIDEO_TITLE_LIST_TITLE_CARD =
  'searchFreewordVideoTitleList--titleCard';
export const FREEWORD_VIDEO_TITLE_LIST_CONTROL_POINT =
  'searchFreewordVideoTitleList-control-point';
export const FREEWORD_BOOK_TITLE_LIST_TITLE_CARD =
  'searchFreewordBookTitleList--titleCard';

export const FREEWORD_LIVE_TITLE_LIST_TITLE_CARD =
  'searchFreewordLiveTitleList--titleCard';
export const FREEWORD_TITLE_LIST_LIVE_TITLE_CARD =
  'searchFreewordTitleList-live-titleCard';

export const LIVE_CATEGORY_TITLE_LIST_TITLE_CARD =
  'liveSearchCategoryTitleList--titleCard';
