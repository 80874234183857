import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useUserInfo } from '../../shared/hooks/useUserInfo';
import type { LogBody } from './__types__/logBody';
import { getKafkaClient, KafkaClient } from './kafkaClient';
import { useTreasureDataClient } from './useTreasureDataClient';

interface KafkaContextProps {
  client?: KafkaClient;
}

const KafkaContext = React.createContext<KafkaContextProps>({});

export const KafkaProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [client, setClient] = useState<KafkaClient>();
  const [isReady, setIsReady] = useState(false);
  const { data: userInfoData, loading } = useUserInfo();
  const userInfo = userInfoData?.userInfo;
  const treasureDataClient = useTreasureDataClient();

  useEffect(() => {
    // getKafkaClient is only needed at the client side
    setClient(getKafkaClient());
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }

    // use treasure data's _td cookie (which is td_client_id in treasure data's database) for kafka as well
    const deviceId = treasureDataClient?.tdClientId;

    if (deviceId) {
      client.setDeviceId(deviceId);
    }

    if (userInfo) {
      client.setUserInfo({
        service_platform: (userInfo.userPlatformCode ||
          'unext') as LogBody['key']['service_platform'],
        user_platform_id: userInfo.userPlatformId ?? '',
        user_multi_account_id: userInfo.multiAccountId || '',
        super_user_flg: userInfo.superUser ? 1 : 0,
      });
    }

    const isReady = !!deviceId && !!userInfo && !loading;
    client.setIsReady(isReady);
    setIsReady(isReady);
  }, [userInfo, loading, client, treasureDataClient?.tdClientId]);

  useEffect(() => {
    if (!client) {
      return;
    }

    if (isReady) {
      /**
       * NOTE: sending empty string for validation purpose
       * source: https://u-next.slack.com/archives/C3HTNHMJ6/p1679024844686829?thread_ts=1679023801.242149&cid=C3HTNHMJ6
       */
      client.trackInitial('');
    }
  }, [client, isReady]);

  return (
    <KafkaContext.Provider value={{ client }}>{children}</KafkaContext.Provider>
  );
};
