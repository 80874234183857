import { QueryHookOptions, QueryResult } from '@apollo/client';
import { cosmo_platformInfoQuery } from '../../../__generated__/globalTypes';
import useClientQuery from '../useClientQuery';
import useIsFromShortcutMode from '../useIsFromShortcutMode';
import { GET_PLATFORM_INFO } from './gql';

/**
 * usePlatformInfo hook is used to query PlatformInfo.
 * First GET_PLATFORM_INFO request is made in _app.tsx.
 * We use the hook to reuse the cached data
 */
export const usePlatformInfo = (
  options?: QueryHookOptions & { skipInShortcutMode?: boolean }
): QueryResult<cosmo_platformInfoQuery> => {
  const isFromShortcutMode = useIsFromShortcutMode();
  const skipInShortcutMode = options?.skipInShortcutMode ?? true;
  const skip = options?.skip || (skipInShortcutMode && isFromShortcutMode);

  const apolloQueryResult = useClientQuery<cosmo_platformInfoQuery>(
    GET_PLATFORM_INFO,
    { ...options, skip }
  );
  return apolloQueryResult;
};
