import Link from 'next/link';
import styled from 'styled-components';
import { DEVICE } from '../../../../shared/styles';
import { COLORS } from '../../../../shared/styles/theme';
import { mixinSectionHeading } from '../mixins';

export const RelatedSectionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const Heading = styled.h2`
  ${mixinSectionHeading}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ShowMoreLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 4px;

  font-size: 18px;
  color: ${COLORS.white_75};
  transition: color 0.15s ease-in;
  flex-shrink: 0;

  > div {
    /* Icon style */
    width: 20px;
  }

  &:hover {
    color: ${COLORS.white};
  }

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
    > div {
      /* Icon style */
      width: 18px;
    }
  }
`;

export const TitleListContainer = styled.div<{ columnCount: number }>`
  margin-top: 16px;

  display: grid;
  gap: 16px 12px;
  grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
  > * {
    min-width: 0;
  }
`;
