type IsPointCardBase = {
  productLineupCodeList: string[];
  hasPackRights: boolean;
};

type IsPointCardLive = {
  __typename: 'Live';
  tickets: {
    price: number;
  }[];
} & IsPointCardBase;

type IsPointCard = {
  __typename: 'Title' | 'PostPlayItem' | 'TitleStage' | 'Episode';
  minimumPrice?: number | null;
  hasMultiprice?: boolean | null;
} & IsPointCardBase;

export const isPoint = (title: IsPointCardLive | IsPointCard): boolean => {
  if (
    title.productLineupCodeList.includes('LNPS_NOD') ||
    title.productLineupCodeList.includes('LNPS_FSCR')
  ) {
    return !title.hasPackRights;
  }

  if (title.__typename === 'Live') {
    return title.tickets.some((x) => x.price > 0);
  }

  return !!title.minimumPrice && title.minimumPrice > 0;
};
