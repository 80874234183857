import { useIntl } from 'react-intl';
import styled from 'styled-components';
import UnextLogo from '../../../../assets/icons/logo/Logo_U-NEXT_Mark.svg';
import { Nullable } from '../../../../utils/Nullable';
import { globalMessages } from '../../../constants/messages';
import { COLORS } from '../../../styles/theme';
import AkamaiImage from '../../Common/AkamaiImage';
import { RoundBadge } from '../../Common/Badge/RoundBadge';
import Icon, { Icons } from '../../Common/Icon';
import { IMAGE_ASPECT } from '../constants';

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${COLORS.gray_3};
  padding-bottom: ${100 / IMAGE_ASPECT.video}%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
  }
`;

const ThumbnailTopLeft = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

const OnlyOnBG = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 48px;
  width: 56px;
  background: linear-gradient(
    134deg,
    rgba(0, 10, 23, 0.5) 0%,
    rgba(0, 10, 23, 0) 50.12%
  );
`;

const OnlyOnLogoContainer = styled.div`
  display: inline-flex;
  padding: 2px;
  > svg {
    width: 18px;
  }
`;

const PointIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
  width: 16px;
`;

export const Shadow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  position: absolute;
  padding: 0 12px 5px;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  background: linear-gradient(
    -180deg,
    ${COLORS.jet_black_overlay_0} 0%,
    ${COLORS.jet_black_overlay_80} 100%
  );
`;

const MetaInfoTitle = styled.h3`
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export interface VideoThumbnailProps {
  titleName: Nullable<string>;
  thumbnailUrl: string;
  isPoint?: boolean;
  isNew?: Nullable<boolean>;
  isOnlyOn?: boolean;
  isLazy: boolean;
  className?: string;
}

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  titleName,
  thumbnailUrl,
  isPoint,
  isNew,
  isOnlyOn,
  isLazy,
  className,
}) => {
  const intl = useIntl();
  return (
    <Container className={className}>
      {isOnlyOn && <OnlyOnBG />}
      {(isOnlyOn || isNew) && (
        <ThumbnailTopLeft>
          {isOnlyOn && (
            <OnlyOnLogoContainer>
              <UnextLogo />
            </OnlyOnLogoContainer>
          )}
          {isNew && (
            <RoundBadge badgeType="promotion" badgeSize="small">
              {intl.formatMessage(globalMessages.new)}
            </RoundBadge>
          )}
        </ThumbnailTopLeft>
      )}
      {isPoint && <PointIcon name={Icons.POINT} />}
      <AkamaiImage
        src={thumbnailUrl}
        availableSizes={[168, 336]}
        sizeConfig={{
          SD: {
            width: 282,
          },
          MOBILE_WIDE: {
            width: 168,
          },
        }}
        alt={titleName ?? ''}
        isLazy={isLazy}
      />
      {titleName && (
        <Shadow>
          <MetaInfoTitle>{titleName}</MetaInfoTitle>
        </Shadow>
      )}
    </Container>
  );
};

export default VideoThumbnail;
