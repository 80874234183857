import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Logo from '../../../assets/icons/logo/Logo_U-NEXT_Horizontal.svg';
import ABJ_Mark from '../../../assets/icons/logo_thirdparty_abj-mark.svg';
import L_Mark from '../../../assets/icons/logo_thirdparty_l-mark.svg';
import { DEVICE } from '../../styles';
import { COLORS } from '../../styles/theme';

const messages = defineMessages({
  lmarkMessage: {
    id: 'footer.lmark.message',
    defaultMessage:
      'このエルマークは、レコード会社・映像製作会社が提供するコンテンツを示す登録商標です。RIAJ70024001',
  },
  abjMessage: {
    id: 'footer.abj.message',
    defaultMessage:
      'ＡＢＪマークは、この電子書店・電子書籍配信サービスが、著作権者からコンテンツ使用許諾を得た正規版配信サービスであることを示す登録商標（登録番号第６０９１７１３号）です。詳しくは［ABJマーク］または［電子出版制作・流通協議会］で検索してください。',
  },
  careers: {
    id: 'footer.link.careers',
    defaultMessage: 'U-NEXT Careers',
  },
  corporate: {
    id: 'footer.link.corporate',
    defaultMessage: 'コーポレート',
  },
  unextSquare: {
    id: 'footer.link.unextSquare',
    defaultMessage: 'U-NEXT SQUARE',
  },
});

const FooterContainer = styled.div`
  display: flex;
  margin-top: 50px;
  background: ${COLORS.white_05};

  font-size: 12px;
  color: ${COLORS.white_50};

  @media ${DEVICE.mobileWide} {
    flex-direction: column;
  }
`;

const FooterMeta = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 56px;

  @media ${DEVICE.mobileWide} {
    padding: 24px;
  }
`;

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;

  &:not(:first-child) {
    margin-top: 40px;
  }
`;

const MetaItemIcon = styled.div`
  width: 58px;
  svg {
    height: 32px;
  }
`;

const MetaItemText = styled.div`
  flex: 1;
  padding-left: 16px;
  border-left: 1px solid ${COLORS.white_15};

  font-size: 12px;
`;

const FooterLogoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 256px;
  box-sizing: border-box;

  background-image: url('/images/footer_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  color: ${COLORS.white_75};
  font-size: 12px;

  svg {
    margin-bottom: 48px;
    width: 128px;
  }

  @media ${DEVICE.mobileWide} {
    width: 100%;
    height: 184px;
    background-image: url('/images/footer_bg_mobile.svg');
    svg {
      margin-bottom: 24px;
    }
  }
`;

const FooterLogoLinks = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  a {
    transition: color 0.15s ease-in;
    text-decoration-line: underline;
    flex-shrink: 0;
    &:hover {
      color: ${COLORS.white};
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterMeta>
        <MetaItem>
          <MetaItemIcon>
            <L_Mark />
          </MetaItemIcon>
          <MetaItemText>
            <FormattedMessage {...messages.lmarkMessage} />
          </MetaItemText>
        </MetaItem>
        <MetaItem>
          <MetaItemIcon>
            <ABJ_Mark />
          </MetaItemIcon>
          <MetaItemText>
            <FormattedMessage {...messages.abjMessage} />
          </MetaItemText>
        </MetaItem>
      </FooterMeta>
      <FooterLogoContainer>
        <Logo />
        <FooterLogoLinks>
          <a
            href="https://careers.unext.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage {...messages.careers} />
          </a>
          <a
            href="https://unext.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage {...messages.corporate} />
          </a>
          <a
            href="https://square.unext.jp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage {...messages.unextSquare} />
          </a>
        </FooterLogoLinks>
      </FooterLogoContainer>
    </FooterContainer>
  );
};

export default Footer;
