import { isThisYear, isToday } from 'date-fns';
import { IntlShape } from 'react-intl';
import { Maybe } from '../../../utils/Nullable';
import messages from './messages';

export function getBookLimitDayText(
  expiredDatetime: Maybe<string>,
  intl: IntlShape
) {
  if (!expiredDatetime) {
    return undefined;
  }

  const limitDayText = isToday(new Date(expiredDatetime))
    ? intl.formatMessage(messages.bookReadButtonSubTextToday, {
        dateTime: intl.formatDate(expiredDatetime, {
          hour: '2-digit',
          minute: '2-digit',
        }),
      })
    : isThisYear(new Date(expiredDatetime))
    ? intl.formatMessage(messages.bookReadButtonSubText, {
        dateTime: intl.formatDate(expiredDatetime, {
          month: 'long',
          day: 'numeric',
        }),
      })
    : intl.formatMessage(messages.bookReadButtonSubText, {
        dateTime: intl.formatDate(expiredDatetime, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      });

  return limitDayText;
}
