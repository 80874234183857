import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { COLORS } from '../../styles/theme';

export type LiveOnAirStatus = 'LIVE_NOW' | 'CATCHUP_NOW';

const messages = defineMessages({
  liveNow: {
    id: 'liveNow.liveNow',
    defaultMessage: 'ライブ配信中',
  },
  catchupNow: {
    id: 'liveNow.catchupNow',
    defaultMessage: '見逃し配信中',
  },
});

const Container = styled.div<{ iconLayout: 'left' | 'right' }>`
  display: inline-flex;
  align-items: center;

  ${({ iconLayout }) =>
    iconLayout === 'right'
      ? css`
          // Type A ( icon: right, size: small )
          flex-direction: row-reverse;
          gap: 2px;
          font-size: 10px;
        `
      : css`
          // Type B ( icon: left, size: big )
          flex-direction: row;
          gap: 4px;
          font-size: 12px;
        `};
`;

const IconContainer = styled.div`
  position: relative;
  height: 14px;
  width: 14px;
`;

const Icon = styled.div<{ active: boolean }>`
  position: absolute;
  background: ${COLORS.white_75};
  height: 7px;
  width: 7px;
  border-radius: 50%;
  left: calc(50% - 3.5px);
  top: calc(50% - 3.5px);

  ${({ active }) =>
    active &&
    css`
      background: ${COLORS.caution};
      &:after {
        content: '';
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: ${COLORS.caution};

        animation: pulsate 1.5s infinite;

        @keyframes pulsate {
          0% {
            opacity: 1;
            transform: scale(1);
          }

          100% {
            opacity: 0.2;
            transform: scale(2);
          }
        }
      }
    `}
`;

const LiveNow: React.FC<{
  active: boolean;
  iconLayout: 'left' | 'right';
  isCatchup?: boolean;
}> = ({ active, iconLayout, isCatchup = false }) => {
  const intl = useIntl();

  return (
    <Container iconLayout={iconLayout}>
      <IconContainer>
        <Icon active={active} />
      </IconContainer>
      <div>
        {intl.formatMessage(isCatchup ? messages.catchupNow : messages.liveNow)}
      </div>
    </Container>
  );
};

export default LiveNow;
