import { gql } from '@apollo/client';

// Fragment used for pageInfo
export const pageInfoFragment = gql`
  fragment PageInfo on PortalPageInfo {
    page
    pages
    pageSize
    results
  }
`;

export const pageInfoHashFragment = gql`
  fragment PageInfoHash on PageInfoHash {
    pageSize
    nextPageHash
  }
`;
