import React from 'react';
import styled from 'styled-components';
import { EditThumbnailOverlay } from '../../Edit';
import { LIST_VIEW_MOBILE_TITLE_CARD_SIZE } from '../constants';
import HorizontalContainer from './containers/HorizontalContainer';
import BookMetaInfoMobile from './MetaInfo/BookMetaInfoMobile';
import { BookMetaInfoProps } from './MetaInfo/utils';
import BookThumbnail, { ThumbnailProps } from './Thumbnail';

const MobileBookMetaInfoContainer = styled.div`
  width: 100%;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  overflow: hidden;
`;

const MobileBookThumbnailLayout = styled.div`
  --mobile-thumbnail-width: calc(
    100% * ${LIST_VIEW_MOBILE_TITLE_CARD_SIZE.book.imageWidth} /
      ${LIST_VIEW_MOBILE_TITLE_CARD_SIZE.book.width}
  );
  width: var(--mobile-thumbnail-width);
  flex-shrink: 0;
`;

type Props = ThumbnailProps & BookMetaInfoProps;

const ListBookCardMobile: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  bookType,
  numberOfFree,
  minimumPrice,
  hasMultiPrice,
  totalBookNum,
  author,
  publisher,
  bookMagazine,
  rate,
  isNew,
  isPreorderable,
  isDailyFree,
  isDailyFreePlus,
  isOnSale,
  isOriginal = false,
  isYomihodai,
  isChapter = false,
  hasUnreadChapter = false,
  isLazy = false,
  subgenreTagList = [],
  publicStartDateTime,
  isEpisodeSpecified,
}) => {
  const thumbnailProps = {
    thumbnailUrl,
    isLazy,
    titleName,
    // isNew, Do not show new/preorderable badge on Thumbnail for mobile list
    // isPreorderable,
  };

  const metaInfoProps = {
    titleName,
    bookType,
    numberOfFree,
    minimumPrice,
    hasMultiPrice,
    totalBookNum,
    author,
    publisher,
    bookMagazine,
    rate,
    isOnSale,
    isNew,
    isPreorderable,
    isDailyFree,
    isDailyFreePlus,
    isOriginal,
    isYomihodai,
    isChapter,
    hasUnreadChapter,
    subgenreTagList,
    publicStartDateTime,
    isEpisodeSpecified,
  };

  return (
    <HorizontalContainer>
      <MobileBookThumbnailLayout>
        <EditThumbnailOverlay>
          <BookThumbnail {...thumbnailProps} />
        </EditThumbnailOverlay>
      </MobileBookThumbnailLayout>

      <MobileBookMetaInfoContainer>
        <BookMetaInfoMobile {...metaInfoProps} />
      </MobileBookMetaInfoContainer>
    </HorizontalContainer>
  );
};

export default ListBookCardMobile;
