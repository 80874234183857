import React, { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { globalMessages } from '../../../constants/messages';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import Icon, { Icons } from '../Icon';

export const dailyFreeBadgeTypes = ['normal', 'plus'] as const;

export type badgeType = (typeof dailyFreeBadgeTypes)[number];

export const dailyFreeBadgeSizes = ['small', 'medium', 'large'] as const;
export type badgeSize = (typeof dailyFreeBadgeSizes)[number];

const PlusIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const getBadgeStyleForSize = (size?: badgeSize) => {
  switch (size) {
    case 'large':
      return css`
        --plus-icon-size: 20px;

        padding: 0 7px;
        font-size: 10px;
        line-height: 18px;
      `;
    case 'medium':
      return css`
        --plus-icon-size: 18px;

        padding: 0 6px;
        font-size: 9px;
        line-height: 16px;
      `;
    case 'small':
      return css`
        --plus-icon-size: 14px;

        padding: 0 4px;
        font-size: 9px;
        line-height: 12px;
      `;
  }
};
interface BadgeContainerProps {
  badgeType: badgeType;
  badgeSize?: badgeSize;
  mobileBadgeSize?: badgeSize;
  faded?: boolean;
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  --plus-icon-size: 18px;

  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: 600;
  border-radius: 100px;

  ${({ badgeSize }) => getBadgeStyleForSize(badgeSize || 'medium')};

  @media ${DEVICE.mobileWide} {
    ${({ mobileBadgeSize }) => getBadgeStyleForSize(mobileBadgeSize)};
  }

  ${PlusIcon} {
    width: var(--plus-icon-size);
  }

  ${({ badgeType, faded }) => {
    switch (badgeType) {
      case 'normal':
        return css`
          color: ${faded ? COLORS.stone : COLORS.white};
          background-color: ${faded ? COLORS.white_50 : COLORS.primary};
        `;
      case 'plus':
        return css`
          color: ${faded ? COLORS.white_50 : COLORS.primary};
          background-color: ${faded ? COLORS.stone : COLORS.white};
          &&& {
            padding-right: calc(var(--plus-icon-size) + 2px);
          }
        `;
    }
  }};
`;

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  bookNum?: number;
  componentName?: string;
}

export const DailyFreeBadge: React.FC<BadgeProps & BadgeContainerProps> = ({
  bookNum,
  componentName,
  ...props
}) => {
  const intl = useIntl();
  const text = bookNum
    ? intl.formatMessage(globalMessages.dailyFreeNumBadge, { count: bookNum })
    : intl.formatMessage(globalMessages.dailyFreeBadge);
  const isPlus = props.badgeType === 'plus';
  return (
    <BadgeContainer data-ucn={componentName} {...props}>
      {text}
      {isPlus && (
        <PlusIcon
          name={
            props.faded ? Icons.DAILY_FREE_PLUS_INACTIVE : Icons.DAILY_FREE_PLUS
          }
        />
      )}
    </BadgeContainer>
  );
};
