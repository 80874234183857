import React from 'react';
import { useIntl } from 'react-intl';
import { cosmo_bookTitleDetailQuery } from '../../../__generated__/globalTypes';
import { PreorderContextType } from '../../../shared/PreorderContext';
import * as bookTitleDetail from '../../Log/ComponentName/bookTitleDetail';
import CreditSection, { Credit } from '../shared/CreditSection';
import SignupPromotion from '../shared/SignupPromotion';
import BookEpisodeListSection from './BookEpisodeListSection';
import BookInfoSection from './BookInfoSection';
import BookRelatedSection from './BookRelatedSection';
import { BookUserButtonsGroupProps } from './BookUserButtonsGroup';
import ChapterRelatedSection from './ChapterRelatedSection';
import ComicStageSection from './ComicStageSection';
import { KeyArtBackdrop, TicketStatus } from './Components';
import messages from './messages';

type ComicDetailContentProps = {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
  credits: Credit[];
} & BookUserButtonsGroupProps &
  PreorderContextType;

const ComicDetailContent: React.FC<ComicDetailContentProps> = ({
  bookSakuhin,
  credits,
  onUpdateBookMark,
  onUpdateBookEvaluation,
  preorderStatus,
  updatePreorderStatus,
}) => {
  const intl = useIntl();
  const isChapter = !!bookSakuhin?.isChapter;
  const firstBook = bookSakuhin.bookList?.flatMap((item) =>
    item ? item : []
  )?.[0];

  return (
    <>
      <KeyArtBackdrop
        backgroundImageUrl={`//${bookSakuhin.thumbnail.standard}`}
      />
      <ComicStageSection
        bookSakuhin={bookSakuhin}
        onUpdateBookMark={onUpdateBookMark}
        onUpdateBookEvaluation={onUpdateBookEvaluation}
      />
      <SignupPromotion
        btnComponentName={bookTitleDetail.STAGE_SIGNUP}
        type={isChapter ? 'BOOK_CHAPTER' : 'BOOK'}
        urlOverwrite={
          isChapter && firstBook
            ? `/book/view/${bookSakuhin.sakuhinCode}/${firstBook.code}`
            : undefined
        }
      />
      <BookEpisodeListSection
        bookSakuhin={bookSakuhin}
        preorderStatus={preorderStatus}
        updatePreorderStatus={updatePreorderStatus}
        pagingStyle="none"
        variant="comicDetail"
      />
      <BookInfoSection bookSakuhin={bookSakuhin} />
      {credits.length > 0 && (
        <CreditSection
          credits={credits}
          sectionHeading={intl.formatMessage(messages.infoHeading)}
          castNameComponentName={bookTitleDetail.CAST_LIST_CASE_NAME}
        />
      )}
      {isChapter ? (
        <ChapterRelatedSection bookSakuhin={bookSakuhin} />
      ) : (
        <BookRelatedSection bookSakuhin={bookSakuhin} />
      )}
      {isChapter && bookSakuhin.bookTickets && (
        <TicketStatus {...bookSakuhin.bookTickets} />
      )}
    </>
  );
};

export default ComicDetailContent;
