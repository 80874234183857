import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import ComingSoonImage from '../../../../assets/placeholders/comingsoon.svg';
import { globalMessages } from '../../../../shared/constants/messages';
import {
  Container,
  FooterText,
  ImageContent,
  TextContent,
  Title,
} from './StackedItem/Components';

interface ComingSoonProps {
  nextUpdateDateTime: string;
}

const StyledComingSoon = styled(ComingSoonImage)`
  border-radius: 10px;
  overflow: hidden;
`;

const ComingSoon: React.FC<ComingSoonProps> = ({ nextUpdateDateTime }) => {
  const intl = useIntl();
  const updateTimeString = intl.formatDate(new Date(nextUpdateDateTime), {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <Container>
      <TextContent type="video">
        <Title>{intl.formatMessage(globalMessages.nextUpdate)}</Title>
        <FooterText>
          {intl.formatMessage(globalMessages.updateText, {
            updateText: updateTimeString,
          })}
        </FooterText>
      </TextContent>
      <ImageContent type="video">
        <StyledComingSoon />
      </ImageContent>
    </Container>
  );
};

export default ComingSoon;
