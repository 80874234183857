import React from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_bookCategoryListQuery,
  cosmo_bookCategoryListQueryVariables,
  cosmo_bookPersonListQuery,
  cosmo_bookPersonListQueryVariables,
  cosmo_bookTitleDetailQuery,
  cosmo_bookTitleDetailRelatedTitlesQuery,
  cosmo_bookTitleDetailRelatedTitlesQueryVariables,
  PortalSortOrder,
} from '../../../__generated__/globalTypes';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import * as bookTitleDetail from '../../Log/ComponentName/bookTitleDetail';
import RelatedSection from '../shared/RelatedWorkSection/RelatedSection';
import {
  GET_BOOK_CATEGORY_LIST,
  GET_BOOK_PERSON_LIST,
  GET_BOOK_RELATED_TITLES,
} from './gql';
import messages from './messages';

const MAX_BLOCKS_NUM = 3;

interface ChapterRelatedSectionProps {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
}

const ChapterRelatedSection: React.FC<ChapterRelatedSectionProps> = ({
  bookSakuhin,
}) => {
  const intl = useIntl();

  const menuCodeList = bookSakuhin.menuList
    .map(({ code }) => code)
    .slice(0, MAX_BLOCKS_NUM);
  const { data: categoryListData } = useClientQuery<
    cosmo_bookCategoryListQuery,
    cosmo_bookCategoryListQueryVariables
  >(GET_BOOK_CATEGORY_LIST, {
    variables: {
      menuCodeList,
      sortOrder: PortalSortOrder.POPULAR,
      pageSize: 20,
      page: 1,
      excludeBookSakuhinCode: bookSakuhin.sakuhinCode,
    },
    skip: menuCodeList.length === 0,
  });

  const personCodeList =
    bookSakuhin.book?.credits
      .map(({ personCode }) => personCode)
      .slice(0, MAX_BLOCKS_NUM)
      .flatMap((x) => (x ? [x] : [])) || [];
  const { data: personListData } = useClientQuery<
    cosmo_bookPersonListQuery,
    cosmo_bookPersonListQueryVariables
  >(GET_BOOK_PERSON_LIST, {
    variables: {
      personCodeList,
      sortOrder: PortalSortOrder.POPULAR,
      pageSize: 20,
      page: 1,
      excludeBookSakuhinCode: bookSakuhin.sakuhinCode,
      onlyChapter: true,
    },
    skip: personCodeList.length === 0,
  });

  const { data: relatedTitlesData } = useClientQuery<
    cosmo_bookTitleDetailRelatedTitlesQuery,
    cosmo_bookTitleDetailRelatedTitlesQueryVariables
  >(GET_BOOK_RELATED_TITLES, {
    variables: {
      bookSakuhinCode: bookSakuhin.sakuhinCode,
    },
  });

  const relatedTitles = relatedTitlesData?.bookTitle_relatedTitles;
  const totalTitleCount = relatedTitles?.pageInfo?.results || 0;
  const hasRelatedTitles = totalTitleCount > 0;

  return (
    <>
      {categoryListData &&
        categoryListData.bookCategoryList.map((cat) =>
          cat.books.length > 0 ? (
            <RelatedSection
              sectionName="bookCategory"
              key={cat.menuCode}
              sourceBookSakuhinCode={bookSakuhin.sakuhinCode}
              sourceBookCode={bookSakuhin.book?.code ?? ''}
              sectionHeading={intl.formatMessage(messages.categoryHeading, {
                categoryName:
                  bookSakuhin.menuList.find(({ code }) => code === cat.menuCode)
                    ?.name ?? '',
              })}
              contentType="chapter"
              items={cat.books}
              totalItemCount={cat.pageInfo.results}
              itemComponentName={
                bookTitleDetail.RECOMMENDED_BOOK_TITLE_LIST_TITLE_CARD
              }
              showMoreLinkProps={{
                href: `/book/browse/related_category/${bookSakuhin.sakuhinCode}/${cat.menuCode}`,
              }}
              trackingBlockCode="" // Send empty string until the API supports it
            />
          ) : null
        )}
      {personListData &&
        personListData.bookPersonList.map((per) =>
          per.books.length > 0 ? (
            <RelatedSection
              sectionName="bookPerson"
              key={per.personCode}
              sourceBookSakuhinCode={bookSakuhin.sakuhinCode}
              sourceBookCode={bookSakuhin.book?.code ?? ''}
              sectionHeading={intl.formatMessage(messages.personHeading, {
                personName:
                  bookSakuhin.book?.credits.find(
                    ({ personCode }) => personCode === per.personCode
                  )?.penName ?? '',
              })}
              contentType="chapter"
              items={per.books}
              totalItemCount={per.pageInfo.results}
              itemComponentName={
                bookTitleDetail.RECOMMENDED_BOOK_TITLE_LIST_TITLE_CARD
              }
              showMoreLinkProps={{
                href: `/book/browse/related_credit/${bookSakuhin.sakuhinCode}/${per.personCode}`,
              }}
              trackingBlockCode="" // Send empty string until the API supports it
            />
          ) : null
        )}
      {hasRelatedTitles && relatedTitles?.titles && (
        <RelatedSection
          sectionName="relatedVideo"
          sectionHeading={intl.formatMessage(messages.relatedVideosHeading)}
          contentType="video"
          items={relatedTitles.titles}
          totalItemCount={totalTitleCount}
          itemComponentName={
            bookTitleDetail.RELATED_VIDEO_TITLE_LIST_TITLE_CARD
          }
          showMoreLinkProps={{
            href: `/book/browse/relation/${bookSakuhin.sakuhinCode}/video`,
          }}
        />
      )}
    </>
  );
};

export default ChapterRelatedSection;
