import throttle from 'just-throttle';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { isActivePath } from '../../utils/routeHelper';

const CHECK_THROTTLE = 5 * 60 * 1000;
const playerViewerRoutes = [
  /^\/play\//,
  /^\/live\/[^/]*$/,
  /^\/realtime\//,
  /^\/book\/view/,
];

interface UseNewVersionCheckerArg {
  checkThrottle?: number;
  onNewVersionAvailable: () => void;
}

const useNewVersionChecker = ({
  checkThrottle = CHECK_THROTTLE,
  onNewVersionAvailable,
}: UseNewVersionCheckerArg): void => {
  const router = useRouter();
  const eTagRef = useRef<string | null>();

  const checkIfModified = useCallback(() => {
    const isExceptionalPath = playerViewerRoutes.some((routeRegexp) =>
      isActivePath(router, routeRegexp)
    );

    if (!isExceptionalPath) {
      fetch('/static/internal/blank.html', {
        method: 'HEAD',
      }).then((response) => {
        // we can rely on ETag here because nginx calculates it from last modified time and content length
        // even if content is not modified, eTag will be changed everytime we deploy
        const freshETag = response.headers.get('ETag');
        if (!eTagRef.current) {
          eTagRef.current = freshETag;
        } else if (eTagRef.current !== freshETag) {
          eTagRef.current = freshETag;
          onNewVersionAvailable();
        }
      });
    }
  }, [onNewVersionAvailable, router]);

  const checkIfModifiedThrottled = useMemo(() => {
    return throttle(checkIfModified, checkThrottle, {
      leading: true,
      trailing: false,
    });
  }, [checkIfModified, checkThrottle]);

  useEffect(() => {
    window.addEventListener('focus', checkIfModifiedThrottled);
    router.events.on('routeChangeComplete', checkIfModifiedThrottled);
    checkIfModifiedThrottled();

    return () => {
      window.removeEventListener('focus', checkIfModifiedThrottled);
      router.events.off('routeChangeComplete', checkIfModifiedThrottled);
    };
  }, [checkIfModifiedThrottled, router.events]);
};

export default useNewVersionChecker;
