import { createGlobalStyle } from 'styled-components';
import { DEVICE, FONT_FAMILY } from './constants';
import theme from './theme';

const GlobalStyle = createGlobalStyle<{ alwaysShowScrollbar?: boolean }>`
  html {
    font-family: ${FONT_FAMILY.GLOBAL};
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
    padding: 0;
  }

  body {
    background: ${theme.background.default};
    color: ${theme.text.standard};
    margin: 0;
    padding: 0;
    ${({ alwaysShowScrollbar }) =>
      alwaysShowScrollbar ? 'overflow-y: scroll;' : ''}
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  button {
    font-family: inherit;
    font-size: 1em;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;

    &:disabled{
      cursor: unset;
    }
  }

  .react-toast-notifications__container{
    z-index: 100000 !important; //should be above title-detail modal
    padding: 24px !important;
    max-width: 360px;

    @media ${DEVICE.mobile} {
      max-width: unset;
      padding: 16px !important;
      width: 100%;
    }
  }
`;

export default GlobalStyle;
