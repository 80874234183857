import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultTitle: {
    id: 'global.defaultTitle',
    defaultMessage:
      'U-NEXT（ユーネクスト）-映画 / ドラマ / アニメから、マンガや雑誌といった電子書籍まで-│31日間無料トライアル',
    description: 'Global default title',
  },
  defaultDescription: {
    id: 'global.defaultDescription',
    defaultMessage:
      '<31日間無料トライアル実施中>映画/ドラマ/アニメ/電子書籍を観るならU-NEXT（ユーネクスト）。名作はもちろん最新作も！豊富な作品の中からお好きな動画を見つけて、是非お楽しみください。',
    description: 'Global default description',
  },
  defaultKeywords: {
    id: 'global.defaultKeywords',
    defaultMessage: 'U-NEXT,ユーネクスト,オンラインレンタル',
    description: 'Global default keywords',
  },
  siteName: {
    id: 'global.siteName',
    defaultMessage: 'U-NEXT＜ユーネクスト＞',
    description: 'Global site name',
  },
});

export default messages;
