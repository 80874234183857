export const HOLDER_LOGOS: Record<string, string> = {
  hol0001512: 'https://imgc.nxtv.jp/img/info/wf/holder/hol0001512.png', // TBSオンデマンド
  hol0001095: 'https://imgc.nxtv.jp/img/info/wf/holder/hol0001095.png', // フジテレビオンデマンド
  hol0001691: 'https://imgc.nxtv.jp/img/info/wf/holder/hol0001691.png', // フジテレビオンデマンド
  hol0000932: 'https://imgc.nxtv.jp/img/info/wf/holder/hol0000932.png', // 有限責任事業組合 Ｖ.Ｆ.パートナーズ(日テレオンデマンド)
} as const;

// https://wiki.unext-info.jp/pages/viewpage.action?pageId=132096262
export enum PLAYBACK_STATUS_FLAG {
  NORMAL = 0,
  CHANGE_EPISODE = 1,
  CONTINUE_WATCHING = 2,
  AUTO_PLAY = 3,
  POST_PLAY = 4,
  SERIES_RELATED_WORK = 6,
  NEXT_EPISODE = 7,
  CONTINUE_FROM_HISTORY = 8,
}

/**
 * ユーザーが180分間操作しなかった場合、次話連続再生を停止する
 *
 * specs: https://wiki.unext-info.jp/pages/viewpage.action?pageId=67621898
 */
export const DISABLE_AUTO_PALY_INACTIVE_DURATION = 10800000;
