import styled, { css } from 'styled-components';
import { cosmo_bookTitleDetailQuery } from '../../../__generated__/globalTypes';
import { DEVICE } from '../../../shared/styles';

import { isToday } from 'date-fns';
import ReactDOM from 'react-dom';
import { defineMessages, useIntl } from 'react-intl';
import { DailyFreeBadge } from '../../../shared/components/Common/Badge/DaillyFreeBadge';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import {
  FLOATING_FOOTER_ID,
  FOOTER_ITEMS_ORDER,
} from '../../../shared/components/Layout';
import { DAILY_FREE_HELP_URL } from '../../../shared/constants';
import useElementById from '../../../shared/hooks/useElementById';
import { useUserInfo } from '../../../shared/hooks/useUserInfo';
import { COLORS } from '../../../shared/styles/theme';
interface KeyArtBackdropProps {
  backgroundImageUrl?: string;
}

export const KeyArtBackdrop = styled.div<KeyArtBackdropProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 420px;
  ${({ theme, backgroundImageUrl }) => css`
    background: linear-gradient(
        -180deg,
        ${theme.titleDetail.keyArt.gradient_40} 0%,
        ${theme.titleDetail.keyArt.gradient_60} 27%,
        ${theme.titleDetail.keyArt.gradient_80} 52%,
        ${theme.titleDetail.keyArt.gradient_90} 78%,
        ${theme.titleDetail.keyArt.gradient_100} 100%
      ),
      url(${backgroundImageUrl}?output-format=jpg&output-quality=70&resize=800:*)
        0 30% / cover;
  `}
  filter: blur(35px);

  @media ${DEVICE.mobileWide} {
    height: 248px;
  }
`;

const TicketStatusContainer = styled.div`
  order: ${FOOTER_ITEMS_ORDER.TICKET_STATUS};
  display: flex;
  align-items: center;
  width: 640px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};
  background: ${COLORS.white_20};
  flex-shrink: 0;
  backdrop-filter: blur(12px);

  > * {
    align-self: stretch;
  }

  > *:not(:first-child) {
    border-left: 1px solid ${COLORS.ink};
  }

  @media ${DEVICE.mobileWide} {
    width: unset;
    margin: 0 12px;
  }
`;

const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex: 1;
  padding: 15px 0;
  max-height: 56px;

  color: ${COLORS.white_50};
  font-size: 14px;
  font-weight: 600;

  > a {
    transition: color 200ms ease-in;
    &:hover {
      color: ${COLORS.white};
    }
  }

  @media ${DEVICE.mobileWide} {
    gap: 2px;
    padding: 10px 0;
    max-height: 40px;
    font-size: 10px;
  }
`;

const Ticket = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media ${DEVICE.mobileWide} {
    gap: 2px;
  }
`;

const TicketCount = styled.div<{ faded: boolean }>`
  font-size: 12px;
  font-weight: 600;
  color: ${({ faded }) => (faded ? COLORS.white_50 : COLORS.white)};

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

const TicketDelivery = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.white};

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

const InfoIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  transition: color 200ms ease-in;
  &:hover {
    color: ${COLORS.white_60};
  }
`;

const TicketInfo = styled.div`
  display: flex;
  width: 48px;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  max-height: 56px;

  > a {
    display: flex;
  }
  @media ${DEVICE.mobileWide} {
    padding: 4px 0;
  }
`;

const ticketStatusMessages = defineMessages({
  guest: {
    id: 'ticket.status.guest',
    defaultMessage: '無料会員登録してマンガを読む',
  },
  ineligible: {
    id: 'ticket.status.ineligible',
    defaultMessage: '毎日無料対象話はありません',
  },
  availableCount: {
    id: 'ticket.status.availableCount',
    defaultMessage: ': {count}話',
  },
  deliveryToday: {
    id: 'ticket.status.deliveryToday',
    defaultMessage: '本日{time}に付与',
  },
  deliveryTomorrow: {
    id: 'ticket.status.deliveryTomorrow',
    defaultMessage: '明日{time}に付与',
  },
});

export const TicketStatus: React.FC<
  NonNullable<cosmo_bookTitleDetailQuery['bookTitle']['bookTickets']>
> = (props) => {
  const intl = useIntl();
  const { data: userInfoData } = useUserInfo();
  const isLoggedIn = !!userInfoData?.userInfo?.id;
  const footerContainer = useElementById(FLOATING_FOOTER_ID);

  const ticketList = [props.sakuhinTicket, props.plusTicket].flatMap((item) =>
    item ? item : []
  );
  return (
    <>
      {footerContainer &&
        ReactDOM.createPortal(
          <TicketStatusContainer>
            {!isLoggedIn ? (
              <TicketContainer>
                <a href={DAILY_FREE_HELP_URL}>
                  {intl.formatMessage(ticketStatusMessages.guest)}
                </a>
              </TicketContainer>
            ) : ticketList.length === 0 ? (
              <TicketContainer>
                {intl.formatMessage(ticketStatusMessages.ineligible)}
              </TicketContainer>
            ) : (
              ticketList.map((ticket) => {
                const hasAvailableTicket = ticket.availableCount > 0;

                return (
                  <TicketContainer key={ticket.code}>
                    <Ticket>
                      <DailyFreeBadge
                        faded={!hasAvailableTicket}
                        badgeSize="medium"
                        mobileBadgeSize="small"
                        badgeType={
                          ticket.typeCode === 'B_SAKUHIN' ? 'normal' : 'plus'
                        }
                      />
                      <TicketCount faded={!hasAvailableTicket}>
                        {intl.formatMessage(
                          ticketStatusMessages.availableCount,
                          {
                            count: ticket.availableCount,
                          }
                        )}
                      </TicketCount>
                    </Ticket>
                    {!hasAvailableTicket && ticket.nextDeliveryDatetime && (
                      <TicketDelivery>
                        {intl.formatMessage(
                          isToday(new Date(ticket.nextDeliveryDatetime))
                            ? ticketStatusMessages.deliveryToday
                            : ticketStatusMessages.deliveryTomorrow,
                          {
                            time: intl.formatDate(ticket.nextDeliveryDatetime, {
                              hour: 'numeric',
                              minute: 'numeric',
                            }),
                          }
                        )}
                      </TicketDelivery>
                    )}
                  </TicketContainer>
                );
              })
            )}
            <TicketInfo>
              <a
                href={DAILY_FREE_HELP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InfoIcon name={Icons.HELP} />
              </a>
            </TicketInfo>
          </TicketStatusContainer>,
          footerContainer
        )}
    </>
  );
};
