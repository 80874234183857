import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  passwordResetWarningTitle: {
    id: 'genre.warning.passwordReset.title',
    defaultMessage: 'このアカウントのパスワードは強制的にリセットされました。',
  },
  passwordResetWarningMessage: {
    id: 'genre.warning.passwordReset.message',
    defaultMessage:
      '第三者と思われる不正なログイン操作を検知したため、アカウントを保護する理由で、パスワードを強制的にリセットし、利用を制限しています。再開には、パスワードの再設定が必要です。また、ご登録されているメールアドレスのパスワードも合わせて変更することを強くお勧めします。（1）',
  },
  passwordResetWarningButtonText: {
    id: 'genre.warning.passwordReset.button',
    defaultMessage: 'ログインID/パスワード変更',
  },
  unusualUseWarningTitle: {
    id: 'genre.warning.unusualUse.title',
    defaultMessage: 'このアカウントは、不正利用を防ぐため、停止されています。',
  },
  unusualUseWarningMessage: {
    id: 'genre.warning.unusualUse.message',
    defaultMessage:
      '第三者と思われる不正なログインでの異常利用を検知したため、危険な状態からアカウントを保護する理由で、アカウントの利用を一時的に停止しています。今後の手続きのため、速やかにカスタマーサポートへお問い合わせください。（2）',
  },
  unusualUseWarningButtonText: {
    id: 'genre.warning.unusualUse.button',
    defaultMessage: 'お問い合わせ',
  },
  accountLockWarningTitle: {
    id: 'genre.warning.accountLock.title',
    defaultMessage: 'このアカウントは、不正利用を防ぐため、停止されています。',
  },
  accountLockWarningMessage: {
    id: 'genre.warning.accountLock.message',
    defaultMessage:
      'アカウントを保護する理由で、アカウントの利用を一時的に停止しています。今後の手続きのため、速やかにカスタマーサポートへお問い合わせください。（3）',
  },
  accountLockWarningButtonText: {
    id: 'genre.warning.accountLock.button',
    defaultMessage: 'お問い合わせ',
  },
  emailWarningTitle: {
    id: 'genre.warning.email.title',
    defaultMessage: 'メールアドレスの確認',
  },
  emailWarningMessage: {
    id: 'genre.warning.email.message',
    defaultMessage:
      'お客様のメールアドレスはまだ設定されていません。\nメールアドレスをご登録いただきID/パスワードを設定すると、様々なデバイスでログインし、お楽しみいただくことが出来るようになります。\nまたログインID/パスワードを忘れた場合に、再発行が行えるようになります。',
  },
  emailWarningButtonText: {
    id: 'genre.warning.email.button',
    defaultMessage: 'メールアドレスを設定',
  },
  characterBlockHeading: {
    id: 'genre.character.blockHeading',
    defaultMessage: 'にんきキャラクター',
  },
  homeRecommendedLives: {
    id: 'genre.live.homeRecommended',
    defaultMessage: 'ライブ配信',
  },
  genreRecommendedLives: {
    id: 'genre.live.genreRecommended',
    defaultMessage: 'ライブ配信',
  },
  genreRecommendedChannels: {
    id: 'genre.channel.genreRecommended',
    defaultMessage: 'リアルタイム配信',
  },
  videoUpsellNoticeText: {
    id: 'genre.video.upsell.notice.text',
    defaultMessage: '初回は31日間無料トライアル',
  },
  videoUpsellNoticeButtonText: {
    id: 'genre.video.upsell.notice.buttonText',
    defaultMessage: '月額プランに申し込む',
  },
  freeComicBannerText: {
    id: 'genre.book.freeComicBannerText',
    defaultMessage: 'なら1日1話無料で読める！',
  },
});

export const genreTitles = {
  YOUGA: defineMessages({
    title: {
      id: 'genreTitles.youga.title',
      defaultMessage: '洋画',
    },
  }),
  HOUGA: defineMessages({
    title: {
      id: 'genreTitles.houga.title',
      defaultMessage: '邦画',
    },
  }),
  FDRAMA: defineMessages({
    title: {
      id: 'genreTitles.fdrama.title',
      defaultMessage: '海外ドラマ',
    },
  }),
  ADRAMA: defineMessages({
    title: {
      id: 'genreTitles.adrama.title',
      defaultMessage: '韓流・アジア',
    },
  }),
  DRAMA: defineMessages({
    title: {
      id: 'genreTitles.drama.title',
      defaultMessage: '国内ドラマ',
    },
  }),
  ANIME: defineMessages({
    title: {
      id: 'genreTitles.anime.title',
      defaultMessage: 'アニメ',
    },
  }),
  KIDS: defineMessages({
    title: {
      id: 'genreTitles.kids.title',
      defaultMessage: 'キッズ',
    },
  }),
  VARIETY: defineMessages({
    title: {
      id: 'genreTitles.variety.title',
      defaultMessage: 'TV番組・エンタメ',
    },
  }),
  DOCUMENT: defineMessages({
    title: {
      id: 'genreTitles.document.title',
      defaultMessage: '報道・スペシャル',
    },
  }),
  MUSIC_IDOL: defineMessages({
    title: {
      id: 'genreTitles.musicIdol.title',
      defaultMessage: '音楽・ライブ',
    },
  }),
  ADULT: defineMessages({
    title: {
      id: 'genreTitles.adult.title',
      defaultMessage: 'その他♡',
    },
  }),
  STAGE_PLAY: defineMessages({
    title: {
      id: 'genreTitles.stagePlay.title',
      defaultMessage: '舞台・演劇',
    },
  }),
  SOCCER: defineMessages({
    title: {
      id: 'genreTitles.soccer.title',
      defaultMessage: 'サッカー',
    },
  }),
  GOLF: defineMessages({
    title: {
      id: 'genreTitles.golf.title',
      defaultMessage: 'ゴルフ',
    },
  }),
  MARTIAL: defineMessages({
    title: {
      id: 'genreTitles.martial.title',
      defaultMessage: '格闘技',
    },
  }),
  OTH_SPORTS: defineMessages({
    title: {
      id: 'genreTitles.otherSports.title',
      defaultMessage: '野球・スポーツ',
    },
  }),
};

export const bookGenreTitles = {
  KIDS_BOOK: defineMessages({
    title: {
      id: 'bookGenreTitles.kidsComic.title',
      defaultMessage: 'キッズ読み放題',
    },
  }),
  FREE_COMIC: defineMessages({
    title: {
      id: 'bookGenreTitles.freecomic.title',
      defaultMessage: '無料マンガ',
    },
  }),
  MAGAZINE: defineMessages({
    title: {
      id: 'bookGenreTitles.magazine.title',
      defaultMessage: '雑誌',
    },
  }),
  COMIC: defineMessages({
    title: {
      id: 'bookGenreTitles.comic.title',
      defaultMessage: 'マンガ',
    },
  }),
  RANOBE: defineMessages({
    title: {
      id: 'bookGenreTitles.ranobe.title',
      defaultMessage: 'ラノベ',
    },
  }),
  BOOK: defineMessages({
    title: {
      id: 'bookGenreTitles.book.title',
      defaultMessage: '書籍',
    },
  }),
  PHOTO_BOOK: defineMessages({
    title: {
      id: 'bookGenreTitles.photobook.title',
      defaultMessage: '写真集',
    },
  }),
  PICBOOK: defineMessages({
    title: {
      id: 'bookGenreTitles.picbook.title',
      defaultMessage: '絵本',
    },
  }),
};

export const isValidKeyForGenreTitles = (
  key?: string
): key is keyof typeof genreTitles =>
  !!key && Object.keys(genreTitles).includes(key);
