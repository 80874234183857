/**
 * 配列の要素をjoinCharacterで結合し、maxLengthに収まるよう整形
 * ex.
 * 「ニュージーランド」→「ニュージーラ…」
 * 「アイルランド / イギリス / アメリカ」→「アイルランド…」
 * 「中国 / フランス / アメリカ / 日本 」→「中国・フランス…」
 * 「日本 / ニュージーランド / 韓国」 → 「日本…」
 *
 * @param array Items to join
 * @param joinCharacter Character to join with
 * @param maxLength Max character length
 */
export const joinWithClipping = (
  array: string[],
  joinCharacter: string,
  maxLength: number
): string => {
  let text = array[0];
  let isClipped = false;
  if (text.length > maxLength) {
    text = text.substr(0, maxLength - 1);
    isClipped = true;
  } else {
    text = array.reduce((currentText, currentElement) => {
      if (currentText.length + currentElement.length + 1 > maxLength) {
        isClipped = true;
        return currentText;
      }
      return isClipped
        ? currentText
        : `${currentText}${joinCharacter}${currentElement}`;
    });
  }
  if (isClipped) text = `${text}…`;
  return text;
};
