import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { useEffect, type ReactNode } from 'react';
import { isExtendedApolloError } from '../../ExtendedApolloError';
import ErrorComponent, { type ErrorComponentProps } from '../Error';

const ErrorComponentWithRouteReset = (
  props: ErrorComponentProps & {
    resetError: () => void;
  }
) => {
  const { resetError, ...resetProps } = props;
  useEffect(() => {
    const handleRouteChange = () => {
      resetError();
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [resetError]);

  return <ErrorComponent {...resetProps} />;
};

const Stub = ({ children }: { children: ReactNode }) => children;

const ErrorBoundary = Sentry.withErrorBoundary(Stub, {
  fallback: ({ error, resetError }) => {
    if (isExtendedApolloError(error) && !error.withoutUI) {
      return (
        <ErrorComponentWithRouteReset
          errorTitle={error.customTitle}
          errorMessage={error.customMessage}
          errorCode={error.errorCode}
          resetError={resetError}
        />
      );
    }
    return <ErrorComponentWithRouteReset resetError={resetError} />;
  },
});

export default ErrorBoundary;
