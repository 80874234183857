import Link from 'next/link';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Logo from '../../../../assets/icons/logo/Logo_U-NEXT_Horizontal.svg';
import { ButtonLookDiv } from '../../../../shared/components/Common/Button';
import { FSOCCER_PACK_LP, GlobalConfig } from '../../../../shared/constants';
import { useUserInfo } from '../../../../shared/hooks/useUserInfo';
import { DEVICE } from '../../../../shared/styles';
import { COLORS } from '../../../../shared/styles/theme';
import UserAgentContext from '../../../../shared/UserAgentContext';
import { messages } from '../messages';
import { sectionStyle } from '../mixins';

const SignupPromotionText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 32px;
  font-size: 13px;
  white-space: pre-wrap;
  color: ${COLORS.white_75};
  border-left: 1px solid ${COLORS.white_15};

  @media ${DEVICE.tablet} {
    padding: 0 0 0 16px;
  }

  @media ${DEVICE.mobileWide} {
    height: 32px;
    font-size: 10px;
  }
`;

const LogoTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media ${DEVICE.tablet} {
    gap: 16px;
  }
`;

const SignupPromotionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  ${sectionStyle};
  background: ${COLORS.charcoal};

  @media ${DEVICE.tablet} {
    gap: 12px;
  }

  @media ${DEVICE.mobileWide} {
    flex-direction: column;
    gap: 12px;
  }
`;

const StyledLogo = styled(Logo)`
  flex-shrink: 0;
  width: 115px;

  @media ${DEVICE.tablet} {
    width: 90px;
  }
`;

const StyledLink = styled(Link)`
  flex-grow: 1;
  > div {
    width: 100%;
    max-width: 370px;
  }
  @media ${DEVICE.mobileWide} {
    width: 100%;
    > div {
      max-width: unset;
    }
  }
`;

interface SignupPromotionProps {
  btnComponentName?: string;
  type: 'GENERAL' | 'BOOK' | 'BOOK_CHAPTER' | 'FSOCCER';
  urlOverwrite?: string;
}

const SignupPromotion: React.FC<SignupPromotionProps> = ({
  btnComponentName,
  type,
  urlOverwrite,
}) => {
  const intl = useIntl();
  const { data: userInfoData, loading: userInfoLoading } = useUserInfo({
    fetchPolicy: 'cache-first',
  });
  const userInfo = userInfoData?.userInfo;
  const { hasUserCookie } = useContext(UserAgentContext);
  const isLoggedIn = !!((!userInfoLoading && !!userInfo?.id) || hasUserCookie);

  if (isLoggedIn) return null;

  let url = `${GlobalConfig.URLS.STATIC}/welcome${
    userInfo?.isAeon ? '/aeon' : ''
  }`;
  let promotionMessage = messages.signupPromotionText;
  let buttonMessage = messages.signupPromotionButtonText;

  switch (type) {
    case 'BOOK':
      buttonMessage = messages.bookSignupPromotionButtonText;
      promotionMessage = messages.bookSignupPromotionText;
      break;

    case 'BOOK_CHAPTER':
      buttonMessage = messages.bookChapterSignupPromotionButtonText;
      promotionMessage = messages.bookChapterSignupPromotionText;
      break;

    case 'FSOCCER':
      url = FSOCCER_PACK_LP;
      buttonMessage = messages.watchNow;
      promotionMessage = messages.soccerPromotionText;
      break;

    case 'GENERAL':
    default:
      break;
  }

  return (
    <SignupPromotionContainer>
      <LogoTextContainer>
        <StyledLogo />
        <SignupPromotionText>
          {intl.formatMessage(promotionMessage)}
        </SignupPromotionText>
      </LogoTextContainer>
      <StyledLink data-ucn={btnComponentName} href={urlOverwrite || url}>
        <ButtonLookDiv
          buttonSize="large"
          buttonTheme="attention"
          text={intl.formatMessage(buttonMessage)}
        />
      </StyledLink>
    </SignupPromotionContainer>
  );
};

export default SignupPromotion;
