import fetch from 'isomorphic-unfetch';
import { GlobalConfig } from '../../shared/constants';
import type { Nullable } from '../../utils/Nullable';
import { TITLE_SPOT } from './ComponentName/liveTitleDetail';
import {
  createDeviceInformation,
  DeviceInformation,
} from './DeviceInformation';
import type { InitialLog } from './__types__/initial';
import type { LogBody } from './__types__/logBody';
import type { SystemCdnPerfLog } from './__types__/system_cdn_perf';

const API_ENDPOINT = 'https://ka.unext.jp/topics/';
const API_KEY = 'A2kXQBT&PgkrnWo7';

export const LEANBACK_GENRE_CODE = {
  HOME: 'home',
  VIDEO_TOP: 'video_domain_top',
  BOOK_TOP: 'book_domain_top',
};

// This value is embedded on the build phase by next.js. This must not be undefined.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const RELEASE_VERSION: string = process.env.RELEASE_VERSION!;

// This value is embedded on the build phase by next.js. This must not be undefined.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const KAFKA_LOG_VERSION: string = process.env.KAFKA_LOG_VERSION!;

interface KafkaUserInfo {
  service_platform: LogBody['key']['service_platform'];
  user_platform_id: string;
  user_multi_account_id: string;
  super_user_flg: 0 | 1;
}

type DataType = LogBody | InitialLog | SystemCdnPerfLog;
type GetDataFn = () => DataType;

type ClickD0LogBody = Extract<
  LogBody,
  { event: { base_schema: 'user_click_dimension_0_default' } }
>;
type ClickD1LogBody = Extract<
  LogBody,
  { event: { base_schema: 'user_click_dimension_1_default' } }
>;
type ClickD2LogBody = Extract<
  LogBody,
  { event: { base_schema: 'user_click_dimension_2_default' } }
>;
type ViewLogBody = Extract<
  LogBody,
  { event: { base_schema: 'user_view_page_default' } }
>;

function sendLogRequest<T>(data: T): Promise<Response> {
  return fetch(`${API_ENDPOINT}${GlobalConfig.KAFKA_FRONT_LOG_TOPIC}`, {
    method: 'POST',
    headers: {
      'x-ka-api-key': API_KEY,
    },
    body: JSON.stringify(data),
  });
}

export class KafkaClient {
  private _sendQueue: GetDataFn[];
  private _device: DeviceInformation;
  // FIXME: This should be initialized properly on the initilize this object.
  private _userInfo: Nullable<KafkaUserInfo>;
  // The rationale for _isReady is:
  // 1. UserInfo & device id are retrieved asynchronously
  // 2. Kafka log timing may happen earlier than when userInfo or device id is available,
  //    as result it'd be necessary to queue the logs before the userInfo & device id are ready
  // 3. Checking the validity of userInfo to judge if logs are ready to be sent can be error prone
  //    cuz only the code consuming the kafkaClient knows if the userInfo is valid or not
  private _isReady: boolean;

  constructor() {
    this._sendQueue = [];
    const device = createDeviceInformation(window.navigator.userAgent);
    this._device = device;
    this._userInfo = null;
    this._isReady = false;
  }

  setDeviceId(id: string): void {
    this._device.setId(id);
  }

  setUserInfo(userInfo: KafkaUserInfo): void {
    this._userInfo = userInfo;
  }

  private _getKey(): LogBody['key'] {
    // We assume this line would not be null because we only enter here after setting userInfo properly.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userInfo = this._userInfo!;

    return {
      service_platform: userInfo.service_platform || 'unext',
      client: 'web',
      user_multi_account_id: userInfo.user_multi_account_id || '',
      device_id: this._device.id,
    };
  }

  setIsReady(isReady: boolean): void {
    this._isReady = isReady;

    if (isReady) {
      this._fireQueue();
    }
  }

  private _fireQueue(): void {
    const sendQueue = this._sendQueue;
    if (sendQueue.length && this._isReady) {
      while (sendQueue.length) {
        const getter = sendQueue.shift();
        if (typeof getter !== 'function') {
          // eslint-disable-next-line no-console
          console.error(
            `This sendCallback must be function but not. Some part to enqueue functions for this may has bug`
          );
          continue;
        }

        const data: DataType = getter();
        // We will not catch in the caller if this promise is rejected.
        // So we should catch at here.
        // eslint-disable-next-line no-console
        sendLogRequest(data).catch(console.error);
      }
    }
  }

  // https://wiki.unext-info.jp/pages/viewpage.action?pageId=35425828
  private async _send(getData: GetDataFn): Promise<void> {
    // userInfo や device id がまだ取得できていない場合、キューに入れる
    if (!this._isReady) {
      this._sendQueue.push(getData);
      return;
    }

    const data: DataType = getData();
    // We will not catch in the caller if this promise is rejected.
    // So we should catch at here.
    try {
      await sendLogRequest(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  trackInitial(experiment: string): void {
    const client_timestamp = Date.now();
    const getInitialData: () => InitialLog = () => {
      // We assume this line would not be null because we only enter here after setting userInfo properly.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const userInfo = this._userInfo!;

      const device = this._device;

      const r = {
        key: this._getKey(),
        session: {
          experiment,
          user_platform_id: userInfo.user_platform_id,
          super_user_flg: userInfo.super_user_flg ?? 0,
        },
        device: {
          app_version: RELEASE_VERSION,
          os: device.os,
          os_version: device.os_version,
          user_agent: device.user_agent,
          viewport_h: window.innerHeight,
          viewport_w: window.innerWidth,
          model_name: '',
          browser_name: device.browser_name,
          browser_version: device.browser_version,
        },
        version: KAFKA_LOG_VERSION as InitialLog['version'],
        client_timestamp,
      };
      return r;
    };

    this._send(getInitialData);
  }

  async trackUserClickDimension0<T extends ClickD0LogBody>(
    target: T['event']['target'],
    detail: T['event']['detail']
  ): Promise<void> {
    const client_timestamp = Date.now();
    const getData = () => {
      return {
        key: this._getKey(),
        event: {
          action: 'click',
          detail,
          target,
          base_schema: 'user_click_dimension_0_default',
        },
        version: KAFKA_LOG_VERSION,
        client_timestamp,
      } as T;
    };

    await this._send(getData);
  }

  async trackUserClickDimension1<T extends ClickD1LogBody>(
    target: T['event']['target'],
    detail: T['event']['detail']
  ): Promise<void> {
    const client_timestamp = Date.now();
    const getData = () => {
      return {
        key: this._getKey(),
        event: {
          action: 'click',
          detail,
          target,
          base_schema: 'user_click_dimension_1_default',
        },
        version: KAFKA_LOG_VERSION,
        client_timestamp,
      } as T;
    };

    await this._send(getData);
  }

  async trackUserClickDimension2<T extends ClickD2LogBody>(
    target: T['event']['target'],
    detail: T['event']['detail']
  ): Promise<void> {
    const client_timestamp = Date.now();
    const getData = () => {
      return {
        key: this._getKey(),
        event: {
          action: 'click',
          detail,
          target,
          base_schema: 'user_click_dimension_2_default',
        },
        version: KAFKA_LOG_VERSION,
        client_timestamp,
      } as T;
    };

    await this._send(getData);
  }

  trackSystemCDNPerf(detail: SystemCdnPerfLog['event']['detail']): void {
    const client_timestamp = Date.now();
    const getSystemCDNPerfData: () => SystemCdnPerfLog = () => {
      return {
        key: this._getKey(),
        event: {
          base_schema: 'system_cdn_perf',
          name: 'system_cdn_perf',
          detail,
        },
        version: KAFKA_LOG_VERSION as SystemCdnPerfLog['version'],
        client_timestamp,
      };
    };
    this._send(getSystemCDNPerfData);
  }

  async trackViewLog<T extends ViewLogBody>(
    target: T['event']['target'],
    detail: T['event']['detail']
  ): Promise<void> {
    const client_timestamp = Date.now();
    const getData = (): ViewLogBody => {
      return {
        key: this._getKey(),
        event: {
          action: 'view',
          detail,
          target,
          base_schema: 'user_view_page_default',
        },
        version: KAFKA_LOG_VERSION,
        client_timestamp,
      } as T;
    };

    await this._send(getData);
  }
}

let client: Nullable<KafkaClient> = null;

export function getKafkaClient(): KafkaClient {
  if (!client) {
    client = new KafkaClient();
  }
  return client;
}

const ignoreArray = [TITLE_SPOT] as const;

export type KafkaIgnoreTypes = (typeof ignoreArray)[number];

export function shouldKafkaIgnore<T>(
  componentName: T | KafkaIgnoreTypes
): componentName is KafkaIgnoreTypes {
  if (ignoreArray.indexOf(componentName as KafkaIgnoreTypes) > -1) {
    return true;
  }
  return false;
}
