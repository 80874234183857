import styled from 'styled-components';
import { DEVICE } from '../../../../shared/styles';
import { COLORS } from '../../../../shared/styles/theme';
import { mixinSectionHeading } from '../mixins';

export const CreditSectionContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const CreditHeading = styled.h2`
  ${mixinSectionHeading}
`;

export const CreditListBlocksContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  @media ${DEVICE.mobileWide} {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
`;

export const CreditListBlockHeading = styled.div`
  margin: 16px 0 8px;
  font-size: 12px;
  font-weight: 600;
  color: ${COLORS.white_75};
`;
