import styled from 'styled-components';
import { ButtonLookDiv } from '../../../../shared/components/Common/Button';
import Icon from '../../../../shared/components/Common/Icon';
import { FOOTER_ITEMS_ORDER } from '../../../../shared/components/Layout';
import { DEVICE } from '../../../../shared/styles';
import { COLORS } from '../../../../shared/styles/theme';
import { sectionStyle } from '../mixins';

export const EpisodeSection = styled.div<{ hideBoxUI: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${({ hideBoxUI }) => (!hideBoxUI ? sectionStyle : '')};
  box-sizing: border-box;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Heading = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    font-size: 20px;
  }
`;

export const ItemsCount = styled.div`
  margin-left: 8px;
  font-size: 16px;
  color: ${COLORS.white_75};

  @media ${DEVICE.mobileWide} {
    font-size: 14px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  @media ${DEVICE.mobileWide} {
    flex-flow: column nowrap;
    justify-content: center;
  }
`;

export const HeadingTextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 8px;

  @media ${DEVICE.mobileWide} {
    align-items: center;
  }
`;

export const EpisodeListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const EpisodeListItemContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.white_15};
  }
`;

export const GroupGrid = styled.div<{ hasInfoColumn: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasInfoColumn }) =>
    hasInfoColumn ? 'auto 1fr' : '1fr'};
  gap: 40px 16px;

  @media ${DEVICE.mobileWide} {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`;

export const GroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${DEVICE.mobileWide} {
    flex-direction: row;
    align-items: center;
  }
`;

export const GroupInfoTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const GroupInfoIcon = styled(Icon)`
  margin-left: 4px;
  width: 18px;
  height: 18px;
`;

export const GroupInfoActiveIcon = styled(GroupInfoIcon)`
  display: none;
  background-color: ${COLORS.primary};
  border-radius: 4px;
`;

export const GroupInfoLink = styled.div`
  > a {
    display: flex;
    align-items: center;
    gap: 4px;

    color: ${COLORS.white_75};
    font-size: 14px;
    cursor: pointer;
    transition: color 200ms ease-in;
  }

  &:hover {
    > a {
      color: ${COLORS.white};
    }

    ${GroupInfoIcon} {
      display: none;
    }

    ${GroupInfoActiveIcon} {
      display: block;
    }
  }

  @media ${DEVICE.mobileWide} {
    margin-left: auto;
  }
`;

export const GroupImageContainer = styled.div`
  width: 100px;
  height: 140px;

  border: 1px solid ${COLORS.white_10};

  img {
    width: 100%;
  }

  @media ${DEVICE.mobileWide} {
    width: 50px;
    height: 70px;
  }
`;

export const GroupItems = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 16px;
  flex-direction: column;

  border-radius: 10px;
  background: ${COLORS.ink};

  @media ${DEVICE.mobileWide} {
    padding: 8px;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`;

export const MissingEpisodeText = styled.div`
  font-size: 14px;
  color: ${COLORS.white_75};
`;

export const NextUpdateText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  color: ${COLORS.white_75};

  > div {
    /* Icon style */
    width: 16px;
  }
`;

export const TabsContainer = styled.div`
  width: 344px;
  margin-left: auto;

  @media ${DEVICE.mobileWide} {
    width: 100%;
    margin-left: unset;
  }
`;

export const FooterTabsContainer = styled.div`
  order: ${FOOTER_ITEMS_ORDER.PAGER};
  width: 344px;
  margin: 0 auto;

  @media ${DEVICE.mobileWide} {
    width: unset;
    margin: 0 12px;
  }
`;

export const ShowAllButton = styled(ButtonLookDiv)`
  margin-top: 16px;
  width: 100%;
  @media ${DEVICE.mobileWide} {
    margin-top: 0px;
  }
`;
