import React from 'react';
import styled from 'styled-components';
import OnlyOnIconComponent from '../../../../assets/icons/icon_onlyon.svg';
import { COLORS } from '../../../styles/theme';
import AkamaiImage from '../../Common/AkamaiImage';
import LiveNow, { LiveOnAirStatus } from '../../LiveNow';
import PointPriceBadge from '../../PointPriceBadge';
import HorizontalContainer from '../Book/containers/HorizontalContainer';
import { IMAGE_ASPECT, LIST_VIEW_MOBILE_TITLE_CARD_SIZE } from '../constants';

const MetaInfoLiveDate = styled.div`
  font-size: 12px;
`;

const CatchupText = styled.div`
  font-size: 12px;
  color: ${COLORS.white_75};
`;

const HorizontalLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const MetaInfoTitle = styled.h3`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
`;

const MetaInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
  padding: 2px 0 0 12px;
  box-sizing: border-box;
  overflow: hidden;
`;

const HorizontalThumbnailPadding = styled.div`
  // 1. Set Thumbnail area width % by this element
  position: relative;
  width: ${(100 * LIST_VIEW_MOBILE_TITLE_CARD_SIZE.live.imageWidth) /
  LIST_VIEW_MOBILE_TITLE_CARD_SIZE.live.width}%;
  flex-shrink: 0;

  > div {
    // 2. Set Thumbnail area height by padding-bottom to keep its aspect-ratio
    position: relative;
    background-color: ${COLORS.gray_3};
    width: 100%;
    padding-bottom: ${100 / IMAGE_ASPECT.live}%;
    overflow: hidden;
    border-radius: 10px;

    &:before {
      // 3. Set border as an overlay. Set border-radius here too to make the border precise.
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      border: 1px solid ${COLORS.white_10};
      z-index: 1;
    }
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
  }
`;

const OnlyOnIcon = styled(OnlyOnIconComponent)`
  width: 70px;
`;

interface ListLiveCardMobileProps {
  name: string;
  thumbnailUrl: string;
  isPoint: boolean;
  liveOnAirStatus?: LiveOnAirStatus;
  isPurchasedLive?: boolean;
  isLazy: boolean;
  isOnlyOn: boolean;
  minimumPrice?: number;
  hasMultiplePrice: boolean;
  dateString: string;
  deliveryStartTimeString: string;
  catchupText: string;
}

const ListLiveCardMobile: React.FC<ListLiveCardMobileProps> = ({
  name,
  thumbnailUrl,
  isPoint,
  liveOnAirStatus,
  isPurchasedLive,
  isLazy,
  isOnlyOn,
  minimumPrice,
  hasMultiplePrice,
  dateString,
  deliveryStartTimeString,
  catchupText,
  ...props
}) => {
  return (
    <HorizontalContainer {...props}>
      <HorizontalThumbnailPadding>
        <div>
          <AkamaiImage
            src={thumbnailUrl}
            availableSizes={[168, 336]}
            sizeConfig={{
              SD: {
                width: 282,
              },
              MOBILE_WIDE: {
                width: 168,
              },
            }}
            alt={name}
            isLazy={isLazy}
          />
        </div>
      </HorizontalThumbnailPadding>
      <MetaInfoContainer>
        {isOnlyOn && <OnlyOnIcon />}
        <MetaInfoLiveDate>{`${dateString} ${deliveryStartTimeString}`}</MetaInfoLiveDate>
        <MetaInfoTitle>{name}</MetaInfoTitle>
        <HorizontalLayout>
          {!isPurchasedLive && (
            <PointPriceBadge
              isPoint={isPoint}
              minimumPrice={minimumPrice}
              hasMultiplePrice={!!hasMultiplePrice}
              badgeSize="small"
            />
          )}
          {catchupText && <CatchupText>{catchupText}</CatchupText>}
        </HorizontalLayout>
        {liveOnAirStatus && (
          <LiveNow
            isCatchup={liveOnAirStatus === 'CATCHUP_NOW'}
            active={liveOnAirStatus === 'LIVE_NOW'}
            iconLayout="left"
          />
        )}
      </MetaInfoContainer>
    </HorizontalContainer>
  );
};

export default ListLiveCardMobile;
