import { defineMessages } from 'react-intl';

/**
 * Global i18n messages
 *
 * Any messages that will be used often or cross-component should be
 * placed in this file.
 */
export const globalMessages = defineMessages({
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'はい',
  },
  no: {
    id: 'no',
    defaultMessage: 'いいえ',
  },
  goBack: {
    id: 'goBack',
    defaultMessage: '戻る',
  },
  price: {
    id: 'price',
    defaultMessage: '{price}円',
  },
  minPrice: {
    id: 'minPrice',
    defaultMessage: '{price}円〜',
  },
  videoKana: {
    id: 'video.kana',
    defaultMessage: 'ビデオ',
  },
  bookKana: {
    id: 'book.kana',
    defaultMessage: 'ブック',
  },
  liveKana: {
    id: 'live.kana',
    defaultMessage: 'ライブ配信',
  },
  musicKana: {
    id: 'music.kana',
    defaultMessage: 'ミュージック',
  },
  point: {
    id: 'point',
    defaultMessage: 'ポイント',
  },
  library: {
    id: 'library',
    defaultMessage: 'ライブラリ',
  },
  sub: {
    id: 'sub',
    defaultMessage: '字',
  },
  dub: {
    id: 'dub',
    defaultMessage: '吹',
  },
  subDub: {
    id: 'subdub',
    defaultMessage: '字・吹',
  },
  purchasedBadge: {
    id: 'badge.purchased',
    defaultMessage: '購入済み',
  },
  freeBadge: {
    id: 'badge.free',
    defaultMessage: '無料',
  },
  nodBadge: {
    id: 'badge.nod',
    defaultMessage: 'NHKパック',
  },
  foreignSoccerBadge: {
    id: 'badge.foreignSoccer',
    defaultMessage: 'サッカーパック',
  },
  dailyFreeBadge: {
    id: 'badge.dailyFree',
    defaultMessage: '毎日無料',
  },
  dailyFreeNumBadge: {
    id: 'badge.dailyFreeNum',
    defaultMessage: '{count}話毎日無料',
  },
  hasUnread: {
    id: 'book.hasUnread',
    defaultMessage: '未読あり',
  },
  bookChapter: {
    id: 'book.chapter',
    defaultMessage: '話読み',
  },
  bookVolume: {
    id: 'book.volume',
    defaultMessage: '単行本',
  },
  COMIC: {
    id: 'comic',
    defaultMessage: 'マンガ',
  },
  MAGAZINE: {
    id: 'magazine',
    defaultMessage: '雑誌',
  },
  RANOBE: {
    id: 'ranobe',
    defaultMessage: 'ラノベ',
  },
  BOOK: {
    id: 'book',
    defaultMessage: '書籍',
  },
  PHOTO_BOOK: {
    id: 'photobook',
    defaultMessage: '書籍',
  },
  PICBOOK: {
    id: 'picbook',
    defaultMessage: '書籍',
  },
  mylist: {
    id: 'mylist',
    defaultMessage: 'マイリスト',
  },
  history: {
    id: 'history',
    defaultMessage: '視聴履歴',
  },
  purchased: {
    id: 'purchased',
    defaultMessage: '購入済み',
  },
  rate: {
    id: 'rate',
    defaultMessage: '作品を評価',
  },
  share: {
    id: 'share',
    defaultMessage: 'シェア',
  },
  ranking: {
    id: 'ranking',
    defaultMessage: 'ランキング',
  },
  rankingDailyFree: {
    id: 'rankingDailyFree',
    defaultMessage: '毎日無料ランキング',
  },
  excl: {
    id: 'excl',
    defaultMessage: '独占',
  },
  exclsvod: {
    id: 'exclsvod',
    defaultMessage: '独占見放題',
  },
  sunday: {
    id: 'sunday',
    defaultMessage: '日曜日',
  },
  sundayShort: {
    id: 'sundayShort',
    defaultMessage: '日',
  },
  monday: {
    id: 'monday',
    defaultMessage: '月曜日',
  },
  mondayShort: {
    id: 'mondayShort',
    defaultMessage: '月',
  },
  tuesday: {
    id: 'tuesday',
    defaultMessage: '火曜日',
  },
  tuesdayShort: {
    id: 'tuesdayShort',
    defaultMessage: '火',
  },
  wednesday: {
    id: 'wednesday',
    defaultMessage: '水曜日',
  },
  wednesdayShort: {
    id: 'wednesdayShort',
    defaultMessage: '水',
  },
  thursday: {
    id: 'thursday',
    defaultMessage: '木曜日',
  },
  thursdayShort: {
    id: 'thursdayShort',
    defaultMessage: '木',
  },
  friday: {
    id: 'friday',
    defaultMessage: '金曜日',
  },
  fridayShort: {
    id: 'fridayShort',
    defaultMessage: '金',
  },
  saturday: {
    id: 'saturday',
    defaultMessage: '土曜日',
  },
  saturdayShort: {
    id: 'saturdayShort',
    defaultMessage: '土',
  },
  nextUpdate: {
    id: 'nextUpdate',
    defaultMessage: '次回更新',
  },
  updateText: {
    id: 'updateText',
    defaultMessage: '{updateText}更新',
  },
  freeBookNum: {
    id: 'freeBookNum',
    defaultMessage: '{freeBookNum}冊無料',
  },
  freeChapterNum: {
    id: 'freeChapterNum',
    defaultMessage: '{freeBookNum}話無料',
  },
  totalBookNum: {
    id: 'totalBookNum',
    defaultMessage: '{totalBookNum}冊',
  },
  totalChapterNum: {
    id: 'totalChapterNum',
    defaultMessage: '{totalBookNum}話',
  },
  purchaseBookNum: {
    id: 'purchaseBookNum',
    defaultMessage: '{count}冊購入済み / {total}冊',
  },
  purchasedBooks: {
    id: 'purchasedBooks',
    defaultMessage: '{count}冊購入済み',
  },
  purchaseChapterNum: {
    id: 'purchaseChapterNum',
    defaultMessage: '{count}話購入済み / {total}話',
  },
  purchasedChapters: {
    id: 'purchasedChapters',
    defaultMessage: '{count}話購入済み',
  },
  delivery: {
    id: 'delivery',
    defaultMessage: '{date}配信',
  },
  year: {
    id: 'year',
    defaultMessage: '年',
  },
  minute: {
    id: 'minute',
    defaultMessage: '分',
  },
  remainingMinute: {
    id: 'remainingMinute',
    defaultMessage: '残り {minute}分',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'キャンセル',
  },
  newArrivals: {
    id: 'newArrivals',
    defaultMessage: '新着',
  },
  newFree: {
    id: 'newFree',
    defaultMessage: 'オススメ無料',
  },
  preorder: {
    id: 'preorder',
    defaultMessage: '新刊予約',
  },
  new: {
    id: 'new',
    defaultMessage: 'New',
  },
  login: {
    id: 'login',
    defaultMessage: 'ログイン',
  },
  searchPlaceholder: {
    id: 'search.placeholder',
    defaultMessage: '作品名、人名で検索',
  },
  totalItems: {
    id: 'totalitems',
    defaultMessage: '{count}件',
  },
  keyboardShortcut: {
    id: 'keyboardShortcut',
    defaultMessage: 'キーボードショートカット',
  },
  countNumber: {
    id: 'genre.countNumber',
    defaultMessage: '{count}件',
  },
  countNumberOverFifty: {
    id: 'genre.countNumberOverFifty',
    defaultMessage: '50件以上',
  },
  more: {
    id: 'genre.more',
    defaultMessage: 'すべて表示',
  },
  liveStreaming: {
    id: 'liveStreaming',
    defaultMessage: 'ライブ配信',
  },
  attention: {
    id: 'attention',
    defaultMessage: '注意',
  },
  disclaimer: {
    id: 'disclaimer',
    defaultMessage: '免責事項',
  },
  detail: {
    id: 'detail',
    defaultMessage: '詳細',
  },
  yomihodai: {
    id: 'yomihodai',
    defaultMessage: '読み放題',
  },
  sale: {
    id: 'sale',
    defaultMessage: 'SALE',
  },
  facebook: {
    id: 'facebook',
    defaultMessage: 'Facebook',
  },
  twitter: {
    id: 'twitter',
    defaultMessage: 'X',
  },
  hatena: {
    id: 'hatena',
    defaultMessage: 'はてブ',
  },
  line: {
    id: 'line',
    defaultMessage: 'LINE',
  },
  copyUrl: {
    id: 'copy.url',
    defaultMessage: '作品名とURLをコピー',
  },
  loginRequiredModalTitle: {
    id: 'modal.login.required.title',
    defaultMessage: 'ログインが必要です',
    description: 'modal login-required title',
  },
  loginRequiredModalText: {
    id: 'modal.login.required.text',
    defaultMessage: 'ログインしますか？',
    description: 'modal login-required text',
  },
  plannedPublicStart: {
    id: 'plannedPublicStart',
    defaultMessage: '配信予定',
  },
  hasRead: {
    id: 'hasRead',
    defaultMessage: '※ このエピソードは閲覧したことがあります。',
  },
});

export const errorMessages = defineMessages({
  notFoundTitle: {
    id: 'error.notFound.title',
    defaultMessage: 'お探しのページが見つかりません',
  },
  notFoundDescription: {
    id: 'error.notFound.description',
    defaultMessage:
      '入力したアドレスが間違っているか、ページが移動した可能性があります。',
  },
  notFoundCode: {
    id: 'error.notFound.code',
    defaultMessage: 'WF00404',
  },
  fetchErrorToast: {
    id: 'error.fetchError',
    defaultMessage:
      '読み込み中にエラーが発生しました。ページを再読み込みしてください。',
  },
  mylistUpdateToast: {
    id: 'error.mylistUpdate',
    defaultMessage: 'マイリストを更新できませんでした。',
  },
  evaluationUpdateToast: {
    id: 'error.evaluationUpdate',
    defaultMessage: '評価を更新できませんでした。',
  },
});
