import React from 'react';
import styled from 'styled-components';
import { FONT_FAMILY } from '../../../styles/constants';
import Icon, { Icons } from '../../Common/Icon';
import { LIST_VIEW_MOBILE_TITLE_CARD_SIZE } from '../constants';
import HorizontalContainer from './containers/HorizontalContainer';
import BookMetaInfoMobile from './MetaInfo/BookMetaInfoMobile';
import { type BookMetaInfoProps } from './MetaInfo/utils';
import { RankingMeta } from './RankingBookTitleCard';
import BookThumbnail from './Thumbnail';

type Props = BookMetaInfoProps & {
  thumbnailUrl: string;
  isLazy?: boolean;
  titleName: string;
  rankingMeta?: RankingMeta;
};

const MobileBookMetaInfoContainer = styled.div`
  width: 100%;
  padding: 0 0 0 12px;
  box-sizing: border-box;
  overflow: hidden;
`;

const MobileBookThumbnailLayout = styled.div`
  --mobile-thumbnail-width: calc(
    100% * ${LIST_VIEW_MOBILE_TITLE_CARD_SIZE.rankingBook.imageWidth} /
      ${LIST_VIEW_MOBILE_TITLE_CARD_SIZE.rankingBook.width}
  );
  width: var(--mobile-thumbnail-width);
  flex-shrink: 0;
`;

const RankingLayout = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
  column-gap: 8px;
`;

const RankingNumber = styled.div`
  font-family: ${FONT_FAMILY.MONOSPACE};
  box-sizing: border-box;
  font-size: 44px;
  text-align: left;
  font-weight: 900;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);

  @supports (background-clip: text) or (-webkit-background-clip: text) {
    color: transparent;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
`;

const RankingDiffIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  align-self: auto;
`;

const RankingBookTitleCardMobile: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  bookType,
  numberOfFree,
  publicStartDateTime,
  minimumPrice,
  hasMultiPrice,
  totalBookNum,
  author,
  publisher,
  bookMagazine,
  rate,
  rankingMeta,
  isNew = false,
  isDailyFree,
  isDailyFreePlus,
  isOnSale = false,
  isOriginal = false,
  isYomihodai = false,
  isLazy = false,
  isChapter = false,
  subgenreTagList = [],
  isEpisodeSpecified,
}) => {
  const metaInfoProps = {
    titleName,
    bookType,
    numberOfFree,
    publicStartDateTime,
    minimumPrice,
    hasMultiPrice,
    totalBookNum,
    author,
    publisher,
    bookMagazine,
    rate,
    isNew,
    isOnSale,
    isOriginal,
    isYomihodai,
    subgenreTagList,
    isDailyFree,
    isDailyFreePlus,
    isChapter,
    isEpisodeSpecified,
  };

  const thumbnailProps = {
    thumbnailUrl,
    isLazy,
  };

  return (
    <HorizontalContainer>
      <MobileBookThumbnailLayout>
        <BookThumbnail {...thumbnailProps} />
      </MobileBookThumbnailLayout>
      <MobileBookMetaInfoContainer>
        {rankingMeta && (
          <RankingLayout>
            <RankingNumber>{rankingMeta.rank}</RankingNumber>
            {rankingMeta.rankDiff > 0 && (
              <RankingDiffIcon name={Icons.FULL_ARROW_UP} />
            )}
          </RankingLayout>
        )}
        <BookMetaInfoMobile {...metaInfoProps} />
      </MobileBookMetaInfoContainer>
    </HorizontalContainer>
  );
};

export default RankingBookTitleCardMobile;
