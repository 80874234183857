import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  BookMediaTypeCode,
  cosmo_bookTitleDetailQuery,
} from '../../../__generated__/globalTypes';
import ReadMore from '../../../shared/components/Common/ReadMore';
import {
  AuthorInfo,
  AuthorThumbnail,
  AuthorType,
  InfoContent,
  InfoHeading,
  InfoSection,
  InfoText,
  InfoTextSmallDark,
} from '../shared/InfoSection';
import messages from './messages';

interface MainSectionProps {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
}

const BookInfoSection: React.FC<MainSectionProps> = ({ bookSakuhin }) => {
  const intl = useIntl();

  if (!bookSakuhin?.book) {
    return null;
  }

  const isUnextOriginalBook =
    bookSakuhin.book.mediaType.code === BookMediaTypeCode.BOOK &&
    bookSakuhin.isUnextOriginal;
  const unextPublishingCredits = bookSakuhin.book.credits.filter(
    (credit) =>
      credit.unextPublishingDetail?.introduction ||
      credit.unextPublishingDetail?.thumbnail
  );

  const isMagazine =
    bookSakuhin.book.mediaType.code === BookMediaTypeCode.MAGAZINE;
  const isComic = bookSakuhin.book.mediaType.code === BookMediaTypeCode.COMIC;
  const introduction = isComic
    ? bookSakuhin.detail.introduction
    : bookSakuhin.book.introduction;
  const originalSaleDateText =
    !!bookSakuhin.book?.originalSaleDate &&
    !bookSakuhin.book?.isPreorderable &&
    `${intl.formatDate(bookSakuhin.book.originalSaleDate, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}${intl.formatMessage(messages.publicStart)}`;

  return (
    <InfoSection>
      {introduction && (
        <InfoContent>
          <InfoHeading>
            <FormattedMessage {...messages.descriptionHeading} />
          </InfoHeading>
          <InfoText>
            <ReadMore text={introduction} maxLine={3} />
          </InfoText>
        </InfoContent>
      )}
      {!isComic && !isMagazine && originalSaleDateText && (
        <InfoContent>
          <InfoTextSmallDark>
            <div>{originalSaleDateText}</div>
          </InfoTextSmallDark>
        </InfoContent>
      )}
      {isMagazine && (
        <InfoContent>
          <InfoTextSmallDark>
            <div>{intl.formatMessage(messages.publicUntilNextIssue)}</div>
          </InfoTextSmallDark>
        </InfoContent>
      )}
      {isUnextOriginalBook &&
        unextPublishingCredits.length > 0 &&
        unextPublishingCredits.map((credit, i) => (
          <InfoContent key={i} hasBorderTop>
            <AuthorInfo>
              {credit.unextPublishingDetail?.thumbnail?.standard && (
                <AuthorThumbnail
                  src={`//${credit.unextPublishingDetail.thumbnail.standard}`}
                />
              )}
              <div>{credit.penName}</div>
              {credit.bookAuthorType && (
                <AuthorType>{credit.bookAuthorType}</AuthorType>
              )}
            </AuthorInfo>
            {credit.unextPublishingDetail?.introduction && (
              <InfoText>
                <ReadMore
                  text={credit.unextPublishingDetail.introduction}
                  maxLine={3}
                />
              </InfoText>
            )}
          </InfoContent>
        ))}
    </InfoSection>
  );
};

export default BookInfoSection;
