import React from 'react';
import styled from 'styled-components';
import UnextLogo from '../../../../assets/icons/logo/Logo_U-NEXT_Mark.svg';
import { COLORS } from '../../../styles/theme';
import { default as AkamaiImage } from '../../Common/AkamaiImage';
import Icon, { Icons } from '../../Common/Icon';
import LiveNow, { LiveOnAirStatus } from '../../LiveNow';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
  IMAGE_ASPECT,
} from '../constants';

const desktopSize = BLOCK_VIEW_TITLE_CARD_SIZE.live;
const mobileSize = BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.live;

export const Shadow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  position: absolute;
  padding: 0 12px 5px;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  background: linear-gradient(
    -180deg,
    ${COLORS.jet_black_overlay_0} 0%,
    ${COLORS.jet_black_overlay_80} 100%
  );
`;

const OnlyOnLogoContainer = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  display: inline-flex;
  padding: 2px;
  > svg {
    width: 18px;
  }
`;

const LiveNowContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: flex;
  padding: 2px;
  border-radius: 4px;
  background: ${COLORS.jet_black_overlay_60};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${COLORS.gray_3};
  padding-bottom: ${100 / IMAGE_ASPECT.live}%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
  }
`;

const PointIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
  width: 16px;
`;

const MetaInfoTitle = styled.h3`
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface LiveCardProps {
  name: string;
  thumbnailUrl: string;
  isPoint?: boolean;
  liveOnAirStatus?: LiveOnAirStatus;
  isLazy: boolean;
  isOnlyOn: boolean;
}

const LiveThumbnail: React.FC<LiveCardProps> = ({
  thumbnailUrl,
  isPoint,
  liveOnAirStatus,
  name,
  isLazy,
  isOnlyOn,
  ...props
}) => {
  return (
    <ImageContainer {...props}>
      {isOnlyOn && (
        <OnlyOnLogoContainer>
          <UnextLogo />
        </OnlyOnLogoContainer>
      )}
      {liveOnAirStatus && (
        <LiveNowContainer>
          <LiveNow
            isCatchup={liveOnAirStatus === 'CATCHUP_NOW'}
            active={liveOnAirStatus === 'LIVE_NOW'}
            iconLayout="right"
          />
        </LiveNowContainer>
      )}
      {isPoint && <PointIcon name={Icons.POINT} />}
      <AkamaiImage
        src={thumbnailUrl}
        availableSizes={[1, 2, 3].map(
          (x) => Math.min(mobileSize.width, desktopSize.width) * x
        )}
        sizeConfig={{
          SD: {
            width: desktopSize.width,
          },
          MOBILE_WIDE: {
            width: mobileSize.width,
          },
        }}
        alt={name}
        isLazy={isLazy}
      />
      <Shadow>
        <MetaInfoTitle>{name}</MetaInfoTitle>
      </Shadow>
    </ImageContainer>
  );
};

export default LiveThumbnail;
