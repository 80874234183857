import { useRouter } from 'next/router';
import React, { useMemo, useReducer } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  cosmo_bookTitleDetailQuery,
  cosmo_bookTitleDetailQueryVariables,
} from '../../../__generated__/globalTypes';
import { createAkamaiImageUrl } from '../../../shared/components/Common/AkamaiImage';
import MetaTags from '../../../shared/components/MetaTags';
import {
  BookGenreUrl,
  PortalErrorCodes,
  UrlQueryParams,
} from '../../../shared/constants';
import { errorMessages } from '../../../shared/constants/messages';
import metaMessages from '../../../shared/constants/meta/titleDetail';
import { createExtendedApolloError } from '../../../shared/ExtendedApolloError';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import {
  PreorderContext,
  preorderStatusReducer,
} from '../../../shared/PreorderContext';
import { DEVICE } from '../../../shared/styles';
import { extractGraphQLError } from '../../../utils';
import { bookGenreTitles } from '../../Genre/messages';
import BookEpisodeListSection from '../Book/BookEpisodeListSection';
import { KeyArtBackdrop, TicketStatus } from '../Book/Components';
import { GET_BOOK } from '../Book/gql';

const Heading = styled.div`
  margin-top: 8px;
  font-size: 22px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    margin-top: 24px;
    font-size: 18px;
  }
`;

const messages = defineMessages({
  bookListHeading: {
    id: 'title.book.bookList.heading',
    defaultMessage: '{title} / {count}冊',
  },
  chapterListHeading: {
    id: 'title.book.chapterList.heading',
    defaultMessage: '{title} / {count}話',
  },
});

interface BookListProps {
  bookSakuhinCode: string;
  bookCode?: string;
  isSsr: boolean;
}

const BookList: React.FC<BookListProps> = ({ bookSakuhinCode, bookCode }) => {
  const intl = useIntl();
  const router = useRouter();

  const bookTitleDetailVariables = useMemo<cosmo_bookTitleDetailQueryVariables>(
    () => ({
      bookSakuhinCode,
      bookCode,
      viewBookCode: router.query[UrlQueryParams.BOOK_VIEW_CODE] as string,
      featurePieceCode: router.query[
        UrlQueryParams.FEATURE_PIECE_CODE
      ] as string,
    }),
    [bookSakuhinCode, bookCode, router]
  );

  const {
    data,
    loading: titleLoading,
    error,
  } = useClientQuery<
    cosmo_bookTitleDetailQuery,
    cosmo_bookTitleDetailQueryVariables
  >(GET_BOOK, {
    variables: bookTitleDetailVariables,
    fetchPolicy: 'cache-and-network',
  });

  const bookSakuhin = data?.bookTitle;

  if (error) {
    const [bookTitleErrorCode] = extractGraphQLError('BOOK_TITLE_ERROR', error);
    const extendedErrorData =
      bookTitleErrorCode === PortalErrorCodes.BOOK_TITLE_NOT_FOUND
        ? {
            errorCode: '404',
            customTitle: intl.formatMessage(errorMessages.notFoundTitle),
            customMessage: intl.formatMessage(
              errorMessages.notFoundDescription
            ),
          }
        : {
            errorCode: '500',
          };
    throw createExtendedApolloError(error, extendedErrorData);
  }

  const mediaCode = bookSakuhin?.book?.mediaType
    ?.code as keyof typeof BookGenreUrl;

  const [preorderStatus, updatePreorderStatus] = useReducer(
    preorderStatusReducer,
    new Map()
  );

  if (!bookSakuhin?.sakuhinCode) {
    return null;
  }

  return (
    <>
      {bookSakuhin.book?.thumbnail.standard && (
        <MetaTags
          // title for og and twitter is being checked
          title={intl.formatMessage(metaMessages.book.defaultTitle, {
            // TODO: WF-12579 仕様が用意できたら更新
            bookTitle: bookSakuhin.book?.name,
            mediaTypeName: intl.formatMessage(bookGenreTitles[mediaCode].title),
          })}
          description={intl.formatMessage(metaMessages.book.defaultDescription)}
          keywords={intl.formatMessage(metaMessages.book.defaultKeywords, {
            bookTitle: bookSakuhin.book?.name,
          })}
          image={`https://${createAkamaiImageUrl(
            bookSakuhin.book.thumbnail.standard,
            { width: 1024 },
            'jpg'
          )}`}
          path={`/book/title/${bookSakuhin.sakuhinCode}/${bookSakuhin.book?.code}`}
          ogType="article"
          canonicalLink={`/book/title/${bookSakuhin.sakuhinCode}`}
          breadcrumbs={[
            { name: 'HOME', pathname: '/' },
            {
              name: intl.formatMessage(bookGenreTitles[mediaCode].title),
              pathname: `/book/genre/${
                BookGenreUrl[mediaCode in BookGenreUrl ? mediaCode : 'BOOK']
              }`,
            },
            {
              name: bookSakuhin.name,
              pathname: `/book/title/${bookSakuhin.sakuhinCode}`,
            },
            {
              name: bookSakuhin.book.name,
              pathname: `/book/title/${bookSakuhin.sakuhinCode}/${bookSakuhin.book.code}`,
            },
          ]}
        />
      )}
      <KeyArtBackdrop
        backgroundImageUrl={
          titleLoading || !bookSakuhin
            ? undefined
            : `//${bookSakuhin.book?.thumbnail.standard}`
        }
      />
      <Heading>
        {intl.formatMessage(
          bookSakuhin.isChapter
            ? messages.chapterListHeading
            : messages.bookListHeading,
          {
            title: bookSakuhin.name,
            count: bookSakuhin.totalBookNum,
          }
        )}
      </Heading>
      <PreorderContext.Provider
        value={{
          preorderStatus,
          updatePreorderStatus,
        }}
      >
        <BookEpisodeListSection
          bookSakuhin={bookSakuhin}
          preorderStatus={preorderStatus}
          updatePreorderStatus={updatePreorderStatus}
          pagingStyle="footerTabs"
          variant={bookSakuhin.isChapter ? 'chapterListModal' : 'normal'}
        />
      </PreorderContext.Provider>
      {bookSakuhin.isChapter && bookSakuhin.bookTickets && (
        <TicketStatus {...bookSakuhin.bookTickets} />
      )}
    </>
  );
};

export default BookList;
