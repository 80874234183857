import {
  ApolloClient,
  NormalizedCacheObject,
  QueryHookOptions,
} from '@apollo/client';
import { useContext } from 'react';
import {
  cosmo_parentalLockQuery,
  cosmo_userInfoQuery,
} from '../../../__generated__/globalTypes';
import LLPUserInfoContext from '../../LLPUserInfoContext';
import useClientQuery from '../useClientQuery';
import useIsFromShortcutMode from '../useIsFromShortcutMode';
import { GET_PARENTAL_LOCK, GET_USER_INFO } from './gql';

export const getParentalLockFromApolloClient = async (
  apolloClient: ApolloClient<NormalizedCacheObject>
): Promise<boolean> => {
  const data = apolloClient.cache.readQuery<cosmo_parentalLockQuery>({
    query: GET_PARENTAL_LOCK,
  });
  return !!data?.parentalLock;
};

/**
 * useUserInfo hook is used to query UserInfo.
 * First GET_USER_INFO request is made in _app.tsx.
 * We use the hook to reuse the cached data
 * and to refetch in case user points have changed.
 * Pass fetchPolicy: 'cache-and-network' option to refetch.
 */
export const useUserInfo = (
  options?: QueryHookOptions
): {
  data:
    | {
        userInfo?: Partial<cosmo_userInfoQuery['userInfo']>;
      }
    | undefined;
  loading: boolean;
} => {
  const { llpUserInfo } = useContext(LLPUserInfoContext);
  const isFromShortcutMode = useIsFromShortcutMode();

  const { data: userInfoData, loading: userInfoLoading } =
    useClientQuery<cosmo_userInfoQuery>(GET_USER_INFO, {
      ...options,
      skip:
        options?.skip ||
        !!(
          isFromShortcutMode &&
          (!llpUserInfo.isInited || llpUserInfo.userInfo)
        ),
    });

  if (isFromShortcutMode && (!llpUserInfo.isInited || llpUserInfo.userInfo)) {
    return {
      data: {
        userInfo: llpUserInfo.userInfo,
      },
      // keep the loading state to be `true` if we failed to get userInfo from LLP. Because
      // the GET_USER_INFO graphql query should be triggered afterwards
      loading: !llpUserInfo.isInited || !llpUserInfo.userInfo,
    };
  }

  return {
    data: userInfoData,
    loading: userInfoLoading,
  };
};
