import { useIntl } from 'react-intl';
import {
  BookMediaTypeCode,
  BookSakuhinDetailFragment,
} from '../../../__generated__/globalTypes';
import { DailyFreeBadge } from '../../../shared/components/Common/Badge/DaillyFreeBadge';
import {
  PaymentBadge,
  YomihodaiBadge,
} from '../../../shared/components/Common/Badge/PaymentBadge';
import { PurchasedBadge } from '../../../shared/components/Common/Badge/PurchasedBadge';
import { RoundBadge } from '../../../shared/components/Common/Badge/RoundBadge';
import PointPriceBadge from '../../../shared/components/PointPriceBadge';
import { globalMessages } from '../../../shared/constants/messages';
import messages from './messages';

const Badges = ({
  bookSakuhin,
}: {
  bookSakuhin: BookSakuhinDetailFragment;
}) => {
  const intl = useIntl();
  const isComic = bookSakuhin.book?.mediaType.code === BookMediaTypeCode.COMIC;
  const isSVOD = bookSakuhin.paymentBadgeList.some(
    (badge) => badge.code === 'SVOD'
  );
  return (
    <>
      {!isSVOD && (
        <PointPriceBadge
          isPoint
          minimumPrice={bookSakuhin.minPrice ?? undefined}
          hasMultiplePrice={!!bookSakuhin.hasMultiplePrices}
          badgeSize="large"
          mobileBadgeSize="small"
        />
      )}
      {bookSakuhin.paymentBadgeList.map((b) =>
        b.code === 'SVOD' ? (
          <YomihodaiBadge
            key={b.code}
            badgeSize="large"
            mobileBadgeSize="small"
          />
        ) : (
          <PaymentBadge key={b.code} badgeSize="large" mobileBadgeSize="small">
            {b.name}
          </PaymentBadge>
        )
      )}
      {!isComic &&
        !!bookSakuhin.purchaseBookNum &&
        !!bookSakuhin.totalBookNumIncludingPreorders && (
          <PurchasedBadge
            meta={{
              purchasedNum: bookSakuhin.purchaseBookNum,
              totalNum: bookSakuhin.totalBookNumIncludingPreorders,
            }}
            mobileBadgeSize="small"
          />
        )}
      {bookSakuhin.isSale && (
        <RoundBadge badgeType="white" mobileBadgeSize="small">
          {intl.formatMessage(globalMessages.sale)}
        </RoundBadge>
      )}
      {bookSakuhin.freeBookNum ? (
        <RoundBadge badgeType="white" mobileBadgeSize="small">
          {intl.formatMessage(globalMessages.freeBookNum, {
            freeBookNum: bookSakuhin.freeBookNum,
          })}
        </RoundBadge>
      ) : null}
    </>
  );
};

export const ChapterComicBadges = ({
  bookSakuhin,
}: {
  bookSakuhin: BookSakuhinDetailFragment;
}) => {
  const intl = useIntl();
  const {
    bookNumForTicket,
    isBookPlusTicketAvailable,
    ticketEndDatetime,
    plusTicketEndDateTime,
  } = bookSakuhin;

  const freeSaleText = bookSakuhin.freeSaleText; // nexus will return formatted freeSaleText

  const plusTicketEndText = plusTicketEndDateTime
    ? intl.formatDate(plusTicketEndDateTime, {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }) + intl.formatMessage(messages.dailyFreePlusEndDateText)
    : undefined;
  const ticketEndText = ticketEndDatetime
    ? intl.formatDate(ticketEndDatetime, {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }) + intl.formatMessage(messages.dailyFreeEndDateText)
    : undefined;
  const displayEndText = [freeSaleText, plusTicketEndText, ticketEndText]
    .filter(Boolean)
    .join('\n');
  return (
    <>
      {bookSakuhin.freeBookNum ? (
        <RoundBadge badgeType="white" mobileBadgeSize="small">
          {intl.formatMessage(globalMessages.freeChapterNum, {
            freeBookNum: bookSakuhin.freeBookNum,
          })}
        </RoundBadge>
      ) : null}
      {bookNumForTicket > 0 && (
        <DailyFreeBadge
          badgeType={isBookPlusTicketAvailable ? 'plus' : 'normal'}
          mobileBadgeSize="small"
          bookNum={bookNumForTicket}
        />
      )}
      {displayEndText && (
        <div style={{ whiteSpace: 'pre-wrap' }}>{displayEndText}</div>
      )}
    </>
  );
};

export default Badges;
