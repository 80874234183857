import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import { EditThumbnailOverlay } from '../../Edit';
import { LIST_VIEW_TITLE_CARD_SIZE } from '../constants';
import PurchasedVideoMetaInfo, {
  PurchasedVideoMetaInfoProps,
} from './PurchasedVideoMetaInfo';
import VideoMetaInfo, { VideoMetaInfoProps } from './VideoMetaInfo';
import VideoThumbnail, { Shadow, VideoThumbnailProps } from './VideoThumbnail';

const Container = styled.div`
  position: relative;
  width: ${LIST_VIEW_TITLE_CARD_SIZE.video.width}px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 200ms ease-in, z-index 100ms 100ms linear;
  background: ${COLORS.ink};
  cursor: pointer;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 1px solid ${COLORS.white_10};
    z-index: 1;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      z-index: 500;
      box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};

      ${Shadow} {
        opacity: 1;
      }
    }
  }
`;

const StyledVideoThumbnail = styled(VideoThumbnail)`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border-bottom: 1px solid ${COLORS.white_10};
    z-index: 1;
  }
`;

type MetaInfoProps = VideoMetaInfoProps | PurchasedVideoMetaInfoProps;

const ListVideoCard: React.FC<VideoThumbnailProps & MetaInfoProps> = ({
  titleName,
  thumbnailUrl,
  isPoint = false,
  isNew = false,
  isLazy = false,
  isOnlyOn = false,
  ...props
}) => {
  const isPurchasedVideo = 'closestExpiryDateText' in props;

  const thumbnailProps = isPurchasedVideo
    ? { titleName, thumbnailUrl, isLazy }
    : {
        titleName,
        thumbnailUrl,
        isPoint: false, // Don't show P icon on Thumbnail for ListView
        isNew,
        isOnlyOn,
        isLazy,
      };
  return (
    <Container {...props}>
      <EditThumbnailOverlay>
        <StyledVideoThumbnail {...thumbnailProps} />
      </EditThumbnailOverlay>
      {isPurchasedVideo ? (
        <PurchasedVideoMetaInfo
          closestExpiryDateText={props.closestExpiryDateText}
          boughtEpisodeNumberTextList={props.boughtEpisodeNumberTextList}
        />
      ) : (
        <VideoMetaInfo
          catchphrase={props.catchphrase}
          hasSub={props.hasSub}
          hasDub={props.hasDub}
          nfreeBadge={props.nfreeBadge}
          year={props.year}
          rate={props.rate}
          isPoint={isPoint}
          price={props.price}
          updateInfo={props.updateInfo}
          hasMultiprice={props.hasMultiprice}
        />
      )}
    </Container>
  );
};

export default ListVideoCard;
