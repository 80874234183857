import { defineMessages } from 'react-intl';

const messages = {
  video: defineMessages({
    defaultTitle: {
      id: 'videoTitleDetail.defaultTitle',
      defaultMessage:
        '{titleName}({genreName} / {productionYear}) - 動画配信 | U-NEXT 31日間無料トライアル',
      description: 'Video title detail default title',
    },
    defaultDescription: {
      id: 'videoTitleDetail.defaultDescription',
      defaultMessage:
        '<31日間無料トライアル実施中>「{titleName}」を今すぐ視聴できます。DVDをレンタルせずに高画質な動画をお楽しみいただけます。',
      description: 'Video title detail default description',
    },
    defaultKeywords: {
      id: 'videoTitleDetail.defaultKeywords',
      defaultMessage: '{titleName},動画,視聴,あらすじ',
      description: 'Video title detail default keywords',
    },
  }),
  book: defineMessages({
    defaultTitle: {
      id: 'bookTitleDetail.defaultTitle',
      defaultMessage:
        '{bookTitle}({mediaTypeName}) - 電子書籍 | U-NEXT 初回600円分無料',
      description: 'Book title detail default title',
    },
    defaultDescription: {
      id: 'bookTitleDetail.defaultDescription',
      defaultMessage:
        '「31日間無料トライアル」初回登録で600円分のポイントプレゼント！ポイントは最新コミックの購入や、最新映画の視聴にご利用可能。更に実額購入分の最大40％をポイント還元！よりお得に楽しめる！',
      description: 'Book title detail default description',
    },
    defaultKeywords: {
      id: 'bookTitleDetail.defaultKeywords',
      defaultMessage:
        '{bookTitle},漫画,まんが,マンガ,コミック,雑誌,ラノベ,電子書籍,U-NEXT,ユーネクスト,無料',
      description: 'Book title detail default keywords',
    },
    freeComicTitle: {
      id: 'bookTitleDetail.freeComicTitle',
      defaultMessage:
        '{bookSakuhinTitle}({bookTitle}) | 無料マンガ - 電子書籍 | U-NEXT',
      description: 'Book title detail free comic title',
    },
    freeComicDescription: {
      id: 'bookTitleDetail.freeComicDescription',
      defaultMessage:
        '「{bookSakuhinTitle}({bookTitle})」が今すぐ読める！マンガ読むならU-NEXT。無料マンガが続々入荷！',
      description: 'Book title detail free comic description',
    },
  }),
  live: defineMessages({
    defaultTitle: {
      id: 'liveTitleDetail.defaultTitle',
      defaultMessage: '{liveName} - 動画配信 | U-NEXT 31日間無料トライアル',
      description: 'Live title detail default title',
    },
    defaultDescription: {
      id: 'liveTitleDetail.defaultDescription',
      defaultMessage:
        'U-NEXTならテレビの大画面で高画質・高音質でライブをご視聴いただけます。「31日間無料トライアル」初回登録で、600円分のポイントプレゼント！映画 / ドラマ / アニメもお楽しみいただけます。',
      description: 'Live title detail default description',
    },
    defaultKeywords: {
      id: 'liveTitleDetail.defaultKeywords',
      defaultMessage: '{liveName},動画,ライブ,LIVE,視聴',
      description: 'Live title detail default keywords',
    },
  }),
};

export default messages;
