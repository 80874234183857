import { createContext } from 'react';

type EditContextShape = {
  enabled: boolean;
  selected: boolean;
  selectable?: boolean;
};

export const EditContext = createContext<EditContextShape>({
  enabled: false,
  selected: false,
  selectable: true,
});
