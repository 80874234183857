import { GlobalConfig } from '../shared/constants';

const DEFAULT_RELOAD_BUDGET = 2;
const DEFAULT_RELOAD_INTERVAL = 15000;
const DEFAULT_REQUEST_DELAY = 10000;

const DEFAULT_IGNORE_LLP_EVERY_NUM_FAILURES = 10;
const DEFAULT_REVERSE_CDN_WEIGHT_EVERY_NUM_FAILURES = 5;

const validateFloodGateControl = (
  floodGateConfigJson: Partial<FloodGateControlConfig> | undefined
): FloodGateControlConfig | undefined =>
  typeof floodGateConfigJson === 'object' &&
  typeof floodGateConfigJson.enable === 'boolean'
    ? {
        enable: floodGateConfigJson.enable,
        reloadBudget:
          typeof floodGateConfigJson.reloadBudget === 'number'
            ? floodGateConfigJson.reloadBudget
            : DEFAULT_RELOAD_BUDGET,
        reloadInterval:
          typeof floodGateConfigJson.reloadInterval === 'number'
            ? floodGateConfigJson.reloadInterval
            : DEFAULT_RELOAD_INTERVAL,
        requestDelay:
          typeof floodGateConfigJson.requestDelay === 'number'
            ? floodGateConfigJson.requestDelay
            : DEFAULT_REQUEST_DELAY,
      }
    : undefined;

const validateLivePlaybackFailsafeConfigurations = (
  llpConfigJson: Partial<LivePlaybackFailsafeConfigurations> | undefined
): LivePlaybackFailsafeConfigurations | undefined =>
  typeof llpConfigJson === 'object' &&
  typeof llpConfigJson.disableLlpPlayback === 'boolean'
    ? {
        disableLlpPlayback: llpConfigJson.disableLlpPlayback,
        ignoreLlpEveryNumFailures:
          typeof llpConfigJson.ignoreLlpEveryNumFailures === 'number'
            ? llpConfigJson.ignoreLlpEveryNumFailures
            : DEFAULT_IGNORE_LLP_EVERY_NUM_FAILURES,
        reverseCdnWeightEveryNumFailures:
          typeof llpConfigJson.reverseCdnWeightEveryNumFailures === 'number'
            ? llpConfigJson.reverseCdnWeightEveryNumFailures
            : DEFAULT_REVERSE_CDN_WEIGHT_EVERY_NUM_FAILURES,
      }
    : undefined;

const validateAdultBannerABTest = (
  adultBannerConfigJson: Partial<AdultBannerABTest> | undefined
): AdultBannerABTest | undefined =>
  typeof adultBannerConfigJson === 'object' &&
  typeof adultBannerConfigJson.enable === 'boolean' &&
  Array.isArray(adultBannerConfigJson.targetUserPFIDSuffix)
    ? {
        enable: adultBannerConfigJson.enable,
        targetUserPFIDSuffix: adultBannerConfigJson.targetUserPFIDSuffix,
      }
    : undefined;

const validateSkipMoviePart = (
  skipPartConfigJson: Partial<SkipMoviePart> | undefined
): SkipMoviePart | undefined =>
  typeof skipPartConfigJson === 'object'
    ? {
        superUser:
          typeof skipPartConfigJson.superUser === 'boolean' &&
          skipPartConfigJson.superUser,
        notSuperUser:
          typeof skipPartConfigJson.notSuperUser === 'boolean' &&
          skipPartConfigJson.notSuperUser,
      }
    : undefined;

const validateCMAFEnabled = (
  CMAFEnabledConfigJson: Partial<CMAFEnabled> | undefined
): CMAFEnabled | undefined =>
  typeof CMAFEnabledConfigJson === 'object'
    ? {
        superUser:
          typeof CMAFEnabledConfigJson.superUser === 'boolean' &&
          CMAFEnabledConfigJson.superUser,
        notSuperUser:
          typeof CMAFEnabledConfigJson.notSuperUser === 'boolean' &&
          CMAFEnabledConfigJson.notSuperUser,
      }
    : undefined;

/*
 * remote configs are defined in this repository
 * https://github.com/u-next/client-remote-config
 */

export const loadRemoteConfig = async (
  isBot: boolean
): Promise<WebFrontRemoteConfig & { isSuccess: boolean }> => {
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, 1500);
  try {
    const configResponse = await fetch(GlobalConfig.URLS.CONFIG, {
      signal: controller.signal,
    });
    const configJson = await configResponse.json();
    const floodGateControl = isBot
      ? undefined
      : validateFloodGateControl(configJson.floodGateControl);
    const livePlaybackFailsafeConfigurations =
      validateLivePlaybackFailsafeConfigurations(
        configJson.livePlaybackFailsafeConfigurations
      );
    const adultBannerABTest = validateAdultBannerABTest(
      configJson.adultBannerABTest
    );

    const skipMoviePart =
      typeof configJson.enableSkipMoviePart === 'object'
        ? validateSkipMoviePart(configJson.enableSkipMoviePart)
        : undefined;

    const CMAFEnabled =
      typeof configJson.enableCMAF === 'object'
        ? validateCMAFEnabled(configJson.enableCMAF)
        : undefined;
    return {
      isSuccess: true,
      maintenanceMode: !isBot && (configJson.maintenanceMode || false),
      skipUserRecommend: configJson.skipUserRecommend || false,
      floodGateControl,
      livePlaybackFailsafeConfigurations,
      adultBannerABTest,
      skipMoviePart,
      CMAFEnabled,
    };
  } catch (e) {
    return {
      isSuccess: false,
      maintenanceMode: false,
      skipUserRecommend: false,
    };
  } finally {
    clearTimeout(timeout);
  }
};

let cachedRemoteConfig: WebFrontRemoteConfig | null = null;
export const loadRemoteConfigWithCache = async (isBot: boolean) => {
  if (cachedRemoteConfig) {
    return cachedRemoteConfig;
  }

  const remoteConfig = await loadRemoteConfig(isBot);
  if (remoteConfig.isSuccess) {
    cachedRemoteConfig = remoteConfig;
  }

  return remoteConfig;
};
