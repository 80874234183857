import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Modal from '../../shared/components/Modal';
import useNewVersionChecker from '../../shared/hooks/useNewVersionChecker';

const messages = defineMessages({
  updateAlertMessage: {
    id: 'newversionchecker.updatealertmesssage',
    defaultMessage: 'U-NEXTに更新があります。ページを再読み込みしてください。',
  },
  refetchButtonText: {
    id: 'newversionchecker.refetchButtonText',
    defaultMessage: '再読み込み',
  },
});

const NewVersionChecker: React.FC = () => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState<boolean>(false);

  const onNewVersionAvailable = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  useNewVersionChecker({
    onNewVersionAvailable,
  });

  return (
    <Modal
      visible={showModal}
      onOk={() => {
        window.location.reload();
      }}
      okText={intl.formatMessage(messages.refetchButtonText)}
      okButtonType="attention"
    >
      {intl.formatMessage(messages.updateAlertMessage)}
    </Modal>
  );
};

export default NewVersionChecker;
