import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query cosmo_userInfo {
    userInfo {
      id
      multiAccountId
      userPlatformId
      userPlatformCode
      superUser
      age
      otherFunctionId
      points
      hasRegisteredEmail
      billingCaution {
        title
        description
        suggestion
        linkUrl
      }
      blockInfo {
        isBlocked
        score
      }
      siteCode
      accountTypeCode
      linkedAccountIssuer
      isAdultPermitted
      needsAdultViewingRights
      parentalLock @client
      isAeon @client
    }
  }
`;

export const GET_PARENTAL_LOCK = gql`
  query cosmo_parentalLock {
    parentalLock @client
  }
`;

export const GET_IS_AEON = gql`
  query cosmo_isAeon {
    isAeon @client
  }
`;
