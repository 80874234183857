import { useCallback, useEffect, useState } from 'react';

const useMedia = <T>(queries: string[], values: T[], defaultValue: T): T => {
  const [value, setValue] = useState(defaultValue);
  const getValue = useCallback(
    (mediaQueryList: MediaQueryList[], values: T[], defaultValue: T) => {
      const index = mediaQueryList.findIndex(
        (mediaQuery) => mediaQuery.matches
      );
      return typeof values[index] !== 'undefined'
        ? values[index]
        : defaultValue;
    },
    []
  );

  useEffect(
    () => {
      const mediaQueryList = queries.map((query) => window.matchMedia(query));
      const handler = () => {
        const newValue = getValue(mediaQueryList, values, defaultValue);
        setValue(newValue);
      };
      handler();
      mediaQueryList.forEach((mediaQuery) => mediaQuery.addListener(handler));

      return () =>
        mediaQueryList.forEach((mediaQuery) =>
          mediaQuery.removeListener(handler)
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should be called just once
    []
  );

  return value;
};

export default useMedia;
