import Link, { LinkProps } from 'next/link';
import React, {
  ComponentProps,
  OptionHTMLAttributes,
  SelectHTMLAttributes,
} from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { globalMessages } from '../../constants/messages';
import { useSearchInputContext } from '../../SearchInputContext';
import { DEVICE } from '../../styles';
import { MAIN_PAGE_PADDING_TOP } from '../../styles/constants';
import { RoundBadge } from '../Common/Badge/RoundBadge';
import Icon, { Icons } from '../Common/Icon';
import { NavigationButtonDiv } from '../Common/NavigationButton';
import { BLOCK_PADDING, BLOCK_PADDING_MOBILE } from '../Slider';

const MainMetaContainer = styled.div`
  padding-top: ${MAIN_PAGE_PADDING_TOP.desktop}px;
  margin: 0 ${BLOCK_PADDING}px;

  @media ${DEVICE.mobileWide} {
    padding-top: ${MAIN_PAGE_PADDING_TOP.mobile}px;
    margin: 0 ${BLOCK_PADDING_MOBILE}px;
  }
`;

const MobileBackButtonLayout = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 600;

  @media ${DEVICE.mobileWide} {
    margin: 0;
    font-size: 28px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-flex;
`;
const SaleBadge = styled(RoundBadge)`
  &&& {
    margin-right: 10px;
  }
`;

const FeatureTitleContainer = styled.div`
  margin-bottom: 24px;
`;

const FeatureTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  font-feature-settings: 'halt';
  @media ${DEVICE.mobileWide} {
    font-size: 24px;
  }
`;

const FeatureSubTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-left: 2px;
  font-size: 18px;
  color: ${({ theme }) => theme.genre.banner.leadText};
  @media ${DEVICE.mobileWide} {
    font-size: 13px;
  }
`;

const Dropdown = styled.select`
  appearance: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  font-size: 16px;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  width: 40px;
  margin-left: 4px;
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.genre.banner.leadText};
  font-size: 20px;
  padding-left: 2px;
  @media ${DEVICE.mobileWide} {
    font-size: 16px;
  }
`;

const CircleButtonLabel = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.text.standard};
  font-size: 14px;
  line-height: 140%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CircleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    & > div {
      background-color: ${({ theme }) =>
        theme.button.standardGray.backgroundHover};
    }
  }
`;

const CircleButton: React.FC<{ text?: string; onClick?: () => void }> = ({
  text,
  onClick,
}) => {
  return (
    <CircleButtonContainer onClick={onClick}>
      <NavigationButtonDiv buttonSize="medium" icon={Icons.HALF_ARROW_LEFT} />
      {text && <CircleButtonLabel>{text}</CircleButtonLabel>}
    </CircleButtonContainer>
  );
};

const ResultNum: React.FC<{ resultNum: number }> = ({ resultNum }) => {
  const intl = useIntl();
  return (
    <SubTitle>
      {resultNum > 0 ? (
        <FormattedMessage
          {...globalMessages.totalItems}
          values={{
            count: intl.formatNumber(resultNum),
          }}
        />
      ) : (
        // Keep the height while switching queries, where result is 0
        <span>&nbsp;</span>
      )}
    </SubTitle>
  );
};

const BackButton: React.FC<{
  linkProps: LinkProps;
  text: string;
}> = ({ linkProps, text }) => {
  const { searchBoxElement, isSearchMobileUI, showSearchGenres } =
    useSearchInputContext();

  const BackLink = () => (
    <Link {...linkProps}>
      <CircleButton text={text} />
    </Link>
  );

  return (
    <>
      {isSearchMobileUI ? (
        <MobileBackButtonLayout>
          <BackLink />
        </MobileBackButtonLayout>
      ) : (
        searchBoxElement &&
        !showSearchGenres &&
        ReactDOM.createPortal(<BackLink />, searchBoxElement)
      )}
    </>
  );
};

export const MainContentMeta: React.FC<{
  title: string;
  parentTitle?: string;
  subTitle?: string;
  resultNum?: number;
  backButtonProps?: ComponentProps<typeof BackButton>;
  dropdownProps?: {
    selectAttributes: SelectHTMLAttributes<HTMLSelectElement>;
    options: OptionHTMLAttributes<HTMLOptionElement> &
      {
        text: string;
        keyPrefix: string;
      }[];
  };
}> = ({
  title,
  parentTitle,
  subTitle,
  resultNum,
  backButtonProps,
  dropdownProps,
}) => {
  return (
    <MainMetaContainer>
      {backButtonProps && <BackButton {...backButtonProps} />}
      {parentTitle && (
        <SubTitle data-ucn="mainContentHeader-subTitle-text">
          {parentTitle}
        </SubTitle>
      )}
      {dropdownProps ? (
        <DropdownContainer>
          <Title>{title}</Title>
          <StyledIcon name={Icons.HALF_ARROW_DOWN} />
          <Dropdown
            data-ucn="mainContentHeader-dropdown-select"
            {...dropdownProps.selectAttributes}
          >
            {dropdownProps.options.map(
              ({ keyPrefix, text, ...optionAttributes }, index) => (
                <option key={`${keyPrefix}${index}`} {...optionAttributes}>
                  {text}
                </option>
              )
            )}
          </Dropdown>
        </DropdownContainer>
      ) : (
        <Title data-ucn="mainContentHeader-title-text">{title}</Title>
      )}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      {resultNum !== undefined && <ResultNum resultNum={resultNum} />}
    </MainMetaContainer>
  );
};

export const FeatureHeading: React.FC<{
  title: string;
  subTitle?: string;
  saleText?: string;
}> = ({ title, subTitle, saleText }) => {
  return (
    <FeatureTitleContainer>
      <FeatureTitle>{title}</FeatureTitle>
      {subTitle && <FeatureSubTitle>{subTitle}</FeatureSubTitle>}
      {saleText && (
        <FeatureSubTitle>
          <SaleBadge badgeType="white" mobileBadgeSize="small">
            SALE
          </SaleBadge>
          {saleText}
        </FeatureSubTitle>
      )}
    </FeatureTitleContainer>
  );
};

export default {
  BackButton,
  CircleButton,
};
