import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type ButtonHTMLAttributes,
} from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import useIsOffScreen from '../../hooks/useIsOffScreen';
import { useIsUnmounted } from '../../hooks/useIsUnmounted';
import useMedia from '../../hooks/useMedia';
import useWindowSize from '../../hooks/useWindowSize';
import { DEVICE } from '../../styles';
import { COLORS } from '../../styles/theme';
import Icon, { Icons } from '../Common/Icon';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../TitleCard/constants';

const ITEMS_MARGIN = 16;
const ITEMS_MARGIN_MOBILE = 12;

export const BLOCK_PADDING = 56;
export const BLOCK_PADDING_MOBILE = 16;

const SliderContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

const ItemsContainer = styled(animated.ul)<{
  $sidePaddingOverwrite?: number;
}>`
  z-index: 10;
  margin: 0;
  display: flex;
  list-style: none;
  padding: 0
    ${({ $sidePaddingOverwrite }) => $sidePaddingOverwrite ?? BLOCK_PADDING}px;

  @media ${DEVICE.mobileWide} {
    padding: 0
      ${({ $sidePaddingOverwrite }) =>
        $sidePaddingOverwrite ?? BLOCK_PADDING_MOBILE}px;
  }

  @media (pointer: coarse) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-padding: ${BLOCK_PADDING}px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media ${DEVICE.mobileWide} {
      scroll-padding: ${BLOCK_PADDING_MOBILE}px;
    }
  }
`;

const ItemContainer = styled.li<{ $isSliding?: boolean }>`
  margin-right: ${ITEMS_MARGIN}px;
  box-sizing: border-box;
  vertical-align: top;
  // prevent the hover effect while sliding
  pointer-events: ${({ $isSliding }) => ($isSliding ? 'none' : 'auto')};

  @media ${DEVICE.mobileWide} {
    margin-right: ${ITEMS_MARGIN_MOBILE}px;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (pointer: coarse) {
    scroll-snap-align: start;
  }
`;

const ButtonSafeArea = styled.div<{ $direction: 'left' | 'right' }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 56px;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.15s ease-in;

  ${({ $direction }) =>
    $direction === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
          transform: scale(-1);
        `};

  ${SliderContainer}:hover &, ${SliderContainer}:focus-within & {
    opacity: 1;
  }

  @media ${DEVICE.mobileWide} {
    width: 48px;
  }

  @media (pointer: coarse) {
    /* タッチ対応デバイスでは非表示 */
    display: none;
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 80px;

  border-radius: 6px 0px 0px 6px;
  background-color: ${COLORS.midnight_overlay_60};
  box-shadow: 0px 4px 8px 0px rgba(43, 38, 71, 0.1);
  backdrop-filter: blur(8px);
  transition: background-color 0.15s ease-in;

  color: ${COLORS.white};
  cursor: pointer;

  @media ${DEVICE.mobileWide} {
    width: 40px;
    height: 64px;

    border-radius: 6px 0px 0px 6px;
  }

  & > div {
    /* Icon style */
    width: 40px;
    @media ${DEVICE.mobileWide} {
      width: 32px;
    }
  }

  &:hover,
  &:focus {
    background-color: ${COLORS.midnight};
  }
`;

export const COUNT_BLOCK_CLASS_NAME = 'CountBlock';

interface SliderProps {
  type: keyof typeof BLOCK_VIEW_TITLE_CARD_SIZE;
  items: (currentStatus: {
    currentIndex: number;
    activeRange: [number, number];
    isSliding: boolean;
    isOffScreen: boolean;
  }) => JSX.Element[];
  keyPrefix: string;
  zIndex?: number;
  sidePaddingOverwrite?: number;
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
}

const Slider: React.FC<SliderProps> = ({
  type,
  items,
  keyPrefix,
  zIndex = 10,
  sidePaddingOverwrite,
  onClickLeftButton,
  onClickRightButton,
}) => {
  const [currentIndex, setIndex] = useState(0);
  const { width } = useWindowSize();
  const getIsUnmounted = useIsUnmounted();
  const { containerRef, isOffScreen } = useIsOffScreen();

  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );
  const isCoarsePointer = useMedia([DEVICE.coarsePointer], [true], false);

  const ref = useRef<HTMLUListElement>(null);
  const [containerWidth, setContainerWidth] = useState(1400);
  const [isSliding, setIsSliding] = useState(false);
  const titleCardWidthPlusMargin = isMobile
    ? BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE[type].width + ITEMS_MARGIN_MOBILE
    : BLOCK_VIEW_TITLE_CARD_SIZE[type].width + ITEMS_MARGIN;
  const containerWidthWithoutPadding =
    containerWidth - (isMobile ? BLOCK_PADDING_MOBILE : BLOCK_PADDING) * 2;
  const maxItems = Math.max(
    Math.floor(containerWidthWithoutPadding / titleCardWidthPlusMargin),
    1
  );

  const springStyleProps = useSpring({
    transform: `translate3d(-${
      currentIndex * titleCardWidthPlusMargin
    }px, 0, 0)`,
    config: { tension: 500, velocity: 0.02, friction: 60 },
    onRest: () => {
      if (getIsUnmounted()) return;
      setIsSliding(false);
    },
  });

  const propItems = items({
    currentIndex,
    activeRange: [currentIndex, currentIndex + maxItems - 1],
    isSliding,
    isOffScreen,
  });

  const scrollRight = useCallback(() => {
    setIsSliding(true);
    setIndex((cur) =>
      Math.max(Math.min(cur + maxItems, propItems.length - maxItems), 0)
    );
  }, [maxItems, propItems.length]);

  const scrollLeft = useCallback(() => {
    setIsSliding(true);
    setIndex((cur) => Math.max(cur - maxItems, 0));
  }, [maxItems]);

  const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      setContainerWidth(ref.current.clientWidth);
    }
  }, [ref.current?.clientWidth]);

  useEffect(() => {
    if (ref.current) {
      setContainerWidth(ref.current.clientWidth);
    }
  }, [width]);

  const ScrollButton: React.FC<
    { direction: 'right' | 'left' } & ButtonHTMLAttributes<HTMLButtonElement>
  > = ({ direction, ...props }) => {
    return (
      <ButtonSafeArea $direction={direction}>
        <NavButton {...props}>
          <Icon name={Icons.HALF_ARROW_RIGHT} />
        </NavButton>
      </ButtonSafeArea>
    );
  };

  // Transform property will create a new stacking context.
  // So when using coarse pointer (native scroll), to make sure popups are displayed on top of the slider, we need to remove the transform property.
  const itemsContainerStyle = isCoarsePointer ? undefined : springStyleProps;

  return (
    <SliderContainer
      style={{
        zIndex,
      }}
      className={COUNT_BLOCK_CLASS_NAME}
      id={keyPrefix}
      ref={containerRef}
    >
      <ItemsContainer
        $sidePaddingOverwrite={sidePaddingOverwrite}
        style={itemsContainerStyle}
        ref={ref}
      >
        {propItems.map((i, index) => (
          <ItemContainer key={`${keyPrefix}-${index}`} $isSliding={isSliding}>
            {i}
          </ItemContainer>
        ))}
      </ItemsContainer>
      {currentIndex !== 0 && (
        <ScrollButton
          onClick={() => {
            scrollLeft();
            onClickLeftButton && onClickLeftButton();
          }}
          data-ucn="slider-left-btn"
          direction="left"
        />
      )}
      {currentIndex + maxItems < propItems.length && (
        <ScrollButton
          onClick={() => {
            scrollRight();
            onClickRightButton && onClickRightButton();
          }}
          data-ucn="slider-right-btn"
          direction="right"
        />
      )}
    </SliderContainer>
  );
};

export default React.memo(Slider);
