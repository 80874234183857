import { useIntl } from 'react-intl';
import {
  cosmo_getVideoTitleFeatureQuery,
  cosmo_getVideoTitleQuery,
} from '../../../__generated__/globalTypes';
import { globalMessages } from '../../../shared/constants/messages';

import Icon, { Icons } from '../../../shared/components/Common/Icon';
import {
  FlexColumns,
  InfoContent,
  InfoContentFeature,
  InfoContentFeatureHeading,
  InfoCopyright,
  InfoHeading,
  InfoSection,
  InfoText,
  InfoTextSmall,
  NoticeInfoText,
  PositionedAgeRatingBadge,
  ScheduleCopyrightContainer,
} from '../shared/InfoSection';
import messages from './messages';

interface InfoSectionProps {
  title: NonNullable<
    cosmo_getVideoTitleQuery['webfront_title_stage'] &
      cosmo_getVideoTitleFeatureQuery['webfront_title_stage']
  >;
}

const VideoInfoSection = ({ title }: InfoSectionProps) => {
  const intl = useIntl();

  const hasAttractionsOrStory = !!title.attractions || !!title.story;

  return (
    <>
      {(hasAttractionsOrStory || title.sakuhinNotices) && (
        <InfoSection>
          {hasAttractionsOrStory && (
            <FlexColumns>
              {title.attractions && (
                <InfoContent>
                  <InfoHeading>
                    {intl.formatMessage(messages.attractionHeaderText)}
                  </InfoHeading>
                  <InfoText>{title.attractions}</InfoText>
                </InfoContent>
              )}
              {title.story && (
                <InfoContent>
                  <InfoHeading>
                    {intl.formatMessage(messages.storyHeaderText)}
                  </InfoHeading>
                  <InfoText>{title.story}</InfoText>
                </InfoContent>
              )}
            </FlexColumns>
          )}
          {title.check && (
            <InfoContent>
              <InfoHeading>
                {intl.formatMessage(messages.triviaHeaderText)}
              </InfoHeading>
              <InfoText>{title.check}</InfoText>
            </InfoContent>
          )}
          {title.sakuhinNotices && title.sakuhinNotices.length > 0 && (
            <InfoContent hasBorderTop={hasAttractionsOrStory}>
              <InfoHeading>
                <Icon name={Icons.CAUTION} />
                {intl.formatMessage(globalMessages.attention)}
              </InfoHeading>
              <InfoText>
                {title.sakuhinNotices.map((notice, i) => (
                  <NoticeInfoText
                    key={`notice-${i}`}
                  >{`・${notice}`}</NoticeInfoText>
                ))}
              </InfoText>
            </InfoContent>
          )}
          {title.feature?.titleComment && (
            <InfoContentFeature>
              <InfoContentFeatureHeading>
                {intl.formatMessage(messages.featureHeaderText) +
                  title.feature.featureName}
              </InfoContentFeatureHeading>
              <div>{title.feature.titleComment}</div>
            </InfoContentFeature>
          )}
          <ScheduleCopyrightContainer>
            {title.displayPublicEndDate && (
              <InfoContent>
                <InfoTextSmall>{title.displayPublicEndDate}</InfoTextSmall>
              </InfoContent>
            )}
            {(title.copyright || title.restrictedCode) && (
              <InfoCopyright>
                {title.copyright && <div>{title.copyright}</div>}
                {title.restrictedCode && (
                  <PositionedAgeRatingBadge badgeSize="small">
                    {title.restrictedCode}
                  </PositionedAgeRatingBadge>
                )}
              </InfoCopyright>
            )}
          </ScheduleCopyrightContainer>
        </InfoSection>
      )}
    </>
  );
};

export default VideoInfoSection;
