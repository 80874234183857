import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/theme';
import { DailyFreeBadge } from '../../Common/Badge/DaillyFreeBadge';
import { EditThumbnailOverlay } from '../../Edit';
import Container from './containers/Container';
import { BookMetaInfoContainer, BookThumbnailPadding } from './ListBookCard';
import BookThumbnail, { ThumbnailProps } from './Thumbnail';

const MetaInfoContainer = styled(BookMetaInfoContainer)`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
`;

const PurchasedInfo = styled.div`
  font-size: 12px;
`;

const LimitDate = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  color: ${COLORS.white_75};
  min-height: 14px; // need to explicitly declare height, to keep the height while there's no content
`;

type Props = ThumbnailProps & {
  purchasedInfo: string;
  limitDayText: string;
  isDailyFree: boolean;
  isDailyFreePlus: boolean;
};

const PurchasedBookCard: React.FC<Props> = ({
  titleName,
  thumbnailUrl,
  isDailyFree,
  isDailyFreePlus,
  isLazy = false,
  purchasedInfo,
  limitDayText,
}) => {
  const thumbnailProps = {
    thumbnailUrl,
    isLazy,
    titleName,
    // isNew, Do not show new/preorderable badge for purchased book
    // isPreorderable,
  };

  return (
    <Container>
      <BookThumbnailPadding>
        <EditThumbnailOverlay>
          <BookThumbnail {...thumbnailProps} />
        </EditThumbnailOverlay>
      </BookThumbnailPadding>
      <MetaInfoContainer>
        <PurchasedInfo>{purchasedInfo}</PurchasedInfo>
        <LimitDate>
          {(isDailyFree || isDailyFreePlus) && (
            <DailyFreeBadge
              badgeType={isDailyFreePlus ? 'plus' : 'normal'}
              badgeSize="small"
            />
          )}
          <div>{limitDayText}</div>
        </LimitDate>
      </MetaInfoContainer>
    </Container>
  );
};

export default PurchasedBookCard;
