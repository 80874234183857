export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ActiveSubscriptionCancellationError: [
      'SubscriptionCancellationError',
      'UnsupportedError',
    ],
    BillingInfo: [
      'BasicPaymentInfo',
      'CreditCardInfo',
      'MobilePaymentInfo',
      'PaymentCardInfo',
      'SbpsV2PaymentInfo',
    ],
    BookPostPlayResult: [
      'BookPostPlayEmptyResponse',
      'BookPostPlayNextBookResult',
      'BookPostPlayRecommendResponse',
    ],
    BookRecommendBlock: [
      'BookBlock',
      'BookFeatureBlock',
      'BookLeanbackBlock',
      'BookOriginalBlock',
      'BookRankingBlock',
      'BookSearchCategoryBlock',
      'BookSpotlightBlock',
      'BookTemplateBlock',
      'BookUserRecommendedBlock',
      'MagazineBlock',
      'OriginalComicBlock',
    ],
    BookTopBlock: [
      'BookRankingBlock',
      'BookTopRecommendBlock',
      'BookUserRecommendedBlock',
    ],
    CalendarEvent: [
      'GenericCalendarEvent',
      'NTeamSportEvent',
      'NoTeamSportEvent',
      'TwoTeamSportEvent',
    ],
    CampaignInfo: [
      'MonthNoChargeCampaignInfo',
      'PointGrantCampaignInfo',
      'SelectionItemCampaignInfo',
      'TrialAndPointGrantCampaignInfo',
      'TrialCampaignInfo',
    ],
    CheckUpsellEligibilityError: [
      'IapReceiptError',
      'UnsupportedError',
      'UpsellAddonError',
      'UserInputsError',
    ],
    CheckableStatus: [
      'CancelDataChargeResponse',
      'CancelPlanResponse',
      'CancelRunningServiceResponse',
      'CancelSimUserApplicationResponse',
      'CancelYuMobilePlanResponse',
      'ConsentSimUserApplicationResponse',
      'CustomerInquiryResponse',
      'DataChargeResponse',
      'DeleteUnextUserResponse',
      'EmailVerificationResponse',
      'ResetSecurityCodeResponse',
      'SendEmailVerificationCodeResponse',
      'SendEmailVerificationResponse',
      'TerminateUnextAccountResponse',
      'TerminateUserResponse',
      'UpdateSecurityCodeResponse',
      'UserDuplicateCheckResponse',
      'UserPrecheckResponse',
      'UserValidationResponse',
      'ValidateEmailCodeForTwoFactorVerificationResponse',
      'ValidateSmsCodeForTwoFactorVerificationResponse',
      'VerifySimUserApplicationConsentTokenResponse',
    ],
    ContentBlock: [
      'CapyRecommendationBlock',
      'MediaSpotlightBlock',
      'NewMediaRankingBlock',
      'PopularityRankingBlock',
      'RevenueRankingBlock',
      'SalesBlock',
    ],
    ContentCode: ['LiveContentCode', 'VodContentCode'],
    ContentPaginatedResponse: [
      'PaginatedAliasChapterCredit',
      'PaginatedAliasChapterPerformance',
      'PaginatedAliasCredit',
      'PaginatedAliasPerformance',
      'PaginatedBonusMaterials',
      'PaginatedContentBlocks',
      'PaginatedContentTags',
      'PaginatedMediaChapterLight',
      'PaginatedMediaLight',
      'PaginatedSalesBlocks',
      'PaginatedTrailers',
    ],
    ContentProduct: ['ContractProduct', 'LiveTicketProduct'],
    CreateUnextAccountError: [
      'BadAddressError',
      'BadBirthdayError',
      'BadUserNameError',
      'BadZipCodeError',
      'DuplicateEmailAddressError',
      'DuplicateLoginIdError',
      'EmailAddressValidationError',
      'EscottTokenExpiredError',
      'InvalidCreditCardError',
      'InvalidEscottTokenError',
      'InvalidLoginIdError',
      'InvalidPasswordError',
      'NotEligibleForFreeTrialError',
    ],
    CreateUnextUserError: [
      'DuplicateEmailAddressError',
      'DuplicateLoginIdError',
      'EmailAddressValidationError',
      'InvalidLoginIdError',
      'InvalidPasswordError',
      'MaxAccountNumberReachedError',
      'UserInputsError',
    ],
    DeleteUnextUserError: ['NotPermittedError'],
    DepositPointsError: ['InsufficientPointsError'],
    FoodCouponError: ['ConsumeFoodCinemaCouponError'],
    GiftCodeError: ['GiftCodeRejectedError'],
    IapUpsellUserError: [
      'IapReceiptError',
      'IapUpsellError',
      'InsufficientPointsError',
    ],
    InvalidLoginInfoError: ['InvalidLoginIdError', 'InvalidPasswordError'],
    MediaBlock: [
      'CapyRecommendationBlock',
      'MediaSpotlightBlock',
      'NewMediaRankingBlock',
      'PopularityRankingBlock',
      'RevenueRankingBlock',
      'SalesBlock',
    ],
    PaginatedResponse: [
      'DataChargeHistory',
      'PaginatedTempApplications',
      'PurchasableConfigsResponse',
    ],
    PointChargeUserError: [
      'CurrencyAuthorizationError',
      'NoApplicablePaymentMethodFor3DSecureError',
      'NoEmailAddressRegisteredFor3DSecureError',
      'PointChargeError',
      'UnsupportedPlatformError',
    ],
    ProofOfWorkSessionError: [
      'DuplicatePhoneNumberError',
      'InvalidPhoneNumberError',
      'PhoneNumberRejectedError',
    ],
    ProofOfWorkSessionForUpdatePhoneNumberError: [
      'DuplicatePhoneNumberError',
      'InvalidPhoneNumberError',
      'PhoneNumberRejectedError',
    ],
    PurchaseError: [
      'InsufficientPointsError',
      'ProductPurchaseError',
      'PurchaseChargeError',
      'UnsupportedPlatformError',
    ],
    PurchasedUnextContentPack: ['BasicPurchasedContentPack'],
    RecommendedVerificationMethodError: ['MissingSecondFactor'],
    RegisterEscottBillingMethodError: [
      'EscottTokenExpiredError',
      'InvalidCreditCardError',
      'InvalidEscottTokenError',
      'UnsupportedError',
      'UserInputsError',
    ],
    ResetLoginInfoForForgottenPasswordError: [
      'InvalidLoginIdError',
      'InvalidOrExpiredTokenError',
      'InvalidPasswordError',
      'InvalidVerificationCodeError',
    ],
    ResetSecurityCodeError: ['InvalidSecurityCodeError'],
    RunningOption: ['RunningGenericOption', 'RunningIFilter'],
    RunningService: [
      'BasicRunningService',
      'RunningPlan',
      'RunningSimType',
      'RunningVoicePlan',
    ],
    RunningServiceV2: ['BasicRunningServiceV2'],
    RunningSimOption: [
      'RunningCallWaitingSimOption',
      'RunningInternationalCallingSimOption',
      'RunningRoamingSimOption',
      'RunningVoiceMailSimOption',
    ],
    SbpsV1OrderStatusError: [
      'InvalidSbpsV1OrderError',
      'UnsupportedPlatformError',
    ],
    SbpsV1StatusUserError: ['SbpsV1StatusError', 'UnsupportedPlatformError'],
    SbpsV2BillingMethodRegistrationStatusError: [
      'SbpsV2BillingMethodRegistrationNotFoundError',
      'UnsupportedError',
    ],
    SearchHits: [
      'AliasSearchHits',
      'MediaChaptersSearchHits',
      'MediaSearchHits',
    ],
    SendEmailCodeForForgottenPasswordError: [
      'DuplicateEmailError',
      'EmailNotRegisteredError',
    ],
    SendEmailForPartnerLoginInfoUpdateError: [
      'EmailAddressValidationError',
      'InvalidLoginIdError',
      'InvalidPasswordError',
      'InvalidTemporaryPartnerAccount',
    ],
    SendSmsValidationCodeError: [
      'AnswerTooSoonError',
      'IncorrectAnswerError',
      'SmsSendError',
    ],
    SwitchUnextUserError: ['UserInputsError'],
    TerminateUnextAccountError: ['HasActiveContractsError'],
    UNextPurchasedProductItem: [
      'PointPurchasedProductItem',
      'VideoPurchasedProductItem',
    ],
    UnextAccountContractInfo: ['BosContractInfo', 'NsboContractInfo'],
    UnextCinemaTicketResult: ['StandardCinemaTicket', 'TohoCinemaTicket'],
    UnextContentPackProduct: ['BasicUnextContentPackProduct'],
    UnextService: [
      'BasicRunningService',
      'BasicRunningServiceV2',
      'NhkOnDemandService',
    ],
    UnextSubscription: ['BasicUnextSubscription'],
    UnextSubscriptionBundleOrProduct: [
      'BasicUnextSubscriptionProduct',
      'UnextSubscriptionBundle',
    ],
    UnextSubscriptionDepositInfoError: [
      'NoUnextSubscriptionError',
      'NotAddonSubcriptionError',
      'NotAddonSubscriptionError',
    ],
    UnextSubscriptionProduct: ['BasicUnextSubscriptionProduct'],
    UnextUserWithTokenVerificationError: [
      'AuthenticatedUserError',
      'InvalidTokenError',
    ],
    UpdateAccountCustomerInfoError: [
      'BadAddressError',
      'BadBirthdayError',
      'BadUserNameError',
      'BadZipCodeError',
      'PendingApplicationError',
    ],
    UpdatePhoneNumberError: ['IncorrectCodeError', 'SmsValidationError'],
    UpdateSecurityCodeError: ['InvalidSecurityCodeError'],
    UpsellDetailsError: [
      'UnknownLandingPageCodeError',
      'UnsupportedPlatformError',
      'UpsellError',
    ],
    UpsellLandingPageCodeError: [
      'UnsupportedPlatformError',
      'UpsellError',
      'UserInputsError',
    ],
    UpsellUserError: ['UpsellAddonError', 'UpsellError'],
    UserFacingError: [
      'AnswerTooSoonError',
      'AuthenticatedUserError',
      'BadAddressError',
      'BadBirthdayError',
      'BadUserNameError',
      'BadZipCodeError',
      'ConsumeFoodCinemaCouponError',
      'CurrencyAuthorizationError',
      'DuplicateEmailAddressError',
      'DuplicateEmailError',
      'DuplicateLoginIdError',
      'DuplicatePhoneNumberError',
      'EmailAddressValidationError',
      'EmailCodeValidationError',
      'EmailNotRegisteredError',
      'EscottTokenExpiredError',
      'GiftCodeRejectedError',
      'HasActiveContractsError',
      'IapReceiptError',
      'IapUpsellError',
      'InboxEventInputError',
      'IncorrectAnswerError',
      'IncorrectCodeError',
      'InsufficientPointsError',
      'InvalidCreditCardError',
      'InvalidDateTimeError',
      'InvalidEscottTokenError',
      'InvalidLoginIdError',
      'InvalidOrExpiredTokenError',
      'InvalidPasswordError',
      'InvalidPhoneNumberError',
      'InvalidSbpsV1OrderError',
      'InvalidSecurityCodeError',
      'InvalidTemporaryPartnerAccount',
      'InvalidTokenError',
      'InvalidVerificationCodeError',
      'MaxAccountNumberReachedError',
      'MismatchedPhoneNumber',
      'MissingSecondFactor',
      'NoUnextSubscriptionError',
      'NotAddonSubcriptionError',
      'NotAddonSubscriptionError',
      'NotEligibleForFreeTrialError',
      'NotPermittedError',
      'PendingApplicationError',
      'PhoneNumberRejectedError',
      'PointChargeError',
      'ProductPurchaseError',
      'PurchaseChargeError',
      'RevokeSessionError',
      'SbpsV1StatusError',
      'SbpsV2BillingMethodRegistrationNotFoundError',
      'SmsSendError',
      'SmsValidationError',
      'SubscriptionCancellationError',
      'UnknownLandingPageCodeError',
      'UnsupportedError',
      'UnsupportedPlatformError',
      'UpsellAddonError',
      'UpsellError',
      'UserInputsError',
    ],
    UserInfoFor3DSecureError: [
      'NoApplicablePaymentMethodFor3DSecureError',
      'NoEmailAddressRegisteredFor3DSecureError',
    ],
    ValidateEmailCodeForForgottenPasswordError: [
      'InvalidOrExpiredTokenError',
      'InvalidVerificationCodeError',
    ],
    ValidateSmsCodeForTwoFactorVerificationError: [
      'IncorrectCodeError',
      'MismatchedPhoneNumber',
      'SmsValidationError',
    ],
    VideoRecommendBlock: [
      'VideoRecommendFeatureBlock',
      'VideoRecommendLiveBlock',
      'VideoRecommendTitleBlock',
    ],
  },
};
export default result;
