import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { globalMessages } from '../../constants/messages';
import { DEVICE, FONT_FAMILY } from '../../styles/constants';
import { COLORS } from '../../styles/theme';
import Icon, { Icons } from '../Common/Icon';
import { isPoint } from './utils';

export { isPoint };

export const pointBadgeSizes = ['small', 'medium', 'large'] as const;
export type pointBadgeSize = (typeof pointBadgeSizes)[number];

const Price = styled.div`
  font-family: ${FONT_FAMILY.MONOSPACE};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.36px;
`;

const Unit = styled.div`
  margin-left: 1px;

  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0.27px;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 2px;
`;

const StyledIcon = styled(Icon)``;

const LargeStyle = css`
  padding: 3px 8px 3px 3px;

  ${Price} {
    font-size: 12px;
    letter-spacing: 0.36px;
  }

  ${Unit} {
    font-size: 9px;
    letter-spacing: 0.27px;
  }

  ${StyledIcon} {
    width: 14px;
    height: 14px;
  }
`;

const MediumStyle = css`
  padding: 3px 8px 3px 3px;

  ${Price} {
    font-size: 10px;
    letter-spacing: 0.3px;
  }

  ${Unit} {
    font-size: 8px;
    letter-spacing: 0.24px;
  }

  ${StyledIcon} {
    width: 12px;
    height: 12px;
  }
`;

const SmallStyle = css`
  padding: 2px 6px 2px 2px;

  ${Price} {
    font-size: 10px;
    letter-spacing: 0.3px;
  }

  ${Unit} {
    font-size: 8px;
    letter-spacing: 0.24px;
  }

  ${StyledIcon} {
    width: 10px;
    height: 10px;
  }
`;

const Container = styled.div<{
  iconOnly: boolean;
  size: pointBadgeSize;
  mobileSize?: pointBadgeSize;
}>`
  display: inline-flex;
  align-items: center;

  border-radius: 100px;
  background: ${COLORS.white_10};
  color: ${COLORS.white};
  line-height: 1;

  ${({ size }) =>
    size === 'large'
      ? LargeStyle
      : size === 'medium'
      ? MediumStyle
      : SmallStyle};

  @media ${DEVICE.mobileWide} {
    ${({ mobileSize }) =>
      mobileSize === 'large'
        ? LargeStyle
        : mobileSize === 'medium'
        ? MediumStyle
        : mobileSize === 'small'
        ? SmallStyle
        : null};

    ${({ iconOnly }) => iconOnly && 'padding:2px;'};
  }

  ${({ iconOnly }) => iconOnly && 'padding:2px;'};
`;

const PointPriceBadge: React.FC<{
  isPoint: boolean;
  minimumPrice?: number;
  hasMultiplePrice?: boolean;
  badgeSize: pointBadgeSize;
  mobileBadgeSize?: pointBadgeSize;
}> = ({
  isPoint,
  minimumPrice,
  hasMultiplePrice,
  badgeSize,
  mobileBadgeSize,
}) => {
  const intl = useIntl();
  if (!isPoint) return null;

  let priceText = null;
  if (minimumPrice !== undefined && minimumPrice >= 0) {
    priceText = intl.formatNumber(minimumPrice);
  }

  let unitText = null;
  if (priceText) {
    unitText = intl.formatMessage(
      hasMultiplePrice ? globalMessages.minPrice : globalMessages.price,
      { price: '' }
    );
  }

  return (
    <Container
      size={badgeSize}
      mobileSize={mobileBadgeSize}
      iconOnly={!priceText}
      data-ucn="pointPriceBadge"
    >
      <StyledIcon name={Icons.POINT} />
      {priceText && (
        <PriceContainer>
          <Price>{priceText}</Price>
          <Unit>{unitText}</Unit>
        </PriceContainer>
      )}
    </Container>
  );
};

export default PointPriceBadge;
