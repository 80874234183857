import { createContext, PropsWithChildren, useCallback, useState } from 'react';
import { cosmo_userInfoQuery } from '../__generated__/globalTypes';

export type LooseUserInfo =
  | Partial<cosmo_userInfoQuery['userInfo']>
  | undefined;

const LLPUserInfoContext = createContext<{
  llpUserInfo: {
    isInited: boolean;
    userInfo: LooseUserInfo;
  };
  updateLlpUserInfo?: (userInfo: LooseUserInfo) => void;
}>({
  llpUserInfo: {
    isInited: false,
    userInfo: undefined,
  },
  updateLlpUserInfo: undefined,
});

export default LLPUserInfoContext;

export const LLPUserInfoProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [llpUserInfo, setLlpUserInfo] = useState<{
    isInited: boolean;
    userInfo: LooseUserInfo;
  }>({ isInited: false, userInfo: undefined });
  const updateLlpUserInfo = useCallback((llpUserInfo: LooseUserInfo) => {
    setLlpUserInfo({
      isInited: true,
      userInfo: llpUserInfo,
    });
  }, []);

  return (
    <LLPUserInfoContext.Provider
      value={{
        llpUserInfo,
        updateLlpUserInfo,
      }}
    >
      {children}
    </LLPUserInfoContext.Provider>
  );
};
