import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { Nullable } from '../../../../utils/Nullable';
import { globalMessages } from '../../../constants/messages';
import { COLORS } from '../../../styles/theme';
import { RoundBadge } from '../../Common/Badge/RoundBadge';
import { SubDubBadge, subDubType } from '../../Common/Badge/SubDubBadge';
import RatingStar from '../../Common/RatingStar';
import PointPriceBadge from '../../PointPriceBadge';
import { IMAGE_ASPECT, LIST_VIEW_TITLE_CARD_SIZE } from '../constants';

const { width, height } = LIST_VIEW_TITLE_CARD_SIZE.video;

const ProductYear = styled.span`
  font-size: 10px;
  color: ${COLORS.white_50};
  white-space: nowrap;
`;

const RatingStarContainer = styled.div`
  margin-left: auto;
  display: inline-block;
  width: 58px;
`;

const MetaInfoCatchphrase = styled.div<{
  hasUpdateInfo?: boolean;
}>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ hasUpdateInfo }) =>
    hasUpdateInfo
      ? css`
          margin-top: 2px;
          -webkit-line-clamp: 1;
        `
      : css`
          margin-top: 8px;
          -webkit-line-clamp: 2;
        `}
  font-size: 12px;
  color: ${COLORS.white_75};
`;

const MetaInfoUpdateInfo = styled.div`
  margin-top: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
`;

const Container = styled.div`
  height: ${height -
  width / IMAGE_ASPECT.video}px; // カードの高さから画像の高さを引いた値
  padding: 8px 12px 0;
  box-sizing: border-box;
`;

const HorizontalLayout = styled.div`
  display: flex;
  gap: 4px;
  flex-shrink: 0;
  align-items: center;
`;

export interface VideoMetaInfoProps {
  year: Nullable<string>;
  catchphrase: Nullable<string>;
  nfreeBadge?: Nullable<string>;
  hasSub?: Nullable<boolean>;
  hasDub?: Nullable<boolean>;
  rate: Nullable<number>;
  isPoint: boolean;
  price?: number;
  updateInfo?: string;
  hasMultiprice?: boolean;
  className?: string;
}

const VideoMetaInfo: React.FC<VideoMetaInfoProps> = ({
  year,
  catchphrase,
  nfreeBadge,
  hasSub,
  hasDub,
  rate,
  isPoint,
  price,
  updateInfo,
  hasMultiprice,
  className,
}) => {
  const intl = useIntl();

  let subDub: subDubType | undefined = undefined;
  if (hasSub && hasDub) {
    subDub = 'subdub';
  } else if (hasSub) {
    subDub = 'sub';
  } else if (hasDub) {
    subDub = 'dub';
  }
  const yearText = year
    ? `${year}${intl.formatMessage(globalMessages.year)}`
    : '';

  return (
    <Container className={className}>
      <HorizontalLayout>
        <PointPriceBadge
          isPoint={isPoint}
          minimumPrice={price}
          hasMultiplePrice={!!hasMultiprice}
          badgeSize="small"
        />
        {nfreeBadge && (
          <RoundBadge badgeType="ghost_dark" badgeSize="small">
            {nfreeBadge}
          </RoundBadge>
        )}
        {subDub && <SubDubBadge badgeSize="small" badgeType={subDub} />}
        {yearText && <ProductYear>{yearText}</ProductYear>}
        <RatingStarContainer>
          <RatingStar rate={rate ?? 0} iconWidth={10} />
        </RatingStarContainer>
      </HorizontalLayout>
      {updateInfo && <MetaInfoUpdateInfo>{updateInfo}</MetaInfoUpdateInfo>}
      {catchphrase && (
        <MetaInfoCatchphrase hasUpdateInfo={!!updateInfo}>
          {catchphrase}
        </MetaInfoCatchphrase>
      )}
    </Container>
  );
};

export default VideoMetaInfo;
