export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  NumberString: { input: unknown; output: string | number };
};

/**
 * This entity represents a user's account in the Waikiki System. This is NOT the equivalent of a
 * u-next user in the common infrastructure
 */
export type Account = {
  __typename: 'Account';
  /** Currently enrolled account wide options. (DO NOT confuse with SIM options which are options tied to individual SIM users) */
  activeOptions: Maybe<Array<Maybe<RunningOption>>>;
  /** Currently active Plan for this account */
  activePlan: Maybe<RunningPlan>;
  /** Automatic data charge settings */
  autoDataChargeSetting: Maybe<AutoDataChargeSetting>;
  /** Customer information of the contract holder in BOS */
  contractHolder: Maybe<User>;
  /** Data Usage Info for this account */
  dataUsageInfo: Maybe<AccountDataUsageInfo>;
  /** Currently in progress sim card deliveries */
  deliveries: Maybe<Array<Maybe<SimDelivery>>>;
  /** Number of active sim users in this account */
  noOfActiveSimUsers: Scalars['Int']['output'];
  /** Number of new sim user applications that are waiting for consent */
  noOfAgreeingSimUserApplications: Scalars['Int']['output'];
  /**
   * Running price for all of the running services in the account based on the BOS system.
   * Note: the price may be different than if you add up all of the Plan, SimTypes, etc. from the account, because those are fetched from the WaikikiApi
   */
  runningPrice: Maybe<RunningPrice>;
  /** Currently active sim user applications */
  simUserApplications: Maybe<Array<Maybe<SimUserApplication>>>;
  /** Currently active sim users */
  simUsers: Maybe<Array<Maybe<SimUser>>>;
  statusCode: MobileAccountStatus;
  statusName: Scalars['String']['output'];
  userPlatformId: Scalars['ID']['output'];
  /** Account version no for backend concurrency control */
  versionNo: Scalars['Int']['output'];
};

/** Billing methods assignable to UNEXT Accounts */
export enum AccountBillingMethod {
  /** Mobile carrier payments for au via SBPS */
  AU = 'AU',
  /** Mobile carrier payments for docomo via SBPS */
  DOCOMO = 'DOCOMO',
  /** In app purchase, either Google Play Purchase or Apple App Store purchase */
  IAP = 'IAP',
  /** Covers credit cards and debit cards */
  PAYMENT_CARD = 'PAYMENT_CARD',
  /** Mobile carrier payments for softbank via SBPS */
  SOFTBANK = 'SOFTBANK',
}

/**
 * Customer information attached to a UnextAccount.
 * This information should require a high degree of authorization because it is also used by operators to verify customer identies over the phone
 */
export type AccountCustomerInfo = {
  __typename: 'AccountCustomerInfo';
  /** Customer registered address */
  address: Maybe<AccountCustomerInfoAddress>;
  /** Customer Date of Birth */
  dateOfBirth: Maybe<Scalars['DateTime']['output']>;
  /** Customer first name (kanji) */
  firstName: Maybe<Scalars['String']['output']>;
  /** Customer first name (phonetic katakana) */
  firstNameKana: Maybe<Scalars['String']['output']>;
  /** Customer gender */
  gender: Maybe<Gender>;
  /** User platform id */
  id: Scalars['ID']['output'];
  /** Customer last name (kanji) */
  lastName: Maybe<Scalars['String']['output']>;
  /** Customer last name (phonetic katakana) */
  lastNameKana: Maybe<Scalars['String']['output']>;
};

/** Expresses an address in Japan (served from account-graph) */
export type AccountCustomerInfoAddress = {
  __typename: 'AccountCustomerInfoAddress';
  /** Address Line 1. Should cover everything until 町 */
  addressLine1: Maybe<Scalars['String']['output']>;
  /** Address line 2. Should cover building name, room number etc. */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** Should cover everything else that doesn't fit */
  addressLine3: Maybe<Scalars['String']['output']>;
  /** 7-digit postal code without hyphen */
  postalCode: Maybe<Scalars['String']['output']>;
  /** Prefecture in Kanji. Is coverted into a JIS code in the backend */
  prefecture: Maybe<Scalars['String']['output']>;
};

/** Address input for customer information input */
export type AccountCustomerInfoAddressInput = {
  /** Address Line 1. Should cover everything until 町 */
  addressLine1: Scalars['String']['input'];
  /** Address line 2. Should cover building name, room number etc. */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Should cover everything else that doesn't fit */
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  /** 7-digit postal code without hyphen */
  postalCode: Scalars['String']['input'];
  /** Prefecture in Kanji. Is coverted into a JIS code in the backend */
  prefecture: Scalars['String']['input'];
};

/** Customer Info Input for updating an existing account */
export type AccountCustomerInfoInput = {
  /** Customer registered address */
  address: AccountCustomerInfoAddressInput;
  /** Customer Date of Birth */
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  /** Customer first name (kanji) */
  firstName: Scalars['String']['input'];
  /** Customer first name (phonetic katakana) */
  firstNameKana: Scalars['String']['input'];
  /** Customer gender */
  gender: GenderInput;
  /** Customer last name (kanji) */
  lastName: Scalars['String']['input'];
  /** Customer last name (phonetic katakana) */
  lastNameKana: Scalars['String']['input'];
};

/** Data Usage/Charge history information for a given user account in the Waikiki System */
export type AccountDataUsageInfo = {
  __typename: 'AccountDataUsageInfo';
  /** Data charge history */
  chargeHistory: Maybe<DataChargeHistory>;
  /** Current remaining data capacity in megabytes */
  currentRemainingCapacity: Maybe<Scalars['Int']['output']>;
  /** Daily data usage amount */
  usageHistory: Maybe<Array<Maybe<DataUsageHistoryEntry>>>;
};

/** Data Usage/Charge history information for a given user account in the Waikiki System */
export type AccountDataUsageInfochargeHistoryArgs = {
  endDate: Scalars['DateTime']['input'];
  pageSpecifier?: InputMaybe<PageSpecifier>;
  startDate: Scalars['DateTime']['input'];
};

/** Data Usage/Charge history information for a given user account in the Waikiki System */
export type AccountDataUsageInfousageHistoryArgs = {
  endDate: Scalars['DateTime']['input'];
  groupByMonth?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['DateTime']['input'];
};

/** Migration statuses */
export type AccountMigrationStatus = {
  __typename: 'AccountMigrationStatus';
  /**
   * This field indicates the progress of an external account being migrated to a UNEXT backend So far, the only case is where a user is being migrated from Paravi to UNEXT
   * The number of situations could increase in the future.
   * DO NOT confuse this with NSBO => BOS migration and "Migration Status"
   */
  externalAccountMigrationStatus: Maybe<ExternalAccountMigrationStatus>;
  /** Denotes whether the user can be migrated from the NSBO system to BOS. This value is null if the current user is not a MAIN user or if the user has already been migrated */
  nsboToBosMigrationStatus: Maybe<NsboToBosMigrationStatus>;
};

export enum AccountTypeCode {
  MAIN = 'MAIN',
  SUB = 'SUB',
}

/** Response for activating mnp */
export type ActivateMnpResponse = {
  __typename: 'ActivateMnpResponse';
  status: Maybe<Scalars['String']['output']>;
};

/**
 * ActiveCoupon refers to a campaign coupon that has been applied to a UnextAccount
 * Covered here: https://wiki.unext-info.jp/pages/viewpage.action?pageId=98499269
 */
export type ActiveCoupon = {
  __typename: 'ActiveCoupon';
  /** Coupon Benefit Details */
  couponDetails: Scalars['String']['output'];
  /** Date the coupon was applied. Is null if coupon hasn't been applied yet */
  dateApplied: Maybe<Scalars['DateTime']['output']>;
  /** Coupon Expiry Date */
  expiryDate: Scalars['DateTime']['output'];
  /** Coupon Code */
  id: Scalars['ID']['output'];
  /** Has the coupon been applied */
  isApplied: Scalars['Boolean']['output'];
};

export type ActiveSessionsResponse = {
  __typename: 'ActiveSessionsResponse';
  sessions: Array<DeviceSession>;
};

/** union for errors encountered in cancelling active subscriptions */
export type ActiveSubscriptionCancellationError =
  | SubscriptionCancellationError
  | UnsupportedError;

/** Server response when cancelling an active subscription */
export type ActiveSubscriptionCancellationResponse = {
  __typename: 'ActiveSubscriptionCancellationResponse';
  /** Id of the cancelled subscription */
  id: Scalars['ID']['output'];
  /** DateTime that the unsubscribe action was applied */
  unsubscribeDateTime: Scalars['DateTime']['output'];
};

/** Response to the addAmazonIapSubscription mutation */
export type AddAmazonIapSubscriptionResponse = {
  __typename: 'AddAmazonIapSubscriptionResponse';
  /** The subscription the user has been added to */
  subscription: Maybe<UnextSubscription>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<IapUpsellUserError>;
};

/** Response to the addAppleIapSubscription mutation */
export type AddAppleIapSubscriptionResponse = {
  __typename: 'AddAppleIapSubscriptionResponse';
  /** The subscription the user has been added to */
  subscription: Maybe<UnextSubscription>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<IapUpsellUserError>;
};

/** Response to the addGoogleIapSubscription mutation */
export type AddGoogleIapSubscriptionResponse = {
  __typename: 'AddGoogleIapSubscriptionResponse';
  /** The subscription the user has been added to */
  subscription: Maybe<UnextSubscription>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<IapUpsellUserError>;
};

/** Response to the addSubscription mutation */
export type AddSubscriptionResponse = {
  __typename: 'AddSubscriptionResponse';
  /** The subscription the user has been added to */
  subscription: Maybe<UnextSubscription>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<UpsellUserError>;
};

/** Input for getting additional customer inquiry */
export type AdditionalCustomerInquiry = {
  details?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Additional Question Answer */
export type AdditionalQuestionAnswer = {
  __typename: 'AdditionalQuestionAnswer';
  /** Additional Question Children */
  children: Maybe<Array<AdditionalQuestionChildren>>;
  /** Detailed Answers */
  detailedAnswers: Maybe<Array<AdditionalQuestionDetailedAnswer>>;
  /** Display Type */
  displayType: Scalars['String']['output'];
};

/** Additional Question Children */
export type AdditionalQuestionChildren = {
  __typename: 'AdditionalQuestionChildren';
  /** Display Type */
  displayType: Maybe<Scalars['String']['output']>;
  /** Linnked ID */
  linkedId: Scalars['ID']['output'];
  /** Placeholder */
  placeholder: Maybe<Scalars['String']['output']>;
};

/** Additional Question Detailed Answer */
export type AdditionalQuestionDetailedAnswer = {
  __typename: 'AdditionalQuestionDetailedAnswer';
  /** Display Type */
  displayType: Scalars['String']['output'];
  /** ID */
  id: Scalars['String']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Placeholder */
  placeholder: Maybe<Scalars['String']['output']>;
};

/** Additional Questions */
export type AdditionalQuestions = {
  __typename: 'AdditionalQuestions';
  /** Answers */
  answers: Maybe<Array<AdditionalQuestionAnswer>>;
  /** ID */
  id: Scalars['ID']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Pattern ID */
  patternId: Scalars['ID']['output'];
};

/** Response for getting additional questions */
export type AdditionalQuestionsResponse = {
  __typename: 'AdditionalQuestionsResponse';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions: Maybe<Array<AdditionalQuestions>>;
  sectionName: Scalars['String']['output'];
};

export type Address = {
  __typename: 'Address';
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  addressLine3: Maybe<Scalars['String']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  prefecture: Maybe<Scalars['String']['output']>;
};

export type Alias = {
  __typename: 'Alias';
  cmsBookAliasXid: Maybe<Scalars['String']['output']>;
  cmsVideoAliasXid: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
  nameKana: Scalars['String']['output'];
  nameKanaNormalized: Scalars['String']['output'];
  nameNormalizedJa: Scalars['String']['output'];
  paginatedChapterCredits: PaginatedAliasChapterCredit;
  paginatedChapterPerformances: PaginatedAliasChapterPerformance;
  paginatedMediaCredits: PaginatedAliasCredit;
  paginatedMediaPerformances: PaginatedAliasPerformance;
};

export type AliaspaginatedChapterCreditsArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type AliaspaginatedChapterPerformancesArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type AliaspaginatedMediaCreditsArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type AliaspaginatedMediaPerformancesArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type AliasChapterCredit = {
  __typename: 'AliasChapterCredit';
  chapter: MediaChapterLight;
  type: Maybe<Scalars['String']['output']>;
};

export type AliasChapterPerformance = {
  __typename: 'AliasChapterPerformance';
  chapter: MediaChapterLight;
  characterName: Maybe<Scalars['String']['output']>;
};

export type AliasCredit = {
  __typename: 'AliasCredit';
  media: MediaLight;
  type: Maybe<Scalars['String']['output']>;
};

export type AliasLight = {
  __typename: 'AliasLight';
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
  nameKana: Scalars['String']['output'];
  nameKanaNormalized: Scalars['String']['output'];
  nameNormalizedJa: Scalars['String']['output'];
};

export type AliasPerformance = {
  __typename: 'AliasPerformance';
  characterName: Maybe<Scalars['String']['output']>;
  media: MediaLight;
};

export type AliasSearchHits = SearchHits & {
  __typename: 'AliasSearchHits';
  hasMore: Scalars['Boolean']['output'];
  hits: Array<AliasLight>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/**
 * The code value representation of external users' origin
 * Affects things like what logo should be displayed, ex: did the user sign up with a partner service
 */
export enum AllianceCode {
  /** Users from ABLE */
  ABLE = 'ABLE',
  /** Users from イオンシネマ WEBスクリーン powered by U-NEXT */
  AEON_CINEMA = 'AEON_CINEMA',
  /** Users from ALL_BLUE */
  ALL_BLUE = 'ALL_BLUE',
  /** Users from ARTERIA_NETWORKS */
  ARTERIA_NETWORKS = 'ARTERIA_NETWORKS',
  /** Users from AU PACK */
  AU_PACK = 'AU_PACK',
  /** Users from BBM, a division within CCC/Tsutaya */
  BBM = 'BBM',
  /** Users from BENEFIT_JAPAN */
  BENEFIT_JAPAN = 'BENEFIT_JAPAN',
  /** Users from BIC_CAMERA */
  BIC_CAMERA = 'BIC_CAMERA',
  /** Users from BIGLOBE */
  BIGLOBE = 'BIGLOBE',
  /** 東芝のBooKplaceマイグレ */
  BP4T = 'BP4T',
  /** Users from BRAVIAマイグレ */
  BRAVIA = 'BRAVIA',
  /** Users from BROAD_ACE */
  BROAD_ACE = 'BROAD_ACE',
  /** Users from 中国電力 */
  CHUGOKU_DENRYOKU = 'CHUGOKU_DENRYOKU',
  /** Users from CTC (コミュファ光連携) */
  CTC = 'CTC',
  /** Users from Daiwa House */
  DAIWA_HOUSE = 'DAIWA_HOUSE',
  /** Users from Daiwa Living */
  DAIWA_LIVING = 'DAIWA_LIVING',
  /** Users from DECKS */
  DECKS = 'DECKS',
  /** Users from EDION */
  EDION = 'EDION',
  /** Users from ENECOM */
  ENECOM = 'ENECOM',
  /** Users from EXPO */
  EXPO = 'EXPO',
  /** Users from FLC */
  FLC = 'FLC',
  /** Users from FORVALTEL */
  FORVALTEL = 'FORVALTEL',
  /** 過去の施策に利用 */
  FRAU = 'FRAU',
  /** Users from ハルカデイズ */
  HARUKADAYS = 'HARUKADAYS',
  /** Users from HIBIT */
  HIBIT = 'HIBIT',
  /** Users from HI_HO */
  HI_HO = 'HI_HO',
  /** Users from JCOTT */
  JCOTT = 'JCOTT',
  /** Users from Joshin */
  JOSHIN = 'JOSHIN',
  /** Users from J_PCS */
  J_PCS = 'J_PCS',
  /** Users from Kanden */
  KANDEN = 'KANDEN',
  /** Users from 大東建託 */
  KENTAKU = 'KENTAKU',
  /** Users from K_OPTI */
  K_OPTI = 'K_OPTI',
  /** Users from LEQUIOS */
  LEQUIOS = 'LEQUIOS',
  /** Users from ミニテック */
  MINITECH = 'MINITECH',
  /** Users from Misawa Home */
  MISAWA_HOME = 'MISAWA_HOME',
  /** NBO→NSBOへのマイグレ */
  NBO = 'NBO',
  /** Users from NEXT_BB */
  NEXT_BB = 'NEXT_BB',
  /** All other unrecognised alliance codes */
  OTHER = 'OTHER',
  /**
   * Users from paravi
   * Also known to take the value of 201
   */
  PARAVI = 'PARAVI',
  /** Users from PC_DEPOT */
  PC_DEPOT = 'PC_DEPOT',
  /** Users from Prestige (プレステージ社員福利厚生) */
  PRESTIGE = 'PRESTIGE',
  /** Users from QT_NET */
  QT_NET = 'QT_NET',
  /** Users from SALES_P */
  SALES_P = 'SALES_P',
  /** Users from 積和不動産 */
  SEKISUI_HOUSE = 'SEKISUI_HOUSE',
  /** Users from SHARP */
  SHARP = 'SHARP',
  /** SOD社員福利厚生 */
  SOD = 'SOD',
  /** Users from SOPHIA */
  SOPHIA = 'SOPHIA',
  /** Users from SO_NET_ISP */
  SO_NET_ISP = 'SO_NET_ISP',
  /** Users from SO_NET_NEW */
  SO_NET_NEW = 'SO_NET_NEW',
  /** Users from ST_NET */
  ST_NET = 'ST_NET',
  /** Users from TELECOM_GROUP */
  TELECOM_GROUP = 'TELECOM_GROUP',
  /** Users from Three Corporation */
  THREE_CORP = 'THREE_CORP',
  /** Users from TJC */
  TJC = 'TJC',
  /** Users from tsutaya */
  TSUTAYA = 'TSUTAYA',
  /** Users from UMX */
  UMX = 'UMX',
  /** Users from UQ_WIMAX */
  UQ_WIMAX = 'UQ_WIMAX',
  /** Users from USEN_HIKARI */
  USEN_HIKARI = 'USEN_HIKARI',
  /** Users from ワコーエステート */
  WAKO_ESTATE = 'WAKO_ESTATE',
  /** Users from ヤマダビデオ(EM) */
  YAMADA_VIDEO_EM = 'YAMADA_VIDEO_EM',
  /** Users from ヤマダビデオ(ケイタイde安心) */
  YAMADA_VIDEO_KEITAI = 'YAMADA_VIDEO_KEITAI',
}

/** Server response wrapping the payment methods allowed for a given UnextAccount */
export type AllowedBillingMethodsResponse = {
  __typename: 'AllowedBillingMethodsResponse';
  /**
   * A list of all of the billing methods allowed for this UnextAccount.
   * This list varies based on what subscriptions the account has active,
   * e.g. Accounts with a YuMobile subscription are locked to payment card payments
   */
  allowedBillingMethods: Array<AccountBillingMethod>;
};

/** Enum allowing distinction between Amazon Fire TV (cube, stick, etc.) and the Amazon Fire Tablet */
export enum AmazonDeviceTypeInput {
  /** For users who are accessing U-NEXT via a Fire Tablet */
  TABLET = 'TABLET',
  /** For users who are accessing U-NEXT via the Amazon Fire TV app on a SmartTV, FireTV cube, stick, etc. */
  TV = 'TV',
}

/** Error for when answer was submitted too soon. */
export type AnswerTooSoonError = UserFacingError & {
  __typename: 'AnswerTooSoonError';
  /** The error message. */
  message: Scalars['String']['output'];
};

export type AppVersion = {
  __typename: 'AppVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

export type Application = {
  __typename: 'Application';
  applicationDateTime: Scalars['DateTime']['output'];
  comments: Maybe<Scalars['String']['output']>;
  completedDateTime: Maybe<Scalars['DateTime']['output']>;
  customerInfo: Maybe<CustomerInfo>;
  id: Scalars['ID']['output'];
  options: Maybe<Array<Maybe<RunningOption>>>;
  plan: Maybe<RunningPlan>;
  routeCode: Scalars['String']['output'];
  routeName: Scalars['String']['output'];
  sendContractFlag: Scalars['Boolean']['output'];
  simUserApplications: Maybe<Array<Maybe<SimUserApplication>>>;
  staffCode: Maybe<Scalars['String']['output']>;
  staffName: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  statusCode: ApplicationStatus;
  statusName: Scalars['String']['output'];
  storeAccountId: Maybe<Scalars['String']['output']>;
  typeCode: ApplicationType;
  typeName: Scalars['String']['output'];
  userPlatformId: Scalars['ID']['output'];
  versionNo: Scalars['Int']['output'];
};

/** Input for submitting application from document ID */
export type ApplicationIdDocumentsInput = {
  applicationId: Scalars['ID']['input'];
  /** Please be aware that this is not customer info input because the CRS reps have to check things not included l */
  customerInfo: InfoForConfirmationInput;
  identityDocumentUris: Array<InputMaybe<Scalars['String']['input']>>;
  simUserApplicationId: Scalars['ID']['input'];
  /** Parameter for concurrency protection. Please get most recent version number using the getApplication query */
  versionNo: Scalars['Int']['input'];
};

/** Input for submitting application */
export type ApplicationInput = {
  billingInfo?: InputMaybe<BillingInfoInput>;
  customerInfo: CustomerInfoInput;
  entryCampaignCode?: InputMaybe<Scalars['String']['input']>;
  entryCode: Scalars['String']['input'];
  entryLandingPageCode: Scalars['String']['input'];
  entrySalesAgencyCode: Scalars['String']['input'];
  isContractHolder: Scalars['Boolean']['input'];
  isFirstContractorsSim: Scalars['Boolean']['input'];
  isUnder18: Scalars['Boolean']['input'];
  mnp?: InputMaybe<MnpInput>;
  options: Array<InputMaybe<OptionInput>>;
  plan: PlanInput;
  routeCode: ApplicationRoute;
  sendContract: Scalars['Boolean']['input'];
  simType: SimTypeInput;
  voicePlan?: InputMaybe<VoicePlanInput>;
};

export type ApplicationRejection = {
  __typename: 'ApplicationRejection';
  code: Maybe<RejectionCode>;
  /** Alias for Application sim user ng history id */
  id: Scalars['ID']['output'];
  reason: Maybe<Scalars['String']['output']>;
  rejectionDateTime: Maybe<Scalars['DateTime']['output']>;
  statusName: Maybe<Scalars['String']['output']>;
};

/** Response for getApplication */
export type ApplicationResponse = {
  __typename: 'ApplicationResponse';
  applicationCustomerId: Maybe<Scalars['Int']['output']>;
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  applicationPlanId: Maybe<Scalars['Int']['output']>;
  comments: Maybe<Scalars['String']['output']>;
  completedDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  routeCode: Maybe<Scalars['String']['output']>;
  routeName: Maybe<Scalars['String']['output']>;
  sendContractFlag: Maybe<Scalars['Boolean']['output']>;
  /** simUserApplication is the first simUserApplication that is created as part of the submitApplication mutation */
  simUserApplication: Maybe<SimUserApplication>;
  staffName: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  statusCode: Maybe<ApplicationStatus>;
  statusName: Maybe<Scalars['String']['output']>;
  typeCode: Maybe<ApplicationType>;
  typeName: Maybe<Scalars['String']['output']>;
  userPlatformId: Maybe<Scalars['String']['output']>;
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** The route of the application */
export enum ApplicationRoute {
  UNEXT_STORE_OTA = 'UNEXT_STORE_OTA',
  WEB = 'WEB',
  YAMADA_OTA = 'YAMADA_OTA',
}

export enum ApplicationStatus {
  /** 申込 */
  APPLIED = 'APPLIED',
  /** 無効 */
  CANCELED = 'CANCELED',
  /** 契約完了 */
  CONTRACTED = 'CONTRACTED',
  /** 審査NG */
  DECLINED = 'DECLINED',
  /** 審査可能 */
  JUDGEABLE = 'JUDGEABLE',
  /** 本人審査完了 */
  JUDGED = 'JUDGED',
  /** 審査中 */
  JUDGING = 'JUDGING',
  /** 再審査依頼 */
  REAPPLYING = 'REAPPLYING',
}

/** The type of the application */
export enum ApplicationType {
  /** 新規 */
  NEW = 'NEW',
  /** SIM追加 */
  SIM = 'SIM',
}

/** Server response upon attempting to apply a giftcode. */
export type ApplyGiftCodeResponse = {
  __typename: 'ApplyGiftCodeResponse';
  /** Details of the campaign associated with the giftcode */
  appliedCampaignInfo: Maybe<CampaignInfo>;
  /** Given giftCode */
  giftCode: Scalars['String']['output'];
  /** Errors on the input. */
  userErrors: Array<GiftCodeError>;
};

export enum AudioCodec {
  AACLC = 'AACLC',
  EAC3 = 'EAC3',
  EAC3JOC = 'EAC3JOC',
  FLAC = 'FLAC',
  HEAACV2 = 'HEAACV2',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum AudioCodecType {
  AAC = 'AAC',
  DOLBY_DIGITAL = 'DOLBY_DIGITAL',
  DOLBY_DIGITAL_ATMOS = 'DOLBY_DIGITAL_ATMOS',
  DOLBY_DIGITAL_PLUS = 'DOLBY_DIGITAL_PLUS',
}

/** AudioProfile is a audio format set for video */
export type AudioProfile = {
  __typename: 'AudioProfile';
  codec: AudioCodec;
};

export type AudioType = {
  __typename: 'AudioType';
  audioType: Maybe<Scalars['String']['output']>;
};

/** Returned if the user needs to log out in order to create a new sub-user */
export type AuthenticatedUserError = UserFacingError & {
  __typename: 'AuthenticatedUserError';
  message: Scalars['String']['output'];
};

export type AutoCompleteResponse = {
  __typename: 'AutoCompleteResponse';
  nodes: Maybe<Array<SearchSuggestionNode>>;
};

/** Automatic data charge settings */
export type AutoDataChargeSetting = {
  __typename: 'AutoDataChargeSetting';
  /** No of GB to charge per month. (Defaults to 0 for no automatic data charge) */
  monthlyDataChargeValue: Maybe<Scalars['Int']['output']>;
  /** Upper limit data capacity limit */
  upperLimit: Maybe<Scalars['Int']['output']>;
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Response for getAvailablePlans */
export type AvailablePlansResponse = {
  __typename: 'AvailablePlansResponse';
  availablePlans: Maybe<Array<Plan>>;
  canSwitchPlan: Maybe<Scalars['Boolean']['output']>;
};

/** Response for getAvailableYuMobilePlans */
export type AvailableYuMobilePlansResponse = {
  __typename: 'AvailableYuMobilePlansResponse';
  availablePlans: Maybe<Array<YuMobilePlan>>;
  canSwitchPlan: Maybe<Scalars['Boolean']['output']>;
};

/** Provided address was rejected */
export type BadAddressError = UserFacingError & {
  __typename: 'BadAddressError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Provided birthday was rejected */
export type BadBirthdayError = UserFacingError & {
  __typename: 'BadBirthdayError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Provided user name was rejected */
export type BadUserNameError = UserFacingError & {
  __typename: 'BadUserNameError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Provided zip code was rejected */
export type BadZipCodeError = UserFacingError & {
  __typename: 'BadZipCodeError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Basic payment information that does not return any more information than the name and payment method code */
export type BasicPaymentInfo = {
  __typename: 'BasicPaymentInfo';
  /** Refers to the settlement type code provided by NSBO */
  id: Scalars['ID']['output'];
  /** Name of the payment method, e.g NTT回収代行 */
  name: Scalars['String']['output'];
};

/** Basic UnextContentPack */
export type BasicPurchasedContentPack = PurchasedUnextContentPack & {
  __typename: 'BasicPurchasedContentPack';
  /** Date the pack was applied for */
  applicationDateTime: Scalars['DateTime']['output'];
  /** Current Billing state */
  billingState: Maybe<ContentPackBillingState>;
  /**
   * Contractually expiration date time for displaying to users
   *
   * Example:
   * The expiration data time we show user is 1/31 23:59:59(displayExpirationDateTime)
   * and in fact the content pack expires at 2/2 00:00:00(expirationDateTime)
   * because we need time to refresh expire date of all users.
   */
  displayExpirationDateTime: Scalars['DateTime']['output'];
  /** Real expiration date time we check in app logic */
  expirationDateTime: Scalars['DateTime']['output'];
  /**
   * Identifier for an instance of a purchased content pack. This is not the id of the content pack product
   * @deprecated Renamed to productId
   */
  id: Scalars['ID']['output'];
  /** Date the pack was last updated */
  lastUpdated: Scalars['DateTime']['output'];
  /** Content pack name */
  name: Maybe<Scalars['String']['output']>;
  /** Reason pack was interrupted */
  pauseReason: Maybe<Scalars['String']['output']>;
  /** Content pack product information */
  product: UnextContentPackProduct;
  /** Identifier for an instance of a purchased content pack. This is not the id of the content pack product */
  productId: Scalars['ID']['output'];
  /** Content pack type code */
  typeCode: ContentPackType;
};

/** Basic running service implementation without any additional fields. Used to represent RunningServices that do not have additional fields */
export type BasicRunningService = RunningService & {
  __typename: 'BasicRunningService';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Running Service ID used on BOS */
  id: Scalars['ID']['output'];
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** Free trial period */
  serviceFreeTrialPeriod: Maybe<TimePeriod>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
};

/** Basic running service V2 without any additional fields. Used to represent RunningServices that do not have additional fields */
export type BasicRunningServiceV2 = RunningServiceV2 & {
  __typename: 'BasicRunningServiceV2';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  externalChargeType: Maybe<Scalars['String']['output']>;
  /** Running Service ID used on BOS */
  id: Scalars['ID']['output'];
  isInFreeTrialPeriod: Scalars['Boolean']['output'];
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatusCode>;
  /** Free trial period */
  serviceFreeTrialPeriodEnd: Maybe<Scalars['DateTime']['output']>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
  serviceType: Maybe<Scalars['String']['output']>;
};

/** Basic Unext content pack that does not contain any specialized fields */
export type BasicUnextContentPackProduct = UnextContentPackProduct & {
  __typename: 'BasicUnextContentPackProduct';
  /** Point rebate for purchasing this product */
  chargePoint: Scalars['Int']['output'];
  /** Product Id */
  id: Scalars['ID']['output'];
  /** Product name */
  name: Scalars['String']['output'];
  /** Price for this product */
  price: ProductPrice;
  /**
   * Terms of service addendum
   * e.g. "※ 一部の作品は、放送1週間後から単品でのレンタルが可能となります。
   */
  termsOfServiceAddendum: Maybe<Scalars['String']['output']>;
  /** The actual body of the terms of service */
  termsOfServiceBody: Maybe<Scalars['String']['output']>;
  /**
   * Terms of service heading
   * e.g. NHKオンデマンドについて
   */
  termsOfServiceHeading: Maybe<Scalars['String']['output']>;
  /**
   * Terms of service Image url
   * e.g. https://imgc.nxtv.jp/img/info/NOD/logo/nhk_ondemand.png
   */
  termsOfServiceImageUrl: Maybe<Scalars['String']['output']>;
  /**
   * Terms of service title
   * e.g. NHKオンデマンドについて
   */
  termsOfServiceTitle: Maybe<Scalars['String']['output']>;
  /** Product type code */
  typeCode: Scalars['String']['output'];
};

/** Typical monthly subscription e.g. Video Subscription etc. */
export type BasicUnextSubscription = UnextSubscription & {
  __typename: 'BasicUnextSubscription';
  /** The Date time this service was applied for */
  applicationDateTime: Scalars['DateTime']['output'];
  /** Identifier */
  externalChargeType: Maybe<Scalars['String']['output']>;
  /** Id for this instance of an active subscription */
  id: Scalars['ID']['output'];
  /** Product is still in its free trial */
  isInFreeTrialPeriod: Scalars['Boolean']['output'];
  /** The subscription that is in effect */
  product: Maybe<UnextSubscriptionProduct>;
  /** Free trial period end date */
  subscriptionFreeTrialPeriodEnd: Maybe<Scalars['DateTime']['output']>;
  /** The date time this service came into affect */
  subscriptionStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /**
   * Penalty fee that occurs if the contract is terminated.
   * Returns a value of 0 for subscriptions with no penalty
   */
  subscriptionTerminationPenaltyFee: Scalars['Int']['output'];
};

/** Basic web subscription product offered by either NSBO or BOS */
export type BasicUnextSubscriptionProduct = UnextSubscriptionProduct & {
  __typename: 'BasicUnextSubscriptionProduct';
  /** Amazon SKU (stock keeping unit). Identifies the Amazon IAP Subscription Product */
  amazonSku: Maybe<Scalars['ID']['output']>;
  /** Amazon Term SKU. Identifies the time variant of the Subscription Product. e.g. 1-month, 3-month, 6-month, etc. subscription */
  amazonTermSku: Maybe<Scalars['ID']['output']>;
  /** Product ID of the corresponding AppStore product. Only populated for Apple IAP products */
  appStoreProductId: Maybe<Scalars['ID']['output']>;
  /**
   * A bundle that this subscription product is part of.
   * Will return null if this subscription product is not a member of a bundle
   */
  bundle: Maybe<UnextSubscriptionBundle>;
  /** Whether or not points can be used to pay for this subscription */
  canChargeWithPoints: Scalars['Boolean']['output'];
  /** Display caption for the subscription product */
  caption: Maybe<Scalars['String']['output']>;
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** typeCode: String! */
  explanationText: Maybe<Array<Scalars['String']['output']>>;
  /** Product ID of the correspondingG Google Play product. Only populated for Google IAP products */
  googlePlayProductId: Maybe<Scalars['ID']['output']>;
  /** Subscription product id */
  id: Scalars['ID']['output'];
  /** Refers to whether or not this is a unext add-on subscription */
  isUnextAddonSubscription: Scalars['Boolean']['output'];
  /** Name of the subscription product */
  name: Scalars['String']['output'];
  /** Subscription Price */
  price: ProductPrice;
  /** Url to the sign-up page */
  signUpUrl: Maybe<Scalars['String']['output']>;
  /** Sub caption for extra details */
  subCaption: Maybe<Scalars['String']['output']>;
  /** Url for embedded terms and conditions */
  termsEmbedUrl: Maybe<Scalars['String']['output']>;
  /** Url for web terms and conditions */
  termsWebUrl: Maybe<Scalars['String']['output']>;
  /** Subscription type code */
  typeCode: Scalars['String']['output'];
};

export type BeemiInfoListItem = {
  __typename: 'BeemiInfoListItem';
  title: Scalars['String']['output'];
  ttl: Scalars['String']['output'];
  type: Maybe<BeemiInfoListItemType>;
  url: Scalars['String']['output'];
};

export enum BeemiInfoListItemType {
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export type BillingCaution = {
  __typename: 'BillingCaution';
  description: Scalars['String']['output'];
  linkUrl: Maybe<Scalars['String']['output']>;
  suggestion: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

/** Additional payment types are expeccted to be appended to the end of this union type */
export type BillingInfo =
  | BasicPaymentInfo
  | CreditCardInfo
  | MobilePaymentInfo
  | PaymentCardInfo
  | SbpsV2PaymentInfo;

/** Input for get billing info */
export type BillingInfoInput = {
  cardholderName: Scalars['String']['input'];
  escottToken: Scalars['String']['input'];
};

/**
 * "
 * The platform code that represent where a bill is settled.
 * e.g.
 * - A bill through credit card payment with BOS user -> BOS
 * - A UnextSubscription bill through Apple IAP -> APPLE_UNEXT
 * Only used in "DisplayBill".
 */
export enum BillingPlatformCode {
  AMAZON = 'AMAZON',
  APPLE = 'APPLE',
  APPLE_PARAVI = 'APPLE_PARAVI',
  APPLE_UNEXT = 'APPLE_UNEXT',
  BOS = 'BOS',
  CP = 'CP',
  GOOGLE = 'GOOGLE',
  NSBO = 'NSBO',
}

/**
 * The platform code that represent where a bill is settled for input.
 * Only used in "issueInvoice".
 */
export enum BillingPlatformCodeInput {
  /** Platform is Amazon(Subscription & Purchase) */
  AMAZON = 'AMAZON',
  /** Platform is Apple(Subscription before XX xx, 2024 & U-coin) */
  APPLE = 'APPLE',
  /** Platform is Apple(ParaviSubscription) */
  APPLE_PARAVI = 'APPLE_PARAVI',
  /** Platform is Apple(UnextSubscription) */
  APPLE_UNEXT = 'APPLE_UNEXT',
  /** Platform is BOS */
  BOS = 'BOS',
  /** Platform is Google(Subscription & Purchase) */
  GOOGLE = 'GOOGLE',
  /** Platform is NSBO */
  NSBO = 'NSBO',
}

export type BillingReport = {
  __typename: 'BillingReport';
  detailList: Array<Maybe<BillingReportEntry>>;
  month: Scalars['DateTime']['output'];
  totalAmount: Scalars['Int']['output'];
  totalYamadaPointUsed: Scalars['Int']['output'];
};

export type BillingReportEntry = {
  __typename: 'BillingReportEntry';
  amount: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  saleDate: Scalars['DateTime']['output'];
  saleTargetCode: SaleTargetCode;
  saleTypeName: Scalars['String']['output'];
  serviceType: Scalars['String']['output'];
};

export type BirthdayUpdateResponse = {
  __typename: 'BirthdayUpdateResponse';
  updated: Scalars['Boolean']['output'];
};

export type BlockInfo = {
  __typename: 'BlockInfo';
  isBlocked: Maybe<Scalars['Boolean']['output']>;
  /** Indicates the blocking level of this account which is blocked (isBlocked : true) */
  score: Maybe<Scalars['Int']['output']>;
};

export enum BlockType {
  B_FEATURE = 'B_FEATURE',
  B_FREE = 'B_FREE',
  B_HISTORY = 'B_HISTORY',
  B_LEANBACK = 'B_LEANBACK',
  B_NEW = 'B_NEW',
  B_PREORDER = 'B_PREORDER',
  B_SPTLIGHT = 'B_SPTLIGHT',
  B_UNEXTPUB = 'B_UNEXTPUB',
  F_TEMPLATE = 'F_TEMPLATE',
  MAGAZINE = 'MAGAZINE',
  ORIG_COMIC = 'ORIG_COMIC',
  RANKING = 'RANKING',
  REC_USR_B = 'REC_USR_B',
  SEARCH_CAT = 'SEARCH_CAT',
}

export type BonusMaterial = {
  __typename: 'BonusMaterial';
  bonusMaterialNoDisplayName: Maybe<Scalars['String']['output']>;
  cmsXid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  introduction: Scalars['String']['output'];
  nameJa: Scalars['String']['output'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for BonusMaterial.
   */
  thumbnails: Array<ThumbnailSet>;
};

export type Book = {
  __typename: 'Book';
  blacklist: Array<Platform>;
  bookContent: Maybe<BookContent>;
  bookMagazine: Maybe<BookMagazine>;
  bookNo: Scalars['Int']['output'];
  code: Scalars['ID']['output'];
  credits: Array<BookCredit>;
  hasRights: Maybe<Scalars['Boolean']['output']>;
  includedBook: Maybe<IncludedBook>;
  introduction: Maybe<Scalars['String']['output']>;
  isBookPlusTicketAvailable: Maybe<Scalars['Boolean']['output']>;
  isBookSakuhinTicketAvailable: Maybe<Scalars['Boolean']['output']>;
  isFree: Maybe<Scalars['Boolean']['output']>;
  isInReadingHistory: Maybe<Scalars['Boolean']['output']>;
  /** Will only be returned for bookList in bookTitle query */
  isLatestBook: Maybe<Scalars['Boolean']['output']>;
  isNew: Maybe<Scalars['Boolean']['output']>;
  isPreorderable: Maybe<Scalars['Boolean']['output']>;
  isPreordered: Maybe<Scalars['Boolean']['output']>;
  isPurchased: Maybe<Scalars['Boolean']['output']>;
  isSale: Maybe<Scalars['Boolean']['output']>;
  isWebtoon: Maybe<Scalars['Boolean']['output']>;
  mediaType: BookMediaType;
  name: Scalars['String']['output'];
  /** The date when the book started to be sold */
  originalSaleDate: Maybe<Scalars['DateTime']['output']>;
  paymentBadge: Maybe<PaymentBadge>;
  publicEndDateTime: Maybe<Scalars['DateTime']['output']>;
  /** The time when the book was published on U-NEXT */
  publicStartDateTime: Maybe<Scalars['DateTime']['output']>;
  publishedIn: Array<Magazine>;
  publisher: Maybe<Publisher>;
  ratingCode: Scalars['String']['output'];
  rightsExpirationDatetime: Maybe<Scalars['DateTime']['output']>;
  saleEndDateTime: Maybe<Scalars['DateTime']['output']>;
  saleStartDateTime: Maybe<Scalars['DateTime']['output']>;
  saleText: Maybe<Scalars['String']['output']>;
  thumbnail: Thumbnail;
};

export type BookBlock = {
  __typename: 'BookBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  results: Scalars['Int']['output'];
  tagCode: Maybe<Scalars['String']['output']>;
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookCategoryListResponse = {
  __typename: 'BookCategoryListResponse';
  books: Array<BookSakuhin>;
  menuCode: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
};

export type BookCategoryResponse = {
  __typename: 'BookCategoryResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookContent = {
  __typename: 'BookContent';
  mainBookFile: Maybe<BookFile>;
  productList: Array<BookProduct>;
  sampleBookFile: Maybe<BookFile>;
};

export type BookCredit = {
  __typename: 'BookCredit';
  bookAuthorType: Maybe<Scalars['String']['output']>;
  penName: Maybe<Scalars['String']['output']>;
  penNameCode: Maybe<Scalars['String']['output']>;
  penNameKana: Maybe<Scalars['String']['output']>;
  personCode: Maybe<Scalars['String']['output']>;
  unextPublishingDetail: Maybe<UnextPublishingDetail>;
};

export type BookDeviceRegistrationInfo = {
  __typename: 'BookDeviceRegistrationInfo';
  device: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
};

export type BookFeature = {
  __typename: 'BookFeature';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  comment: Scalars['String']['output'];
  displayEnd: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  results: Scalars['Int']['output'];
  typeCode: Scalars['String']['output'];
};

export type BookFeatureBlock = {
  __typename: 'BookFeatureBlock';
  code: Scalars['ID']['output'];
  feature: BookFeature;
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookFeatureItem = {
  __typename: 'BookFeatureItem';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  comment: Scalars['String']['output'];
  displayEnd: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  typeCode: BookFeatureTypeCode;
};

export type BookFeatureResponse = {
  __typename: 'BookFeatureResponse';
  feature: BookFeatureItem;
  pageInfo: PortalPageInfo;
};

export enum BookFeatureTypeCode {
  CAMPAIGN = 'CAMPAIGN',
  FEATURE = 'FEATURE',
  FEATURE_C = 'FEATURE_C',
}

export type BookFile = {
  __typename: 'BookFile';
  code: Scalars['ID']['output'];
  completeFlg: Scalars['Boolean']['output'];
  licenseExpiredDateTime: Maybe<Scalars['DateTime']['output']>;
  resumePoint: Scalars['Float']['output'];
  updateDateTime: Scalars['String']['output'];
  webtoonFlg: Scalars['Boolean']['output'];
};

export type BookFreeResponse = {
  __typename: 'BookFreeResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookFreewordSearchResponse = {
  __typename: 'BookFreewordSearchResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookHistoryRemovalResponse = {
  __typename: 'BookHistoryRemovalResponse';
  bookSakuhinCodes: Array<Scalars['ID']['output']>;
  removedAll: Scalars['Boolean']['output'];
};

export type BookHistoryResponse = {
  __typename: 'BookHistoryResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

export type BookLabel = {
  __typename: 'BookLabel';
  code: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type BookLeanbackBlock = {
  __typename: 'BookLeanbackBlock';
  code: Scalars['ID']['output'];
  leanbackList: Array<LeanbackGenre>;
  type: BlockType;
};

export type BookMagazine = {
  __typename: 'BookMagazine';
  bookMagazineCode: Scalars['ID']['output'];
  bookMagazineName: Maybe<Scalars['String']['output']>;
  magazineGenreCode: Maybe<Scalars['String']['output']>;
};

export type BookMediaType = {
  __typename: 'BookMediaType';
  code: BookMediaTypeCode;
  name: Scalars['String']['output'];
};

export enum BookMediaTypeCode {
  BOOK = 'BOOK',
  COMIC = 'COMIC',
  MAGAZINE = 'MAGAZINE',
  PHOTO_BOOK = 'PHOTO_BOOK',
  PICBOOK = 'PICBOOK',
  RANOBE = 'RANOBE',
}

export type BookMenu = {
  __typename: 'BookMenu';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BookMetaSearchResponse = {
  __typename: 'BookMetaSearchResponse';
  books: Array<BookSakuhin>;
  name: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
};

export enum BookMetaType {
  LABEL = 'LABEL',
  PUBLISHED_IN = 'PUBLISHED_IN',
  PUBLISHER = 'PUBLISHER',
}

export type BookNewArrivalsResponse = {
  __typename: 'BookNewArrivalsResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookOriginalBlock = {
  __typename: 'BookOriginalBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  lead: Scalars['String']['output'];
  leadTitle: Scalars['String']['output'];
  results: Scalars['Int']['output'];
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookOriginalTitlesResponse = {
  __typename: 'BookOriginalTitlesResponse';
  books: Array<BookSakuhin>;
  leadText: Scalars['String']['output'];
  leadTitle: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
};

export type BookPersonListResponse = {
  __typename: 'BookPersonListResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
  personCode: Scalars['String']['output'];
};

export type BookPersonResponse = {
  __typename: 'BookPersonResponse';
  books: Array<BookSakuhin>;
  /** Returns the the person's name if personNameCode, which personNameCode and penNameCode can be set, is provided. */
  name: Maybe<Scalars['String']['output']>;
  pageInfo: PortalPageInfo;
};

export type BookPlaylistUrl = {
  __typename: 'BookPlaylistUrl';
  epub: Maybe<Epub>;
  ubooks: Array<UBook>;
  version: Maybe<Scalars['Int']['output']>;
};

export type BookPlaylistUrlResponse = {
  __typename: 'BookPlaylistUrlResponse';
  bookFileCode: Scalars['String']['output'];
  bookFileTypeCode: Scalars['String']['output'];
  completeFlg: Scalars['Boolean']['output'];
  dictionaryWordNameList: Array<Scalars['String']['output']>;
  kanaSyllabaryText: Scalars['String']['output'];
  licenseUrl: Scalars['String']['output'];
  penNameKana: Scalars['String']['output'];
  playToken: Scalars['String']['output'];
  playTokenHash: Scalars['String']['output'];
  playlistBaseUrl: Scalars['String']['output'];
  playlistUrl: BookPlaylistUrl;
  productCode: Scalars['String']['output'];
  resumePoint: Scalars['Float']['output'];
  rightsExpirationDatetime: Maybe<Scalars['DateTime']['output']>;
  saleTypeCode: Scalars['String']['output'];
  webtoonFlg: Scalars['Boolean']['output'];
};

export type BookPostPlayEmptyResponse = {
  __typename: 'BookPostPlayEmptyResponse';
  type: BookPostPlayResultType;
};

export type BookPostPlayNextBookResult = {
  __typename: 'BookPostPlayNextBookResult';
  nextBook: BookSakuhin;
  type: BookPostPlayResultType;
};

export type BookPostPlayRecommendResponse = {
  __typename: 'BookPostPlayRecommendResponse';
  recommend: Array<BookSakuhin>;
  type: BookPostPlayResultType;
};

export type BookPostPlayResult =
  | BookPostPlayEmptyResponse
  | BookPostPlayNextBookResult
  | BookPostPlayRecommendResponse;

export enum BookPostPlayResultType {
  NEXT_BOOK = 'NEXT_BOOK',
  NONE = 'NONE',
  RECOMMEND = 'RECOMMEND',
}

export type BookPreorderResponse = {
  __typename: 'BookPreorderResponse';
  set: Scalars['Boolean']['output'];
};

export type BookPreorderableResponse = {
  __typename: 'BookPreorderableResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookProduct = {
  __typename: 'BookProduct';
  code: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  salePeriod: Maybe<SalePeriod>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
};

export type BookProductItem = {
  __typename: 'BookProductItem';
  bookCode: Scalars['ID']['output'];
  bookCodeList: Array<Scalars['ID']['output']>;
  code: Scalars['ID']['output'];
  discountRate: Scalars['String']['output'];
  isPoint: Scalars['Boolean']['output'];
  isSale: Scalars['Boolean']['output'];
  licensedDeviceCodeList: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productTypeCode: Scalars['String']['output'];
  recentLicenseExpiredDateTime: Maybe<Scalars['DateTime']['output']>;
  saleEndDateTime: Scalars['DateTime']['output'];
  saleStartDateTime: Scalars['DateTime']['output'];
  saleTypeCode: Scalars['String']['output'];
  sellingPrice: Scalars['Int']['output'];
  sourcePrice: Scalars['Int']['output'];
};

export type BookProductList = {
  __typename: 'BookProductList';
  hasReadingHistory: Scalars['Boolean']['output'];
  isWaitingForRecoveryTicket: Scalars['Boolean']['output'];
  products: Array<BookProductItem>;
};

export type BookProductPurchaseResponse = {
  __typename: 'BookProductPurchaseResponse';
  product: Maybe<BookPurchasedProductItem>;
};

export type BookPublishingDetailThumbnail = {
  __typename: 'BookPublishingDetailThumbnail';
  icon: Maybe<Scalars['String']['output']>;
  standard: Maybe<Scalars['String']['output']>;
};

export type BookPurchaseRemoveResponse = {
  __typename: 'BookPurchaseRemoveResponse';
  bookSakuhinCodes: Array<Scalars['ID']['output']>;
};

export type BookPurchaseWithTicketResponseModel = {
  __typename: 'BookPurchaseWithTicketResponseModel';
  bookTicketCode: Scalars['String']['output'];
  bookTicketTypeCode: Scalars['String']['output'];
  bookTicketTypeName: Scalars['String']['output'];
  useTicketCount: Scalars['Int']['output'];
};

export type BookPurchasedProductItem = {
  __typename: 'BookPurchasedProductItem';
  code: Scalars['ID']['output'];
  discountRate: Maybe<Scalars['String']['output']>;
  isSale: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  originalPrice: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  publicEndDate: Scalars['DateTime']['output'];
  publicStartDate: Scalars['DateTime']['output'];
  segment: Scalars['String']['output'];
};

export type BookRanking = {
  __typename: 'BookRanking';
  bookCode: Scalars['ID']['output'];
  isNewRanking: Scalars['Boolean']['output'];
  rankDiff: Scalars['Int']['output'];
};

export type BookRankingBlock = {
  __typename: 'BookRankingBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  comment: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  rankings: Array<BookRanking>;
  results: Scalars['Int']['output'];
  targetCode: Scalars['String']['output'];
  targets: Array<BookRankingTarget>;
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookRankingResponse = {
  __typename: 'BookRankingResponse';
  books: Array<BookRankingSakuhin>;
  displayName: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
  rankingComment: Scalars['String']['output'];
  rankingHeading: Scalars['String']['output'];
  rankingTargetCode: BookRankingTargetCode;
};

export type BookRankingSakuhin = {
  __typename: 'BookRankingSakuhin';
  bookSakuhin: BookSakuhin;
  isNewRanking: Scalars['Boolean']['output'];
  rankDiff: Scalars['Int']['output'];
};

export type BookRankingSakuhins = {
  __typename: 'BookRankingSakuhins';
  books: Array<BookRankingSakuhin>;
  displayName: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
  rankingComment: Scalars['String']['output'];
  rankingHeading: Scalars['String']['output'];
};

export type BookRankingTarget = {
  __typename: 'BookRankingTarget';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  rankings: Array<BookRanking>;
};

export enum BookRankingTargetCode {
  D_BOOK = 'D_BOOK',
  D_COMIC = 'D_COMIC',
  D_RANOBE = 'D_RANOBE',
}

export type BookReadMoreResponse = {
  __typename: 'BookReadMoreResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

export type BookRecommendBlock =
  | BookBlock
  | BookFeatureBlock
  | BookLeanbackBlock
  | BookOriginalBlock
  | BookRankingBlock
  | BookSearchCategoryBlock
  | BookSpotlightBlock
  | BookTemplateBlock
  | BookUserRecommendedBlock
  | MagazineBlock
  | OriginalComicBlock;

export type BookRecommendBlockResponse = {
  __typename: 'BookRecommendBlockResponse';
  pageInfo: PortalPageInfo;
  recommendBlocks: Array<BookRecommendBlock>;
};

export type BookResumePointUpdateResponse = {
  __typename: 'BookResumePointUpdateResponse';
  bookFileCode: Scalars['String']['output'];
  resumePoint: Scalars['Float']['output'];
};

export type BookSakuhin = {
  __typename: 'BookSakuhin';
  blacklist: Array<Platform>;
  book: Maybe<Book>;
  bookLabel: Maybe<BookLabel>;
  /** bookList will start from "current episode" */
  bookList: Maybe<Array<Maybe<Book>>>;
  bookNumForTicket: Scalars['Int']['output'];
  bookSakuhinChapterLink: Maybe<BookSakuhinChapterLink>;
  bookTickets: Maybe<BookTickets>;
  bookViewCode: Scalars['String']['output'];
  books: Maybe<BooksResponse>;
  code: Scalars['ID']['output'];
  detail: BookSakuhinDetail;
  dictionaryWordNameList: Array<Scalars['String']['output']>;
  featurePieceCode: Maybe<Scalars['String']['output']>;
  freeBookNum: Maybe<Scalars['Int']['output']>;
  freeSaleText: Maybe<Scalars['String']['output']>;
  fromBookNum: Maybe<Scalars['Int']['output']>;
  /** @deprecated Changed to isChapter */
  hasChapter: Scalars['Boolean']['output'];
  hasMultiplePrices: Maybe<Scalars['Boolean']['output']>;
  hasUnreadChapter: Maybe<Scalars['Boolean']['output']>;
  isBlacklist: Maybe<Scalars['Boolean']['output']>;
  isBookPlusTicketAvailable: Maybe<Scalars['Boolean']['output']>;
  isBookReserved: Maybe<Scalars['Boolean']['output']>;
  isBookSakuhinTicketAvailable: Maybe<Scalars['Boolean']['output']>;
  isChapter: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isFavorite: Maybe<Scalars['Boolean']['output']>;
  isNew: Maybe<Scalars['Boolean']['output']>;
  isPointAvailable: Maybe<Scalars['Boolean']['output']>;
  isRecentSale: Maybe<Scalars['Boolean']['output']>;
  isSale: Maybe<Scalars['Boolean']['output']>;
  isUnextOriginal: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use isUnextOriginal */
  isUnextPublishing: Maybe<Scalars['Boolean']['output']>;
  kanaSyllabaryText: Maybe<Scalars['String']['output']>;
  menuList: Array<BookMenu>;
  minPrice: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nextReleaseDatetime: Maybe<Scalars['DateTime']['output']>;
  otherOriginalBooks: Maybe<BookOriginalTitlesResponse>;
  paymentBadgeList: Array<PaymentBadge>;
  plusTicketEndDateTime: Maybe<Scalars['DateTime']['output']>;
  purchaseBookNum: Maybe<Scalars['Int']['output']>;
  /** Will only be returned by the webfront_purchasedBooks query */
  purchasedBooks: Array<PurchasedBook>;
  rate: Scalars['Float']['output'];
  recentLicenseExpiredDatetime: Maybe<Scalars['DateTime']['output']>;
  recentPublicEndDateTime: Maybe<Scalars['DateTime']['output']>;
  recommendedBooks: Maybe<BookTitleRecommendedBooksResponse>;
  relatedBooks: Maybe<RelatedBooksResponse>;
  relatedTitles: Maybe<RelatedVideosResponse>;
  subgenreTagList: Array<SubgenreTag>;
  thumbnail: BookSakuhinThumbnails;
  ticketEndDatetime: Maybe<Scalars['DateTime']['output']>;
  toBookNum: Maybe<Scalars['Int']['output']>;
  totalBookNum: Scalars['Int']['output'];
  totalBookNumIncludingPreorders: Maybe<Scalars['Int']['output']>;
  userRate: Maybe<Scalars['Float']['output']>;
};

export type BookSakuhinbooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type BookSakuhinrecommendedBooksArgs = {
  includePreorderableFlg?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type BookSakuhinrelatedBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type BookSakuhinrelatedTitlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type BookSakuhinChapterLink = {
  __typename: 'BookSakuhinChapterLink';
  bookSakuhinCode: Scalars['ID']['output'];
  isNew: Scalars['Boolean']['output'];
};

export type BookSakuhinDetail = {
  __typename: 'BookSakuhinDetail';
  catchSentence: Scalars['String']['output'];
  introduction: Scalars['String']['output'];
};

export type BookSakuhinThumbnails = {
  __typename: 'BookSakuhinThumbnails';
  secondary: Scalars['String']['output'];
  standard: Scalars['String']['output'];
};

/** FREE corresponds to FOD_OR_POD in portal request */
export enum BookSaleType {
  FREE = 'FREE',
}

export type BookSearchCategory = {
  __typename: 'BookSearchCategory';
  code: Scalars['ID']['output'];
  defaultSortOrder: PortalSortOrder;
  name: Scalars['String']['output'];
  typeCode: Scalars['String']['output'];
};

export type BookSearchCategoryBlock = {
  __typename: 'BookSearchCategoryBlock';
  code: Scalars['ID']['output'];
  menuList: Array<BookSearchCategory>;
  name: Scalars['String']['output'];
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookSpotLight = {
  __typename: 'BookSpotLight';
  code: Scalars['ID']['output'];
  feature: BookSpotLightFeature;
  featureTemplate: BookSpotLightFeatureTemplate;
  linkTypeCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  publicEndDateTime: Maybe<Scalars['DateTime']['output']>;
  publicStartDateTime: Maybe<Scalars['DateTime']['output']>;
  sakuhin: BookSpotLightSakuhin;
  thumbnail: Thumbnail;
};

export type BookSpotLightFeature = {
  __typename: 'BookSpotLightFeature';
  code: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BookSpotLightFeatureTemplate = {
  __typename: 'BookSpotLightFeatureTemplate';
  code: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BookSpotLightSakuhin = {
  __typename: 'BookSpotLightSakuhin';
  code: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BookSpotlightBlock = {
  __typename: 'BookSpotlightBlock';
  code: Scalars['ID']['output'];
  spotlightList: Array<BookSpotLight>;
  type: BlockType;
};

export type BookTemplateBlock = {
  __typename: 'BookTemplateBlock';
  code: Scalars['ID']['output'];
  template: FeatureTemplate;
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookTicket = {
  __typename: 'BookTicket';
  availableCount: Scalars['Int']['output'];
  code: Scalars['ID']['output'];
  nextDeliveryCount: Maybe<Scalars['Int']['output']>;
  nextDeliveryDatetime: Maybe<Scalars['DateTime']['output']>;
  receivedCount: Scalars['Int']['output'];
  typeCode: Scalars['String']['output'];
  typeName: Scalars['String']['output'];
};

export type BookTickets = {
  __typename: 'BookTickets';
  plusTicket: Maybe<BookTicket>;
  sakuhinTicket: Maybe<BookTicket>;
};

export type BookTitleRecommendedBooksResponse = {
  __typename: 'BookTitleRecommendedBooksResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
  trackingCode: Scalars['String']['output'];
};

export type BookTopBlock =
  | BookRankingBlock
  | BookTopRecommendBlock
  | BookUserRecommendedBlock;

export type BookTopBlockResponse = {
  __typename: 'BookTopBlockResponse';
  blocks: Maybe<Array<Maybe<BookTopBlock>>>;
  results: Scalars['Int']['output'];
};

export type BookTopLeanback = {
  __typename: 'BookTopLeanback';
  displayCode: Scalars['String']['output'];
  menuName: Scalars['String']['output'];
  searchMenuCode: Scalars['String']['output'];
  searchMenuName: Scalars['String']['output'];
  searchMenuType: Scalars['String']['output'];
  tagCode: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
};

export type BookTopLeanbackResponse = {
  __typename: 'BookTopLeanbackResponse';
  list: Maybe<Array<Maybe<BookTopLeanback>>>;
  results: Scalars['Int']['output'];
};

export type BookTopRecommendBlock = {
  __typename: 'BookTopRecommendBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  results: Scalars['Int']['output'];
  type: BlockType;
};

export type BookUserRecommendedBlock = {
  __typename: 'BookUserRecommendedBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  comment: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  results: Scalars['Int']['output'];
  tagCode: Maybe<Scalars['String']['output']>;
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type BookUserRecommendedResponse = {
  __typename: 'BookUserRecommendedResponse';
  books: Array<BookSakuhin>;
  comment: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  results: Maybe<Scalars['Int']['output']>;
};

export enum BookVolumeGroupTypeCode {
  ALL = 'ALL',
  CHAPTER = 'CHAPTER',
  VOLUME = 'VOLUME',
}

export type BooksRecommendResponse = {
  __typename: 'BooksRecommendResponse';
  books: Array<BookSakuhin>;
  results: Scalars['Int']['output'];
};

export type BooksResponse = {
  __typename: 'BooksResponse';
  books: Array<Book>;
  pageInfo: Maybe<PortalPageInfo>;
};

/** Contract status of a UnextAccount on BOS */
export type BosContractInfo = UnextAccountContractInfo & {
  __typename: 'BosContractInfo';
  /** Start date for this contract. */
  contractStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Contract status id */
  contractStatusId: Scalars['Int']['output'];
  /** Contract Status type code (alphanumeric code) */
  contractStatusTypeCode: Maybe<Scalars['String']['output']>;
  /** Contract status type name (in Japanese) */
  contractStatusTypeName: Maybe<Scalars['String']['output']>;
  /** Last updated date time for this contract */
  contractStatusUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
};

export type BrandGroupLight = {
  __typename: 'BrandGroupLight';
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
};

export type BulkBookProductPurchaseResponse = {
  __typename: 'BulkBookProductPurchaseResponse';
  products: Array<BookPurchasedProductItem>;
};

/** Response for bulk cancelling running services */
export type BulkCancelRunningServicesResponse = {
  __typename: 'BulkCancelRunningServicesResponse';
  results: Array<RunningServiceCancellationResponse>;
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Defines display text properties */
export type CalendarDisplayText = {
  __typename: 'CalendarDisplayText';
  /** Text used for button */
  buttonText: Scalars['String']['output'];
  /** Message displayed when no content is available */
  emptyMessage: Scalars['String']['output'];
};

/**
 * Calendar Event: used for display on calendar
 * https://wiki.unext-info.jp/pages/viewpage.action?pageId=221093138
 */
export type CalendarEvent = {
  /** ContentCode: contains information specific to live content or VOD content */
  contentCode: ContentCode;
  /** Calendar Event Start Date Time */
  date: Scalars['DateTime']['output'];
  /** Calendar Event Id */
  id: Scalars['ID']['output'];
  /** Image Url: usually for background image of the event */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Calendar Event Title */
  name: Scalars['String']['output'];
  /** Descriptor tags for the event */
  tags: Maybe<Array<CalendarSearchTag>>;
};

/** Calendar Event State: that used for display the text in the UI */
export enum CalendarEventState {
  /** Calendar Event finished: After the live broadcast ends and before VOD is available */
  FINISHED = 'FINISHED',
  /** Calendar Event on going: During the live broadcast */
  ON_GOING = 'ON_GOING',
  /** Calendar Event scheduled: Before the live broadcast */
  SCHEDULED = 'SCHEDULED',
  /** Calendar Event VOD: during VOD broadcast */
  VOD = 'VOD',
}

/** Response to the CalendarFeatureSettings request used to determine if the calendar should be visible, if so what the searchable tags will be and which text will be displayed. */
export type CalendarFeatureSettingsResponse = {
  __typename: 'CalendarFeatureSettingsResponse';
  /** The text for button and empty message */
  displayText: Maybe<CalendarDisplayText>;
  /** Whether or not the calendar feature is enabled. */
  enabled: Scalars['Boolean']['output'];
  /** Whether or not the calendar request parameters would produce a result that contains events. This can be used to determine if the calendar should be visible. */
  hasEvents: Scalars['Boolean']['output'];
  /** The tags and their values that can be used to further query the events that will be returned from the calendar request. */
  searchableTags: Array<CalendarTag>;
};

/** Input used to search for calendar events by a menu item */
export type CalendarMenuInput = {
  /** The type of menu item to search for */
  type: CalendarMenuItemInput;
  /** The value of the menu item to search for */
  value: Scalars['String']['input'];
};

/** The kind of menu item to search for */
export enum CalendarMenuItemInput {
  /** Menu item for genre */
  GENRE = 'GENRE',
  /** Menu item for league */
  LEAGUE = 'LEAGUE',
}

/** Represents a tag on a calendar event */
export type CalendarSearchTag = {
  __typename: 'CalendarSearchTag';
  /** The key identifier for the tag */
  key: Scalars['String']['output'];
  /** The value of the tag */
  value: Scalars['String']['output'];
};

/** Input used to search for calendar events by a tag */
export type CalendarSearchTagInput = {
  /** The key identifier for the tag */
  key: Scalars['String']['input'];
  /** The value of the tag */
  value: Scalars['String']['input'];
};

/** Represents a tag on a calendar event */
export type CalendarTag = {
  __typename: 'CalendarTag';
  /** The default display text for the tag */
  defaultText: Maybe<Scalars['String']['output']>;
  /** The key identifier for the tag */
  key: Scalars['String']['output'];
  /** All values that belong to a tag for a set of events */
  values: Array<CalendarTagValue>;
};

/** Represents a value associated with a calendar tag, including a key and a name */
export type CalendarTagValue = {
  __typename: 'CalendarTagValue';
  /** The display name associated with the value (supposed to be the league name in case of league) */
  displayName: Maybe<Scalars['String']['output']>;
  /** The value of the tag */
  value: Scalars['String']['output'];
};

export type Campaign = {
  __typename: 'Campaign';
  code: Maybe<Scalars['ID']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedBy: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

/** Union of all campaign types that can be awarded via giftcode. */
export type CampaignInfo =
  | MonthNoChargeCampaignInfo
  | PointGrantCampaignInfo
  | SelectionItemCampaignInfo
  | TrialAndPointGrantCampaignInfo
  | TrialCampaignInfo;

export type CampaignInput = {
  code: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CampaignUpdateInput = {
  code: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CampaignsPaginatedResponse = {
  __typename: 'CampaignsPaginatedResponse';
  campaigns: Maybe<Array<Maybe<Campaign>>>;
  totalCount: Scalars['Int']['output'];
};

/** List of server responses for service cancellations */
export type CancelActiveSubscriptionsResponse = {
  __typename: 'CancelActiveSubscriptionsResponse';
  /** 1:1 with cancel request on subscription/service id */
  results: Array<ActiveSubscriptionCancellationResponse>;
  /** List of errors encountered during processing of mutation */
  userErrors: Array<ActiveSubscriptionCancellationError>;
};

/** Response for cancelling data charge */
export type CancelDataChargeResponse = CheckableStatus & {
  __typename: 'CancelDataChargeResponse';
  status: Scalars['Boolean']['output'];
};

/** Response for cancelling plan */
export type CancelPlanResponse = CheckableStatus & {
  __typename: 'CancelPlanResponse';
  status: Scalars['Boolean']['output'];
};

/** Response for cancelling running service */
export type CancelRunningServiceResponse = CheckableStatus & {
  __typename: 'CancelRunningServiceResponse';
  status: Scalars['Boolean']['output'];
};

/** Response for canceling sim user application */
export type CancelSimUserApplicationResponse = CheckableStatus & {
  __typename: 'CancelSimUserApplicationResponse';
  status: Scalars['Boolean']['output'];
};

/** Response for cancelling yu mobile plan */
export type CancelYuMobilePlanResponse = CheckableStatus & {
  __typename: 'CancelYuMobilePlanResponse';
  status: Scalars['Boolean']['output'];
};

/** The cancellation reason */
export enum CancellationReason {
  /** 自動解約 */
  AUTO = 'AUTO',
  /** 強制解約 */
  FORCED = 'FORCED',
  /** 強制申込キャンセル */
  FORCED_CANCEL = 'FORCED_CANCEL',
  /** 初期契約解除 */
  INITIAL_TERMINATION = 'INITIAL_TERMINATION',
  /** MNP転出予約番号期限切れ */
  MNP_EXPIRED = 'MNP_EXPIRED',
  /** 他社へのMNP転出 */
  MNP_MOVING_OUT = 'MNP_MOVING_OUT',
  /** プラン変更 */
  PLAN_CHANGE = 'PLAN_CHANGE',
  /** シェアプラン契約に伴う映像サービス解約 */
  START_SHARE_PLAN = 'START_SHARE_PLAN',
  /** ユーザー解約 */
  USER = 'USER',
  /** ユーザー申込キャンセル */
  USER_CANCEL = 'USER_CANCEL',
  /** 映像サービスのクーリングオフ */
  VIDEO_COOLING_OFF = 'VIDEO_COOLING_OFF',
}

export enum CapyPaymentBadgeCode {
  BUY = 'BUY',
  POINT = 'POINT',
  RENTAL = 'RENTAL',
  SELL = 'SELL',
  SVOD = 'SVOD',
}

export type CapyRecommendationBlock = MediaBlock & {
  __typename: 'CapyRecommendationBlock';
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  paginatedMedia: PaginatedMediaLight;
};

export enum CapySaleBadgeCode {
  SALE = 'SALE',
}

export enum CapySaleTypeCode {
  EST = 'EST',
  FOD = 'FOD',
  POD = 'POD',
  SOD = 'SOD',
  TOD = 'TOD',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum CapyVideoCodec {
  DVHE = 'DVHE',
  H264 = 'H264',
  H265 = 'H265',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type Channel = {
  __typename: 'Channel';
  alias: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  movieProfileList: Array<MovieProfile>;
  name: Scalars['String']['output'];
  paymentBadgeList: Array<PaymentBadge>;
  thumbnail: Thumbnail;
};

/** union for errors encountered during checking if the upsell is eligible */
export type CheckUpsellEligibilityError =
  | IapReceiptError
  | UnsupportedError
  | UpsellAddonError
  | UserInputsError;

/** Response to the check upsell eligibility query */
export type CheckUpsellEligibilityResponse = {
  __typename: 'CheckUpsellEligibilityResponse';
  /** Whether the upsell is eligible */
  isEligible: Scalars['Boolean']['output'];
  /** The result of the upsell payment guidance for different purchase agent */
  upsellGuidance: Maybe<UpsellGuidance>;
  /** Errors encountered while checking upsell eligibility */
  userErrors: Array<CheckUpsellEligibilityError>;
};

export type CheckableStatus = {
  status: Scalars['Boolean']['output'];
};

/** returns Cinema Company */
export type CinemaCompanyCoupon = {
  __typename: 'CinemaCompanyCoupon';
  /** Returns coupon type */
  movieCouponTypeCode: Maybe<Scalars['String']['output']>;
  /** Returns coupon name */
  movieCouponTypeName: Maybe<Scalars['String']['output']>;
  /** Returns price */
  price: Maybe<Scalars['Int']['output']>;
};

/** Responds to the film company, also returns an error if the acquisition fails. */
export type CinemaCompanyResponse = {
  __typename: 'CinemaCompanyResponse';
  /** Retrieve a list of companies that support QR Coupon and those that support Classic Coupon. */
  companies: Maybe<CinemaCouponCompanies>;
};

/**
 * this is an early version of the MOVIE ticket.
 * At this time,the old new version does not affect users.
 */
export type CinemaCoupon = {
  __typename: 'CinemaCoupon';
  /** classic edition */
  cinemaCouponClassicEdition: Maybe<Array<CinemaCouponClassicEdition>>;
  /** QR edition */
  cinemaCouponQREdition: Maybe<CinemaCouponQREditions>;
};

/**
 * this is an early version of the MOVIE ticket.
 * At this time the old new version does not affect users.
 */
export type CinemaCouponClassicEdition = {
  __typename: 'CinemaCouponClassicEdition';
  /** the company code of the food coupon you currently have by the user. */
  companyCode: Maybe<Scalars['String']['output']>;
  /** the company name of the food coupon you currently have by the user. */
  companyName: Maybe<Scalars['String']['output']>;
  /** Information on movie theaters where tickets can be used */
  theaterInfo: Maybe<TheaterInfo>;
  /** It stores information about the ticket. */
  ticketInfo: Maybe<TicketInfo>;
  ticketMatchingCode: Maybe<Scalars['String']['output']>;
  /** code of tickets held by user */
  userTicketCode: Maybe<Scalars['String']['output']>;
};

/** Returns a list of companies which supply cinema coupons to U-NEXT */
export type CinemaCouponCompanies = {
  __typename: 'CinemaCouponCompanies';
  /** Returns film companies that do not support qr */
  classicEdition: Array<ClassicEditionCinemaCouponCompany>;
  /** Returns film companies that support qr. */
  qrEdition: Array<QREditionCinemaCouponCompany>;
};

/** Retrieve cinema coupons held by the user */
export type CinemaCouponQREditions = {
  __typename: 'CinemaCouponQREditions';
  /** Privilege tickets granted to those who register as members of U-NEXT through specific channels. */
  benefitCinemaCouponQREditions: Maybe<Array<OwnedCinemaCouponQREdition>>;
  /** standard available on the web tickets */
  cinemaCouponQREditions: Maybe<Array<OwnedCinemaCouponQREdition>>;
};

/** Retruns ClassicEditionCompany */
export type ClassicEditionCinemaCouponCompany = {
  __typename: 'ClassicEditionCinemaCouponCompany';
  /** Returns companyCode */
  companyCode: Maybe<Scalars['String']['output']>;
  /** Returns companyName */
  companyName: Maybe<Scalars['String']['output']>;
  /** Returns serviceName */
  serviceName: Maybe<Scalars['String']['output']>;
  /** Returns theaterEnabled */
  theaterEnabled: Maybe<Scalars['Int']['output']>;
};

export enum ClassificationInput {
  CRS = 'CRS',
  DELIGHT = 'DELIGHT',
  GENERAL_PROMOTION = 'GENERAL_PROMOTION',
  LEGACY = 'LEGACY',
  PERSONALIZED_PROMOTION = 'PERSONALIZED_PROMOTION',
  USER_MESSAGE = 'USER_MESSAGE',
}

export type Collection = {
  __typename: 'Collection';
  collectionType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
  paginatedMedia: PaginatedMediaLight;
};

export type CollectionpaginatedMediaArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type CollectionLight = {
  __typename: 'CollectionLight';
  collectionType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
};

/** Response for consenting sim user application */
export type ConsentSimUserApplicationResponse = CheckableStatus & {
  __typename: 'ConsentSimUserApplicationResponse';
  status: Scalars['Boolean']['output'];
};

/** When you use food coupon, it will tell you if the process was successful. */
export type ConsumeFoodCinemaCouponError = UserFacingError & {
  __typename: 'ConsumeFoodCinemaCouponError';
  /** A code denoting why may have been rejected */
  code: Scalars['String']['output'];
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Information required when using food coupons */
export type ConsumeFoodCinemaCouponInput = {
  /** specify the couponID to be used */
  foodCouponId: Scalars['Int']['input'];
  /** hashcode to verify that the given coupon is correct */
  hashCode: Scalars['String']['input'];
};

/** Use food coupon and return the result */
export type ConsumeFoodCinemaCouponResponse = {
  __typename: 'ConsumeFoodCinemaCouponResponse';
  /** Return error message if success false */
  userErrors: Array<FoodCouponError>;
};

export type ContentBlock =
  | CapyRecommendationBlock
  | MediaSpotlightBlock
  | NewMediaRankingBlock
  | PopularityRankingBlock
  | RevenueRankingBlock
  | SalesBlock;

export enum ContentBlockQueryModeInput {
  ALL = 'ALL',
  SVOD_ONLY = 'SVOD_ONLY',
  TVOD_ONLY = 'TVOD_ONLY',
}

export enum ContentBlockTypeInput {
  CONTENT_BLOCK_TYPE_CUSTOM = 'CONTENT_BLOCK_TYPE_CUSTOM',
  CONTENT_BLOCK_TYPE_CUSTOM_CONDITION = 'CONTENT_BLOCK_TYPE_CUSTOM_CONDITION',
  CONTENT_BLOCK_TYPE_MEDIA_SPOTLIGHT = 'CONTENT_BLOCK_TYPE_MEDIA_SPOTLIGHT',
  CONTENT_BLOCK_TYPE_NEW_MEDIA = 'CONTENT_BLOCK_TYPE_NEW_MEDIA',
  CONTENT_BLOCK_TYPE_POPULARITY = 'CONTENT_BLOCK_TYPE_POPULARITY',
  CONTENT_BLOCK_TYPE_POPULAR_CONTENT_TAGS = 'CONTENT_BLOCK_TYPE_POPULAR_CONTENT_TAGS',
  CONTENT_BLOCK_TYPE_RECOMMENDATION = 'CONTENT_BLOCK_TYPE_RECOMMENDATION',
  CONTENT_BLOCK_TYPE_REVENUE_RANKING = 'CONTENT_BLOCK_TYPE_REVENUE_RANKING',
  CONTENT_BLOCK_TYPE_SALES = 'CONTENT_BLOCK_TYPE_SALES',
  CONTENT_BLOCK_TYPE_UNIVERSAL_NEW_MEDIA_CHAPTER = 'CONTENT_BLOCK_TYPE_UNIVERSAL_NEW_MEDIA_CHAPTER',
  CONTENT_BLOCK_TYPE_UNIVERSAL_POPULARITY_CHAPTER = 'CONTENT_BLOCK_TYPE_UNIVERSAL_POPULARITY_CHAPTER',
  CONTENT_BLOCK_TYPE_UNIVERSAL_REVENUE_RANKING = 'CONTENT_BLOCK_TYPE_UNIVERSAL_REVENUE_RANKING',
  CONTENT_BLOCK_TYPE_UNIVERSAL_SVOD_NEW_MEDIA = 'CONTENT_BLOCK_TYPE_UNIVERSAL_SVOD_NEW_MEDIA',
  CONTENT_BLOCK_TYPE_UNIVERSAL_SVOD_POPULARITY = 'CONTENT_BLOCK_TYPE_UNIVERSAL_SVOD_POPULARITY',
  CONTENT_BLOCK_TYPE_UNIVERSAL_TVOD_NEW_MEDIA = 'CONTENT_BLOCK_TYPE_UNIVERSAL_TVOD_NEW_MEDIA',
  CONTENT_BLOCK_TYPE_UNIVERSAL_TVOD_POPULARITY = 'CONTENT_BLOCK_TYPE_UNIVERSAL_TVOD_POPULARITY',
  CONTENT_BLOCK_TYPE_UNSPECIFIED = 'CONTENT_BLOCK_TYPE_UNSPECIFIED',
}

/** ContentCode can be one of two types representing different stages of content delivery: live or VOD. */
export type ContentCode = LiveContentCode | VodContentCode;

/** Billing state of a content pack */
export enum ContentPackBillingState {
  /**
   * Content pack will be renewed at the end of the month regardless of when the user signed up.
   * e.g. if the user signed up on 1/1 or 1/15, the renewal will happen on 1/31 (end of month).
   */
  ENDMONTH = 'ENDMONTH',
  /**
   * Content pack is renewed monthly. The renewal date is based on on when the user signed up.
   * e.g. if the user signed up on 1/10, the renewal date will be 2/10
   */
  MONTHLY = 'MONTHLY',
  /** Content Pack has been interrupted */
  PAUSE = 'PAUSE',
  /** Content pack has been stopped */
  STOP = 'STOP',
}

/** Allowed Content Pack Types */
export enum ContentPackType {
  /** NHK on Demand Content Pack */
  NOD = 'NOD',
  /** SPOTV Content Pack */
  SPOTV = 'SPOTV',
}

/** Allowed Content Pack Types for Input */
export enum ContentPackTypeInput {
  /** NHK on Demand Content Pack */
  NOD = 'NOD',
  /** SPOTV Content Pack */
  SPOTV = 'SPOTV',
}

/** The page information of one paginated response */
export type ContentPageInfo = {
  __typename: 'ContentPageInfo';
  /** Current requested offset */
  currentOffset: Scalars['Int']['output'];
  /** Whether there are additional records after the final record on this page */
  hasNext: Scalars['Boolean']['output'];
  /** Whether there are additional records prior to the first record on this page */
  hasPrevious: Scalars['Boolean']['output'];
  /** Number of records on one page */
  pageSize: Scalars['Int']['output'];
  /** Total number of records in the unpaginated query result */
  totalRecordCount: Scalars['Int']['output'];
};

/** Represents a paginated response */
export type ContentPaginatedResponse = {
  pageInfo: ContentPageInfo;
};

/** Pagination input use in content-graph */
export type ContentPaginationInput = {
  /** Number of items to return */
  limit: Scalars['Int']['input'];
  /** Offset of the first item to return */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Union of purchasable products for content. */
export type ContentProduct = ContractProduct | LiveTicketProduct;

export type ContentTag = {
  __typename: 'ContentTag';
  contentType: ContentType;
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
};

export enum ContentType {
  ALIAS = 'ALIAS',
  BRAND_GROUP = 'BRAND_GROUP',
  COLLECTION = 'COLLECTION',
  CONTENT_TAG = 'CONTENT_TAG',
  MEDIA = 'MEDIA',
  MEDIA_CHAPTER = 'MEDIA_CHAPTER',
  PLANET = 'PLANET',
  PRODUCTION_COMPANY = 'PRODUCTION_COMPANY',
  PUBLISHED_MEDIA = 'PUBLISHED_MEDIA',
  STUDIO = 'STUDIO',
  UNKNOWN = 'UNKNOWN',
}

export enum ContentTypeInput {
  ALIAS = 'ALIAS',
  BRAND_GROUP = 'BRAND_GROUP',
  COLLECTION = 'COLLECTION',
  CONTENT_TAG = 'CONTENT_TAG',
  MEDIA = 'MEDIA',
  MEDIA_CHAPTER = 'MEDIA_CHAPTER',
  PLANET = 'PLANET',
  PRODUCTION_COMPANY = 'PRODUCTION_COMPANY',
  PUBLISHED_MEDIA = 'PUBLISHED_MEDIA',
  STUDIO = 'STUDIO',
  UNKNOWN = 'UNKNOWN',
}

/** Promotional campaigns applied when signing a contract */
export type ContractCampaign = {
  __typename: 'ContractCampaign';
  /** Link to PDF for campaign details. Aka キャンペーン要項リンク */
  campaignPdfUrl: Maybe<Scalars['String']['output']>;
  /** Campaign code */
  code: Scalars['String']['output'];
  /** Description for internal use only */
  description: Maybe<Scalars['String']['output']>;
  /** Description for external use */
  displayDescription: Maybe<Scalars['String']['output']>;
  /** Name for external use. Note some campaigns will not have display names */
  displayTitle: Maybe<Scalars['String']['output']>;
  /** Name for internal use only */
  name: Scalars['String']['output'];
};

/** A charge incurred by the customer on their contract */
export type ContractCharge = {
  __typename: 'ContractCharge';
  name: Maybe<Scalars['String']['output']>;
  priceExcludingTax: Maybe<Scalars['Int']['output']>;
  priceIncludingTax: Maybe<Scalars['Int']['output']>;
};

export type ContractInfo = {
  __typename: 'ContractInfo';
  contractStartDateTime: Maybe<Scalars['DateTime']['output']>;
  contractStatusId: Maybe<Scalars['Int']['output']>;
  contractStatusTypeCode: Maybe<Scalars['String']['output']>;
  contractStatusTypeName: Maybe<Scalars['String']['output']>;
  contractStatusUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** Contracted Plan as part of a mobile service contract */
export type ContractPlan = {
  __typename: 'ContractPlan';
  name: Scalars['String']['output'];
  planTypeEnum: Maybe<PlanTypeEnum>;
  priceExcludingTax: Scalars['Int']['output'];
  priceIncludingTax: Scalars['Int']['output'];
};

/**
 * A product that can be purchased to provide access to a media.
 * TVOD and EST Products are also ContractProducts. SVOD are Excluded.
 */
export type ContractProduct = {
  __typename: 'ContractProduct';
  /** Product Display Name (単話名、又は全話の場合は、”全話（N)” でエピソード数を示す) */
  displayName: Maybe<Scalars['String']['output']>;
  /**
   * expiredDateTime is the available date time for viewing period if user do a rental in case of TOD, which is used to calculate and display 視聴可能期限
   * expiredDateTime is empty for EST
   */
  expiredDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Whether the product is currently undergoing a promotion */
  onSale: Scalars['Boolean']['output'];
  /** Original price if there was no promotion */
  originalPrice: Maybe<Scalars['Int']['output']>;
  /** Original price if there was no promotion including tax */
  originalPriceInTax: Maybe<Scalars['Int']['output']>;
  /** Price in yen */
  price: Maybe<Scalars['Int']['output']>;
  /** Price including tax */
  priceInTax: Maybe<Scalars['Int']['output']>;
  /** Product Code */
  productCode: Scalars['String']['output'];
  /** Name of the product in Japanese */
  productName: Scalars['String']['output'];
  /** End of Sale period (公開終了時間) */
  publishEndDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Start of Sale period (公開開始時間) */
  publishStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Product Sale Type Code */
  saleTypeCode: Scalars['String']['output'];
  /** Product Segment (単話 又は 全話) */
  segment: Maybe<Scalars['String']['output']>;
};

export type ContractProductSummary = {
  __typename: 'ContractProductSummary';
  copyright: Maybe<Scalars['String']['output']>;
  hasMultiplePrices: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  minPrice: Maybe<Scalars['Int']['output']>;
  paymentBadges: Maybe<Array<CapyPaymentBadgeCode>>;
  platformLifetimeClass: TitleLifetimeClass;
  publicEndDateTime: Scalars['DateTime']['output'];
  publicStartDateTime: Scalars['DateTime']['output'];
  saleBadges: Maybe<Array<CapySaleBadgeCode>>;
};

/** A sim user present on a contract */
export type ContractSimUser = {
  __typename: 'ContractSimUser';
  isUnder18: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Maybe<Scalars['String']['output']>;
  simType: ContractCharge;
  voicePlan: Maybe<ContractCharge>;
};

export enum CooperationPointsPolicy {
  USE_COOPERATION_CARD_ONLY = 'USE_COOPERATION_CARD_ONLY',
  USE_COOPERATION_CARD_PRIORITY = 'USE_COOPERATION_CARD_PRIORITY',
  USE_COOPERATION_POINT_ONLY = 'USE_COOPERATION_POINT_ONLY',
}

/** Policy for cooperating partners like Yamada */
export enum CooperationPointsPolicyInput {
  /** Only use card */
  USE_COOPERATION_CARD_ONLY = 'USE_COOPERATION_CARD_ONLY',
  /** Prefer card over points */
  USE_COOPERATION_CARD_PRIORITY = 'USE_COOPERATION_CARD_PRIORITY',
  /** Only use points */
  USE_COOPERATION_POINT_ONLY = 'USE_COOPERATION_POINT_ONLY',
}

/** Ticket identification. There are old and new cinemas. */
export enum CouponTypeInput {
  /** old cinemas */
  CINEMA_CLASSIC_TYPE = 'CINEMA_CLASSIC_TYPE',
  /** new cinema */
  CINEMA_QR_TYPE = 'CINEMA_QR_TYPE',
  /** new food */
  FOOD_QR_TYPE = 'FOOD_QR_TYPE',
}

/** Create SBPS registration billing method request */
export type CreateRegisterSbpsV2BillingMethodRequest = {
  __typename: 'CreateRegisterSbpsV2BillingMethodRequest';
  /** Returns the html body of the form that should be submitted to SBPS V2 to create a registration request */
  formBody: Scalars['String']['output'];
};

/** Input for preparing SBPS subscription purchase request */
export type CreateSbpsSubscriptionPurchaseRequestInput = {
  /** The redirect URL for the failure of the SBPS payment */
  cancelUrl: Scalars['String']['input'];
  /** The redirect URL for the SBPS payment error */
  errorUrl: Scalars['String']['input'];
  /** landing page code */
  landingPageCode: Scalars['String']['input'];
  /** sbps payment method */
  sbpsPaymentMethod: SbpsPaymentMethodInput;
  /** The redirect URL for the success of the SBPS payment */
  successUrl: Scalars['String']['input'];
};

/** union for errors encountered in unext account creation */
export type CreateUnextAccountError =
  | BadAddressError
  | BadBirthdayError
  | BadUserNameError
  | BadZipCodeError
  | DuplicateEmailAddressError
  | DuplicateLoginIdError
  | EmailAddressValidationError
  | EscottTokenExpiredError
  | InvalidCreditCardError
  | InvalidEscottTokenError
  | InvalidLoginIdError
  | InvalidPasswordError
  | NotEligibleForFreeTrialError;

/** Server response upon attempting to create a unext account. */
export type CreateUnextAccountResponse = {
  __typename: 'CreateUnextAccountResponse';
  /** Updated account entity. Returns null if there were errors */
  account: Maybe<UnextAccount>;
  /**
   * loginInfo contains the OAuth2 login credentials for the user. If requested, the user will be
   * logged in via OAuth2. If setCookie is set to true, the credentials will be saved in the cookie,
   * and not returned in the query result.
   */
  loginInfo: Maybe<LoginInfoV2>;
  /** Errors on the input. */
  userErrors: Array<CreateUnextAccountError>;
};

/** Server response upon attempting to create a unext account. */
export type CreateUnextAccountResponseloginInfoArgs = {
  setCookie?: InputMaybe<Scalars['Boolean']['input']>;
};

/** union for errors encountered in unext user creation */
export type CreateUnextUserError =
  | DuplicateEmailAddressError
  | DuplicateLoginIdError
  | EmailAddressValidationError
  | InvalidLoginIdError
  | InvalidPasswordError
  | MaxAccountNumberReachedError
  | UserInputsError;

/** Server response upon attempting to create a unext user. */
export type CreateUnextUserResponse = {
  __typename: 'CreateUnextUserResponse';
  /** Updated account entity. Returns null if there were errors */
  account: Maybe<UnextAccount>;
  /**
   * loginInfo contains the OAuth2 login credentials for the user. If requested, the user will be
   * logged in via OAuth2. If setCookie is set to true, the credentials will be saved in the cookie,
   * and not returned in the query result.
   */
  loginInfo: Maybe<LoginInfoV2>;
  /** Errors on the input. */
  userErrors: Array<CreateUnextUserError>;
};

/** Server response upon attempting to create a unext user. */
export type CreateUnextUserResponseloginInfoArgs = {
  setCookie?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Response from server after creating a user */
export type CreateUserResponse = {
  __typename: 'CreateUserResponse';
  loginId: Maybe<Scalars['String']['output']>;
  /** loginInfo contains the OAuth2 login credentials for the user. If requested, the user will be logged in via OAuth2. If setCookie is set to true, the credentials will be saved in the cookie, and not returned in the query result. */
  loginInfo: Maybe<LoginInfo>;
  multiAccountId: Maybe<Scalars['String']['output']>;
  /** Denotes whether or not creating a user was successful */
  status: Maybe<Scalars['String']['output']>;
  userPlatformId: Maybe<Scalars['String']['output']>;
};

/** Response from server after creating a user */
export type CreateUserResponseloginInfoArgs = {
  setCookie?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Credit = {
  __typename: 'Credit';
  castTypeName: Maybe<Scalars['String']['output']>;
  characterName: Maybe<Scalars['String']['output']>;
  personCode: Maybe<Scalars['String']['output']>;
  personName: Maybe<Scalars['String']['output']>;
  personNameCode: Maybe<Scalars['String']['output']>;
};

/** Although this type is called CreditCardInfo, it also handles debit cards as well */
export type CreditCardInfo = {
  __typename: 'CreditCardInfo';
  brandTypeCode: Maybe<Scalars['String']['output']>;
  cardType: Maybe<Scalars['String']['output']>;
  companyCode: Maybe<Scalars['String']['output']>;
  creditCardCompany: Maybe<Scalars['String']['output']>;
  creditCardExpiryDate: Maybe<Scalars['String']['output']>;
  creditCardNumber: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
};

/** User executing the purchase does not have the required currency authorization level */
export type CurrencyAuthorizationError = UserFacingError & {
  __typename: 'CurrencyAuthorizationError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

export type CustomerInfo = {
  __typename: 'CustomerInfo';
  address: Maybe<Address>;
  dateOfBirth: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  firstNameKana: Maybe<Scalars['String']['output']>;
  gender: Maybe<Gender>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  lastNameKana: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
};

/**
 * Customer info input for the contract holder (aka BOS/Common Infrastructure user) signing up
 * via the Yu-mobile intake
 */
export type CustomerInfoInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['DateTime']['input'];
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  firstNameKana: Scalars['String']['input'];
  gender: GenderInput;
  lastName: Scalars['String']['input'];
  lastNameKana: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  prefecture: Scalars['String']['input'];
};

/** Input for updating customer info */
export type CustomerInfoUpdateInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameKana?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  prefecture?: InputMaybe<Scalars['String']['input']>;
};

/** Customer Inquiry Category */
export type CustomerInquiryCategory = {
  __typename: 'CustomerInquiryCategory';
  additionalQuestionPattern: Maybe<Scalars['String']['output']>;
  children: Maybe<Array<CustomerInquiryCategory>>;
  id: Scalars['ID']['output'];
  linkUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent: Maybe<Scalars['String']['output']>;
};

/** Response for getting customer inquiry category */
export type CustomerInquiryCategoryResponse = {
  __typename: 'CustomerInquiryCategoryResponse';
  children: Maybe<CustomerInquiryCategory>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Input for sending customer inquiry */
export type CustomerInquiryInput = {
  category: Scalars['String']['input'];
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  contractStatus?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameKana?: InputMaybe<Scalars['String']['input']>;
  inquirySourcePage: Scalars['String']['input'];
  inquiryTargetPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameKana?: InputMaybe<Scalars['String']['input']>;
  replyEmailAddress: Scalars['String']['input'];
};

/** Input for sending customer inquiry V2 */
export type CustomerInquiryInputV2 = {
  additionalInquiries?: InputMaybe<Array<AdditionalCustomerInquiry>>;
  category1: Scalars['String']['input'];
  category2: Scalars['String']['input'];
  category3?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  formType: Scalars['String']['input'];
  inquiryTargetPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  replyEmailAddress: Scalars['String']['input'];
  userInfo?: InputMaybe<CustomerInquiryUserInfo>;
};

/** Response for customer inquiry */
export type CustomerInquiryResponse = CheckableStatus & {
  __typename: 'CustomerInquiryResponse';
  status: Scalars['Boolean']['output'];
};

/** Input for getting user info when sending customer inquiry */
export type CustomerInquiryUserInfo = {
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  firstNameKana: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lastNameKana: Scalars['String']['input'];
};

/** A singular instance of data charge */
export type DataCharge = {
  __typename: 'DataCharge';
  appliedBy: DataChargeUserType;
  capacity: Scalars['String']['output'];
  dateApplied: Scalars['DateTime']['output'];
  /** Data charge id */
  id: Scalars['ID']['output'];
  isCanceled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** Provides the data charge history with paging information */
export type DataChargeHistory = PaginatedResponse & {
  __typename: 'DataChargeHistory';
  pageInfo: PageInfo;
  records: Array<Maybe<DataCharge>>;
};

/** Data Charge Product */
export type DataChargeProduct = {
  __typename: 'DataChargeProduct';
  capacity: Scalars['String']['output'];
  discountAmount: Scalars['Int']['output'];
  discountAmountWithTax: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceExcludingTax: Scalars['Int']['output'];
  priceIncludingTax: Scalars['Int']['output'];
  regularPriceWithoutTax: Scalars['Int']['output'];
  taxRate: Scalars['Float']['output'];
};

/** Input for purchasing data charge product */
export type DataChargeProductInput = {
  id: Scalars['ID']['input'];
};

/** Response for data charge */
export type DataChargeResponse = CheckableStatus & {
  __typename: 'DataChargeResponse';
  status: Scalars['Boolean']['output'];
};

/** Automatic Data charge configuration settings */
export type DataChargeSettingInput = {
  /** Upper limit data capacity limit */
  upperLimit: Scalars['Int']['input'];
  versionNo: Scalars['Int']['input'];
};

/** The user type of data charge */
export enum DataChargeUserType {
  CAMPAIGN = 'CAMPAIGN',
  CS = 'CS',
  USER = 'USER',
}

/** Represents the data usage for a one day period */
export type DataUsageHistoryEntry = {
  __typename: 'DataUsageHistoryEntry';
  /** Date of the data usage measurement */
  date: Scalars['DateTime']['output'];
  /** Data usage in (MB) */
  usage: Scalars['Int']['output'];
};

/** union for errors encountered when trying to delete unextUser */
export type DeleteUnextUserError = NotPermittedError;

/** Delete UnextUser Response */
export type DeleteUnextUserResponse = CheckableStatus & {
  __typename: 'DeleteUnextUserResponse';
  /** Returns true if everything was ok */
  status: Scalars['Boolean']['output'];
  /** List of errors encountered when attempting to terminate an account */
  userErrors: Array<DeleteUnextUserError>;
};

/** The status of delivery */
export enum DeliveryStatus {
  /** 着荷済み */
  ARRIVED = 'ARRIVED',
  /** キャンセル済み */
  CANCELED = 'CANCELED',
  /** 配送中 */
  DELIVERING = 'DELIVERING',
  /** 配送手続き中 */
  PREPARING = 'PREPARING',
  /** 不在戻り */
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
}

/** Union of the errors that can be encountered when trying to make a deposit */
export type DepositPointsError = InsufficientPointsError;

/** Input filters for device config */
export type DeviceConfigFilters = {
  carrier?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  diskVolume?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
};

/** Response for device purchase check */
export type DevicePurchaseCheckResponse = {
  __typename: 'DevicePurchaseCheckResponse';
  /** Number of device purchases allowed */
  allowedDevicePurchases: Scalars['Int']['output'];
  /** Can the user purchase devices or not */
  canPurchaseDevices: Scalars['Boolean']['output'];
  /** Reason why a user is not eligible if canPurchaseDevices is false */
  reasonCode: Maybe<DevicePurchaseIneligibilityCode>;
};

export type DevicePurchaseCustomerInfo = {
  __typename: 'DevicePurchaseCustomerInfo';
  addressLine1: Scalars['String']['output'];
  addressLine2: Scalars['String']['output'];
  addressLine3: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  prefecture: Scalars['String']['output'];
};

/** Input for getting customer info when making a purchase for a device */
export type DevicePurchaseCustomerInfoInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  prefecture: Scalars['String']['input'];
};

/** The ineligibility code for device purchase */
export enum DevicePurchaseIneligibilityCode {
  /** 購入不可期間中もしくは購入上限台数に達している */
  CAN_NOT_PURCHASE_TERM_OR_MAX_LIMIT = 'CAN_NOT_PURCHASE_TERM_OR_MAX_LIMIT',
  /** マイグレ可能なNSBO_PF */
  IS_NSBO_USER = 'IS_NSBO_USER',
  /** 休止中ステータスの場合 */
  IS_SUSPENDED = 'IS_SUSPENDED',
  /** 本人確認審査済みSIM利用者がいない */
  NO_ELIGIBLE_SIM_USERS = 'NO_ELIGIBLE_SIM_USERS',
  /** BOS_PFだがYUモバイルを申し込んでいない */
  NO_MOBILE_CONTRACT = 'NO_MOBILE_CONTRACT',
  /** 有効な決済が登録されていない場合 */
  NO_VALID_PAYMENT_METHOD = 'NO_VALID_PAYMENT_METHOD',
  /** 該当端末の在庫がない */
  SOLD_OUT = 'SOLD_OUT',
}

/** Response for device purchase */
export type DevicePurchaseResponse = {
  __typename: 'DevicePurchaseResponse';
  configurationName: Scalars['String']['output'];
  customerInfo: DevicePurchaseCustomerInfo;
  deliveryCompanyName: Maybe<Scalars['String']['output']>;
  deliveryTrackingNo: Maybe<Scalars['String']['output']>;
  deliveryTrackingUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imei: Scalars['String']['output'];
  orderNo: Scalars['String']['output'];
  priceExcludingTax: Scalars['Int']['output'];
  priceIncludingTax: Scalars['Int']['output'];
  purchasableConfigurationId: Scalars['ID']['output'];
  purchaseDateTime: Maybe<Scalars['DateTime']['output']>;
  reservationDateTime: Scalars['DateTime']['output'];
  seller: Scalars['String']['output'];
  shippingDateTime: Maybe<Scalars['DateTime']['output']>;
  statusCode: DevicePurchaseStatus;
  statusName: Scalars['String']['output'];
  versionNo: Scalars['Int']['output'];
};

/** The status of device purchase */
export enum DevicePurchaseStatus {
  /** キャンセル済み */
  CANCELED = 'CANCELED',
  /** 購入完了 */
  PURCHASED = 'PURCHASED',
  /** 注文完了 */
  RESERVED = 'RESERVED',
}

/** Represents a login session */
export type DeviceSession = {
  __typename: 'DeviceSession';
  /** When this session was first created (i.e. when did the user first login) */
  createdAt: Scalars['DateTime']['output'];
  /** Enum to constrain the possible device type for users */
  deviceType: DeviceType;
  /** Localized display name for the deviceType */
  deviceTypeDisplayName: Scalars['String']['output'];
  /** Indicates whether or not this device session is the user's current session */
  isCurrentSession: Scalars['Boolean']['output'];
  /** When was this session last used to generate an access token (oauth) or user token (legacy auth) */
  lastRefreshed: Scalars['DateTime']['output'];
  /** A rough estimate of the phyical location using a MaxMind DB as our location provider */
  location: Maybe<Scalars['String']['output']>;
  /** Observed ip address that was used to create the session */
  remoteIp: Scalars['String']['output'];
  /** Identifier for the session */
  sessionId: Scalars['String']['output'];
  /** Whether or not this session currently has SUDO mode */
  sudo: Scalars['Boolean']['output'];
  /** User agent of the device that was used to create the session */
  userAgent: Maybe<Scalars['String']['output']>;
};

/** List of device types (per the user-agent parser) */
export enum DeviceType {
  /** Amazon Fire TVs */
  AMAZON_FIRETV = 'AMAZON_FIRETV',
  /** Android phones (native) */
  ANDROID_PHONE = 'ANDROID_PHONE',
  /** Android phones (web) */
  ANDROID_PHONE_WEB = 'ANDROID_PHONE_WEB',
  /** Android tablets (native) */
  ANDROID_TABLET = 'ANDROID_TABLET',
  /** Android tablets (web) */
  ANDROID_TABLET_WEB = 'ANDROID_TABLET_WEB',
  /** Android TVs */
  ANDROID_TV = 'ANDROID_TV',
  /** Apple iPads (native) */
  APPLE_IPAD = 'APPLE_IPAD',
  /** Apple iPads (web) */
  APPLE_IPAD_WEB = 'APPLE_IPAD_WEB',
  /** Apple iPhones (native) */
  APPLE_IPHONE = 'APPLE_IPHONE',
  /** Apple iPhones (web) */
  APPLE_IPHONE_WEB = 'APPLE_IPHONE_WEB',
  /** Mac computers */
  MAC_COMPUTER = 'MAC_COMPUTER',
  /** Sony PlayStations */
  PLAYSTATION = 'PLAYSTATION',
  /** Generic TV */
  TV = 'TV',
  /** All the rest unknown devices */
  UNKNOWN = 'UNKNOWN',
  /** Computers */
  WINDOWS_COMPUTER = 'WINDOWS_COMPUTER',
}

export type DisplayBill = {
  __typename: 'DisplayBill';
  amount: Scalars['Int']['output'];
  billingPlatformCode: BillingPlatformCode;
  canIssueInvoice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAdult: Scalars['Boolean']['output'];
  isRefunded: Scalars['Boolean']['output'];
  productName: Scalars['String']['output'];
  saleDateTime: Scalars['DateTime']['output'];
};

/** The Summary of monthly bills. */
export type DisplayBillsSummary = {
  __typename: 'DisplayBillsSummary';
  bills: Array<Maybe<DisplayBill>>;
  month: Scalars['DateTime']['output'];
  totalAmount: Scalars['Int']['output'];
};

export type DownloadTitleMeta = {
  __typename: 'DownloadTitleMeta';
  keywords: Array<Scalars['String']['output']>;
  titleInKatakana: Scalars['String']['output'];
};

export enum DrmType {
  FAIRPLAY = 'FAIRPLAY',
  PLAYREADY = 'PLAYREADY',
  WIDEVINE = 'WIDEVINE',
}

export enum DubSubType {
  ALL = 'ALL',
  DUB = 'DUB',
  SUB = 'SUB',
}

/** Provided email address has been used */
export type DuplicateEmailAddressError = UserFacingError & {
  __typename: 'DuplicateEmailAddressError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Email address is registered to more than one user */
export type DuplicateEmailError = UserFacingError & {
  __typename: 'DuplicateEmailError';
  /** The error message */
  message: Scalars['String']['output'];
};

/** Provided login ld has been used */
export type DuplicateLoginIdError = UserFacingError & {
  __typename: 'DuplicateLoginIdError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Provided phone number is already in use */
export type DuplicatePhoneNumberError = UserFacingError & {
  __typename: 'DuplicatePhoneNumberError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

export enum DynamicRange {
  DV = 'DV',
  HDR10 = 'HDR10',
  HDR10PLUS = 'HDR10PLUS',
  SDR = 'SDR',
  UNSPECIFIED = 'UNSPECIFIED',
}

/** Response for getEScottTokenUrl */
export type EScottResponse = {
  __typename: 'EScottResponse';
  tokenUrl: Maybe<Scalars['String']['output']>;
};

export type EScottTokenError = {
  __typename: 'EScottTokenError';
  code: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
};

/** Response for getEScottTokenStatus */
export type EScottTokenStatusResponse = {
  __typename: 'EScottTokenStatusResponse';
  /** @deprecated This is an old error type that does not implement UserFacingError */
  errorList: Maybe<Array<Maybe<EScottTokenError>>>;
};

/** Error for when the email address fails validation */
export type EmailAddressValidationError = UserFacingError & {
  __typename: 'EmailAddressValidationError';
  /** The code that describes this error */
  code: EmailAddressValidationErrorCode;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The error code for email address validation */
export enum EmailAddressValidationErrorCode {
  /** The email address is already in use */
  DUPLICATE = 'DUPLICATE',
  /** The address contained an invalid character */
  INVALID_CHARACTER = 'INVALID_CHARACTER',
  /** The address is not in a valid email format */
  INVALID_FORMAT = 'INVALID_FORMAT',
  /** The address exceeded the max length */
  MAX_LENGTH = 'MAX_LENGTH',
  /** The email address is not allowed to be used */
  NOT_ALLOWED = 'NOT_ALLOWED',
}

/** Error for when the email verification code fails validation */
export type EmailCodeValidationError = UserFacingError & {
  __typename: 'EmailCodeValidationError';
  /** The code that describes this error */
  code: EmailCodeValidationErrorCode;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The error code for email address validation */
export enum EmailCodeValidationErrorCode {
  /** The email verification code has expired */
  EXPIRED_CODE = 'EXPIRED_CODE',
  /** The email verification code was incorrect */
  INCORRECT_CODE = 'INCORRECT_CODE',
  /** The email verification code could not be verified for this user */
  INVALID_USER = 'INVALID_USER',
  /** The email verification was attempted too many times */
  MAX_ATTEMPTS = 'MAX_ATTEMPTS',
}

/** Email address does not belong to any existing user */
export type EmailNotRegisteredError = UserFacingError & {
  __typename: 'EmailNotRegisteredError';
  /** The error message */
  message: Scalars['String']['output'];
};

/** Type for specifying additional query parameters when sending the registration email */
export type EmailRegistrationWebParamInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['NumberString']['input']>;
};

/** Input for validating an email verification code */
export type EmailVerificationCodeValidationInput = {
  /** Security hash */
  hash: Scalars['String']['input'];
  /** Secret token */
  token: Scalars['String']['input'];
  /** Email verification code */
  verificationCode: Scalars['String']['input'];
};

/** Response for email verification */
export type EmailVerificationResponse = CheckableStatus & {
  __typename: 'EmailVerificationResponse';
  status: Scalars['Boolean']['output'];
};

export type EmergencyNotification = {
  __typename: 'EmergencyNotification';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['DateTime']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  lastUpdatedBy: Maybe<Scalars['String']['output']>;
  level: Maybe<Level>;
  name: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EmergencyNotificationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  level: Level;
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type EmergencyNotificationsPaginatedResponse = {
  __typename: 'EmergencyNotificationsPaginatedResponse';
  emergencyNotifications: Maybe<Array<Maybe<EmergencyNotification>>>;
  totalCount: Scalars['Int']['output'];
};

export type EndpointUrl = {
  __typename: 'EndpointUrl';
  licenseUrlList: Array<LicenseUrl>;
  playlistUrl: Maybe<Scalars['String']['output']>;
  type: Maybe<StreamingType>;
};

export type EndpointUrlV2 = {
  __typename: 'EndpointUrlV2';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  playables: Array<Playable>;
  previews: Maybe<Array<Preview>>;
  sceneSearchList: Maybe<Array<SceneSearch>>;
};

export type Episode = {
  __typename: 'Episode';
  chromecastFlag: Maybe<Scalars['Boolean']['output']>;
  completeFlag: Maybe<Scalars['Boolean']['output']>;
  displayDurationText: Maybe<Scalars['String']['output']>;
  displayNo: Maybe<Scalars['String']['output']>;
  downloadFlag: Maybe<Scalars['Boolean']['output']>;
  duration: Maybe<Scalars['Int']['output']>;
  endDateText: Maybe<Scalars['String']['output']>;
  endrollPosition: Maybe<Scalars['Int']['output']>;
  episodeName: Maybe<Scalars['String']['output']>;
  episodeNotices: Maybe<Array<Scalars['String']['output']>>;
  /** for getWatchingEpisode */
  episodeTitleInfo: Maybe<EpisodeTitleInfo>;
  episodelistButtonName: Maybe<Scalars['String']['output']>;
  existsRelatedEpisode: Maybe<Scalars['Boolean']['output']>;
  hasDub: Maybe<Scalars['Boolean']['output']>;
  hasMultiplePrices: Scalars['Boolean']['output'];
  hasPackRights: Scalars['Boolean']['output'];
  hasSubtitle: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  interruption: Maybe<Scalars['Int']['output']>;
  /** @deprecated no need. use interruption instead */
  interruptionPercent: Maybe<Scalars['Int']['output']>;
  introduction: Maybe<Scalars['String']['output']>;
  isNew: Maybe<Scalars['Boolean']['output']>;
  /** returns purchased status if logged-in, else false */
  isPurchased: Maybe<Scalars['Boolean']['output']>;
  maxResolutionCode: Maybe<Scalars['String']['output']>;
  /** @deprecated please use minimumPrice instead */
  minPrice: Maybe<Scalars['Int']['output']>;
  /** returns -1 for SVOD */
  minimumPrice: Scalars['Int']['output'];
  movieTypeCode: Maybe<Scalars['String']['output']>;
  no: Maybe<Scalars['String']['output']>;
  nodCatchupFlag: Maybe<Scalars['Boolean']['output']>;
  nodCatchupPlanCode: Maybe<Scalars['String']['output']>;
  nodSpecialFlag: Maybe<Scalars['Boolean']['output']>;
  nodSpecialPlanCode: Maybe<Scalars['String']['output']>;
  /** @deprecated replaced by paymentBadgeList */
  paymentBadge: Maybe<PaymentBadge>;
  paymentBadgeList: Array<PaymentBadge>;
  playButtonName: Maybe<Scalars['String']['output']>;
  productLineupCodeList: Array<Scalars['String']['output']>;
  publicEndDate: Maybe<Scalars['DateTime']['output']>;
  publicStartDate: Maybe<Scalars['DateTime']['output']>;
  publishStyleCode: Maybe<Scalars['String']['output']>;
  purchaseEpisodeLimitday: Maybe<Scalars['String']['output']>;
  saleText: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  /** @deprecated replaced by episodeNotices */
  supplementText1: Maybe<Scalars['String']['output']>;
  /** @deprecated replaced by episodeNotices */
  supplementText2: Maybe<Scalars['String']['output']>;
  thumbnail: Maybe<Thumbnail>;
};

export type EpisodeCredit = {
  __typename: 'EpisodeCredit';
  episodeDisplayNumber: Maybe<Scalars['String']['output']>;
  episodeName: Maybe<Scalars['String']['output']>;
  episodeNumber: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  people: Array<Credit>;
};

export type EpisodeCreditResponse = {
  __typename: 'EpisodeCreditResponse';
  episodeCredits: Array<EpisodeCredit>;
  pageInfo: PortalPageInfo;
};

export type EpisodeTitleInfo = {
  __typename: 'EpisodeTitleInfo';
  hasMultiprice: Scalars['Boolean']['output'];
  hasPackRights: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastEpisode: Scalars['String']['output'];
  minimumPrice: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nfreeBadge: Scalars['String']['output'];
  productLineupCodeList: Array<Scalars['String']['output']>;
  rate: Scalars['Int']['output'];
  updateOfWeek: Scalars['Int']['output'];
};

export type Epub = {
  __typename: 'Epub';
  content: Maybe<Scalars['String']['output']>;
  coverImage: Maybe<Scalars['String']['output']>;
  isEncrypted: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** Error for escott token expired. */
export type EscottTokenExpiredError = UserFacingError & {
  __typename: 'EscottTokenExpiredError';
  /** The error message. */
  message: Scalars['String']['output'];
};

export enum EvaluationTypes {
  RATE_0 = 'RATE_0',
  RATE_1 = 'RATE_1',
  RATE_2 = 'RATE_2',
  RATE_3 = 'RATE_3',
  RATE_4 = 'RATE_4',
  RATE_5 = 'RATE_5',
}

export enum ExclusiveBadgeCode {
  EXCL = 'EXCL',
  EXCL_SVOD = 'EXCL_SVOD',
}

export type ExclusiveInfo = {
  __typename: 'ExclusiveInfo';
  isOnlyOn: Scalars['Boolean']['output'];
  typeCode: Maybe<ExclusiveBadgeCode>;
};

/** Denotes what external system an account is being migrated from */
export enum ExternalAccountMigrationContext {
  /** The UnextAccount was migrated from PARAVI (LOGICAß) */
  PARAVI = 'PARAVI',
}

/** Represents the migration status of a user being migrated from an external servicel like PARAVI to BOS */
export type ExternalAccountMigrationStatus = {
  __typename: 'ExternalAccountMigrationStatus';
  /** Further information regarding the circumstances of the external migration */
  context: Maybe<ExternalAccountMigrationContext>;
  /** Is the migration still awaiting user action */
  isPending: Scalars['Boolean']['output'];
  /** Temporary email address that was assigned to the user after the external migration */
  temporaryEmailAddress: Maybe<Scalars['String']['output']>;
};

/** Remedy required to fix a failed subscription activation */
export enum FailedEntitlementGrantRemediation {
  /** Account needs to pay the deposit before entitlements can be granted */
  PAY_DEPOSIT = 'PAY_DEPOSIT',
  /** Account is suspended, so the user needs to be redirected to the account resumption (billing method update) process */
  RESUME_ACCOUNT = 'RESUME_ACCOUNT',
  /** Account needs to be upsold, i.e. the account does not have the subscription you are trying to grant entitlements for */
  UPSELL = 'UPSELL',
}

export type FamilyAccountInviteToken = {
  __typename: 'FamilyAccountInviteToken';
  expireDate: Scalars['DateTime']['output'];
  expireTime: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userPlatformCode: Scalars['String']['output'];
  userPlatformId: Scalars['String']['output'];
};

export type FavoriteBooksResponse = {
  __typename: 'FavoriteBooksResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

export type FavoriteTitlesResponse = {
  __typename: 'FavoriteTitlesResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export enum FavoriteTypes {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export type Feature = {
  __typename: 'Feature';
  /** @deprecated No need to care at client side */
  commentDisplayFlag: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  leadText: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  pageInfo: Maybe<PortalPageInfo>;
  titleComments: Array<FeatureTitleComment>;
  titles: Array<Title>;
  typeCode: Maybe<Scalars['String']['output']>;
};

export type FeatureArtwork = {
  __typename: 'FeatureArtwork';
  movies: Maybe<FeatureMovies>;
  thumbnail: Thumbnail;
  typeCode: Scalars['String']['output'];
};

export type FeatureMovies = {
  __typename: 'FeatureMovies';
  large: Maybe<Scalars['String']['output']>;
  medium: Maybe<Scalars['String']['output']>;
  small: Maybe<Scalars['String']['output']>;
};

export type FeatureTemplate = {
  __typename: 'FeatureTemplate';
  code: Scalars['ID']['output'];
  displayEnd: Scalars['DateTime']['output'];
  featureArtwork: FeatureArtwork;
  heading: Maybe<Scalars['String']['output']>;
  lead: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  typeCode: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type FeatureTemplateResponse = {
  __typename: 'FeatureTemplateResponse';
  books: Array<BookSakuhin>;
  template: FeatureTemplate;
  titles: Array<Title>;
  videoFirst: Scalars['Boolean']['output'];
};

export type FeatureTitleComment = {
  __typename: 'FeatureTitleComment';
  comment: Maybe<Scalars['String']['output']>;
  featureCode: Scalars['ID']['output'];
  /** id: featureCode_titleCode, done for facilitating-caching by client */
  id: Scalars['ID']['output'];
  titleCode: Scalars['ID']['output'];
};

export type Fee = {
  __typename: 'Fee';
  code: Maybe<Scalars['String']['output']>;
  feeWithTax: Maybe<Scalars['Int']['output']>;
  feeWithoutTax: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  taxRate: Maybe<Scalars['Float']['output']>;
};

export type FeeDetail = {
  __typename: 'FeeDetail';
  initialFees: Array<Maybe<Fee>>;
  initialSubtotal: Maybe<Scalars['Int']['output']>;
  monthlyFees: Array<Maybe<Fee>>;
  monthlySubtotal: Maybe<Scalars['Int']['output']>;
};

/** Retruns FoodCompanyCoupon */
export type FoodCompanyCoupon = {
  __typename: 'FoodCompanyCoupon';
  /** Returns faceAmount */
  faceAmount: Maybe<Scalars['Int']['output']>;
  /** Returns coupon type */
  foodCouponTypeCode: Maybe<Scalars['String']['output']>;
  /** Returns coupon name */
  foodCouponTypeName: Maybe<Scalars['String']['output']>;
  /** Returns sellingPrice */
  sellingPrice: Maybe<Scalars['Int']['output']>;
};

export type FoodCouponError = ConsumeFoodCinemaCouponError;

/** User food ticket */
export type FoodTicket = {
  __typename: 'FoodTicket';
  /** retrieve information about the company code of the food coupon you currently have by the user. */
  companyCode: Maybe<Scalars['String']['output']>;
  /** retrieve information about the company name of the food coupon you currently have by the user. */
  companyName: Maybe<Scalars['String']['output']>;
  /** retrieve information about expiration datetime on food tikcet */
  expirationDatetime: Maybe<Scalars['String']['output']>;
  /** retrieve food ticket face value information */
  faceAmount: Maybe<Scalars['Int']['output']>;
  /** retrieve food ticket hash code information */
  hashCode: Maybe<Scalars['String']['output']>;
  /** retrieve information on codes and identifiers associated with food ticket purchase transactions (transactions) */
  purchaseTransactionCode: Maybe<Scalars['String']['output']>;
  /** Number of points spent by the user to obtain tickets */
  usedPoint: Maybe<Scalars['Int']['output']>;
  /** retrieves information on the food ticket id currently held by the user */
  userFoodTicketId: Maybe<Scalars['ID']['output']>;
};

export type ForceLowBitRateFlgs = {
  __typename: 'ForceLowBitRateFlgs';
  cellular: Maybe<Scalars['Boolean']['output']>;
  wifi: Maybe<Scalars['Boolean']['output']>;
};

/**
 * Gender of users retrieved from the system may sometimes be unknown, especially if they are
 * migration users from NSBO, who were never asked for their gender during registration.
 */
export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNKNOWN = 'UNKNOWN',
}

/** For mobile service account applications, the user must be explicitly set to Female or Male */
export enum GenderInput {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

/** Generic event with no additional information */
export type GenericCalendarEvent = CalendarEvent & {
  __typename: 'GenericCalendarEvent';
  /** ContentCode: contains information specific to live content or VOD content */
  contentCode: ContentCode;
  /** Calendar Event Start Date Time */
  date: Scalars['DateTime']['output'];
  /** Calendar Event Id */
  id: Scalars['ID']['output'];
  /** Image Url: usually for background image of the event */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Calendar Event Name */
  name: Scalars['String']['output'];
  /** Descriptor tags for the event */
  tags: Maybe<Array<CalendarSearchTag>>;
};

/** Geometry includes vr formats of a vr video. */
export type Geometry = {
  __typename: 'Geometry';
  geometryType: GeometryType;
  horizontalAngleDegree: Scalars['Int']['output'];
  projectionType: ProjectionType;
  stereoLayout: StereoLayout;
  stereoVideo: Scalars['Boolean']['output'];
};

export enum GeometryType {
  REGULAR = 'REGULAR',
  UNSPECIFIED = 'UNSPECIFIED',
  VR = 'VR',
}

/** Response for getting sim user application consent token */
export type GetSimUserApplicationConsentTokenResponse = {
  __typename: 'GetSimUserApplicationConsentTokenResponse';
  token: Scalars['String']['output'];
};

/** Server response upon attempting to get the details of a giftcode. */
export type GiftCodeDetailsResponse = {
  __typename: 'GiftCodeDetailsResponse';
  /** Details of the campaign associated with the giftcode */
  campaignInfo: Maybe<CampaignInfo>;
  /** Given giftCode */
  giftCode: Scalars['String']['output'];
  /** Errors on the input. */
  userErrors: Array<GiftCodeError>;
};

/** union for errors encountered during GiftCode queries or mutations */
export type GiftCodeError = GiftCodeRejectedError;

/** Result of sending gift coupon to another user */
export type GiftCodeReceipient = {
  __typename: 'GiftCodeReceipient';
  /** recipient address */
  recipientEmail: Scalars['String']['output'];
};

/** Provided gift code was rejected */
export type GiftCodeRejectedError = UserFacingError & {
  __typename: 'GiftCodeRejectedError';
  /** A code denoting why the the gift code may have been rejected */
  code: Maybe<Scalars['String']['output']>;
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Input for creating a giftCinemaCoupon request */
export type GiftCouponInput = {
  /** ticket type */
  couponType: CouponTypeInput;
  /** Pass the ticketID held by the user. */
  ownedTicketId: Scalars['Int']['input'];
  /** Please enter the email address of the person to whom you would like to give the tickets */
  recipientAddress: Scalars['String']['input'];
  /** Ticket URL */
  ticketURL: Scalars['String']['input'];
};

/** Response when trying to grant a user their entitlements from a subscription */
export type GrantSubscriptionEntitlementsResponse = {
  __typename: 'GrantSubscriptionEntitlementsResponse';
  /**
   * Indicates the action the account needs to take on activation failure
   * Returns null if activation was successful
   */
  requiredRemediation: Maybe<FailedEntitlementGrantRemediation>;
  /** Returns true if the entitlements were granted or if they were already granted */
  success: Scalars['Boolean']['output'];
};

/** Response when trying to deposit points for a subscription */
export type GrantSubscriptionEntitlementsWithDepositResponse = {
  __typename: 'GrantSubscriptionEntitlementsWithDepositResponse';
  /** Errors that can happen if you are trying ot make a deposit */
  userErrors: Array<DepositPointsError>;
};

/** Error for when a user tries to terminate account while they still have either active subscriptions or active content packs */
export type HasActiveContractsError = UserFacingError & {
  __typename: 'HasActiveContractsError';
  /** The error message */
  message: Scalars['String']['output'];
};

export type HistoryTitlesResponse = {
  __typename: 'HistoryTitlesResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

/** Error that may occur during the iap receipt verification process */
export type IapReceiptError = UserFacingError & {
  __typename: 'IapReceiptError';
  /** Internal error code if it exists */
  code: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message: Scalars['String']['output'];
  /** Describes the type of error that occurred */
  type: IapReceiptErrorClass;
};

/** User-facing errors that can be encountered during IAP Receipt handling */
export enum IapReceiptErrorClass {
  /** Transaction for the expected product not found in the receipt */
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  /** Receipt expiration has passed */
  RECEIPT_EXPIRED = 'RECEIPT_EXPIRED',
}

/** Error that may occur during the iap upsell process */
export type IapUpsellError = UserFacingError & {
  __typename: 'IapUpsellError';
  /** Internal error code if it exists */
  code: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message: Scalars['String']['output'];
  /** Describes the type of error that occurred */
  type: UpsellErrorClass;
};

/**
 * union for errors encountered during IAP upsell
 * DEPRECATION NOTICE: InsufficientPointsError is no longer returned but kept for backwards compatibility
 */
export type IapUpsellUserError =
  | IapReceiptError
  | IapUpsellError
  | InsufficientPointsError;

export type IconThumbnail = {
  __typename: 'IconThumbnail';
  icon: Scalars['String']['output'];
};

/**
 * Read/received event for inbox messages
 *
 * One of notificationId or inAppId has to be set
 */
export type InboxEventInput = {
  /** Id for the in-app message */
  inAppId?: InputMaybe<Scalars['String']['input']>;
  /** The installation ID */
  installationId: Scalars['String']['input'];
  /** The message ID */
  messageId: Scalars['String']['input'];
  /** NotificationId that owns this push notification */
  notificationId?: InputMaybe<Scalars['String']['input']>;
  /** type of the event */
  type: InboxEventType;
};

export type InboxEventInputError = UserFacingError & {
  __typename: 'InboxEventInputError';
  /** Index of the input that triggers this error */
  index: Scalars['Int']['output'];
  /** Error message */
  message: Scalars['String']['output'];
};

export enum InboxEventType {
  READ = 'READ',
  RECEIVED = 'RECEIVED',
}

export type InboxMessage = {
  __typename: 'InboxMessage';
  /** Time this message will become active and available for the user to see. Useful for linking to scheduled notifications. */
  activeAt: Scalars['DateTime']['output'];
  /** Unique ID for the campaign/event for the messages. */
  categoryId: Scalars['String']['output'];
  /** Time of record creation */
  createdAt: Scalars['DateTime']['output'];
  /** Time for this message to fall of the user's list */
  expiresAt: Scalars['DateTime']['output'];
  /** ID for this message */
  id: Scalars['ID']['output'];
  /** The user's multiAccountId. */
  multiAccountId: Scalars['ID']['output'];
  /** A reference to the schedule_notification ID. This will be null for In-APP messages */
  notificationId: Maybe<Scalars['String']['output']>;
  /** PUSH or In-APP */
  notificationType: InboxMessageType;
  /** The payload of the message */
  payload: InboxMessagePayload;
  /** Indicates if the message has been either read, viewed, or opened by the user */
  readFlag: Scalars['Boolean']['output'];
  /** The correlation id to group the requests to indicate where they came from */
  requestId: Maybe<Scalars['String']['output']>;
  /** Time of record update */
  updatedAt: Scalars['DateTime']['output'];
};

export type InboxMessagePayload = {
  __typename: 'InboxMessagePayload';
  /** Message body */
  body: Scalars['String']['output'];
  /** Callback URI for this message */
  callbackUri: Scalars['String']['output'];
  /** ID for Android notification channels */
  channelId: Maybe<Scalars['String']['output']>;
  /** The aspect ratio of the image (if there is one) */
  imageAspectRatio: Maybe<Scalars['String']['output']>;
  /** Url for the optional image */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Message title */
  title: Scalars['String']['output'];
};

export enum InboxMessageType {
  INBOX = 'INBOX',
  IN_APP = 'IN_APP',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  UNKNOWN = 'UNKNOWN',
}

export type IncludedBook = {
  __typename: 'IncludedBook';
  bookNumber: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sakuhinCode: Scalars['String']['output'];
  thumbnail: Thumbnail;
};

/** Error for an incorrect answer. */
export type IncorrectAnswerError = UserFacingError & {
  __typename: 'IncorrectAnswerError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Error for an invalid security code value. */
export type IncorrectCodeError = UserFacingError & {
  __typename: 'IncorrectCodeError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Personal information that needs to be validated by a CRS representative */
export type InfoForConfirmationInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  firstNameKana: Scalars['String']['input'];
  gender: GenderInput;
  lastName: Scalars['String']['input'];
  lastNameKana: Scalars['String']['input'];
  mnp?: InputMaybe<MnpInput>;
  /** THIS FIELD WILL BECOME REQUIRED IN A FUTURE RELEASE */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  prefecture: Scalars['String']['input'];
};

export type InfoListItem = {
  __typename: 'InfoListItem';
  title: Scalars['String']['output'];
  ttl: Scalars['DateTime']['output'];
  type: Maybe<InfoListItemType>;
  url: Scalars['String']['output'];
};

export enum InfoListItemType {
  INFO = 'INFO',
  WARNING = 'WARNING',
}

/** Error during product purchase related to the point balance */
export type InsufficientPointsError = UserFacingError & {
  __typename: 'InsufficientPointsError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

export type IntQueryOperatorInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};

/** Provided credit card was invalid */
export type InvalidCreditCardError = UserFacingError & {
  __typename: 'InvalidCreditCardError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Provided date time value was invalid */
export type InvalidDateTimeError = UserFacingError & {
  __typename: 'InvalidDateTimeError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Error received when the submitted Escott Token is invalid */
export type InvalidEscottTokenError = UserFacingError & {
  __typename: 'InvalidEscottTokenError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The login ID is not valid */
export type InvalidLoginIdError = UserFacingError & {
  __typename: 'InvalidLoginIdError';
  /** The error code for why the validation failed */
  code: UserInfoValidationErrorCode;
  /** The error message */
  message: Scalars['String']['output'];
};

/** union for errors that occur while resetting the password for forgotten password */
export type InvalidLoginInfoError = InvalidLoginIdError | InvalidPasswordError;

/** The one time token provided is not valid or has expired */
export type InvalidOrExpiredTokenError = UserFacingError & {
  __typename: 'InvalidOrExpiredTokenError';
  /** The error message */
  message: Scalars['String']['output'];
};

/** The email address is not valid */
export type InvalidPasswordError = UserFacingError & {
  __typename: 'InvalidPasswordError';
  /** The error code for why the validation failed */
  code: UserInfoValidationErrorCode;
  /** The error message */
  message: Scalars['String']['output'];
};

/** Provided phone number was invalid */
export type InvalidPhoneNumberError = UserFacingError & {
  __typename: 'InvalidPhoneNumberError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Error for when orderId is invalid format */
export type InvalidSbpsV1OrderError = UserFacingError & {
  __typename: 'InvalidSbpsV1OrderError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Error for an invalid security code value. */
export type InvalidSecurityCodeError = UserFacingError & {
  __typename: 'InvalidSecurityCodeError';
  /** The path to the input field that caused the error. */
  field: Maybe<Array<Scalars['String']['output']>>;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** The current user is either not a partner account or has already had the login info updated */
export type InvalidTemporaryPartnerAccount = UserFacingError & {
  __typename: 'InvalidTemporaryPartnerAccount';
  /** The error message */
  message: Scalars['String']['output'];
};

/** Errors returned when the there is an issue that make the token invalid. */
export type InvalidTokenError = UserFacingError & {
  __typename: 'InvalidTokenError';
  /** Specify the reason for invalidating the token */
  code: Maybe<InvalidTokenErrorCode>;
  message: Scalars['String']['output'];
};

export enum InvalidTokenErrorCode {
  /** The token is too old and has expired. */
  EXPIRED = 'EXPIRED',
  /** The token was not correctly signed or was not a valid invite token. */
  INVALID = 'INVALID',
  /** The token was signed correctly but its payload was not of the correct format or did not contain necessary fields. */
  WRONG_PAYLOAD_FORMAT = 'WRONG_PAYLOAD_FORMAT',
}

/** The verification code provided is not correct */
export type InvalidVerificationCodeError = UserFacingError & {
  __typename: 'InvalidVerificationCodeError';
  /** The error message */
  message: Scalars['String']['output'];
};

/** Issued invoice ID and temporarily accessible signed url. */
export type IssuedInvoice = {
  __typename: 'IssuedInvoice';
  /** The ID of the issued invoice. */
  invoiceId: Scalars['String']['output'];
  /** The temporarily accessible signed URL of the issued invoice. */
  url: Scalars['String']['output'];
};

export type JapanShippingAddress = {
  __typename: 'JapanShippingAddress';
  buildingName: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  district: Scalars['String']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  municipality: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  prefecture: Scalars['String']['output'];
  roomNo: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type JapanShippingAddressInput = {
  buildingName?: InputMaybe<Scalars['String']['input']>;
  district: Scalars['String']['input'];
  municipality: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  prefecture: Scalars['String']['input'];
  roomNo?: InputMaybe<Scalars['String']['input']>;
};

export type Label = {
  __typename: 'Label';
  /**
   * キャッチ文
   * Description
   */
  catch: Scalars['String']['output'];
  /**
   * Label code
   * e.g. paravi, nod, hbo
   */
  code: Scalars['ID']['output'];
  /** Necessary for requesting corresponding Live blocks, Alt blocks and so on. */
  genreCode: Scalars['String']['output'];
  /**
   * Label name
   * e.g. "SPOTV NOW", "NHKオンデマンド", "Paravi"
   */
  name: Scalars['String']['output'];
  /** Corresponding search categories */
  searchMenuList: Array<SearchCategory>;
  thumbnail: LabelThumbnail;
};

export type LabelBlock = {
  __typename: 'LabelBlock';
  labelList: Array<Label>;
  /**
   * Label block name
   * e.g. "レーベル"
   */
  name: Scalars['String']['output'];
};

export type LabelThumbnail = {
  __typename: 'LabelThumbnail';
  /** Logo image. a.k.a "icon" */
  logo: Scalars['String']['output'];
  /** Image for stage. a.k.a "tspt_fwxga" */
  secondary: Scalars['String']['output'];
  /** Image for thumbnail. */
  standard: Scalars['String']['output'];
};

/** Type representing LandingPage. */
export type LandingPage = {
  __typename: 'LandingPage';
  /** Display description for the landing page if available. */
  displayDescription: Maybe<Scalars['String']['output']>;
  /** Display title for the landing page if available. */
  displayTitle: Maybe<Scalars['String']['output']>;
  /** Subscription products that can be added to a unext account via this landing page */
  subscriptionProducts: Array<UnextSubscriptionProduct>;
};

/** Input for adding or updating yu mobile plan or voice plan */
export type LandingPageInput = {
  campaignCode?: InputMaybe<Scalars['String']['input']>;
  landingPageCode?: InputMaybe<Scalars['String']['input']>;
  operationCode?: InputMaybe<Scalars['String']['input']>;
  salesAgencyCode?: InputMaybe<Scalars['String']['input']>;
  shopCode?: InputMaybe<Scalars['String']['input']>;
};

export type LeadTitle = {
  __typename: 'LeadTitle';
  leadCode: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  thumbnail: LeadTitleThumbnail;
  titleCode: Scalars['ID']['output'];
};

export type LeadTitleThumbnail = {
  __typename: 'LeadTitleThumbnail';
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type LeagueBlock = {
  __typename: 'LeagueBlock';
  list: Array<LeagueInfo>;
  name: Scalars['String']['output'];
};

export type LeagueDetail = {
  __typename: 'LeagueDetail';
  contentNames: Array<Scalars['String']['output']>;
  contentNumber: Scalars['Int']['output'];
  league: LeagueInfo;
};

export type LeagueInfo = {
  __typename: 'LeagueInfo';
  code: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tagCode: Scalars['ID']['output'];
  thumbnail: LeagueThumbnail;
};

export type LeagueThumbnail = {
  __typename: 'LeagueThumbnail';
  secondary: Scalars['String']['output'];
  standard: Scalars['String']['output'];
};

export type LeanbackGenre = {
  __typename: 'LeanbackGenre';
  displayCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  searchMenu: SearchMenu;
  thumbnail: IconThumbnail;
};

export type LeanbackGenreGroup = {
  __typename: 'LeanbackGenreGroup';
  displayCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  leanbackGenres: Array<LeanbackGenre>;
  name: Scalars['String']['output'];
};

export enum Level {
  EMERGENCY = 'EMERGENCY',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export type LicenseUrl = {
  __typename: 'LicenseUrl';
  licenseUrl: Maybe<Scalars['String']['output']>;
  type: Maybe<DrmType>;
};

/**
 * In case of portal-error, errorCode can be found under gql "errors" array with following message:
 *
 * "relatedLives" resolver:   "LIVE_RELATED_LIVES_ERROR",
 * "relatedVideos" resolver: "LIVE_RELATED_VIDEOS_ERROR"
 */
export type Live = {
  __typename: 'Live';
  allowsTimeshiftedPlayback: Scalars['Boolean']['output'];
  announcements: Array<LiveAnnouncement>;
  attractions: Maybe<Scalars['String']['output']>;
  castSummary: Maybe<Scalars['String']['output']>;
  caution: Scalars['String']['output'];
  copyright: Maybe<Scalars['String']['output']>;
  credits: Array<Credit>;
  deliveryEndDateTime: Scalars['DateTime']['output'];
  deliveryStartDateTime: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayLiveMinute: Scalars['Int']['output'];
  displaySaleEndDateTime: Maybe<Scalars['DateTime']['output']>;
  displayStartDateTime: Maybe<Scalars['DateTime']['output']>;
  hasPackRights: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isOnlyOn: Scalars['Boolean']['output'];
  location: Maybe<Scalars['String']['output']>;
  musicCrossAidText: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note: Maybe<Scalars['String']['output']>;
  notices: Array<LiveNotice>;
  paymentBadgeList: Array<PaymentBadge>;
  performanceStartDateTime: Scalars['DateTime']['output'];
  productLineupCodeList: Array<Scalars['String']['output']>;
  purchased: Scalars['Boolean']['output'];
  relatedLives: Maybe<Array<Live>>;
  /** @deprecated Use relatedVideos */
  relatedTitles: Array<TitleRelationGroup>;
  relatedVideos: Maybe<Array<Title>>;
  saleTypeCode: Scalars['String']['output'];
  seriesCode: Maybe<Scalars['ID']['output']>;
  subContentList: Maybe<Array<LiveSubContent>>;
  testLiveId: Scalars['ID']['output'];
  tickets: Array<LiveTicket>;
  timeshiftedPlaybackAllowedUntil: Maybe<Scalars['DateTime']['output']>;
  timeshiftedPlaybackIntactUntil: Maybe<Scalars['DateTime']['output']>;
  venue: Maybe<Scalars['String']['output']>;
  viewableDeviceText: Scalars['String']['output'];
};

export type LiveAnnouncement = {
  __typename: 'LiveAnnouncement';
  body: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/**
 * If structureType is FLAT then
 * list will only have 1 item in the array
 * otherwise if structureType is GROUP then
 * list can have multiple items
 */
export type LiveBlock = {
  __typename: 'LiveBlock';
  code: Scalars['String']['output'];
  list: Array<LiveBlockLiveGroupResponse>;
  name: Scalars['String']['output'];
  structureType: LiveBlockStructureType;
  trackingCode: Scalars['String']['output'];
};

export type LiveBlockDetailResponse = {
  __typename: 'LiveBlockDetailResponse';
  code: Scalars['String']['output'];
  liveList: Array<Live>;
  name: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
  structureType: LiveBlockStructureType;
  trackingCode: Scalars['String']['output'];
};

/**
 * If structureType is FLAT then
 * only liveList and total will have data
 * otherwise if structureType is GROUP then
 * all properties will have data
 */
export type LiveBlockLiveGroupResponse = {
  __typename: 'LiveBlockLiveGroupResponse';
  /**
   * LiveGroupCode
   *
   * e.g. LGP0000000001
   */
  code: Maybe<Scalars['String']['output']>;
  liveList: Array<Live>;
  name: Maybe<Scalars['String']['output']>;
  thumbnail: Maybe<LiveGroupThumbnail>;
  total: Scalars['Int']['output'];
};

export enum LiveBlockStructureType {
  FLAT = 'FLAT',
  GROUP = 'GROUP',
}

export type LiveBlocksResponse = {
  __typename: 'LiveBlocksResponse';
  blockList: Array<LiveBlock>;
  pageInfo: PortalPageInfo;
};

/** LiveContentCode contains information specific to live content. */
export type LiveContentCode = {
  __typename: 'LiveContentCode';
  /** A unique code identifying the live content. */
  liveCode: Scalars['String']['output'];
};

export type LiveGroupResponse = {
  __typename: 'LiveGroupResponse';
  /**
   * LiveGroupCode
   *
   * e.g. LGP0000000001
   */
  code: Scalars['String']['output'];
  liveList: Array<Live>;
  name: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
  thumbnail: LiveGroupThumbnail;
  /**
   * total_live_num
   * @deprecated Use pageInfo
   */
  total: Scalars['Int']['output'];
};

export type LiveGroupThumbnail = {
  __typename: 'LiveGroupThumbnail';
  secondary: Scalars['String']['output'];
  standard: Scalars['String']['output'];
};

export type LiveInfo = {
  __typename: 'LiveInfo';
  allowsTimeShiftedPlayback: Scalars['Boolean']['output'];
  deliveryEndEpoch: Scalars['Float']['output'];
  deliveryStartEpoch: Scalars['Float']['output'];
  performanceStartEpoch: Scalars['Float']['output'];
};

export type LiveNotice = {
  __typename: 'LiveNotice';
  id: Scalars['ID']['output'];
  movies: LiveNoticeMovie;
  name: Scalars['String']['output'];
  publicStartDateTime: Scalars['DateTime']['output'];
  thumbnail: Thumbnail;
  typeCode: Scalars['String']['output'];
};

export type LiveNoticeMovie = {
  __typename: 'LiveNoticeMovie';
  large: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
};

export type LivePagingResponse = {
  __typename: 'LivePagingResponse';
  liveList: Array<Live>;
  pageInfo: PortalPageInfo;
};

export type LivePlaylistUrl = {
  __typename: 'LivePlaylistUrl';
  beaconSpan: Maybe<Scalars['Int']['output']>;
  endpointUrls: Array<EndpointUrl>;
  playToken: Maybe<Scalars['String']['output']>;
  playTokenHash: Maybe<Scalars['String']['output']>;
  result: Maybe<Result>;
  resultStatus: Maybe<Scalars['Int']['output']>;
};

export type LivePlaylistUrlResult = {
  __typename: 'LivePlaylistUrlResult';
  beaconSpan: Scalars['Int']['output'];
  endpointUrls: Array<EndpointUrlV2>;
  endpointVersion: Maybe<Scalars['Int']['output']>;
  live: Maybe<LiveInfo>;
  playToken: Scalars['String']['output'];
  playTokenHash: Scalars['String']['output'];
  playlistTypeCode: Scalars['String']['output'];
  result: Result;
  resultStatus: Scalars['Int']['output'];
  subContentCode: Scalars['String']['output'];
  userLiveTicketCode: Scalars['String']['output'];
};

export type LivePlaylistUrlV2 = {
  __typename: 'LivePlaylistUrlV2';
  beaconSpan: Scalars['Int']['output'];
  endpointUrls: Array<EndpointUrlV2>;
  endpointVersion: Maybe<Scalars['Int']['output']>;
  live: Maybe<LiveInfo>;
  playToken: Scalars['String']['output'];
  playTokenHash: Scalars['String']['output'];
  playlistTypeCode: Scalars['String']['output'];
  result: Result;
  resultStatus: Scalars['Int']['output'];
  subContentCode: Scalars['String']['output'];
  userLiveTicketCode: Scalars['String']['output'];
};

export type LivePlaylistValidationResult = {
  __typename: 'LivePlaylistValidationResult';
  result: Result;
  resultStatus: Scalars['Int']['output'];
};

export type LiveProductList = {
  __typename: 'LiveProductList';
  contractProducts: Array<VideoAssociatedProductItem>;
  liveTickets: Array<PurchaseLiveTicket>;
};

export type LiveProductListcontractProductsArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type LiveSubContent = {
  __typename: 'LiveSubContent';
  code: Scalars['String']['output'];
  isFixedStartDateTime: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publicEndDateTime: Scalars['DateTime']['output'];
  publicStartDateTime: Maybe<Scalars['DateTime']['output']>;
  thumbnail: Maybe<LiveSubContentThumbnail>;
  typeCode: Scalars['String']['output'];
};

export type LiveSubContentThumbnail = {
  __typename: 'LiveSubContentThumbnail';
  standard: Maybe<Scalars['String']['output']>;
};

export type LiveTicket = {
  __typename: 'LiveTicket';
  id: Scalars['ID']['output'];
  isSelling: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  productCode: Scalars['String']['output'];
  saleEndDateTime: Scalars['DateTime']['output'];
  saleStartDateTime: Scalars['DateTime']['output'];
  typeCode: Scalars['String']['output'];
};

/** A product that can be purchased to give access to a live stream. */
export type LiveTicketProduct = {
  __typename: 'LiveTicketProduct';
  /** Common Product ID (cmn_product_id in LCMS) */
  commonProductId: Maybe<Scalars['String']['output']>;
  /** Live Ticket Code */
  id: Scalars['String']['output'];
  /** Name of the ticket in Japanese */
  name: Scalars['String']['output'];
  /** Whether the product is selling at the moment. */
  onSale: Scalars['Boolean']['output'];
  /** Price in JPY */
  price: Maybe<Scalars['Int']['output']>;
  /** Price in JPY tax included */
  priceInTax: Maybe<Scalars['Int']['output']>;
  /** End sale date */
  saleEndDateTime: Scalars['DateTime']['output'];
  /** Start sale date */
  saleStartDateTime: Scalars['DateTime']['output'];
  /** Type code for ticket */
  typeCode: Scalars['String']['output'];
};

export enum Location {
  /** supposed to open the link from web browser. */
  EXTERNAL_BROWSER = 'EXTERNAL_BROWSER',
  /** supposed to open the link from browser inside app(like webview). */
  INAPP_BROWSER = 'INAPP_BROWSER',
  /** supposed to use this feature from native app, so ignore it. */
  NATIVE_APP = 'NATIVE_APP',
}

export type LoginCredentials = {
  __typename: 'LoginCredentials';
  id: Maybe<Scalars['ID']['output']>;
  loginId: Scalars['String']['output'];
  role: Role;
};

export type LoginDelegationAuthorizeSessionResponse = {
  __typename: 'LoginDelegationAuthorizeSessionResponse';
  authorized: Maybe<Scalars['Boolean']['output']>;
};

export type LoginDelegationCreateSessionResponse = {
  __typename: 'LoginDelegationCreateSessionResponse';
  authPageUrlTemplate: Maybe<Scalars['String']['output']>;
  code: Maybe<Scalars['String']['output']>;
  deviceInfo: Maybe<LoginDelegationSessionDeviceInfo>;
  expiresAt: Maybe<Scalars['String']['output']>;
  sessionId: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  userInfo: Maybe<LoginDelegationUserInfo>;
};

export type LoginDelegationPollResponse = {
  __typename: 'LoginDelegationPollResponse';
  oneTimeToken: Maybe<Scalars['String']['output']>;
};

export type LoginDelegationRevokeSessionResponse = {
  __typename: 'LoginDelegationRevokeSessionResponse';
  revoked: Maybe<Scalars['Boolean']['output']>;
};

export type LoginDelegationSessionDeviceInfo = {
  __typename: 'LoginDelegationSessionDeviceInfo';
  appVersion: Maybe<Scalars['String']['output']>;
  deviceName: Maybe<Scalars['String']['output']>;
  deviceType: Maybe<Scalars['String']['output']>;
  deviceUuid: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
};

export type LoginDelegationUserInfo = {
  __typename: 'LoginDelegationUserInfo';
  ip: Maybe<Scalars['String']['output']>;
  userAgent: Maybe<Scalars['String']['output']>;
};

/** OAuth2 Login Credentials */
export type LoginInfo = {
  __typename: 'LoginInfo';
  accessToken: Maybe<Scalars['String']['output']>;
  refreshToken: Maybe<Scalars['String']['output']>;
};

/** OAuth2 Login Credentials (served from account graph) */
export type LoginInfoV2 = {
  __typename: 'LoginInfoV2';
  /** User's OAuth Access Token (handle with care) */
  accessToken: Maybe<Scalars['String']['output']>;
  /** User's OAuth Refresh Token (handle with EXTREME care) */
  refreshToken: Maybe<Scalars['String']['output']>;
};

export type LoginInput = {
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Role;
};

export type LoginUpdateInput = {
  loginId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
};

/** Landing page promotional data */
export type LpData = {
  __typename: 'LpData';
  campaign: Maybe<ContractCampaign>;
  /**
   * Deprecated. Use campaign.code instead
   * @deprecated No longer supported
   */
  campaignCode: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  /** LpData Id is identical to LandingPageCode */
  id: Scalars['ID']['output'];
  landingPageCode: Scalars['String']['output'];
  landingPageEndDateTime: Maybe<Scalars['DateTime']['output']>;
  landingPageName: Scalars['String']['output'];
  landingPageStartDateTime: Scalars['DateTime']['output'];
  landingPageTypeCode: Scalars['String']['output'];
  landingPageTypeName: Scalars['String']['output'];
  mailTypeCode: Scalars['String']['output'];
  salesAgency: LpSalesAgency;
  supportedSettlementMethods: Maybe<Array<Maybe<LpSettlementMethod>>>;
};

/** Lp Data Sales Agency */
export type LpSalesAgency = {
  __typename: 'LpSalesAgency';
  code: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** Lp Data settlement method */
export type LpSettlementMethod = {
  __typename: 'LpSettlementMethod';
  typeCode: Scalars['String']['output'];
  typeName: Scalars['String']['output'];
};

export type Magazine = {
  __typename: 'Magazine';
  code: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type MagazineBlock = {
  __typename: 'MagazineBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  magazineGenreCode: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  results: Scalars['Int']['output'];
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

export type MagazinesResponse = {
  __typename: 'MagazinesResponse';
  books: Array<BookSakuhin>;
  name: Scalars['String']['output'];
  pageInfo: PortalPageInfo;
};

export enum MainGenreCodeType {
  ADRAMA = 'ADRAMA',
  ANIME = 'ANIME',
  DOCUMENT = 'DOCUMENT',
  DRAMA = 'DRAMA',
  FDRAMA = 'FDRAMA',
  GOLF = 'GOLF',
  HOUGA = 'HOUGA',
  KIDS = 'KIDS',
  MARTIAL = 'MARTIAL',
  MUSIC_IDOL = 'MUSIC_IDOL',
  NEWS = 'NEWS',
  OTH_SPORTS = 'OTH_SPORTS',
  SEMIADULT = 'SEMIADULT',
  SOCCER = 'SOCCER',
  SPORT = 'SPORT',
  STAGE_PLAY = 'STAGE_PLAY',
  VARIETY = 'VARIETY',
  YOUGA = 'YOUGA',
}

/** Maximum number of family account has reached */
export type MaxAccountNumberReachedError = UserFacingError & {
  __typename: 'MaxAccountNumberReachedError';
  /** The error message. */
  message: Scalars['String']['output'];
};

export type Media = {
  __typename: 'Media';
  id: Scalars['ID']['output'];
  metadata: MediaMeta;
  paginatedRecommendations: PaginatedMediaLight;
  playbackRight: Maybe<PlaybackRight>;
  productInfo: Maybe<ContractProductSummary>;
};

export type MediapaginatedRecommendationsArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type MediaproductInfoArgs = {
  deviceId: Scalars['String']['input'];
};

export type MediaBlock = {
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type MediaChapter = {
  __typename: 'MediaChapter';
  id: Scalars['ID']['output'];
  /** The parent package product info. */
  mediaProductInfo: Maybe<MediaLightProductSummary>;
  metadata: MediaChapterMeta;
  paginatedRecommendations: PaginatedMediaChapterLight;
  /**
   * The chapter product info may be quite different from it's parent package product info.
   * For Beemi, chapter use only platformLifetimeClass field in MediaChapterLightProductSummary.
   */
  productInfo: Maybe<MediaChapterLightProductSummary>;
};

export type MediaChapterpaginatedRecommendationsArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type MediaChapterLight = {
  __typename: 'MediaChapterLight';
  id: Scalars['ID']['output'];
  /** The parent package product info. */
  mediaProductInfo: Maybe<MediaLightProductSummary>;
  metadata: MediaChapterLightMeta;
  /**
   * The chapter product info may be quite different from it's parent package product info.
   * For Beemi, chapter use only platformLifetimeClass field in MediaChapterLightProductSummary.
   */
  productInfo: Maybe<MediaChapterLightProductSummary>;
};

export type MediaChapterLightMeta = {
  __typename: 'MediaChapterLightMeta';
  chapterNo: Scalars['Int']['output'];
  contentTags: Array<ContentTag>;
  durationSeconds: Scalars['Float']['output'];
  endTimeSeconds: Scalars['Float']['output'];
  favoriteCount: Scalars['Int']['output'];
  featureBadges: Array<MediaFeatureBadge>;
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
  startTimeSeconds: Scalars['Float']['output'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for MediaChapter.
   */
  thumbnails: Array<ThumbnailSet>;
};

/**
 * The chapter product info may be quite different from it's parent package product info.
 * For Beemi, MediaChapterLightProductSummary has only platformLifetimeClass field.
 */
export type MediaChapterLightProductSummary = {
  __typename: 'MediaChapterLightProductSummary';
  id: Scalars['ID']['output'];
  minPrice: Maybe<Scalars['Int']['output']>;
  platformLifetimeClass: TitleLifetimeClass;
};

export type MediaChapterMeta = {
  __typename: 'MediaChapterMeta';
  brandGroups: Array<BrandGroupLight>;
  chapterNo: Scalars['Int']['output'];
  contentTags: Array<ContentTag>;
  credits: Array<MediaCredit>;
  durationSeconds: Scalars['Float']['output'];
  endTimeSeconds: Scalars['Float']['output'];
  favoriteCount: Scalars['Int']['output'];
  featureBadges: Array<MediaFeatureBadge>;
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
  nameKana: Scalars['String']['output'];
  paginatedAllChapters: PaginatedMediaChapterLight;
  parent: MediaLight;
  performances: Array<MediaPerformance>;
  ratingCode: RatingCode;
  startTimeSeconds: Scalars['Float']['output'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for MediaChapter.
   */
  thumbnails: Array<ThumbnailSet>;
  /**
   * The same videoConsumables as the ones media package has.
   * please use consumable id with chapter startTimeSeconds/endTimeSeconds
   * to play a video chapter.
   */
  videoConsumables: Array<VideoConsumable>;
};

export type MediaChapterMetapaginatedAllChaptersArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type MediaChaptersSearchHits = SearchHits & {
  __typename: 'MediaChaptersSearchHits';
  hasMore: Scalars['Boolean']['output'];
  hits: Array<MediaChapterLight>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MediaCredit = {
  __typename: 'MediaCredit';
  alias: AliasLight;
  creditType: Maybe<Scalars['String']['output']>;
};

export enum MediaFeatureBadge {
  ATMOS = 'ATMOS',
  DV = 'DV',
  FHD = 'FHD',
  HDR = 'HDR',
  HDR10PLUS = 'HDR10PLUS',
  SAMPLE = 'SAMPLE',
  SD = 'SD',
  TWO_D = 'TWO_D',
  UHD4K = 'UHD4K',
  UHD8K = 'UHD8K',
  UNSPECIFIED = 'UNSPECIFIED',
  VR = 'VR',
}

export enum MediaFeatureBadgeInput {
  ATMOS = 'ATMOS',
  DV = 'DV',
  FHD = 'FHD',
  HDR = 'HDR',
  HDR10PLUS = 'HDR10PLUS',
  SAMPLE = 'SAMPLE',
  SD = 'SD',
  TWO_D = 'TWO_D',
  UHD4K = 'UHD4K',
  UHD8K = 'UHD8K',
  UNSPECIFIED = 'UNSPECIFIED',
  VR = 'VR',
}

export type MediaLight = {
  __typename: 'MediaLight';
  id: Scalars['ID']['output'];
  metadata: MediaLightMeta;
  productInfo: Maybe<MediaLightProductSummary>;
};

export type MediaLightMeta = {
  __typename: 'MediaLightMeta';
  contentTags: Array<ContentTag>;
  favoriteCount: Scalars['Int']['output'];
  featureBadges: Array<MediaFeatureBadge>;
  id: Scalars['ID']['output'];
  mainDurationSeconds: Maybe<Scalars['Float']['output']>;
  nameJa: Scalars['String']['output'];
  originalReleaseDate: Maybe<Scalars['DateTime']['output']>;
  promoLine: Maybe<Scalars['String']['output']>;
  releaseLifetimeClass: TitleLifetimeClass;
  synopsis: Maybe<Scalars['String']['output']>;
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Media.
   */
  thumbnails: Array<ThumbnailSet>;
};

export type MediaLightProductSummary = {
  __typename: 'MediaLightProductSummary';
  hasMultiplePrices: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  minPrice: Maybe<Scalars['Int']['output']>;
  paymentBadges: Maybe<Array<CapyPaymentBadgeCode>>;
  platformLifetimeClass: TitleLifetimeClass;
  publicEndDateTime: Scalars['DateTime']['output'];
  publicStartDateTime: Scalars['DateTime']['output'];
  saleBadges: Maybe<Array<CapySaleBadgeCode>>;
};

export type MediaMeta = {
  __typename: 'MediaMeta';
  /** The product number used to catalog JAV, e.g. SCUTE-378. Obviously only populated for adult titles */
  adultProductCode: Maybe<Scalars['String']['output']>;
  brandGroups: Array<BrandGroupLight>;
  collections: Array<CollectionLight>;
  commentarySynopsis: Maybe<Scalars['String']['output']>;
  contentTags: Array<ContentTag>;
  copyright: Maybe<Scalars['String']['output']>;
  credits: Array<MediaCredit>;
  favoriteCount: Scalars['Int']['output'];
  featureBadges: Array<MediaFeatureBadge>;
  highlights: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logLine: Maybe<Scalars['String']['output']>;
  nameJa: Scalars['String']['output'];
  nameKana: Scalars['String']['output'];
  originalPrice: Scalars['Int']['output'];
  originalReleaseDate: Maybe<Scalars['DateTime']['output']>;
  paginatedBonusMaterials: PaginatedBonusMaterials;
  paginatedChapters: PaginatedMediaChapterLight;
  paginatedTrailers: PaginatedTrailers;
  performances: Array<MediaPerformance>;
  productionCompany: Maybe<ProductionCompanyLight>;
  promoLine: Maybe<Scalars['String']['output']>;
  ratingCode: RatingCode;
  releaseLifetimeClass: TitleLifetimeClass;
  /** Gets an array of ThumbnailSet, each ThumbnailSet in results is a screenshot. */
  screenshots: Array<ThumbnailSet>;
  studio: Maybe<StudioLight>;
  synonyms: Maybe<Array<Scalars['String']['output']>>;
  synopsis: Maybe<Scalars['String']['output']>;
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Media.
   */
  thumbnails: Array<ThumbnailSet>;
  /**
   * The following kinds of video consumable types would be provided for a media:
   * MAIN: main video
   * SAMPLE: sample video
   * DUBS: dubbed main video
   * SUBS: subbed main video
   *
   * Please get the video length from the MAIN type video consumable.
   *
   * To get available formats for playing video(codec, resolution, etc),
   * please check videoProfiles under a VideoConsumable.
   */
  videoConsumables: Array<VideoConsumable>;
};

export type MediaMetapaginatedBonusMaterialsArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type MediaMetapaginatedChaptersArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type MediaMetapaginatedTrailersArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type MediaPerformance = {
  __typename: 'MediaPerformance';
  alias: AliasLight;
  characterName: Maybe<Scalars['String']['output']>;
};

export type MediaSearchHits = SearchHits & {
  __typename: 'MediaSearchHits';
  hasMore: Scalars['Boolean']['output'];
  hits: Array<MediaLight>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Block containing a singular featured title */
export type MediaSpotlightBlock = MediaBlock & {
  __typename: 'MediaSpotlightBlock';
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  spotlight: MediaLight;
};

export type MigrationStatus = {
  __typename: 'MigrationStatus';
  canMigrate: Maybe<Scalars['Boolean']['output']>;
  /** Reason code defining why a user cannot be migrated */
  reasonCode: Maybe<Scalars['String']['output']>;
  /** Detailed message defining why a user cannot be migrated */
  reasonMessage: Maybe<Scalars['String']['output']>;
};

/** Error when the validated phone number does not match the current user phone number */
export type MismatchedPhoneNumber = UserFacingError & {
  __typename: 'MismatchedPhoneNumber';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Error indicating the user does not have an email address or a phone number registered */
export type MissingSecondFactor = UserFacingError & {
  __typename: 'MissingSecondFactor';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Mnp Information for a SimUserApplication */
export type MnpInfo = {
  __typename: 'MnpInfo';
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  mnpNumber: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
};

/** Input for mnp */
export type MnpInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  mnpNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Status of an issued Mnp */
export type MnpNumber = {
  __typename: 'MnpNumber';
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  issueDate: Maybe<Scalars['DateTime']['output']>;
  number: Maybe<Scalars['String']['output']>;
  simUserId: Scalars['ID']['output'];
  statusCode: Maybe<MnpNumberStatusCode>;
  statusName: Maybe<Scalars['String']['output']>;
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Statuses for an issued MNP number */
export enum MnpNumberStatusCode {
  /** MNP転出予約番号発行済 */
  ACTIVE = 'ACTIVE',
  /** MNP転出予約番号発行依頼中 */
  APPLYING = 'APPLYING',
  /** MNP転出予約番号キャンセル済 */
  CANCELED = 'CANCELED',
  /** MNP転出予約番号キャンセル中 */
  CANCELING = 'CANCELING',
  /** MNP転出予約番号キャンセル失敗 */
  CANCEL_FAILURE = 'CANCEL_FAILURE',
  /** MNP転出予約番号期限切れ */
  EXPIRED = 'EXPIRED',
  /** MNP転出予約番号発行失敗 */
  ISSUE_FAILURE = 'ISSUE_FAILURE',
  /** MNP転出済 */
  USED = 'USED',
}

/** Response for mnp */
export type MnpResponse = {
  __typename: 'MnpResponse';
  expiryDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  issuedDate: Scalars['DateTime']['output'];
  number: Scalars['String']['output'];
  simUserId: Scalars['Int']['output'];
  statusCode: Scalars['String']['output'];
  statusName: Scalars['String']['output'];
  versionNo: Scalars['Int']['output'];
};

/** The status of mobile account */
export enum MobileAccountStatus {
  ACTIVE = 'ACTIVE',
  FORCED_WITHDRAWN = 'FORCED_WITHDRAWN',
  SUSPENDED = 'SUSPENDED',
  WITHDRAWN = 'WITHDRAWN',
}

export type MobilePaymentInfo = {
  __typename: 'MobilePaymentInfo';
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
};

/**
 * The initial Mobile Service Agreement signed with the customer. This may not match the currently provided service if the customer has added sim cards, changed plan, etc.
 * Do not confuse this with the Account type
 */
export type MobileServiceContract = {
  __typename: 'MobileServiceContract';
  administrativeFees: Maybe<Array<Maybe<ContractCharge>>>;
  /** Date that the contract was applied for */
  applicationDateTime: Scalars['DateTime']['output'];
  appliedCampaigns: Maybe<Array<Maybe<ContractCampaign>>>;
  contractDocumentUri: Scalars['String']['output'];
  contractPrintRequestDate: Maybe<Scalars['DateTime']['output']>;
  /** Full name of the contract holder */
  contractorName: Scalars['String']['output'];
  /** Full name of the contract holder (kana) */
  contractorNameKana: Scalars['String']['output'];
  /** Internal ID for mobile system */
  customerContractDocumentId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the contract */
  name: Scalars['String']['output'];
  options: Maybe<Array<Maybe<ContractCharge>>>;
  plan: Maybe<ContractPlan>;
  /** Internal ID for mobile system */
  planContractDocumentId: Maybe<Scalars['Int']['output']>;
  routeCode: Scalars['String']['output'];
  routeName: Scalars['String']['output'];
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  simUsers: Maybe<Array<Maybe<ContractSimUser>>>;
  typeCode: Scalars['String']['output'];
  typeName: Scalars['String']['output'];
  versionNo: Scalars['Int']['output'];
};

/** Base entity for a mobile service contract */
export type MobileServiceContractBase = {
  __typename: 'MobileServiceContractBase';
  /** Date that the contract was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Internal ID for mobile system */
  customerContractDocumentId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** Name of the contract */
  name: Maybe<Scalars['String']['output']>;
  /** Internal ID for mobile system */
  planContractDocumentId: Maybe<Scalars['Int']['output']>;
};

/** Campaign to receive free service for a month. */
export type MonthNoChargeCampaignInfo = {
  __typename: 'MonthNoChargeCampaignInfo';
  /** List of details of campaignCont (?). */
  campaignContDetailInfoList: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Description of the rewards of the campaign. */
  campaignReward: Maybe<Scalars['String']['output']>;
  /** Campaign type. */
  campaignType: Maybe<Scalars['String']['output']>;
  /** Id of the details. */
  contDetailId: Maybe<Scalars['String']['output']>;
  /** Plan target of the campaign. */
  planId: Maybe<Scalars['String']['output']>;
  /** Service target of the campaign. */
  serviceId: Maybe<Scalars['String']['output']>;
};

export type MoviePartsPosition = {
  __typename: 'MoviePartsPosition';
  endSeconds: Scalars['Int']['output'];
  /** @deprecated Use fromSeconds */
  from: Scalars['String']['output'];
  fromSeconds: Scalars['Int']['output'];
  hasRemainingPart: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use endSeconds */
  to: Scalars['String']['output'];
  type: MoviePartsPositionType;
};

export enum MoviePartsPositionType {
  ENDING = 'ENDING',
  OPENING = 'OPENING',
}

export type MovieProfile = {
  __typename: 'MovieProfile';
  typeCode: Scalars['String']['output'];
};

export type MultiAccountUserInfo = {
  __typename: 'MultiAccountUserInfo';
  accountTypeCode: AccountTypeCode;
  id: Scalars['ID']['output'];
  loginId: Scalars['String']['output'];
  mailRegistrationFlg: Scalars['Boolean']['output'];
  multiAccountId: Scalars['String']['output'];
  securityToken: Maybe<Scalars['String']['output']>;
  userEmail: Scalars['String']['output'];
  userPurchaseAuth: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  activateMnp: Maybe<ActivateMnpResponse>;
  /** Adds an Amazon AppStore IAP subscription purchased as part of the upsell workflow */
  addAmazonIapSubscription: AddAmazonIapSubscriptionResponse;
  /** Adds an Apple AppStore IAP subscription to the current user as part of the upsell workflow */
  addAppleIapSubscription: AddAppleIapSubscriptionResponse;
  /** Adds a Google Play IAP subscription purchased as part of the upsell workflow */
  addGoogleIapSubscription: AddGoogleIapSubscriptionResponse;
  /**
   * Adds a new option to the mobile service account
   * @deprecated Use addYuMobileOption instead
   */
  addOption: Maybe<Account>;
  /** Adds the specified sim option to the specified sim user */
  addSimOption: Maybe<Account>;
  /** Adds the subscription to the current user for the given landing page code as part of the upsell workflow */
  addSubscription: AddSubscriptionResponse;
  /** Adds a new voice plan to a sim user to a mobile service account. */
  addVoicePlan: Maybe<Account>;
  /** Adds a new option to the mobile service account */
  addYuMobileOption: Maybe<YuMobileAccount>;
  /** Applies the giftcode for the current user */
  applyGiftCode: ApplyGiftCodeResponse;
  /**
   * Cancels a bunch of running services at the same time. Similar to the above, take care when cancelling a running service that is not of runningTypeCode: [VIDEO].
   * Cancellation Reason is fixed to CancellationReason.User
   */
  bulkCancelRunningServices: Maybe<BulkCancelRunningServicesResponse>;
  bulkRemoveBookTitleFavorite: Array<Scalars['ID']['output']>;
  /** Cancel subscriptions and pause content packs renewals in one mutation */
  cancelAndPauseContractedProducts: CancelActiveSubscriptionsResponse;
  /** Cancels a device purchase with the given purchasable config id */
  cancelDevicePurchase: Maybe<DevicePurchaseResponse>;
  /**
   * Cancels a plan tied to a mobile account
   * @deprecated Use cancelYuMobilePlan instead
   */
  cancelPlan: Maybe<CancelPlanResponse>;
  /** Cancels a running service. When using this mutation to cancel a running service that is not of runningTypeCode: [VIDEO] make sure there are no dangling dependencies on this running service */
  cancelRunningService: Maybe<CancelRunningServiceResponse>;
  /** Cancels an in progress sim user application */
  cancelSimUserApplication: Maybe<CancelSimUserApplicationResponse>;
  /** Cancels a previous submitted Temp Application (Requires Yamada-Store Authorization) */
  cancelTempApplication: Maybe<TempApplication>;
  /** Cancels a scheduled change to a voice plan that has not yet been applied */
  cancelVoicePlanUpdate: Maybe<Account>;
  /** Cancels a plan tied to a mobile account */
  cancelYuMobilePlan: Maybe<CancelYuMobilePlanResponse>;
  /** Purchases the data charge product with the provided ID */
  chargeData: Maybe<DataChargeResponse>;
  /** Configures the auto data charge settings for the account */
  configureAutoDataCharge: Maybe<Account>;
  /** Consents a sim user application. This is usually called by the sim user who does not necessarily have a U-NEXT service account nor a mobile service account. */
  consentSimUserApplication: Maybe<ConsentSimUserApplicationResponse>;
  /** This is a query to be used when using food coupons */
  consumeFoodCinemaCoupon: ConsumeFoodCinemaCouponResponse;
  createCampaign: Maybe<Campaign>;
  createEmergencyNotification: Maybe<EmergencyNotification>;
  createPartner: Maybe<Partner>;
  /** Creates a SBPS v1 subscription purchase request */
  createSbpsV1SubscriptionPurchaseRequest: SbpsFormRequest;
  /**
   * Create SBPS register billing method request,
   * Client will send request based on these data
   */
  createSbpsV2BillingMethodRegistrationRequest: CreateRegisterSbpsV2BillingMethodRequest;
  /** Creates a SBPS v2 subscription purchase request */
  createSbpsV2SubscriptionPurchaseRequest: SbpsFormRequest;
  /** Create Proof of Work Captcha Session for ID verification */
  createSmsProofOfWorkSessionForTwoFactorVerification: ProofOfWorkSessionResponse;
  /** Create Proof of Work Captcha Session for phone number validation */
  createSmsProofOfWorkSessionForUpdatePhoneNumber: ProofOfWorkSessionForUpdatePhoneNumberResponse;
  /** @deprecated Corporate Code and customer code have been moved */
  createStore: Maybe<Store>;
  createStoreGroup: Maybe<StoreGroup>;
  /** Create a new UnextUser on a UnextAccount designated based on platform and user ID. */
  createUnextUser: CreateUnextUserResponse;
  /**
   * Create a new UnextUser on a UnextAccount designated based on platform and user ID
   * statelessly using a token created with the getMultiaccountToken query. This is how
   * users can be created through LINE invites
   */
  createUnextUserFromToken: CreateUnextUserResponse;
  /** Creates a new Unext Account, a new Unext User, and adds the basic unext subscription to their account */
  createUnextVideoAccountAndUnextUser: CreateUnextAccountResponse;
  /** Create UNEXT-ID user on Common Infrastructure and BOS systems. First step of the new user registration process. */
  createUser: Maybe<CreateUserResponse>;
  /**
   * Create a new UNEXT user and their video service account. This is bundled to simplify legacy issues when switching from NSBO to BOS.
   * Eventually, this will be split into Create user and Create video account like it is for mobile service accounts
   * @deprecated Use createUnextVideoAccountAndUnextUser from account-graph instead
   */
  createUserAndVideoAccount: Maybe<CreateUserResponse>;
  deleteCampaign: Maybe<Scalars['Boolean']['output']>;
  deleteCookie: Maybe<Scalars['ID']['output']>;
  deleteEmergencyNotification: Maybe<Scalars['Boolean']['output']>;
  deletePartner: Maybe<Scalars['Boolean']['output']>;
  /** Delete purchased media of the given ids */
  deletePurchasedMedia: Scalars['Boolean']['output'];
  deleteStore: Maybe<Scalars['Boolean']['output']>;
  deleteStoreGroup: Maybe<Scalars['Boolean']['output']>;
  /**
   * Delete unext user
   * Note: MAIN user only can delete unext user
   */
  deleteUnextUser: DeleteUnextUserResponse;
  /** the ticket holder passes the ticket to another user via e-mail. */
  giftCoupon: GiftCodeReceipient;
  /**
   * Attempt to grant the entitlements for a subscription that requires a startup procedure.
   * If the entitlements cannot be granted, this mutation will return the next step the user should take.
   * This operation is idempotent, so call this if there is doubt the user has received their entitlements.
   */
  grantSubscriptionEntitlements: GrantSubscriptionEntitlementsResponse;
  /**
   * Make the required initial point deposit for a subscription and receive the entitlements for the subscription
   * Similar to a purchase, it will prioritize taking points and then coins if your purchasing agent is APPLE
   */
  grantSubscriptionEntitlementsWithDeposit: GrantSubscriptionEntitlementsWithDepositResponse;
  /** Issues an invoice for the given billing platform and unique key. */
  issueInvoice: Maybe<IssuedInvoice>;
  /** Issues an MNP number for the indicated sim user to leave YU-mobile */
  issueMnpNumber: Maybe<MnpNumber>;
  /** Link a Yamada user account with a U-NEXT user */
  linkYamadaAccountWithUser: Maybe<YamadaAccountInfo>;
  /** Validation error will be in error.extensions.loginDelegationError object with field 'code' and 'message' */
  loginDelegationAuthorizeSession: LoginDelegationAuthorizeSessionResponse;
  /** Creation error will be in error.extensions.loginDelegationError object with field 'code' and 'message' */
  loginDelegationCreateSession: LoginDelegationCreateSessionResponse;
  /** Validation error will be in error.extensions.loginDelegationError object with field 'code' and 'message' */
  loginDelegationRevokeSession: LoginDelegationRevokeSessionResponse;
  /** Starts a 3DSecure transaction for a point charge product purchase */
  pointChargeVia3DSecure: PointChargeVia3DSecureResponse;
  purchaseAdultRights: PurchaseAdultRightsResponse;
  /** Allows the purchase of an AV Video product */
  purchaseAdultVideoProduct: PurchaseAdultVideoProductResponse;
  /** Allows the purchase of a Book product */
  purchaseBookProduct: PurchaseBookProductResponse;
  purchaseBookWithTicket: BookPurchaseWithTicketResponseModel;
  /** Allows the purchase of a Content Pack */
  purchaseContentPack: PurchaseContentPackResponse;
  /** Makes a purchase for a device of the given PurchaseableConfiguration Id for the user. */
  purchaseDevice: Maybe<DevicePurchaseResponse>;
  /** Allows the purchase of a Live Ticket */
  purchaseLiveTicket: PurchaseLiveTicketResponse;
  /** mutation support purchasing video/ live and point product */
  purchaseUNextProduct: UNextProductPurchaseResponse;
  /** Allows the purchase of a general Video product */
  purchaseVideoProduct: PurchaseVideoProductResponse;
  /**
   * Update the current billing method for this account to escott. Should be used in conjunction with the allowed billing methods to determine
   * which billing methods are actually allowed beforehand.
   * Note: one account can only have one billing method at a time. Providing multiple inputs will result in rejection.
   */
  registerEscottBillingMethod: RegisterEscottBillingMethodResponse;
  removeAllBookHistory: BookHistoryRemovalResponse;
  removeAllVideoHistory: VideoHistoryRemovalResponse;
  removeBookHistory: BookHistoryRemovalResponse;
  removeBookPurchase: BookPurchaseRemoveResponse;
  /**
   * Removes an option from the mobile service account
   * @deprecated Use removeYuMobileOption instead
   */
  removeOption: Maybe<Account>;
  /** Removes the specified sim option instance indicated by sim option id from the sim user */
  removeSimOption: Maybe<Account>;
  removeVideoHistory: VideoHistoryRemovalResponse;
  removeVideoHistoryTitles: VideoHistoryRemovalResponse;
  /** Removes an option from the mobile service account */
  removeYuMobileOption: Maybe<YuMobileAccount>;
  /** Resets the password, and optionally the login ID for a user who has forgotten their password. */
  resetLoginInfoForForgottenPassword: ResetLoginInfoForForgottenPasswordResponse;
  /**
   * Resets the security code of the MAIN user. Can be used when the user does not remember their current security code.
   * Requires user to be verified via email or sms validation first.
   * Note: Only available to the MAIN user
   */
  resetSecurityCode: ResetSecurityCodeResponse;
  /** Restarts the service for a stopped user. */
  resumeSimUser: Maybe<SimStateChangeResponse>;
  /** Resumes a previous paused Temp Application (Requires Yamada-Store Authorization) */
  resumeTempApplication: Maybe<TempApplication>;
  /** Revoke session */
  revokeSession: RevokeSessionResponse;
  /** Send customer inquiry */
  sendCustomerInquiry: Maybe<CustomerInquiryResponse>;
  /** Send customer inquiry v2 */
  sendCustomerInquiryV2: Maybe<CustomerInquiryResponse>;
  /** Sends a confirmation code to the provided email address for a user who has forgotten their password. */
  sendEmailCodeForForgottenPassword: SendEmailCodeForForgottenPasswordResponse;
  /**
   * Sends an email verification code to the current logged in unext user.
   * This defers from "sendEmailCodeForUpdateEmailAddress" provided for mobile applications because this step takes place
   * AFTER user creation for video and before for mobile.
   * This is intended to be used together with operations that require email validation prior to executing an operation
   */
  sendEmailCodeForTwoFactorVerification: Maybe<SendEmailVerificationCodeResult>;
  /**
   * Sends an email verification code to a new email provided by a logged in user.
   * Use case: the existing user wants to change email address.
   * The current email address of the user has to be already verified before calling this to avoid malicious usage.
   */
  sendEmailCodeForUpdateEmailAddress: Maybe<SendEmailVerificationCodeForUpdateResponse>;
  /** Sends a confirmation code to the provided email to update the partner accounts login info. */
  sendEmailForPartnerLoginInfoUpdate: SendEmailForPartnerLoginInfoUpdateResponse;
  /**
   * Send email address verification email
   * (DEPRECATED)
   * @deprecated No longer supported
   */
  sendEmailVerification: Maybe<SendEmailVerificationResponse>;
  sendEmailVerificationCode: Maybe<SendEmailVerificationCodeResponse>;
  sendInboxEvent: SendInboxEventResponse;
  /**
   * Sends a validation code to the phone number indicated after PoW session has been validated. Meant to be called after
   * successfully getting a response from createSmsProofOfWorkSessionForTwoFactorVerification.
   */
  sendSmsCodeForTwoFactorVerification: SendSmsValidationCodeResponse;
  /**
   * Sends a validation code to the phone number indicated after PoW session has been validated. Meant to be called after
   * successfully getting a response from createSmsProofOfWorkSessionForUpdatePhoneNumber.
   */
  sendSmsCodeForUpdatePhoneNumber: SendSmsValidationCodeResponse;
  /** Stops the sim user service temporarily. Does not remove the sim user from the account. */
  stopSimUser: Maybe<SimStateChangeResponse>;
  /** Submit the application */
  submitApplication: Maybe<ApplicationResponse>;
  /** Submits an application and sim user application for a new sim user and starts the application flow to add a new sim user */
  submitApplicationForNewSimUser: Maybe<ApplicationResponse>;
  /** Submit the document uris to the waikiki system from personal identification */
  submitApplicationIdDocuments: Maybe<SimUserApplication>;
  /** Submits an application to the temp application system for judging as the first step of the OTA application process. (Requires Yamada-Store Authorization) */
  submitTempApplication: Maybe<TempApplicationResponse>;
  /** In case of portal-error, errorCode can be found under gql-error with message 'SWITCH_ACCOUNT_ERROR' */
  switchAccount: MultiAccountUserInfo;
  /**
   * Switches to a different user. User must belong to the same account as the currently logged in user.
   * Will replace the at/rt cookies with cookies for the indicated user on the *.unext.jp domain.
   * Clients should redirect their users to video.unext.jp if this returns a success.
   *
   * You can only switch from MAIN to SUB or SUB to MAIN user. Security Code is required when switching from SUB to MAIN.
   */
  switchUnextUser: SwitchUnextUserResponse;
  /** Registers the download for the license control logic. */
  syncDownloadLicense: SyncLicenseResult;
  /** Terminates currently logged in account and logs out the current user */
  terminateAccount: TerminateUnextAccountResponse;
  /** Removes the sim user from the account */
  terminateSimUser: Maybe<SimStateChangeResponse>;
  /**
   * Terminates the account of the currently logged in U-NEXT user and logs them out. Use the canUserBeTerminated query to check if this mutation can be executed successfully
   * @deprecated Use terminateAccount from account-graph instead
   */
  terminateUser: Maybe<TerminateUserResponse>;
  /**
   * Update non-protected account customer info. Update account holder first name, last name, address, etc.
   * Note: MAIN user only.
   */
  updateAccountCustomerInfo: UpdateAccountCustomerInfoResponse;
  updateAppVersion: Maybe<AppVersion>;
  /**
   * In case of portal-error, errorCode can be found under gql-error with message 'UPDATE_BIRTHDAY_ERROR'.
   *
   * The month parameter should be an integer between 1 - 12, and day should be between 1 - 31.
   */
  updateBirthday: BirthdayUpdateResponse;
  updateBookTitleEvaluation: BookSakuhin;
  updateBookTitleFavorite: BookSakuhin;
  updateCampaign: Maybe<Campaign>;
  /** Update Credit Card */
  updateCreditCard: Maybe<User>;
  /**
   * Updates the user email for an existing user.
   * Invoke sendEmailCodeForTwoFactorVerification to get the hash, token, and verification code prior to invoking this
   */
  updateEmailAddress: UpdateEmailResponse;
  /** Updates the login info (loginId and password) for an existing user. */
  updateLoginInfo: UpdateLoginInfoResponse;
  updatePartner: Maybe<Partner>;
  /**
   * Updates the partner account's login info provided in the sendEmailForPartnerLoginInfoUpdate mutation, after the
   * validation input is verified.
   */
  updatePartnerLoginInfo: UpdatePartnerLoginInfoResponse;
  /** Update phone number with validation code */
  updatePhoneNumber: UpdatePhoneNumberResponse;
  /**
   * Switches the plan tied to a mobile account
   * @deprecated Use updateYuMobilePlan instead
   */
  updatePlan: Maybe<Account>;
  /**
   * Updates the security code of the MAIN user.
   * Note: Only available to the MAIN user
   */
  updateSecurityCode: UpdateSecurityCodeResponse;
  /** @deprecated Corporate Code and customer code have been moved */
  updateStore: Maybe<Store>;
  updateStoreGroup: Maybe<StoreGroup>;
  /** Updates an temp application and resubmits it for judging as the first step of the OTA process after a failure. (Requires Yamada-Store Authorization) */
  updateTempApplication: Maybe<TempApplication>;
  /** @deprecated Renamed to webfront_updateTitleEvaluation */
  updateTitleEvaluation: Maybe<Title>;
  /** @deprecated Renamed to webfront_updateTitleFavorite */
  updateTitleFavorite: Maybe<Title>;
  /**
   * Updates the currency authorization of the indicated user.
   * Note: Only available to the MAIN user. Indicated user must belong to the same account as the current logged in user
   */
  updateUnextUserCurrencyAuthorization: UnextUser;
  /** @deprecated Use updateAccountCustomerInfo from account-graph instead */
  updateUserInfo: Maybe<User>;
  updateVideoResumePoint: ResumePointUpdateResponse;
  /** Schedules a change to the voice plan tied to a sim user in a mobile service account. The new voice plan will come into effect the 1st of the following month */
  updateVoicePlan: Maybe<Account>;
  /** Update the account info for a linked Yamada user account */
  updateYamadaAccountInfo: Maybe<YamadaAccountInfo>;
  /** Switches the plan tied to a mobile account */
  updateYuMobilePlan: Maybe<YuMobileAccount>;
  /** Validates email verification code for the current logged in unext user */
  validateEmailCodeForTwoFactorVerification: Maybe<ValidateEmailCodeForTwoFactorVerificationResponse>;
  /** Validates sms verification code for the current logged in unext user */
  validateSmsCodeForTwoFactorVerification: ValidateSmsCodeForTwoFactorVerificationResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BULK_BOOK_PRODUCT_PURCHASE_ERROR' */
  webfront_bulkPurchaseBookProduct: BulkBookProductPurchaseResponse;
  webfront_cancelBookPreorder: Array<Scalars['ID']['output']>;
  webfront_checkUserSecurityCode: UserSecurityCodeCheckResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_PRODUCT_PURCHASE_ERROR' */
  webfront_purchaseBookProduct: BookProductPurchaseResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'VIDEO_PRODUCT_PURCHASE_ERROR' */
  webfront_purchaseVideoProduct: VideoProductPurchaseResponse;
  webfront_setBookPreorder: BookPreorderResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'UPDATE_BOOK_RESUME_POINT_ERROR' */
  webfront_updateBookResumePoint: BookResumePointUpdateResponse;
  /** @deprecated Changed to webfront_updateTitleStageEvaluation */
  webfront_updateTitleEvaluation: Maybe<Title>;
  /** @deprecated Changed to webfront_updateTitleStageFavorite */
  webfront_updateTitleFavorite: Maybe<Title>;
  webfront_updateTitleStageEvaluation: Maybe<TitleStage>;
  webfront_updateTitleStageFavorite: Maybe<TitleStage>;
  /** In case of portal-error, errorCode can be found under gql-error with message 'USER_SECURITY_CODE_ERROR' */
  webfront_updateUserSecurityCode: UserSecurityCodeUpdateResponse;
};

export type MutationactivateMnpArgs = {
  mnpNumber: Scalars['String']['input'];
  mnpPortInTelephoneNo: Scalars['String']['input'];
  simSerialNo: Scalars['String']['input'];
};

export type MutationaddAmazonIapSubscriptionArgs = {
  amazonAppUserId: Scalars['String']['input'];
  amazonDeviceType: AmazonDeviceTypeInput;
  amazonPackageName: Scalars['String']['input'];
  amazonSku: Scalars['ID']['input'];
  amazonTermSku?: InputMaybe<Scalars['ID']['input']>;
  appReceipt: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  deviceUuid: Scalars['String']['input'];
};

export type MutationaddAppleIapSubscriptionArgs = {
  appReceipt: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  deviceUuid: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

export type MutationaddGoogleIapSubscriptionArgs = {
  appReceipt: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  deviceUuid: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
  signature: Scalars['String']['input'];
};

export type MutationaddOptionArgs = {
  entryParams: WebEntryParams;
  option: OptionInput;
};

export type MutationaddSimOptionArgs = {
  entryLandingPageCode: Scalars['String']['input'];
  entryParams?: InputMaybe<WebEntryParams>;
  simOption: SimOptionInput;
  simUserId: Scalars['ID']['input'];
};

export type MutationaddSubscriptionArgs = {
  landingPageCode: Scalars['String']['input'];
};

export type MutationaddVoicePlanArgs = {
  landingPageDetails?: InputMaybe<LandingPageInput>;
  simUserId: Scalars['ID']['input'];
  voicePlan: VoicePlanInput;
};

export type MutationaddYuMobileOptionArgs = {
  entryParams: WebEntryParams;
  option: OptionInput;
};

export type MutationapplyGiftCodeArgs = {
  giftCode: Scalars['String']['input'];
};

export type MutationbulkCancelRunningServicesArgs = {
  userRunningServiceIds: Array<Scalars['ID']['input']>;
};

export type MutationbulkRemoveBookTitleFavoriteArgs = {
  bookSakuhinIds: Array<Scalars['ID']['input']>;
};

export type MutationcancelAndPauseContractedProductsArgs = {
  contentPackTypes?: Array<ContentPackTypeInput>;
  subscriptionIds?: Array<Scalars['ID']['input']>;
};

export type MutationcancelDevicePurchaseArgs = {
  devicePurchaseId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationcancelRunningServiceArgs = {
  cancellationReason?: InputMaybe<CancellationReason>;
  userRunningServiceId: Scalars['ID']['input'];
};

export type MutationcancelSimUserApplicationArgs = {
  applicationId: Scalars['ID']['input'];
  simUserApplicationId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationcancelTempApplicationArgs = {
  tempApplicationId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationcancelVoicePlanUpdateArgs = {
  currentVoicePlanId: Scalars['ID']['input'];
  simUserId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationchargeDataArgs = {
  dataChargeProduct: DataChargeProductInput;
};

export type MutationconfigureAutoDataChargeArgs = {
  dataChargeSetting: DataChargeSettingInput;
};

export type MutationconsentSimUserApplicationArgs = {
  token: Scalars['String']['input'];
};

export type MutationconsumeFoodCinemaCouponArgs = {
  input: ConsumeFoodCinemaCouponInput;
};

export type MutationcreateCampaignArgs = {
  campaignInput: CampaignInput;
};

export type MutationcreateEmergencyNotificationArgs = {
  emergencyNotificationInput: EmergencyNotificationInput;
};

export type MutationcreatePartnerArgs = {
  partnerInput: PartnerInput;
};

export type MutationcreateSbpsV1SubscriptionPurchaseRequestArgs = {
  input: CreateSbpsSubscriptionPurchaseRequestInput;
};

export type MutationcreateSbpsV2BillingMethodRegistrationRequestArgs = {
  cancelUrl: Scalars['String']['input'];
  errorUrl: Scalars['String']['input'];
  paymentMethod: SbpsV2PaymentMethodInput;
  successUrl: Scalars['String']['input'];
};

export type MutationcreateSbpsV2SubscriptionPurchaseRequestArgs = {
  input: CreateSbpsSubscriptionPurchaseRequestInput;
};

export type MutationcreateSmsProofOfWorkSessionForTwoFactorVerificationArgs = {
  clientIdentifier: Scalars['String']['input'];
};

export type MutationcreateSmsProofOfWorkSessionForUpdatePhoneNumberArgs = {
  clientIdentifier: Scalars['String']['input'];
  msisdn: Scalars['String']['input'];
};

export type MutationcreateStoreArgs = {
  storeInput: StoreInput;
};

export type MutationcreateStoreGroupArgs = {
  storeGroup: StoreGroupInput;
};

export type MutationcreateUnextUserArgs = {
  currencyAuthorization: UnextUserCurrencyAuthorizationInput;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type MutationcreateUnextUserFromTokenArgs = {
  currencyAuthorization: UnextUserCurrencyAuthorizationInput;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationcreateUnextVideoAccountAndUnextUserArgs = {
  application: VideoApplicationInput;
  emailAddress: Scalars['String']['input'];
  entryParams: WebEntryParams;
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  sendEmailMagazine: Scalars['Boolean']['input'];
};

export type MutationcreateUserArgs = {
  emailAddress: Scalars['String']['input'];
  entryParams: WebEntryParams;
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationcreateUserAndVideoAccountArgs = {
  application: VideoApplicationInput;
  emailAddress: Scalars['String']['input'];
  entryParams: WebEntryParams;
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  sendEmailMagazine: Scalars['Boolean']['input'];
};

export type MutationdeleteCampaignArgs = {
  code: Scalars['ID']['input'];
};

export type MutationdeleteEmergencyNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeletePartnerArgs = {
  code: Scalars['ID']['input'];
};

export type MutationdeletePurchasedMediaArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type MutationdeleteStoreArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteStoreGroupArgs = {
  id: Scalars['ID']['input'];
};

export type MutationdeleteUnextUserArgs = {
  multiAccountId: Scalars['String']['input'];
};

export type MutationgiftCouponArgs = {
  input: GiftCouponInput;
};

export type MutationgrantSubscriptionEntitlementsArgs = {
  subscriptionType: SubscriptionTypeInput;
};

export type MutationgrantSubscriptionEntitlementsWithDepositArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  subscriptionType: SubscriptionTypeInput;
};

export type MutationissueInvoiceArgs = {
  billingPlatformCode?: InputMaybe<BillingPlatformCodeInput>;
  billingUniqueKey?: InputMaybe<Scalars['String']['input']>;
};

export type MutationissueMnpNumberArgs = {
  simUserId: Scalars['ID']['input'];
};

export type MutationlinkYamadaAccountWithUserArgs = {
  sessionKey: Scalars['String']['input'];
  yamadaMemberId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationloginDelegationAuthorizeSessionArgs = {
  code: Scalars['String']['input'];
};

export type MutationloginDelegationCreateSessionArgs = {
  appVersion: Scalars['String']['input'];
  deviceName: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  deviceUuid: Scalars['String']['input'];
  location: Scalars['String']['input'];
};

export type MutationloginDelegationRevokeSessionArgs = {
  code: Scalars['String']['input'];
};

export type MutationpointChargeVia3DSecureArgs = {
  deviceType: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type MutationpurchaseAdultRightsArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type MutationpurchaseAdultVideoProductArgs = {
  deviceType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicyInput>;
};

export type MutationpurchaseBookProductArgs = {
  id: Scalars['ID']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicyInput>;
};

export type MutationpurchaseBookWithTicketArgs = {
  productCode: Scalars['ID']['input'];
};

export type MutationpurchaseContentPackArgs = {
  productId: Scalars['String']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicyInput>;
};

export type MutationpurchaseDeviceArgs = {
  customerInfo: DevicePurchaseCustomerInfoInput;
  purchasableConfigId: Scalars['ID']['input'];
};

export type MutationpurchaseLiveTicketArgs = {
  deviceType: Scalars['String']['input'];
  liveTicketId: Scalars['String']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicyInput>;
};

export type MutationpurchaseUNextProductArgs = {
  liveTicketCode?: InputMaybe<Scalars['ID']['input']>;
  productCode?: InputMaybe<Scalars['ID']['input']>;
  purchasingAgent?: InputMaybe<PurchasingAgent>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type MutationpurchaseVideoProductArgs = {
  deviceType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicyInput>;
};

export type MutationregisterEscottBillingMethodArgs = {
  paymentCardBillingInfo?: InputMaybe<PaymentCardBillingInfoInput>;
};

export type MutationremoveBookHistoryArgs = {
  bookSakuhinCodes: Array<Scalars['ID']['input']>;
};

export type MutationremoveBookPurchaseArgs = {
  bookSakuhinCodes: Array<Scalars['ID']['input']>;
};

export type MutationremoveOptionArgs = {
  option: OptionInput;
  optionId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationremoveSimOptionArgs = {
  runningSimOptionId: Scalars['ID']['input'];
  simOptionTypeEnum?: InputMaybe<SimOptionTypeEnum>;
  simUserId: Scalars['ID']['input'];
};

export type MutationremoveVideoHistoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationremoveVideoHistoryTitlesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationremoveYuMobileOptionArgs = {
  option: OptionInput;
  optionId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationresetLoginInfoForForgottenPasswordArgs = {
  newLoginId?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationresetSecurityCodeArgs = {
  newSecurityCode: Scalars['String']['input'];
};

export type MutationresumeSimUserArgs = {
  simUserId: Scalars['Int']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationresumeTempApplicationArgs = {
  tempApplicationId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationrevokeSessionArgs = {
  sessionId: Scalars['String']['input'];
};

export type MutationsendCustomerInquiryArgs = {
  customerInquiryInput: CustomerInquiryInput;
};

export type MutationsendCustomerInquiryV2Args = {
  customerInquiryInput: CustomerInquiryInputV2;
};

export type MutationsendEmailCodeForForgottenPasswordArgs = {
  emailAddress: Scalars['String']['input'];
};

export type MutationsendEmailCodeForUpdateEmailAddressArgs = {
  newEmailAddress: Scalars['String']['input'];
};

export type MutationsendEmailForPartnerLoginInfoUpdateArgs = {
  emailAddress: Scalars['String']['input'];
  loginId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type MutationsendEmailVerificationArgs = {
  baseUrl: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  queryParams?: InputMaybe<Array<InputMaybe<EmailRegistrationWebParamInput>>>;
};

export type MutationsendEmailVerificationCodeArgs = {
  emailAddress: Scalars['String']['input'];
};

export type MutationsendInboxEventArgs = {
  events: Array<InboxEventInput>;
};

export type MutationsendSmsCodeForTwoFactorVerificationArgs = {
  answer: Scalars['String']['input'];
  clientIdentifier: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type MutationsendSmsCodeForUpdatePhoneNumberArgs = {
  answer: Scalars['String']['input'];
  clientIdentifier: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type MutationstopSimUserArgs = {
  simUserId: Scalars['Int']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationsubmitApplicationArgs = {
  application: ApplicationInput;
};

export type MutationsubmitApplicationForNewSimUserArgs = {
  optionInput?: InputMaybe<OptionInput>;
  simUser: NewSimUserInput;
};

export type MutationsubmitApplicationIdDocumentsArgs = {
  documents?: InputMaybe<ApplicationIdDocumentsInput>;
};

export type MutationsubmitTempApplicationArgs = {
  tempApplication: TempApplicationInput;
};

export type MutationswitchAccountArgs = {
  functionFlag: Scalars['String']['input'];
  multiAccountId: Scalars['String']['input'];
  securityCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationswitchUnextUserArgs = {
  multiAccountId: Scalars['String']['input'];
  securityCode?: InputMaybe<Scalars['String']['input']>;
  setCookie?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationsyncDownloadLicenseArgs = {
  codes: Array<Scalars['String']['input']>;
};

export type MutationterminateSimUserArgs = {
  simUserId: Scalars['Int']['input'];
  versionNo: Scalars['Int']['input'];
};

export type MutationupdateAccountCustomerInfoArgs = {
  newAccountCustomerInfo: AccountCustomerInfoInput;
};

export type MutationupdateAppVersionArgs = {
  version: Scalars['String']['input'];
};

export type MutationupdateBirthdayArgs = {
  day: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type MutationupdateBookTitleEvaluationArgs = {
  bookSakuhinId: Scalars['ID']['input'];
  type: EvaluationTypes;
};

export type MutationupdateBookTitleFavoriteArgs = {
  bookSakuhinId: Scalars['ID']['input'];
  type: FavoriteTypes;
};

export type MutationupdateCampaignArgs = {
  campaignInput: CampaignUpdateInput;
};

export type MutationupdateCreditCardArgs = {
  cardholderName: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationupdateEmailAddressArgs = {
  emailVerification: EmailVerificationCodeValidationInput;
  newEmailAddress: Scalars['String']['input'];
};

export type MutationupdateLoginInfoArgs = {
  newLoginId?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
};

export type MutationupdatePartnerArgs = {
  partnerUpdateInput: PartnerUpdateInput;
};

export type MutationupdatePartnerLoginInfoArgs = {
  deviceType: Scalars['String']['input'];
  deviceUuid: Scalars['String']['input'];
  emailVerification: EmailVerificationCodeValidationInput;
};

export type MutationupdatePhoneNumberArgs = {
  smsVerification: SmsVerificationCodeValidationInput;
};

export type MutationupdatePlanArgs = {
  landingPageDetails?: InputMaybe<LandingPageInput>;
  plan: PlanInput;
  versionNo: Scalars['Int']['input'];
};

export type MutationupdateSecurityCodeArgs = {
  newSecurityCode: Scalars['String']['input'];
  oldSecurityCode: Scalars['String']['input'];
};

export type MutationupdateStoreArgs = {
  storeUpdateInput: StoreUpdateInput;
};

export type MutationupdateStoreGroupArgs = {
  storeGroup: StoreGroupUpdateInput;
};

export type MutationupdateTempApplicationArgs = {
  tempApplicationUpdateInput: TempApplicationUpdateInput;
};

export type MutationupdateTitleEvaluationArgs = {
  id: Scalars['ID']['input'];
  type: EvaluationTypes;
};

export type MutationupdateTitleFavoriteArgs = {
  id: Scalars['ID']['input'];
  type: FavoriteTypes;
};

export type MutationupdateUnextUserCurrencyAuthorizationArgs = {
  authorization: UnextUserCurrencyAuthorizationInput;
  userId: Scalars['ID']['input'];
};

export type MutationupdateUserInfoArgs = {
  customerInfo: CustomerInfoUpdateInput;
};

export type MutationupdateVideoResumePointArgs = {
  completeFlag: Scalars['Boolean']['input'];
  episodeCode: Scalars['ID']['input'];
  pauseTimestamp: Scalars['String']['input'];
  playMode: PlayMode;
  resumePoint: Scalars['Int']['input'];
};

export type MutationupdateVoicePlanArgs = {
  currentVoicePlanId: Scalars['ID']['input'];
  landingPageDetails?: InputMaybe<LandingPageInput>;
  simUserId: Scalars['ID']['input'];
  versionNo: Scalars['Int']['input'];
  voicePlan: VoicePlanInput;
};

export type MutationupdateYamadaAccountInfoArgs = {
  pointsAllocatedForPayment: Scalars['Int']['input'];
};

export type MutationupdateYuMobilePlanArgs = {
  landingPageDetails?: InputMaybe<LandingPageInput>;
  plan: PlanInput;
  versionNo: Scalars['Int']['input'];
};

export type MutationvalidateEmailCodeForTwoFactorVerificationArgs = {
  emailVerification: EmailVerificationCodeValidationInput;
};

export type MutationvalidateSmsCodeForTwoFactorVerificationArgs = {
  smsVerification: SmsVerificationCodeValidationInput;
};

export type Mutationwebfront_bulkPurchaseBookProductArgs = {
  productCodes: Array<Scalars['ID']['input']>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type Mutationwebfront_cancelBookPreorderArgs = {
  bookCodeList: Array<Scalars['ID']['input']>;
};

export type Mutationwebfront_checkUserSecurityCodeArgs = {
  securityCode: Scalars['String']['input'];
};

export type Mutationwebfront_purchaseBookProductArgs = {
  productCode: Scalars['ID']['input'];
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type Mutationwebfront_purchaseVideoProductArgs = {
  liveTicketCode?: InputMaybe<Scalars['ID']['input']>;
  productCode?: InputMaybe<Scalars['ID']['input']>;
  purchasingAgent?: InputMaybe<PurchasingAgent>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type Mutationwebfront_setBookPreorderArgs = {
  bookCode: Scalars['ID']['input'];
};

export type Mutationwebfront_updateBookResumePointArgs = {
  bookFileCode: Scalars['String']['input'];
  completeFlg?: InputMaybe<Scalars['Boolean']['input']>;
  maxResumePoint?: InputMaybe<Scalars['Float']['input']>;
  pauseTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
  resumePoint: Scalars['Float']['input'];
};

export type Mutationwebfront_updateTitleEvaluationArgs = {
  id: Scalars['ID']['input'];
  type: EvaluationTypes;
};

export type Mutationwebfront_updateTitleFavoriteArgs = {
  id: Scalars['ID']['input'];
  type: FavoriteTypes;
};

export type Mutationwebfront_updateTitleStageEvaluationArgs = {
  id: Scalars['ID']['input'];
  type: EvaluationTypes;
};

export type Mutationwebfront_updateTitleStageFavoriteArgs = {
  id: Scalars['ID']['input'];
  type: FavoriteTypes;
};

export type Mutationwebfront_updateUserSecurityCodeArgs = {
  newSecurityCode: Scalars['String']['input'];
  oldSecurityCode: Scalars['String']['input'];
};

/** Calendar event for sports that have some number of teams participating that is not covered by some other event. */
export type NTeamSportEvent = CalendarEvent & {
  __typename: 'NTeamSportEvent';
  /** ContentCode: contains information specific to live content or VOD content */
  contentCode: ContentCode;
  /** Calendar Event Start Date Time */
  date: Scalars['DateTime']['output'];
  /** Calendar Event Id */
  id: Scalars['ID']['output'];
  /** Image Url: usually for background image of the event */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Commentary language */
  language: Maybe<Scalars['String']['output']>;
  /** League */
  league: Maybe<Scalars['String']['output']>;
  /** Calendar Event Name */
  name: Scalars['String']['output'];
  /** The participants of this event */
  participants: Array<TeamParticipant>;
  /** Round */
  round: Maybe<Scalars['String']['output']>;
  /** Calendar Event State: that used for display the text in the UI */
  state: CalendarEventState;
  /** Descriptor tags for the event */
  tags: Maybe<Array<CalendarSearchTag>>;
};

/** Block containing the Top 100 newest titles as of the current server time for a planet or in the universe. */
export type NewMediaRankingBlock = MediaBlock & {
  __typename: 'NewMediaRankingBlock';
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  paginatedMedia: PaginatedMediaLight;
};

/**
 * Input type used for registering a new sim user to a mobile service account. Not to be confused with SimUserInput which is used when
 * create sim users together with an account.
 */
export type NewSimUserInput = {
  customerInfo: SimUserCustomerInfoInput;
  entryCampaignCode: Scalars['String']['input'];
  entryCode?: InputMaybe<Scalars['String']['input']>;
  entryLandingPageCode: Scalars['String']['input'];
  entryOperationCode?: InputMaybe<Scalars['String']['input']>;
  entrySalesAgencyCode: Scalars['String']['input'];
  isContractHolder: Scalars['Boolean']['input'];
  isFirstContractorsSim: Scalars['Boolean']['input'];
  isUnder18: Scalars['Boolean']['input'];
  mnp?: InputMaybe<MnpInput>;
  sendContract: Scalars['Boolean']['input'];
  simType: SimTypeInput;
  voicePlan?: InputMaybe<VoicePlanInput>;
};

/** Represents an instance of the NHK On Demand Service */
export type NhkOnDemandService = {
  __typename: 'NhkOnDemandService';
  automaticBillingTypeCode: Maybe<Scalars['String']['output']>;
  automaticBillingTypeName: Maybe<Scalars['String']['output']>;
  commonProductTypeCode: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  expiryDateTime: Maybe<Scalars['DateTime']['output']>;
  lastUpdated: Maybe<Scalars['DateTime']['output']>;
  pauseReason: Maybe<Scalars['String']['output']>;
  planCode: Maybe<Scalars['String']['output']>;
  planName: Maybe<Scalars['String']['output']>;
  ppdMagazineReadableFlag: Maybe<Scalars['Boolean']['output']>;
  priceIncludingTax: Maybe<Scalars['Int']['output']>;
  registrationDateTime: Maybe<Scalars['DateTime']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** Payment method registered to the UnextAccount cannot be used with 3D secure or no payment method registered */
export type NoApplicablePaymentMethodFor3DSecureError = {
  __typename: 'NoApplicablePaymentMethodFor3DSecureError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Email address was not registered for this UnextAccount */
export type NoEmailAddressRegisteredFor3DSecureError = {
  __typename: 'NoEmailAddressRegisteredFor3DSecureError';
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Calendar event for a sporting event that does not involve team participants */
export type NoTeamSportEvent = CalendarEvent & {
  __typename: 'NoTeamSportEvent';
  /** ContentCode: contains information specific to live content or VOD content */
  contentCode: ContentCode;
  /** Calendar Event Start Date Time */
  date: Scalars['DateTime']['output'];
  /** Calendar Event Id */
  id: Scalars['ID']['output'];
  /** Image Url: usually for background image of the event */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Commentary language */
  language: Maybe<Scalars['String']['output']>;
  /** League */
  league: Maybe<Scalars['String']['output']>;
  /** Calendar Event Name */
  name: Scalars['String']['output'];
  /** Round */
  round: Maybe<Scalars['String']['output']>;
  /** Calendar Event State: that used for display the text in the UI */
  state: CalendarEventState;
  /** Descriptor tags for the event */
  tags: Maybe<Array<CalendarSearchTag>>;
};

/** Error encountered when deposit info is being fetched for a UnextSubscription that does not exist */
export type NoUnextSubscriptionError = UserFacingError & {
  __typename: 'NoUnextSubscriptionError';
  /** The error message */
  message: Scalars['String']['output'];
};

export type Node = {
  __typename: 'Node';
  contentType: ContentType;
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
};

export type NodeFilterInput = {
  contentType: ContentTypeInput;
  id: Scalars['ID']['input'];
};

/** Deprecated */
export type NotAddonSubcriptionError = UserFacingError & {
  __typename: 'NotAddonSubcriptionError';
  /**
   * The error message
   * @deprecated use the correctly spelled error type instead
   */
  message: Scalars['String']['output'];
};

/** Error encountered when the Subscription Type provided is not an AddonSubscription */
export type NotAddonSubscriptionError = UserFacingError & {
  __typename: 'NotAddonSubscriptionError';
  /** The error message */
  message: Scalars['String']['output'];
};

/** Refers to a error caused by the user is not eligible for free trial */
export type NotEligibleForFreeTrialError = UserFacingError & {
  __typename: 'NotEligibleForFreeTrialError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Error when SUB user tries to delete unextuser */
export type NotPermittedError = UserFacingError & {
  __typename: 'NotPermittedError';
  /** The error message */
  message: Scalars['String']['output'];
};

/** Contract status of a UnextAccount on NSBO */
export type NsboContractInfo = UnextAccountContractInfo & {
  __typename: 'NsboContractInfo';
  /** Start date for this contract. */
  contractStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Contract Status type code (alphanumeric code) */
  contractStatusTypeCode: Maybe<Scalars['String']['output']>;
  /** Contract status type name (in Japanese) */
  contractStatusTypeName: Maybe<Scalars['String']['output']>;
  /** Last updated date time for this contract */
  contractStatusUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** Covers NSBO to BOS migration status of a UnextAccount */
export type NsboToBosMigrationStatus = {
  __typename: 'NsboToBosMigrationStatus';
  /** Denotes whether a UnextAccount can be migrated */
  canMigrate: Maybe<Scalars['Boolean']['output']>;
  /** Reason code defining why a UnextAccount cannot be migrated */
  reasonCode: Maybe<Scalars['String']['output']>;
  /** Detailed message defining why a UnextAccount cannot be migrated */
  reasonMessage: Maybe<Scalars['String']['output']>;
};

export type OneTimeTokenResponse = {
  __typename: 'OneTimeTokenResponse';
  token: Scalars['String']['output'];
};

/**
 * An option that can be purchased by a user
 * Options are additional services that are applied account wide. Not to be confused with SimOptions
 */
export type Option = {
  __typename: 'Option';
  /** Denotes whether or not this option can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  optionTypeEnum: Maybe<OptionTypeEnum>;
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** Input for getting option */
export type OptionInput = {
  id: Scalars['ID']['input'];
  optionTypeEnum: OptionTypeEnum;
  runningTypeCode: Scalars['String']['input'];
};

/** The type of Yu Mobile Option */
export enum OptionTypeEnum {
  I_FILTER = 'I_FILTER',
}

export type OriginalComicBlock = {
  __typename: 'OriginalComicBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID']['output'];
  lead: Scalars['String']['output'];
  leadTitle: Scalars['String']['output'];
  results: Scalars['Int']['output'];
  trackingCode: Scalars['String']['output'];
  type: BlockType;
};

/** TempApplicationInput is transformed into the OTA Application input when the application is ready to be committed into the Waikiki System */
export type OtaApplicationInput = {
  billingInfo: BillingInfoInput;
  emailAddress: Scalars['String']['input'];
  encryptedPassword?: InputMaybe<Scalars['String']['input']>;
  entryParams: OtaEntryParams;
  loginId?: InputMaybe<Scalars['String']['input']>;
  multiAccountId?: InputMaybe<Scalars['String']['input']>;
  options: Array<InputMaybe<OptionInput>>;
  plan: PlanInput;
  routeCode: ApplicationRoute;
  sendContract: Scalars['Boolean']['input'];
  simUsers?: InputMaybe<Array<InputMaybe<OtaApplicationSimUserInput>>>;
  temporaryApplicationId: Scalars['Int']['input'];
  userPlatformId?: InputMaybe<Scalars['String']['input']>;
};

/** This is an input type for use with OtaApplicationInput mutation only */
export type OtaApplicationSimUserInput = {
  customerInfo: SimUserCustomerInfoInput;
  isContractHolder: Scalars['Boolean']['input'];
  isFirstContractorsSim: Scalars['Boolean']['input'];
  isUnder18: Scalars['Boolean']['input'];
  mnp?: InputMaybe<MnpInput>;
  registrationNo: Scalars['String']['input'];
  simSerialNo: Scalars['String']['input'];
  simType: SimTypeInput;
  temporaryApplicationSimUserId: Scalars['String']['input'];
  voicePlan?: InputMaybe<VoicePlanInput>;
};

/** Parameters for designating the entry route and campaigns for a given ota application. This set of parameters is specifically for submitting an OTA application */
export type OtaEntryParams = {
  /** Sign up cash back rebate value */
  cashbackValue?: InputMaybe<Scalars['Int']['input']>;
  entryCampaignCode: Scalars['String']['input'];
  entrySalesAgencyCode: Scalars['String']['input'];
  entryShopCode: Scalars['String']['input'];
  staffCode: Scalars['String']['input'];
  staffName: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
};

/** Movie Tickets */
export type OwnedCinemaCouponQREdition = {
  __typename: 'OwnedCinemaCouponQREdition';
  /** You can determine whether it is a Standart ticket or a Toho ticket and retrieve the results. */
  unextCinemaTicket: Maybe<UnextCinemaTicketResult>;
};

export type PackDescription = {
  __typename: 'PackDescription';
  url: PackDescriptionUrl;
};

export type PackDescriptionUrl = {
  __typename: 'PackDescriptionUrl';
  browser: Scalars['String']['output'];
  embedded: Scalars['String']['output'];
};

/** The page information of one paginated response */
export type PageInfo = {
  __typename: 'PageInfo';
  /** Index of the current page (1-based indexing) */
  currentPage: Scalars['Int']['output'];
  /** Index of the first record in the current page among all the records (1-based indexing) */
  firstIdOnPage: Maybe<Scalars['Int']['output']>;
  /** Whether there is a following page */
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  /** Whether there is a preceding page */
  hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  /** Index of the last record in the current page among all the records (1-based indexing) */
  lastIdOnPage: Maybe<Scalars['Int']['output']>;
  /** Index of the last page (1-based indexing) */
  maxPage: Scalars['Int']['output'];
  /** Number of records in one page */
  pageSize: Scalars['Int']['output'];
  /** Total number of records in the unpaginated query result */
  totalRecordCount: Scalars['Int']['output'];
};

export type PageInfoHash = {
  __typename: 'PageInfoHash';
  nextPageHash: Scalars['ID']['output'];
  pageSize: Scalars['Int']['output'];
};

/** Specifies a certain page to query for queries with pagination support */
export type PageSpecifier = {
  /** Page index. 1 by default which is the first page. */
  pageNo?: InputMaybe<Scalars['Int']['input']>;
  /** Page size. 50 by default. */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  /** Key for sorting. null by default the behavior of which will depend on the backend API. */
  sortKey?: InputMaybe<Scalars['String']['input']>;
  /** Sort order. null by default the behavior of which will depend on the backend API. */
  sortOrder?: InputMaybe<SortOrder>;
};

export type PaginatedAliasChapterCredit = ContentPaginatedResponse & {
  __typename: 'PaginatedAliasChapterCredit';
  pageInfo: ContentPageInfo;
  records: Array<AliasChapterCredit>;
};

export type PaginatedAliasChapterPerformance = ContentPaginatedResponse & {
  __typename: 'PaginatedAliasChapterPerformance';
  pageInfo: ContentPageInfo;
  records: Array<AliasChapterPerformance>;
};

export type PaginatedAliasCredit = ContentPaginatedResponse & {
  __typename: 'PaginatedAliasCredit';
  pageInfo: ContentPageInfo;
  records: Array<AliasCredit>;
};

export type PaginatedAliasPerformance = ContentPaginatedResponse & {
  __typename: 'PaginatedAliasPerformance';
  pageInfo: ContentPageInfo;
  records: Array<AliasPerformance>;
};

export type PaginatedBonusMaterials = ContentPaginatedResponse & {
  __typename: 'PaginatedBonusMaterials';
  pageInfo: ContentPageInfo;
  records: Array<BonusMaterial>;
};

export type PaginatedContentBlocks = ContentPaginatedResponse & {
  __typename: 'PaginatedContentBlocks';
  pageInfo: ContentPageInfo;
  records: Array<ContentBlock>;
};

export type PaginatedContentTags = ContentPaginatedResponse & {
  __typename: 'PaginatedContentTags';
  pageInfo: ContentPageInfo;
  records: Array<ContentTag>;
};

export type PaginatedMediaChapterLight = ContentPaginatedResponse & {
  __typename: 'PaginatedMediaChapterLight';
  pageInfo: ContentPageInfo;
  records: Array<MediaChapterLight>;
};

export type PaginatedMediaLight = ContentPaginatedResponse & {
  __typename: 'PaginatedMediaLight';
  pageInfo: ContentPageInfo;
  records: Array<MediaLight>;
};

/** Represents a paginated response */
export type PaginatedResponse = {
  pageInfo: PageInfo;
};

export type PaginatedSalesBlocks = ContentPaginatedResponse & {
  __typename: 'PaginatedSalesBlocks';
  pageInfo: ContentPageInfo;
  records: Array<SalesBlock>;
};

/** Represents one paginated response of temp applications */
export type PaginatedTempApplications = PaginatedResponse & {
  __typename: 'PaginatedTempApplications';
  pageInfo: PageInfo;
  records: Array<Maybe<TempApplication>>;
};

export type PaginatedTrailers = ContentPaginatedResponse & {
  __typename: 'PaginatedTrailers';
  pageInfo: ContentPageInfo;
  records: Array<Trailer>;
};

/** The kind of participant in a team sport */
export enum ParticipantKind {
  /** The participant is part of the away team */
  AWAY = 'AWAY',
  /** The participant is part of the home team */
  HOME = 'HOME',
}

export type Partner = {
  __typename: 'Partner';
  code: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  entrySalesAgencyCode: Maybe<Scalars['String']['output']>;
  invalidated: Scalars['Boolean']['output'];
  invalidatedAt: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedBy: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partnerCode: Scalars['String']['output'];
  stores: Maybe<Array<Store>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PartnerInput = {
  code: Scalars['ID']['input'];
  entrySalesAgencyCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  partnerCode: Scalars['String']['input'];
  stores?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PartnerUpdateInput = {
  code: Scalars['String']['input'];
  entrySalesAgencyCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerCode?: InputMaybe<Scalars['String']['input']>;
  stores?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PartnersPaginatedResponse = {
  __typename: 'PartnersPaginatedResponse';
  partners: Maybe<Array<Maybe<Partner>>>;
  totalCount: Scalars['Int']['output'];
};

/** 有料決済種別（ユーザが選択できる＝表示する決済手段） */
export enum PaySettlement {
  /** カード決済またはAmazonPay決済 */
  CREDIT_OR_AMAZON = 'CREDIT_OR_AMAZON',
  /** カード決済またはAmazonPay決済またはSBPS決済キャリア */
  CREDIT_OR_AMAZON_OR_SBPS = 'CREDIT_OR_AMAZON_OR_SBPS',
  /** カード決済またはAmazonPay決済またはSBPS決済キャリアと楽天 */
  CREDIT_OR_AMAZON_OR_SBPS_OR_RAKUTEN = 'CREDIT_OR_AMAZON_OR_SBPS_OR_RAKUTEN',
  /** カード決済またはキャリア決済 */
  CREDIT_OR_CARRIER = 'CREDIT_OR_CARRIER',
  /** カード決済またはSBPS決済（キャリア）またはSBPS決済（楽天ID） */
  CREDIT_OR_CARRIER_OR_SBPS = 'CREDIT_OR_CARRIER_OR_SBPS',
  /** カード決済またはSBPS決済（楽天ID） */
  CREDIT_OR_RAKUTEN = 'CREDIT_OR_RAKUTEN',
  /** カード決済またはSBPS決済（キャリア） */
  CREDIT_OR_SBPS = 'CREDIT_OR_SBPS',
  /** AmazonPay決済 */
  ONLY_AMAZON = 'ONLY_AMAZON',
  /** キャリア決済 */
  ONLY_CARRIER = 'ONLY_CARRIER',
  /** カード決済 */
  ONLY_CREDIT = 'ONLY_CREDIT',
  /** SBPS */
  ONLY_SBPS = 'ONLY_SBPS',
}

/** Known as SaleTypeBadge for Books CMS API */
export type PaymentBadge = {
  __typename: 'PaymentBadge';
  code: PaymentBadgeCode;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum PaymentBadgeCode {
  BUY = 'BUY',
  POINT = 'POINT',
  RENTAL = 'RENTAL',
  SALE = 'SALE',
  SELL = 'SELL',
  SVOD = 'SVOD',
}

/** Input for upserting a PaymentCard billing method to a UnextAcconut */
export type PaymentCardBillingInfoInput = {
  /** Name on the payment cards */
  cardholderName: Scalars['String']['input'];
  /** Escott token acquired from Escott (Sony Payments) */
  token: Scalars['String']['input'];
};

/** Payment card info for a payment card that has been registered as a billing method to a UnextAccount */
export type PaymentCardInfo = {
  __typename: 'PaymentCardInfo';
  /** Payment card expirty date */
  cardExpiryDate: Scalars['String']['output'];
  /** Masked payment card number */
  cardNumber: Scalars['String']['output'];
  /** Payment card type */
  cardType: Scalars['String']['output'];
  /** Cardholder name */
  cardholderName: Maybe<Scalars['String']['output']>;
  /** Registered Card id */
  id: Scalars['ID']['output'];
};

/** Operation was not allowed due to a pending application process. */
export type PendingApplicationError = UserFacingError & {
  __typename: 'PendingApplicationError';
  /** The error message. */
  message: Scalars['String']['output'];
};

export type PersonFreewordSearchResponse = {
  __typename: 'PersonFreewordSearchResponse';
  pageInfo: PortalPageInfo;
  people: Array<Credit>;
};

export type PersonWebfront = {
  __typename: 'PersonWebfront';
  penNameCode: Maybe<Scalars['ID']['output']>;
  personCode: Scalars['ID']['output'];
  personName: Scalars['String']['output'];
  personNameCode: Maybe<Scalars['ID']['output']>;
};

/** Provided phone number was rejected */
export type PhoneNumberRejectedError = UserFacingError & {
  __typename: 'PhoneNumberRejectedError';
  /** A code denoting why the the phone number may have been rejected */
  code: Maybe<Scalars['String']['output']>;
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** A mobile service plan that can be purchased by a user */
export type Plan = {
  __typename: 'Plan';
  /** Denotes whether or not this plan can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Denotes whether or not a video U-NEXT subscription is included with this plan */
  includesVideoSubscription: Maybe<Scalars['Boolean']['output']>;
  planTypeEnum: Maybe<PlanTypeEnum>;
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** Input for getting plan */
export type PlanInput = {
  id: Scalars['ID']['input'];
  runningTypeCode: Scalars['String']['input'];
};

/** The type of Yu Mobile Plan */
export enum PlanTypeEnum {
  SHARED = 'SHARED',
  SINGLE = 'SINGLE',
  SINGLE_UNEXT = 'SINGLE_UNEXT',
}

export type Planet = {
  __typename: 'Planet';
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum Platform {
  ANDROID = 'ANDROID',
  ANDROID_TV = 'ANDROID_TV',
  IOS = 'IOS',
  WEB = 'WEB',
}

/** Denotes which backend the user is hosted on */
export enum PlatformCode {
  AMAZON = 'AMAZON',
  BOS = 'BOS',
  GELATONI = 'GELATONI',
  LDH = 'LDH',
  NSBO = 'NSBO',
  THEATER = 'THEATER',
  YAMADA = 'YAMADA',
}

export type PlatformInfo = {
  __typename: 'PlatformInfo';
  deviceName: Scalars['String']['output'];
  deviceType: Scalars['Int']['output'];
  screenType: Maybe<ScreenType>;
  userAgent: Scalars['String']['output'];
};

export enum PlayMode {
  DOWNLOAD = 'DOWNLOAD',
  STREAMING = 'STREAMING',
}

export enum PlayType {
  DOWNLOAD = 'DOWNLOAD',
  STREAMING = 'STREAMING',
}

export type Playable = {
  __typename: 'Playable';
  cdns: Array<PlayableCdn>;
  type: StreamingType;
};

export type PlayableCdn = {
  __typename: 'PlayableCdn';
  cdnId: Scalars['ID']['output'];
  licenseUrlList: Array<LicenseUrl>;
  playlistUrl: Scalars['String']['output'];
  timeshiftPlaylistUrl: Maybe<Scalars['String']['output']>;
  weight: Scalars['Float']['output'];
};

export type PlaybackRight = {
  __typename: 'PlaybackRight';
  createdAt: Scalars['DateTime']['output'];
  expiredDateTime: Maybe<Scalars['DateTime']['output']>;
  productCode: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlaybackTokenResponse = {
  __typename: 'PlaybackTokenResponse';
  /** Token to control simultaneous playback */
  isemToken: Scalars['String']['output'];
  /** Token for viewing rights */
  playbackToken: Scalars['String']['output'];
  productCode: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<CapySaleTypeCode>;
};

export type PlaylistMovieProfile = {
  __typename: 'PlaylistMovieProfile';
  cdnId: Maybe<Scalars['String']['output']>;
  licenseUrlList: Array<LicenseUrl>;
  movieAudioList: Array<AudioType>;
  playlistUrl: Maybe<Scalars['String']['output']>;
  type: Maybe<StreamingType>;
};

export type PlaylistUrl = {
  __typename: 'PlaylistUrl';
  beaconSpan: Maybe<Scalars['Int']['output']>;
  downloadTitleMeta: Maybe<DownloadTitleMeta>;
  licenseExpireDate: Maybe<Scalars['String']['output']>;
  playToken: Maybe<Scalars['String']['output']>;
  playTokenHash: Maybe<Scalars['String']['output']>;
  result: Maybe<Result>;
  resultStatus: Maybe<Scalars['Int']['output']>;
  subTitle: Maybe<Scalars['String']['output']>;
  urlInfo: Array<UrlInfo>;
};

export enum PointBackProduct {
  CHARGE = 'CHARGE',
  EST = 'EST',
  LIVE = 'LIVE',
  PPDKARA = 'PPDKARA',
  TOD = 'TOD',
}

export type PointBackResponse = {
  __typename: 'PointBackResponse';
  /** video_cont_flg: ビデオ見放題明細を持っているかフラグ */
  hasVideoSubscription: Scalars['Boolean']['output'];
  isAnnouncedIos: Scalars['Boolean']['output'];
  point: Scalars['Int']['output'];
  setting: Maybe<PointBackSetting>;
  settlementPlatformCode: Scalars['String']['output'];
};

export type PointBackSetting = {
  __typename: 'PointBackSetting';
  /** point_back_increase_percentage_flg: ポイントバック率を上げる余地があるフラグ */
  canIncreasePercentage: Scalars['Boolean']['output'];
  /** restricted_to_point_flg: ポイントまたは U コインしか購入することができないフラグ */
  isRestrictedToPoint: Scalars['Boolean']['output'];
  maxPercentage: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
  productList: Array<PointBackProduct>;
  scheduleDate: Scalars['String']['output'];
};

/** Point balance */
export type PointBalance = {
  __typename: 'PointBalance';
  /** Current point balance */
  balance: Scalars['Int']['output'];
  /** The earliest expiry date of the points */
  earliestExpiringDateTime: Maybe<Scalars['DateTime']['output']>;
  /** The number of points that will expire at the earliest expiry date */
  earliestExpiringPoints: Scalars['Int']['output'];
  /** Point type, whether it's normal points or u-coins */
  type: PointClass;
};

/** The PointCharge operation has failed. */
export type PointChargeError = UserFacingError & {
  __typename: 'PointChargeError';
  /** A code denoting why the point charge operation failed. */
  code: Maybe<Scalars['String']['output']>;
  /** Additional error message. */
  message: Scalars['String']['output'];
};

/** Details of a point charge product */
export type PointChargeProduct = {
  __typename: 'PointChargeProduct';
  /** Amount of points that will be added to the account. */
  pointAmount: Scalars['Int']['output'];
  /** Product ID */
  productId: Scalars['String']['output'];
  /** Descriptive name of the product */
  productName: Scalars['String']['output'];
  /** Price of the product */
  salePrice: Scalars['Int']['output'];
};

/** Server response upon attempting to get the list of point charge products */
export type PointChargeProductsResponse = {
  __typename: 'PointChargeProductsResponse';
  /** List of Point Charge Products */
  products: Array<PointChargeProduct>;
};

/** Status of a PointCharge transaction */
export type PointChargeStatusResponse = {
  __typename: 'PointChargeStatusResponse';
  /** Returns true if the transaction is completed. */
  isFinished: Scalars['Boolean']['output'];
  /** Status of the transaction. */
  status: Maybe<Scalars['String']['output']>;
};

/** union for errors encountered during GiftCode queries or mutations */
export type PointChargeUserError =
  | CurrencyAuthorizationError
  | NoApplicablePaymentMethodFor3DSecureError
  | NoEmailAddressRegisteredFor3DSecureError
  | PointChargeError
  | UnsupportedPlatformError;

/** Encrypted Transaction information for 3DSecure flow */
export type PointChargeVia3DSecureResponse = {
  __typename: 'PointChargeVia3DSecureResponse';
  /** Purchase Details encrypted with the Payment Service Provider's key */
  encryptedValue: Maybe<Scalars['String']['output']>;
  /** Our platform's identifier in the Payment Service Provider's system */
  merchantId: Maybe<Scalars['String']['output']>;
  /** Errors on the input. */
  userErrors: Array<PointChargeUserError>;
};

/**
 * Point type, whether it's normal points or u-coins
 * e.g.
 * - POINT: normal points
 * - UCOIN: points purchased through IAP -> APPLE
 */
export enum PointClass {
  /** normal points */
  POINT = 'POINT',
  /** u-coins */
  UCOIN = 'UCOIN',
}

/**
 * Point type, whether it's normal points or u-coins (used for input)
 * e.g.
 * - POINT: normal points
 * - UCOIN: points purchased through IAP -> APPLE
 */
export enum PointClassInput {
  /** normal points */
  POINT = 'POINT',
  /** u-coins */
  UCOIN = 'UCOIN',
}

/** Campaign to grant a certain number of points to the account. */
export type PointGrantCampaignInfo = {
  __typename: 'PointGrantCampaignInfo';
  /** Description of the rewards of the campaign. */
  campaignReward: Maybe<Scalars['String']['output']>;
  /** Campaign type. */
  campaignType: Maybe<Scalars['String']['output']>;
  /** Number of points granted with this campaign. */
  pointAmount: Maybe<Scalars['String']['output']>;
};

/** PointGrantScheduleItem refers to the operation of granting a certain number of Points to the user and when it will happen */
export type PointGrantScheduleItem = {
  __typename: 'PointGrantScheduleItem';
  /** Point amount */
  amount: Scalars['Int']['output'];
  /** Date at which the points will be granted */
  date: Scalars['DateTime']['output'];
};

/**
 * Point History Event, represents a point balance change event.
 * Could be a purchase of points, usage or expiration.
 */
export type PointHistoryEvent = {
  __typename: 'PointHistoryEvent';
  /** Point amount */
  amount: Scalars['Int']['output'];
  /** Additional details about the Item/Product, nullable */
  itemDetail: Maybe<Scalars['String']['output']>;
  /** Item/Product name, nullable */
  itemName: Maybe<Scalars['String']['output']>;
  /** Item/Product type that was target of the point operation, nullable */
  itemType: Maybe<Scalars['String']['output']>;
  /** Date of the operation */
  operationDateTime: Scalars['DateTime']['output'];
  /** Code of the point operation, nullable */
  operationTypeCode: Maybe<Scalars['String']['output']>;
  /** Point History Class, defines how the point balance changed due to the operation. */
  pointHistoryType: PointHistoryEventClass;
  /** Point Class of the event (normal or UCOIN) */
  type: PointClass;
};

/** Point History Type, specifies how the point balance changed due to some operation */
export enum PointHistoryEventClass {
  /** Point purchase event */
  ADDED = 'ADDED',
  /** Point expiration event */
  EXPIRED = 'EXPIRED',
  /** Point usage event */
  USED = 'USED',
}

/**
 * ポイント利用・失効履歴
 * Portal API docs: https://wiki.unext-info.jp/pages/viewpage.action?pageId=98498593
 */
export type PointHistoryModel = {
  __typename: 'PointHistoryModel';
  /**
   * ポイント失効予定日時の表示テキスト（「まで有効」も含む）
   * 表示しなければいけない場合だけテキストが入っている
   *
   * e.g. "2022年9月17日 23:59まで有効"
   */
  itemDetailInfo: Scalars['String']['output'];
  /** 詳細 の項目「4. 詳細」に相当する (https://wiki.unext-info.jp/pages/viewpage.action?pageId=85289617) */
  itemName: Scalars['String']['output'];
  /**
   * 詳細 の項目「3. 項目」に相当する (https://wiki.unext-info.jp/pages/viewpage.action?pageId=85289617)
   * 表示しなければいけない場合だけテキストが入っている
   *
   * e.g. "キャンペーン／ギフトコード適用"
   */
  itemType: Scalars['String']['output'];
  /**
   * ポイント操作日
   * e.g. "2022年6月20日"
   */
  operationDate: Scalars['String']['output'];
  /**
   * ポイント操作日時（データとして使う際に）
   * e.g. "20220620170513"
   */
  operationDatetime: Scalars['String']['output'];
  /**
   * ポイント操作時間
   * e.g. "17:05"
   */
  operationTime: Scalars['String']['output'];
  /**
   * ポイント操作対象区分
   *
   * API docs that lists codes: https://wiki.unext-info.jp/pages/viewpage.action?pageId=98498593
   */
  operationTypeCode: Scalars['String']['output'];
  /**
   * 操作ポイント数
   * 付与時に付記する "+" はクライアントで付けた方がよいかと
   */
  point: Scalars['Int']['output'];
  /**
   * ポイント操作の区分
   * ポイント数文字列に色付けする際の基準
   */
  pointHistoryType: PointHistoryType;
};

/** Point History Summary, contains the chronological list of Point History Events */
export type PointHistorySummary = {
  __typename: 'PointHistorySummary';
  /** The list of events */
  events: Array<PointHistoryEvent>;
};

export enum PointHistoryType {
  ADDED = 'ADDED',
  EXPIRED = 'EXPIRED',
  USED = 'USED',
}

export type PointPurchasedProductItem = {
  __typename: 'PointPurchasedProductItem';
  chargePoint: Maybe<Scalars['Int']['output']>;
  code: Scalars['ID']['output'];
  commonProductTypeCode: Maybe<Scalars['String']['output']>;
  expireRightHours: Maybe<Scalars['Int']['output']>;
  isAdult: Scalars['Boolean']['output'];
  isPoint: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  planCode: Maybe<Scalars['String']['output']>;
  productTypeCode: Maybe<Scalars['String']['output']>;
  salePrice: Maybe<Scalars['Int']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
};

export type PointbackSchedule = {
  __typename: 'PointbackSchedule';
  /**
   * ポイントバックするポイント数
   *
   * e.g. 302
   */
  pointBackAmount: Scalars['Int']['output'];
  /**
   * ポイントバックする日
   *
   * e.g. "20181229"
   */
  pointBackDate: Scalars['String']['output'];
};

/** Block containing the Top 100 most popular titles as of the current server time for a planet or in the universe. */
export type PopularityRankingBlock = MediaBlock & {
  __typename: 'PopularityRankingBlock';
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  paginatedMedia: PaginatedMediaLight;
};

export type PortalPageInfo = {
  __typename: 'PortalPageInfo';
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  results: Scalars['Int']['output'];
};

export enum PortalPointHistoryRequestType {
  IOS = 'IOS',
  NORMAL = 'NORMAL',
}

export enum PortalSortOrder {
  ALPHA_ASC = 'ALPHA_ASC',
  ALPHA_DESC = 'ALPHA_DESC',
  AUTHOR_ASC = 'AUTHOR_ASC',
  AUTHOR_DESC = 'AUTHOR_DESC',
  LAST_UPDATE_DESC = 'LAST_UPDATE_DESC',
  NEW_ARRIVAL = 'NEW_ARRIVAL',
  POPULAR = 'POPULAR',
  PRODUCTION_YEAR_ASC = 'PRODUCTION_YEAR_ASC',
  PRODUCTION_YEAR_DESC = 'PRODUCTION_YEAR_DESC',
  PUBLIC_END_ASC = 'PUBLIC_END_ASC',
  PUBLIC_START_DESC = 'PUBLIC_START_DESC',
  RECOMMEND = 'RECOMMEND',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  UPDATE_DATE_TIME = 'UPDATE_DATE_TIME',
}

export type PostPlayItem = {
  __typename: 'PostPlayItem';
  catchphrase: Scalars['String']['output'];
  chromecastFlag: Maybe<Scalars['Boolean']['output']>;
  country: Scalars['String']['output'];
  displayNo: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  episodeCode: Scalars['String']['output'];
  episodeName: Scalars['String']['output'];
  exclusive: ExclusiveInfo;
  /** @deprecated use exclusive instead */
  exclusiveBadgeCode: Maybe<ExclusiveBadgeCode>;
  hasDub: Scalars['Boolean']['output'];
  hasMultiprice: Scalars['Boolean']['output'];
  hasPackRights: Scalars['Boolean']['output'];
  hasSubtitle: Scalars['Boolean']['output'];
  introduction: Scalars['String']['output'];
  isNew: Scalars['Boolean']['output'];
  isOriginal: Scalars['Boolean']['output'];
  lastEpisode: Scalars['String']['output'];
  mainCast: Array<Credit>;
  minimumPrice: Scalars['Int']['output'];
  nextUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
  nfreeBadge: Scalars['String']['output'];
  paymentBadgeList: Array<PaymentBadge>;
  productLineupCodeList: Array<Scalars['String']['output']>;
  productionYear: Scalars['String']['output'];
  /** 配信中話数 */
  publicMainEpisodeCount: Maybe<Scalars['Int']['output']>;
  publishStyleCode: Scalars['String']['output'];
  rate: Scalars['Int']['output'];
  saleText: Scalars['String']['output'];
  thumbnail: Thumbnail;
  titleCode: Scalars['String']['output'];
  titleName: Scalars['String']['output'];
  updateOfWeek: Scalars['Int']['output'];
};

export type PostPlayResult = {
  __typename: 'PostPlayResult';
  nextEpisode: Maybe<PostPlayItem>;
  recommendations: Maybe<Array<PostPlayItem>>;
  resultType: PostPlayResultType;
};

export enum PostPlayResultType {
  NEXT_EPISODE = 'NEXT_EPISODE',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
}

/** An address that is the result of a postal code lookup */
export type PostalCodeAddress = {
  __typename: 'PostalCodeAddress';
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  addressLine3: Maybe<Scalars['String']['output']>;
  prefecture: Maybe<Scalars['String']['output']>;
};

export type Preview = {
  __typename: 'Preview';
  cdns: Array<PreviewCdn>;
  type: PreviewType;
};

export type PreviewCdn = {
  __typename: 'PreviewCdn';
  cdnId: Scalars['ID']['output'];
  height: Scalars['Int']['output'];
  playlistUrl: Scalars['String']['output'];
  timeshiftPlaylistUrl: Maybe<Scalars['String']['output']>;
  updateIntervalMs: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Int']['output'];
};

export enum PreviewType {
  JPG = 'JPG',
}

export type Product = {
  __typename: 'Product';
  chargePoint: Scalars['Int']['output'];
  expire: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isPointChargable: Scalars['Boolean']['output'];
  nsboSaleTypeCode: Scalars['String']['output'];
  nsboTypeCode: Scalars['String']['output'];
  packDescription: Maybe<PackDescription>;
  planCode: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  ruleImageUrl: Scalars['String']['output'];
  ruleText: Scalars['String']['output'];
  ruleTextHeading: Scalars['String']['output'];
  ruleTitle: Scalars['String']['output'];
  typeCode: Scalars['String']['output'];
};

/** A basic struct that holds the pricing data required to present a product (ContentPack, Subscription, etc.) to the front end */
export type ProductPrice = {
  __typename: 'ProductPrice';
  /** Price with the consumption tax excluded */
  priceExcludingTax: Maybe<Scalars['Int']['output']>;
  /** Price with the consumption tax included */
  priceIncludingTax: Scalars['Int']['output'];
  /**
   * Amount of points that will be "commited" at the start of every month
   * if this product is purchased.
   * e.g. the SPOTV IAP pack costs 800 "yen" per month, but requires a deposit of 1200 points
   * at the start of each month
   */
  requiredPointDeposit: Maybe<Scalars['Int']['output']>;
};

/** Product Purchase */
export type ProductPurchase = {
  __typename: 'ProductPurchase';
  adultFlag: Maybe<Scalars['Boolean']['output']>;
  billingPrice: Scalars['Int']['output'];
  cancelFlag: Maybe<Scalars['Boolean']['output']>;
  partnerPointAmount: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  purchaseDate: Scalars['DateTime']['output'];
  /** LoginId of the user who made the purchase */
  purchaserLoginId: Scalars['String']['output'];
  salePrice: Scalars['Int']['output'];
  uCoinAmount: Scalars['Int']['output'];
  unextPointAmount: Scalars['Int']['output'];
};

/** Error during product purchase related to the product status or the user's authorizations */
export type ProductPurchaseError = UserFacingError & {
  __typename: 'ProductPurchaseError';
  /** A code denoting why the the product may not be purchased */
  code: Maybe<Scalars['String']['output']>;
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** The Summary of monthly purchased products. */
export type ProductPurchaseSummary = {
  __typename: 'ProductPurchaseSummary';
  /** The month of the purchase history */
  month: Scalars['DateTime']['output'];
  /** The purchase list */
  purchases: Array<Maybe<ProductPurchaseV2>>;
  /** The total billing price */
  totalBillingPrice: Scalars['Int']['output'];
  /** The total number of partner points */
  totalPartnerPointNum: Scalars['Int']['output'];
  /** The total sale price */
  totalSalePrice: Scalars['Int']['output'];
  /** The total number of ucoins */
  totalUcoinNum: Scalars['Int']['output'];
  /** The total number of unext points */
  totalUnextPointNum: Scalars['Int']['output'];
};

/** Product Purchase v2 (served from account graph) */
export type ProductPurchaseV2 = {
  __typename: 'ProductPurchaseV2';
  /** Whether or not the purchased product is adult content */
  adultFlag: Maybe<Scalars['Boolean']['output']>;
  /** Billing price of purchased product */
  billingPrice: Scalars['Int']['output'];
  /** Whether or not the purchased product has been cancelled */
  cancelFlag: Maybe<Scalars['Boolean']['output']>;
  /** Number of partner points granted by this purchase */
  partnerPointAmount: Scalars['Int']['output'];
  /** Name of purchased product */
  productName: Scalars['String']['output'];
  /** DateTime of the purchase */
  purchaseDate: Scalars['DateTime']['output'];
  /** LoginId of the user who made the purchase */
  purchaserLoginId: Scalars['String']['output'];
  /** Sale price of purchased product */
  salePrice: Scalars['Int']['output'];
  /** Number of ucoins granted by this purchase */
  uCoinAmount: Scalars['Int']['output'];
  /** Number of unext points granted by this purchase */
  unextPointAmount: Scalars['Int']['output'];
};

export enum ProductTypeCode {
  CHARGE = 'CHARGE',
  FTABCHARGE = 'FTABCHARGE',
  GOOGCHARGE = 'GOOGCHARGE',
  PPDKARA = 'PPDKARA',
  PPDNOD = 'PPDNOD',
  PPDSPOTV = 'PPDSPOTV',
  UCOIN = 'UCOIN',
}

export type ProductionCompanyLight = {
  __typename: 'ProductionCompanyLight';
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
};

/** Response to productsForMedia */
export type ProductsForVideoContentResponse = {
  __typename: 'ProductsForVideoContentResponse';
  /** Content packs that can be purchased to give access to this media */
  contentPacks: Array<UnextContentPackProduct>;
  /** Products specific to this media that can be purchase to give access to the designated media */
  products: Array<ContentProduct>;
  /** Subscription products (aka products that are billed monthly by U-NEXT) that give access to the designated media */
  subscriptions: Array<UnextSubscriptionBundleOrProduct>;
};

export enum ProjectionType {
  CUBEMAP = 'CUBEMAP',
  CUSTOM = 'CUSTOM',
  EQUIRECTANGULAR = 'EQUIRECTANGULAR',
  FISHEYE = 'FISHEYE',
  UNSPECIFIED = 'UNSPECIFIED',
}

/** Proof of work session for validating phone number */
export type ProofOfWorkSession = {
  __typename: 'ProofOfWorkSession';
  /** The number of bits used in the proof of work question */
  bits: Scalars['Int']['output'];
  /** The difficulty of question */
  difficulty: Scalars['Int']['output'];
  /** The issue date time of question */
  issuedAt: Scalars['DateTime']['output'];
  /** The uuid of question */
  uuid: Scalars['String']['output'];
  /** The date time that question is not valid if after it */
  validEnd: Scalars['DateTime']['output'];
  /** The date time that question is not valid if before it */
  validStart: Scalars['DateTime']['output'];
};

/** union for errors encountered in creating phoneNum change proof */
export type ProofOfWorkSessionError =
  | DuplicatePhoneNumberError
  | InvalidPhoneNumberError
  | PhoneNumberRejectedError;

/** union for errors encountered in creating phoneNum change proof */
export type ProofOfWorkSessionForUpdatePhoneNumberError =
  | DuplicatePhoneNumberError
  | InvalidPhoneNumberError
  | PhoneNumberRejectedError;

/** Server response when get createSmsProofOfWorkSessionForUpdatePhoneNumber to change user's phone number */
export type ProofOfWorkSessionForUpdatePhoneNumberResponse = {
  __typename: 'ProofOfWorkSessionForUpdatePhoneNumberResponse';
  /** Returns the generated question */
  question: Maybe<ProofOfWorkSession>;
  /** Errors on the input. */
  userErrors: Array<ProofOfWorkSessionForUpdatePhoneNumberError>;
};

/** Server response to createIdVerificationProofOfWorkSession */
export type ProofOfWorkSessionResponse = {
  __typename: 'ProofOfWorkSessionResponse';
  /** Returns the generated question */
  question: Maybe<ProofOfWorkSession>;
  /** Errors on the input. */
  userErrors: Array<ProofOfWorkSessionError>;
};

export type Publisher = {
  __typename: 'Publisher';
  code: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** Abstraction representing a device configuration, e.g. iPhone 12 Pro Max 128GB, iPhone 12 Pro Max 64GB, etc. */
export type PurchasableConfig = {
  __typename: 'PurchasableConfig';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUri: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priceExcludingTax: Scalars['Int']['output'];
  priceIncludingTax: Scalars['Int']['output'];
  seller: Scalars['String']['output'];
  /** Seller terms of service url */
  sellerTosUrl: Scalars['String']['output'];
  stock: Scalars['Int']['output'];
};

/** Paginated response for a list of purchasable configs */
export type PurchasableConfigsResponse = PaginatedResponse & {
  __typename: 'PurchasableConfigsResponse';
  pageInfo: PageInfo;
  records: Maybe<Array<PurchasableConfig>>;
};

/** Purchasable model is an abstraction representing a device model. e.g. iPhone 11 Pro, iPhone 11 Pro Max, etc. */
export type PurchasableModel = {
  __typename: 'PurchasableModel';
  availableConfigs: PurchasableConfigsResponse;
  id: Scalars['ID']['output'];
  imageUri: Scalars['String']['output'];
  model: Scalars['String']['output'];
};

/** Purchasable model is an abstraction representing a device model. e.g. iPhone 11 Pro, iPhone 11 Pro Max, etc. */
export type PurchasableModelavailableConfigsArgs = {
  filters?: InputMaybe<DeviceConfigFilters>;
  pageSpecifier?: InputMaybe<PageSpecifier>;
};

export type PurchaseAdultRightsResponse = {
  __typename: 'PurchaseAdultRightsResponse';
  isPurchased: Scalars['Boolean']['output'];
};

/** Response to the purchase of an adult video product */
export type PurchaseAdultVideoProductResponse = {
  __typename: 'PurchaseAdultVideoProductResponse';
  /** Basic information about the purchased product */
  product: Maybe<PurchasedVideoProduct>;
  /** Errors on the input. */
  userErrors: Array<Maybe<PurchaseError>>;
};

/** Response to the purchase of a book product */
export type PurchaseBookProductResponse = {
  __typename: 'PurchaseBookProductResponse';
  /** Basic information about the purchased product */
  product: Maybe<PurchasedBookProduct>;
  /** Errors on the input. */
  userErrors: Array<Maybe<PurchaseError>>;
};

/** Error during product purchase related to the payment process */
export type PurchaseChargeError = UserFacingError & {
  __typename: 'PurchaseChargeError';
  /** A code denoting why the the charging the payment method failed */
  code: Maybe<Scalars['String']['output']>;
  /** Additional error message */
  message: Scalars['String']['output'];
};

/** Response to the purchase of a Content Pack */
export type PurchaseContentPackResponse = {
  __typename: 'PurchaseContentPackResponse';
  /** Basic information about the purchased product */
  product: Maybe<PurchasedUnextContentPack>;
  /** Errors on the input. */
  userErrors: Array<Maybe<PurchaseError>>;
};

/** union for errors encountered during product purchases */
export type PurchaseError =
  | InsufficientPointsError
  | ProductPurchaseError
  | PurchaseChargeError
  | UnsupportedPlatformError;

/** Server response for purchaseHistory query. */
export type PurchaseHistoryResponse = {
  __typename: 'PurchaseHistoryResponse';
  /** Summary of the purchase history */
  summary: Maybe<ProductPurchaseSummary>;
  /**
   * Errors on the input.
   * @deprecated throw Graphql Error for invalid date time instead
   */
  userErrors: Array<InvalidDateTimeError>;
};

export type PurchaseLiveTicket = {
  __typename: 'PurchaseLiveTicket';
  id: Scalars['ID']['output'];
  isSelling: Scalars['Boolean']['output'];
  lives: Array<Maybe<PurchaseLiveTicketLiveDescription>>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  productCode: Scalars['String']['output'];
  saleEndDateTime: Scalars['DateTime']['output'];
  saleStartDateTime: Scalars['DateTime']['output'];
  typeCode: Scalars['String']['output'];
};

export type PurchaseLiveTicketLiveDescription = {
  __typename: 'PurchaseLiveTicketLiveDescription';
  copyright: Maybe<Scalars['String']['output']>;
  deliveryEndDateTime: Scalars['DateTime']['output'];
  deliveryStartDateTime: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  performanceStartDateTime: Scalars['DateTime']['output'];
  saleTypeCode: Scalars['String']['output'];
  venue: Maybe<Scalars['String']['output']>;
};

/** Response to the purchase of a Live Ticket */
export type PurchaseLiveTicketResponse = {
  __typename: 'PurchaseLiveTicketResponse';
  /** Basic information about the purchased product */
  product: Maybe<PurchasedLiveTicket>;
  /** Errors on the input. */
  userErrors: Array<Maybe<PurchaseError>>;
};

/** Response to the purchase of a video product */
export type PurchaseVideoProductResponse = {
  __typename: 'PurchaseVideoProductResponse';
  /** Basic information about the purchased product */
  product: Maybe<PurchasedVideoProduct>;
  /** Errors on the input. */
  userErrors: Array<Maybe<PurchaseError>>;
};

export type PurchasedBook = {
  __typename: 'PurchasedBook';
  bookCode: Scalars['ID']['output'];
  bookNo: Scalars['Int']['output'];
};

/** Basic information about the purchased book product */
export type PurchasedBookProduct = {
  __typename: 'PurchasedBookProduct';
  /** Id of the book product */
  id: Scalars['ID']['output'];
  /** Name of the book product */
  name: Scalars['String']['output'];
};

export type PurchasedBooksResponse = {
  __typename: 'PurchasedBooksResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

/** Basic information about the purchased live event */
export type PurchasedLiveEvent = {
  __typename: 'PurchasedLiveEvent';
  /** Expected end time of the live stream */
  deliveryEndDateTime: Scalars['DateTime']['output'];
  /** Start time of the live stream */
  deliveryStartDateTime: Scalars['DateTime']['output'];
  /** Id of the Live */
  id: Scalars['ID']['output'];
  /** Name of the Live */
  name: Scalars['String']['output'];
  /** Expected start time of the actual event */
  performanceStartDateTime: Scalars['DateTime']['output'];
};

/** Basic information about the purchased live event ticket */
export type PurchasedLiveTicket = {
  __typename: 'PurchasedLiveTicket';
  /** Id of the Live Ticket product */
  liveTicketId: Scalars['ID']['output'];
  /** List of lives included in the ticket */
  lives: Array<Maybe<PurchasedLiveEvent>>;
  /** Name of the Live Ticket Product */
  name: Scalars['String']['output'];
};

export enum PurchasedMediaSortModeInput {
  CREATED_AT = 'CREATED_AT',
  EXPIRED_AT = 'EXPIRED_AT',
}

export type PurchasedMediaSorterInput = {
  order: SortOrder;
  sortMode: PurchasedMediaSortModeInput;
};

export type PurchasedTitlesResponse = {
  __typename: 'PurchasedTitlesResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

/** Interface for defining a content pack that has been purchased on a UnextAccount */
export type PurchasedUnextContentPack = {
  /** Date the pack was applied for */
  applicationDateTime: Scalars['DateTime']['output'];
  /** Current Billing state */
  billingState: Maybe<ContentPackBillingState>;
  /** DateTime when the content pack expires */
  expirationDateTime: Scalars['DateTime']['output'];
  /**
   * Identifier for an instance of a purchased content pack. This is not the id of the content pack product
   * @deprecated Renamed to productId
   */
  id: Scalars['ID']['output'];
  /** Date the pack was last updated */
  lastUpdated: Scalars['DateTime']['output'];
  /** Content pack name */
  name: Maybe<Scalars['String']['output']>;
  /** Reason pack was interrupted */
  pauseReason: Maybe<Scalars['String']['output']>;
  /** Content pack product information */
  product: UnextContentPackProduct;
  /** Identifier for an instance of a purchased content pack. This is not the id of the content pack product */
  productId: Scalars['ID']['output'];
  /** Content pack type code */
  typeCode: ContentPackType;
};

/** Basic information about the purchased video product */
export type PurchasedVideoProduct = {
  __typename: 'PurchasedVideoProduct';
  /** Id of the video product */
  id: Scalars['ID']['output'];
  /** True if if is an AV product */
  isAdult: Scalars['Boolean']['output'];
  /** Name of the video product */
  name: Scalars['String']['output'];
  /** Duration of the purchased viewing rights */
  validityDurationMinutes: Scalars['Int']['output'];
};

export enum PurchasingAgent {
  AMAZON = 'AMAZON',
  APPLE = 'APPLE',
  DEFAULT = 'DEFAULT',
  GOOGLE = 'GOOGLE',
  HUAWEI = 'HUAWEI',
}

/** Agent/Platform through which the purchase is being made */
export enum PurchasingAgentInput {
  /** Amazon fire devices */
  AMAZON = 'AMAZON',
  /** APPLE */
  APPLE = 'APPLE',
  /** GOOGLE */
  GOOGLE = 'GOOGLE',
  /** HUAWEI */
  HUAWEI = 'HUAWEI',
  /** WEB */
  WEB = 'WEB',
}

/** Return QREditionCompany */
export type QREditionCinemaCouponCompany = {
  __typename: 'QREditionCinemaCouponCompany';
  /** Return the cinema coupon type supported by this company */
  cinemaCoupon: Array<Maybe<CinemaCompanyCoupon>>;
  /** Returns the company code */
  companyCode: Maybe<Scalars['String']['output']>;
  /** Returns the company name */
  companyName: Maybe<Scalars['String']['output']>;
  /** Return the food coupon type supported by this company */
  foodCoupon: Array<Maybe<FoodCompanyCoupon>>;
};

export type Query = {
  __typename: 'Query';
  /** Get all Active Sale Content blocks for a planet, with paginated media. */
  activeSaleBlocksV2: PaginatedSalesBlocks;
  /** List active sessions */
  activeSessions: ActiveSessionsResponse;
  /** Gets the full alias object */
  alias: Maybe<Alias>;
  /** Gets all content tags suggestions grouping by category for search top page */
  allContentTagSuggestions: Array<Suggestion>;
  /**
   * Retrieves what billing methods are allowed for this user based on the state of the current account.
   * Many business rules in the backend will exclude certain billing methods from the logged in account.
   * For example, users that have an active YU Mobile subscription are only allowed to use credit card payments
   * Note: only available to MAIN users
   */
  allowedBillingMethods: Maybe<AllowedBillingMethodsResponse>;
  autoSuggest: Array<Scalars['String']['output']>;
  /** Auto-complete suggestions */
  beemiAutoCompleteSearch: Maybe<AutoCompleteResponse>;
  beemiInfoList: Array<BeemiInfoListItem>;
  /** Full search */
  beemiSearch: Maybe<TextSearchResponse>;
  bookCategoryList: Array<BookCategoryListResponse>;
  /** set valid volumeGroupType to use v2 api */
  bookHistory: Maybe<BookHistoryResponse>;
  bookPersonList: Array<BookPersonListResponse>;
  bookRanking: BookRankingSakuhins;
  bookReadMore: Maybe<BookReadMoreResponse>;
  /**
   * Fetches a book search category list block only, which can also be fetched by the book recommend blocks query.
   * In case of portal-error, errorCode can be found under gql-error with message 'BOOK_SEARCH_CATEGORY_BLOCK_ERROR'
   */
  bookSearchCategoryBlock: Maybe<BookSearchCategoryBlock>;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_TITLE_ERROR' */
  bookTitle: BookSakuhin;
  bookTitle_books: Maybe<BooksResponse>;
  bookTitle_postPlay: BookPostPlayResult;
  bookTitle_recommendedBooks: Maybe<BookTitleRecommendedBooksResponse>;
  bookTitle_relatedBooks: Maybe<RelatedBooksResponse>;
  bookTitle_relatedTitles: Maybe<RelatedVideosResponse>;
  booksRecommend: BooksRecommendResponse;
  /** Get calendar events by date range and optionally an array of tags */
  calendarEvents: Array<CalendarEvent>;
  /** Indicates if the calendar should be visible, what tags can be used to search for events and which text will be displayed */
  calendarFeatureSettings: CalendarFeatureSettingsResponse;
  /** Checks if the current user can create a new sub-user using the given invite token */
  canCreateUnextUserWithToken: UnextUserWithTokenVerificationResponse;
  /**
   * Performs a series of checks to determine if a user can be terminated from the Unext Platform
   *
   * The response will be cached for 1 hour. cancelAndPauseContractedProducts mutation will invalidate the cache.
   */
  canUnextAccountBeTerminated: Scalars['Boolean']['output'];
  /** Returns a boolean to indicate whether the terminateUser mutation can be executed */
  canUserBeTerminated: Maybe<Scalars['Boolean']['output']>;
  /**
   * Validate the security code
   * Note: only available to MAIN users
   *
   * The response will be cached for 24 hours. updateSecurityCode or resetSecurityCode mutation will invalidate the cache.
   */
  checkSecurityCode: Scalars['Boolean']['output'];
  /** Checks upsell eligibility for different purchase agent, web/apple ios/google android etc. */
  checkUpsellEligibility: CheckUpsellEligibilityResponse;
  /** Get a list of cinema companies */
  cinemaCompanies: CinemaCompanyResponse;
  /** Gets the full Collection/Series object */
  collection: Maybe<Collection>;
  /** Gets the full content block with pagination */
  contentBlockFull: Maybe<ContentBlock>;
  /**
   * Retrieves the current point balance for the given type.
   *
   * This response won't be cached because there is no way to invalidate it by far.
   */
  currentPointBalance: PointBalance;
  /** Checks a user's eligibility for device purchases. If a purchasableConfigId is specified, the inventory of that configuration is also checked. Otherwise, it will only check the user's eligibility */
  devicePurchaseEligibilityCheck: DevicePurchaseCheckResponse;
  /**
   * Retrieves the summary of bills for the last N months.
   *
   * The response will be cached for 1 minute in case user query different nMonths back and forth.
   */
  displayBillsSummary: Array<Maybe<DisplayBillsSummary>>;
  familyAccountInviteToken: Maybe<FamilyAccountInviteToken>;
  /**
   * Retrieves the mobile service account tied to the currently logged in U-NEXT user
   * @deprecated Use getYuMobileAccount instead
   */
  getAccount: Maybe<Account>;
  /** Gets the account data usage info */
  getAccountDataUsageInfo: Maybe<AccountDataUsageInfo>;
  /** I don't know what this query is supposed to do... */
  getAdditionalQuestion: Maybe<AdditionalQuestionsResponse>;
  /** Retrieves a single mobile service application tied to a mobile service account */
  getApplication: Maybe<Application>;
  /** Retrieves a list of service charges that will be applied when applying for a Sim Card */
  getApplicationFee: Maybe<Array<Maybe<Fee>>>;
  /** Retrieves all applications that are tied to a mobile service account */
  getApplications: Maybe<Array<Maybe<Application>>>;
  /**
   * Retrieves the plans that a user can switch to based on their current purchased plan, sim status, etc. Please note that this is different from getPlan/getPlans
   * @deprecated Use getAvailableYuMobilePlans instead
   */
  getAvailablePlans: Maybe<AvailablePlansResponse>;
  /** Retrieves the plans that a user can switch to based on their current purchased plan, sim status, etc. Please note that this is different from getYuMobilePlan/getYuMobilePlans */
  getAvailableYuMobilePlans: Maybe<AvailableYuMobilePlansResponse>;
  /** Retrieves the billing details (請求金額) from the last n months */
  getBillingReport: Maybe<Array<Maybe<BillingReport>>>;
  /** @deprecated Renamed to bookHistory */
  getBookHistory: Maybe<BookHistoryResponse>;
  getBookTopBlocks: Maybe<BookTopBlockResponse>;
  getBookTopLeanback: Maybe<BookTopLeanbackResponse>;
  getCampaign: Maybe<Campaign>;
  getCampaigns: Maybe<CampaignsPaginatedResponse>;
  /** @deprecated Use getSakuhinGroupBlock or getSakuhinGroup instead */
  getCharacterBlock: Array<SakuhinGroup>;
  getCommonProducts: Array<Product>;
  getCurrentAppVersion: Maybe<AppVersion>;
  /** Gets a list of customer inquiry categories */
  getCustomerInquiryCategories: Maybe<Array<CustomerInquiryCategory>>;
  /** Gets data charge user for a given user */
  getDataChargeHistory: Maybe<DataChargeHistory>;
  /** Retrieves all data charge products that can be purchased by a U-NEXT user for a mobile service account */
  getDataChargeProducts: Maybe<Array<Maybe<DataChargeProduct>>>;
  /** Retrieves the Data Usage History for a mobile service account */
  getDataUsageHistory: Maybe<Array<Maybe<DataUsageHistoryEntry>>>;
  /** Gets an individual device purchase */
  getDevicePurchase: Maybe<DevicePurchaseResponse>;
  /** Gets a user's entire purchase history */
  getDevicePurchaseHistory: Maybe<Array<DevicePurchaseResponse>>;
  /** Retrieves the U-NEXT url required to issue new Escott tokens */
  getEScottTokenUrl: Maybe<EScottResponse>;
  getEmergencyNotification: Maybe<EmergencyNotification>;
  getEmergencyNotifications: Maybe<EmergencyNotificationsPaginatedResponse>;
  /** @deprecated Renamed to webfront_episodeCredits */
  getEpisodeCredits: Maybe<EpisodeCreditResponse>;
  /** Checks the validity of the provided escott token */
  getEscottTokenStatus: Maybe<EScottTokenStatusResponse>;
  /** @deprecated Renamed to webfront_favoriteTitles */
  getFavoriteTitles: Maybe<FavoriteTitlesResponse>;
  /** @deprecated Renamed to webfront_feature */
  getFeature: Maybe<Feature>;
  /** Get Info for the currently logged in store */
  getInfo: Maybe<Store>;
  /** @deprecated Renamed to webfront_leanbackGenres */
  getLeanbackGenres: Array<LeanbackGenreGroup>;
  /** @deprecated Renamed to webfront_linearChannels */
  getLinearChannels: Array<Channel>;
  /** @deprecated Renamed to webfront_liveList */
  getLiveList: Array<Live>;
  /** Retrieves the promotional information for a given landing page code */
  getLpData: Maybe<LpData>;
  /** Gets the contract details for the customer's mobile account */
  getMobileServiceContract: Maybe<MobileServiceContract>;
  /** Retrieves a list of service contracts that have been signed for the logged-in customer's mobile service account */
  getMobileServiceContractHistory: Maybe<
    Array<Maybe<MobileServiceContractBase>>
  >;
  /**
   * Retrieves a single option that can be purchased by a U-NEXT user for a mobile service account
   * @deprecated Use getYuMobileOption instead
   */
  getOption: Maybe<Option>;
  /**
   * Retrieves all options that can be purchased by a U-NEXT user for a mobile service account
   * @deprecated Use getYuMobileOptions instead
   */
  getOptions: Maybe<Array<Maybe<Option>>>;
  getPartner: Maybe<Partner>;
  getPartners: Maybe<PartnersPaginatedResponse>;
  /**
   * Retrieves a single plan that can be purchased by a U-NEXT user for a mobile service account
   * @deprecated Use getYuMobilePlan instead
   */
  getPlan: Maybe<Plan>;
  /**
   * Retrieves all plans that can be purchased by a NEW U-NEXT user for a mobile service account
   * @deprecated Use getYuMobilePlans instead
   */
  getPlans: Maybe<Array<Maybe<Plan>>>;
  /** @deprecated Renamed to platformInfo */
  getPlatformInfo: PlatformInfo;
  /**
   * Gets a purchaseable device configuration from our partners. Returns GraphQLError if not found.
   * In the GQL schema, a device refers to the specific physical device. A PurchasableModel is a phone model that's on sale, e.g. iPhone 11 Pro, Pixel 5, etc.
   * A PurchasableConfiguration is an variant of that model that can be purchased, e.g. iPhone 12 Pro 256gb.
   */
  getPurchasableDeviceConfig: PurchasableConfig;
  getPurchasableModel: PurchasableModel;
  /** Gets a list of purchaseable devices from our partners */
  getPurchasableModels: Maybe<Array<PurchasableModel>>;
  /** Get monthly purchase list */
  getPurchaseReport: Maybe<Array<Maybe<ProductPurchase>>>;
  /** @deprecated Renamed to webfront_recommendBlocks */
  getRecommendBlocks: Maybe<RecommendBlockResponse>;
  /** Gets remaining data capacity for this period in megabytes */
  getRemainingDataCapacity: Maybe<Scalars['Int']['output']>;
  getSakuhinGroup: Maybe<SakuhinGroup>;
  getSakuhinGroupBlock: Array<SakuhinGroup>;
  /** @deprecated Renamed to webfront_searchGenres */
  getSearchGenres: Array<SearchGenreGroup>;
  getSettingMenu: SettingMenuResponse;
  /** Retrieves a single sim option that can be purchased by a sim user */
  getSimOption: Maybe<SimOption>;
  /** Retrieves all sim options that can be purchased by a sim user */
  getSimOptions: Maybe<Array<Maybe<SimOption>>>;
  /** Retrieves a single sim type that can be purchased by a sim user */
  getSimType: Maybe<SimType>;
  /** Retrieves all sim types that can be purchased by a sim user */
  getSimTypes: Maybe<Array<Maybe<SimType>>>;
  /** Gets a consent token of the specified sim user application. This token can be used by the sim user later on for consent. This is usually called by the contractor. */
  getSimUserApplicationConsentToken: Maybe<GetSimUserApplicationConsentTokenResponse>;
  /** @deprecated Renamed to spotlight */
  getSpotlight: Array<Spotlight>;
  getStore: Maybe<Store>;
  getStoreGroup: Maybe<StoreGroup>;
  getStoreGroups: Maybe<StoreGroupsPaginatedResponse>;
  getStores: Maybe<StoresPaginatedResponse>;
  /** Retrieves a single temporary application that was created by the specified store account */
  getTempApplication: Maybe<TempApplication>;
  /** Retrieves a list of service charges that will be applied when creating a Temp Application as part of the OTA application flow */
  getTempApplicationFee: Maybe<Array<Maybe<Fee>>>;
  /** Retrieves all temporary applications that were created by the specified store account */
  getTempApplications: Maybe<PaginatedTempApplications>;
  /**
   * In case of portal-error, errorCode can be found under gql-error with message 'VIDEO_TITLE_ERROR'
   * @deprecated Renamed to webfront_title
   */
  getTitle: Maybe<Title>;
  getUpsellDisplayState: UpsellDisplayState;
  getUpsellGuide: UpsellGuide;
  /**
   * Gets the user info for the currently logged in U-NEXT user. Do not confuse this with getAccount, which retrieves the mobile service account.
   * @deprecated Use unextAccount instead
   */
  getUser: Maybe<User>;
  /** @deprecated Renamed to userInfo */
  getUserInfo: Maybe<UserInfo>;
  /** @deprecated Renamed to webfront_userRecommendFeature */
  getUserRecommendFeature: Maybe<Feature>;
  /**
   * Gets list of services that a user is signed up for. This includes RunningServices from BOS and other legacy services that are administered via the CommonInfrastructure.
   * Note: Only runningTypeCodes that are solely administered by BOS are returned
   */
  getUserUnextServices: Maybe<Array<Maybe<UnextService>>>;
  /** Gets list of services that a user is signed up for (any platform). and other legacy services that are administered via the CommonInfrastructure. */
  getUserUnextServicesV2: Maybe<Array<Maybe<UnextService>>>;
  /** Retrieves a single voice plan that can be purchased by a sim user using a voice sim */
  getVoicePlan: Maybe<VoicePlan>;
  /** Retrieves all voice plans that can be purchased by a sim user using a voice sim */
  getVoicePlans: Maybe<Array<Maybe<VoicePlan>>>;
  /**
   * episodeSize: number of episodes to be requested
   * @deprecated Renamed to webfront_watchingEpisodes
   */
  getWatchingEpisodes: Maybe<Array<Episode>>;
  /** Retrieves the mobile service account tied to the currently logged in U-NEXT user */
  getYuMobileAccount: Maybe<YuMobileAccount>;
  /** Retrieves a single option that can be purchased by a U-NEXT user for a mobile service account */
  getYuMobileOption: Maybe<YuMobileOption>;
  /** Retrieves all options that can be purchased by a U-NEXT user for a mobile service account */
  getYuMobileOptions: Maybe<Array<Maybe<YuMobileOption>>>;
  /** Retrieves a single plan that can be purchased by a U-NEXT user for a mobile service account */
  getYuMobilePlan: Maybe<YuMobilePlan>;
  /** Retrieves all plans that can be purchased by a NEW U-NEXT user for a mobile service account */
  getYuMobilePlans: Maybe<Array<Maybe<YuMobilePlan>>>;
  /** Returns information about a giftcode, if it's valid */
  giftCodeDetails: GiftCodeDetailsResponse;
  /** Query inbox messages by id */
  inboxMessage: Maybe<InboxMessage>;
  /** Queries all inbox messages available to the current user with the given classification */
  inboxMessages: Array<InboxMessage>;
  /**
   * Create a signed token encapsulating the user's platformId and platformCode
   * so that they may create a multiaccount user through LINE statelessly.
   *
   * The response will be cached for 6 hours.
   */
  inviteToken: Scalars['String']['output'];
  isAeon: Scalars['Boolean']['output'];
  /**
   * Label detail for a given label code
   * Corresponding to a Portal API: /1/cmscommon/label/detail
   */
  label: Label;
  /**
   * Label block for home or video-top
   * Corresponding to a Portal API: /1/cmscommon/label
   */
  labelBlock: LabelBlock;
  leagueBlock: Maybe<LeagueBlock>;
  leagueDetail: Maybe<LeagueDetail>;
  /** @deprecated Insufficient for paging. Use liveBlocks */
  liveBlock: Array<LiveBlock>;
  liveBlockDetail: LiveBlockDetailResponse;
  liveBlocks: LiveBlocksResponse;
  liveCategorySearch: LivePagingResponse;
  liveFreewordSearch: LivePagingResponse;
  liveGroup: Maybe<LiveGroupResponse>;
  /**
   * Live Playlist URL query for clients which support time-shift playback.
   * For clients which do not support time-shift, use webfront_livePlaylistUrlV2 instead.
   */
  livePlaylistUrl: LivePlaylistUrlResult;
  /**
   * Live Playlist validation query for clients which support time-shift playback.
   * For clients which do not support time-shift, use webfront_livePlaylistValidation instead.
   */
  livePlaylistValidation: LivePlaylistValidationResult;
  liveProductList: LiveProductList;
  liveRelatedLives: LivePagingResponse;
  liveRelatedVideos: RelatedVideosResponse;
  /** Poll error will be in error.extensions.loginDelegationError object with field 'code' and 'message' */
  loginDelegationPollSession: Maybe<LoginDelegationPollResponse>;
  /** Validation error will be in error.extensions.loginDelegationError object with field 'code' and 'message' */
  loginDelegationValidateSession: Maybe<LoginDelegationSessionDeviceInfo>;
  /**
   * Gets an array of addresses that are assigned to a postal code.
   * Supports 3 digit and 7 digit postal codes
   */
  lookupPostalCode: Maybe<Array<Maybe<PostalCodeAddress>>>;
  /** Gets the full media object */
  media: Maybe<Media>;
  /** Gets the full media chapter object */
  mediaChapter: Maybe<MediaChapter>;
  multiAccountUsersInfo: Array<MultiAccountUserInfo>;
  /** Gets Chapters added to the users' My List */
  myListChapters: Array<MediaChapterLight>;
  /** Gets packages added to the user's My List */
  myListPackages: Array<MediaLight>;
  /** Gets a paginated list of the top 100 newest chapters */
  newChaptersV2: PaginatedMediaChapterLight;
  /**
   * newMediaRankingBlock returns Top 100 media in the planet with pagination ranked by
   * platform release date DESCENDING.
   */
  newMediaRankingBlock: Maybe<NewMediaRankingBlock>;
  originalBooks: BookOriginalTitlesResponse;
  originalComic: BookOriginalTitlesResponse;
  /**
   * Probably a feature used in movie theater qr tickets.
   * It is a query to check if the couponID held by the user and the hashcode obtained from qr match.
   */
  ownedCinemaCouponQR: OwnedCinemaCouponQREdition;
  /**
   * Fetch owned issued tickets. Returns only tickets with matching ticket code if ticket code is supplied.
   * Max result required to specify the maximum number of tickets to return
   */
  ownedCinemaCoupons: CinemaCoupon;
  /**
   * Get detailed information about food coupon from owned food coupon ID
   * hash_code or platform_code, pfid is required
   */
  ownedFoodCinemaCoupon: FoodTicket;
  /** Fetch food tickets */
  ownedFoodTickets: Maybe<Array<FoodTicket>>;
  parentalLock: Scalars['Boolean']['output'];
  personLives: LivePagingResponse;
  /** Get paginated Pickup Content blocks for a planet */
  pickupBlockV2: PaginatedContentBlocks;
  /** Gets all content planets currently available */
  planets: Array<Planet>;
  platformInfo: PlatformInfo;
  /** Acquire the required playback tokens required to start playback */
  playBackTokens: Maybe<PlaybackTokenResponse>;
  /** Returns the list of PointCharge products */
  pointChargeProducts: PointChargeProductsResponse;
  /** Returns the status of the point charge operation */
  pointChargeStatusFor3DSecure: PointChargeStatusResponse;
  /**
   * Lists the amount of points that are scheduled to be granted to the user and when.
   *
   * This response will be cached for 1 minute.
   */
  pointGrantSchedule: Array<Maybe<PointGrantScheduleItem>>;
  /**
   * Point Usage History for the logged in account, only available to MAIN user
   *
   * This response will be cached for 1 minute.
   */
  pointHistory: PointHistorySummary;
  /** Gets a paginated list of the top 100 popular chapters */
  popularChaptersV2: PaginatedMediaChapterLight;
  /** Get popular content tags */
  popularContentTagsV2: PaginatedContentTags;
  /** popularityRankingBlock return Top 100 media in the planet with pagination ranked by popularity DESCENDING. */
  popularityRankingBlock: Maybe<PopularityRankingBlock>;
  /**
   * Gets an array of addresses that are assigned to a postal code.
   * Supports 3 digit and 7 digit postal codes.
   *
   * Response is cached for 1 month.
   */
  postalCode: Array<PostalCodeAddress>;
  /** For OTA applications. Carries out pre-checks on user to see if this user can submit a new temporary application. Returns true if user credential validation passes, and false if the validation fails. */
  precheckUserForApplication: Maybe<UserPrecheckResponse>;
  /** Gets all of the products that can be purchased for to give access to liva/media */
  productsForVideoContent: ProductsForVideoContentResponse;
  /**
   * Purchase History for the logged in account, only available to MAIN user
   * The input, purchaseMonth is in ISO format(UTC) but only its year and month will be used
   * So yyyy-MM or yyyyMM would be enough, e.g. 2019-01 or 201901
   *
   * This response will be cached for 1 minute in case user select different month back and forth.
   */
  purchaseHistory: PurchaseHistoryResponse;
  purchasedLives: LivePagingResponse;
  /** Get the list of purchased media */
  purchasedMedia: PaginatedMediaLight;
  queryRegisteredDevices: Array<BookDeviceRegistrationInfo>;
  /**
   * Gets the first N items of each block recommended by the data science recommendation system (starship).
   * See type for more details. Use getContentBlockFull for paginated block.
   */
  recommendedBlocksV2: PaginatedContentBlocks;
  /** Gets a paginated list of the top 100 recommended chapters for the given user */
  recommendedChaptersV2: PaginatedMediaChapterLight;
  /**
   * Query what kind of verification method a user should use. If they have an email registered,
   * recommend email. Otherwise, recommend SMS.
   *
   * This response will be cached for 1 hour or until user validate their email or phone number.
   */
  recommendedVerificationMethod: RecommendedVerificationMethodResponse;
  registerDevice: BookDeviceRegistrationInfo;
  /** revenueRankingBlock return Top 100 media in the planet with pagination ranked by revenue DESCENDING */
  revenueRankingBlock: Maybe<RevenueRankingBlock>;
  /** Returns the order status of SBPS v1 by order id */
  sbpsV1OrderStatus: SbpsV1OrderStatusResponse;
  /** Get the status of an active SBPS v1 payment */
  sbpsV1PurchaseOrderStatus: SbpsV1PurchaseOrderStatusResponse;
  /**
   * Retrieves the status of an SBPS V2 Billing Method Registration Request that was created with the
   * createSbpsV2BillingMethodRegistrationRequest mutation
   */
  sbpsV2BillingMethodRegistrationRequestStatus: SbpsV2BillingMethodRegistrationStatusResponse;
  sendMail: SendMailResponse;
  /** Get basic information by node Xid, returns: Xid, Node name in Japanese and ContentType */
  simpleNodeLookup: Maybe<SimpleNodeLookupResponse>;
  spotlight: Array<Spotlight>;
  /** Gets 4 spotlighted suggestions for search top page */
  spotlightedSuggestions: Array<SpotlightedSuggestion>;
  subscriptionProductList: SubscriptionProductList;
  /** Get timespans by tag */
  timespans: Array<Timespan>;
  titleBlockDetail: Maybe<TitleBlockDetailResponse>;
  unRegisterDevice: Array<BookDeviceRegistrationInfo>;
  /**
   * Retrieve the account of the current logged in user.
   * Note: some fields are only availble if the current user is the MAIN user of the acount
   *
   * This response will be cached for 1 hour. updateAccountCustomerInfo mutation will invalidate the cache.
   */
  unextAccount: UnextAccount;
  /**
   * Retrieves a complete summary o Gets a list of active subscriptions tied to the account of the current logged in user.
   * Note: only available to MAIN users
   *
   * This response will be cached for 1 hour. cancelAndPauseContractedProducts mutation will invalidate the cache.
   */
  unextAccountContractSummary: UnextAccountContractSummary;
  /** Returns the deposit info for a unext addon subscription */
  unextSubscriptionDepositInfo: UnextSubscriptionDepositInfoResponse;
  /** Retrieve a singular unext subscription product by its id */
  unextSubscriptionProduct: Maybe<UnextSubscriptionProduct>;
  /**
   * Retrieve the current logged in user
   *
   * This response will be cached for 1 hour or until user update their infos.
   */
  unextUser: UnextUser;
  /**
   * universalNewMediaRankingBlock returns Top 100 media in the universe with pagination ranked by
   * platform release date DESCENDING.
   */
  universalNewMediaRankingBlock: Maybe<NewMediaRankingBlock>;
  /**
   * universalPopularityRankingBlock return Top 100 media in the universe with pagination ranked by
   * popularity DESCENDING.
   */
  universalPopularityRankingBlock: Maybe<PopularityRankingBlock>;
  /** universalRevenueRankingBlock return Top 100 media in the universe with pagination ranked by revenue DESCENDING */
  universalRevenueRankingBlock: Maybe<RevenueRankingBlock>;
  /** Prepares the user to execute the upsell */
  upsellDetails: UpsellDetailsResponse;
  /** Gets the upsell landing page code for the current user depending on arguments */
  upsellLandingPageCode: UpsellLandingPageCodeResponse;
  /** Gets the upsell landing page status */
  upsellLandingPageStatus: UpsellLandingPageStatusResponse;
  userCard: UserCard;
  /** For OTA applications, Used to check if a loginId/email already exists in our user store when signing up brand new users to both a U-NEXT user account and a mobile service account. Returns true if the email is not a duplicate and false if the user is a duplicate. (Requires Yamada-Store Authorization) */
  userDuplicateCheck: Maybe<UserDuplicateCheckResponse>;
  userInfo: Maybe<UserInfo>;
  /** Returns the currently known customer information required for 3DSecure flow */
  userInfoFor3DSecure: UserInfoFor3DSecureResponse;
  userPointHistory: Array<Maybe<PointHistoryModel>>;
  userPointbackSchedule: Array<PointbackSchedule>;
  /**
   * Used to validate a email verification link sent to new users as part of the email verification process
   * (DEPRECATED)
   * @deprecated No longer supported
   */
  validateEmail: Maybe<EmailVerificationResponse>;
  /** Validates the confirmation code sent in the forgotten password email. */
  validateEmailCodeForForgottenPassword: ValidateEmailCodeForForgottenPasswordResponse;
  /** Used to validate a email verification code sent to new users as part of the email verification process */
  validateEmailVerificationCode: Maybe<EmailVerificationResponse>;
  /** Validates a password candidate with U-next safe-password rules */
  validatePassword: ValidatePasswordResponse;
  /** Checks if the provided password is correct for features requiring password re-entry but not relogin */
  validateStorePassword: Maybe<Scalars['Boolean']['output']>;
  /** Verifies if the provided sim serial nos (up to 4) are valid. (Requires Yamada OAuth) */
  verifySimSerialNos: Maybe<Array<Maybe<Scalars['Boolean']['output']>>>;
  /** Verifies if a consent token is valid. */
  verifySimUserApplicationConsentToken: Maybe<VerifySimUserApplicationConsentTokenResponse>;
  /** Verifies if the provided temp application registration numbers are valid */
  verifyTempApplicationRegistrationNos: Maybe<
    Array<Maybe<Scalars['Boolean']['output']>>
  >;
  videoRecommendBlocks: Maybe<VideoRecommendBlockResponse>;
  webfront_bookCategory: BookCategoryResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_FEATURES_ERROR' */
  webfront_bookFeatures: BookFeatureResponse;
  webfront_bookFreewordSearch: BookFreewordSearchResponse;
  webfront_bookMetaSearch: Maybe<BookMetaSearchResponse>;
  webfront_bookPerson: BookPersonResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_PLAYLIST_URL_ERROR' */
  webfront_bookPlaylistUrl: BookPlaylistUrlResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_PRODUCT_LIST_ERROR' */
  webfront_bookProductList: BookProductList;
  /** @deprecated use bookRanking */
  webfront_bookRanking: BookRankingResponse;
  webfront_bookRecommendBlocks: BookRecommendBlockResponse;
  webfront_bookUserRecommended: Maybe<BookUserRecommendedResponse>;
  webfront_episodeCredits: Maybe<EpisodeCreditResponse>;
  webfront_favoriteBooks: FavoriteBooksResponse;
  webfront_favoriteTitles: Maybe<FavoriteTitlesResponse>;
  webfront_feature: Maybe<Feature>;
  webfront_featureTemplate: FeatureTemplateResponse;
  webfront_freeBooks: BookFreeResponse;
  webfront_getLinearChannel: Channel;
  /** In case of portal-error, errorCode can be found under gql-error with message 'LIVE_DETAIL_ERROR'. */
  webfront_getLive: Live;
  /** @deprecated use liveRelatedLives with paging info included. */
  webfront_getLive_relatedLives: Maybe<Array<Live>>;
  /** @deprecated use liveRelatedVideos with paging info included. */
  webfront_getLive_relatedVideos: Maybe<Array<Title>>;
  /**
   * menuCode must be a genre menu code. Sub menu codes are not accepted and will return
   * nothing.
   * @deprecated Please use webfront_getSingleSearchGenre for querying details of one single searchGenre. This query is not very friendly for caching so we decide to deprecate it.
   */
  webfront_getSearchGenre: SearchGenreGroup;
  webfront_getSingleSearchGenre: SearchGenre;
  webfront_historyTitles: HistoryTitlesResponse;
  webfront_infoList: Array<InfoListItem>;
  webfront_leadTitle: LeadTitle;
  webfront_leanbackGenres: Array<LeanbackGenreGroup>;
  webfront_linearChannels: Array<Channel>;
  webfront_linearPlaylistUrl: Maybe<LivePlaylistUrl>;
  /** @deprecated use liveCategorySearch */
  webfront_liveCategorySearch: Array<Live>;
  /** @deprecated use liveFreewordSearch */
  webfront_liveFreewordSearch: Array<Live>;
  webfront_liveList: Array<Live>;
  /**
   * Live Playlist URL query for clients which do NOT support time-shift playback.
   * After live delivery ends, this returns 1101 error and therefore time-shift URLs can not be retrieved.
   * For clients which support time-shift, use livePlaylistUrl instead.
   */
  webfront_livePlaylistUrlV2: Maybe<LivePlaylistUrlV2>;
  /**
   * Live Playlist validation query for clients which do NOT support time-shift playback.
   * After live delivery ends, this returns 1103 error and therefore it can not check validation.
   * For clients which support time-shift, use livePlaylistValidation instead.
   */
  webfront_livePlaylistValidation: LivePlaylistValidationResult;
  /** @deprecated Use liveProductList instead */
  webfront_liveTicketList: Array<PurchaseLiveTicket>;
  webfront_magazines: MagazinesResponse;
  webfront_newBooks: BookNewArrivalsResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'ONE_TIME_TOKEN_ERROR' */
  webfront_oneTimeToken: Maybe<OneTimeTokenResponse>;
  /** @deprecated Use originalBooks */
  webfront_originalBooks: BookOriginalTitlesResponse;
  webfront_person: Maybe<PersonWebfront>;
  webfront_personFreewordSearch: PersonFreewordSearchResponse;
  /** @deprecated use personLives */
  webfront_personLiveList: Array<Live>;
  webfront_playlistUrl: Maybe<PlaylistUrl>;
  webfront_pointBack: PointBackResponse;
  webfront_postPlay: Maybe<PostPlayResult>;
  webfront_preorderableBooks: BookPreorderableResponse;
  /** set valid volumeGroupType to use v2 api */
  webfront_purchasedBooks: PurchasedBooksResponse;
  /** @deprecated use purchasedLives */
  webfront_purchasedLiveList: Array<Live>;
  webfront_purchasedTitles: PurchasedTitlesResponse;
  webfront_recommendBlocks: Maybe<RecommendBlockResponse>;
  webfront_searchGenres: Array<SearchGenreGroup>;
  webfront_searchVideo: VideoSearchResponse;
  webfront_seriesRelatedTitles: Array<TitleRelationGroup>;
  webfront_targetDevice: Maybe<TargetDeviceResponse>;
  /** @deprecated Changed to webfront_title_stage */
  webfront_title: Maybe<Title>;
  webfront_title_credits: Maybe<TitleCreditResponse>;
  /** @deprecated Changed to webfront_title_titleEpisodes */
  webfront_title_episodes: Array<Episode>;
  webfront_title_recommendedTitles: Maybe<RecommendedTitlesResponse>;
  webfront_title_relatedBooks: Maybe<RelatedBooksResponse>;
  webfront_title_relatedTitles: Array<TitleRelationGroup>;
  webfront_title_stage: Maybe<TitleStage>;
  webfront_title_titleEpisodes: Maybe<TitleEpisodes>;
  webfront_upsell: Upsell;
  /** Returned [Feature] will not return typeCode, commentDisplayFlag and titleComments */
  webfront_userRecommendFeature: Maybe<Feature>;
  webfront_videoFreewordSearch: VideoFreewordSearchResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'VIDEO_PRODUCT_LIST_ERROR' */
  webfront_videoProductList: Maybe<VideoProductList>;
  webfront_videoRanking: VideoRankingResponse;
  webfront_watchingEpisodes: Maybe<Array<Episode>>;
};

export type QueryactiveSaleBlocksV2Args = {
  itemsPerBlock?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId: Scalars['ID']['input'];
};

export type QueryaliasArgs = {
  aliasId: Scalars['ID']['input'];
};

export type QueryautoSuggestArgs = {
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  suggesterType: SuggesterType;
};

export type QuerybeemiAutoCompleteSearchArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
  query: Scalars['String']['input'];
};

export type QuerybeemiInfoListArgs = {
  isCheck?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerybeemiSearchArgs = {
  nodeFilter?: InputMaybe<Array<NodeFilterInput>>;
  pagination?: InputMaybe<ContentPaginationInput>;
  query: Scalars['String']['input'];
  ratingCode: RatingCodeInput;
  searchFilters?: InputMaybe<SearchFilterInput>;
  searchSorters?: InputMaybe<Array<SearchSorterInput>>;
  searchType: SearchTypeInput;
};

export type QuerybookCategoryListArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  menuCodeList: Array<Scalars['ID']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sortOrder: PortalSortOrder;
};

export type QuerybookHistoryArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchWord?: InputMaybe<Scalars['String']['input']>;
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
};

export type QuerybookPersonListArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  onlyChapter?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  personCodeList: Array<Scalars['ID']['input']>;
  sortOrder: PortalSortOrder;
};

export type QuerybookRankingArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
  targetCode: Scalars['String']['input'];
};

export type QuerybookReadMoreArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchWord?: InputMaybe<Scalars['String']['input']>;
  volumeGroupType: BookVolumeGroupTypeCode;
};

export type QuerybookSearchCategoryBlockArgs = {
  genreId: Scalars['ID']['input'];
};

export type QuerybookTitleArgs = {
  bookCode?: InputMaybe<Scalars['ID']['input']>;
  bookListChapterPageSize?: InputMaybe<Scalars['Int']['input']>;
  bookListPageSize?: InputMaybe<Scalars['Int']['input']>;
  bookSakuhinCode: Scalars['ID']['input'];
  featurePieceCode?: InputMaybe<Scalars['String']['input']>;
  viewBookCode?: InputMaybe<Scalars['String']['input']>;
};

export type QuerybookTitle_booksArgs = {
  code: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerybookTitle_postPlayArgs = {
  bookCode?: InputMaybe<Scalars['ID']['input']>;
  includePreorderableFlg?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerybookTitle_recommendedBooksArgs = {
  bookSakuhinCode: Scalars['ID']['input'];
  includePreorderableFlg?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerybookTitle_relatedBooksArgs = {
  code: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerybookTitle_relatedTitlesArgs = {
  code: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerybooksRecommendArgs = {
  bookCode: Scalars['ID']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerycalendarEventsArgs = {
  from: Scalars['DateTime']['input'];
  tags?: InputMaybe<Array<CalendarSearchTagInput>>;
  to: Scalars['DateTime']['input'];
};

export type QuerycalendarFeatureSettingsArgs = {
  menuItem: CalendarMenuInput;
};

export type QuerycanCreateUnextUserWithTokenArgs = {
  token: Scalars['String']['input'];
};

export type QuerycheckSecurityCodeArgs = {
  securityCode: Scalars['String']['input'];
};

export type QuerycheckUpsellEligibilityArgs = {
  amazonAppUserId?: InputMaybe<Scalars['String']['input']>;
  amazonDeviceType?: InputMaybe<AmazonDeviceTypeInput>;
  appReceipt?: InputMaybe<Scalars['String']['input']>;
  appStoreProductId?: InputMaybe<Scalars['String']['input']>;
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  subscriptionType: SubscriptionTypeInput;
};

export type QuerycollectionArgs = {
  collectionId: Scalars['ID']['input'];
};

export type QuerycontentBlockFullArgs = {
  blockId: Scalars['ID']['input'];
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycurrentPointBalanceArgs = {
  type: PointClassInput;
};

export type QuerydevicePurchaseEligibilityCheckArgs = {
  purchasableConfigId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerydisplayBillsSummaryArgs = {
  nMonths: Scalars['Int']['input'];
};

export type QuerygetAdditionalQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetApplicationArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetApplicationFeeArgs = {
  entryCode?: InputMaybe<Scalars['String']['input']>;
  hasEntryCode?: InputMaybe<Scalars['Boolean']['input']>;
  simType: SimTypeEnum;
};

export type QuerygetApplicationsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetBillingReportArgs = {
  nMonths: Scalars['Int']['input'];
};

export type QuerygetBookHistoryArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchWord?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetBookTopBlocksArgs = {
  bookNumber?: InputMaybe<Scalars['Int']['input']>;
  includePreoderFlag?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetBookTopLeanbackArgs = {
  includeKids?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetCampaignArgs = {
  code: Scalars['ID']['input'];
};

export type QuerygetCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerygetCharacterBlockArgs = {
  genreCode: Scalars['String']['input'];
  sakuhinGroupCode?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetCommonProductsArgs = {
  planCode?: InputMaybe<Scalars['String']['input']>;
  productTypeCode: ProductTypeCode;
};

export type QuerygetCustomerInquiryCategoriesArgs = {
  formType: Scalars['String']['input'];
};

export type QuerygetDataChargeHistoryArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  pageSpecifier?: InputMaybe<PageSpecifier>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerygetDataUsageHistoryArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  groupByMonth?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerygetDevicePurchaseArgs = {
  devicePurchaseId: Scalars['ID']['input'];
};

export type QuerygetEmergencyNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetEmergencyNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerygetEpisodeCreditsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  titleId: Scalars['ID']['input'];
};

export type QuerygetEscottTokenStatusArgs = {
  entryParams?: InputMaybe<WebEntryParams>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type QuerygetFavoriteTitlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
};

export type QuerygetFeatureArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetLinearChannelsArgs = {
  genreId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetLiveListArgs = {
  genreId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetLpDataArgs = {
  landingPageCode: Scalars['String']['input'];
};

export type QuerygetMobileServiceContractArgs = {
  contractDocumentId: Scalars['ID']['input'];
  customerContractDocumentId: Scalars['ID']['input'];
  planContractDocumentId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetOptionArgs = {
  optionID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetPartnerArgs = {
  code: Scalars['ID']['input'];
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetPlanArgs = {
  planID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetPurchasableDeviceConfigArgs = {
  purchasableConfigId: Scalars['ID']['input'];
};

export type QuerygetPurchasableModelArgs = {
  purchasableModelId: Scalars['ID']['input'];
};

export type QuerygetPurchaseReportArgs = {
  purchaseMonth: Scalars['DateTime']['input'];
};

export type QuerygetRecommendBlocksArgs = {
  extensions?: InputMaybe<Scalars['String']['input']>;
  genreId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sakuhinsPerFeature?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetSakuhinGroupArgs = {
  sakuhinGroupCode: Scalars['ID']['input'];
};

export type QuerygetSakuhinGroupBlockArgs = {
  genreCode: Scalars['ID']['input'];
};

export type QuerygetSettingMenuArgs = {
  deviceCode?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetSimOptionArgs = {
  simOptionID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetSimTypeArgs = {
  simTypeID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetSimUserApplicationConsentTokenArgs = {
  applicationId: Scalars['ID']['input'];
  extras?: InputMaybe<Array<InputMaybe<Scalars['NumberString']['input']>>>;
  simUserApplicationId: Scalars['ID']['input'];
};

export type QuerygetSpotlightArgs = {
  genreId: Scalars['ID']['input'];
};

export type QuerygetStoreArgs = {
  id: Scalars['ID']['input'];
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetStoreGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetStoreGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetStoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetTempApplicationArgs = {
  tempApplicationId: Scalars['ID']['input'];
};

export type QuerygetTempApplicationFeeArgs = {
  registrationNos: Array<InputMaybe<Scalars['String']['input']>>;
  validateRegistrationNos?: Scalars['Boolean']['input'];
};

export type QuerygetTempApplicationsArgs = {
  pageSpecifier?: InputMaybe<PageSpecifier>;
  statusCodes?: InputMaybe<Array<TempApplicationStatus>>;
};

export type QuerygetTitleArgs = {
  id: Scalars['ID']['input'];
};

export type QuerygetUpsellGuideArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type QuerygetUserRecommendFeatureArgs = {
  additionalDataFlag?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  topPickTypeNumber: Scalars['Int']['input'];
};

export type QuerygetUserUnextServicesArgs = {
  ppdRightTypeCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  runningTypeCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerygetUserUnextServicesV2Args = {
  ppdRightTypeCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerygetVoicePlanArgs = {
  voicePlanID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetWatchingEpisodesArgs = {
  episodeSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerygetYuMobileOptionArgs = {
  optionID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetYuMobilePlanArgs = {
  planID?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygiftCodeDetailsArgs = {
  giftCode: Scalars['String']['input'];
};

export type QueryinboxMessageArgs = {
  id: Scalars['String']['input'];
};

export type QueryinboxMessagesArgs = {
  classification?: InputMaybe<ClassificationInput>;
};

export type QuerylabelArgs = {
  code: Scalars['ID']['input'];
};

export type QueryleagueBlockArgs = {
  genreCode: Scalars['ID']['input'];
};

export type QueryleagueDetailArgs = {
  leagueCode: Scalars['ID']['input'];
};

export type QueryliveBlockArgs = {
  genreCode?: InputMaybe<Scalars['ID']['input']>;
  numPerBlock: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryliveBlockDetailArgs = {
  genreCode?: InputMaybe<Scalars['ID']['input']>;
  liveBlockCode: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryliveBlocksArgs = {
  genreCode?: InputMaybe<Scalars['ID']['input']>;
  numPerBlock: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryliveCategorySearchArgs = {
  menuCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryliveFreewordSearchArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type QueryliveGroupArgs = {
  genreCode: Scalars['ID']['input'];
  liveGroupCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerylivePlaylistUrlArgs = {
  liveCode?: InputMaybe<Scalars['String']['input']>;
};

export type QuerylivePlaylistValidationArgs = {
  liveCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryliveProductListArgs = {
  liveCode: Scalars['ID']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type QueryliveRelatedLivesArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryliveRelatedVideosArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryloginDelegationPollSessionArgs = {
  code: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type QueryloginDelegationValidateSessionArgs = {
  code: Scalars['String']['input'];
};

export type QuerylookupPostalCodeArgs = {
  postalCode: Scalars['String']['input'];
};

export type QuerymediaArgs = {
  mediaId: Scalars['ID']['input'];
};

export type QuerymediaChapterArgs = {
  mediaChapterId: Scalars['ID']['input'];
};

export type QuerymultiAccountUsersInfoArgs = {
  isAll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerymyListChaptersArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QuerymyListPackagesArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QuerynewChaptersV2Args = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QuerynewMediaRankingBlockArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId: Scalars['ID']['input'];
};

export type QueryoriginalBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personCode?: InputMaybe<Scalars['String']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryoriginalComicArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personCode?: InputMaybe<Scalars['String']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryownedCinemaCouponQRArgs = {
  hashCode?: InputMaybe<Scalars['String']['input']>;
  ownedCinemaCouponId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryownedCinemaCouponsArgs = {
  cinemaCouponId?: InputMaybe<Scalars['ID']['input']>;
  maxResult?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryownedFoodCinemaCouponArgs = {
  ownedFoodCinemaCouponId?: InputMaybe<Scalars['String']['input']>;
};

export type QuerypersonLivesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personCode: Scalars['ID']['input'];
};

export type QuerypickupBlockV2Args = {
  itemsPerBlock?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId: Scalars['ID']['input'];
};

export type QueryplayBackTokensArgs = {
  bitRateLowerLimit?: InputMaybe<Scalars['Int']['input']>;
  bitRateUpperLimit?: InputMaybe<Scalars['Int']['input']>;
  consumableId: Scalars['ID']['input'];
  contentId: Scalars['ID']['input'];
  deviceId: Scalars['String']['input'];
  isDownload?: Scalars['Boolean']['input'];
  resolutionLowerLimit?: InputMaybe<Scalars['String']['input']>;
  resolutionUpperLimit?: InputMaybe<Scalars['String']['input']>;
};

export type QuerypointChargeStatusFor3DSecureArgs = {
  transactionId: Scalars['String']['input'];
};

export type QuerypointHistoryArgs = {
  deviceType: Scalars['String']['input'];
  type: PointClassInput;
};

export type QuerypopularChaptersV2Args = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QuerypopularContentTagsV2Args = {
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerypopularityRankingBlockArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId: Scalars['ID']['input'];
};

export type QuerypostalCodeArgs = {
  postalCode: Scalars['String']['input'];
};

export type QueryprecheckUserForApplicationArgs = {
  loginId: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type QueryproductsForVideoContentArgs = {
  deviceType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type QuerypurchaseHistoryArgs = {
  purchaseMonth: Scalars['DateTime']['input'];
};

export type QuerypurchasedLivesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerypurchasedMediaArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
  sorter?: InputMaybe<PurchasedMediaSorterInput>;
};

export type QueryrecommendedBlocksV2Args = {
  blockTypeFilter?: InputMaybe<Array<InputMaybe<ContentBlockTypeInput>>>;
  itemsPerBlock?: Scalars['Int']['input'];
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryrecommendedChaptersV2Args = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QueryregisterDeviceArgs = {
  deviceId: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
};

export type QueryrevenueRankingBlockArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
  planetId: Scalars['ID']['input'];
};

export type QuerysbpsV1OrderStatusArgs = {
  orderId: Scalars['String']['input'];
};

export type QuerysbpsV1PurchaseOrderStatusArgs = {
  orderId: Scalars['String']['input'];
};

export type QuerysbpsV2BillingMethodRegistrationRequestStatusArgs = {
  transactionId: Scalars['ID']['input'];
};

export type QuerysendMailArgs = {
  ldpage?: InputMaybe<Scalars['String']['input']>;
  mail: Scalars['String']['input'];
  template: Scalars['String']['input'];
};

export type QuerysimpleNodeLookupArgs = {
  nodes?: InputMaybe<SimpleNodeLookupFilterInput>;
};

export type QueryspotlightArgs = {
  genreId: Scalars['ID']['input'];
};

export type QuerytimespansArgs = {
  tags?: InputMaybe<Array<CalendarSearchTagInput>>;
};

export type QuerytitleBlockDetailArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  recommendBlockCode: Scalars['ID']['input'];
};

export type QueryunRegisterDeviceArgs = {
  deviceIds: Array<Scalars['String']['input']>;
};

export type QueryunextSubscriptionDepositInfoArgs = {
  subscriptionType: SubscriptionTypeInput;
};

export type QueryunextSubscriptionProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryuniversalNewMediaRankingBlockArgs = {
  mode: ContentBlockQueryModeInput;
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QueryuniversalPopularityRankingBlockArgs = {
  mode: ContentBlockQueryModeInput;
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QueryuniversalRevenueRankingBlockArgs = {
  pagination?: InputMaybe<ContentPaginationInput>;
};

export type QueryupsellDetailsArgs = {
  landingPageCode: Scalars['String']['input'];
};

export type QueryupsellLandingPageCodeArgs = {
  amazonDeviceType?: InputMaybe<AmazonDeviceTypeInput>;
  purchasingAgent?: InputMaybe<PurchasingAgentInput>;
  subscriptionType: SubscriptionTypeInput;
};

export type QueryupsellLandingPageStatusArgs = {
  landingPageCode: Scalars['String']['input'];
};

export type QueryuserDuplicateCheckArgs = {
  emailAddress: Scalars['String']['input'];
  loginId: Scalars['String']['input'];
};

export type QueryuserPointHistoryArgs = {
  type: PortalPointHistoryRequestType;
};

export type QueryvalidateEmailArgs = {
  emailAddress: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  issuedAt: Scalars['String']['input'];
};

export type QueryvalidateEmailCodeForForgottenPasswordArgs = {
  token: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type QueryvalidateEmailVerificationCodeArgs = {
  emailAddress: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  token: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type QueryvalidatePasswordArgs = {
  password: Scalars['String']['input'];
};

export type QueryvalidateStorePasswordArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
};

export type QueryverifySimSerialNosArgs = {
  serialNos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryverifySimUserApplicationConsentTokenArgs = {
  token: Scalars['String']['input'];
};

export type QueryverifyTempApplicationRegistrationNosArgs = {
  registrationNos: Array<Scalars['String']['input']>;
};

export type QueryvideoRecommendBlocksArgs = {
  genreCode: Scalars['ID']['input'];
  numberOfContentPerBlock: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type Querywebfront_bookCategoryArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  filterSaleType?: InputMaybe<BookSaleType>;
  menuCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
};

export type Querywebfront_bookFeaturesArgs = {
  bookFeatureCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_bookFreewordSearchArgs = {
  filterSaleType?: InputMaybe<BookSaleType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  sortOrder: PortalSortOrder;
};

export type Querywebfront_bookMetaSearchArgs = {
  code: Scalars['ID']['input'];
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  filterSaleType?: InputMaybe<BookSaleType>;
  metaType: BookMetaType;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
};

export type Querywebfront_bookPersonArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  filterSaleType?: InputMaybe<BookSaleType>;
  onlyChapter?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personCode: Scalars['ID']['input'];
  personNameCode?: InputMaybe<Scalars['ID']['input']>;
  sortOrder: PortalSortOrder;
};

export type Querywebfront_bookPlaylistUrlArgs = {
  bookFileCode: Scalars['String']['input'];
};

export type Querywebfront_bookProductListArgs = {
  bookCode?: InputMaybe<Scalars['ID']['input']>;
  bookSakuhinCode?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_bookRankingArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  rankingTargetCode: BookRankingTargetCode;
};

export type Querywebfront_bookRecommendBlocksArgs = {
  booksPerBlock?: InputMaybe<Scalars['Int']['input']>;
  genreId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_bookUserRecommendedArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_episodeCreditsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  titleId: Scalars['ID']['input'];
};

export type Querywebfront_favoriteBooksArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
};

export type Querywebfront_favoriteTitlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
};

export type Querywebfront_featureArgs = {
  genreId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_featureTemplateArgs = {
  id: Scalars['ID']['input'];
  tagCode?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_freeBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode: Scalars['ID']['input'];
};

export type Querywebfront_getLinearChannelArgs = {
  id: Scalars['ID']['input'];
};

export type Querywebfront_getLiveArgs = {
  id: Scalars['ID']['input'];
};

export type Querywebfront_getLive_relatedLivesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_getLive_relatedVideosArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_getSearchGenreArgs = {
  menuCode: Scalars['ID']['input'];
};

export type Querywebfront_getSingleSearchGenreArgs = {
  menuCode: Scalars['ID']['input'];
};

export type Querywebfront_historyTitlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_infoListArgs = {
  isCheck?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Querywebfront_linearChannelsArgs = {
  genreId?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_linearPlaylistUrlArgs = {
  channelCode?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_liveCategorySearchArgs = {
  menuCode: Scalars['ID']['input'];
};

export type Querywebfront_liveFreewordSearchArgs = {
  query: Scalars['String']['input'];
};

export type Querywebfront_liveListArgs = {
  genreId?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_livePlaylistUrlV2Args = {
  liveCode?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_livePlaylistValidationArgs = {
  liveCode?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_liveTicketListArgs = {
  id: Scalars['ID']['input'];
};

export type Querywebfront_magazinesArgs = {
  magazineGenreCode: Scalars['ID']['input'];
};

export type Querywebfront_newBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode: Scalars['ID']['input'];
};

export type Querywebfront_originalBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personCode?: InputMaybe<Scalars['String']['input']>;
  tagCode: Scalars['ID']['input'];
};

export type Querywebfront_personArgs = {
  code: Scalars['ID']['input'];
};

export type Querywebfront_personFreewordSearchArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export type Querywebfront_personLiveListArgs = {
  personCode?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_playlistUrlArgs = {
  audioTypeList?: InputMaybe<Array<AudioCodecType>>;
  bitrateHigh?: InputMaybe<Scalars['Int']['input']>;
  bitrateLow?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  codec?: InputMaybe<Array<VideoCodec>>;
  dynamicRangeList?: InputMaybe<Array<VideoDynamicRangeType>>;
  httpsSegments?: InputMaybe<Scalars['Boolean']['input']>;
  keyOnly?: InputMaybe<Scalars['Boolean']['input']>;
  mediaType?: InputMaybe<Scalars['String']['input']>;
  playMode?: InputMaybe<Scalars['String']['input']>;
  playType?: InputMaybe<PlayType>;
  singleBitrateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  validationOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Querywebfront_pointBackArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type Querywebfront_postPlayArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_preorderableBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode: Scalars['ID']['input'];
};

export type Querywebfront_purchasedBooksArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
};

export type Querywebfront_purchasedTitlesArgs = {
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
};

export type Querywebfront_recommendBlocksArgs = {
  extensions?: InputMaybe<Scalars['String']['input']>;
  genreId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sakuhinsPerFeature?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_searchVideoArgs = {
  categoryCode?: InputMaybe<Scalars['ID']['input']>;
  dubSubFilter?: InputMaybe<DubSubType>;
  filterSaleType?: InputMaybe<SaleType>;
  genreCode?: InputMaybe<Scalars['ID']['input']>;
  mainGenreCode?: InputMaybe<MainGenreCodeType>;
  mainGenreId?: InputMaybe<Scalars['String']['input']>;
  menuCode?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personCode?: InputMaybe<Scalars['ID']['input']>;
  personGroupCode?: InputMaybe<Scalars['ID']['input']>;
  personNameCode?: InputMaybe<Scalars['ID']['input']>;
  sakuhinGroupCode?: InputMaybe<Scalars['ID']['input']>;
  sortOrder: PortalSortOrder;
  tagCode?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_seriesRelatedTitlesArgs = {
  seriesCode?: InputMaybe<Scalars['ID']['input']>;
};

export type Querywebfront_targetDeviceArgs = {
  titleCode?: InputMaybe<Scalars['String']['input']>;
};

export type Querywebfront_titleArgs = {
  id: Scalars['ID']['input'];
};

export type Querywebfront_title_creditsArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_title_episodesArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_title_recommendedTitlesArgs = {
  id: Scalars['ID']['input'];
};

export type Querywebfront_title_relatedBooksArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_title_relatedTitlesArgs = {
  id: Scalars['ID']['input'];
};

export type Querywebfront_title_stageArgs = {
  featureCode?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type Querywebfront_title_titleEpisodesArgs = {
  id: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Querywebfront_upsellArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type Querywebfront_userRecommendFeatureArgs = {
  additionalDataFlag?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  topPickTypeNumber: Scalars['Int']['input'];
};

export type Querywebfront_videoFreewordSearchArgs = {
  filterSaleType?: InputMaybe<SaleType>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  sortOrder: PortalSortOrder;
};

export type Querywebfront_videoProductListArgs = {
  episodeCode: Scalars['ID']['input'];
  purchasingAgent?: InputMaybe<PurchasingAgent>;
  titleCode: Scalars['ID']['input'];
};

export type Querywebfront_videoRankingArgs = {
  isCheck?: InputMaybe<Scalars['Boolean']['input']>;
  rankingType: VideoRankingType;
};

export type Querywebfront_watchingEpisodesArgs = {
  episodeSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum RatingCode {
  G = 'G',
  PG12 = 'PG12',
  R15 = 'R15',
  R18 = 'R18',
  R20 = 'R20',
  UNKNOWN = 'UNKNOWN',
}

export enum RatingCodeInput {
  G = 'G',
  PG12 = 'PG12',
  R15 = 'R15',
  R18 = 'R18',
  R20 = 'R20',
  UNKNOWN = 'UNKNOWN',
}

export type RecommendBlock = {
  __typename: 'RecommendBlock';
  /** @deprecated No need to care at client side */
  commentDisplayFlag: Maybe<Scalars['Boolean']['output']>;
  featureName: Maybe<Scalars['String']['output']>;
  featurePickupFlag: Maybe<Scalars['Boolean']['output']>;
  featureTypeCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leadText: Maybe<Scalars['String']['output']>;
  mediaTypeCode: Maybe<Scalars['String']['output']>;
  mediaTypeGroupCode: Maybe<Scalars['String']['output']>;
  pages: Maybe<Scalars['Int']['output']>;
  titleComments: Array<FeatureTitleComment>;
  titleResults: Maybe<Scalars['Int']['output']>;
  titles: Array<Title>;
};

export type RecommendBlockResponse = {
  __typename: 'RecommendBlockResponse';
  pageInfo: PortalPageInfo;
  recommendBlocks: Array<RecommendBlock>;
};

export type RecommendationBlock = {
  __typename: 'RecommendationBlock';
  /** @deprecated No need to care at client side */
  commentDisplayFlag: Maybe<Scalars['Boolean']['output']>;
  featureName: Maybe<Scalars['String']['output']>;
  featurePickupFlag: Maybe<Scalars['Boolean']['output']>;
  featureTypeCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leadText: Maybe<Scalars['String']['output']>;
  mediaTypeCode: Maybe<Scalars['String']['output']>;
  mediaTypeGroupCode: Maybe<Scalars['String']['output']>;
  pages: Maybe<Scalars['Int']['output']>;
  titleResults: Maybe<Scalars['Int']['output']>;
  titles: Array<Title>;
};

export type RecommendationBlockResponse = {
  __typename: 'RecommendationBlockResponse';
  pageInfo: PortalPageInfo;
  recommendationBlocks: Array<RecommendationBlock>;
};

export type RecommendedTitlesResponse = {
  __typename: 'RecommendedTitlesResponse';
  titles: Array<Title>;
};

/** union for errors encountered when validating the sms verification code */
export type RecommendedVerificationMethodError = MissingSecondFactor;

/** Server response for recommendedVerificationMethodResponse. */
export type RecommendedVerificationMethodResponse = {
  __typename: 'RecommendedVerificationMethodResponse';
  /** The result of the query that contains the recommended two factor method and current verification status */
  result: Maybe<RecommendedVerificationMethodResult>;
  /** Errors with the request */
  userErrors: Array<RecommendedVerificationMethodError>;
};

/** Result for the recommended verification request */
export type RecommendedVerificationMethodResult = {
  __typename: 'RecommendedVerificationMethodResult';
  /** The recommend identity verification method */
  recommendedMethod: SecondFactor;
  /** The verification status for the currently logged in user */
  verificationStatus: VerificationStatus;
};

/** union for errors encountered in Register escott billing method error */
export type RegisterEscottBillingMethodError =
  | EscottTokenExpiredError
  | InvalidCreditCardError
  | InvalidEscottTokenError
  | UnsupportedError
  | UserInputsError;

/** Server response for registering an Escott Billing Method to an account */
export type RegisterEscottBillingMethodResponse = {
  __typename: 'RegisterEscottBillingMethodResponse';
  /** Updated account entity. Always return value if there were errors during update */
  account: Maybe<UnextAccount>;
  /** List of errors encountered during processing of mutation */
  userErrors: Array<RegisterEscottBillingMethodError>;
};

/** The rejection code */
export enum RejectionCode {
  /** 画像不鮮明 */
  ILLEGIBLE = 'ILLEGIBLE',
  /** 情報不一致 */
  INCORRECT = 'INCORRECT',
  /** その他 */
  OTHER = 'OTHER',
}

export type RelatedBooksResponse = {
  __typename: 'RelatedBooksResponse';
  books: Array<BookSakuhin>;
  pageInfo: Maybe<PortalPageInfo>;
};

export type RelatedVideosResponse = {
  __typename: 'RelatedVideosResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

/** union for errors that occur while resetting the password for forgotten password */
export type ResetLoginInfoForForgottenPasswordError =
  | InvalidLoginIdError
  | InvalidOrExpiredTokenError
  | InvalidPasswordError
  | InvalidVerificationCodeError;

/** Response to the ResetLoginInfoForForgottenPassword mutation */
export type ResetLoginInfoForForgottenPasswordResponse = {
  __typename: 'ResetLoginInfoForForgottenPasswordResponse';
  /** The result of updating the user's login info */
  result: Maybe<ResetLoginInfoResult>;
  /** Errors encountered while resetting the login info */
  userErrors: Array<ResetLoginInfoForForgottenPasswordError>;
};

/** The result of the reset login info request */
export type ResetLoginInfoResult = {
  __typename: 'ResetLoginInfoResult';
  /** Whether or not the login ID was updated */
  loginIdUpdated: Scalars['Boolean']['output'];
  /** Whether or not the password was updated */
  passwordUpdated: Scalars['Boolean']['output'];
};

/** union for errors encountered when resetting the security code */
export type ResetSecurityCodeError = InvalidSecurityCodeError;

/** Return type for resetSecurityCode mutations. */
export type ResetSecurityCodeResponse = CheckableStatus & {
  __typename: 'ResetSecurityCodeResponse';
  /** Returns true if everything was ok */
  status: Scalars['Boolean']['output'];
  /** The list of errors that occurred from executing the mutation. */
  userErrors: Array<ResetSecurityCodeError>;
};

export enum Resolution {
  FHD = 'FHD',
  HHD = 'HHD',
  SD = 'SD',
  UHD4K = 'UHD4K',
  UHD8K = 'UHD8K',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type Result = {
  __typename: 'Result';
  errorCode: Maybe<Scalars['String']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
};

export type ResumePointUpdateResponse = {
  __typename: 'ResumePointUpdateResponse';
  updated: Scalars['Boolean']['output'];
};

/** Block containing the Top 100 media titles ranked by sales revenue for a planet or in the universe. */
export type RevenueRankingBlock = MediaBlock & {
  __typename: 'RevenueRankingBlock';
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  paginatedMedia: PaginatedMediaLight;
};

/** Errors for revoking session */
export type RevokeSessionError = UserFacingError & {
  __typename: 'RevokeSessionError';
  /** The error message should be shown to the user */
  message: Scalars['String']['output'];
};

export type RevokeSessionResponse = {
  __typename: 'RevokeSessionResponse';
  userErrors: Array<RevokeSessionError>;
};

export enum Role {
  ADMIN = 'ADMIN',
  READ_ONLY = 'READ_ONLY',
  USER = 'USER',
}

export type RunningCallWaitingSimOption = RunningSimOption & {
  __typename: 'RunningCallWaitingSimOption';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Running Service ID used on BOS */
  runningServiceId: Maybe<Scalars['ID']['output']>;
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  simOptionTypeEnum: Maybe<SimOptionTypeEnum>;
  /** ID of the sim user who owns this sim option */
  simUserId: Scalars['ID']['output'];
  /** Sim Option Status as recorded on the Mobile API. The sim option status is independent of the service contract status */
  statusCode: Scalars['String']['output'];
  /** Sim Option Status as recorded on the Mobile API. The sim option status is independent of the service contract status */
  statusName: Scalars['String']['output'];
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Placeholder Option for when more option types are added */
export type RunningGenericOption = RunningOption & {
  __typename: 'RunningGenericOption';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  optionTypeEnum: Maybe<OptionTypeEnum>;
  runningServiceId: Scalars['ID']['output'];
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** IFilter Option that has been purchased by a contract holder */
export type RunningIFilter = RunningOption & {
  __typename: 'RunningIFilter';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  oemlsCustomerId: Maybe<Scalars['String']['output']>;
  oemlsLicenseKey: Maybe<Scalars['String']['output']>;
  optionTypeEnum: Maybe<OptionTypeEnum>;
  runningServiceId: Scalars['ID']['output'];
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  versionNo: Maybe<Scalars['Int']['output']>;
};

export type RunningInternationalCallingSimOption = RunningSimOption & {
  __typename: 'RunningInternationalCallingSimOption';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Running Service ID used on BOS */
  runningServiceId: Maybe<Scalars['ID']['output']>;
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  simOptionTypeEnum: Maybe<SimOptionTypeEnum>;
  /** ID of the sim user who owns this sim option */
  simUserId: Scalars['ID']['output'];
  /**
   * Sim Option Status. International Calling is not a option that incurs monthly charges, so there is no service contract
   * The sim option status is independent of the service contract status
   */
  statusCode: Scalars['String']['output'];
  /**
   * Sim Option Status. International Calling is not a option that incurs monthly charges, so there is no service contract
   * The sim option status is independent of the service contract status
   */
  statusName: Scalars['String']['output'];
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Represents an account wide option */
export type RunningOption = {
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  optionTypeEnum: Maybe<OptionTypeEnum>;
  /** Running Service ID used on BOS */
  runningServiceId: Scalars['ID']['output'];
  /** The BOS Running service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** Plan that has been purchased by a contract holder */
export type RunningPlan = RunningService & {
  __typename: 'RunningPlan';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Running Service ID */
  id: Scalars['ID']['output'];
  /** Enum to indicate whether this plan is a SINGLE or a SHARED plan */
  planTypeEnum: Maybe<PlanTypeEnum>;
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** Free trial period */
  serviceFreeTrialPeriod: Maybe<TimePeriod>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
};

/** This MONTHLY unit price of a plan/voice plan/sim type etc. or other service that is registered on BOS */
export type RunningPrice = {
  __typename: 'RunningPrice';
  dailyPriceExcludingTax: Maybe<Scalars['Int']['output']>;
  dailyPriceIncludingTax: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  priceExcludingTax: Maybe<Scalars['Int']['output']>;
  priceIncludingTax: Maybe<Scalars['Int']['output']>;
};

export type RunningRoamingSimOption = RunningSimOption & {
  __typename: 'RunningRoamingSimOption';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Running Service ID used on BOS */
  runningServiceId: Maybe<Scalars['ID']['output']>;
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  simOptionTypeEnum: Maybe<SimOptionTypeEnum>;
  /** ID of the sim user who owns this sim option */
  simUserId: Scalars['ID']['output'];
  /**
   * Sim Option Status. International Calling is not a option that incurs monthly charges, so there is no service contract
   * The sim option status is independent of the service contract status
   */
  statusCode: Scalars['String']['output'];
  /**
   * Sim Option Status. International Calling is not a option that incurs monthly charges, so there is no service contract
   * The sim option status is independent of the service contract status
   */
  statusName: Scalars['String']['output'];
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Generic representation of a running service, i.e. a plan/voice plan/sim type etc. that has been registered on BOS */
export type RunningService = {
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Running Service ID used on BOS */
  id: Scalars['ID']['output'];
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** Free trial period */
  serviceFreeTrialPeriod: Maybe<TimePeriod>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
};

/** Response for running service cancellation */
export type RunningServiceCancellationResponse = {
  __typename: 'RunningServiceCancellationResponse';
  errorMessage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  unsubscribeDateTime: Scalars['DateTime']['output'];
};

/** Generic representation v2 of a running service, i.e. a plan/voice plan/sim type etc. */
export type RunningServiceV2 = {
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  externalChargeType: Maybe<Scalars['String']['output']>;
  /** Running Service ID used on BOS */
  id: Scalars['ID']['output'];
  isInFreeTrialPeriod: Scalars['Boolean']['output'];
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatusCode>;
  /** Free trial period end date */
  serviceFreeTrialPeriodEnd: Maybe<Scalars['DateTime']['output']>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
  serviceType: Maybe<Scalars['String']['output']>;
};

/** Options tied to individual sim cards/sim users. They are purchased after the original application, so they are not included in the application type */
export type RunningSimOption = {
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Running Service ID used on BOS */
  runningServiceId: Maybe<Scalars['ID']['output']>;
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  simOptionTypeEnum: Maybe<SimOptionTypeEnum>;
  /** ID of the sim user who owns this sim option */
  simUserId: Scalars['ID']['output'];
  versionNo: Maybe<Scalars['Int']['output']>;
};

export type RunningSimType = RunningService & {
  __typename: 'RunningSimType';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Running Service ID */
  id: Scalars['ID']['output'];
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** Free trial period */
  serviceFreeTrialPeriod: Maybe<TimePeriod>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
  /** Enum to indicate the type of sim card */
  simTypeEnum: Maybe<SimTypeEnum>;
};

export type RunningVoiceMailSimOption = RunningSimOption & {
  __typename: 'RunningVoiceMailSimOption';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Running Service ID used on BOS */
  runningServiceId: Maybe<Scalars['ID']['output']>;
  /** The BOS Running Service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  simOptionTypeEnum: Maybe<SimOptionTypeEnum>;
  /** ID of the sim user who owns this sim option */
  simUserId: Scalars['ID']['output'];
  /** Sim Option Status as recorded on the Mobile API. The sim option status is independent of the service contract status */
  statusCode: Scalars['String']['output'];
  /** Sim Option Status as recorded on the Mobile API. The sim option status is independent of the service contract status */
  statusName: Scalars['String']['output'];
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Voice plan that has been purchased by a sim user */
export type RunningVoicePlan = RunningService & {
  __typename: 'RunningVoicePlan';
  /** The Date time this service was applied for */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  internationalCallingStatusCode: Maybe<Scalars['String']['output']>;
  internationalCallingStatusName: Maybe<Scalars['String']['output']>;
  /** The service that is in effect */
  service: Maybe<Service>;
  serviceContractStatus: Maybe<ServiceContractStatus>;
  /** Free trial period */
  serviceFreeTrialPeriod: Maybe<TimePeriod>;
  /** The date time this service came into affect */
  serviceStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Penalty fee that occurs if the contract is terminated */
  serviceTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
  /** If a sim user has requested a voice plan change, this field will be filled with the display name of the next voice plan */
  upcomingVoicePlanName: Maybe<Scalars['String']['output']>;
  /** The date on which the upcoming voice plan will be applied. (This date is always the 1st of the following month); */
  upcomingVoicePlanStartDate: Maybe<Scalars['DateTime']['output']>;
  versionNo: Scalars['Int']['output'];
};

export type SakuhinGroup = {
  __typename: 'SakuhinGroup';
  code: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  targetAge: Scalars['String']['output'];
  thumbnail: SakuhinGroupThumbnail;
  topSakuhinList: Array<TopSakuhin>;
  totalSakuhinNum: Scalars['Int']['output'];
};

export type SakuhinGroupThumbnail = {
  __typename: 'SakuhinGroupThumbnail';
  circle: Scalars['String']['output'];
  square: Scalars['String']['output'];
};

export type SalePeriod = {
  __typename: 'SalePeriod';
  endDateTime: Maybe<Scalars['String']['output']>;
  price: Maybe<Scalars['Int']['output']>;
  startDateTime: Maybe<Scalars['String']['output']>;
};

/** The sale target code */
export enum SaleTargetCode {
  /** Unknown (What is this?) */
  ANYTIME_CHARGE = 'ANYTIME_CHARGE',
  /** Single Purchase */
  PURCHASE = 'PURCHASE',
  /** User Running Service Charge */
  RUNNING = 'RUNNING',
}

export enum SaleType {
  MIHOUDAI = 'MIHOUDAI',
  POINT = 'POINT',
}

/** Block containing all of the sales that are in progress for a planet */
export type SalesBlock = MediaBlock & {
  __typename: 'SalesBlock';
  description: Maybe<Scalars['String']['output']>;
  displayEndDate: Scalars['DateTime']['output'];
  displayStartDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  paginatedMedia: PaginatedMediaLight;
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Trailer.
   */
  thumbnails: Array<ThumbnailSet>;
};

/** SBPS subscription purchase request */
export type SbpsFormRequest = {
  __typename: 'SbpsFormRequest';
  /** Returns the html body of the form that should be submitted to SBPS */
  formBody: Scalars['String']['output'];
};

/** Available payment methods for SBPS */
export enum SbpsPaymentMethodInput {
  /** SBPS au one */
  AUONE = 'AUONE',
  /** SBPS docomo */
  DOCOMO = 'DOCOMO',
  /** SBPS softbank 2 */
  SOFTBANK2 = 'SOFTBANK2',
}

/** Describes the status of an sbps v1 order. */
export enum SbpsV1OrderStatus {
  /** Indicates that the SBPSV1Order was processed successfully */
  DONE = 'DONE',
  /** Indicates that the order ID does not exist */
  NOT_FOUND = 'NOT_FOUND',
  /** Indicates that the payment has been created but is waiting for authorization */
  PENDING = 'PENDING',
}

/** union for errors encountered during upsell status queries */
export type SbpsV1OrderStatusError =
  | InvalidSbpsV1OrderError
  | UnsupportedPlatformError;

/** Response to the sbps v1 order status query */
export type SbpsV1OrderStatusResponse = {
  __typename: 'SbpsV1OrderStatusResponse';
  /** The order status of the sbps v1 order status query */
  orderStatus: Maybe<SbpsV1OrderStatus>;
  /** Errors on the input. */
  userErrors: Array<SbpsV1OrderStatusError>;
};

/** Status of an active SBPS v1 payment */
export enum SbpsV1PurchaseOrderStatus {
  /** The transaction completed successfully */
  DONE = 'DONE',
  /** The transaction failed */
  FAILED = 'FAILED',
  /** The transaction is currently in progress */
  PENDING = 'PENDING',
}

/** What is the status of an active SBPS v1 payment, given an order ID */
export type SbpsV1PurchaseOrderStatusResponse = {
  __typename: 'SbpsV1PurchaseOrderStatusResponse';
  /** The status of an active SBPS v1 payment */
  status: Maybe<SbpsV1PurchaseOrderStatus>;
  /** Errors on the input. */
  userErrors: Array<SbpsV1StatusUserError>;
};

/** Error encountered while fetching sbpsv1 in-flight payment status */
export type SbpsV1StatusError = UserFacingError & {
  __typename: 'SbpsV1StatusError';
  /** Additional error message. */
  message: Scalars['String']['output'];
};

/** A user facing error encountered during sbpsv1 payment status check */
export type SbpsV1StatusUserError =
  | SbpsV1StatusError
  | UnsupportedPlatformError;

/** Error encountered while fetching sbpsv1 in-flight payment status */
export type SbpsV2BillingMethodRegistrationNotFoundError = UserFacingError & {
  __typename: 'SbpsV2BillingMethodRegistrationNotFoundError';
  /** Additional error message. */
  message: Scalars['String']['output'];
};

/** SBPS V2 Billing Method Registration Status */
export enum SbpsV2BillingMethodRegistrationStatus {
  /** Failed to register SBPS V2 as the account billing method */
  FAILED = 'FAILED',
  /** Billing Method Registration Request was created, but is waiting for authorization from SBPS */
  PENDING = 'PENDING',
  /** Billing Method Registration Request was created successfully */
  SUCCEEDED = 'SUCCEEDED',
}

/** A user facing error encountered during SBPS V2 Billing Method Registration */
export type SbpsV2BillingMethodRegistrationStatusError =
  | SbpsV2BillingMethodRegistrationNotFoundError
  | UnsupportedError;

/** Result for checking on a an SBPS V2 Billing Method Registration Request */
export type SbpsV2BillingMethodRegistrationStatusResponse = {
  __typename: 'SbpsV2BillingMethodRegistrationStatusResponse';
  /** Registration result */
  result: Maybe<SbpsV2BillingMethodRegistrationStatus>;
  /** Errors with the request */
  userErrors: Array<SbpsV2BillingMethodRegistrationStatusError>;
};

/** Sbps V2 Payment information */
export type SbpsV2PaymentInfo = {
  __typename: 'SbpsV2PaymentInfo';
  /** Payment method code */
  code: Scalars['String']['output'];
  /** Refers to the settlement type code provided by NSBO */
  id: Scalars['ID']['output'];
  /** Name of the payment method, e.g ドコモ */
  name: Scalars['String']['output'];
};

/** The code value representation of SBPS user payment method */
export enum SbpsV2PaymentMethodInput {
  /** au carrier payment */
  AU = 'AU',
  /** docomo carrier payment */
  DOCOMO = 'DOCOMO',
  /** softbank carrier payment */
  SOFTBANK = 'SOFTBANK',
}

export type SceneSearch = {
  __typename: 'SceneSearch';
  cdns: Array<SceneSearchCdn>;
  type: SceneSearchType;
};

export type SceneSearchCdn = {
  __typename: 'SceneSearchCdn';
  cdnId: Scalars['ID']['output'];
  height: Scalars['Int']['output'];
  playlistUrl: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Int']['output'];
};

export type SceneSearchList = {
  __typename: 'SceneSearchList';
  IMS_AD1: Maybe<Scalars['String']['output']>;
  IMS_L: Maybe<Scalars['String']['output']>;
  IMS_M: Maybe<Scalars['String']['output']>;
  IMS_S: Maybe<Scalars['String']['output']>;
};

export enum SceneSearchType {
  IMS_L = 'IMS_L',
  IMS_M = 'IMS_M',
  IMS_S = 'IMS_S',
}

export enum ScreenType {
  PC = 'PC',
  Phone = 'Phone',
  TV = 'TV',
  Tablet = 'Tablet',
}

export type SearchCategory = {
  __typename: 'SearchCategory';
  captionDubFlag: Scalars['Boolean']['output'];
  /** Returns -1 if unavailable */
  count: Scalars['Int']['output'];
  /** @deprecated Use defaultSortOrder */
  defaultOrder: Scalars['String']['output'];
  defaultSortOrder: PortalSortOrder;
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  menuTypeCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentMenuCode: Maybe<Scalars['String']['output']>;
  parentMenuName: Maybe<Scalars['String']['output']>;
  thumbnail: IconThumbnail;
};

export type SearchFilterInput = {
  duration?: InputMaybe<IntQueryOperatorInput>;
  featureBadges1?: InputMaybe<Array<MediaFeatureBadgeInput>>;
  featureBadges2?: InputMaybe<Array<MediaFeatureBadgeInput>>;
  hasSvod?: InputMaybe<Scalars['Boolean']['input']>;
  onSale?: InputMaybe<Scalars['Boolean']['input']>;
  platformLifetimeClass?: InputMaybe<Array<TitleLifetimeClassInput>>;
  price?: InputMaybe<IntQueryOperatorInput>;
  releaseLifetimeClass?: InputMaybe<Array<TitleLifetimeClassInput>>;
};

export type SearchGenre = {
  __typename: 'SearchGenre';
  captionDubFlag: Scalars['Boolean']['output'];
  /** @deprecated Use defaultSortOrder */
  defaultOrder: Scalars['String']['output'];
  defaultSortOrder: PortalSortOrder;
  displayCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  menuTypeCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  searchCategories: Array<SearchCategory>;
};

export type SearchGenreGroup = {
  __typename: 'SearchGenreGroup';
  displayCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  searchGenres: Array<SearchGenre>;
};

export type SearchHits = {
  hasMore: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SearchMenu = {
  __typename: 'SearchMenu';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum SearchSortModeInput {
  FAVORITE_COUNT = 'FAVORITE_COUNT',
  LENGTH = 'LENGTH',
  PLATFORM_RELEASE_DATE = 'PLATFORM_RELEASE_DATE',
  PLAYS = 'PLAYS',
  PRICE = 'PRICE',
  RELEASE_DATE = 'RELEASE_DATE',
  RELEVANCE = 'RELEVANCE',
  SALES = 'SALES',
  USER_EVALUATION = 'USER_EVALUATION',
}

export enum SearchSortOrderInput {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SearchSorterInput = {
  order: SearchSortOrderInput;
  sortMode: SearchSortModeInput;
};

export type SearchSuggestionNode = {
  __typename: 'SearchSuggestionNode';
  contentType: ContentType;
  /**
   * The description field provides additional information for enriching frontend displays in some needs,
   * e.g. category name for ContentTag suggestions.
   */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  score: Scalars['Float']['output'];
  text: Scalars['String']['output'];
};

export enum SearchTypeInput {
  ALIAS = 'ALIAS',
  ALL = 'ALL',
  MEDIA_CHAPTER = 'MEDIA_CHAPTER',
  PUBLISHED_MEDIA = 'PUBLISHED_MEDIA',
  VIDEO_MEDIA = 'VIDEO_MEDIA',
}

/** The type of authentication for 2FA */
export enum SecondFactor {
  /** Verify with registered email address */
  EMAIL = 'EMAIL',
  /** Verify over SMS */
  SMS = 'SMS',
}

/** Campaign to receive specific items */
export type SelectionItemCampaignInfo = {
  __typename: 'SelectionItemCampaignInfo';
  /** List of product Ids target of this campaign. */
  selectionItemList: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** union for errors that occur while sending the email code for forgotten password */
export type SendEmailCodeForForgottenPasswordError =
  | DuplicateEmailError
  | EmailNotRegisteredError;

/** Response to the SendEmailCodeForForgottenPassword mutation */
export type SendEmailCodeForForgottenPasswordResponse = {
  __typename: 'SendEmailCodeForForgottenPasswordResponse';
  /** Errors encountered while sending the email */
  userErrors: Array<SendEmailCodeForForgottenPasswordError>;
  /** Whether or not the email was successful sent */
  verificationCode: Maybe<Scalars['String']['output']>;
};

/** union for errors that occur when setting the login info for a user */
export type SendEmailForPartnerLoginInfoUpdateError =
  | EmailAddressValidationError
  | InvalidLoginIdError
  | InvalidPasswordError
  | InvalidTemporaryPartnerAccount;

/** Response to the SendEmailForPartnerLoginInfoUpdate mutation */
export type SendEmailForPartnerLoginInfoUpdateResponse = {
  __typename: 'SendEmailForPartnerLoginInfoUpdateResponse';
  /** Result of the send email for partner login info required for validating the email code */
  result: Maybe<SendEmailVerificationCodeResult>;
  /** Errors encountered while checking email verification codes */
  userErrors: Array<SendEmailForPartnerLoginInfoUpdateError>;
};

/** Server response sending an email verification code to an existing user */
export type SendEmailVerificationCodeForUpdateResponse = {
  __typename: 'SendEmailVerificationCodeForUpdateResponse';
  /** Returns true if everything was ok */
  result: Maybe<SendEmailVerificationCodeResult>;
  /** Errors with the request */
  userErrors: Array<EmailAddressValidationError>;
};

/** Response for sending mail verification code */
export type SendEmailVerificationCodeResponse = CheckableStatus & {
  __typename: 'SendEmailVerificationCodeResponse';
  hash: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

/** Server response sending an email verification code to an existing user */
export type SendEmailVerificationCodeResult = {
  __typename: 'SendEmailVerificationCodeResult';
  /** Security hash */
  hash: Scalars['String']['output'];
  /** Secret token */
  token: Scalars['String']['output'];
};

/** Response for sending mail verification */
export type SendEmailVerificationResponse = CheckableStatus & {
  __typename: 'SendEmailVerificationResponse';
  status: Scalars['Boolean']['output'];
};

export type SendInboxEventResponse = {
  __typename: 'SendInboxEventResponse';
  /** Errors if any */
  userErrors: Maybe<Array<InboxEventInputError>>;
};

export type SendMailResponse = {
  __typename: 'SendMailResponse';
  /** Confirmation code will be returned when template is set to 'resetAuth' */
  confirmationCode: Maybe<Scalars['String']['output']>;
  result: Scalars['Boolean']['output'];
};

/** union for errors encountered when updating the phone number */
export type SendSmsValidationCodeError =
  | AnswerTooSoonError
  | IncorrectAnswerError
  | SmsSendError;

/** Server response when sending an sms verification code to an existing user */
export type SendSmsValidationCodeResponse = {
  __typename: 'SendSmsValidationCodeResponse';
  /** The result of sending the sms verification code */
  result: Maybe<SendSmsValidationCodeResult>;
  /** Errors on the input. */
  userErrors: Array<SendSmsValidationCodeError>;
};

/** Contains the result of sending the sms validation code */
export type SendSmsValidationCodeResult = {
  __typename: 'SendSmsValidationCodeResult';
  /** The expiration date and time for the sent verification code */
  validUntil: Maybe<Scalars['DateTime']['output']>;
};

export type Service = {
  __typename: 'Service';
  /**
   * Denotes whether or not this service can be unsubscribed via U-NEXT systems.
   * For services signed up via U-NEXT, this will be true. For services added via external partners, this will be false.
   * For example, Apple IAP subscriptions would have this value set to false.
   */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Denotes whether this running service includes a subscription to the video U-NEXT service */
  includesVideoSubscription: Maybe<Scalars['Boolean']['output']>;
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** The Contract status of a running service */
export type ServiceContractStatus = {
  __typename: 'ServiceContractStatus';
  date: Maybe<Scalars['DateTime']['output']>;
  statusCode: Maybe<ServiceContractStatusCode>;
  statusName: Maybe<Scalars['String']['output']>;
};

/** The status code of serivce contract */
export enum ServiceContractStatusCode {
  APPLICATION_DONE = 'APPLICATION_DONE',
  APPLICATION_PROCESS = 'APPLICATION_PROCESS',
  CONTRACT_ACTIVE = 'CONTRACT_ACTIVE',
  CONTRACT_IN_GRACE_PERIOD = 'CONTRACT_IN_GRACE_PERIOD',
  CONTRACT_NON_ACTIVE = 'CONTRACT_NON_ACTIVE',
  CONTRACT_SUSPENDED = 'CONTRACT_SUSPENDED',
}

export type SettingMenuItem = {
  __typename: 'SettingMenuItem';
  location: Location;
  serviceControlCode: Scalars['String']['output'];
  serviceControlName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SettingMenuResponse = {
  __typename: 'SettingMenuResponse';
  menu: Array<SettingMenuItem>;
};

/** Holds information of a signed terms of service agreement */
export type SignedTosAgreement = {
  __typename: 'SignedTosAgreement';
  documentUri: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  judgedDateTime: Maybe<Scalars['DateTime']['output']>;
  judgedOperatorId: Maybe<Scalars['Int']['output']>;
  /** Rejection history for this temp credit card application in order of most recent */
  rejectionHistory: Array<Maybe<SignedTosAgreementRejection>>;
  statusCode: SignedTosAgreementStatus;
  statusName: Scalars['String']['output'];
  versionNo: Scalars['Int']['output'];
};

/** Holds information of a signed terms of service agreement */
export type SignedTosAgreementrejectionHistoryArgs = {
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SignedTosAgreementRejection = {
  __typename: 'SignedTosAgreementRejection';
  code: SignedTosAgreementRejectionCode;
  documentUri: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  judgedOperatorId: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  rejectionDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** The rejection code of signed TOS agreement */
export enum SignedTosAgreementRejectionCode {
  /** 画像が異なる */
  DIFFERENT_IMAGE = 'DIFFERENT_IMAGE',
  /** バージョンが古い */
  EXPIRED_VERSION = 'EXPIRED_VERSION',
  /** 画像不鮮明 */
  ILLEGIBLE = 'ILLEGIBLE',
  /** 申込日未記載 */
  MISSING_APPLICATION_DATE = 'MISSING_APPLICATION_DATE',
  /** レ点チェック漏れ */
  MISSING_CHECK_MARKS = 'MISSING_CHECK_MARKS',
  /** 署名が無い */
  NO_SIGNATURE = 'NO_SIGNATURE',
}

/** The status of signed TOS agreement */
export enum SignedTosAgreementStatus {
  /** 申込情報入力済み */
  APPLIED = 'APPLIED',
  /** 無効 */
  CANCELED = 'CANCELED',
  /** 不備 */
  DECLINED = 'DECLINED',
  /** 審査完了 */
  JUDGED = 'JUDGED',
  /** 審査中 */
  JUDGING = 'JUDGING',
  /** 審査中断 */
  PENDING = 'PENDING',
  /** 再審査依頼 */
  REAPPLYING = 'REAPPLYING',
}

/** Input for updating signed tos agreement */
export type SignedTosAgreementUpdateInput = {
  documentUri: Scalars['String']['input'];
  versionNo: Scalars['Int']['input'];
};

/** A Sim card that is issued to a SimUser to provide service */
export type SimCard = {
  __typename: 'SimCard';
  /** The phone number of the SimCard, aka msisdn */
  phoneNumber: Maybe<Scalars['String']['output']>;
  serialNo: Scalars['ID']['output'];
};

export type SimDelivery = {
  __typename: 'SimDelivery';
  /** DateTime that the delivery was requested for Sim Reissue and the datetime the application was issued for the initial sim issue */
  applicationDateTime: Maybe<Scalars['DateTime']['output']>;
  customerInfo: CustomerInfo;
  deliveredDate: Maybe<Scalars['DateTime']['output']>;
  deliveryAttemptedDate: Maybe<Scalars['DateTime']['output']>;
  deliveryStatus: DeliveryStatus;
  /** @deprecated Please use deliveryStatus instead */
  deliveryStatusCode: Scalars['String']['output'];
  deliveryStatusName: Scalars['String']['output'];
  deliveryTypeCode: Scalars['String']['output'];
  deliveryTypeName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Indicates whether or not this sim delivery will need to be activated via MNP */
  isMnp: Maybe<Scalars['Boolean']['output']>;
  requestDate: Scalars['DateTime']['output'];
  serialNumber: Maybe<Scalars['String']['output']>;
  shipDate: Maybe<Scalars['DateTime']['output']>;
  simTypeCode: Scalars['String']['output'];
  simTypeName: Scalars['String']['output'];
  trackingNumber: Maybe<Scalars['String']['output']>;
  versionNo: Scalars['Int']['output'];
};

/**
 * A sim option that can be purchased by a sim user.
 * Sim options are additional services that are tied to individual sim users/cards. Not to be confused with Options.
 */
export type SimOption = {
  __typename: 'SimOption';
  /** Denotes whether or not this sim option can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  requiredSimTypes: Array<Maybe<SimType>>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  simOptionTypeEnum: Maybe<SimOptionTypeEnum>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** Input for adding sim option */
export type SimOptionInput = {
  id: Scalars['ID']['input'];
  runningTypeCode: Scalars['String']['input'];
  simOptionTypeEnum: SimOptionTypeEnum;
};

/** The type of SIM option */
export enum SimOptionTypeEnum {
  CALL_WAITING = 'CALL_WAITING',
  INTERNATIONAL_CALLING = 'INTERNATIONAL_CALLING',
  ROAMING = 'ROAMING',
  VOICE_MAIL = 'VOICE_MAIL',
}

/** Response for resume, stop, terminate sim user */
export type SimStateChangeResponse = {
  __typename: 'SimStateChangeResponse';
  simUser: Maybe<SimUser>;
  status: Maybe<Scalars['String']['output']>;
};

/** The status of the sim */
export enum SimStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
}

/** A SimType that can be purchased by a sim user */
export type SimType = {
  __typename: 'SimType';
  /** Denotes whether or not this SimType can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  requiredPlans: Array<Maybe<Plan>>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  simTypeEnum: Maybe<SimTypeEnum>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** Sim type */
export enum SimTypeEnum {
  /** データSIM */
  DATA = 'DATA',
  /** SMS付きデータSIM */
  SMS_DATA = 'SMS_DATA',
  /** 音声SIM */
  VOICE = 'VOICE',
}

/** Input for sim type */
export type SimTypeInput = {
  id: Scalars['ID']['input'];
  runningTypeCode: Scalars['String']['input'];
  simTypeEnum: SimTypeEnum;
};

export type SimUser = {
  __typename: 'SimUser';
  activeSimOptions: Maybe<Array<Maybe<RunningSimOption>>>;
  activeSimType: Maybe<RunningSimType>;
  activeVoicePlan: Maybe<RunningVoicePlan>;
  applicationSimId: Maybe<Scalars['Int']['output']>;
  customerInfo: Maybe<CustomerInfo>;
  /** Returns true if this sim user is a Running user has a delivery that has not reached DELIVERED status and false if not. */
  hasDelivery: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /**
   * Returns an MnpNumber object if an mnp number has been issued for this simUser.
   * Otherwise returns null.
   */
  mnpNumber: Maybe<MnpNumber>;
  simCard: Maybe<SimCard>;
  statusCode: Maybe<Scalars['String']['output']>;
  statusName: Maybe<Scalars['String']['output']>;
  userPlatformId: Scalars['ID']['output'];
  versionNo: Maybe<Scalars['Int']['output']>;
};

export type SimUserApplication = {
  __typename: 'SimUserApplication';
  applicationDateTime: Scalars['DateTime']['output'];
  applicationId: Maybe<Scalars['Int']['output']>;
  customerInfo: CustomerInfo;
  entryCode: Maybe<Scalars['String']['output']>;
  /** This is an alias for sim user application id */
  id: Scalars['ID']['output'];
  identityDocumentUris: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Denotes whether the application fee should be waived this user. If true, do not charge the application fee. */
  isApplicationFeeWaived: Scalars['Boolean']['output'];
  isContractor: Scalars['Boolean']['output'];
  isFirstContractorsSim: Scalars['Boolean']['output'];
  judgeableDateTime: Maybe<Scalars['DateTime']['output']>;
  judgedDateTime: Maybe<Scalars['DateTime']['output']>;
  judgedOperatorId: Maybe<Scalars['Int']['output']>;
  judgingOperatorId: Maybe<Scalars['Int']['output']>;
  mnp: Maybe<MnpInfo>;
  /**
   * Rejection history for this sim user application in order of most recent
   * Can filter for the rejection reason that is currently in effect
   */
  rejectionHistory: Array<Maybe<ApplicationRejection>>;
  routeCode: Scalars['String']['output'];
  simType: RunningSimType;
  simUserId: Scalars['Int']['output'];
  statusCode: SimUserApplicationStatus;
  statusName: Scalars['String']['output'];
  typeCode: SimTypeEnum;
  typeName: Scalars['String']['output'];
  userPlatformId: Scalars['ID']['output'];
  versionNo: Scalars['Int']['output'];
  voicePlan: Maybe<RunningVoicePlan>;
};

export type SimUserApplicationrejectionHistoryArgs = {
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SimUserApplicationStatus {
  /** 利用者同意待ち */
  AGREEING = 'AGREEING',
  /** 申込情報入力済み */
  APPLIED = 'APPLIED',
  /** 無効 */
  CANCELED = 'CANCELED',
  /** 不備 */
  DECLINED = 'DECLINED',
  /** 審査待ち */
  JUDGEABLE = 'JUDGEABLE',
  /** 審査完了 */
  JUDGED = 'JUDGED',
  /** 審査中 */
  JUDGING = 'JUDGING',
  /** MNP手続き待ち */
  MNP_ACTIVATE_WAITING = 'MNP_ACTIVATE_WAITING',
  /** MNP手続き中 */
  MNP_ACTIVATING = 'MNP_ACTIVATING',
  /** 審査中断 */
  PENDING = 'PENDING',
  /** 再審査依頼 */
  REAPPLYING = 'REAPPLYING',
}

/**
 * Sim User Customer info for a YU mobile user. Note that this is identical to the normal customer info input but is missing email address
 * because this information is strangely not stored by the mobile system.
 */
export type SimUserCustomerInfoInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['DateTime']['input'];
  firstName: Scalars['String']['input'];
  firstNameKana: Scalars['String']['input'];
  gender: GenderInput;
  lastName: Scalars['String']['input'];
  lastNameKana: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  prefecture: Scalars['String']['input'];
};

/** Input type used when created a new sim user TOGETHER with a new mobile service account */
export type SimUserInput = {
  customerInfo: SimUserCustomerInfoInput;
  entryCampaignCode?: InputMaybe<Scalars['String']['input']>;
  entryCode?: InputMaybe<Scalars['String']['input']>;
  entryLandingPageCode: Scalars['String']['input'];
  entryOperationCode?: InputMaybe<Scalars['String']['input']>;
  entrySalesAgencyCode: Scalars['String']['input'];
  isContractHolder: Scalars['Boolean']['input'];
  isFirstContractorsSim: Scalars['Boolean']['input'];
  mnp?: InputMaybe<MnpInput>;
  sendContract: Scalars['Boolean']['input'];
  simType: SimTypeInput;
  voicePlan?: InputMaybe<VoicePlanInput>;
};

export enum SimUserStatus {
  /** 利用中 */
  RUNNING = 'RUNNING',
  /** 利用停止中 */
  STOPPED = 'STOPPED',
  /** 解約済み */
  TERMINATED = 'TERMINATED',
}

export type SimpleNodeLookupFilterInput = {
  forceCacheRefresh?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SimpleNodeLookupResponse = {
  __typename: 'SimpleNodeLookupResponse';
  nodes: Maybe<Array<Node>>;
};

/** Error for an invalid security code value. */
export type SmsSendError = UserFacingError & {
  __typename: 'SmsSendError';
  /** The error code */
  code: Scalars['String']['output'];
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Error to capture code when sms fails validation. */
export type SmsValidationError = UserFacingError & {
  __typename: 'SmsValidationError';
  /** The error code. */
  code: Scalars['String']['output'];
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Input for validating an SMS verification code */
export type SmsVerificationCodeValidationInput = {
  /** UUID returned from the SMS verification send request */
  uuid: Scalars['String']['input'];
  /** SMS verification code sent to user */
  verificationCode: Scalars['String']['input'];
};

/** Sort order */
export enum SortOrder {
  /** Ascending order */
  ASC = 'ASC',
  /** Descending order */
  DESC = 'DESC',
}

export type Spotlight = {
  __typename: 'Spotlight';
  id: Scalars['ID']['output'];
  image: Maybe<SpotlightImage>;
  linkCode: Maybe<Scalars['String']['output']>;
  linkType: Maybe<Scalars['String']['output']>;
  linkUrl: Maybe<Scalars['String']['output']>;
};

export type SpotlightImage = {
  __typename: 'SpotlightImage';
  landscape: Maybe<Scalars['String']['output']>;
  standard: Maybe<Scalars['String']['output']>;
};

export type SpotlightedSuggestion = {
  __typename: 'SpotlightedSuggestion';
  backgroundImage: ThumbnailSet;
  contentType: ContentType;
  /**
   * The description field provides additional information for enriching frontend displays in some needs,
   * e.g. category name for ContentTag suggestions.
   */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

/** Ticket Formats for Standart */
export type StandardCinemaTicket = {
  __typename: 'StandardCinemaTicket';
  /** ticket's company code */
  companyCode: Maybe<Scalars['String']['output']>;
  /** ticket's company code */
  companyName: Maybe<Scalars['String']['output']>;
  /** ticket expiration date. */
  expirationDatetime: Maybe<Scalars['String']['output']>;
  /** ticket's hash code. */
  hashCode: Maybe<Scalars['String']['output']>;
  /** password to ensure the security of your ticket. */
  password: Maybe<Scalars['String']['output']>;
  /** ticket's seat code */
  seatReservationCode: Maybe<Scalars['String']['output']>;
  /** ticket's ticket code */
  ticketCode: Maybe<Scalars['String']['output']>;
  /** foreign key of the table user_movie_tikcet to retrieve its information */
  userMovieTicketId: Maybe<Scalars['Int']['output']>;
};

export enum StereoLayout {
  BOTTOM_TOP = 'BOTTOM_TOP',
  CUSTOM = 'CUSTOM',
  LEFT_RIGHT = 'LEFT_RIGHT',
  RIGHT_LEFT = 'RIGHT_LEFT',
  TOP_BOTTOM = 'TOP_BOTTOM',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type Store = {
  __typename: 'Store';
  address: Maybe<Scalars['String']['output']>;
  comments: Maybe<Scalars['String']['output']>;
  /** @deprecated Use Store.parent.corporateCode instead */
  corporateCode: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use Store.parent.code instead */
  customerCode: Maybe<Scalars['String']['output']>;
  entrySalesAgencyCode: Maybe<Scalars['String']['output']>;
  groups: Maybe<Array<Maybe<StoreGroup>>>;
  id: Maybe<Scalars['ID']['output']>;
  invalidated: Scalars['Boolean']['output'];
  invalidatedAt: Maybe<Scalars['DateTime']['output']>;
  lastUpdatedBy: Maybe<Scalars['String']['output']>;
  loginCredentials: Maybe<LoginCredentials>;
  name: Maybe<Scalars['String']['output']>;
  parent: Maybe<Partner>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  shippingAddresses: Maybe<Array<JapanShippingAddress>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type StoreGroup = {
  __typename: 'StoreGroup';
  campaigns: Maybe<Array<Maybe<Campaign>>>;
  canOfferCashback: Maybe<Scalars['Boolean']['output']>;
  comments: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  emergencyNotifications: Maybe<Array<Maybe<EmergencyNotification>>>;
  id: Maybe<Scalars['ID']['output']>;
  lastUpdatedBy: Maybe<Scalars['String']['output']>;
  maxCashbackValue: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  stores: Maybe<Array<Maybe<Store>>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type StoreGroupcampaignsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoreGroupemergencyNotificationsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoreGroupInput = {
  campaigns?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  canOfferCashback?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emergencyNotifications?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >;
  maxCashbackValue?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  stores?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type StoreGroupUpdateInput = {
  campaigns?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  canOfferCashback?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emergencyNotifications?: InputMaybe<
    Array<InputMaybe<Scalars['ID']['input']>>
  >;
  id: Scalars['ID']['input'];
  maxCashbackValue?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stores?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type StoreGroupsPaginatedResponse = {
  __typename: 'StoreGroupsPaginatedResponse';
  storeGroups: Maybe<Array<Maybe<StoreGroup>>>;
  totalCount: Scalars['Int']['output'];
};

export type StoreInput = {
  address: Scalars['String']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  corporateCode?: InputMaybe<Scalars['String']['input']>;
  customerCode?: InputMaybe<Scalars['String']['input']>;
  entrySalesAgencyCode?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  login: LoginInput;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber: Scalars['String']['input'];
  shippingAddresses?: InputMaybe<Array<JapanShippingAddressInput>>;
};

export type StoreUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  corporateCode?: InputMaybe<Scalars['String']['input']>;
  customerCode?: InputMaybe<Scalars['String']['input']>;
  entrySalesAgencyCode?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  login?: InputMaybe<LoginUpdateInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  shippingAddresses?: InputMaybe<Array<JapanShippingAddressInput>>;
};

export type StoresPaginatedResponse = {
  __typename: 'StoresPaginatedResponse';
  stores: Maybe<Array<Maybe<Store>>>;
  totalCount: Scalars['Int']['output'];
};

export enum StreamingType {
  DASH = 'DASH',
  DASH_CMAF = 'DASH_CMAF',
  DASH_DOLBYVISION = 'DASH_DOLBYVISION',
  DASH_HDR10 = 'DASH_HDR10',
  HLS_AES = 'HLS_AES',
  HLS_AES_VR = 'HLS_AES_VR',
  HLS_CMAF = 'HLS_CMAF',
  HLS_FP = 'HLS_FP',
  HLS_IS = 'HLS_IS',
  HLS_S_AES = 'HLS_S_AES',
  MARLIN = 'MARLIN',
  SMOOTH = 'SMOOTH',
}

export type StudioLight = {
  __typename: 'StudioLight';
  id: Scalars['ID']['output'];
  nameJa: Scalars['String']['output'];
};

export type SubgenreTag = {
  __typename: 'SubgenreTag';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Refers to a recoverable error that user could try to fix by retry when subscription cancellation */
export type SubscriptionCancellationError = UserFacingError & {
  __typename: 'SubscriptionCancellationError';
  /** The error message. */
  message: Scalars['String']['output'];
};

export type SubscriptionProduct = {
  __typename: 'SubscriptionProduct';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SubscriptionProductList = {
  __typename: 'SubscriptionProductList';
  productList: Array<SubscriptionProduct>;
};

/** Types of subscriptions that can be found on a UnextAccount */
export enum SubscriptionType {
  /** International Football pack with (la liga, premier league, fa cup, and more) */
  INTERNATIONAL_SOCCER = 'INTERNATIONAL_SOCCER',
  /** SPOTV ADD-ON subscription */
  SPOTV = 'SPOTV',
  /** Base U-next VOD subscription */
  VIDEO = 'VIDEO',
}

/** Types of subscriptions that can be added to a UnextAccount */
export enum SubscriptionTypeInput {
  /** International SOCCER pack with (la liga, premier league, fa cup, and more) */
  INTERNATIONAL_SOCCER = 'INTERNATIONAL_SOCCER',
  /** SPOTV subscription */
  SPOTV = 'SPOTV',
  /** Base U-next VOD subscription */
  VIDEO = 'VIDEO',
}

export enum SuggesterType {
  /** TV以外の場合 */
  GENERAL = 'GENERAL',
  /** TVの場合 */
  TV = 'TV',
}

export type Suggestion = {
  __typename: 'Suggestion';
  contentType: ContentType;
  /**
   * The description field provides additional information for enriching frontend displays in some needs,
   * e.g. category name for ContentTag suggestions.
   */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  incidence: Scalars['Int']['output'];
  nameJa: Scalars['String']['output'];
};

/** union for errors encountered in unext user switch */
export type SwitchUnextUserError = UserInputsError;

/** Server response upon attempting to switch to a unext user belonging to the same UnextAccount */
export type SwitchUnextUserResponse = {
  __typename: 'SwitchUnextUserResponse';
  /**
   * loginInfo contains the OAuth2 login credentials for the user.
   * If setCookie is set to true, the loginInfo credentials will be saved in the cookie,
   * and not returned in the query result.
   */
  loginInfo: Maybe<LoginInfoV2>;
  /** Errors on the input. */
  userErrors: Array<SwitchUnextUserError>;
};

export type SyncLicenseResult = {
  __typename: 'SyncLicenseResult';
  deletedFileCodes: Array<Scalars['String']['output']>;
};

export type TargetDevice = {
  __typename: 'TargetDevice';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TargetDeviceResponse = {
  __typename: 'TargetDeviceResponse';
  isLicensedPC: Scalars['Boolean']['output'];
  isLicensedTV: Scalars['Boolean']['output'];
};

/** Calendar Event Participant: used for display on calendar */
export type TeamParticipant = {
  __typename: 'TeamParticipant';
  /** Participant Id */
  id: Scalars['ID']['output'];
  /** Image Url: can be a logo or artist's photo etc */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Participant kind - e.g. home, away */
  kind: Maybe<ParticipantKind>;
  /** Participant Display Name */
  name: Scalars['String']['output'];
};

export type TempApplication = {
  __typename: 'TempApplication';
  appliedDatetime: Scalars['DateTime']['output'];
  cashbackValue: Scalars['Int']['output'];
  comments: Maybe<Scalars['String']['output']>;
  completedDatetime: Maybe<Scalars['DateTime']['output']>;
  entryCampaignCode: Scalars['String']['output'];
  entryOperationCode: Maybe<Scalars['String']['output']>;
  entrySalesAgencycode: Scalars['String']['output'];
  entryShopCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPaused: Scalars['Boolean']['output'];
  plan: Maybe<Plan>;
  receiptNo: Scalars['Int']['output'];
  routeCode: Scalars['String']['output'];
  routeName: Scalars['String']['output'];
  signedTosAgreement: Maybe<SignedTosAgreement>;
  staffCode: Scalars['String']['output'];
  staffName: Scalars['String']['output'];
  statusCode: TempApplicationStatus;
  statusName: Scalars['String']['output'];
  statusUpdateDatetime: Scalars['DateTime']['output'];
  storeAccountId: Scalars['String']['output'];
  tempCreditCardApplication: Maybe<TempCreditCardApplication>;
  tempCustomerInfo: Maybe<TempCustomerInfo>;
  tempOptionApplications: Array<Maybe<TempOptionApplication>>;
  tempSimUserApplications: Array<Maybe<TempSimUserApplication>>;
  userPlatformId: Scalars['ID']['output'];
  versionNo: Scalars['Int']['output'];
};

/** Parameters for designating the entry route and campaigns for a given ota application. This set of parameters is specifically for submitting an OTA application */
export type TempApplicationEntryParams = {
  /** Sign up cash back rebate value */
  cashbackValue?: InputMaybe<Scalars['Int']['input']>;
  entryCampaignCode: Scalars['String']['input'];
  staffCode: Scalars['String']['input'];
  staffName: Scalars['String']['input'];
};

/** Application input for the temporary application system, which must be created as the first step of the OTA application process */
export type TempApplicationInput = {
  comments: Scalars['String']['input'];
  creditCardImageUri: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  entryParams: TempApplicationEntryParams;
  /** If the user is brand new we need the loginId to be used for the new account */
  loginId?: InputMaybe<Scalars['String']['input']>;
  /** If the user already exists, we need to know which multiAccount user is filling out this YU Mobile application */
  multiAccountId?: InputMaybe<Scalars['String']['input']>;
  options: Array<InputMaybe<OptionInput>>;
  /** If the user is brand new we need the password to be used for the new account. (In plain text please, the password will be encrypted after ingest) */
  password?: InputMaybe<Scalars['String']['input']>;
  plan: PlanInput;
  routeCode: ApplicationRoute;
  signedTosAgreementDocumentUri: Scalars['String']['input'];
  simUsers?: InputMaybe<Array<InputMaybe<TempApplicationSimUserInput>>>;
  /** If the user already exists, we need to know which user to append this YU Mobile application to */
  userPlatformId?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Response for submitting temporary application
 *  Only return the relevant IDs and statuses to avoid return unnecessary personal info
 */
export type TempApplicationResponse = {
  __typename: 'TempApplicationResponse';
  appliedDatetime: Maybe<Scalars['DateTime']['output']>;
  comments: Maybe<Scalars['String']['output']>;
  completedDatetime: Maybe<Scalars['DateTime']['output']>;
  entryCampaignCode: Maybe<Scalars['String']['output']>;
  entryOperationCode: Maybe<Scalars['String']['output']>;
  entrySalesAgencyCode: Maybe<Scalars['String']['output']>;
  entryShopCode: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  receiptNo: Maybe<Scalars['Int']['output']>;
  signedTosAgreementId: Maybe<Scalars['Int']['output']>;
  staffCode: Maybe<Scalars['String']['output']>;
  staffName: Maybe<Scalars['String']['output']>;
  statusCode: Maybe<ApplicationStatus>;
  statusName: Maybe<Scalars['String']['output']>;
  statusUpdateDatetime: Maybe<Scalars['DateTime']['output']>;
  storeAccountId: Maybe<Scalars['String']['output']>;
  temporaryApplicationCreditCardId: Maybe<Scalars['Int']['output']>;
  temporaryApplicationCustomerId: Maybe<Scalars['Int']['output']>;
  temporaryApplicationPlanId: Maybe<Scalars['Int']['output']>;
  userPlatformId: Maybe<Scalars['String']['output']>;
  versionNo: Maybe<Scalars['Int']['output']>;
};

/** Input for get sim users */
export type TempApplicationSimUserInput = {
  customerInfo: SimUserCustomerInfoInput;
  identityDocumentUris: Array<InputMaybe<Scalars['String']['input']>>;
  isContractHolder: Scalars['Boolean']['input'];
  isFirstContractorsSim: Scalars['Boolean']['input'];
  isUnder18: Scalars['Boolean']['input'];
  mnp?: InputMaybe<MnpInput>;
  registrationNo: Scalars['String']['input'];
  simSerialNo: Scalars['String']['input'];
  simType: SimTypeInput;
  voicePlan: VoicePlanInput;
};

export enum TempApplicationStatus {
  /** 申込 */
  APPLIED = 'APPLIED',
  /** キャンセル */
  CANCELED = 'CANCELED',
  /** 審査NG */
  DECLINED = 'DECLINED',
  /** 本人審査完了 */
  JUDGED = 'JUDGED',
  /** 審査中 */
  JUDGING = 'JUDGING',
  /** 開通完了 */
  OPENED = 'OPENED',
  /** 開通失敗 */
  OPENING_FAILURE = 'OPENING_FAILURE',
  /** 開通準備完了 */
  READY_TO_OPEN = 'READY_TO_OPEN',
  /** 開通準備失敗 */
  READY_TO_OPEN_FAILURE = 'READY_TO_OPEN_FAILURE',
  /** 再審査依頼 */
  REAPPLYING = 'REAPPLYING',
  /** 本登録完了 */
  REGISTERED = 'REGISTERED',
}

/** Input for updating temporary application */
export type TempApplicationUpdateInput = {
  creditCardApplicationUpdate?: InputMaybe<TempCreditCardApplicationUpdateInput>;
  /** Temp Application Id */
  id: Scalars['ID']['input'];
  signedTosAgreementUpdate?: InputMaybe<SignedTosAgreementUpdateInput>;
  simUserApplications?: InputMaybe<
    Array<InputMaybe<TempSimUserApplicationUpdateInput>>
  >;
  versionNo: Scalars['Int']['input'];
};

export type TempCreditCardApplication = {
  __typename: 'TempCreditCardApplication';
  creditCardImageUri: Maybe<Scalars['String']['output']>;
  creditCardToken: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  judgedDateTime: Maybe<Scalars['DateTime']['output']>;
  judgedOperatorId: Maybe<Scalars['Int']['output']>;
  /** Rejection history for this temp credit card application in order of most recent */
  rejectionHistory: Array<Maybe<TempCreditCardApplicationRejection>>;
  statusCode: TempCreditCardApplicationStatus;
  statusName: Scalars['String']['output'];
  versionNo: Scalars['Int']['output'];
};

export type TempCreditCardApplicationrejectionHistoryArgs = {
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TempCreditCardApplicationRejection = {
  __typename: 'TempCreditCardApplicationRejection';
  creditCardImageUri: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  judgedOperatorId: Maybe<Scalars['Int']['output']>;
  reason: Maybe<Scalars['String']['output']>;
  rejectionDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** The status of temporary credit card application */
export enum TempCreditCardApplicationStatus {
  /** 申込情報入力済み */
  APPLIED = 'APPLIED',
  /** 無効 */
  CANCELED = 'CANCELED',
  /** 不備 */
  DECLINED = 'DECLINED',
  /** 審査完了 */
  JUDGED = 'JUDGED',
  /** 審査中 */
  JUDGING = 'JUDGING',
  /** 審査中断 */
  PENDING = 'PENDING',
  /** 再審査依頼 */
  REAPPLYING = 'REAPPLYING',
}

/** Input for update temporary credit card application */
export type TempCreditCardApplicationUpdateInput = {
  creditCardImageUri: Scalars['String']['input'];
  versionNo: Scalars['Int']['input'];
};

export type TempCustomerInfo = {
  __typename: 'TempCustomerInfo';
  address: Maybe<Address>;
  dateOfBirth: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  firstNameKana: Maybe<Scalars['String']['output']>;
  gender: Maybe<Gender>;
  /** Note: the id is null if it's associated with the TempSimUserApplication */
  id: Maybe<Scalars['ID']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  lastNameKana: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  unextLoginId: Maybe<Scalars['String']['output']>;
  unextLoginPasswordEncrypted: Maybe<Scalars['String']['output']>;
  unextMultiAccountId: Maybe<Scalars['String']['output']>;
};

export type TempOptionApplication = {
  __typename: 'TempOptionApplication';
  id: Scalars['ID']['output'];
  option: Option;
  statusCode: TempOptionApplicationStatus;
  statusName: Scalars['String']['output'];
};

/** The status of temporary option application */
export enum TempOptionApplicationStatus {
  /** 申込中 */
  APPLIED = 'APPLIED',
  /** キャンセル */
  CANCELED = 'CANCELED',
  /** 完了 */
  COMPLETED = 'COMPLETED',
}

export type TempSimUserApplication = {
  __typename: 'TempSimUserApplication';
  applicationDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identityDocumentUris: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Denotes whether the application fee should be waived this user. If true, do not charge the
   * application fee.
   */
  isApplicationFeeWaived: Scalars['Boolean']['output'];
  isContractor: Scalars['Boolean']['output'];
  isFirstContractorsSim: Scalars['Boolean']['output'];
  isUnder18: Scalars['Boolean']['output'];
  judgeableDateTime: Maybe<Scalars['DateTime']['output']>;
  judgedDateTime: Maybe<Scalars['DateTime']['output']>;
  judgedOperatorId: Maybe<Scalars['Int']['output']>;
  judgingOperatorId: Maybe<Scalars['Int']['output']>;
  mnp: Maybe<MnpInfo>;
  registrationNo: Maybe<Scalars['String']['output']>;
  /**
   * Rejection history for this temp sim user application in order of most recent. Can filter for the
   * rejection reason that is currently in effect.
   */
  rejectionHistory: Array<Maybe<TempSimUserApplicationRejection>>;
  simSerialNo: Scalars['String']['output'];
  simType: Maybe<SimType>;
  statusCode: TempSimUserApplicationStatus;
  statusName: Scalars['String']['output'];
  tempApplicationId: Maybe<Scalars['Int']['output']>;
  tempCustomerInfo: Maybe<TempCustomerInfo>;
  tempVoicePlanApplication: Maybe<TempVoicePlanApplication>;
  versionNo: Scalars['Int']['output'];
};

export type TempSimUserApplicationrejectionHistoryArgs = {
  validOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TempSimUserApplicationRejection = {
  __typename: 'TempSimUserApplicationRejection';
  code: RejectionCode;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  rejectionDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** The status of temporary sim user application */
export enum TempSimUserApplicationStatus {
  /** 申込情報入力済み */
  APPLIED = 'APPLIED',
  /** 無効 */
  CANCELED = 'CANCELED',
  /** 不備 */
  DECLINED = 'DECLINED',
  /** 審査完了 */
  JUDGED = 'JUDGED',
  /** 審査中 */
  JUDGING = 'JUDGING',
  /** 開通完了 */
  OPENED = 'OPENED',
  /** 開通失敗 */
  OPENING_FAILURE = 'OPENING_FAILURE',
  /** 審査中断 */
  PENDING = 'PENDING',
  /** 開通準備完了 */
  READY_TO_OPEN = 'READY_TO_OPEN',
  /** 開通準備失敗 */
  READY_TO_OPEN_FAILURE = 'READY_TO_OPEN_FAILURE',
  /** 再審査依頼 */
  REAPPLYING = 'REAPPLYING',
  /** 本登録完了 */
  REGISTERED = 'REGISTERED',
}

/** Input for updating temporary sim user application */
export type TempSimUserApplicationUpdateInput = {
  customerInfo: SimUserCustomerInfoInput;
  id: Scalars['ID']['input'];
  identityDocumentUris?: InputMaybe<Array<Scalars['String']['input']>>;
  isContractor?: InputMaybe<Scalars['Boolean']['input']>;
  mnp?: InputMaybe<MnpInput>;
  versionNo: Scalars['Int']['input'];
};

export type TempVoicePlanApplication = {
  __typename: 'TempVoicePlanApplication';
  id: Scalars['ID']['output'];
  statusCode: TempVoicePlanApplicationStatus;
  statusName: Scalars['String']['output'];
  voicePlan: VoicePlan;
};

/** The status of temporary voice plan application */
export enum TempVoicePlanApplicationStatus {
  /** 申込中 */
  APPLIED = 'APPLIED',
  /** キャンセル */
  CANCELED = 'CANCELED',
  /** 完了 */
  COMPLETED = 'COMPLETED',
}

/** union for errors encountered when trying to terminate your user */
export type TerminateUnextAccountError = HasActiveContractsError;

/** Terminate UnextAccount Response */
export type TerminateUnextAccountResponse = CheckableStatus & {
  __typename: 'TerminateUnextAccountResponse';
  /** Returns true if everything was ok */
  status: Scalars['Boolean']['output'];
  /** List of errors encountered when attempting to terminate an account */
  userErrors: Array<TerminateUnextAccountError>;
};

/** Response for terminating user */
export type TerminateUserResponse = CheckableStatus & {
  __typename: 'TerminateUserResponse';
  status: Scalars['Boolean']['output'];
};

export type TextSearchResponse = {
  __typename: 'TextSearchResponse';
  aliases: AliasSearchHits;
  media: MediaSearchHits;
  mediaChapters: MediaChaptersSearchHits;
  suggestions: Array<Suggestion>;
};

/** Information about movie theaters can be obtained. */
export type TheaterInfo = {
  __typename: 'TheaterInfo';
  /** Prefectures with movie theaters */
  prefectureName: Maybe<Scalars['String']['output']>;
  /** Code to identify the cinema */
  theaterCode: Maybe<Scalars['String']['output']>;
  /** theater name */
  theaterName: Maybe<Scalars['String']['output']>;
};

export type Thumbnail = {
  __typename: 'Thumbnail';
  /** Only set for videos */
  other: Maybe<Scalars['String']['output']>;
  secondary: Maybe<Scalars['String']['output']>;
  /** Only set for Channel */
  secondaryHD: Maybe<Scalars['String']['output']>;
  standard: Maybe<Scalars['String']['output']>;
  /** Only set for LiveNotice */
  wide: Array<Scalars['String']['output']>;
};

/**
 * A ThumbnailSet represent the same image but in different variants(aspect ratio/resolution).
 * spec: https://wiki.unext-info.jp/pages/viewpage.action?pageId=106375214
 */
export type ThumbnailSet = {
  __typename: 'ThumbnailSet';
  /** Square image 1(3:4 < aspect ratio < 4:3, < 580K pixels.) Example: 640x640 */
  s1: Scalars['String']['output'];
  /** Square image 2(3:4 < aspect ratio < 4:3, between 580K and 3M pixels.) Example: 1440x1440 */
  s2: Scalars['String']['output'];
  /** Square image 3(3:4 < aspect ratio < 4:3, between 3M and 16M pixels.) Example: 2880x2880 */
  s3: Scalars['String']['output'];
  /** Tight image 1(aspect ratio <= 3:4, < 580K pixels.) Example: 320x640 */
  t1: Scalars['String']['output'];
  /** Tight image 2(aspect ratio <= 3:4, between 580K and 3M pixels.) Example: 1080x1920 */
  t2: Scalars['String']['output'];
  /** Tight image 3(aspect ratio <= 3:4, between 3M and 16M pixels.) Example: 2160x3840 */
  t3: Scalars['String']['output'];
  /** Ultra-wide image 1(extreme aspect ratio, e.g. 21:9) */
  uw1: Scalars['String']['output'];
  /** Ultra-wide image 2(extreme aspect ratio, e.g. 21:9) */
  uw2: Scalars['String']['output'];
  /** Ultra-wide image 3(extreme aspect ratio, e.g. 21:9) */
  uw3: Scalars['String']['output'];
  /** Wide image 1(aspect ratio >= 4:3, < 580K pixels.) Example: 640x320 */
  w1: Scalars['String']['output'];
  /** Wide image 2(aspect ratio >= 4:3, between 580K and 3M pixels.) Example: 1920x1080 */
  w2: Scalars['String']['output'];
  /** Wide image 3(aspect ratio >= 4:3, between 3M and 16M pixels.) Example: 3840x2160 */
  w3: Scalars['String']['output'];
};

/** Information about tickets can be obtained. */
export type TicketInfo = {
  __typename: 'TicketInfo';
  /** Indicates the time to apply for tickets. */
  appliedAt: Maybe<Scalars['String']['output']>;
  /** Indicates the expiration date of the ticket */
  expire: Maybe<Scalars['String']['output']>;
  /** Time ticket was issued */
  issuedAt: Maybe<Scalars['String']['output']>;
  /** Indicates the price of the ticket */
  point: Maybe<Scalars['String']['output']>;
  /** State indicated by the ticket */
  status: Maybe<Scalars['String']['output']>;
  /** ticket code */
  ticketCode: Maybe<Scalars['String']['output']>;
  /** Ticket image url */
  ticketImageUrl: Maybe<Scalars['String']['output']>;
  /** ticket name */
  ticketName: Maybe<Scalars['String']['output']>;
};

/** Time Period */
export type TimePeriod = {
  __typename: 'TimePeriod';
  end: Maybe<Scalars['DateTime']['output']>;
  start: Maybe<Scalars['DateTime']['output']>;
};

/** Timespan */
export type Timespan = {
  __typename: 'Timespan';
  /** Timespan end date */
  endDate: Scalars['DateTime']['output'];
  /** The timespan ID */
  id: Scalars['ID']['output'];
  /** The ID of the timespan that occurs immediately after this timespan if it exists */
  nextId: Maybe<Scalars['ID']['output']>;
  /** The participants of this event */
  participants: Array<TeamParticipant>;
  /** The ID of the timespan that occurs immediately before this timespan if it exists */
  previousId: Maybe<Scalars['ID']['output']>;
  /** Timespan start date */
  startDate: Scalars['DateTime']['output'];
  /** Descriptor tags for the timespan */
  tags: Maybe<Array<CalendarSearchTag>>;
};

export type Title = {
  __typename: 'Title';
  attractions: Maybe<Scalars['String']['output']>;
  bookmarkStatus: Maybe<Scalars['Boolean']['output']>;
  boughtEpisodeNumberTextList: Maybe<Array<Scalars['String']['output']>>;
  cast: Array<Credit>;
  castSummary: Maybe<Scalars['String']['output']>;
  catchphrase: Maybe<Scalars['String']['output']>;
  check: Maybe<Scalars['String']['output']>;
  closestExpiryDateText: Maybe<Scalars['String']['output']>;
  comingSoonMainEpisodeCount: Maybe<Scalars['Int']['output']>;
  copyright: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  credits: Maybe<TitleCreditResponse>;
  currentEpisode: Maybe<Episode>;
  endDateText: Maybe<Scalars['String']['output']>;
  /** Returns the (user's) current episode if no ID is provided. */
  episode: Episode;
  /** @deprecated Changed to titleEpisodes */
  episodes: Array<Episode>;
  exclusive: ExclusiveInfo;
  /** @deprecated use exclusive instead */
  exclusiveBadgeCode: Maybe<ExclusiveBadgeCode>;
  hasDub: Maybe<Scalars['Boolean']['output']>;
  hasMultiprice: Maybe<Scalars['Boolean']['output']>;
  hasPackRights: Scalars['Boolean']['output'];
  hasSubtitle: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isNew: Maybe<Scalars['Boolean']['output']>;
  isOriginal: Scalars['Boolean']['output'];
  lastEpisode: Maybe<Scalars['String']['output']>;
  mainCast: Array<Credit>;
  /** @deprecated Changed to mainGenreId */
  mainGenreCode: Maybe<MainGenreCodeType>;
  mainGenreId: Maybe<Scalars['String']['output']>;
  mainGenreName: Maybe<Scalars['String']['output']>;
  mediaGroupTypeCode: Maybe<Scalars['String']['output']>;
  mediaTypeCode: Maybe<Scalars['String']['output']>;
  minimumPrice: Maybe<Scalars['Int']['output']>;
  missingAlertText: Maybe<Scalars['String']['output']>;
  nextUpdateDate: Maybe<Scalars['String']['output']>;
  nextUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
  nfreeBadge: Maybe<Scalars['String']['output']>;
  nodCatchupFlag: Maybe<Scalars['Boolean']['output']>;
  nodSpecialFlag: Maybe<Scalars['Boolean']['output']>;
  onairFlag: Maybe<Scalars['Boolean']['output']>;
  paymentBadgeList: Array<PaymentBadge>;
  productLineupCodeList: Array<Scalars['String']['output']>;
  productionYear: Maybe<Scalars['String']['output']>;
  publicEndDate: Maybe<Scalars['DateTime']['output']>;
  publicMainEpisodeCount: Maybe<Scalars['Int']['output']>;
  publicStartDate: Maybe<Scalars['DateTime']['output']>;
  publishStyleCode: Maybe<Scalars['String']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
  relatedBooks: Maybe<RelatedBooksResponse>;
  relatedTitles: Array<TitleRelationGroup>;
  resolutionCode: Maybe<Scalars['String']['output']>;
  restrictedCode: Maybe<Scalars['String']['output']>;
  sakuhinNotices: Maybe<Array<Scalars['String']['output']>>;
  saleText: Maybe<Scalars['String']['output']>;
  seriesCode: Maybe<Scalars['String']['output']>;
  seriesInOrder: Maybe<Scalars['Int']['output']>;
  seriesName: Maybe<Scalars['String']['output']>;
  sinceYear: Maybe<Scalars['String']['output']>;
  story: Maybe<Scalars['String']['output']>;
  thumbnail: Maybe<Thumbnail>;
  /** @deprecated use titleComments in Feature or RecommendBlock */
  titleComment: Maybe<Scalars['String']['output']>;
  titleEpisodes: Maybe<TitleEpisodes>;
  /** @deprecated not used */
  titleHeading: Maybe<Scalars['String']['output']>;
  titleName: Maybe<Scalars['String']['output']>;
  totalEpisodes: Maybe<Scalars['Int']['output']>;
  updateOfWeek: Maybe<Scalars['Int']['output']>;
  userRate: Maybe<Scalars['Int']['output']>;
};

export type TitlecreditsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type TitleepisodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TitleepisodesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type TitlerelatedBooksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type TitletitleEpisodesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type TitleBlockDetailResponse = {
  __typename: 'TitleBlockDetailResponse';
  block: Maybe<VideoRecommendTitleBlock>;
  pageInfo: PortalPageInfo;
};

export type TitleCreditResponse = {
  __typename: 'TitleCreditResponse';
  hasEpisodeCredits: Maybe<Scalars['Boolean']['output']>;
  pageInfo: PortalPageInfo;
  titleCredits: Array<Credit>;
};

export type TitleEpisodes = {
  __typename: 'TitleEpisodes';
  episodes: Array<Episode>;
  pageInfo: PortalPageInfo;
};

export type TitleFeature = {
  __typename: 'TitleFeature';
  featureName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  titleComment: Scalars['String']['output'];
};

export enum TitleLifetimeClass {
  NEW = 'NEW',
  OLD = 'OLD',
  SEMI_NEW = 'SEMI_NEW',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum TitleLifetimeClassInput {
  NEW = 'NEW',
  OLD = 'OLD',
  SEMI_NEW = 'SEMI_NEW',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type TitleRelationGroup = {
  __typename: 'TitleRelationGroup';
  groupName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  titles: Array<Title>;
};

export type TitleStage = {
  __typename: 'TitleStage';
  attractions: Maybe<Scalars['String']['output']>;
  bookmarkStatus: Maybe<Scalars['Boolean']['output']>;
  cast: Array<Credit>;
  castSummary: Maybe<Scalars['String']['output']>;
  catchphrase: Maybe<Scalars['String']['output']>;
  check: Maybe<Scalars['String']['output']>;
  comingSoonMainEpisodeCount: Maybe<Scalars['Int']['output']>;
  copyright: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  currentEpisode: Maybe<Episode>;
  displayPublicEndDate: Maybe<Scalars['String']['output']>;
  endDateText: Maybe<Scalars['String']['output']>;
  /** Returns the (user's) current episode if no ID is provided. */
  episode: Episode;
  exclusive: ExclusiveInfo;
  /** @deprecated use exclusive instead */
  exclusiveBadgeCode: Maybe<ExclusiveBadgeCode>;
  /** Returns feature information if featureCode is provided */
  feature: Maybe<TitleFeature>;
  hasDub: Maybe<Scalars['Boolean']['output']>;
  hasMultiprice: Maybe<Scalars['Boolean']['output']>;
  hasPackRights: Scalars['Boolean']['output'];
  hasSubtitle: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isNew: Maybe<Scalars['Boolean']['output']>;
  isOriginal: Scalars['Boolean']['output'];
  lastEpisode: Maybe<Scalars['String']['output']>;
  mainCast: Array<Credit>;
  /** @deprecated Changed to mainGenreId. */
  mainGenreCode: Maybe<MainGenreCodeType>;
  mainGenreId: Maybe<Scalars['String']['output']>;
  mainGenreName: Maybe<Scalars['String']['output']>;
  mediaGroupTypeCode: Maybe<Scalars['String']['output']>;
  mediaTypeCode: Maybe<Scalars['String']['output']>;
  minimumPrice: Maybe<Scalars['Int']['output']>;
  missingAlertText: Maybe<Scalars['String']['output']>;
  nextUpdateDate: Maybe<Scalars['String']['output']>;
  nextUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
  nfreeBadge: Maybe<Scalars['String']['output']>;
  nodCatchupFlag: Maybe<Scalars['Boolean']['output']>;
  nodSpecialFlag: Maybe<Scalars['Boolean']['output']>;
  onairFlag: Maybe<Scalars['Boolean']['output']>;
  paymentBadgeList: Array<PaymentBadge>;
  productLineupCodeList: Array<Scalars['String']['output']>;
  productionYear: Maybe<Scalars['String']['output']>;
  publicEndDate: Maybe<Scalars['DateTime']['output']>;
  publicMainEpisodeCount: Maybe<Scalars['Int']['output']>;
  publicStartDate: Maybe<Scalars['DateTime']['output']>;
  publishStyleCode: Maybe<Scalars['String']['output']>;
  rate: Maybe<Scalars['Int']['output']>;
  resolutionCode: Maybe<Scalars['String']['output']>;
  restrictedCode: Maybe<Scalars['String']['output']>;
  sakuhinNotices: Maybe<Array<Scalars['String']['output']>>;
  saleText: Maybe<Scalars['String']['output']>;
  seriesCode: Maybe<Scalars['String']['output']>;
  seriesName: Maybe<Scalars['String']['output']>;
  sinceYear: Maybe<Scalars['String']['output']>;
  story: Maybe<Scalars['String']['output']>;
  thumbnail: Maybe<Thumbnail>;
  /** @deprecated not used */
  titleComment: Maybe<Scalars['String']['output']>;
  /** @deprecated not used */
  titleHeading: Maybe<Scalars['String']['output']>;
  titleName: Maybe<Scalars['String']['output']>;
  /** @deprecated Use publicMainEpisodeCount */
  totalEpisodes: Maybe<Scalars['Int']['output']>;
  updateOfWeek: Maybe<Scalars['Int']['output']>;
  userRate: Maybe<Scalars['Int']['output']>;
};

export type TitleStageepisodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TitleStagefeatureArgs = {
  featureCode?: InputMaybe<Scalars['ID']['input']>;
};

/** Ticket Formats for toho */
export type TohoCinemaTicket = {
  __typename: 'TohoCinemaTicket';
  /** ticket's company code */
  companyCode: Maybe<Scalars['String']['output']>;
  /** ticket's company code */
  companyName: Maybe<Scalars['String']['output']>;
  /** ticket expiration date. */
  expirationDatetime: Maybe<Scalars['String']['output']>;
  /** ticket's hash code. */
  hashCode: Maybe<Scalars['String']['output']>;
  /** password to ensure the security of your ticket. */
  password: Maybe<Scalars['String']['output']>;
  /** ticket's seat code */
  seatReservationCode: Maybe<Scalars['String']['output']>;
  /** ticket's ticket code */
  ticketCode: Maybe<Scalars['String']['output']>;
  /** ticket's tohotikectManagementNo */
  tohoTicketManagementNo: Maybe<Scalars['String']['output']>;
  /** foreign key of the table user_movie_tikcet to retrieve its information */
  userMovieTicketId: Maybe<Scalars['Int']['output']>;
};

export type TopSakuhin = {
  __typename: 'TopSakuhin';
  sakuhinName: Scalars['String']['output'];
  thumbnail: Thumbnail;
};

export type Trailer = {
  __typename: 'Trailer';
  cmsXid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  introduction: Scalars['String']['output'];
  nameJa: Scalars['String']['output'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Trailer.
   */
  thumbnails: Array<ThumbnailSet>;
  trailerNoDisplayName: Maybe<Scalars['String']['output']>;
};

/**
 * Combo campaign that gives both a trial period and a point grant
 * e.g. 30日見放題PPD+1200ポイント
 */
export type TrialAndPointGrantCampaignInfo = {
  __typename: 'TrialAndPointGrantCampaignInfo';
  /** Number of points granted with this campaign. */
  pointAmount: Maybe<Scalars['String']['output']>;
  /** Description of the rewards of the point campaign. */
  pointCampaignReward: Maybe<Scalars['String']['output']>;
  /** Point Grant campaign type. */
  pointCampaignType: Maybe<Scalars['String']['output']>;
  /** Description of the rewards of the trial campaign. */
  trialCampaignReward: Maybe<Scalars['String']['output']>;
  /** Trial campaign type. */
  trialCampaignType: Maybe<Scalars['String']['output']>;
  /** Duration of the rights on the trial item in hours. */
  trialItemDurationHours: Maybe<Scalars['String']['output']>;
  /** Item target of the trial campaign. */
  trialItemId: Maybe<Scalars['String']['output']>;
};

/** Campain for a trial period of some kind of subscription or item. */
export type TrialCampaignInfo = {
  __typename: 'TrialCampaignInfo';
  /** Description of the rewards of the campaign. */
  campaignReward: Maybe<Scalars['String']['output']>;
  /** Campaign type. */
  campaignType: Maybe<Scalars['String']['output']>;
  /** Duration of the rights on the target item in hours. */
  itemDurationHours: Maybe<Scalars['String']['output']>;
  /** Item target of the campaign. */
  itemId: Maybe<Scalars['String']['output']>;
};

/** Calendar event where there are two teams participating, where one team is delegated as the home team and the other the away team. */
export type TwoTeamSportEvent = CalendarEvent & {
  __typename: 'TwoTeamSportEvent';
  /** The away team participant for this event */
  awayTeam: TeamParticipant;
  /** ContentCode: contains information specific to live content or VOD content */
  contentCode: ContentCode;
  /** Calendar Event Start Date Time */
  date: Scalars['DateTime']['output'];
  /** The home team participant for this event */
  homeTeam: TeamParticipant;
  /** Calendar Event Id */
  id: Scalars['ID']['output'];
  /** Image Url: usually for background image of the event */
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Commentary language */
  language: Maybe<Scalars['String']['output']>;
  /** League */
  league: Maybe<Scalars['String']['output']>;
  /** Calendar Event Name */
  name: Scalars['String']['output'];
  /** Round */
  round: Maybe<Scalars['String']['output']>;
  /** Calendar Event State: that used for display the text in the UI */
  state: CalendarEventState;
  /** Descriptor tags for the event */
  tags: Maybe<Array<CalendarSearchTag>>;
};

export type UBook = {
  __typename: 'UBook';
  content: Maybe<Scalars['String']['output']>;
  coverImage: Maybe<Scalars['String']['output']>;
  isEncrypted: Maybe<Scalars['Boolean']['output']>;
  name: Maybe<Scalars['String']['output']>;
  scale: Maybe<Scalars['String']['output']>;
};

export type UNextProductPurchaseResponse = {
  __typename: 'UNextProductPurchaseResponse';
  product: Maybe<UNextPurchasedProductItem>;
};

export type UNextPurchasedProductItem =
  | PointPurchasedProductItem
  | VideoPurchasedProductItem;

/**
 * This is the general Unext Account.
 * One UnextAccount will contain up to FOUR unext users.
 * Think of it as user_platform_id = account and multi_account_id = user.
 */
export type UnextAccount = {
  __typename: 'UnextAccount';
  /** The currently active coupons on this account */
  activeCoupons: Array<ActiveCoupon>;
  /** List of mobile applications that this U-NEXT user has made in the Waikiki System. This value is null if the current user is not a MAIN user */
  applications: Maybe<Array<Maybe<Application>>>;
  /**
   * Contract Information provided by one of our many billing backends
   * Will return null if the user does not have an email validation cookie
   */
  contractInfo: Maybe<UnextAccountContractInfo>;
  /** Current billing method in use by this user. Union type can refer to payment methods provided by any system */
  currentBillingMethod: Maybe<BillingInfo>;
  /**
   * Point balance
   * Available to all users
   *
   * This response won't be cached when including currentPointBalance because there is no way to invalidate it by far.
   */
  currentPointBalance: PointBalance;
  /**
   * Customer Personal Information
   * Only available to MAIN user
   * User must have an email validation cookie to access
   */
  customerInfo: Maybe<AccountCustomerInfo>;
  /** Identifier for the unext account, aka User Platform Id */
  id: Scalars['ID']['output'];
  /**
   * Account Migration Status
   * Only available to MAIN user
   */
  migrationStatus: AccountMigrationStatus;
  /** Mobile Service account tied to this user. (i.e. the Mobile Service account on the waikiki service that this user the contract holder for). */
  mobileServiceAccount: Maybe<YuMobileAccount>;
  /** User Platform Code. This denotes which system holds this account's billing information */
  platformCode: UserPlatformCode;
  /** Lists the amount of points that are scheduled to be granted to the user and when. */
  pointGrantSchedule: Array<Maybe<PointGrantScheduleItem>>;
  /** Point Usage History for this account, only available to MAIN user */
  pointHistory: PointHistorySummary;
  /**
   * This includes extra properties which are needed.
   * Think of it as a miscellaneous properties.
   */
  properties: Maybe<UnextAccountProperties>;
  /** Purchase History for the logged in account, only available to MAIN user */
  purchaseHistory: PurchaseHistoryResponse;
  /**
   * List of users that have been placed under this account
   * Only available to MAIN user
   */
  users: Array<UnextUser>;
};

/**
 * This is the general Unext Account.
 * One UnextAccount will contain up to FOUR unext users.
 * Think of it as user_platform_id = account and multi_account_id = user.
 */
export type UnextAccountapplicationsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * This is the general Unext Account.
 * One UnextAccount will contain up to FOUR unext users.
 * Think of it as user_platform_id = account and multi_account_id = user.
 */
export type UnextAccountcurrentPointBalanceArgs = {
  type: PointClassInput;
};

/**
 * This is the general Unext Account.
 * One UnextAccount will contain up to FOUR unext users.
 * Think of it as user_platform_id = account and multi_account_id = user.
 */
export type UnextAccountpointHistoryArgs = {
  deviceType: Scalars['String']['input'];
  type: PointClassInput;
};

/**
 * This is the general Unext Account.
 * One UnextAccount will contain up to FOUR unext users.
 * Think of it as user_platform_id = account and multi_account_id = user.
 */
export type UnextAccountpurchaseHistoryArgs = {
  purchaseMonth: Scalars['DateTime']['input'];
};

/** Additional contract info types are expected to be appended for each contract backend */
export type UnextAccountContractInfo = {
  /** Start date for this contract. */
  contractStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /** Contract Status type code (alphanumeric code) */
  contractStatusTypeCode: Maybe<Scalars['String']['output']>;
  /** Contract status type name (in Japanese) */
  contractStatusTypeName: Maybe<Scalars['String']['output']>;
  /** Last updated date time for this contract */
  contractStatusUpdateDateTime: Maybe<Scalars['DateTime']['output']>;
};

/** A summary of the products that have been contracted to a UnextAccount */
export type UnextAccountContractSummary = {
  __typename: 'UnextAccountContractSummary';
  /** Content packs purchased on this UnextAccount */
  contentPacks: Array<PurchasedUnextContentPack>;
  /** Monthly Subscriptions that are active on this UnextAccount and administered via a UnextBilling Backend */
  subscriptions: Array<UnextSubscription>;
};

/**
 * This includes extra properties which are needed.
 * Think of it as a miscellaneous properties.
 */
export type UnextAccountProperties = {
  __typename: 'UnextAccountProperties';
  /**
   * The code value representation of external users' origin
   * Affects things like what logo should be displayed, ex: did the user sign up with a partner service
   */
  allianceCode: Maybe<AllianceCode>;
  /**
   * returns the user breached level
   * If not breached this value is null
   */
  blockScore: Maybe<Scalars['Int']['output']>;
  /**
   * Is user excluded from billing
   * 請求除外ユーザー
   */
  excludedFromBilling: Scalars['Boolean']['output'];
  /** returns true if allianceCode value starts with '5' */
  isWithUnext: Maybe<Scalars['Boolean']['output']>;
};

/** Determine if it is a standart ticket or a toho ticket */
export type UnextCinemaTicketResult = StandardCinemaTicket | TohoCinemaTicket;

/** Interface for defining a content pack that can be sold on Unext */
export type UnextContentPackProduct = {
  /** Point rebate for purchasing this product */
  chargePoint: Scalars['Int']['output'];
  /** Product Id */
  id: Scalars['ID']['output'];
  /** Product name */
  name: Scalars['String']['output'];
  /** Price for this product */
  price: ProductPrice;
  /**
   * Terms of service addendum
   * e.g. "※ 一部の作品は、放送1週間後から単品でのレンタルが可能となります。
   */
  termsOfServiceAddendum: Maybe<Scalars['String']['output']>;
  /** The actual body of the terms of service */
  termsOfServiceBody: Maybe<Scalars['String']['output']>;
  /**
   * Terms of service heading
   * e.g. NHKオンデマンドについて
   */
  termsOfServiceHeading: Maybe<Scalars['String']['output']>;
  /**
   * Terms of service Image url
   * e.g. https://imgc.nxtv.jp/img/info/NOD/logo/nhk_ondemand.png
   */
  termsOfServiceImageUrl: Maybe<Scalars['String']['output']>;
  /**
   * Terms of service title
   * e.g. NHKオンデマンドについて
   */
  termsOfServiceTitle: Maybe<Scalars['String']['output']>;
  /** Product type code */
  typeCode: Scalars['String']['output'];
};

export type UnextPublishingDetail = {
  __typename: 'UnextPublishingDetail';
  introduction: Maybe<Scalars['String']['output']>;
  thumbnail: Maybe<BookPublishingDetailThumbnail>;
};

export type UnextService =
  | BasicRunningService
  | BasicRunningServiceV2
  | NhkOnDemandService;

/** Monthly subscription product that has been added to a UnextAccount with a periodic payment */
export type UnextSubscription = {
  /** The Date time this subscription was applied for */
  applicationDateTime: Scalars['DateTime']['output'];
  /**
   * What kind of external billing is used to pay for this subscription
   * Returns null if billed internally within U-NEXT
   */
  externalChargeType: Maybe<Scalars['String']['output']>;
  /** Id for this instance of an active subscription */
  id: Scalars['ID']['output'];
  /** Indicates whether or not this subscription is still within it's free trial period */
  isInFreeTrialPeriod: Scalars['Boolean']['output'];
  /** The subscription product that is in effect */
  product: Maybe<UnextSubscriptionProduct>;
  /** Free trial period end date */
  subscriptionFreeTrialPeriodEnd: Maybe<Scalars['DateTime']['output']>;
  /** The date time this service came into affect */
  subscriptionStartDateTime: Maybe<Scalars['DateTime']['output']>;
  /**
   * Penalty fee that occurs if the contract is terminated
   * Returns a value of 0 for subscriptions with no penalty
   */
  subscriptionTerminationPenaltyFee: Maybe<Scalars['Int']['output']>;
};

/** Subscription bundle, eg. all the possible subscriptions for SpotTV */
export type UnextSubscriptionBundle = {
  __typename: 'UnextSubscriptionBundle';
  /** Name of the bundle of products */
  name: Scalars['String']['output'];
  /** Products part of the bundle */
  products: Maybe<Array<UnextSubscriptionProduct>>;
  /**
   * Url to signup to the class of subscription.
   * The url handler itself will filter out available subscriptions for the current device
   */
  signUpUrl: Maybe<Scalars['String']['output']>;
  /** Subscription type */
  subscriptionType: SubscriptionType;
};

/** Union type to return either a SubscriptionBundle or a SubscriptionProduct */
export type UnextSubscriptionBundleOrProduct =
  | BasicUnextSubscriptionProduct
  | UnextSubscriptionBundle;

/** Subscription deposit information */
export type UnextSubscriptionDepositInfo = {
  __typename: 'UnextSubscriptionDepositInfo';
  /** The full subscription product that this deposit is going towards */
  product: Maybe<UnextSubscriptionProduct>;
  /** Number of points required to activate this add-on subscription */
  requiredPointDeposit: Scalars['Int']['output'];
  /**
   * The end date that the user can enjoy the subscription until after paying the deposit.
   * i.e. the next payment date for this subscription
   */
  subscriptionPeriodEndDateTime: Scalars['DateTime']['output'];
  /**
   * Id of the subscription product for this addon UnextSubscription
   * @deprecated resolve the name via subscriptionProduct instead
   */
  subscriptionProductName: Scalars['String']['output'];
};

/** Errors encountered when fetching subscription deposit */
export type UnextSubscriptionDepositInfoError =
  | NoUnextSubscriptionError
  | NotAddonSubcriptionError
  | NotAddonSubscriptionError;

/** Response to the unext subscription deposit information query */
export type UnextSubscriptionDepositInfoResponse = {
  __typename: 'UnextSubscriptionDepositInfoResponse';
  /** Deposit information */
  depositInfo: Maybe<UnextSubscriptionDepositInfo>;
  /** Errors encountered while fetching the deposit info */
  userErrors: Array<UnextSubscriptionDepositInfoError>;
};

/**
 * A monthly subscription product that can be subscribed to offered by UNEXT with a periodic payment. This is different from single purchaseable products such as TVOD or EST and products that are billed daily such as ppd passes
 * Transforms into an Subscription after a user applies it to their account
 */
export type UnextSubscriptionProduct = {
  /** Amazon SKU (stock keeping unit). Identifies the Amazon IAP Subscription Product */
  amazonSku: Maybe<Scalars['ID']['output']>;
  /** Amazon Term SKU. Identifies the time variant of the Subscription Product. e.g. 1-month, 3-month, 6-month, etc. subscription */
  amazonTermSku: Maybe<Scalars['ID']['output']>;
  /** Product ID of the corresponding AppStore product. Only populated for Apple IAP products */
  appStoreProductId: Maybe<Scalars['ID']['output']>;
  /**
   * A bundle that this subscription product is part of.
   * Will return null if this subscription product is not a member of a bundle
   */
  bundle: Maybe<UnextSubscriptionBundle>;
  /** Whether or not points can be used to pay for this subscription */
  canChargeWithPoints: Scalars['Boolean']['output'];
  /** Display caption for the subscription product */
  caption: Maybe<Scalars['String']['output']>;
  /** Description */
  description: Maybe<Scalars['String']['output']>;
  /** Subscription product id */
  id: Scalars['ID']['output'];
  /** Name of the subscription product */
  name: Scalars['String']['output'];
  /** Subscription Price */
  price: ProductPrice;
  /** Sub caption for extra details */
  subCaption: Maybe<Scalars['String']['output']>;
  /** Subscription type code */
  typeCode: Scalars['String']['output'];
};

/** This type represents all family account users. */
export type UnextUser = {
  __typename: 'UnextUser';
  /**
   * Identifier for the UnextAccount that this user belongs to. This is being
   * returned here for legacy reasons because many older operations require both the UserPlatformId and MultiAccountId
   */
  accountId: Scalars['ID']['output'];
  /** Identifier for this specific user, aka Multi Account Id */
  id: Scalars['ID']['output'];
  /** User Platform Code. This denotes which system holds this account's billing information */
  platformCode: UserPlatformCode;
  /** The unext account that owns this user */
  unextAccount: UnextAccount;
  /** Family account user type */
  userClass: UnextUserClass;
  /** Basic user info that belongs to the user */
  userInfo: Maybe<UnextUserInfo>;
};

/** Class of the user */
export enum UnextUserClass {
  /**
   * The main user of a UnextAccount.
   * There is only one per Account
   */
  MAIN = 'MAIN',
  /**
   * Sub user of a UnextAccount.
   * There can be up to 3 sub users.
   */
  SUB = 'SUB',
}

/** Denotes which currencies a user is allowed to use when making purchases */
export enum UnextUserCurrencyAuthorization {
  /** User is authorized to make purchases with all currencies */
  ALL = 'ALL',
  /** User is not authorized to make any purchases */
  NONE = 'NONE',
  /** User is only authorized to make purchases with points */
  POINTS_ONLY = 'POINTS_ONLY',
}

/** Set a UnextUser's Currency Authorization */
export enum UnextUserCurrencyAuthorizationInput {
  /** User is authorized to make purchases with all currencies */
  ALL = 'ALL',
  /** User is not authorized to make any purchases */
  NONE = 'NONE',
  /** User is only authorized to make purchases with points */
  POINTS_ONLY = 'POINTS_ONLY',
}

/** UnextUser specific information */
export type UnextUserInfo = {
  __typename: 'UnextUserInfo';
  /** To what degree is this user authorized to buy stuff */
  currencyAuthorization: UnextUserCurrencyAuthorization;
  /**
   * Email address used for password recovery and 2-factor authorization.
   * Adequate care must be taken when deciding whether or not to return this value to the requester
   */
  emailAddress: Maybe<Scalars['String']['output']>;
  /** Id of the unext user, aka multi account id */
  id: Scalars['ID']['output'];
  /** User name used for login */
  loginId: Scalars['String']['output'];
  /**
   * Phone number used for issuing cinema coupons and 2-factor authorization
   * Adequate care must be taken when deciding whether or not to return this value to the requester
   */
  phoneNumber: Maybe<Scalars['String']['output']>;
  /** DateTime when this user was created */
  registrationDate: Scalars['DateTime']['output'];
};

export type UnextUserWithTokenVerificationError =
  | AuthenticatedUserError
  | InvalidTokenError;

/** Response for a invite token verification query. */
export type UnextUserWithTokenVerificationResponse = {
  __typename: 'UnextUserWithTokenVerificationResponse';
  /** Returns true if the user can currently create a new sub-user using that token */
  success: Scalars['Boolean']['output'];
  /** List of errors than prevent user from creating a new sub-user */
  userErrors: Array<UnextUserWithTokenVerificationError>;
};

/** Error for when an unknown or invalid landing page code is provided */
export type UnknownLandingPageCodeError = UserFacingError & {
  __typename: 'UnknownLandingPageCodeError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/**
 * Refers to a service being requested that is unsupported. EG: Using an NSBO platform
 * code on a service that only supports BOS.
 */
export type UnsupportedError = UserFacingError & {
  __typename: 'UnsupportedError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Error for when the current user is on a platform not supported by the operation */
export type UnsupportedPlatformError = UserFacingError & {
  __typename: 'UnsupportedPlatformError';
  /** The error message. */
  message: Scalars['String']['output'];
};

/** union for errors encountered when updating the user's account info. */
export type UpdateAccountCustomerInfoError =
  | BadAddressError
  | BadBirthdayError
  | BadUserNameError
  | BadZipCodeError
  | PendingApplicationError;

/** Server response upon attempting to update a user's account info. */
export type UpdateAccountCustomerInfoResponse = {
  __typename: 'UpdateAccountCustomerInfoResponse';
  /** Updated account entity. Returns null if there were errors */
  account: Maybe<UnextAccount>;
  /** Errors on the input. */
  userErrors: Array<UpdateAccountCustomerInfoError>;
};

/**
 * When updating a user email with a existing account, we return both the
 * user post-update as well as any user-actionable errors that might have been
 * encountered.
 */
export type UpdateEmailResponse = {
  __typename: 'UpdateEmailResponse';
  /** The user after the update (will be null if email verification error was encountered) */
  user: Maybe<UnextUser>;
  /** Errors encountered while checking email verification codes */
  userErrors: Array<EmailCodeValidationError>;
};

/** Response to the UpdateLoginInfo mutation */
export type UpdateLoginInfoResponse = {
  __typename: 'UpdateLoginInfoResponse';
  /** The updated user entity. */
  user: Maybe<UnextUser>;
  /** Errors encountered while resetting the login info */
  userErrors: Array<InvalidLoginInfoError>;
};

/**
 * When updating a partner account's login info, we return both the
 * user post-update as well as any user-actionable errors that might have been
 * encountered.
 */
export type UpdatePartnerLoginInfoResponse = {
  __typename: 'UpdatePartnerLoginInfoResponse';
  /** The user after the update (will be null if email verification error was encountered) */
  user: Maybe<UnextUser>;
  /** Errors encountered while checking email verification codes */
  userErrors: Array<EmailCodeValidationError>;
};

/** union for errors encountered when updating the phone number */
export type UpdatePhoneNumberError = IncorrectCodeError | SmsValidationError;

/** Server response upon attempting to update a user's phone number. */
export type UpdatePhoneNumberResponse = {
  __typename: 'UpdatePhoneNumberResponse';
  /** The updated user entity. */
  user: Maybe<UnextUser>;
  /** Errors on the input. */
  userErrors: Array<UpdatePhoneNumberError>;
};

/** union for errors encountered when updating the security code */
export type UpdateSecurityCodeError = InvalidSecurityCodeError;

/** Return type for updateSecurityCode mutations. */
export type UpdateSecurityCodeResponse = CheckableStatus & {
  __typename: 'UpdateSecurityCodeResponse';
  /** Returns true if everything was ok */
  status: Scalars['Boolean']['output'];
  /** The list of errors that occurred from executing the mutation. */
  userErrors: Array<UpdateSecurityCodeError>;
};

export type Upsell = {
  __typename: 'Upsell';
  /** In case of portal-error, errorCode can be found under gql-error with message 'UPSELL_ERROR' */
  canDisplay: Scalars['Boolean']['output'];
  /** In case of portal-error, errorCode can be found under gql-error with message 'UPSELL_GUIDE_ERROR' */
  guide: UpsellGuide;
};

export type UpsellAddonError = UserFacingError & {
  __typename: 'UpsellAddonError';
  /** Internal error code if it exists */
  code: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message: Scalars['String']['output'];
  /** Describes the type of error that occurred */
  type: UpsellAddonErrorClass;
};

export enum UpsellAddonErrorClass {
  /** Account is already contracted to the service */
  ALREADY_CONTRACTED = 'ALREADY_CONTRACTED',
  /** The charge could not be authorized on the registered billing method for the account */
  AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED',
  /** Account already has a subscription that is incompatible with the one that is being added */
  INCOMPATIBLE_SUBSCRIPTION_PRESENT = 'INCOMPATIBLE_SUBSCRIPTION_PRESENT',
  /**
   * Due to the way the authorization works for Rakuten Pay, the point deposit will be deducted at the same time
   * as the subscription is made.
   * This error occurs when the UnextAccount's current point balance isn't enough to pay for the initial deposit of an addon subscription
   */
  INSUFFICIENT_POINTS_FOR_RAKUTEN_ON_SBPS = 'INSUFFICIENT_POINTS_FOR_RAKUTEN_ON_SBPS',
  /** This account does not have enough points to pay the deposit for the addon subscription */
  INSUFFICIENT_POINT_BALANCE_FOR_DEPOSIT = 'INSUFFICIENT_POINT_BALANCE_FOR_DEPOSIT',
  /** This account is unable to apply for this subscription */
  INVALID_ACCOUNT = 'INVALID_ACCOUNT',
  /** Provided user info is invalid */
  INVALID_USER = 'INVALID_USER',
  /** LP code provided has expired */
  LANDING_PAGE_EXPIRED = 'LANDING_PAGE_EXPIRED',
  /** This account does not have a valid billing method */
  NO_VALID_BILLING_METHOD = 'NO_VALID_BILLING_METHOD',
  /** Target pack now stopped to be serviced */
  PACK_SUSPENDED = 'PACK_SUSPENDED',
  /** This prerequisite for this subscription on this account is in an invalid state */
  PREREQUISITE_INVALID_STATE = 'PREREQUISITE_INVALID_STATE',
  /** This account is missing a prequisite required to apply for this subscription */
  PREREQUISITE_MISSING = 'PREREQUISITE_MISSING',
  /** Unknown error encountered during addon upsell process */
  UNKNOWN = 'UNKNOWN',
  /** An upsell request for the current user has already been submitted */
  USER_IS_UNDER_UPSELL = 'USER_IS_UNDER_UPSELL',
}

/** Type representing UpsellDetails. */
export type UpsellDetails = {
  __typename: 'UpsellDetails';
  /** Document Text for the campaign. */
  campaignDocumentText: Maybe<Scalars['String']['output']>;
  /** Document URL for the campaign. */
  campaignDocumentUrl: Maybe<Scalars['String']['output']>;
  /** Fallback landing page code if available. */
  fallbackLandingPageCode: Maybe<Scalars['String']['output']>;
  /** Landing page information. */
  landingPage: Maybe<LandingPage>;
  /** Pay settlement type for available payment options. */
  paySettlementType: Maybe<PaySettlement>;
};

/** union for errors encountered during upsell */
export type UpsellDetailsError =
  | UnknownLandingPageCodeError
  | UnsupportedPlatformError
  | UpsellError;

/** Response to the prepare upsell query */
export type UpsellDetailsResponse = {
  __typename: 'UpsellDetailsResponse';
  /** The result of the prepare upsell query */
  result: Maybe<UpsellDetails>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<UpsellDetailsError>;
};

export type UpsellDisplayState = {
  __typename: 'UpsellDisplayState';
  canDisplay: Scalars['Boolean']['output'];
};

/** Error that may occur during the upsell process */
export type UpsellError = UserFacingError & {
  __typename: 'UpsellError';
  /** Internal error code if it exists */
  code: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message: Scalars['String']['output'];
  /** Describes the type of error that occurred */
  type: UpsellErrorClass;
};

/** User-facing errors that can be encountered during upsell */
export enum UpsellErrorClass {
  /** Account is already contracted to the service */
  ALREADY_CONTRACTED = 'ALREADY_CONTRACTED',
  /** This account is unable to apply for this service */
  INVALID_ACCOUNT = 'INVALID_ACCOUNT',
  /** Invalid argument passed in request body */
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  /** Provided user info is invalid */
  INVALID_USER = 'INVALID_USER',
  /** LP code provided has expired */
  LANDING_PAGE_EXPIRED = 'LANDING_PAGE_EXPIRED',
  /** Issue encountered preventing IAP */
  MOBILE_ACCOUNT_ISSUE = 'MOBILE_ACCOUNT_ISSUE',
  /** Error encountered in query for running services */
  RUNNING_SERVICE_ISSUE = 'RUNNING_SERVICE_ISSUE',
  /** The current subscription has expired or has been cancelled, so it is not active, and thus cannot be used for upselling. */
  SUBSCRIPTION_IS_NOT_ACTIVE = 'SUBSCRIPTION_IS_NOT_ACTIVE',
  /** Unknown error encountered during upsell process */
  UNKNOWN = 'UNKNOWN',
  /** An upsell request for the current user has already been submitted */
  USER_IS_UNDER_UPSELL = 'USER_IS_UNDER_UPSELL',
}

/** Guidance for how to direct the user based on their purchasing agent. */
export type UpsellGuidance = {
  __typename: 'UpsellGuidance';
  /** The upsell destination url for payment */
  destinationUrl: Maybe<Scalars['String']['output']>;
  /** True means need to do IAP. False means Not available to do IAP (please go to the destination URL to purchase on web) */
  inAppPurchaseRequired: Scalars['Boolean']['output'];
};

export type UpsellGuide = {
  __typename: 'UpsellGuide';
  destinationUrl: Scalars['String']['output'];
  hasRemainingPpd: Scalars['Boolean']['output'];
  needSubscribeAppStore: Scalars['Boolean']['output'];
  subscriptionDisabled: Maybe<UpsellGuideSubscriptionDisabled>;
  userPlatformCode: Scalars['String']['output'];
};

export type UpsellGuideSubscriptionDisabled = {
  __typename: 'UpsellGuideSubscriptionDisabled';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** union for errors encountered when fetching landing page code */
export type UpsellLandingPageCodeError =
  | UnsupportedPlatformError
  | UpsellError
  | UserInputsError;

/** Response to the upsell landing page code */
export type UpsellLandingPageCodeResponse = {
  __typename: 'UpsellLandingPageCodeResponse';
  /** The upsell landing page code */
  code: Maybe<Scalars['String']['output']>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<UpsellLandingPageCodeError>;
};

/** The status of the upsell landing page */
export type UpsellLandingPageStatus = {
  __typename: 'UpsellLandingPageStatus';
  /** Shows if the user can enter a new contract */
  canContract: Scalars['Boolean']['output'];
  /** Shows if the user account is suspended */
  isSuspended: Scalars['Boolean']['output'];
};

/** Response to the upsell landing page status */
export type UpsellLandingPageStatusResponse = {
  __typename: 'UpsellLandingPageStatusResponse';
  /** The upsell landing page status result */
  result: Maybe<UpsellLandingPageStatus>;
  /** Errors encountered while preparing upsell */
  userErrors: Array<UpsellUserError>;
};

/** union for errors encountered during upsell */
export type UpsellUserError = UpsellAddonError | UpsellError;

export type UrlInfo = {
  __typename: 'UrlInfo';
  captionFlg: Maybe<Scalars['Boolean']['output']>;
  code: Maybe<Scalars['String']['output']>;
  commodityCode: Maybe<Scalars['String']['output']>;
  dubFlg: Maybe<Scalars['Boolean']['output']>;
  endPoint: Maybe<Scalars['Int']['output']>;
  endrollStartPosition: Maybe<Scalars['Int']['output']>;
  holderId: Maybe<Scalars['String']['output']>;
  movieAudioList: Maybe<Array<AudioType>>;
  moviePartsPositionList: Array<MoviePartsPosition>;
  movieProfile: Maybe<Array<PlaylistMovieProfile>>;
  movieSecurityLevelCode: Maybe<Scalars['String']['output']>;
  resumePoint: Maybe<Scalars['Int']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  sceneSearchList: Maybe<SceneSearchList>;
  startPoint: Maybe<Scalars['Int']['output']>;
  umcContentId: Maybe<Scalars['String']['output']>;
};

/**
 * A U-NEXT User stored in the common infrastructure with billing information in either NSBO or BOS.
 * This is NOT the equivalent of an account in the Waikiki System
 */
export type User = {
  __typename: 'User';
  /** List of mobile applications that this U-NEXT user has made in the Waikiki System. This value is null if the current user is not a MAIN user */
  applications: Maybe<Array<Maybe<Application>>>;
  /** Contract info in BOS system */
  contractInfo: Maybe<ContractInfo>;
  /** Current billing method in use by this user. Only credit card/debit card billing info is supported in BOS right now */
  currentBillingMethod: Maybe<BillingInfo>;
  /** Contact Info for this user registered in both BOS and Common Infrastructure */
  customerInfo: Maybe<CustomerInfo>;
  /**
   * This boolean refers to whether or not this user currently has an unresolved external migration. So far, the only case is where a user is being migrated from Paravi to UNEXT
   * The number of situations could increase in the future.
   * DO NOT confuse this with NSBO => BOS migration and "Migration Status"
   */
  hasUnresolvedExternalMigration: Scalars['Boolean']['output'];
  /** User multi account id */
  id: Maybe<Scalars['ID']['output']>;
  /** User name used for login */
  loginId: Maybe<Scalars['String']['output']>;
  /** Denotes whether the user can be migrated from the NSBO system to BOS. This value is null if the current user is not a MAIN user or if the user has already been migrated */
  migrationStatus: Maybe<MigrationStatus>;
  /** Mobile Service account tied to this user. (i.e. the Mobile Service account on the waikiki service that this user the contract holder for). */
  mobileServiceAccount: Maybe<Account>;
  /** The currently active plan if this U-NEXT user has a Waikiki Account tied to their user platform id */
  plan: Maybe<RunningPlan>;
  /** Platform code denotes which system hold the user's billing information */
  platformCode: Maybe<PlatformCode>;
  /** Number of U-NEXT points held by this user */
  points: Maybe<Scalars['Int']['output']>;
  /** DateTime when this user was created */
  registrationDate: Maybe<Scalars['DateTime']['output']>;
  userPlatformId: Maybe<Scalars['ID']['output']>;
  /** Code denoting whether user is a primary or multi account user */
  userType: Maybe<Scalars['String']['output']>;
  /** Account info of a linked Yamada Denki User Account */
  yamadaAccountInfo: Maybe<YamadaAccountInfo>;
};

/**
 * A U-NEXT User stored in the common infrastructure with billing information in either NSBO or BOS.
 * This is NOT the equivalent of an account in the Waikiki System
 */
export type UserapplicationsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserCard = {
  __typename: 'UserCard';
  settlementName: Scalars['String']['output'];
  settlementType: Scalars['String']['output'];
};

/** Response for user duplicate check */
export type UserDuplicateCheckResponse = CheckableStatus & {
  __typename: 'UserDuplicateCheckResponse';
  status: Scalars['Boolean']['output'];
};

/**
 * Errors to be used in error union lists to be shown to a user.
 * In other words, errors that are actionable.
 */
export type UserFacingError = {
  /** Error message */
  message: Scalars['String']['output'];
};

export type UserInfo = {
  __typename: 'UserInfo';
  accountTypeCode: Maybe<AccountTypeCode>;
  adultPreventionCode: Scalars['String']['output'];
  adultPreventionText: Scalars['String']['output'];
  age: Maybe<Scalars['Int']['output']>;
  billingCaution: Maybe<BillingCaution>;
  /** @deprecated Not needed. Use age instead */
  birthday: Maybe<Scalars['DateTime']['output']>;
  blockInfo: Maybe<BlockInfo>;
  /** @deprecated Use age instead */
  filmRatingCode: Maybe<Scalars['String']['output']>;
  forceLowBitRateFlgs: Maybe<ForceLowBitRateFlgs>;
  hasRegisteredEmail: Maybe<Scalars['Boolean']['output']>;
  hasVideoSubscription: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** Indicates if the user is permitted to watch/purchase adult contents */
  isAdultPermitted: Maybe<Scalars['Boolean']['output']>;
  isAeon: Scalars['Boolean']['output'];
  /** @deprecated Use blockInfo instead */
  isBlocked: Maybe<Scalars['Boolean']['output']>;
  linkedAccountIssuer: Maybe<Scalars['String']['output']>;
  multiAccountId: Maybe<Scalars['String']['output']>;
  needsAdultViewingRights: Maybe<Scalars['Boolean']['output']>;
  otherFunctionId: Maybe<Scalars['String']['output']>;
  parentalLock: Scalars['Boolean']['output'];
  pointLatestExpire: Maybe<Scalars['Int']['output']>;
  pointLatestExpireDateTime: Maybe<Scalars['DateTime']['output']>;
  points: Maybe<Scalars['Int']['output']>;
  ppdVideo: Maybe<UserInfoPpdVideo>;
  siteCode: Maybe<Scalars['String']['output']>;
  superUser: Maybe<Scalars['Boolean']['output']>;
  userEmail: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['String']['output']>;
  userPlatformCode: Maybe<Scalars['String']['output']>;
  userPlatformId: Maybe<Scalars['String']['output']>;
};

export type UserInfobillingCautionArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

/** This union covers all of the errors related to fetching the user info that is going to be used for a 3D Secure transaction */
export type UserInfoFor3DSecureError =
  | NoApplicablePaymentMethodFor3DSecureError
  | NoEmailAddressRegisteredFor3DSecureError;

/** Current 3DSecure Customer information */
export type UserInfoFor3DSecureResponse = {
  __typename: 'UserInfoFor3DSecureResponse';
  /** Masked email address of the MAIN user */
  emailAddress: Maybe<Scalars['String']['output']>;
  /** First Name in the MAIN User's Billing Info */
  firstName: Maybe<Scalars['String']['output']>;
  /** Last Name in the MAIN User's Billing Info */
  lastName: Maybe<Scalars['String']['output']>;
  /** Errors encounter when fetching user info for 3D secure */
  userErrors: Array<UserInfoFor3DSecureError>;
};

export type UserInfoPpdVideo = {
  __typename: 'UserInfoPpdVideo';
  expireLimitDateTime: Maybe<Scalars['DateTime']['output']>;
  hasRight: Maybe<Scalars['Boolean']['output']>;
  planCode: Maybe<Scalars['String']['output']>;
};

/** The error code for login ID and password validation */
export enum UserInfoValidationErrorCode {
  /** The value is already in use */
  DUPLICATE = 'DUPLICATE',
  /** The value contained an invalid character */
  INVALID_CHARACTER = 'INVALID_CHARACTER',
  /** The login ID and password match */
  LOGIN_ID_AND_PASSWORD_MATCH = 'LOGIN_ID_AND_PASSWORD_MATCH',
  /** The value exceeded the max length */
  MAX_LENGTH = 'MAX_LENGTH',
  /** The value did not reach the min length */
  MIN_LENGTH = 'MIN_LENGTH',
  /** The value does not meet the security requirements */
  NOT_SECURE = 'NOT_SECURE',
  /** The value is required but not provided */
  REQUIRED = 'REQUIRED',
  /** The value is not allowed */
  RESERVED = 'RESERVED',
}

/** Provided requests are invalid. See 'fields' to check which fields are malformed */
export type UserInputsError = UserFacingError & {
  __typename: 'UserInputsError';
  /** The path to the input field that caused the error. */
  fields: Array<Scalars['String']['output']>;
  /** The error message. */
  message: Scalars['String']['output'];
};

/** Denotes which backend the user is hosted on */
export enum UserPlatformCode {
  /** User is hosted on BOS */
  BOS = 'BOS',
  /** User is hosted on Gelatoni (aka アニメ放題) */
  GELATONI = 'GELATONI',
  /** User is hosted on NSBO */
  NSBO = 'NSBO',
  /** User is hosted on Yamada */
  YAMADA = 'YAMADA',
}

/** Rejection code for user precheck in OTA. */
export enum UserPrecheckRejectCode {
  /** This user failed the OTA eligibility check. */
  INELIGIBLE_FOR_OTA = 'INELIGIBLE_FOR_OTA',
  /** User account doesn't have an email address registered. */
  MISSING_EMAIL_ADDRESS = 'MISSING_EMAIL_ADDRESS',
  /** User account doesn't exist or input password was incorrect. */
  NOT_EXISTING = 'NOT_EXISTING',
  /** User account is not a MAIN account. */
  NOT_MAIN_USER = 'NOT_MAIN_USER',
  /** User account cannot be migrated from NSBO to BOS. */
  UNMIGRATABLE = 'UNMIGRATABLE',
  /** This user already has a plan. */
  WITH_ACTIVE_PLAN = 'WITH_ACTIVE_PLAN',
  /**
   * This user has already submitted application.
   * @deprecated Use WITH_ACTIVE_PLAN instead
   */
  WITH_OUTSTANDING_APPLICATION = 'WITH_OUTSTANDING_APPLICATION',
  /**
   * This user has already submitted temporary application.
   * @deprecated Not used anymore
   */
  WITH_OUTSTANDING_TEMPAPPLICATION = 'WITH_OUTSTANDING_TEMPAPPLICATION',
  /** User account's platform is not supported. Currently only BOS and NSBO are supported. */
  WITH_UNSUPPORTED_PLATFORM = 'WITH_UNSUPPORTED_PLATFORM',
}

/** Response for user precheck */
export type UserPrecheckResponse = CheckableStatus & {
  __typename: 'UserPrecheckResponse';
  emailAddress: Maybe<Scalars['String']['output']>;
  multiAccountId: Maybe<Scalars['ID']['output']>;
  rejectCode: Maybe<UserPrecheckRejectCode>;
  status: Scalars['Boolean']['output'];
  userPlatformId: Maybe<Scalars['ID']['output']>;
};

export type UserSecurityCodeCheckResponse = {
  __typename: 'UserSecurityCodeCheckResponse';
  valid: Scalars['Boolean']['output'];
};

export type UserSecurityCodeUpdateResponse = {
  __typename: 'UserSecurityCodeUpdateResponse';
  updated: Scalars['Boolean']['output'];
};

/** Response for user validation */
export type UserValidationResponse = CheckableStatus & {
  __typename: 'UserValidationResponse';
  status: Scalars['Boolean']['output'];
};

/** union for errors that occur while validating the email code for forgotten password */
export type ValidateEmailCodeForForgottenPasswordError =
  | InvalidOrExpiredTokenError
  | InvalidVerificationCodeError;

/** Response to the ValidateEmailCodeForForgottenPassword mutation */
export type ValidateEmailCodeForForgottenPasswordResponse = {
  __typename: 'ValidateEmailCodeForForgottenPasswordResponse';
  /** Whether or not the verification code is valid */
  isValid: Scalars['Boolean']['output'];
  /** Errors encountered while validating the email code */
  userErrors: Array<ValidateEmailCodeForForgottenPasswordError>;
};

/** Server response when verifying an email for an existing user */
export type ValidateEmailCodeForTwoFactorVerificationResponse =
  CheckableStatus & {
    __typename: 'ValidateEmailCodeForTwoFactorVerificationResponse';
    /** Returns true if everything was ok */
    status: Scalars['Boolean']['output'];
    /** Errors encountered while checking email verification codes */
    userErrors: Array<EmailCodeValidationError>;
  };

/** Response for password candidate validation. */
export type ValidatePasswordResponse = {
  __typename: 'ValidatePasswordResponse';
  userErrors: Array<InvalidPasswordError>;
  valid: Scalars['Boolean']['output'];
};

/** union for errors encountered when validating the sms verification code */
export type ValidateSmsCodeForTwoFactorVerificationError =
  | IncorrectCodeError
  | MismatchedPhoneNumber
  | SmsValidationError;

/** Server response when verifying a phone number for an existing user */
export type ValidateSmsCodeForTwoFactorVerificationResponse =
  CheckableStatus & {
    __typename: 'ValidateSmsCodeForTwoFactorVerificationResponse';
    /** Returns true if everything was ok */
    status: Scalars['Boolean']['output'];
    /** Errors on the input. */
    userErrors: Array<ValidateSmsCodeForTwoFactorVerificationError>;
  };

/** Provides the current verification status for a user */
export type VerificationStatus = {
  __typename: 'VerificationStatus';
  /** Whether or not the current user has been verified */
  isVerified: Scalars['Boolean']['output'];
  /** The method used to verify the user */
  method: Maybe<SecondFactor>;
};

/** Response for verifying sim user application consent token */
export type VerifySimUserApplicationConsentTokenResponse = CheckableStatus & {
  __typename: 'VerifySimUserApplicationConsentTokenResponse';
  applicationId: Maybe<Scalars['ID']['output']>;
  /** Extra values included in the token. These are the values that were passed in when creating the token. */
  extras: Maybe<Array<Maybe<Scalars['NumberString']['output']>>>;
  simUserApplicationId: Maybe<Scalars['ID']['output']>;
  status: Scalars['Boolean']['output'];
};

/** Input for creating new UNEXT Video Account and Unext User */
export type VideoApplicationInput = {
  customerInfo: VideoServiceCustomerInfoInput;
  escottToken: Scalars['String']['input'];
};

export type VideoAssociatedProductItem = {
  __typename: 'VideoAssociatedProductItem';
  code: Scalars['ID']['output'];
  displayButtonText: Scalars['String']['output'];
  displaySaleType: Scalars['String']['output'];
  displayValidityDurationText: Scalars['String']['output'];
  displayValidityEndText: Scalars['String']['output'];
  name: Scalars['String']['output'];
  originalPrice: Scalars['Int']['output'];
  packDescription: Maybe<PackDescription>;
  planCode: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  publicEndDate: Scalars['DateTime']['output'];
  publicStartDate: Scalars['DateTime']['output'];
  ruleImageUrl: Scalars['String']['output'];
  ruleNote: Scalars['String']['output'];
  ruleTextHeading: Scalars['String']['output'];
  ruleTextHtml: Scalars['String']['output'];
  ruleTitle: Scalars['String']['output'];
  typeCode: Scalars['String']['output'];
};

export enum VideoCodec {
  H264 = 'H264',
  H265 = 'H265',
}

/**
 * VideoConsumable includes the following information:
 * consumable id: for getting a playback token.
 * type: is a main video or a short sample video.
 * videoProfilse: available formats for playing video(codec, resolution, etc),
 *                check vr formats in geometry of a video profile.
 * durationSeconds: the length of the video in seconds.
 */
export type VideoConsumable = {
  __typename: 'VideoConsumable';
  /** audioProfiles lists all available audio formats of the video */
  audioProfiles: Array<AudioProfile>;
  /** total video length in seconds */
  durationSeconds: Scalars['Float']['output'];
  /** consumable id */
  id: Scalars['ID']['output'];
  type: VideoConsumableType;
  /** videoProfiles lists all available video formats for different devices' choosing */
  videoProfiles: Array<VideoProfile>;
};

export enum VideoConsumableType {
  DUBS = 'DUBS',
  MAIN = 'MAIN',
  SAMPLE = 'SAMPLE',
  SUBS = 'SUBS',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum VideoDynamicRangeType {
  DOLBY_VISION = 'DOLBY_VISION',
  HDR10 = 'HDR10',
  SDR = 'SDR',
}

export type VideoFreewordSearchResponse = {
  __typename: 'VideoFreewordSearchResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export type VideoHistoryRemovalResponse = {
  __typename: 'VideoHistoryRemovalResponse';
  removed: Scalars['Boolean']['output'];
};

export type VideoProductItem = {
  __typename: 'VideoProductItem';
  code: Scalars['ID']['output'];
  discountRate: Maybe<Scalars['String']['output']>;
  displayButtonText: Scalars['String']['output'];
  displayName: Maybe<Scalars['String']['output']>;
  displaySaleType: Scalars['String']['output'];
  /** from display_valid_span */
  displayValidityDurationText: Scalars['String']['output'];
  displayValidityEndText: Scalars['String']['output'];
  invoice: Scalars['Int']['output'];
  isAdult: Scalars['Boolean']['output'];
  isSale: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  originalPrice: Scalars['Int']['output'];
  pointBalance: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  publicEndDate: Scalars['DateTime']['output'];
  publicStartDate: Scalars['DateTime']['output'];
  purchaseDescription: Maybe<Scalars['String']['output']>;
  saleTypeCode: Scalars['String']['output'];
  segment: Scalars['String']['output'];
  svodAvailableFromText: Scalars['String']['output'];
  /** from valid_span */
  validityDurationMinutes: Scalars['Int']['output'];
};

export type VideoProductList = {
  __typename: 'VideoProductList';
  contractProducts: Array<VideoAssociatedProductItem>;
  ppvProducts: Array<VideoProductItem>;
};

export type VideoProductListcontractProductsArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type VideoProductPurchaseResponse = {
  __typename: 'VideoProductPurchaseResponse';
  product: Maybe<VideoPurchasedProductItem>;
};

/** VideoProfile is a video format set for playing video */
export type VideoProfile = {
  __typename: 'VideoProfile';
  codec: CapyVideoCodec;
  dynamicRange: DynamicRange;
  /** geometry includes vr formats of a vr video. */
  geometry: Maybe<Geometry>;
  resolution: Resolution;
};

export type VideoPurchasedProductItem = {
  __typename: 'VideoPurchasedProductItem';
  code: Scalars['ID']['output'];
  discountRate: Maybe<Scalars['String']['output']>;
  isAdult: Scalars['Boolean']['output'];
  isSale: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  originalPrice: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  publicEndDate: Scalars['DateTime']['output'];
  publicStartDate: Scalars['DateTime']['output'];
  purchaseDescription: Maybe<Scalars['String']['output']>;
  segment: Scalars['String']['output'];
  /** from valid_span */
  validityDurationMinutes: Scalars['Int']['output'];
};

export type VideoRankingResponse = {
  __typename: 'VideoRankingResponse';
  titles: Array<VideoRankingTitle>;
};

export type VideoRankingTitle = {
  __typename: 'VideoRankingTitle';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
};

export enum VideoRankingType {
  ALL = 'ALL',
  ANIME = 'ANIME',
  MOVIE = 'MOVIE',
  TV_SERIES = 'TV_SERIES',
  VARIETY = 'VARIETY',
}

export type VideoRecommendBlock =
  | VideoRecommendFeatureBlock
  | VideoRecommendLiveBlock
  | VideoRecommendTitleBlock;

export type VideoRecommendBlockResponse = {
  __typename: 'VideoRecommendBlockResponse';
  blocks: Array<VideoRecommendBlock>;
  pageInfo: PortalPageInfo;
};

export enum VideoRecommendBlockType {
  FEATURE = 'FEATURE',
  LIVE_BLOCK = 'LIVE_BLOCK',
  SAKUHIN = 'SAKUHIN',
}

export type VideoRecommendFeatureBlock = {
  __typename: 'VideoRecommendFeatureBlock';
  block: RecommendBlock;
  code: Scalars['ID']['output'];
  trackingCode: Scalars['String']['output'];
  type: VideoRecommendBlockType;
};

export type VideoRecommendLiveBlock = {
  __typename: 'VideoRecommendLiveBlock';
  block: LiveBlock;
  code: Scalars['ID']['output'];
  trackingCode: Scalars['String']['output'];
  type: VideoRecommendBlockType;
};

export type VideoRecommendTitleBlock = {
  __typename: 'VideoRecommendTitleBlock';
  code: Scalars['ID']['output'];
  list: Array<Title>;
  name: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  trackingCode: Scalars['String']['output'];
  type: VideoRecommendBlockType;
};

export type VideoSearchResponse = {
  __typename: 'VideoSearchResponse';
  pageInfo: PortalPageInfo;
  /** Returns the the person's name if personCode and personNameCode, which personNameCode and penNameCode can be set, are provided. */
  personName: Maybe<Scalars['String']['output']>;
  titles: Array<Title>;
};

/** Customer info input for a user signing up via u-next video service intake */
export type VideoServiceCustomerInfoInput = {
  /** Address Line 1. Should cover everything until 町 */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Address line 2. Should cover building name, room number etc. */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Should cover everything else that doesn't fit */
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  /** Customer Date of Birth */
  dateOfBirth: Scalars['DateTime']['input'];
  /** Customer email address (will be used for 2-factor when effecting changes on customer info) */
  emailAddress: Scalars['String']['input'];
  /** Customer first name (kanji) */
  firstName: Scalars['String']['input'];
  /** Customer first name (phonetic katakana) */
  firstNameKana: Scalars['String']['input'];
  /** Customer gender */
  gender: GenderInput;
  /** Customer last name (kanji) */
  lastName: Scalars['String']['input'];
  /** Customer last name (phonetic katakana) */
  lastNameKana: Scalars['String']['input'];
  /** Customer Phone number (will be used for 2-factor for issuing cinema coupons) */
  phoneNumber: Scalars['String']['input'];
  /** 7-digit postal code without hyphen */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Prefecture in Kanji. Is coverted into a JIS code in the backend */
  prefecture?: InputMaybe<Scalars['String']['input']>;
};

/** VodContentCode contains information specific to VOD content. */
export type VodContentCode = {
  __typename: 'VodContentCode';
  /** A unique code identifying the VOD content. */
  vodCode: Scalars['String']['output'];
};

/** A Voice plan that can be purchased by a sim user. */
export type VoicePlan = {
  __typename: 'VoicePlan';
  /** Denotes whether or not this voice plan can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  requiredSimTypes: Array<Maybe<SimType>>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** Input for getting voice plan */
export type VoicePlanInput = {
  id: Scalars['ID']['input'];
  runningTypeCode: Scalars['String']['input'];
};

/** Parameters for designating the entry route for a given user creation. This set of parameters is specifically for user enrolling through the website */
export type WebEntryParams = {
  entryCampaignCode?: InputMaybe<Scalars['String']['input']>;
  entryLandingPageCode: Scalars['String']['input'];
  entryOperationCode?: InputMaybe<Scalars['String']['input']>;
  entrySalesAgencyCode: Scalars['String']['input'];
  entryShopCode?: InputMaybe<Scalars['String']['input']>;
};

/** Account info for a linked Yamada Member Account */
export type YamadaAccountInfo = {
  __typename: 'YamadaAccountInfo';
  /** Yamada mobile account info registration id (Yamada mobile user info id) */
  id: Scalars['ID']['output'];
  /** Amount of Yamada points to be deducted to pay for YU Mobile service per month */
  pointsAllocatedForPayment: Scalars['Int']['output'];
};

/**
 * This entity represents a user's account in the Waikiki System. This is NOT the equivalent of a
 * u-next user in the common infrastructure
 */
export type YuMobileAccount = {
  __typename: 'YuMobileAccount';
  /** Currently enrolled account wide options. (DO NOT confuse with SIM options which are options tied to individual SIM users) */
  activeOptions: Maybe<Array<Maybe<RunningOption>>>;
  /** Currently active Plan for this account */
  activePlan: Maybe<RunningPlan>;
  /** Automatic data charge settings */
  autoDataChargeSetting: Maybe<AutoDataChargeSetting>;
  /** Customer information of the contract holder in BOS */
  contractHolder: Maybe<UnextAccount>;
  /** Data Usage Info for this account */
  dataUsageInfo: Maybe<AccountDataUsageInfo>;
  /** Currently in progress sim card deliveries */
  deliveries: Maybe<Array<Maybe<SimDelivery>>>;
  /** Number of active sim users in this account */
  noOfActiveSimUsers: Scalars['Int']['output'];
  /** Number of new sim user applications that are waiting for consent */
  noOfAgreeingSimUserApplications: Scalars['Int']['output'];
  /**
   * Running price for all of the running services in the account based on the BOS system.
   * Note: the price may be different than if you add up all of the Plan, SimTypes, etc. from the account, because those are fetched from the WaikikiApi
   */
  runningPrice: Maybe<RunningPrice>;
  /** Currently active sim user applications */
  simUserApplications: Maybe<Array<Maybe<SimUserApplication>>>;
  /** Currently active sim users */
  simUsers: Maybe<Array<Maybe<SimUser>>>;
  statusCode: MobileAccountStatus;
  statusName: Scalars['String']['output'];
  userPlatformId: Scalars['ID']['output'];
  /** Account version no for backend concurrency control */
  versionNo: Scalars['Int']['output'];
};

/**
 * An option that can be purchased by a user
 * Options are additional services that are applied account wide. Not to be confused with SimOptions
 */
export type YuMobileOption = {
  __typename: 'YuMobileOption';
  /** Denotes whether or not this option can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  optionTypeEnum: Maybe<OptionTypeEnum>;
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

/** A mobile service plan that can be purchased by a user */
export type YuMobilePlan = {
  __typename: 'YuMobilePlan';
  /** Denotes whether or not this plan can be unsubscribed via U-NEXT systems. */
  canBeUnsubscribed: Maybe<Scalars['Boolean']['output']>;
  chargeInterval: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  displayCaption: Maybe<Scalars['String']['output']>;
  displayRunningName: Maybe<Scalars['String']['output']>;
  displaySubCaption: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Denotes whether or not a video U-NEXT subscription is included with this plan */
  includesVideoSubscription: Maybe<Scalars['Boolean']['output']>;
  planTypeEnum: Maybe<PlanTypeEnum>;
  priceTypeCode: Maybe<Scalars['String']['output']>;
  priceTypeName: Maybe<Scalars['String']['output']>;
  runningName: Maybe<Scalars['String']['output']>;
  runningPrice: Maybe<RunningPrice>;
  runningTypeCode: Maybe<Scalars['String']['output']>;
  runningTypeName: Maybe<Scalars['String']['output']>;
  saleTypeCode: Maybe<Scalars['String']['output']>;
  userServiceControlCode: Maybe<Scalars['String']['output']>;
};

export type cosmo_getNewBooksQueryVariables = Exact<{
  tagCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getNewBooksQuery = {
  webfront_newBooks: {
    __typename: 'BookNewArrivalsResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_getFreeBooksQueryVariables = Exact<{
  tagCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getFreeBooksQuery = {
  webfront_freeBooks: {
    __typename: 'BookFreeResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_getPreorderableBooksQueryVariables = Exact<{
  tagCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getPreorderableBooksQuery = {
  webfront_preorderableBooks: {
    __typename: 'BookPreorderableResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_getBookRankingQueryVariables = Exact<{
  targetCode: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_getBookRankingQuery = {
  bookRanking: {
    __typename: 'BookRankingSakuhins';
    rankingHeading: string;
    rankingComment: string;
    displayName: string;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    books: Array<{
      __typename: 'BookRankingSakuhin';
      rankDiff: number;
      isNewRanking: boolean;
      bookSakuhin: {
        __typename: 'BookSakuhin';
        name: string;
        rate: number;
        freeBookNum: number | null;
        isNew: boolean | null;
        isSale: boolean | null;
        minPrice: number | null;
        hasMultiplePrices: boolean | null;
        totalBookNum: number;
        isUnextOriginal: boolean | null;
        bookViewCode: string;
        featurePieceCode: string | null;
        recentLicenseExpiredDatetime: string | null;
        isChapter: boolean;
        hasUnreadChapter: boolean | null;
        isBookSakuhinTicketAvailable: boolean | null;
        isBookPlusTicketAvailable: boolean | null;
        sakuhinCode: string;
        book: {
          __typename: 'Book';
          code: string;
          name: string;
          isPreorderable: boolean | null;
          publicStartDateTime: string | null;
          mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
          thumbnail: { __typename: 'Thumbnail'; standard: string | null };
          publisher: {
            __typename: 'Publisher';
            code: string | null;
            name: string | null;
          } | null;
          credits: Array<{
            __typename: 'BookCredit';
            personCode: string | null;
            penName: string | null;
          }>;
          publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
        } | null;
        paymentBadgeList: Array<{
          __typename: 'PaymentBadge';
          code: PaymentBadgeCode;
        }>;
        subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
      };
    }>;
  };
};

export type cosmo_bookMetaSearchQueryVariables = Exact<{
  metaType: BookMetaType;
  code: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<BookSaleType>;
}>;

export type cosmo_bookMetaSearchQuery = {
  webfront_bookMetaSearch: {
    __typename: 'BookMetaSearchResponse';
    name: string;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
};

export type cosmo_originalBooksQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_originalBooksQuery = {
  originalBooks: {
    __typename: 'BookOriginalTitlesResponse';
    leadTitle: string;
    leadText: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      isNew: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      sakuhinCode: string;
      detail: { __typename: 'BookSakuhinDetail'; catchSentence: string };
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        introduction: string | null;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
          unextPublishingDetail: {
            __typename: 'UnextPublishingDetail';
            thumbnail: {
              __typename: 'BookPublishingDetailThumbnail';
              standard: string | null;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_originalComicsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_originalComicsQuery = {
  originalComics: {
    __typename: 'BookOriginalTitlesResponse';
    leadTitle: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_getUserRecommendedBooksQueryVariables = Exact<{
  tagCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getUserRecommendedBooksQuery = {
  recommendedBooks: {
    __typename: 'BookUserRecommendedResponse';
    results: number | null;
    heading: string;
    comment: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
};

export type cosmo_calendar_settingQueryVariables = Exact<{
  genre: Scalars['String']['input'];
}>;

export type cosmo_calendar_settingQuery = {
  calendarFeatureSettings: {
    __typename: 'CalendarFeatureSettingsResponse';
    enabled: boolean;
    hasEvents: boolean;
    searchableTags: Array<{
      __typename: 'CalendarTag';
      defaultText: string | null;
      key: string;
      values: Array<{
        __typename: 'CalendarTagValue';
        value: string;
        displayName: string | null;
      }>;
    }>;
    displayText: {
      __typename: 'CalendarDisplayText';
      buttonText: string;
      emptyMessage: string;
    } | null;
  };
};

export type cosmo_calendar_eventsQueryVariables = Exact<{
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  tags?: InputMaybe<Array<CalendarSearchTagInput> | CalendarSearchTagInput>;
}>;

export type cosmo_calendar_eventsQuery = {
  calendarEvents: Array<
    | {
        __typename: 'GenericCalendarEvent';
        date: string;
        id: string;
        imageUrl: string | null;
        name: string;
        contentCode:
          | { __typename: 'LiveContentCode'; liveCode: string }
          | { __typename: 'VodContentCode'; vodCode: string };
      }
    | {
        __typename: 'NTeamSportEvent';
        language: string | null;
        state: CalendarEventState;
        league: string | null;
        date: string;
        id: string;
        imageUrl: string | null;
        name: string;
        contentCode:
          | { __typename: 'LiveContentCode'; liveCode: string }
          | { __typename: 'VodContentCode'; vodCode: string };
      }
    | {
        __typename: 'NoTeamSportEvent';
        language: string | null;
        state: CalendarEventState;
        league: string | null;
        date: string;
        id: string;
        imageUrl: string | null;
        name: string;
        contentCode:
          | { __typename: 'LiveContentCode'; liveCode: string }
          | { __typename: 'VodContentCode'; vodCode: string };
      }
    | {
        __typename: 'TwoTeamSportEvent';
        language: string | null;
        state: CalendarEventState;
        round: string | null;
        league: string | null;
        date: string;
        id: string;
        imageUrl: string | null;
        name: string;
        homeTeam: {
          __typename: 'TeamParticipant';
          name: string;
          id: string;
          imageUrl: string | null;
        };
        awayTeam: {
          __typename: 'TeamParticipant';
          name: string;
          id: string;
          imageUrl: string | null;
        };
        contentCode:
          | { __typename: 'LiveContentCode'; liveCode: string }
          | { __typename: 'VodContentCode'; vodCode: string };
      }
  >;
};

export type cosmo_characterQueryVariables = Exact<{
  characterCode: Scalars['ID']['input'];
}>;

export type cosmo_characterQuery = {
  getSakuhinGroup: {
    __typename: 'SakuhinGroup';
    code: string;
    name: string;
  } | null;
};

export type cosmo_characterTitlesQueryVariables = Exact<{
  characterCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<SaleType>;
}>;

export type cosmo_characterTitlesQuery = {
  webfront_searchVideo: {
    __typename: 'VideoSearchResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  };
};

export type cosmo_character_listQueryVariables = Exact<{
  genreCode: Scalars['ID']['input'];
}>;

export type cosmo_character_listQuery = {
  getSakuhinGroupBlock: Array<{
    __typename: 'SakuhinGroup';
    code: string;
    name: string;
    thumbnail: { __typename: 'SakuhinGroupThumbnail'; circle: string };
  }>;
};

export type cosmo_titleBlockDetailQueryVariables = Exact<{
  recommendBlockCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_titleBlockDetailQuery = {
  titleBlockDetail: {
    __typename: 'TitleBlockDetailResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    block: {
      __typename: 'VideoRecommendTitleBlock';
      code: string;
      type: VideoRecommendBlockType;
      trackingCode: string;
      name: string;
      total: number;
      list: Array<{
        __typename: 'Title';
        id: string;
        titleName: string | null;
        isNew: boolean | null;
        catchphrase: string | null;
        rate: number | null;
        productionYear: string | null;
        updateOfWeek: number | null;
        lastEpisode: string | null;
        nfreeBadge: string | null;
        hasSubtitle: boolean | null;
        hasDub: boolean | null;
        productLineupCodeList: Array<string>;
        hasPackRights: boolean;
        hasMultiprice: boolean | null;
        minimumPrice: number | null;
        isOriginal: boolean;
        paymentBadgeList: Array<{
          __typename: 'PaymentBadge';
          code: PaymentBadgeCode;
        }>;
        thumbnail: {
          __typename: 'Thumbnail';
          standard: string | null;
          secondary: string | null;
        } | null;
        exclusive: {
          __typename: 'ExclusiveInfo';
          isOnlyOn: boolean;
          typeCode: ExclusiveBadgeCode | null;
        };
      }>;
    } | null;
  } | null;
};

export type cosmo_getOneTimeTokenQueryVariables = Exact<{
  [key: string]: never;
}>;

export type cosmo_getOneTimeTokenQuery = {
  webfront_oneTimeToken: {
    __typename: 'OneTimeTokenResponse';
    token: string;
  } | null;
};

export type cosmo_purchaseAdultRightsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type cosmo_purchaseAdultRightsMutation = {
  purchaseAdultRights: {
    __typename: 'PurchaseAdultRightsResponse';
    isPurchased: boolean;
  };
};

export type cosmo_moreOptions_removeVideoHistoryMutationVariables = Exact<{
  titleId: Scalars['ID']['input'];
}>;

export type cosmo_moreOptions_removeVideoHistoryMutation = {
  removeVideoHistory: {
    __typename: 'VideoHistoryRemovalResponse';
    removed: boolean;
  };
};

export type cosmo_leadTitleQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_leadTitleQuery = {
  webfront_leadTitle: {
    __typename: 'LeadTitle';
    leadCode: string;
    titleCode: string;
    text: string;
    thumbnail: {
      __typename: 'LeadTitleThumbnail';
      small: string;
      large: string;
    };
  };
};

export type cosmo_upsellQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_upsellQuery = {
  webfront_upsell: { __typename: 'Upsell'; canDisplay: boolean };
};

export type cosmo_upsell_guideQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_upsell_guideQuery = {
  webfront_upsell: {
    __typename: 'Upsell';
    guide: {
      __typename: 'UpsellGuide';
      destinationUrl: string;
      subscriptionDisabled: {
        __typename: 'UpsellGuideSubscriptionDisabled';
        code: string;
      } | null;
    };
  };
};

export type cosmo_homeQueryVariables = Exact<{
  infoCheck?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_homeQuery = {
  webfront_infoList: Array<{
    __typename: 'InfoListItem';
    title: string;
    url: string;
    ttl: string;
  }>;
  labelBlock: {
    __typename: 'LabelBlock';
    name: string;
    labelList: Array<{
      __typename: 'Label';
      code: string;
      name: string;
      catch: string;
      genreCode: string;
      searchMenuList: Array<{
        __typename: 'SearchCategory';
        id: string;
        name: string;
        menuTypeCode: string;
        parentMenuCode: string | null;
        parentMenuName: string | null;
        defaultSortOrder: PortalSortOrder;
        captionDubFlag: boolean;
      }>;
      thumbnail: {
        __typename: 'LabelThumbnail';
        standard: string;
        secondary: string;
        logo: string;
      };
    }>;
  };
};

export type cosmo_genreQueryVariables = Exact<{
  genreId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  titlesPerBlock: Scalars['Int']['input'];
  livesPerBlock: Scalars['Int']['input'];
}>;

export type cosmo_genreQuery = {
  getSakuhinGroupBlock: Array<{
    __typename: 'SakuhinGroup';
    code: string;
    name: string;
    thumbnail: { __typename: 'SakuhinGroupThumbnail'; circle: string };
  }>;
  webfront_linearChannels: Array<{
    __typename: 'Channel';
    id: string;
    name: string;
    description: string;
    thumbnail: { __typename: 'Thumbnail'; standard: string | null };
  }>;
  leagueBlock: {
    __typename: 'LeagueBlock';
    name: string;
    list: Array<{
      __typename: 'LeagueInfo';
      name: string;
      code: string;
      tagCode: string;
      thumbnail: { __typename: 'LeagueThumbnail'; standard: string };
    }>;
  } | null;
  liveBlocks: {
    __typename: 'LiveBlocksResponse';
    blockList: Array<{
      __typename: 'LiveBlock';
      code: string;
      name: string;
      structureType: LiveBlockStructureType;
      trackingCode: string;
      list: Array<{
        __typename: 'LiveBlockLiveGroupResponse';
        code: string | null;
        name: string | null;
        total: number;
        liveList: Array<{
          __typename: 'Live';
          id: string;
          name: string;
          deliveryStartDateTime: string;
          deliveryEndDateTime: string;
          performanceStartDateTime: string;
          isOnlyOn: boolean;
          saleTypeCode: string;
          allowsTimeshiftedPlayback: boolean;
          hasPackRights: boolean;
          productLineupCodeList: Array<string>;
          subContentList: Array<{
            __typename: 'LiveSubContent';
            typeCode: string;
            publicStartDateTime: string | null;
            publicEndDateTime: string;
          }> | null;
          tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
          notices: Array<{
            __typename: 'LiveNotice';
            publicStartDateTime: string;
            thumbnail: { __typename: 'Thumbnail'; standard: string | null };
          }>;
          paymentBadgeList: Array<{
            __typename: 'PaymentBadge';
            code: PaymentBadgeCode;
          }>;
        }>;
      }>;
    }>;
  };
  webfront_recommendBlocks: {
    __typename: 'RecommendBlockResponse';
    recommendBlocks: Array<{
      __typename: 'RecommendBlock';
      id: string;
      featureName: string | null;
      featureTypeCode: string | null;
      featurePickupFlag: boolean | null;
      leadText: string | null;
      titleResults: number | null;
      titles: Array<{
        __typename: 'Title';
        id: string;
        titleName: string | null;
        isNew: boolean | null;
        catchphrase: string | null;
        rate: number | null;
        productionYear: string | null;
        updateOfWeek: number | null;
        lastEpisode: string | null;
        nfreeBadge: string | null;
        hasSubtitle: boolean | null;
        hasDub: boolean | null;
        productLineupCodeList: Array<string>;
        hasPackRights: boolean;
        hasMultiprice: boolean | null;
        minimumPrice: number | null;
        isOriginal: boolean;
        thumbnail: {
          __typename: 'Thumbnail';
          standard: string | null;
          secondary: string | null;
        } | null;
        paymentBadgeList: Array<{
          __typename: 'PaymentBadge';
          code: PaymentBadgeCode;
        }>;
        exclusive: {
          __typename: 'ExclusiveInfo';
          isOnlyOn: boolean;
          typeCode: ExclusiveBadgeCode | null;
        };
      }>;
      titleComments: Array<{
        __typename: 'FeatureTitleComment';
        titleCode: string;
        comment: string | null;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
};

export type cosmo_getUserBlocksQueryVariables = Exact<{
  favoriteTitlesSortOrder: PortalSortOrder;
  titlesPerBlock: Scalars['Int']['input'];
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
}>;

export type cosmo_getUserBlocksQuery = {
  webfront_watchingEpisodes: Array<{
    __typename: 'Episode';
    id: string;
    episodeName: string | null;
    displayNo: string | null;
    interruption: number | null;
    duration: number | null;
    completeFlag: boolean | null;
    episodeTitleInfo: {
      __typename: 'EpisodeTitleInfo';
      id: string;
      name: string;
    } | null;
    thumbnail: { __typename: 'Thumbnail'; standard: string | null } | null;
  }> | null;
  webfront_favoriteTitles: {
    __typename: 'FavoriteTitlesResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
  bookHistory: {
    __typename: 'BookReadMoreResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        bookContent: {
          __typename: 'BookContent';
          mainBookFile: {
            __typename: 'BookFile';
            resumePoint: number;
            completeFlg: boolean;
          } | null;
        } | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
  recommendedBooks: {
    __typename: 'BookUserRecommendedResponse';
    results: number | null;
    heading: string;
    comment: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
};

export type cosmo_bookRecommendBlocksQueryVariables = Exact<{
  genreId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  titlesPerBlock: Scalars['Int']['input'];
}>;

export type cosmo_bookRecommendBlocksQuery = {
  bookRecommendBlocks: {
    __typename: 'BookRecommendBlockResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    recommendBlocks: Array<
      | {
          __typename: 'BookBlock';
          type: BlockType;
          code: string;
          results: number;
          books: Array<{
            __typename: 'BookSakuhin';
            name: string;
            rate: number;
            freeBookNum: number | null;
            isNew: boolean | null;
            isSale: boolean | null;
            minPrice: number | null;
            hasMultiplePrices: boolean | null;
            totalBookNum: number;
            isUnextOriginal: boolean | null;
            bookViewCode: string;
            featurePieceCode: string | null;
            recentLicenseExpiredDatetime: string | null;
            isChapter: boolean;
            hasUnreadChapter: boolean | null;
            isBookSakuhinTicketAvailable: boolean | null;
            isBookPlusTicketAvailable: boolean | null;
            sakuhinCode: string;
            book: {
              __typename: 'Book';
              code: string;
              name: string;
              isPreorderable: boolean | null;
              publicStartDateTime: string | null;
              mediaType: {
                __typename: 'BookMediaType';
                code: BookMediaTypeCode;
              };
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
              publisher: {
                __typename: 'Publisher';
                code: string | null;
                name: string | null;
              } | null;
              credits: Array<{
                __typename: 'BookCredit';
                personCode: string | null;
                penName: string | null;
              }>;
              publishedIn: Array<{
                __typename: 'Magazine';
                name: string | null;
              }>;
            } | null;
            paymentBadgeList: Array<{
              __typename: 'PaymentBadge';
              code: PaymentBadgeCode;
            }>;
            subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
          }>;
        }
      | {
          __typename: 'BookFeatureBlock';
          type: BlockType;
          code: string;
          trackingCode: string;
          feature: {
            __typename: 'BookFeature';
            name: string;
            code: string;
            comment: string;
            typeCode: string;
            displayEnd: string;
            results: number;
            books: Array<{
              __typename: 'BookSakuhin';
              name: string;
              rate: number;
              freeBookNum: number | null;
              isNew: boolean | null;
              isSale: boolean | null;
              minPrice: number | null;
              hasMultiplePrices: boolean | null;
              totalBookNum: number;
              isUnextOriginal: boolean | null;
              bookViewCode: string;
              featurePieceCode: string | null;
              recentLicenseExpiredDatetime: string | null;
              isChapter: boolean;
              hasUnreadChapter: boolean | null;
              isBookSakuhinTicketAvailable: boolean | null;
              isBookPlusTicketAvailable: boolean | null;
              sakuhinCode: string;
              book: {
                __typename: 'Book';
                code: string;
                name: string;
                isPreorderable: boolean | null;
                publicStartDateTime: string | null;
                mediaType: {
                  __typename: 'BookMediaType';
                  code: BookMediaTypeCode;
                };
                thumbnail: { __typename: 'Thumbnail'; standard: string | null };
                publisher: {
                  __typename: 'Publisher';
                  code: string | null;
                  name: string | null;
                } | null;
                credits: Array<{
                  __typename: 'BookCredit';
                  personCode: string | null;
                  penName: string | null;
                }>;
                publishedIn: Array<{
                  __typename: 'Magazine';
                  name: string | null;
                }>;
              } | null;
              paymentBadgeList: Array<{
                __typename: 'PaymentBadge';
                code: PaymentBadgeCode;
              }>;
              subgenreTagList: Array<{
                __typename: 'SubgenreTag';
                name: string;
              }>;
            }>;
          };
        }
      | { __typename: 'BookLeanbackBlock' }
      | {
          __typename: 'BookOriginalBlock';
          type: BlockType;
          code: string;
          results: number;
          leadTitle: string;
          books: Array<{
            __typename: 'BookSakuhin';
            name: string;
            isNew: boolean | null;
            bookViewCode: string;
            featurePieceCode: string | null;
            sakuhinCode: string;
            detail: { __typename: 'BookSakuhinDetail'; catchSentence: string };
            book: {
              __typename: 'Book';
              code: string;
              name: string;
              introduction: string | null;
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
              credits: Array<{
                __typename: 'BookCredit';
                personCode: string | null;
                penName: string | null;
                unextPublishingDetail: {
                  __typename: 'UnextPublishingDetail';
                  thumbnail: {
                    __typename: 'BookPublishingDetailThumbnail';
                    standard: string | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
        }
      | {
          __typename: 'BookRankingBlock';
          type: BlockType;
          code: string;
          trackingCode: string;
          results: number;
          books: Array<{
            __typename: 'BookSakuhin';
            name: string;
            rate: number;
            freeBookNum: number | null;
            isNew: boolean | null;
            isSale: boolean | null;
            minPrice: number | null;
            hasMultiplePrices: boolean | null;
            totalBookNum: number;
            isUnextOriginal: boolean | null;
            bookViewCode: string;
            featurePieceCode: string | null;
            recentLicenseExpiredDatetime: string | null;
            isChapter: boolean;
            hasUnreadChapter: boolean | null;
            isBookSakuhinTicketAvailable: boolean | null;
            isBookPlusTicketAvailable: boolean | null;
            sakuhinCode: string;
            book: {
              __typename: 'Book';
              code: string;
              name: string;
              isPreorderable: boolean | null;
              publicStartDateTime: string | null;
              mediaType: {
                __typename: 'BookMediaType';
                code: BookMediaTypeCode;
              };
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
              publisher: {
                __typename: 'Publisher';
                code: string | null;
                name: string | null;
              } | null;
              credits: Array<{
                __typename: 'BookCredit';
                personCode: string | null;
                penName: string | null;
              }>;
              publishedIn: Array<{
                __typename: 'Magazine';
                name: string | null;
              }>;
            } | null;
            paymentBadgeList: Array<{
              __typename: 'PaymentBadge';
              code: PaymentBadgeCode;
            }>;
            subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
          }>;
          rankings: Array<{
            __typename: 'BookRanking';
            rankDiff: number;
            isNewRanking: boolean;
          }>;
          targets: Array<{
            __typename: 'BookRankingTarget';
            code: string;
            displayName: string;
            books: Array<{
              __typename: 'BookSakuhin';
              name: string;
              rate: number;
              freeBookNum: number | null;
              isNew: boolean | null;
              isSale: boolean | null;
              minPrice: number | null;
              hasMultiplePrices: boolean | null;
              totalBookNum: number;
              isUnextOriginal: boolean | null;
              bookViewCode: string;
              featurePieceCode: string | null;
              recentLicenseExpiredDatetime: string | null;
              isChapter: boolean;
              hasUnreadChapter: boolean | null;
              isBookSakuhinTicketAvailable: boolean | null;
              isBookPlusTicketAvailable: boolean | null;
              sakuhinCode: string;
              book: {
                __typename: 'Book';
                code: string;
                name: string;
                isPreorderable: boolean | null;
                publicStartDateTime: string | null;
                mediaType: {
                  __typename: 'BookMediaType';
                  code: BookMediaTypeCode;
                };
                thumbnail: { __typename: 'Thumbnail'; standard: string | null };
                publisher: {
                  __typename: 'Publisher';
                  code: string | null;
                  name: string | null;
                } | null;
                credits: Array<{
                  __typename: 'BookCredit';
                  personCode: string | null;
                  penName: string | null;
                }>;
                publishedIn: Array<{
                  __typename: 'Magazine';
                  name: string | null;
                }>;
              } | null;
              paymentBadgeList: Array<{
                __typename: 'PaymentBadge';
                code: PaymentBadgeCode;
              }>;
              subgenreTagList: Array<{
                __typename: 'SubgenreTag';
                name: string;
              }>;
            }>;
            rankings: Array<{
              __typename: 'BookRanking';
              rankDiff: number;
              isNewRanking: boolean;
            }>;
          }>;
        }
      | { __typename: 'BookSearchCategoryBlock' }
      | {
          __typename: 'BookSpotlightBlock';
          type: BlockType;
          code: string;
          spotlightList: Array<{
            __typename: 'BookSpotLight';
            name: string;
            code: string;
            linkTypeCode: string;
            thumbnail: { __typename: 'Thumbnail'; secondary: string | null };
            sakuhin: {
              __typename: 'BookSpotLightSakuhin';
              name: string;
              code: string;
            };
            feature: {
              __typename: 'BookSpotLightFeature';
              name: string;
              code: string;
            };
            featureTemplate: {
              __typename: 'BookSpotLightFeatureTemplate';
              name: string;
              code: string;
            };
          }>;
        }
      | {
          __typename: 'BookTemplateBlock';
          type: BlockType;
          code: string;
          trackingCode: string;
          template: {
            __typename: 'FeatureTemplate';
            heading: string | null;
            displayEnd: string;
            code: string;
            typeCode: string;
            url: string | null;
            lead: string | null;
            name: string;
            featureArtwork: {
              __typename: 'FeatureArtwork';
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
            };
          };
        }
      | {
          __typename: 'BookUserRecommendedBlock';
          type: BlockType;
          code: string;
          results: number;
          heading: string;
          comment: string;
          books: Array<{
            __typename: 'BookSakuhin';
            name: string;
            rate: number;
            freeBookNum: number | null;
            isNew: boolean | null;
            isSale: boolean | null;
            minPrice: number | null;
            hasMultiplePrices: boolean | null;
            totalBookNum: number;
            isUnextOriginal: boolean | null;
            bookViewCode: string;
            featurePieceCode: string | null;
            recentLicenseExpiredDatetime: string | null;
            isChapter: boolean;
            hasUnreadChapter: boolean | null;
            isBookSakuhinTicketAvailable: boolean | null;
            isBookPlusTicketAvailable: boolean | null;
            sakuhinCode: string;
            book: {
              __typename: 'Book';
              code: string;
              name: string;
              isPreorderable: boolean | null;
              publicStartDateTime: string | null;
              mediaType: {
                __typename: 'BookMediaType';
                code: BookMediaTypeCode;
              };
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
              publisher: {
                __typename: 'Publisher';
                code: string | null;
                name: string | null;
              } | null;
              credits: Array<{
                __typename: 'BookCredit';
                personCode: string | null;
                penName: string | null;
              }>;
              publishedIn: Array<{
                __typename: 'Magazine';
                name: string | null;
              }>;
            } | null;
            paymentBadgeList: Array<{
              __typename: 'PaymentBadge';
              code: PaymentBadgeCode;
            }>;
            subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
          }>;
        }
      | {
          __typename: 'MagazineBlock';
          type: BlockType;
          code: string;
          name: string;
          results: number;
          magazineGenreCode: string;
          trackingCode: string;
          books: Array<{
            __typename: 'BookSakuhin';
            name: string;
            rate: number;
            freeBookNum: number | null;
            isNew: boolean | null;
            isSale: boolean | null;
            minPrice: number | null;
            hasMultiplePrices: boolean | null;
            totalBookNum: number;
            isUnextOriginal: boolean | null;
            bookViewCode: string;
            featurePieceCode: string | null;
            recentLicenseExpiredDatetime: string | null;
            isChapter: boolean;
            hasUnreadChapter: boolean | null;
            isBookSakuhinTicketAvailable: boolean | null;
            isBookPlusTicketAvailable: boolean | null;
            sakuhinCode: string;
            book: {
              __typename: 'Book';
              code: string;
              name: string;
              isPreorderable: boolean | null;
              publicStartDateTime: string | null;
              mediaType: {
                __typename: 'BookMediaType';
                code: BookMediaTypeCode;
              };
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
              publisher: {
                __typename: 'Publisher';
                code: string | null;
                name: string | null;
              } | null;
              credits: Array<{
                __typename: 'BookCredit';
                personCode: string | null;
                penName: string | null;
              }>;
              publishedIn: Array<{
                __typename: 'Magazine';
                name: string | null;
              }>;
            } | null;
            paymentBadgeList: Array<{
              __typename: 'PaymentBadge';
              code: PaymentBadgeCode;
            }>;
            subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
          }>;
        }
      | {
          __typename: 'OriginalComicBlock';
          type: BlockType;
          code: string;
          results: number;
          leadTitle: string;
          books: Array<{
            __typename: 'BookSakuhin';
            name: string;
            isNew: boolean | null;
            bookViewCode: string;
            featurePieceCode: string | null;
            sakuhinCode: string;
            detail: { __typename: 'BookSakuhinDetail'; catchSentence: string };
            book: {
              __typename: 'Book';
              code: string;
              name: string;
              introduction: string | null;
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
              credits: Array<{
                __typename: 'BookCredit';
                personCode: string | null;
                penName: string | null;
                unextPublishingDetail: {
                  __typename: 'UnextPublishingDetail';
                  thumbnail: {
                    __typename: 'BookPublishingDetailThumbnail';
                    standard: string | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
        }
    >;
  };
};

export type cosmo_getBookCategoryBlockQueryVariables = Exact<{
  genreId: Scalars['ID']['input'];
}>;

export type cosmo_getBookCategoryBlockQuery = {
  bookSearchCategoryBlock: {
    __typename: 'BookSearchCategoryBlock';
    type: BlockType;
    code: string;
    name: string;
    menuList: Array<{
      __typename: 'BookSearchCategory';
      name: string;
      code: string;
      defaultSortOrder: PortalSortOrder;
      typeCode: string;
    }>;
  } | null;
};

export type cosmo_videoRecommendBlocksQueryVariables = Exact<{
  genreCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  numPerBlock: Scalars['Int']['input'];
}>;

export type cosmo_videoRecommendBlocksQuery = {
  videoRecommendBlocks: {
    __typename: 'VideoRecommendBlockResponse';
    blocks: Array<
      | {
          __typename: 'VideoRecommendFeatureBlock';
          code: string;
          type: VideoRecommendBlockType;
          trackingCode: string;
          block: {
            __typename: 'RecommendBlock';
            id: string;
            featureName: string | null;
            featureTypeCode: string | null;
            featurePickupFlag: boolean | null;
            leadText: string | null;
            titleResults: number | null;
            titles: Array<{
              __typename: 'Title';
              id: string;
              titleName: string | null;
              isNew: boolean | null;
              catchphrase: string | null;
              rate: number | null;
              productionYear: string | null;
              updateOfWeek: number | null;
              lastEpisode: string | null;
              nfreeBadge: string | null;
              hasSubtitle: boolean | null;
              hasDub: boolean | null;
              productLineupCodeList: Array<string>;
              hasPackRights: boolean;
              hasMultiprice: boolean | null;
              minimumPrice: number | null;
              isOriginal: boolean;
              thumbnail: {
                __typename: 'Thumbnail';
                standard: string | null;
                secondary: string | null;
              } | null;
              paymentBadgeList: Array<{
                __typename: 'PaymentBadge';
                code: PaymentBadgeCode;
              }>;
              exclusive: {
                __typename: 'ExclusiveInfo';
                isOnlyOn: boolean;
                typeCode: ExclusiveBadgeCode | null;
              };
            }>;
            titleComments: Array<{
              __typename: 'FeatureTitleComment';
              titleCode: string;
              comment: string | null;
            }>;
          };
        }
      | {
          __typename: 'VideoRecommendLiveBlock';
          code: string;
          type: VideoRecommendBlockType;
          trackingCode: string;
          block: {
            __typename: 'LiveBlock';
            code: string;
            name: string;
            structureType: LiveBlockStructureType;
            trackingCode: string;
            list: Array<{
              __typename: 'LiveBlockLiveGroupResponse';
              code: string | null;
              name: string | null;
              total: number;
              liveList: Array<{
                __typename: 'Live';
                id: string;
                name: string;
                deliveryStartDateTime: string;
                deliveryEndDateTime: string;
                performanceStartDateTime: string;
                isOnlyOn: boolean;
                saleTypeCode: string;
                allowsTimeshiftedPlayback: boolean;
                hasPackRights: boolean;
                productLineupCodeList: Array<string>;
                subContentList: Array<{
                  __typename: 'LiveSubContent';
                  typeCode: string;
                  publicStartDateTime: string | null;
                  publicEndDateTime: string;
                }> | null;
                tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
                notices: Array<{
                  __typename: 'LiveNotice';
                  publicStartDateTime: string;
                  thumbnail: {
                    __typename: 'Thumbnail';
                    standard: string | null;
                  };
                }>;
                paymentBadgeList: Array<{
                  __typename: 'PaymentBadge';
                  code: PaymentBadgeCode;
                }>;
              }>;
            }>;
          };
        }
      | {
          __typename: 'VideoRecommendTitleBlock';
          code: string;
          type: VideoRecommendBlockType;
          trackingCode: string;
          name: string;
          total: number;
          list: Array<{
            __typename: 'Title';
            id: string;
            titleName: string | null;
            isNew: boolean | null;
            catchphrase: string | null;
            rate: number | null;
            productionYear: string | null;
            updateOfWeek: number | null;
            lastEpisode: string | null;
            nfreeBadge: string | null;
            hasSubtitle: boolean | null;
            hasDub: boolean | null;
            productLineupCodeList: Array<string>;
            hasPackRights: boolean;
            hasMultiprice: boolean | null;
            minimumPrice: number | null;
            isOriginal: boolean;
            paymentBadgeList: Array<{
              __typename: 'PaymentBadge';
              code: PaymentBadgeCode;
            }>;
            thumbnail: {
              __typename: 'Thumbnail';
              standard: string | null;
              secondary: string | null;
            } | null;
            exclusive: {
              __typename: 'ExclusiveInfo';
              isOnlyOn: boolean;
              typeCode: ExclusiveBadgeCode | null;
            };
          }>;
        }
    >;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
};

export type cosmo_labelQueryVariables = Exact<{
  labelCode: Scalars['ID']['input'];
}>;

export type cosmo_labelQuery = {
  label: {
    __typename: 'Label';
    code: string;
    name: string;
    catch: string;
    genreCode: string;
    searchMenuList: Array<{
      __typename: 'SearchCategory';
      id: string;
      name: string;
      menuTypeCode: string;
      parentMenuCode: string | null;
      parentMenuName: string | null;
      defaultSortOrder: PortalSortOrder;
      captionDubFlag: boolean;
    }>;
    thumbnail: {
      __typename: 'LabelThumbnail';
      standard: string;
      secondary: string;
      logo: string;
    };
  };
};

export type cosmo_leagueQueryVariables = Exact<{
  leagueCode: Scalars['ID']['input'];
}>;

export type cosmo_leagueQuery = {
  leagueDetail: {
    __typename: 'LeagueDetail';
    league: {
      __typename: 'LeagueInfo';
      name: string;
      code: string;
      tagCode: string;
      thumbnail: { __typename: 'LeagueThumbnail'; secondary: string };
    };
  } | null;
};

export type cosmo_liveGroupQueryVariables = Exact<{
  liveGroupCode: Scalars['ID']['input'];
  genreCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_liveGroupQuery = {
  liveGroup: {
    __typename: 'LiveGroupResponse';
    code: string;
    name: string;
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
};

export type cosmo_liveBlockDetailQueryVariables = Exact<{
  liveBlockCode: Scalars['String']['input'];
  genreCode?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_liveBlockDetailQuery = {
  liveBlockDetail: {
    __typename: 'LiveBlockDetailResponse';
    structureType: LiveBlockStructureType;
    trackingCode: string;
    code: string;
    name: string;
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_getFavoriteTitlesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
}>;

export type cosmo_getFavoriteTitlesQuery = {
  favoriteTitles: {
    __typename: 'FavoriteTitlesResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  } | null;
};

export type cosmo_historyTitlesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_historyTitlesQuery = {
  historyTitles: {
    __typename: 'HistoryTitlesResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_purchasedTitlesQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
}>;

export type cosmo_purchasedTitlesQuery = {
  purchasedTitles: {
    __typename: 'PurchasedTitlesResponse';
    titles: Array<{
      __typename: 'Title';
      closestExpiryDateText: string | null;
      boughtEpisodeNumberTextList: Array<string> | null;
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_removeTitleFavoritesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type cosmo_removeTitleFavoritesMutation = {
  updateTitleFavorite: { __typename: 'TitleStage'; id: string } | null;
};

export type cosmo_purchasedLiveListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_purchasedLiveListQuery = {
  purchasedLives: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_bookFavoritesQueryVariables = Exact<{
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
}>;

export type cosmo_bookFavoritesQuery = {
  favoriteBooks: {
    __typename: 'FavoriteBooksResponse';
    pageInfoHash: {
      __typename: 'PageInfoHash';
      pageSize: number;
      nextPageHash: string;
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  };
};

export type cosmo_removeBookFavoritesMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type cosmo_removeBookFavoritesMutation = {
  bulkRemoveBookTitleFavorite: Array<string>;
};

export type cosmo_bookHistoryQueryVariables = Exact<{
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchWord?: InputMaybe<Scalars['String']['input']>;
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
}>;

export type cosmo_bookHistoryQuery = {
  bookHistory: {
    __typename: 'BookHistoryResponse';
    pageInfoHash: {
      __typename: 'PageInfoHash';
      pageSize: number;
      nextPageHash: string;
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
};

export type cosmo_bookPurchasedQueryVariables = Exact<{
  nextPageHash?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  sortOrder: PortalSortOrder;
  volumeGroupType?: InputMaybe<BookVolumeGroupTypeCode>;
}>;

export type cosmo_bookPurchasedQuery = {
  bookPurchased: {
    __typename: 'PurchasedBooksResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      purchasedBooks: Array<{ __typename: 'PurchasedBook'; bookCode: string }>;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfoHash: {
      __typename: 'PageInfoHash';
      pageSize: number;
      nextPageHash: string;
    };
  };
};

export type cosmo_removeVideoHistoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type cosmo_removeVideoHistoryMutation = {
  removeVideoHistory: {
    __typename: 'VideoHistoryRemovalResponse';
    removed: boolean;
  };
};

export type cosmo_removeAllVideoHistoryMutationVariables = Exact<{
  [key: string]: never;
}>;

export type cosmo_removeAllVideoHistoryMutation = {
  removeAllVideoHistory: {
    __typename: 'VideoHistoryRemovalResponse';
    removed: boolean;
  };
};

export type cosmo_removeBookHistoryMutationVariables = Exact<{
  bookSakuhinCodes: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type cosmo_removeBookHistoryMutation = {
  removeBookHistory: {
    __typename: 'BookHistoryRemovalResponse';
    bookSakuhinCodes: Array<string>;
  };
};

export type cosmo_removeAllBookHistoryMutationVariables = Exact<{
  [key: string]: never;
}>;

export type cosmo_removeAllBookHistoryMutation = {
  removeAllBookHistory: {
    __typename: 'BookHistoryRemovalResponse';
    removedAll: boolean;
  };
};

export type cosmo_removeBookPurchaseMutationVariables = Exact<{
  bookSakuhinCodes: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type cosmo_removeBookPurchaseMutation = {
  removeBookPurchase: {
    __typename: 'BookPurchaseRemoveResponse';
    bookSakuhinCodes: Array<string>;
  };
};

export type cosmo_allPersonSearchQueryVariables = Exact<{
  personCode: Scalars['ID']['input'];
  personNameCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  mainGenreId?: InputMaybe<Scalars['String']['input']>;
  videoSortOrder: PortalSortOrder;
  bookSortOrder: PortalSortOrder;
}>;

export type cosmo_allPersonSearchQuery = {
  webfront_searchVideo: {
    __typename: 'VideoSearchResponse';
    personName: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  webfront_bookPerson: {
    __typename: 'BookPersonResponse';
    name: string | null;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  personLives: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_personSearchVideoQueryVariables = Exact<{
  personCode: Scalars['ID']['input'];
  personNameCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<SaleType>;
  mainGenreId?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_personSearchVideoQuery = {
  webfront_searchVideo: {
    __typename: 'VideoSearchResponse';
    personName: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_personSearchBookQueryVariables = Exact<{
  personCode: Scalars['ID']['input'];
  personNameCode?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<BookSaleType>;
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  onlyChapter?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_personSearchBookQuery = {
  webfront_bookPerson: {
    __typename: 'BookPersonResponse';
    name: string | null;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_personSearchLiveQueryVariables = Exact<{
  personCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  code: Scalars['ID']['input'];
}>;

export type cosmo_personSearchLiveQuery = {
  personLives: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  webfront_person: {
    __typename: 'PersonWebfront';
    personName: string;
    personCode: string;
  } | null;
};

export type webfront_seriesRelatedTitlesQueryVariables = Exact<{
  seriesCode: Scalars['ID']['input'];
}>;

export type webfront_seriesRelatedTitlesQuery = {
  webfront_seriesRelatedTitles: Array<{
    __typename: 'TitleRelationGroup';
    id: string;
    groupName: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  }>;
};

export type cosmo_videoRankingQueryVariables = Exact<{
  rankingType: VideoRankingType;
  isCheck?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_videoRankingQuery = {
  webfront_videoRanking: {
    __typename: 'VideoRankingResponse';
    titles: Array<{
      __typename: 'VideoRankingTitle';
      id: string;
      thumbnailUrl: string;
      name: string;
    }>;
  };
};

export type cosmo_bookViewerQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  bookCode?: InputMaybe<Scalars['ID']['input']>;
}>;

export type cosmo_bookViewerQuery = {
  bookTitle: {
    __typename: 'BookSakuhin';
    name: string;
    rate: number;
    userRate: number | null;
    isFavorite: boolean | null;
    totalBookNum: number;
    purchaseBookNum: number | null;
    totalBookNumIncludingPreorders: number | null;
    isUnextOriginal: boolean | null;
    isNew: boolean | null;
    bookNumForTicket: number;
    nextReleaseDatetime: string | null;
    ticketEndDatetime: string | null;
    hasUnreadChapter: boolean | null;
    isBookSakuhinTicketAvailable: boolean | null;
    isBookPlusTicketAvailable: boolean | null;
    plusTicketEndDateTime: string | null;
    minPrice: number | null;
    hasMultiplePrices: boolean | null;
    isCompleted: boolean;
    freeBookNum: number | null;
    isSale: boolean | null;
    isChapter: boolean;
    freeSaleText: string | null;
    sakuhinCode: string;
    book: {
      __typename: 'Book';
      hasRights: boolean | null;
      code: string;
      name: string;
      isNew: boolean | null;
      isPreorderable: boolean | null;
      isPreordered: boolean | null;
      isFree: boolean | null;
      isPurchased: boolean | null;
      isSale: boolean | null;
      isWebtoon: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      isInReadingHistory: boolean | null;
      saleText: string | null;
      introduction: string | null;
      bookNo: number;
      publicStartDateTime: string | null;
      publicEndDateTime: string | null;
      originalSaleDate: string | null;
      rightsExpirationDatetime: string | null;
      paymentBadge: {
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      } | null;
      bookContent: {
        __typename: 'BookContent';
        productList: Array<{
          __typename: 'BookProduct';
          code: string | null;
          saleTypeCode: string | null;
          salePeriod: { __typename: 'SalePeriod'; price: number | null } | null;
        }>;
        mainBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
          licenseExpiredDateTime: string | null;
        } | null;
        sampleBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
        } | null;
      } | null;
      bookMagazine: {
        __typename: 'BookMagazine';
        magazineGenreCode: string | null;
        bookMagazineCode: string;
        bookMagazineName: string | null;
      } | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      publisher: {
        __typename: 'Publisher';
        code: string | null;
        name: string | null;
      } | null;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
      credits: Array<{
        __typename: 'BookCredit';
        bookAuthorType: string | null;
        personCode: string | null;
        penName: string | null;
        penNameCode: string | null;
        unextPublishingDetail: {
          __typename: 'UnextPublishingDetail';
          introduction: string | null;
          thumbnail: {
            __typename: 'BookPublishingDetailThumbnail';
            standard: string | null;
          } | null;
        } | null;
      }>;
      publishedIn: Array<{
        __typename: 'Magazine';
        code: string | null;
        name: string | null;
      }>;
      includedBook: {
        __typename: 'IncludedBook';
        code: string;
        name: string;
        sakuhinCode: string;
        bookNumber: number;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      } | null;
    } | null;
    bookTickets: {
      __typename: 'BookTickets';
      sakuhinTicket: {
        __typename: 'BookTicket';
        code: string;
        typeCode: string;
        typeName: string;
        availableCount: number;
        receivedCount: number;
        nextDeliveryDatetime: string | null;
        nextDeliveryCount: number | null;
      } | null;
      plusTicket: {
        __typename: 'BookTicket';
        code: string;
        typeCode: string;
        typeName: string;
        availableCount: number;
        receivedCount: number;
        nextDeliveryDatetime: string | null;
        nextDeliveryCount: number | null;
      } | null;
    } | null;
    bookLabel: {
      __typename: 'BookLabel';
      code: string | null;
      name: string | null;
    } | null;
    detail: {
      __typename: 'BookSakuhinDetail';
      introduction: string;
      catchSentence: string;
    };
    paymentBadgeList: Array<{
      __typename: 'PaymentBadge';
      code: PaymentBadgeCode;
      name: string;
    }>;
    subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    thumbnail: { __typename: 'BookSakuhinThumbnails'; standard: string };
  };
};

export type cosmo_bookPostplayQueryVariables = Exact<{
  bookCode?: InputMaybe<Scalars['ID']['input']>;
}>;

export type cosmo_bookPostplayQuery = {
  bookTitle_postPlay:
    | { __typename: 'BookPostPlayEmptyResponse' }
    | {
        __typename: 'BookPostPlayNextBookResult';
        nextBook: {
          __typename: 'BookSakuhin';
          name: string;
          rate: number;
          userRate: number | null;
          isFavorite: boolean | null;
          totalBookNum: number;
          purchaseBookNum: number | null;
          totalBookNumIncludingPreorders: number | null;
          isUnextOriginal: boolean | null;
          isNew: boolean | null;
          bookNumForTicket: number;
          nextReleaseDatetime: string | null;
          ticketEndDatetime: string | null;
          hasUnreadChapter: boolean | null;
          isBookSakuhinTicketAvailable: boolean | null;
          isBookPlusTicketAvailable: boolean | null;
          plusTicketEndDateTime: string | null;
          minPrice: number | null;
          hasMultiplePrices: boolean | null;
          isCompleted: boolean;
          freeBookNum: number | null;
          isSale: boolean | null;
          isChapter: boolean;
          freeSaleText: string | null;
          sakuhinCode: string;
          book: {
            __typename: 'Book';
            hasRights: boolean | null;
            code: string;
            name: string;
            isNew: boolean | null;
            isPreorderable: boolean | null;
            isPreordered: boolean | null;
            isFree: boolean | null;
            isPurchased: boolean | null;
            isSale: boolean | null;
            isWebtoon: boolean | null;
            isBookSakuhinTicketAvailable: boolean | null;
            isBookPlusTicketAvailable: boolean | null;
            isInReadingHistory: boolean | null;
            saleText: string | null;
            introduction: string | null;
            bookNo: number;
            publicStartDateTime: string | null;
            publicEndDateTime: string | null;
            originalSaleDate: string | null;
            rightsExpirationDatetime: string | null;
            paymentBadge: {
              __typename: 'PaymentBadge';
              code: PaymentBadgeCode;
            } | null;
            thumbnail: { __typename: 'Thumbnail'; standard: string | null };
            publisher: {
              __typename: 'Publisher';
              code: string | null;
              name: string | null;
            } | null;
            mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
            credits: Array<{
              __typename: 'BookCredit';
              bookAuthorType: string | null;
              personCode: string | null;
              penName: string | null;
              penNameCode: string | null;
              unextPublishingDetail: {
                __typename: 'UnextPublishingDetail';
                introduction: string | null;
                thumbnail: {
                  __typename: 'BookPublishingDetailThumbnail';
                  standard: string | null;
                } | null;
              } | null;
            }>;
            publishedIn: Array<{
              __typename: 'Magazine';
              code: string | null;
              name: string | null;
            }>;
            bookContent: {
              __typename: 'BookContent';
              productList: Array<{
                __typename: 'BookProduct';
                salePeriod: {
                  __typename: 'SalePeriod';
                  price: number | null;
                } | null;
              }>;
              mainBookFile: {
                __typename: 'BookFile';
                code: string;
                completeFlg: boolean;
                resumePoint: number;
                licenseExpiredDateTime: string | null;
              } | null;
              sampleBookFile: {
                __typename: 'BookFile';
                code: string;
                completeFlg: boolean;
                resumePoint: number;
              } | null;
            } | null;
            includedBook: {
              __typename: 'IncludedBook';
              code: string;
              name: string;
              sakuhinCode: string;
              bookNumber: number;
              thumbnail: { __typename: 'Thumbnail'; standard: string | null };
            } | null;
          } | null;
          bookTickets: {
            __typename: 'BookTickets';
            sakuhinTicket: {
              __typename: 'BookTicket';
              code: string;
              typeCode: string;
              typeName: string;
              availableCount: number;
              receivedCount: number;
              nextDeliveryDatetime: string | null;
              nextDeliveryCount: number | null;
            } | null;
            plusTicket: {
              __typename: 'BookTicket';
              code: string;
              typeCode: string;
              typeName: string;
              availableCount: number;
              receivedCount: number;
              nextDeliveryDatetime: string | null;
              nextDeliveryCount: number | null;
            } | null;
          } | null;
          bookLabel: {
            __typename: 'BookLabel';
            code: string | null;
            name: string | null;
          } | null;
          detail: {
            __typename: 'BookSakuhinDetail';
            introduction: string;
            catchSentence: string;
          };
          paymentBadgeList: Array<{
            __typename: 'PaymentBadge';
            code: PaymentBadgeCode;
            name: string;
          }>;
          subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
          thumbnail: { __typename: 'BookSakuhinThumbnails'; standard: string };
        };
      }
    | { __typename: 'BookPostPlayRecommendResponse' };
};

export type cosmo_getBookPlaylistUrlQueryVariables = Exact<{
  bookFileCode: Scalars['String']['input'];
}>;

export type cosmo_getBookPlaylistUrlQuery = {
  webfront_bookPlaylistUrl: {
    __typename: 'BookPlaylistUrlResponse';
    playToken: string;
    playTokenHash: string;
    productCode: string;
    saleTypeCode: string;
    bookFileCode: string;
    bookFileTypeCode: string;
    licenseUrl: string;
    playlistBaseUrl: string;
    resumePoint: number;
    completeFlg: boolean;
    kanaSyllabaryText: string;
    penNameKana: string;
    webtoonFlg: boolean;
    playlistUrl: {
      __typename: 'BookPlaylistUrl';
      version: number | null;
      ubooks: Array<{
        __typename: 'UBook';
        content: string | null;
        coverImage: string | null;
        isEncrypted: boolean | null;
        name: string | null;
        scale: string | null;
      }>;
    };
  };
};

export type cosmo_updateBookResumePointMutationVariables = Exact<{
  bookFileCode: Scalars['String']['input'];
  resumePoint: Scalars['Float']['input'];
  completeFlg?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_updateBookResumePointMutation = {
  webfront_updateBookResumePoint: {
    __typename: 'BookResumePointUpdateResponse';
    bookFileCode: string;
    resumePoint: number;
  };
};

export type cosmo_VideoGenreQueryVariables = Exact<{
  genreCode: Scalars['ID']['input'];
}>;

export type cosmo_VideoGenreQuery = {
  webfront_getSingleSearchGenre: {
    __typename: 'SearchGenre';
    id: string;
    name: string;
    searchCategories: Array<{
      __typename: 'SearchCategory';
      id: string;
      name: string;
      captionDubFlag: boolean;
      defaultSortOrder: PortalSortOrder;
    }>;
  };
};

export type cosmo_VideoCategoryQueryVariables = Exact<{
  categoryCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  sortOrder: PortalSortOrder;
  dubSubFilter?: InputMaybe<DubSubType>;
  filterSaleType?: InputMaybe<SaleType>;
}>;

export type cosmo_VideoCategoryQuery = {
  webfront_searchVideo: {
    __typename: 'VideoSearchResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  };
};

export type cosmo_BookGenreQueryVariables = Exact<{
  genreCode: Scalars['ID']['input'];
}>;

export type cosmo_BookGenreQuery = {
  webfront_getSingleSearchGenre: {
    __typename: 'SearchGenre';
    id: string;
    name: string;
    searchCategories: Array<{
      __typename: 'SearchCategory';
      id: string;
      name: string;
      captionDubFlag: boolean;
      defaultSortOrder: PortalSortOrder;
    }>;
  };
};

export type cosmo_BookCategoryQueryVariables = Exact<{
  categoryCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<BookSaleType>;
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_BookCategoryQuery = {
  webfront_bookCategory: {
    __typename: 'BookCategoryResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  };
};

export type cosmo_MagazineCategoryQueryVariables = Exact<{
  magazineGenreCode: Scalars['ID']['input'];
}>;

export type cosmo_MagazineCategoryQuery = {
  magazines: {
    __typename: 'MagazinesResponse';
    name: string;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  };
};

export type cosmo_VideoCategoryMenuTypeCodeQueryVariables = Exact<{
  genreCode: Scalars['ID']['input'];
}>;

export type cosmo_VideoCategoryMenuTypeCodeQuery = {
  webfront_getSingleSearchGenre: {
    __typename: 'SearchGenre';
    id: string;
    menuTypeCode: string;
  };
};

export type cosmo_LiveCategoryQueryVariables = Exact<{
  categoryCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_LiveCategoryQuery = {
  liveCategorySearch: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_VideoFeatureQueryVariables = Exact<{
  featureCode: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  genreId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type cosmo_VideoFeatureQuery = {
  webfront_feature: {
    __typename: 'Feature';
    id: string;
    name: string | null;
    typeCode: string | null;
    leadText: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
    titleComments: Array<{
      __typename: 'FeatureTitleComment';
      titleCode: string;
      comment: string | null;
    }>;
  } | null;
};

export type cosmo_BookFeaturesQueryVariables = Exact<{
  bookFeatureCode: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  tagCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_BookFeaturesQuery = {
  webfront_bookFeatures: {
    __typename: 'BookFeatureResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    feature: {
      __typename: 'BookFeatureItem';
      code: string;
      name: string;
      typeCode: BookFeatureTypeCode;
      comment: string;
      displayEnd: string;
      books: Array<{
        __typename: 'BookSakuhin';
        name: string;
        rate: number;
        freeBookNum: number | null;
        isNew: boolean | null;
        isSale: boolean | null;
        minPrice: number | null;
        hasMultiplePrices: boolean | null;
        totalBookNum: number;
        isUnextOriginal: boolean | null;
        bookViewCode: string;
        featurePieceCode: string | null;
        recentLicenseExpiredDatetime: string | null;
        isChapter: boolean;
        hasUnreadChapter: boolean | null;
        isBookSakuhinTicketAvailable: boolean | null;
        isBookPlusTicketAvailable: boolean | null;
        sakuhinCode: string;
        book: {
          __typename: 'Book';
          code: string;
          name: string;
          isPreorderable: boolean | null;
          publicStartDateTime: string | null;
          mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
          thumbnail: { __typename: 'Thumbnail'; standard: string | null };
          publisher: {
            __typename: 'Publisher';
            code: string | null;
            name: string | null;
          } | null;
          credits: Array<{
            __typename: 'BookCredit';
            personCode: string | null;
            penName: string | null;
          }>;
          publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
        } | null;
        paymentBadgeList: Array<{
          __typename: 'PaymentBadge';
          code: PaymentBadgeCode;
        }>;
        subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
      }>;
    };
  };
};

export type cosmo_getSearchGenresQueryVariables = Exact<{
  [key: string]: never;
}>;

export type cosmo_getSearchGenresQuery = {
  webfront_searchGenres: Array<{
    __typename: 'SearchGenreGroup';
    displayCode: string;
    name: string;
    searchGenres: Array<{
      __typename: 'SearchGenre';
      id: string;
      name: string;
      displayCode: string;
      menuTypeCode: string;
      searchCategories: Array<{
        __typename: 'SearchCategory';
        name: string;
        id: string;
      }>;
    }>;
  }>;
};

export type cosmo_allFreewordSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  videoSortOrder: PortalSortOrder;
  bookSortOrder: PortalSortOrder;
}>;

export type cosmo_allFreewordSearchQuery = {
  webfront_videoFreewordSearch: {
    __typename: 'VideoFreewordSearchResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  webfront_bookFreewordSearch: {
    __typename: 'BookFreewordSearchResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  webfront_personFreewordSearch: {
    __typename: 'PersonFreewordSearchResponse';
    people: Array<{
      __typename: 'Credit';
      personName: string | null;
      personCode: string | null;
      personNameCode: string | null;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  liveFreewordSearch: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_videoFreewordSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<SaleType>;
}>;

export type cosmo_videoFreewordSearchQuery = {
  webfront_videoFreewordSearch: {
    __typename: 'VideoFreewordSearchResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_bookFreewordSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortOrder: PortalSortOrder;
  filterSaleType?: InputMaybe<BookSaleType>;
}>;

export type cosmo_bookFreewordSearchQuery = {
  webfront_bookFreewordSearch: {
    __typename: 'BookFreewordSearchResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_liveFreewordSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_liveFreewordSearchQuery = {
  liveFreewordSearch: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_personFreewordSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_personFreewordSearchQuery = {
  webfront_personFreewordSearch: {
    __typename: 'PersonFreewordSearchResponse';
    people: Array<{
      __typename: 'Credit';
      personName: string | null;
      personCode: string | null;
      personNameCode: string | null;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type autoSuggestQueryVariables = Exact<{
  query: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type autoSuggestQuery = { autoSuggest: Array<string> };

export type cosmo_all_labelQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_all_labelQuery = {
  labelBlock: {
    __typename: 'LabelBlock';
    name: string;
    labelList: Array<{
      __typename: 'Label';
      code: string;
      name: string;
      catch: string;
      genreCode: string;
      searchMenuList: Array<{
        __typename: 'SearchCategory';
        id: string;
        name: string;
        menuTypeCode: string;
        parentMenuCode: string | null;
        parentMenuName: string | null;
        defaultSortOrder: PortalSortOrder;
        captionDubFlag: boolean;
      }>;
      thumbnail: {
        __typename: 'LabelThumbnail';
        standard: string;
        secondary: string;
        logo: string;
      };
    }>;
  };
};

export type cosmo_videoProductListQueryVariables = Exact<{
  titleCode: Scalars['ID']['input'];
  episodeCode: Scalars['ID']['input'];
  deviceType: Scalars['String']['input'];
}>;

export type cosmo_videoProductListQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    titleName: string | null;
    episode: {
      __typename: 'Episode';
      episodeName: string | null;
      displayNo: string | null;
    };
  } | null;
  webfront_videoProductList: {
    __typename: 'VideoProductList';
    ppvProducts: Array<{
      __typename: 'VideoProductItem';
      code: string;
      name: string;
      saleTypeCode: string;
      discountRate: string | null;
      displayButtonText: string;
      displayName: string | null;
      purchaseDescription: string | null;
      displaySaleType: string;
      displayValidityDurationText: string;
      originalPrice: number;
      price: number;
      isSale: boolean;
      publicEndDate: string;
      svodAvailableFromText: string;
    }>;
    contractProducts: Array<{
      __typename: 'VideoAssociatedProductItem';
      code: string;
      name: string;
      typeCode: string;
      price: number;
      displaySaleType: string;
      displayButtonText: string;
      ruleTitle: string;
      ruleNote: string;
      packDescription: {
        __typename: 'PackDescription';
        url: { __typename: 'PackDescriptionUrl'; browser: string };
      } | null;
    }>;
  } | null;
  webfront_pointBack: {
    __typename: 'PointBackResponse';
    point: number;
    isAnnouncedIos: boolean;
    hasVideoSubscription: boolean;
    setting: {
      __typename: 'PointBackSetting';
      percentage: number;
      maxPercentage: number;
      productList: Array<PointBackProduct>;
      scheduleDate: string;
      isRestrictedToPoint: boolean;
      canIncreasePercentage: boolean;
    } | null;
  };
  productsForVideoContent: {
    __typename: 'ProductsForVideoContentResponse';
    subscriptions: Array<
      | { __typename: 'BasicUnextSubscriptionProduct' }
      | {
          __typename: 'UnextSubscriptionBundle';
          name: string;
          subscriptionType: SubscriptionType;
        }
    >;
  };
};

export type cosmo_livePurchasePageQueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  deviceType: Scalars['String']['input'];
}>;

export type cosmo_livePurchasePageQueryQuery = {
  webfront_pointBack: {
    __typename: 'PointBackResponse';
    point: number;
    isAnnouncedIos: boolean;
    hasVideoSubscription: boolean;
    setting: {
      __typename: 'PointBackSetting';
      percentage: number;
      maxPercentage: number;
      productList: Array<PointBackProduct>;
      scheduleDate: string;
      isRestrictedToPoint: boolean;
      canIncreasePercentage: boolean;
    } | null;
  };
  liveProductList: {
    __typename: 'LiveProductList';
    liveTickets: Array<{
      __typename: 'PurchaseLiveTicket';
      id: string;
      name: string;
      price: number;
      productCode: string;
      isSelling: boolean;
      saleEndDateTime: string;
      saleStartDateTime: string;
      lives: Array<{
        __typename: 'PurchaseLiveTicketLiveDescription';
        id: string;
        saleTypeCode: string;
        deliveryStartDateTime: string;
      } | null>;
    }>;
    contractProducts: Array<{
      __typename: 'VideoAssociatedProductItem';
      code: string;
      name: string;
      typeCode: string;
      price: number;
      displaySaleType: string;
      displayButtonText: string;
      ruleTitle: string;
      ruleNote: string;
      packDescription: {
        __typename: 'PackDescription';
        url: { __typename: 'PackDescriptionUrl'; browser: string };
      } | null;
    }>;
  };
  productsForVideoContent: {
    __typename: 'ProductsForVideoContentResponse';
    subscriptions: Array<
      | { __typename: 'BasicUnextSubscriptionProduct' }
      | {
          __typename: 'UnextSubscriptionBundle';
          name: string;
          subscriptionType: SubscriptionType;
        }
    >;
  };
};

export type cosmo_purchaseVideoProductMutationVariables = Exact<{
  productCode?: InputMaybe<Scalars['ID']['input']>;
  liveTicketCode?: InputMaybe<Scalars['ID']['input']>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
}>;

export type cosmo_purchaseVideoProductMutation = {
  webfront_purchaseVideoProduct: {
    __typename: 'VideoProductPurchaseResponse';
    product: { __typename: 'VideoPurchasedProductItem'; code: string } | null;
  };
};

export type cosmo_bookListQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  booksPage?: InputMaybe<Scalars['Int']['input']>;
  booksPageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_bookListQuery = {
  bookTitle: {
    __typename: 'BookSakuhin';
    books: {
      __typename: 'BooksResponse';
      pageInfo: {
        __typename: 'PortalPageInfo';
        page: number;
        pages: number;
        pageSize: number;
        results: number;
      } | null;
      books: Array<{
        __typename: 'Book';
        code: string;
        hasRights: boolean | null;
        isPurchased: boolean | null;
        isPreorderable: boolean | null;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
    } | null;
  };
};

export type cosmo_bookProductListQueryVariables = Exact<{
  bookCode?: InputMaybe<Scalars['ID']['input']>;
  bookSakuhinCode: Scalars['ID']['input'];
}>;

export type cosmo_bookProductListQuery = {
  platformInfo: { __typename: 'PlatformInfo'; deviceType: number };
  bookTitle: { __typename: 'BookSakuhin'; totalBookNum: number };
  webfront_bookProductList: {
    __typename: 'BookProductList';
    products: Array<{
      __typename: 'BookProductItem';
      code: string;
      saleTypeCode: string;
      licensedDeviceCodeList: Array<string>;
      sellingPrice: number;
      isSale: boolean;
      isPoint: boolean;
      recentLicenseExpiredDateTime: string | null;
      bookCode: string;
      name: string;
    }>;
  };
  webfront_pointBack: {
    __typename: 'PointBackResponse';
    point: number;
    hasVideoSubscription: boolean;
    setting: {
      __typename: 'PointBackSetting';
      percentage: number;
      maxPercentage: number;
      productList: Array<PointBackProduct>;
      scheduleDate: string;
      isRestrictedToPoint: boolean;
      canIncreasePercentage: boolean;
    } | null;
  };
};

export type cosmo_purchaseBookProductMutationVariables = Exact<{
  productCode: Scalars['ID']['input'];
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
}>;

export type cosmo_purchaseBookProductMutation = {
  webfront_purchaseBookProduct: {
    __typename: 'BookProductPurchaseResponse';
    product: { __typename: 'BookPurchasedProductItem'; code: string } | null;
  };
};

export type cosmo_bulkPurchaseBookProductMutationVariables = Exact<{
  productCodes: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
}>;

export type cosmo_bulkPurchaseBookProductMutation = {
  webfront_bulkPurchaseBookProduct: {
    __typename: 'BulkBookProductPurchaseResponse';
    products: Array<{ __typename: 'BookPurchasedProductItem'; code: string }>;
  };
};

export type cosmo_purchaseBookWithTicketMutationVariables = Exact<{
  productCode: Scalars['ID']['input'];
}>;

export type cosmo_purchaseBookWithTicketMutation = {
  purchaseBookWithTicket: {
    __typename: 'BookPurchaseWithTicketResponseModel';
    bookTicketTypeCode: string;
    bookTicketTypeName: string;
    bookTicketCode: string;
    useTicketCount: number;
  };
};

export type cosmo_bookTitleDetailQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  bookCode?: InputMaybe<Scalars['ID']['input']>;
  viewBookCode?: InputMaybe<Scalars['String']['input']>;
  featurePieceCode?: InputMaybe<Scalars['String']['input']>;
  bookListPageSize?: InputMaybe<Scalars['Int']['input']>;
  bookListChapterPageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_bookTitleDetailQuery = {
  bookTitle: {
    __typename: 'BookSakuhin';
    name: string;
    rate: number;
    userRate: number | null;
    isFavorite: boolean | null;
    totalBookNum: number;
    purchaseBookNum: number | null;
    totalBookNumIncludingPreorders: number | null;
    isUnextOriginal: boolean | null;
    isNew: boolean | null;
    bookNumForTicket: number;
    nextReleaseDatetime: string | null;
    ticketEndDatetime: string | null;
    hasUnreadChapter: boolean | null;
    isBookSakuhinTicketAvailable: boolean | null;
    isBookPlusTicketAvailable: boolean | null;
    plusTicketEndDateTime: string | null;
    minPrice: number | null;
    hasMultiplePrices: boolean | null;
    isCompleted: boolean;
    freeBookNum: number | null;
    isSale: boolean | null;
    isChapter: boolean;
    freeSaleText: string | null;
    sakuhinCode: string;
    bookList: Array<{
      __typename: 'Book';
      isLatestBook: boolean | null;
      code: string;
      name: string;
      isNew: boolean | null;
      isPreorderable: boolean | null;
      isPreordered: boolean | null;
      isFree: boolean | null;
      isPurchased: boolean | null;
      isSale: boolean | null;
      isWebtoon: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      isInReadingHistory: boolean | null;
      saleText: string | null;
      introduction: string | null;
      bookNo: number;
      publicStartDateTime: string | null;
      publicEndDateTime: string | null;
      originalSaleDate: string | null;
      rightsExpirationDatetime: string | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      publisher: {
        __typename: 'Publisher';
        code: string | null;
        name: string | null;
      } | null;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
      credits: Array<{
        __typename: 'BookCredit';
        bookAuthorType: string | null;
        personCode: string | null;
        penName: string | null;
        penNameCode: string | null;
        unextPublishingDetail: {
          __typename: 'UnextPublishingDetail';
          introduction: string | null;
          thumbnail: {
            __typename: 'BookPublishingDetailThumbnail';
            standard: string | null;
          } | null;
        } | null;
      }>;
      publishedIn: Array<{
        __typename: 'Magazine';
        code: string | null;
        name: string | null;
      }>;
      bookContent: {
        __typename: 'BookContent';
        productList: Array<{
          __typename: 'BookProduct';
          salePeriod: { __typename: 'SalePeriod'; price: number | null } | null;
        }>;
        mainBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
          licenseExpiredDateTime: string | null;
        } | null;
        sampleBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
        } | null;
      } | null;
      includedBook: {
        __typename: 'IncludedBook';
        code: string;
        name: string;
        sakuhinCode: string;
        bookNumber: number;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      } | null;
    } | null> | null;
    bookSakuhinChapterLink: {
      __typename: 'BookSakuhinChapterLink';
      bookSakuhinCode: string;
      isNew: boolean;
    } | null;
    menuList: Array<{ __typename: 'BookMenu'; code: string; name: string }>;
    bookTickets: {
      __typename: 'BookTickets';
      sakuhinTicket: {
        __typename: 'BookTicket';
        code: string;
        typeCode: string;
        typeName: string;
        availableCount: number;
        receivedCount: number;
        nextDeliveryDatetime: string | null;
        nextDeliveryCount: number | null;
      } | null;
      plusTicket: {
        __typename: 'BookTicket';
        code: string;
        typeCode: string;
        typeName: string;
        availableCount: number;
        receivedCount: number;
        nextDeliveryDatetime: string | null;
        nextDeliveryCount: number | null;
      } | null;
    } | null;
    bookLabel: {
      __typename: 'BookLabel';
      code: string | null;
      name: string | null;
    } | null;
    detail: {
      __typename: 'BookSakuhinDetail';
      introduction: string;
      catchSentence: string;
    };
    paymentBadgeList: Array<{
      __typename: 'PaymentBadge';
      code: PaymentBadgeCode;
      name: string;
    }>;
    book: {
      __typename: 'Book';
      code: string;
      name: string;
      isNew: boolean | null;
      isPreorderable: boolean | null;
      isPreordered: boolean | null;
      isFree: boolean | null;
      isPurchased: boolean | null;
      isSale: boolean | null;
      isWebtoon: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      isInReadingHistory: boolean | null;
      saleText: string | null;
      introduction: string | null;
      bookNo: number;
      publicStartDateTime: string | null;
      publicEndDateTime: string | null;
      originalSaleDate: string | null;
      rightsExpirationDatetime: string | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      publisher: {
        __typename: 'Publisher';
        code: string | null;
        name: string | null;
      } | null;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
      credits: Array<{
        __typename: 'BookCredit';
        bookAuthorType: string | null;
        personCode: string | null;
        penName: string | null;
        penNameCode: string | null;
        unextPublishingDetail: {
          __typename: 'UnextPublishingDetail';
          introduction: string | null;
          thumbnail: {
            __typename: 'BookPublishingDetailThumbnail';
            standard: string | null;
          } | null;
        } | null;
      }>;
      publishedIn: Array<{
        __typename: 'Magazine';
        code: string | null;
        name: string | null;
      }>;
      bookContent: {
        __typename: 'BookContent';
        productList: Array<{
          __typename: 'BookProduct';
          salePeriod: { __typename: 'SalePeriod'; price: number | null } | null;
        }>;
        mainBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
          licenseExpiredDateTime: string | null;
        } | null;
        sampleBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
        } | null;
      } | null;
      includedBook: {
        __typename: 'IncludedBook';
        code: string;
        name: string;
        sakuhinCode: string;
        bookNumber: number;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      } | null;
    } | null;
    subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    thumbnail: { __typename: 'BookSakuhinThumbnails'; standard: string };
  };
};

export type cosmo_bookTitleBooksQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  booksPage?: InputMaybe<Scalars['Int']['input']>;
  booksPageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_bookTitleBooksQuery = {
  bookTitle_books: {
    __typename: 'BooksResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
    books: Array<{
      __typename: 'Book';
      code: string;
      name: string;
      isNew: boolean | null;
      isPreorderable: boolean | null;
      isPreordered: boolean | null;
      isFree: boolean | null;
      isPurchased: boolean | null;
      isSale: boolean | null;
      isWebtoon: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      isInReadingHistory: boolean | null;
      saleText: string | null;
      introduction: string | null;
      bookNo: number;
      publicStartDateTime: string | null;
      publicEndDateTime: string | null;
      originalSaleDate: string | null;
      rightsExpirationDatetime: string | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      publisher: {
        __typename: 'Publisher';
        code: string | null;
        name: string | null;
      } | null;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
      credits: Array<{
        __typename: 'BookCredit';
        bookAuthorType: string | null;
        personCode: string | null;
        penName: string | null;
        penNameCode: string | null;
        unextPublishingDetail: {
          __typename: 'UnextPublishingDetail';
          introduction: string | null;
          thumbnail: {
            __typename: 'BookPublishingDetailThumbnail';
            standard: string | null;
          } | null;
        } | null;
      }>;
      publishedIn: Array<{
        __typename: 'Magazine';
        code: string | null;
        name: string | null;
      }>;
      bookContent: {
        __typename: 'BookContent';
        productList: Array<{
          __typename: 'BookProduct';
          salePeriod: { __typename: 'SalePeriod'; price: number | null } | null;
        }>;
        mainBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
          licenseExpiredDateTime: string | null;
        } | null;
        sampleBookFile: {
          __typename: 'BookFile';
          code: string;
          completeFlg: boolean;
          resumePoint: number;
        } | null;
      } | null;
      includedBook: {
        __typename: 'IncludedBook';
        code: string;
        name: string;
        sakuhinCode: string;
        bookNumber: number;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      } | null;
    }>;
  } | null;
};

export type cosmo_bookTitleDetailRelatedStuffsQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
}>;

export type cosmo_bookTitleDetailRelatedStuffsQuery = {
  bookTitle_relatedBooks: {
    __typename: 'RelatedBooksResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
  bookTitle_recommendedBooks: {
    __typename: 'BookTitleRecommendedBooksResponse';
    trackingCode: string;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
  bookTitle_relatedTitles: {
    __typename: 'RelatedVideosResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  } | null;
};

export type cosmo_bookTitleDetailRelatedTitlesQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
}>;

export type cosmo_bookTitleDetailRelatedTitlesQuery = {
  bookTitle_relatedTitles: {
    __typename: 'RelatedVideosResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  } | null;
};

export type cosmo_bookCategoryListQueryVariables = Exact<{
  menuCodeList: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  sortOrder: PortalSortOrder;
  pageSize: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_bookCategoryListQuery = {
  bookCategoryList: Array<{
    __typename: 'BookCategoryListResponse';
    menuCode: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  }>;
};

export type cosmo_bookPersonListQueryVariables = Exact<{
  personCodeList: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  sortOrder: PortalSortOrder;
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']['input']>;
  onlyChapter?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_bookPersonListQuery = {
  bookPersonList: Array<{
    __typename: 'BookPersonListResponse';
    personCode: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  }>;
};

export type cosmo_authorOriginalBooksQueryVariables = Exact<{
  personCode: Scalars['String']['input'];
}>;

export type cosmo_authorOriginalBooksQuery = {
  originalBooks: {
    __typename: 'BookOriginalTitlesResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  };
};

export type cosmo_updateBookTitleFavoriteMutationVariables = Exact<{
  bookSakuhinId: Scalars['ID']['input'];
  type: FavoriteTypes;
}>;

export type cosmo_updateBookTitleFavoriteMutation = {
  updateBookTitleFavorite: {
    __typename: 'BookSakuhin';
    code: string;
    isFavorite: boolean | null;
  };
};

export type cosmo_updateBookTitleEvaluationMutationVariables = Exact<{
  bookSakuhinId: Scalars['ID']['input'];
  type: EvaluationTypes;
}>;

export type cosmo_updateBookTitleEvaluationMutation = {
  updateBookTitleEvaluation: {
    __typename: 'BookSakuhin';
    code: string;
    name: string;
    rate: number;
    userRate: number | null;
  };
};

export type cosmo_getEpisodeDetailQueryVariables = Exact<{
  code: Scalars['ID']['input'];
  episodeCode: Scalars['ID']['input'];
}>;

export type cosmo_getEpisodeDetailQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    titleName: string | null;
    productionYear: string | null;
    missingAlertText: string | null;
    nextUpdateDateTime: string | null;
    copyright: string | null;
    mainGenreId: string | null;
    publicMainEpisodeCount: number | null;
    episode: {
      __typename: 'Episode';
      id: string;
      episodeName: string | null;
      purchaseEpisodeLimitday: string | null;
      duration: number | null;
      displayNo: string | null;
      interruption: number | null;
      completeFlag: boolean | null;
      saleTypeCode: string | null;
      introduction: string | null;
      saleText: string | null;
      episodeNotices: Array<string> | null;
      isNew: boolean | null;
      hasPackRights: boolean;
      minimumPrice: number;
      hasMultiplePrices: boolean;
      productLineupCodeList: Array<string>;
      isPurchased: boolean | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null } | null;
    };
  } | null;
  webfront_title_relatedTitles: Array<{
    __typename: 'TitleRelationGroup';
    id: string;
    groupName: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  }>;
  webfront_title_relatedBooks: {
    __typename: 'RelatedBooksResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
};

export type cosmo_getLiveDetailQueryVariables = Exact<{
  liveCode: Scalars['ID']['input'];
}>;

export type cosmo_getLiveDetailQuery = {
  webfront_getLive: {
    __typename: 'Live';
    id: string;
    name: string;
    description: string;
    attractions: string | null;
    copyright: string | null;
    note: string | null;
    viewableDeviceText: string;
    displayLiveMinute: number;
    venue: string | null;
    location: string | null;
    deliveryStartDateTime: string;
    deliveryEndDateTime: string;
    displaySaleEndDateTime: string | null;
    performanceStartDateTime: string;
    isOnlyOn: boolean;
    saleTypeCode: string;
    allowsTimeshiftedPlayback: boolean;
    timeshiftedPlaybackAllowedUntil: string | null;
    purchased: boolean;
    productLineupCodeList: Array<string>;
    hasPackRights: boolean;
    testLiveId: string;
    caution: string;
    paymentBadgeList: Array<{
      __typename: 'PaymentBadge';
      name: string;
      code: PaymentBadgeCode;
    }>;
    subContentList: Array<{
      __typename: 'LiveSubContent';
      typeCode: string;
      publicStartDateTime: string | null;
      publicEndDateTime: string;
    }> | null;
    notices: Array<{
      __typename: 'LiveNotice';
      typeCode: string;
      publicStartDateTime: string;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      };
    }>;
    tickets: Array<{
      __typename: 'LiveTicket';
      id: string;
      name: string;
      saleStartDateTime: string;
      saleEndDateTime: string;
      price: number;
    }>;
    credits: Array<{
      __typename: 'Credit';
      personNameCode: string | null;
      personName: string | null;
      characterName: string | null;
      castTypeName: string | null;
      personCode: string | null;
    }>;
    announcements: Array<{
      __typename: 'LiveAnnouncement';
      title: string;
      body: string;
      url: string;
    }>;
  };
};

export type cosmo_getLiveDetailRelatedStuffsQueryVariables = Exact<{
  liveCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_getLiveDetailRelatedStuffsQuery = {
  liveRelatedLives: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
  liveRelatedVideos: {
    __typename: 'RelatedVideosResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_getVideoTitleQueryVariables = Exact<{
  code: Scalars['ID']['input'];
}>;

export type cosmo_getVideoTitleQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    titleName: string | null;
    rate: number | null;
    userRate: number | null;
    productionYear: string | null;
    country: string | null;
    catchphrase: string | null;
    attractions: string | null;
    story: string | null;
    check: string | null;
    seriesCode: string | null;
    seriesName: string | null;
    publicStartDate: string | null;
    displayPublicEndDate: string | null;
    restrictedCode: string | null;
    copyright: string | null;
    mainGenreId: string | null;
    bookmarkStatus: boolean | null;
    mainGenreName: string | null;
    isNew: boolean | null;
    isOriginal: boolean;
    lastEpisode: string | null;
    updateOfWeek: number | null;
    nextUpdateDateTime: string | null;
    productLineupCodeList: Array<string>;
    hasMultiprice: boolean | null;
    minimumPrice: number | null;
    nfreeBadge: string | null;
    hasDub: boolean | null;
    hasSubtitle: boolean | null;
    saleText: string | null;
    publicMainEpisodeCount: number | null;
    comingSoonMainEpisodeCount: number | null;
    missingAlertText: string | null;
    sakuhinNotices: Array<string> | null;
    hasPackRights: boolean;
    thumbnail: {
      __typename: 'Thumbnail';
      standard: string | null;
      secondary: string | null;
    } | null;
    exclusive: {
      __typename: 'ExclusiveInfo';
      typeCode: ExclusiveBadgeCode | null;
      isOnlyOn: boolean;
    };
    paymentBadgeList: Array<{
      __typename: 'PaymentBadge';
      name: string;
      code: PaymentBadgeCode;
    }>;
    currentEpisode: {
      __typename: 'Episode';
      id: string;
      interruption: number | null;
      duration: number | null;
      completeFlag: boolean | null;
      displayDurationText: string | null;
      existsRelatedEpisode: boolean | null;
      playButtonName: string | null;
      purchaseEpisodeLimitday: string | null;
    } | null;
  } | null;
};

export type cosmo_getVideoTitleFeatureQueryVariables = Exact<{
  code: Scalars['ID']['input'];
  featureCode?: InputMaybe<Scalars['ID']['input']>;
}>;

export type cosmo_getVideoTitleFeatureQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    feature: {
      __typename: 'TitleFeature';
      id: string;
      featureName: string;
      titleComment: string;
    } | null;
  } | null;
};

export type cosmo_getVideoTitleEpisodesQueryVariables = Exact<{
  code: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getVideoTitleEpisodesQuery = {
  webfront_title_titleEpisodes: {
    __typename: 'TitleEpisodes';
    episodes: Array<{
      __typename: 'Episode';
      id: string;
      episodeName: string | null;
      purchaseEpisodeLimitday: string | null;
      duration: number | null;
      displayNo: string | null;
      interruption: number | null;
      completeFlag: boolean | null;
      saleTypeCode: string | null;
      introduction: string | null;
      saleText: string | null;
      episodeNotices: Array<string> | null;
      isNew: boolean | null;
      hasPackRights: boolean;
      minimumPrice: number;
      hasMultiplePrices: boolean;
      productLineupCodeList: Array<string>;
      isPurchased: boolean | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null } | null;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
};

export type cosmo_getVideoTitleRelatedStuffsQueryVariables = Exact<{
  code: Scalars['ID']['input'];
  creditsPage?: InputMaybe<Scalars['Int']['input']>;
  creditsPageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getVideoTitleRelatedStuffsQuery = {
  webfront_title_credits: {
    __typename: 'TitleCreditResponse';
    titleCredits: Array<{
      __typename: 'Credit';
      personNameCode: string | null;
      personName: string | null;
      characterName: string | null;
      castTypeName: string | null;
      personCode: string | null;
    }>;
  } | null;
  webfront_title_relatedTitles: Array<{
    __typename: 'TitleRelationGroup';
    id: string;
    groupName: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  }>;
  webfront_title_relatedBooks: {
    __typename: 'RelatedBooksResponse';
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
  } | null;
  webfront_title_recommendedTitles: {
    __typename: 'RecommendedTitlesResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  } | null;
};

export type cosmo_updateTitleEvaluationMutationVariables = Exact<{
  titleCode: Scalars['ID']['input'];
  type: EvaluationTypes;
}>;

export type cosmo_updateTitleEvaluationMutation = {
  updateTitleEvaluation: {
    __typename: 'TitleStage';
    id: string;
    rate: number | null;
    userRate: number | null;
  } | null;
};

export type cosmo_getPlaylistUrlQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']['input']>;
  playMode?: InputMaybe<Scalars['String']['input']>;
  bitrateLow?: InputMaybe<Scalars['Int']['input']>;
  bitrateHigh?: InputMaybe<Scalars['Int']['input']>;
  validationOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_getPlaylistUrlQuery = {
  webfront_playlistUrl: {
    __typename: 'PlaylistUrl';
    subTitle: string | null;
    playToken: string | null;
    playTokenHash: string | null;
    beaconSpan: number | null;
    resultStatus: number | null;
    licenseExpireDate: string | null;
    result: {
      __typename: 'Result';
      errorCode: string | null;
      errorMessage: string | null;
    } | null;
    urlInfo: Array<{
      __typename: 'UrlInfo';
      code: string | null;
      startPoint: number | null;
      resumePoint: number | null;
      endPoint: number | null;
      endrollStartPosition: number | null;
      holderId: string | null;
      saleTypeCode: string | null;
      umcContentId: string | null;
      movieSecurityLevelCode: string | null;
      captionFlg: boolean | null;
      dubFlg: boolean | null;
      commodityCode: string | null;
      sceneSearchList: {
        __typename: 'SceneSearchList';
        IMS_AD1: string | null;
        IMS_L: string | null;
        IMS_M: string | null;
        IMS_S: string | null;
      } | null;
      movieProfile: Array<{
        __typename: 'PlaylistMovieProfile';
        cdnId: string | null;
        type: StreamingType | null;
        playlistUrl: string | null;
        movieAudioList: Array<{
          __typename: 'AudioType';
          audioType: string | null;
        }>;
        licenseUrlList: Array<{
          __typename: 'LicenseUrl';
          type: DrmType | null;
          licenseUrl: string | null;
        }>;
      }> | null;
      movieAudioList: Array<{
        __typename: 'AudioType';
        audioType: string | null;
      }> | null;
      moviePartsPositionList: Array<{
        __typename: 'MoviePartsPosition';
        type: MoviePartsPositionType;
        fromSeconds: number;
        endSeconds: number;
        hasRemainingPart: boolean | null;
      }>;
    }>;
  } | null;
};

export type cosmo_getPostPlayQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_getPostPlayQuery = {
  webfront_postPlay: {
    __typename: 'PostPlayResult';
    resultType: PostPlayResultType;
    nextEpisode: {
      __typename: 'PostPlayItem';
      episodeCode: string;
      titleCode: string;
      titleName: string;
      episodeName: string;
      displayNo: string;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      publicMainEpisodeCount: number | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
        name: string;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      };
    } | null;
    recommendations: Array<{
      __typename: 'PostPlayItem';
      lastEpisode: string;
      updateOfWeek: number;
      nextUpdateDateTime: string | null;
      catchphrase: string;
      rate: number;
      productionYear: string;
      minimumPrice: number;
      hasMultiprice: boolean;
      isNew: boolean;
      hasSubtitle: boolean;
      hasDub: boolean;
      saleText: string;
      country: string;
      duration: number;
      nfreeBadge: string;
      isOriginal: boolean;
      episodeCode: string;
      titleCode: string;
      titleName: string;
      episodeName: string;
      displayNo: string;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      publicMainEpisodeCount: number | null;
      mainCast: Array<{
        __typename: 'Credit';
        personNameCode: string | null;
        personName: string | null;
        characterName: string | null;
        castTypeName: string | null;
        personCode: string | null;
      }>;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
        name: string;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      };
    }> | null;
  } | null;
};

export type PostPlayFragment = {
  __typename: 'PostPlayItem';
  episodeCode: string;
  titleCode: string;
  titleName: string;
  episodeName: string;
  displayNo: string;
  productLineupCodeList: Array<string>;
  hasPackRights: boolean;
  publicMainEpisodeCount: number | null;
  paymentBadgeList: Array<{
    __typename: 'PaymentBadge';
    code: PaymentBadgeCode;
    name: string;
  }>;
  thumbnail: {
    __typename: 'Thumbnail';
    standard: string | null;
    secondary: string | null;
  };
};

export type LivePlaylistUrlFragment = {
  __typename: 'LivePlaylistUrl';
  playToken: string | null;
  playTokenHash: string | null;
  beaconSpan: number | null;
  resultStatus: number | null;
  result: {
    __typename: 'Result';
    errorCode: string | null;
    errorMessage: string | null;
  } | null;
  endpointUrls: Array<{
    __typename: 'EndpointUrl';
    type: StreamingType | null;
    playlistUrl: string | null;
    licenseUrlList: Array<{
      __typename: 'LicenseUrl';
      type: DrmType | null;
      licenseUrl: string | null;
    }>;
  }>;
};

export type cosmo_getLivePlaylistUrlQueryVariables = Exact<{
  liveCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_getLivePlaylistUrlQuery = {
  livePlaylistUrl: {
    __typename: 'LivePlaylistUrlResult';
    playToken: string;
    playTokenHash: string;
    beaconSpan: number;
    resultStatus: number;
    playlistTypeCode: string;
    result: {
      __typename: 'Result';
      errorCode: string | null;
      errorMessage: string | null;
    };
    endpointUrls: Array<{
      __typename: 'EndpointUrlV2';
      id: string;
      displayName: string;
      image: string;
      playables: Array<{
        __typename: 'Playable';
        type: StreamingType;
        cdns: Array<{
          __typename: 'PlayableCdn';
          cdnId: string;
          weight: number;
          playlistUrl: string;
          timeshiftPlaylistUrl: string | null;
          licenseUrlList: Array<{
            __typename: 'LicenseUrl';
            type: DrmType | null;
            licenseUrl: string | null;
          }>;
        }>;
      }>;
      previews: Array<{
        __typename: 'Preview';
        type: PreviewType;
        cdns: Array<{
          __typename: 'PreviewCdn';
          cdnId: string;
          weight: number;
          playlistUrl: string;
          timeshiftPlaylistUrl: string | null;
          updateIntervalMs: number;
        }>;
      }> | null;
      sceneSearchList: Array<{
        __typename: 'SceneSearch';
        type: SceneSearchType;
        cdns: Array<{
          __typename: 'SceneSearchCdn';
          cdnId: string;
          weight: number;
          playlistUrl: string;
          height: number;
          width: number;
        }>;
      }> | null;
    }>;
    live: {
      __typename: 'LiveInfo';
      deliveryStartEpoch: number;
      deliveryEndEpoch: number;
      allowsTimeShiftedPlayback: boolean;
    } | null;
  };
};

export type cosmo_getLivePlaylistValidationQueryVariables = Exact<{
  liveCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_getLivePlaylistValidationQuery = {
  livePlaylistValidation: {
    __typename: 'LivePlaylistValidationResult';
    resultStatus: number;
    result: {
      __typename: 'Result';
      errorCode: string | null;
      errorMessage: string | null;
    };
  };
};

export type cosmo_getLinearPlaylistUrlQueryVariables = Exact<{
  channelCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_getLinearPlaylistUrlQuery = {
  webfront_linearPlaylistUrl: {
    __typename: 'LivePlaylistUrl';
    playToken: string | null;
    playTokenHash: string | null;
    beaconSpan: number | null;
    resultStatus: number | null;
    result: {
      __typename: 'Result';
      errorCode: string | null;
      errorMessage: string | null;
    } | null;
    endpointUrls: Array<{
      __typename: 'EndpointUrl';
      type: StreamingType | null;
      playlistUrl: string | null;
      licenseUrlList: Array<{
        __typename: 'LicenseUrl';
        type: DrmType | null;
        licenseUrl: string | null;
      }>;
    }>;
  } | null;
};

export type cosmo_getTitleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  episodeCode: Scalars['ID']['input'];
  episodePage?: InputMaybe<Scalars['Int']['input']>;
  episodePageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type cosmo_getTitleQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    titleName: string | null;
    publishStyleCode: string | null;
    episode: {
      __typename: 'Episode';
      id: string;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
    };
  } | null;
  webfront_title_titleEpisodes: {
    __typename: 'TitleEpisodes';
    episodes: Array<{
      __typename: 'Episode';
      id: string;
      episodeName: string | null;
      displayNo: string | null;
      duration: number | null;
      interruption: number | null;
      completeFlag: boolean | null;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null } | null;
    }>;
  } | null;
};

export type cosmo_getLiveQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type cosmo_getLiveQuery = {
  webfront_getLive: {
    __typename: 'Live';
    name: string;
    saleTypeCode: string;
    deliveryStartDateTime: string;
    tickets: Array<{
      __typename: 'LiveTicket';
      id: string;
      name: string;
      price: number;
    }>;
  };
};

export type cosmo_getLinearChannelQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type cosmo_getLinearChannelQuery = {
  webfront_getLinearChannel: { __typename: 'Channel'; name: string };
};

export type getParentalLockQueryVariables = Exact<{ [key: string]: never }>;

export type getParentalLockQuery = { parentalLock: boolean };

export type getIsAeonQueryVariables = Exact<{ [key: string]: never }>;

export type getIsAeonQuery = { isAeon: boolean };

export type InitializeLocalValuesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InitializeLocalValuesQuery = {
  parentalLock: boolean;
  isAeon: boolean;
};

export type cosmo_featureTemplateQueryVariables = Exact<{
  featureTemplateCode: Scalars['ID']['input'];
  tagCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type cosmo_featureTemplateQuery = {
  featureTemplate: {
    __typename: 'FeatureTemplateResponse';
    videoFirst: boolean;
    template: {
      __typename: 'FeatureTemplate';
      code: string;
      name: string;
      lead: string | null;
      displayEnd: string;
      heading: string | null;
      featureArtwork: {
        __typename: 'FeatureArtwork';
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      };
    };
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  };
};

export type cosmo_getLeanbackGenresQueryVariables = Exact<{
  [key: string]: never;
}>;

export type cosmo_getLeanbackGenresQuery = {
  webfront_leanbackGenres: Array<{
    __typename: 'LeanbackGenreGroup';
    id: string;
    leanbackGenres: Array<{
      __typename: 'LeanbackGenre';
      id: string;
      displayCode: string;
      name: string;
      searchMenu: {
        __typename: 'SearchMenu';
        id: string;
        name: string;
        type: string;
      };
    }>;
  }>;
};

export type cosmo_getMultiAccountUserInfoQueryVariables = Exact<{
  isAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type cosmo_getMultiAccountUserInfoQuery = {
  multiAccountUsersInfo: Array<{
    __typename: 'MultiAccountUserInfo';
    id: string;
    accountTypeCode: AccountTypeCode;
    loginId: string;
  }>;
};

export type cosmo_setBookPreorderMutationVariables = Exact<{
  bookCode: Scalars['ID']['input'];
}>;

export type cosmo_setBookPreorderMutation = {
  webfront_setBookPreorder: {
    __typename: 'BookPreorderResponse';
    set: boolean;
  };
};

export type cosmo_cancelBookPreorderMutationVariables = Exact<{
  bookCodeList: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type cosmo_cancelBookPreorderMutation = {
  webfront_cancelBookPreorder: Array<string>;
};

export type cosmo_getVideoTitleRelatedVideoQueryVariables = Exact<{
  code: Scalars['ID']['input'];
}>;

export type cosmo_getVideoTitleRelatedVideoQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    titleName: string | null;
    productionYear: string | null;
    mainGenreName: string | null;
  } | null;
  webfront_title_relatedTitles: Array<{
    __typename: 'TitleRelationGroup';
    id: string;
    groupName: string | null;
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
  }>;
};

export type cosmo_getVideoTitleRelatedBookQueryVariables = Exact<{
  code: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_getVideoTitleRelatedBookQuery = {
  webfront_title_stage: {
    __typename: 'TitleStage';
    id: string;
    titleName: string | null;
    productionYear: string | null;
    mainGenreName: string | null;
  } | null;
  webfront_title_relatedBooks: {
    __typename: 'RelatedBooksResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
  } | null;
};

export type cosmo_bookTitleRelatedVideoQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_bookTitleRelatedVideoQuery = {
  bookTitle: {
    __typename: 'BookSakuhin';
    name: string;
    bookSakuhinCode: string;
    book: {
      __typename: 'Book';
      code: string;
      name: string;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
    } | null;
  };
  bookTitle_relatedTitles: {
    __typename: 'RelatedVideosResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
};

export type cosmo_bookTitleRelatedBookQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_bookTitleRelatedBookQuery = {
  bookTitle: {
    __typename: 'BookSakuhin';
    name: string;
    bookSakuhinCode: string;
    book: {
      __typename: 'Book';
      code: string;
      name: string;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
    } | null;
  };
  bookTitle_relatedBooks: {
    __typename: 'RelatedBooksResponse';
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    } | null;
  } | null;
};

export type cosmo_liveTitleRelatedVideoQueryVariables = Exact<{
  liveCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_liveTitleRelatedVideoQuery = {
  webfront_getLive: {
    __typename: 'Live';
    id: string;
    name: string;
    description: string;
  };
  liveRelatedVideos: {
    __typename: 'RelatedVideosResponse';
    titles: Array<{
      __typename: 'Title';
      id: string;
      titleName: string | null;
      isNew: boolean | null;
      catchphrase: string | null;
      rate: number | null;
      productionYear: string | null;
      updateOfWeek: number | null;
      lastEpisode: string | null;
      nfreeBadge: string | null;
      hasSubtitle: boolean | null;
      hasDub: boolean | null;
      productLineupCodeList: Array<string>;
      hasPackRights: boolean;
      hasMultiprice: boolean | null;
      minimumPrice: number | null;
      isOriginal: boolean;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      thumbnail: {
        __typename: 'Thumbnail';
        standard: string | null;
        secondary: string | null;
      } | null;
      exclusive: {
        __typename: 'ExclusiveInfo';
        isOnlyOn: boolean;
        typeCode: ExclusiveBadgeCode | null;
      };
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_liveTitleRelatedLiveQueryVariables = Exact<{
  liveCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_liveTitleRelatedLiveQuery = {
  webfront_getLive: {
    __typename: 'Live';
    id: string;
    name: string;
    description: string;
  };
  liveRelatedLives: {
    __typename: 'LivePagingResponse';
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  };
};

export type cosmo_bookTitleRecommendedBookQueryVariables = Exact<{
  bookSakuhinCode: Scalars['ID']['input'];
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
}>;

export type cosmo_bookTitleRecommendedBookQuery = {
  bookTitle: {
    __typename: 'BookSakuhin';
    name: string;
    bookSakuhinCode: string;
    book: {
      __typename: 'Book';
      code: string;
      name: string;
      mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
    } | null;
  };
  bookTitle_recommendedBooks: {
    __typename: 'BookTitleRecommendedBooksResponse';
    trackingCode: string;
    books: Array<{
      __typename: 'BookSakuhin';
      name: string;
      rate: number;
      freeBookNum: number | null;
      isNew: boolean | null;
      isSale: boolean | null;
      minPrice: number | null;
      hasMultiplePrices: boolean | null;
      totalBookNum: number;
      isUnextOriginal: boolean | null;
      bookViewCode: string;
      featurePieceCode: string | null;
      recentLicenseExpiredDatetime: string | null;
      isChapter: boolean;
      hasUnreadChapter: boolean | null;
      isBookSakuhinTicketAvailable: boolean | null;
      isBookPlusTicketAvailable: boolean | null;
      sakuhinCode: string;
      book: {
        __typename: 'Book';
        code: string;
        name: string;
        isPreorderable: boolean | null;
        publicStartDateTime: string | null;
        mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
        publisher: {
          __typename: 'Publisher';
          code: string | null;
          name: string | null;
        } | null;
        credits: Array<{
          __typename: 'BookCredit';
          personCode: string | null;
          penName: string | null;
        }>;
        publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
      } | null;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
      subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
    }>;
    pageInfo: {
      __typename: 'PortalPageInfo';
      page: number;
      pages: number;
      pageSize: number;
      results: number;
    };
  } | null;
};

export type cosmo_getTargetDeviceQueryVariables = Exact<{
  titleCode: Scalars['String']['input'];
}>;

export type cosmo_getTargetDeviceQuery = {
  webfront_targetDevice: {
    __typename: 'TargetDeviceResponse';
    isLicensedPC: boolean;
    isLicensedTV: boolean;
  } | null;
};

export type BookDetailFragment = {
  __typename: 'Book';
  code: string;
  name: string;
  isNew: boolean | null;
  isPreorderable: boolean | null;
  isPreordered: boolean | null;
  isFree: boolean | null;
  isPurchased: boolean | null;
  isSale: boolean | null;
  isWebtoon: boolean | null;
  isBookSakuhinTicketAvailable: boolean | null;
  isBookPlusTicketAvailable: boolean | null;
  isInReadingHistory: boolean | null;
  saleText: string | null;
  introduction: string | null;
  bookNo: number;
  publicStartDateTime: string | null;
  publicEndDateTime: string | null;
  originalSaleDate: string | null;
  rightsExpirationDatetime: string | null;
  thumbnail: { __typename: 'Thumbnail'; standard: string | null };
  publisher: {
    __typename: 'Publisher';
    code: string | null;
    name: string | null;
  } | null;
  mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
  credits: Array<{
    __typename: 'BookCredit';
    bookAuthorType: string | null;
    personCode: string | null;
    penName: string | null;
    penNameCode: string | null;
    unextPublishingDetail: {
      __typename: 'UnextPublishingDetail';
      introduction: string | null;
      thumbnail: {
        __typename: 'BookPublishingDetailThumbnail';
        standard: string | null;
      } | null;
    } | null;
  }>;
  publishedIn: Array<{
    __typename: 'Magazine';
    code: string | null;
    name: string | null;
  }>;
  bookContent: {
    __typename: 'BookContent';
    productList: Array<{
      __typename: 'BookProduct';
      salePeriod: { __typename: 'SalePeriod'; price: number | null } | null;
    }>;
    mainBookFile: {
      __typename: 'BookFile';
      code: string;
      completeFlg: boolean;
      resumePoint: number;
      licenseExpiredDateTime: string | null;
    } | null;
    sampleBookFile: {
      __typename: 'BookFile';
      code: string;
      completeFlg: boolean;
      resumePoint: number;
    } | null;
  } | null;
  includedBook: {
    __typename: 'IncludedBook';
    code: string;
    name: string;
    sakuhinCode: string;
    bookNumber: number;
    thumbnail: { __typename: 'Thumbnail'; standard: string | null };
  } | null;
};

export type BookSakuhinDetailFragment = {
  __typename: 'BookSakuhin';
  name: string;
  rate: number;
  userRate: number | null;
  isFavorite: boolean | null;
  totalBookNum: number;
  purchaseBookNum: number | null;
  totalBookNumIncludingPreorders: number | null;
  isUnextOriginal: boolean | null;
  isNew: boolean | null;
  bookNumForTicket: number;
  nextReleaseDatetime: string | null;
  ticketEndDatetime: string | null;
  hasUnreadChapter: boolean | null;
  isBookSakuhinTicketAvailable: boolean | null;
  isBookPlusTicketAvailable: boolean | null;
  plusTicketEndDateTime: string | null;
  minPrice: number | null;
  hasMultiplePrices: boolean | null;
  isCompleted: boolean;
  freeBookNum: number | null;
  isSale: boolean | null;
  isChapter: boolean;
  freeSaleText: string | null;
  sakuhinCode: string;
  bookTickets: {
    __typename: 'BookTickets';
    sakuhinTicket: {
      __typename: 'BookTicket';
      code: string;
      typeCode: string;
      typeName: string;
      availableCount: number;
      receivedCount: number;
      nextDeliveryDatetime: string | null;
      nextDeliveryCount: number | null;
    } | null;
    plusTicket: {
      __typename: 'BookTicket';
      code: string;
      typeCode: string;
      typeName: string;
      availableCount: number;
      receivedCount: number;
      nextDeliveryDatetime: string | null;
      nextDeliveryCount: number | null;
    } | null;
  } | null;
  bookLabel: {
    __typename: 'BookLabel';
    code: string | null;
    name: string | null;
  } | null;
  detail: {
    __typename: 'BookSakuhinDetail';
    introduction: string;
    catchSentence: string;
  };
  paymentBadgeList: Array<{
    __typename: 'PaymentBadge';
    code: PaymentBadgeCode;
    name: string;
  }>;
  book: {
    __typename: 'Book';
    code: string;
    name: string;
    isNew: boolean | null;
    isPreorderable: boolean | null;
    isPreordered: boolean | null;
    isFree: boolean | null;
    isPurchased: boolean | null;
    isSale: boolean | null;
    isWebtoon: boolean | null;
    isBookSakuhinTicketAvailable: boolean | null;
    isBookPlusTicketAvailable: boolean | null;
    isInReadingHistory: boolean | null;
    saleText: string | null;
    introduction: string | null;
    bookNo: number;
    publicStartDateTime: string | null;
    publicEndDateTime: string | null;
    originalSaleDate: string | null;
    rightsExpirationDatetime: string | null;
    thumbnail: { __typename: 'Thumbnail'; standard: string | null };
    publisher: {
      __typename: 'Publisher';
      code: string | null;
      name: string | null;
    } | null;
    mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
    credits: Array<{
      __typename: 'BookCredit';
      bookAuthorType: string | null;
      personCode: string | null;
      penName: string | null;
      penNameCode: string | null;
      unextPublishingDetail: {
        __typename: 'UnextPublishingDetail';
        introduction: string | null;
        thumbnail: {
          __typename: 'BookPublishingDetailThumbnail';
          standard: string | null;
        } | null;
      } | null;
    }>;
    publishedIn: Array<{
      __typename: 'Magazine';
      code: string | null;
      name: string | null;
    }>;
    bookContent: {
      __typename: 'BookContent';
      productList: Array<{
        __typename: 'BookProduct';
        salePeriod: { __typename: 'SalePeriod'; price: number | null } | null;
      }>;
      mainBookFile: {
        __typename: 'BookFile';
        code: string;
        completeFlg: boolean;
        resumePoint: number;
        licenseExpiredDateTime: string | null;
      } | null;
      sampleBookFile: {
        __typename: 'BookFile';
        code: string;
        completeFlg: boolean;
        resumePoint: number;
      } | null;
    } | null;
    includedBook: {
      __typename: 'IncludedBook';
      code: string;
      name: string;
      sakuhinCode: string;
      bookNumber: number;
      thumbnail: { __typename: 'Thumbnail'; standard: string | null };
    } | null;
  } | null;
  subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
  thumbnail: { __typename: 'BookSakuhinThumbnails'; standard: string };
};

export type BookCardFragment = {
  __typename: 'Book';
  code: string;
  name: string;
  isPreorderable: boolean | null;
  publicStartDateTime: string | null;
  mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
  thumbnail: { __typename: 'Thumbnail'; standard: string | null };
  publisher: {
    __typename: 'Publisher';
    code: string | null;
    name: string | null;
  } | null;
  credits: Array<{
    __typename: 'BookCredit';
    personCode: string | null;
    penName: string | null;
  }>;
  publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
};

export type BookTitleCardFragment = {
  __typename: 'BookSakuhin';
  name: string;
  rate: number;
  freeBookNum: number | null;
  isNew: boolean | null;
  isSale: boolean | null;
  minPrice: number | null;
  hasMultiplePrices: boolean | null;
  totalBookNum: number;
  isUnextOriginal: boolean | null;
  bookViewCode: string;
  featurePieceCode: string | null;
  recentLicenseExpiredDatetime: string | null;
  isChapter: boolean;
  hasUnreadChapter: boolean | null;
  isBookSakuhinTicketAvailable: boolean | null;
  isBookPlusTicketAvailable: boolean | null;
  sakuhinCode: string;
  book: {
    __typename: 'Book';
    code: string;
    name: string;
    isPreorderable: boolean | null;
    publicStartDateTime: string | null;
    mediaType: { __typename: 'BookMediaType'; code: BookMediaTypeCode };
    thumbnail: { __typename: 'Thumbnail'; standard: string | null };
    publisher: {
      __typename: 'Publisher';
      code: string | null;
      name: string | null;
    } | null;
    credits: Array<{
      __typename: 'BookCredit';
      personCode: string | null;
      penName: string | null;
    }>;
    publishedIn: Array<{ __typename: 'Magazine'; name: string | null }>;
  } | null;
  paymentBadgeList: Array<{
    __typename: 'PaymentBadge';
    code: PaymentBadgeCode;
  }>;
  subgenreTagList: Array<{ __typename: 'SubgenreTag'; name: string }>;
};

export type OriginalBookCardFragment = {
  __typename: 'Book';
  code: string;
  name: string;
  introduction: string | null;
  thumbnail: { __typename: 'Thumbnail'; standard: string | null };
  credits: Array<{
    __typename: 'BookCredit';
    personCode: string | null;
    penName: string | null;
    unextPublishingDetail: {
      __typename: 'UnextPublishingDetail';
      thumbnail: {
        __typename: 'BookPublishingDetailThumbnail';
        standard: string | null;
      } | null;
    } | null;
  }>;
};

export type OriginalBookTitleCardFragment = {
  __typename: 'BookSakuhin';
  name: string;
  isNew: boolean | null;
  bookViewCode: string;
  featurePieceCode: string | null;
  sakuhinCode: string;
  detail: { __typename: 'BookSakuhinDetail'; catchSentence: string };
  book: {
    __typename: 'Book';
    code: string;
    name: string;
    introduction: string | null;
    thumbnail: { __typename: 'Thumbnail'; standard: string | null };
    credits: Array<{
      __typename: 'BookCredit';
      personCode: string | null;
      penName: string | null;
      unextPublishingDetail: {
        __typename: 'UnextPublishingDetail';
        thumbnail: {
          __typename: 'BookPublishingDetailThumbnail';
          standard: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type CharacterCardFragment = {
  __typename: 'SakuhinGroup';
  code: string;
  name: string;
  thumbnail: { __typename: 'SakuhinGroupThumbnail'; circle: string };
};

export type LabelFragment = {
  __typename: 'Label';
  code: string;
  name: string;
  catch: string;
  genreCode: string;
  searchMenuList: Array<{
    __typename: 'SearchCategory';
    id: string;
    name: string;
    menuTypeCode: string;
    parentMenuCode: string | null;
    parentMenuName: string | null;
    defaultSortOrder: PortalSortOrder;
    captionDubFlag: boolean;
  }>;
  thumbnail: {
    __typename: 'LabelThumbnail';
    standard: string;
    secondary: string;
    logo: string;
  };
};

export type LiveCardFragment = {
  __typename: 'Live';
  id: string;
  name: string;
  deliveryStartDateTime: string;
  deliveryEndDateTime: string;
  performanceStartDateTime: string;
  isOnlyOn: boolean;
  saleTypeCode: string;
  allowsTimeshiftedPlayback: boolean;
  hasPackRights: boolean;
  productLineupCodeList: Array<string>;
  subContentList: Array<{
    __typename: 'LiveSubContent';
    typeCode: string;
    publicStartDateTime: string | null;
    publicEndDateTime: string;
  }> | null;
  tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
  notices: Array<{
    __typename: 'LiveNotice';
    publicStartDateTime: string;
    thumbnail: { __typename: 'Thumbnail'; standard: string | null };
  }>;
  paymentBadgeList: Array<{
    __typename: 'PaymentBadge';
    code: PaymentBadgeCode;
  }>;
};

export type LiveBlockFragment = {
  __typename: 'LiveBlock';
  code: string;
  name: string;
  structureType: LiveBlockStructureType;
  trackingCode: string;
  list: Array<{
    __typename: 'LiveBlockLiveGroupResponse';
    code: string | null;
    name: string | null;
    total: number;
    liveList: Array<{
      __typename: 'Live';
      id: string;
      name: string;
      deliveryStartDateTime: string;
      deliveryEndDateTime: string;
      performanceStartDateTime: string;
      isOnlyOn: boolean;
      saleTypeCode: string;
      allowsTimeshiftedPlayback: boolean;
      hasPackRights: boolean;
      productLineupCodeList: Array<string>;
      subContentList: Array<{
        __typename: 'LiveSubContent';
        typeCode: string;
        publicStartDateTime: string | null;
        publicEndDateTime: string;
      }> | null;
      tickets: Array<{ __typename: 'LiveTicket'; price: number }>;
      notices: Array<{
        __typename: 'LiveNotice';
        publicStartDateTime: string;
        thumbnail: { __typename: 'Thumbnail'; standard: string | null };
      }>;
      paymentBadgeList: Array<{
        __typename: 'PaymentBadge';
        code: PaymentBadgeCode;
      }>;
    }>;
  }>;
};

export type PageInfoFragment = {
  __typename: 'PortalPageInfo';
  page: number;
  pages: number;
  pageSize: number;
  results: number;
};

export type PageInfoHashFragment = {
  __typename: 'PageInfoHash';
  pageSize: number;
  nextPageHash: string;
};

export type TitleCardFragment = {
  __typename: 'Title';
  id: string;
  titleName: string | null;
  isNew: boolean | null;
  catchphrase: string | null;
  rate: number | null;
  productionYear: string | null;
  updateOfWeek: number | null;
  lastEpisode: string | null;
  nfreeBadge: string | null;
  hasSubtitle: boolean | null;
  hasDub: boolean | null;
  productLineupCodeList: Array<string>;
  hasPackRights: boolean;
  hasMultiprice: boolean | null;
  minimumPrice: number | null;
  isOriginal: boolean;
  paymentBadgeList: Array<{
    __typename: 'PaymentBadge';
    code: PaymentBadgeCode;
  }>;
  thumbnail: {
    __typename: 'Thumbnail';
    standard: string | null;
    secondary: string | null;
  } | null;
  exclusive: {
    __typename: 'ExclusiveInfo';
    isOnlyOn: boolean;
    typeCode: ExclusiveBadgeCode | null;
  };
};

export type CurationBlockFragment = {
  __typename: 'VideoRecommendTitleBlock';
  code: string;
  type: VideoRecommendBlockType;
  trackingCode: string;
  name: string;
  total: number;
  list: Array<{
    __typename: 'Title';
    id: string;
    titleName: string | null;
    isNew: boolean | null;
    catchphrase: string | null;
    rate: number | null;
    productionYear: string | null;
    updateOfWeek: number | null;
    lastEpisode: string | null;
    nfreeBadge: string | null;
    hasSubtitle: boolean | null;
    hasDub: boolean | null;
    productLineupCodeList: Array<string>;
    hasPackRights: boolean;
    hasMultiprice: boolean | null;
    minimumPrice: number | null;
    isOriginal: boolean;
    paymentBadgeList: Array<{
      __typename: 'PaymentBadge';
      code: PaymentBadgeCode;
    }>;
    thumbnail: {
      __typename: 'Thumbnail';
      standard: string | null;
      secondary: string | null;
    } | null;
    exclusive: {
      __typename: 'ExclusiveInfo';
      isOnlyOn: boolean;
      typeCode: ExclusiveBadgeCode | null;
    };
  }>;
};

export type FeatureBlockFragment = {
  __typename: 'RecommendBlock';
  id: string;
  featureName: string | null;
  featureTypeCode: string | null;
  featurePickupFlag: boolean | null;
  leadText: string | null;
  titleResults: number | null;
  titles: Array<{
    __typename: 'Title';
    id: string;
    titleName: string | null;
    isNew: boolean | null;
    catchphrase: string | null;
    rate: number | null;
    productionYear: string | null;
    updateOfWeek: number | null;
    lastEpisode: string | null;
    nfreeBadge: string | null;
    hasSubtitle: boolean | null;
    hasDub: boolean | null;
    productLineupCodeList: Array<string>;
    hasPackRights: boolean;
    hasMultiprice: boolean | null;
    minimumPrice: number | null;
    isOriginal: boolean;
    thumbnail: {
      __typename: 'Thumbnail';
      standard: string | null;
      secondary: string | null;
    } | null;
    paymentBadgeList: Array<{
      __typename: 'PaymentBadge';
      code: PaymentBadgeCode;
    }>;
    exclusive: {
      __typename: 'ExclusiveInfo';
      isOnlyOn: boolean;
      typeCode: ExclusiveBadgeCode | null;
    };
  }>;
  titleComments: Array<{
    __typename: 'FeatureTitleComment';
    titleCode: string;
    comment: string | null;
  }>;
};

export type cosmo_updateVideoFavoriteMutationVariables = Exact<{
  titleCode: Scalars['ID']['input'];
  type: FavoriteTypes;
}>;

export type cosmo_updateVideoFavoriteMutation = {
  webfront_updateTitleStageFavorite: {
    __typename: 'TitleStage';
    id: string;
    bookmarkStatus: boolean | null;
  } | null;
};

export type cosmo_updateBookFavoriteMutationVariables = Exact<{
  bookSakuhinId: Scalars['ID']['input'];
  type: FavoriteTypes;
}>;

export type cosmo_updateBookFavoriteMutation = {
  updateBookTitleFavorite: {
    __typename: 'BookSakuhin';
    code: string;
    isFavorite: boolean | null;
  };
};

export type cosmo_platformInfoQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_platformInfoQuery = {
  platformInfo: {
    __typename: 'PlatformInfo';
    deviceType: number;
    deviceName: string;
    screenType: ScreenType | null;
  };
};

export type cosmo_userInfoQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_userInfoQuery = {
  userInfo: {
    __typename: 'UserInfo';
    id: string | null;
    multiAccountId: string | null;
    userPlatformId: string | null;
    userPlatformCode: string | null;
    superUser: boolean | null;
    age: number | null;
    otherFunctionId: string | null;
    points: number | null;
    hasRegisteredEmail: boolean | null;
    siteCode: string | null;
    accountTypeCode: AccountTypeCode | null;
    linkedAccountIssuer: string | null;
    isAdultPermitted: boolean | null;
    needsAdultViewingRights: boolean | null;
    parentalLock: boolean;
    isAeon: boolean;
    billingCaution: {
      __typename: 'BillingCaution';
      title: string;
      description: string;
      suggestion: string | null;
      linkUrl: string | null;
    } | null;
    blockInfo: {
      __typename: 'BlockInfo';
      isBlocked: boolean | null;
      score: number | null;
    } | null;
  } | null;
};

export type cosmo_parentalLockQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_parentalLockQuery = { parentalLock: boolean };

export type cosmo_isAeonQueryVariables = Exact<{ [key: string]: never }>;

export type cosmo_isAeonQuery = { isAeon: boolean };
