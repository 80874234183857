import { defineMessages } from 'react-intl';

const messages = defineMessages({
  leanbackHome: {
    id: 'leanback.home',
    defaultMessage: 'ホーム',
    description: 'Text for the home link in the leanback menu',
  },
  leanbackSearch: {
    id: 'leanback.search',
    defaultMessage: '検索',
    description: 'Text for the search link in the leanback menu',
  },
  leanbackMainAccount: {
    id: 'leanback.mainaccount',
    defaultMessage: 'メインアカウント',
    description:
      'Text for the switch account(between family accounts) link when main account is logged in the leanback menu',
  },
  leanbackAccountContract: {
    id: 'leanback.accountcontract',
    defaultMessage: 'アカウント・契約',
    description: 'Text for the account link in the leanback menu',
  },
  leanbackPoint: {
    id: 'leanback.point',
    defaultMessage: 'U-NEXTポイント',
    description: 'Text for the point link in the leanback menu',
  },
  leanbackPointPrefix: {
    id: 'leanback.pointprefix',
    defaultMessage: '現在：',
    description: 'Prefix text for the current point in the leanback menu',
  },
  leanbackMyList: {
    id: 'leanback.mylist',
    defaultMessage: 'マイリスト',
    description: 'Text for the mylist link in the leanback menu',
  },
  leanbackPurchased: {
    id: 'leanback.purchased',
    defaultMessage: '購入済み',
    description: 'Text for the purchased in the leanback menu',
  },
  leanbackHistory: {
    id: 'leanback.history',
    defaultMessage: '視聴履歴',
    description: 'Text for the history in the leanback menu',
  },
  leanbackService: {
    id: 'leanback.service',
    defaultMessage: 'U-NEXTの特長',
    description: 'Text for the introduction link in the leanback menu',
  },
  leanbackHelp: {
    id: 'leanback.help',
    defaultMessage: 'ヘルプ',
    description: 'Text for the help link in the leanback menu',
  },
  leanbackRanking: {
    id: 'leanback.ranking',
    defaultMessage: 'ランキング',
  },
  leanbackAdultConfirm: {
    id: 'leanback.adultconfirm',
    defaultMessage: 'その他',
  },
  loggedOutBlockTrial: {
    id: 'loggedoutblock.trial',
    defaultMessage: '無料でお試し',
  },
  loggedOutBlockLogin: {
    id: 'loggedoutblock.login',
    defaultMessage: 'ログイン',
  },
  loggedOutBlockService: {
    id: 'loggedoutblock.service',
    defaultMessage: 'U-NEXTの特長',
  },
});

export default messages;
