import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useEffect } from 'react';
import { getScrollbarWidth } from '../../utils';

export const SCROLLBAR_WIDTH_PROPERTY = '--scrollbar-width';
/**
 * Hook to lock the body scroll whenever a component is mounted or
 * whenever isLocked is set to true.
 *
 * You can pass in true always to cause a lock on mount/dismount of the component
 * using this hook.
 *
 * @param isLocked Toggle the scroll lock
 * @param disabled Disables the entire hook (allows conditional skipping of the lock)
 */
export const useLockBodyScroll = (
  isLocked: boolean,
  disabled?: boolean,
  targetElement?: HTMLDivElement | null
): void => {
  useEffect(() => {
    if (isLocked && !disabled) {
      // Calculate scrollbar width to avoid the
      // content shifting when it disappears.
      const scrollbarWidth = getScrollbarWidth();
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.style.setProperty(
        SCROLLBAR_WIDTH_PROPERTY,
        `${scrollbarWidth}px`
      );
      if (targetElement) disableBodyScroll(targetElement);
    }
    return () => {
      if (!disabled) {
        document.body.style.paddingRight = '';
        document.body.style.removeProperty(SCROLLBAR_WIDTH_PROPERTY);
        clearAllBodyScrollLocks();
      }
    };
  }, [isLocked, disabled, targetElement]);
};
