import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../../styles/theme';

export const ageRatingBadgeSizes = ['small', 'medium'] as const;
export type ageRatingBadgeSize = (typeof ageRatingBadgeSizes)[number];

interface BadgeContainerProps {
  baseSize: ageRatingBadgeSize;
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;

  border-radius: 2px;
  border: 0.75px solid ${COLORS.white_50};

  color: ${COLORS.white_50};
  font-size: 10px;
  font-weight: 300;
  line-height: 1.6;

  ${({ baseSize }) =>
    baseSize === 'small' &&
    css`
      padding: 0 4px;
      line-height: 1.5;
      font-size: 8px;
    `};
`;

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  badgeSize: ageRatingBadgeSize;
}

export const AgeRatingBadge: React.FC<BadgeProps> = ({
  badgeSize,
  children,
  ...props
}) => {
  return (
    <BadgeContainer baseSize={badgeSize} data-ucn="ageRatingBadge" {...props}>
      {children}
    </BadgeContainer>
  );
};
