import React, { PropsWithChildren } from 'react';

interface SearchMenu {
  id: string;
  name: string;
  type: string;
}

export interface LeanbackGenre {
  id: string;
  name: string;
  displayCode: string;
  searchMenu: SearchMenu;
}

interface LeanbackContext {
  leanbackData: LeanbackGenre[];
  getLeanbackByDisplayCode: (
    leanbackData: LeanbackGenre[],
    displayCode: LeanbackGenre['displayCode']
  ) => LeanbackGenre;
}

/**
 * Helper function to retrieve leanback data by provided displayCode
 *
 * @param leanbackData Leanback data from the context
 * @param displayCode Display code to lookup
 */
const getLeanbackByDisplayCode: LeanbackContext['getLeanbackByDisplayCode'] = (
  leanbackData,
  displayCode
  // @ts-ignore
) => leanbackData.find((i) => i.displayCode === displayCode);

export const LeanbackContext = React.createContext<LeanbackContext>({
  leanbackData: [],
  getLeanbackByDisplayCode,
});

type LeanbackProviderProps = PropsWithChildren<{
  leanbackData: LeanbackGenre[];
}>;

export const LeanbackProvider: React.FC<LeanbackProviderProps> = ({
  leanbackData,
  ...props
}) => {
  return (
    <LeanbackContext.Provider
      value={{ leanbackData, getLeanbackByDisplayCode }}
    >
      {props.children}
    </LeanbackContext.Provider>
  );
};
