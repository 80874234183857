import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { cosmo_getLiveDetailQuery } from '../../../__generated__/globalTypes';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import {
  DesktopTwoColumnsGrid,
  InfoContent,
  InfoHeading,
  InfoSection,
} from '../shared/InfoSection';
import messages from '../Video/messages';

const StyledIcon = styled(Icon)`
  width: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: flex-start;

  @media ${DEVICE.mobileWide} {
    width: 18px;
  }
`;

const AnnouncementLink = styled.a`
  display: flex;
  gap: 4px;
  align-items: flex-start;
  align-self: start;
  padding: 8px;
  border-radius: 8px;
  transition: background 0.15s ease-in;

  @media (hover: hover) {
    &:hover {
      background-color: ${COLORS.white_10};
    }
  }

  @media ${DEVICE.mobileWide} {
    padding: 4px;
  }
`;

const Title = styled.div`
  font-size: 13px;
`;

const Body = styled.div`
  margin-top: 4px;
  color: ${COLORS.white_75};
  font-size: 10px;

  @media ${DEVICE.mobileWide} {
    margin-top: 0px;
  }
`;

const Announcement: React.FC<{ title: string; body: string; url: string }> = ({
  title,
  body,
  url,
}) => {
  return (
    <AnnouncementLink href={url} target="_blank" rel="noopener noreferrer">
      <StyledIcon name={Icons.NEW_TAB} />
      <div>
        <Title>{title}</Title>
        {body && <Body>{body}</Body>}
      </div>
    </AnnouncementLink>
  );
};

interface LiveAnnouncementSectionProps {
  liveDetail: cosmo_getLiveDetailQuery['webfront_getLive'];
}

const LiveAnnouncementSection: React.FC<LiveAnnouncementSectionProps> = ({
  liveDetail,
}) => {
  const intl = useIntl();

  return liveDetail.announcements.length > 0 ? (
    <InfoSection>
      <InfoContent>
        <InfoHeading>
          {intl.formatMessage(messages.announcementHeaderText)}
        </InfoHeading>
        <DesktopTwoColumnsGrid>
          {liveDetail.announcements.map((item, index) => (
            <Announcement key={index} {...item} />
          ))}
        </DesktopTwoColumnsGrid>
      </InfoContent>
    </InfoSection>
  ) : null;
};

export default LiveAnnouncementSection;
