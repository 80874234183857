import { useRouter } from 'next/router';
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { UrlObject } from 'url';
import { Nullable } from '../utils/Nullable';
import useDebouncedState from './hooks/useDebouncedState';
import useMedia from './hooks/useMedia';
import { DEVICE } from './styles';

type Url = string | UrlObject;
interface SearchInputContextProps {
  searchValue: string;
  searchOpen: boolean;
  searchError: Nullable<Error>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  clear: () => void;
  searchBoxElement: HTMLDivElement | null;
  setSearchBoxElement: Dispatch<SetStateAction<HTMLDivElement | null>>;
  isSearchMobileUI: boolean;
  showSearchGenres: boolean;
}

const SearchInputContext = React.createContext<
  SearchInputContextProps | undefined
>(undefined);

const SearchInputProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const searchQueryParam =
    (Array.isArray(router.query.query)
      ? router.query.query[0]
      : router.query.query) ?? '';
  const [searchOpen, setIsOpen] = useState(false);
  const [lastRoute, setLastRoute] = useState<Nullable<Url>>(null);
  const [searchError, setSearchError] = useState<Nullable<Error>>(null);
  const [searchValue, debouncedValue, setSearchValue] =
    useDebouncedState(searchQueryParam);
  const [searchBoxElement, setSearchBoxElement] =
    useState<HTMLDivElement | null>(null);

  // Control SearchGenres visibility aside from searchOpen for Auto Suggest Search
  const [showSearchGenres, setShowSearchGenres] = useState(false);

  const isSearchMobileUI = useMedia(
    [DEVICE.sd, DEVICE.exceptSd],
    [true, false],
    false
  );

  /**
   * This effect handles visibility of search genres when the search input is opened/closed.
   *
   * When opened, except at search result page , show search genres.
   * When closed, hide search genres.
   */
  useEffect(() => {
    if (searchOpen && !router.query.query) {
      setShowSearchGenres(true);
    } else {
      setShowSearchGenres(false);
    }
  }, [router.query.query, searchOpen]);

  /**
   * When input values are deleted by hand, show search genres
   */
  useEffect(() => {
    if (!searchValue && searchOpen) {
      setShowSearchGenres(true);
    }
  }, [searchValue]);

  /**
   * This effect handles behavior when the debounced search input
   * value changes.
   *
   * If we are not already on the /freeword route, then we push
   * in a new route.
   */
  useEffect(() => {
    if (
      debouncedValue.trim() !== '' &&
      !router.pathname.startsWith('/freeword')
    ) {
      setLastRoute(router.asPath);
    }
  }, [debouncedValue]);

  /**
   * This effect is handling behavior when the search input is closed.
   *
   * If we have a lastRoute, we will route back to it. If we don't
   * (in the case of a deeplink) we take the user back to the index route
   */
  useEffect(() => {
    if (
      searchValue.trim() === '' &&
      router.pathname.startsWith('/freeword') &&
      !searchOpen
    ) {
      if (lastRoute) {
        router
          .push(lastRoute)
          .catch((e) => setSearchError(new Error(e.message)));
      } else {
        router.replace('/').catch((e) => setSearchError(new Error(e.message)));
      }
    }
  }, [searchOpen, setSearchError]);

  /**
   * This effect handles behavior for when the route is changed.
   *
   * If after a route change, the new debounced value is not the same
   * as the query value then we will update the searchValue to either the
   * new query or to an empty string (in the case of null). This makes sure
   * that the value in the searchbox is whatever the user last entered regardless
   * of routing to something like a title detail page.
   *
   * If the new route is not /freeword and query is null, then we will close the
   * search if it is open.
   *
   * In the final case, we want the search to always be open in the case the user
   * is on /freeword
   */
  useEffect(() => {
    if (router.query.query !== debouncedValue) {
      setSearchValue(searchQueryParam);

      if (!router.pathname.startsWith('/freeword') && !router.query.query) {
        setIsOpen(false);
      }
    }

    if (router.pathname.startsWith('/freeword')) {
      setIsOpen(true);
    }
  }, [router, setSearchValue]);

  const clear = () => {
    setSearchError(null);
    setSearchValue('');
  };
  return (
    <SearchInputContext.Provider
      value={{
        searchValue,
        searchOpen,
        searchError,
        setIsOpen,
        setSearchValue,
        clear,
        searchBoxElement,
        setSearchBoxElement,
        isSearchMobileUI,
        showSearchGenres,
      }}
    >
      {children}
    </SearchInputContext.Provider>
  );
};

const useSearchInputContext = (): SearchInputContextProps => {
  const context = useContext(SearchInputContext);

  if (context === undefined) {
    throw new Error(
      'useSearchInputContext must be used within a SearchInputProvider'
    );
  }
  return context;
};

export { SearchInputProvider, useSearchInputContext };
