import styled, { css } from 'styled-components';
import { AgeRatingBadge } from '../../../shared/components/Common/Badge/AgeRatingBadge';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import { sectionStyle } from './mixins';

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${sectionStyle};

  &:empty {
    display: none;
  }
`;

export const FlexColumns = styled.div`
  display: flex;
  gap: 16px;

  @media ${DEVICE.mobileWide} {
    flex-direction: column;
  }
`;

export const DesktopTwoColumnsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;

  @media ${DEVICE.mobileWide} {
    grid-template-columns: 100%;
    gap: 8px;
    margin-top: 10px;
  }
`;

export const InfoContent = styled.div<{ hasBorderTop?: boolean }>`
  flex: 1;
  ${({ hasBorderTop }) =>
    hasBorderTop &&
    css`
      padding-top: 16px;
      border-top: 1px solid ${COLORS.white_15};
    `};
`;

export const ScheduleCopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 16px;
`;

export const NoticeInfoText = styled.div`
  text-indent: -1em;
  margin-left: 1em;
`;

export const InfoContentFeature = styled(InfoContent)`
  overflow: hidden;
  border-radius: 12px;
  border: 0.5px solid ${COLORS.white_10};
  background: ${COLORS.white_05};
  font-size: 14px;
  color: ${COLORS.white_75};

  > * {
    padding: 12px 16px;
  }

  @media ${DEVICE.mobileWide} {
    > * {
      padding: 16px;
    }
  }
`;

export const InfoContentFeatureHeading = styled.div`
  background: ${COLORS.white_05};
  font-size: 14px;
  font-weight: 600;
`;

export const InfoHeading = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;

  > div {
    /* Icon style */
    margin-right: 4px;
    width: 20px;
  }
`;

export const InfoText = styled.div`
  margin-top: 10px;
  font-size: 13px;
  color: ${COLORS.white_75};
  white-space: pre-wrap;

  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

export const InfoTextSmall = styled.div`
  font-size: 12px;
  color: ${COLORS.white_75};
`;

export const InfoTextSmallDark = styled.div`
  display: flex;
  gap: 6px;
  font-size: 12px;
  color: ${COLORS.white_50};

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

export const InfoCopyright = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  font-size: 12px;
  color: ${COLORS.white_50};
`;

export const PositionedAgeRatingBadge = styled(AgeRatingBadge)`
  margin-left: auto;
`;

export const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
`;

export const AuthorThumbnail = styled.img`
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid ${COLORS.white_30};
`;

export const AuthorType = styled.div`
  font-size: 12px;
  color: ${COLORS.white_50};
  font-weight: 300;
`;
