import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { COLORS } from '../../../../shared/styles/theme';

export const PopupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const MIN_SIDE_MARGIN = 16; // window内の左右のmargin

const Dialog = styled(animated.div)<{ $leftOffset: number }>`
  position: absolute;
  top: calc(100% + 16px);
  left: ${({ $leftOffset }) => `calc(50% + ${$leftOffset}px)`};
  transform: translateX(-50%);
  z-index: 2001;

  display: flex;
  width: 344px;
  max-width: calc(100vw - ${MIN_SIDE_MARGIN * 2}px);
  box-sizing: border-box;
  padding: 16px;
  flex-direction: column;

  border-radius: 10px;
  background: linear-gradient(
    180deg,
    ${COLORS.charcoal} 0%,
    ${COLORS.ink} 100%
  );
  box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};
`;

export const Popup: React.FC<
  PropsWithChildren<{
    show: boolean;
  }>
> = ({ show, children }) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [dialogOffsetLeft, setOffset] = useState(0);
  const popupTransitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 150,
    },
    onDestroyed: (item) => item && setOffset(0), // window幅が変わったときに備え、非表示になったときにoffsetをリセットする
  });

  useEffect(() => {
    // 「window幅からMIN_SIDE_MARGINを除いた枠」の中にDialogが収まらない場合に位置を調整する
    const dialog = dialogRef.current;
    if (dialog) {
      const { left, right } = dialog.getBoundingClientRect();
      const clientWidth = document.documentElement.clientWidth;
      const maxRight = clientWidth - MIN_SIDE_MARGIN;
      if (left < MIN_SIDE_MARGIN) {
        // 左端がはみ出る場合
        setOffset(-left + MIN_SIDE_MARGIN);
      } else if (right > maxRight) {
        // 右端がはみ出る場合
        setOffset(maxRight - right);
      }
    }
  }, [show]);

  return popupTransitions(
    (props, item) =>
      item && (
        <Dialog ref={dialogRef} style={props} $leftOffset={dialogOffsetLeft}>
          {children}
        </Dialog>
      )
  );
};
