import { defineMessages } from 'react-intl';

const messages = defineMessages({
  readSample: {
    id: 'title.book.sample.read',
    defaultMessage: '無料サンプルを読む',
  },
  bookReadButtonText: {
    id: 'title.book.readButton.text',
    defaultMessage: '読む',
    description: 'read button text',
  },
  bookFreeReadButtonText: {
    id: 'title.book.free.readButton.text',
    defaultMessage: '無料で読む',
    description: 'free-read button text',
  },
  descriptionHeading: {
    id: 'title.book.description.heading',
    defaultMessage: '内容紹介',
  },
  infoHeading: {
    id: 'title.book.info.heading',
    defaultMessage: '作品情報',
  },
  authorOriginalBooks: {
    id: 'title.book.authorOriginalBooks',
    defaultMessage: 'この著者の他のオリジナル作品',
  },
  publicStart: {
    id: 'title.book.publicStart.text',
    defaultMessage: '発売',
  },
  preorderable: {
    id: 'title.book.preorderable.text',
    defaultMessage: '新刊予約受付中',
  },
  webtoon: {
    id: 'title.book.webtoon.text',
    defaultMessage: 'タテ読み・カラー',
  },
  completed: {
    id: 'title.book.completed',
    defaultMessage: '完結',
  },
  public: {
    id: 'title.book.public',
    defaultMessage: '配信',
  },
  publicUntilNextIssue: {
    id: 'title.book.publicUntilNextIssue',
    defaultMessage: '次号配信開始まで配信',
  },
  errorDialogTitle: {
    id: 'title.error.title',
    defaultMessage: 'お知らせ',
  },
  errorDialogButton: {
    id: 'title.error.button',
    defaultMessage: '閉じる',
  },
  bookReadButtonSubText: {
    id: 'title.book.readButton.sub.text',
    defaultMessage: '{dateTime}まで閲覧可',
    description: 'read button sub-text',
  },
  bookReadButtonSubTextToday: {
    id: 'title.book.readButton.sub.text.today',
    defaultMessage: '本日 {dateTime}まで閲覧可',
    description: 'read button sub-text-today',
  },
  dailyFreeEndDateText: {
    id: 'title.book.dailyFree.endDate',
    defaultMessage: 'まで毎日無料',
  },
  dailyFreePlusEndDateText: {
    id: 'title.book.dailyFreePlus.endDate',
    defaultMessage: 'まで毎日無料＋',
  },
  relatedBooksHeading: {
    id: 'title.book.related.books',
    defaultMessage: 'シリーズ・関連ブック',
  },
  recommendedBooksHeading: {
    id: 'title.book.recommended.books',
    defaultMessage: 'こちらもオススメ',
  },
  relatedVideosHeading: {
    id: 'title.book.related.videos',
    defaultMessage: '関連ビデオ',
  },
  categoryHeading: {
    id: 'title.book.category.books',
    defaultMessage: '「{categoryName}」カテゴリの人気毎日無料',
  },
  personHeading: {
    id: 'title.book.person.books',
    defaultMessage: '「{personName}」の毎日無料',
  },
});

export default messages;
