import { NextRouter, useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import React, { AnchorHTMLAttributes, MouseEvent } from 'react';
import styled from 'styled-components';
import { UrlObject } from 'url';
import { UrlQueryParams } from '../../constants';

const getFilteredQuery = (
  router: NextRouter,
  queryKey: string | string[]
): ParsedUrlQuery => {
  const newQuery = { ...router.query };

  if (Array.isArray(queryKey)) {
    queryKey.forEach((key) => {
      delete newQuery[key];
    });
  } else {
    delete newQuery[queryKey];
  }

  return newQuery;
};

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  type: 'book' | 'video' | 'live';
  titleCode: string;
  optionalCode?: {
    bookCode?: string;
    bookViewCode?: string;
    featurePieceCode?: string;
  };
  query?: ParsedUrlQuery;
  disabled?: boolean;
}

const TitleLink: React.FC<Props> = ({
  type,
  titleCode,
  optionalCode: { bookCode, bookViewCode, featurePieceCode } = {},
  children,
  onClick,
  query,
  disabled,
  ...props
}) => {
  const router = useRouter();
  if (disabled) return <>{children}</>;

  let href = `/title/${titleCode}`;
  if (type === 'book') {
    href = `/book/title/${titleCode}`;
  } else if (type === 'live') {
    href = `/livedetail/${titleCode}`;
  }

  if (!router) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }

  let newPath: string;
  let newURL: UrlObject;

  const filteredQuery = getFilteredQuery(router, [
    'videoTitleCode',
    'bookSakuhinCode',
    'bookCode',
    UrlQueryParams.BOOK_ALT_PARAMS,
    // Clear existing codes for transition between book/video/live
    UrlQueryParams.TITLE_DETAIL_ID,
    UrlQueryParams.BOOK_TITLE_DETAIL_ID,
    UrlQueryParams.BOOK_CODE,
    UrlQueryParams.BOOK_VIEW_CODE,
    UrlQueryParams.BOOK_EPISODE_LIST_FLAG,
    UrlQueryParams.LIVE_CODE,
    // Clean up the previous feature related query
    UrlQueryParams.FEATURE_PIECE_CODE,
    UrlQueryParams.FEATURE_CODE,

    UrlQueryParams.PLAYBACK_STATUS_FLAG,
  ]);

  if (
    router.pathname.match(/\/title\/\[videoTitleCode\]/) ||
    router.pathname.match(/\/book\/title\/\[bookSakuhinCode\]/) ||
    router.pathname.match(/\/livedetail\/\[liveCode\]/)
  ) {
    newURL = {
      pathname: `/title/[videoTitleCode]`,
      query: { ...filteredQuery, ...query },
    };
    newPath = `/title/${titleCode}`;

    if (type === 'book') {
      newURL.pathname = `/book/title/[bookSakuhinCode]${
        bookCode ? '/[bookCode]' : ''
      }`;
      newPath = `/book/title/${titleCode}${bookCode ? `/${bookCode}` : ''}`;
    } else if (type === 'live') {
      newURL.pathname = `/livedetail/[liveCode]`;
      newPath = `/livedetail/${titleCode}`;
    }
  } else {
    const newQuery: ParsedUrlQuery = {};

    if (type === 'book') {
      newQuery[UrlQueryParams.BOOK_TITLE_DETAIL_ID] = titleCode;
      if (bookViewCode) {
        newQuery[UrlQueryParams.BOOK_VIEW_CODE] = bookViewCode;
      }
      if (featurePieceCode) {
        newQuery[UrlQueryParams.FEATURE_PIECE_CODE] = featurePieceCode;
      }
      if (bookCode && (!bookViewCode || bookViewCode === 'BOOK')) {
        newQuery[UrlQueryParams.BOOK_CODE] = bookCode;
      }
    } else if (type === 'live') {
      newQuery[UrlQueryParams.LIVE_CODE] = titleCode;
    } else {
      newQuery[UrlQueryParams.TITLE_DETAIL_ID] = titleCode;
    }

    newURL = {
      pathname: router.pathname,
      query: { ...filteredQuery, ...newQuery, ...query },
    };
  }

  const onTitleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    router.push(newURL, newPath, {
      scroll: false,
    });
    e.preventDefault();
  };

  return (
    <a
      href={href}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        onTitleLinkClick(e);
      }}
      {...props}
    >
      {children}
    </a>
  );
};

export const FocusStyledTitleLink = styled(TitleLink)`
  &&& {
    &:focus-visible {
      outline: 0;
      > * {
        transform: scale(1.1);
      }
    }
  }
`;

export default TitleLink;
