import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';

export const badgeTypes = [
  'promotion',
  'ghost',
  'ghost_dark',
  'white',
] as const;

export type badgeType = (typeof badgeTypes)[number];

export const badgeSizes = ['small', 'medium'] as const;
export type badgeSize = (typeof badgeSizes)[number];

interface BadgeContainerProps {
  badgeSize?: badgeSize;
  mobileBadgeSize?: badgeSize;
  badgeType: badgeType;
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 8px;
  line-height: 16px;
  font-size: 10px;
  font-weight: 300;
  border-radius: 100px;

  ${({ badgeType, theme }) => {
    switch (badgeType) {
      case 'promotion':
        return css`
          padding: 0 6px;
          font-weight: 600;
          color: ${theme.badge.promotion.text};
          background-color: ${theme.badge.promotion.background};
        `;
      case 'ghost':
        return css`
          border: 0.75px solid ${COLORS.white};
        `;
      case 'ghost_dark':
        return css`
          color: rgba(255, 255, 255, 0.75);
          border: 0.75px solid ${COLORS.white_75};
        `;
      case 'white':
        return css`
          background: ${COLORS.white};
          color: ${COLORS.jet_black};
          font-weight: 600;
        `;
    }
  }};

  ${({ badgeSize }) =>
    badgeSize === 'small' &&
    css`
      padding: 0 4px;
      line-height: 12px;
      font-size: 8px;
    `};

  @media ${DEVICE.mobileWide} {
    ${({ mobileBadgeSize }) =>
      mobileBadgeSize === 'small' &&
      css`
        padding: 0 4px;
        line-height: 12px;
        font-size: 8px;
      `};
  }
`;

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  badgeType: badgeType;
  badgeSize?: badgeSize;
  mobileBadgeSize?: badgeSize;
  componentName?: string;
}

export const RoundBadge: React.FC<BadgeProps> = ({
  children,
  componentName,
  ...props
}) => {
  return (
    <BadgeContainer data-ucn={componentName} {...props}>
      {children}
    </BadgeContainer>
  );
};
