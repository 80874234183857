import 'intersection-observer';
import type { AppProps } from 'next/app';
import App, { AppContext, AppInitialProps } from 'next/app';
import { parseCookies } from 'nookies';
import { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';
import { URLSearchParams } from 'url';
import { KafkaProvider } from '../domain/Log/KafkaContext';
import { useTreasureDataClient } from '../domain/Log/useTreasureDataClient';
import NewVersionChecker from '../domain/NewVersionChecker';
import TitleModal from '../domain/titleDetail/shared/TitleModal';
import localStorageProvider from '../lib/localStorageProvider';
import withApollo, { WithApolloProps } from '../lib/withApollo';
import ErrorBoundary from '../shared/components/ErrorBoundary';
import Layout from '../shared/components/Layout';
import { LeanbackProvider } from '../shared/components/Leanback/LeanbackContext';
import GlobalMetaTags from '../shared/components/MetaTags/global';
import { GlobalConfig, UrlQueryParams } from '../shared/constants';
import { usePlatformInfo } from '../shared/hooks/usePlatformInfo';
import { useUserInfo } from '../shared/hooks/useUserInfo';
import { IsInitialRouteProvider } from '../shared/IsInitialRouteContext';
import { LLPUserInfoProvider } from '../shared/LLPUserInfoContext';
import { RemoteConfigProvider } from '../shared/RemoteConfigContext';
import { theme } from '../shared/styles';
import UserAgentContext from '../shared/UserAgentContext';
import { isBotRequest } from '../utils';
import { maskToken } from '../utils/maskToken';
import type { Maybe } from '../utils/Nullable';
import { loadRemoteConfigWithCache } from '../utils/remoteConfig';
import { isActivePath } from '../utils/routeHelper';

// Use window.location.href to support IE11
function getPageName(href: string) {
  const regex = /https?:\/\/.+\/genre\/(\w+)/;
  return regex.test(href) ? regex.exec(href)?.[1] : null;
}

function setCriteoTagData(pageName: Maybe<string>) {
  if (!pageName) {
    window.RECOMMENDED_TITLEID = [];
  } else {
    try {
      const req = new XMLHttpRequest();
      req.addEventListener('load', function () {
        try {
          const json = JSON.parse(req.responseText);
          window.RECOMMENDED_TITLEID = json.data.map(function (item: {
            id: string;
          }) {
            return item.id;
          });
        } catch (e) {
          window.RECOMMENDED_TITLEID = [];
        }
      });
      req.overrideMimeType('application/json');
      req.open(
        'GET',
        'https://metac.nxtv.jp/img/static_resource/json/' + pageName + '.json'
      );
      req.send();
    } catch (e) {
      window.RECOMMENDED_TITLEID = [];
    }
  }
}

const messages: {
  [key: string]: Record<string, string>;
} = {
  ja: require('../i18n/locales/ja.json'),
};

const playerViewerRoutes = [
  /^\/play\//,
  /^\/live\/[^/]*$/,
  /^\/realtime\//,
  /^\/book\/view/,
];

const noLeanbackRoutes = [...playerViewerRoutes];
const noFooterRoutes = [...playerViewerRoutes];
const noScrollbarRoutes = [...playerViewerRoutes];
const noBackgroundPatternRoutes = [/^\/$/];

interface CoreAppProps {
  isSsr: boolean;
  isBot: boolean;
  isTitleModalSsr: boolean;
  url: string;
  userAgent: string;
  err?: {
    statusCode: number;
  };
  hasUserCookie: boolean | null;
  maskedRtTokenSsr?: string;
  remoteConfig: WebFrontRemoteConfig;
}
const CoreApp = ({
  Component,
  pageProps,
  router,
  isSsr,
  isBot,
  isTitleModalSsr,
  url,
  userAgent,
  leanbackData,
  hasUserCookie,
  maskedRtTokenSsr,
  remoteConfig,
  // Workaround for https://github.com/vercel/next.js/issues/8592
  // See https://github.com/vercel/next.js/issues/8592#issuecomment-648942301
  err,
}: AppProps & CoreAppProps & WithApolloProps) => {
  const { data: userInfoData, loading: userInfoLoading } = useUserInfo();
  const { data: platformInfoData, loading: platformInfoLoading } =
    usePlatformInfo();

  const treasureDataClient = useTreasureDataClient(
    {
      userInfo: userInfoData?.userInfo,
      platformInfo: platformInfoData?.platformInfo,
    },
    userInfoLoading || platformInfoLoading
  );

  // Save the initial maksedRtToken from SSR into state. When App is called on client side rendering,
  // it will get an empty maskedRtTokenSsr because the cookie is HTTP only, but the empty string won't
  // affect this state after intialization
  const [maskedRtToken] = useState(maskedRtTokenSsr);

  const onRouteChange = useCallback(() => {
    const pageViewData = {
      page_title: window.document.title,
      page_location: window.location.href,
    };
    window.gtagHelper('config', 'UA-66178160-1', pageViewData);
    window.gtagHelper('config', 'UA-66178160-9', pageViewData);
    setCriteoTagData(getPageName(window.location.href));

    if (treasureDataClient) {
      treasureDataClient.trackPageview();
    }
  }, [treasureDataClient]);

  useEffect(() => {
    if (treasureDataClient) {
      treasureDataClient.trackPageview();
    }
  }, [treasureDataClient]);

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [router, onRouteChange]);

  useEffect(() => {
    if (userInfoData) {
      window.unx = {
        usrinfo: {
          isLogin: !!userInfoData.userInfo?.id,
          userPlatformId: userInfoData.userInfo?.userPlatformId ?? '',
          multiAccountId: userInfoData.userInfo?.multiAccountId ?? '',
        },
      };
      const { userInfo } = userInfoData;
      if (userInfo?.multiAccountId) {
        // ログイン済みでgoogle analytics用にuser_multi_account_idをセットする
        window.gtagHelper('set', { user_id: userInfo.multiAccountId });

        // カスタムディメンションのセット
        window.gtagHelper('config', 'UA-66178160-1', {
          custom_map: { dimension4: 'USER_ID' },
          USER_ID: userInfo?.multiAccountId,
        });

        window.gtagHelper('config', 'UA-66178160-9', {
          custom_map: { dimension4: 'USER_ID' },
          USER_ID: userInfo?.multiAccountId,
          linker: { domains: ['unext.jp', 'hnext.jp'] },
        });

        // まとめて送信
        window.gtagHelper('event', 'user_initialize', {
          non_interaction: true,
        });
      } else {
        // 未ログインの初期化
        window.gtagHelper('config', 'UA-66178160-1');
        window.gtagHelper('config', 'UA-66178160-9', {
          linker: { domains: ['unext.jp', 'hnext.jp'] },
        });
      }
    }
  }, [userInfoData]);

  useEffect(() => {
    // Auto scroll restoration has serious issue on iOS Safari as of 2024
    // See WF-13889
    if (
      /Safari/i.test(userAgent) &&
      typeof window !== 'undefined' &&
      window.history &&
      window.history.scrollRestoration
    ) {
      window.history.scrollRestoration = 'manual';
    }
  }, [userAgent]);

  const showLeanback = !noLeanbackRoutes.some((routeRegexp) =>
    isActivePath(router, routeRegexp)
  );
  const showBackgroundPattern =
    showLeanback &&
    !noBackgroundPatternRoutes.some((routeRegexp) =>
      isActivePath(router, routeRegexp)
    );
  const showDefaultFooter = !noFooterRoutes.some((routeRegexp) =>
    isActivePath(router, routeRegexp)
  );
  const alwaysShowScrollbar = !noScrollbarRoutes.some((routeRegexp) =>
    isActivePath(router, routeRegexp)
  );
  const videoTitleCode = router.query.td || router.query.videoTitleCode;
  const bookSakuhinCode = router.query.btd || router.query.bookSakuhinCode;
  const bookCode = router.query.bc || router.query.bookCode;
  const liveCode = router.query.lc || router.query.liveCode;

  const isLivePlayer = router.route === '/live/[liveCode]';

  return (
    <SWRConfig
      value={{
        provider: () => localStorageProvider({ storageKey: maskedRtToken }),
      }}
    >
      <IntlProvider locale="ja-JP" messages={messages['ja']}>
        <ThemeProvider theme={theme}>
          <UserAgentContext.Provider
            value={{
              userAgent,
              hasUserCookie,
              isBot,
            }}
          >
            <RemoteConfigProvider value={remoteConfig}>
              <LLPUserInfoProvider>
                <KafkaProvider>
                  <IsInitialRouteProvider>
                    <LeanbackProvider leanbackData={leanbackData}>
                      <GlobalMetaTags url={url} />
                      <ErrorBoundary>
                        <Layout
                          showLeanback={showLeanback}
                          showBackgroundPattern={showBackgroundPattern}
                          showDefaultFooter={showDefaultFooter}
                          alwaysShowScrollbar={alwaysShowScrollbar}
                        >
                          {GlobalConfig.ENABLE_VERSION_CHECKER && !isSsr && (
                            <NewVersionChecker />
                          )}
                          <ErrorBoundary>
                            <>
                              {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                              <Component {...pageProps} err={err} />
                              {videoTitleCode && (
                                <TitleModal
                                  titleCode={videoTitleCode as string}
                                  isSsr={isTitleModalSsr}
                                />
                              )}
                              {showLeanback && bookSakuhinCode && (
                                <TitleModal
                                  book={{
                                    bookCode: bookCode as string,
                                    bookSakuhinCode: bookSakuhinCode as string,
                                  }}
                                  isSsr={isTitleModalSsr}
                                />
                              )}
                              {liveCode && !isLivePlayer && (
                                <TitleModal
                                  liveCode={liveCode as string}
                                  isSsr={isTitleModalSsr}
                                />
                              )}
                            </>
                          </ErrorBoundary>
                        </Layout>
                      </ErrorBoundary>
                    </LeanbackProvider>
                  </IsInitialRouteProvider>
                </KafkaProvider>
              </LLPUserInfoProvider>
            </RemoteConfigProvider>
          </UserAgentContext.Provider>
        </ThemeProvider>
      </IntlProvider>
    </SWRConfig>
  );
};

CoreApp.getInitialProps = async (appContext: AppContext) => {
  const appInitialProps = await App.getInitialProps(appContext);

  const { ctx, router } = appContext;

  // IE detection and end-of-life-redirect
  const userAgent = ctx.req?.headers?.['user-agent'];
  if (userAgent) {
    const isIE = !!userAgent.match(
      /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/g
    )?.length;
    if (isIE) {
      ctx.res?.writeHead(301, {
        Location: '/static/notice/ie-eol/index.html',
      });
      ctx.res?.end();
    }
  }

  const cookies = ctx?.req ? parseCookies({ req: ctx.req }) : undefined;
  const rtToken = cookies?._rt;
  const hasUserCookie = Boolean(cookies?._at || cookies?._st);

  const isBot = isBotRequest(ctx);

  // Redirecting video/book/live detail pages to deep-linked pages for SEO reason
  if (isBot) {
    if (ctx.res && router.query.td) {
      ctx.res.writeHead(301, {
        Location: `/title/${router.query.td}`,
      });
      ctx.res.end();
    } else if (ctx.res && router.query.btd) {
      const queryString = router.query[UrlQueryParams.BOOK_EPISODE_LIST_FLAG]
        ? `?${new URLSearchParams({
            [UrlQueryParams.BOOK_EPISODE_LIST_FLAG]: 'true',
          }).toString()}`
        : '';
      ctx.res.writeHead(301, {
        Location: `/book/title/${router.query.btd}${
          router.query.bc ? `/${router.query.bc}` : ''
        }${queryString}`,
      });
      ctx.res.end();
    } else if (ctx.res && router.query.lc) {
      ctx.res.writeHead(301, {
        Location: `/livedetail/${router.query.lc}`,
      });
      ctx.res.end();
    }
  }

  const remoteConfig = await loadRemoteConfigWithCache(isBot);

  const appProps: AppInitialProps & CoreAppProps = {
    ...appInitialProps,
    isSsr: !!ctx.res,
    isBot,
    isTitleModalSsr: !!(
      ctx.res &&
      (router.query.videoTitleCode ||
        router.query.bookSakuhinCode ||
        router.query.liveCode)
    ),
    url: ctx?.req?.url ?? '',
    userAgent:
      ctx.req?.headers['user-agent'] ??
      (typeof window === 'object' ? navigator.userAgent : ''),
    hasUserCookie: typeof window === 'object' ? null : hasUserCookie,
    maskedRtTokenSsr: maskToken(rtToken), // only gets meaningful value on SSR, on CSR, maskedRtTokenSsr will be an empty string
    remoteConfig,
  };

  // manually set response header on SSR, to disable bfcache on Safari/other browsers
  ctx.res?.setHeader('Cache-Control', 'no-store');

  return appProps;
};

export default withApollo(CoreApp);
