import type { CustomToastProps } from '@u-next/react-toast-notifications';
import React from 'react';
import styled from 'styled-components';
import { withProperties } from '../../../utils/typeHelper';
import { DEVICE } from '../../styles';
import LegacyIcon, { LegacyIcons } from '../Common/Icon/LegacyIcon';

const ToastContainer = styled.div`
  background: ${({ theme }) => theme.toast.background};
  padding: 32px;
  font-size: 16px;
  color: ${({ theme }) => theme.text.faded};
  border-radius: 6px;
  margin-bottom: 16px;
  box-shadow: 0px 8px 40px 0px ${({ theme }) => theme.toast.shadow};

  @media ${DEVICE.mobile} {
    padding: 24px;
  }
`;

const Toast: React.FC<CustomToastProps> = ({
  children,
  onDismiss,
  showCloseButton,
  onClose,
  title,
}) => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <ToastContainer>
        {showCloseButton && (
          <ToastCloseButton
            onClick={() => {
              onDismiss();
              if (typeof onClose === 'function') {
                onClose();
              }
            }}
          />
        )}
        {!!title && <Title>{title}</Title>}
        {children}
      </ToastContainer>
    </div>
  );
};

const CloseButtonIcon = styled(LegacyIcon)`
  width: 100%;
  height: 100%;
  transition: transform 0.15s ease-in;

  > svg {
    top: 0;
    width: 24px;
    height: 24px;
  }
`;

const CloseButtonMain = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: ${({ theme }) => theme.toast.background};
  cursor: pointer;
  outline: 0 none;
  padding: 0;
  border: none;

  &:hover ${CloseButtonIcon} {
    transform: scale(1.2);
  }
`;

const ToastCloseButton: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <CloseButtonMain onClick={onClick}>
    <CloseButtonIcon name={LegacyIcons.NAV_CLOSE} />
  </CloseButtonMain>
);

const Title = styled.div`
  padding-bottom: 8px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.standard};
`;

export default withProperties(Toast, {
  Message: styled.div`
    margin-top: 16px;
    line-height: 24px;
    font-size: 16px;
    div {
      &:not(:first-child) {
        padding-top: 16px;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  `,
  ButtonLayout: styled.div`
    display: flex;
    justify-content: flex-center;
    margin-top: 32px;
  `,
});
