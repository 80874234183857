import { gql } from '@apollo/client';

export const GET_LEANBACK_GENRES = gql`
  query cosmo_getLeanbackGenres {
    webfront_leanbackGenres {
      id
      leanbackGenres {
        id
        displayCode
        name
        searchMenu {
          id
          name
          type
        }
      }
    }
  }
`;

export const GET_MULTI_ACCOUNT_USERS_INFO = gql`
  query cosmo_getMultiAccountUserInfo($isAll: Boolean) {
    multiAccountUsersInfo(isAll: $isAll) {
      id
      accountTypeCode
      loginId
    }
  }
`;
