import { FC } from 'react';
import styled, { css } from 'styled-components';
import OnlyOnIconComponent from '../../../assets/icons/icon_onlyon.svg';
import OriginalIconComponent from '../../../assets/icons/logo/Logo_U-NEXT_Original.svg';
import { RoundBadge } from '../../../shared/components/Common/Badge/RoundBadge';
import { ButtonLookDiv } from '../../../shared/components/Common/Button';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';

export const StageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 1;
`;

export const VideoStageSection = styled(StageSection)`
  pointer-events: none; // 背面にあるKeyartLinkをクリックできるよう、ポインターイベントの対象から外す
  @media ${DEVICE.mobileWide} {
    width: 100%;
    margin-top: 35vw;
  }
`;

export const BookStageLayout = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  @media ${DEVICE.mobileWide} {
    flex-direction: column-reverse;
  }
`;

export const ComicStageLayout = styled(BookStageLayout)`
  justify-content: flex-start;
  @media ${DEVICE.mobileWide} {
    margin-top: 24px;
    flex-direction: row-reverse;
    > * {
      flex-grow: 1;
    }
  }
`;

export const ComicStageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const BookMainImage = styled.div`
  --area-height: 385px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 374px;
  height: var(--area-height);
  img {
    max-height: var(--area-height);
  }
  > div {
    /* Placeholder style */
    height: var(--area-height);
    width: calc(var(--area-height) * 5 / 7);
  }

  @media ${DEVICE.sd} {
    --area-height: 320px;
  }

  @media ${DEVICE.mobileWide} {
    pointer-events: none; // モバイルの場合はリンクを無効にする
    --area-height: 193px;
    width: 100%;
    padding: 0 0 40px;
  }
`;

export const ComicMainImage = styled(BookMainImage)`
  @media ${DEVICE.mobileWide} {
    --area-height: 112px;
    width: unset;
    margin: unset;
    flex-grow: 0;
    padding: 0;
  }

  @media ${DEVICE.exceptMobileWide} {
    img {
      /* マンガの場合、「話読み・単行本」切り替えタブの区切り線が画像と重なるため、ここで影をつける */
      box-shadow: 28px 0 24px -24px rgba(10, 20, 33, 0.8),
        -28px 0 24px -24px rgba(10, 20, 33, 0.8);
    }
  }
`;

export const StageMetaArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 344px;
  flex-grow: 0;
  flex-shrink: 0;

  @media ${DEVICE.sd} {
    width: 320px;
  }

  @media ${DEVICE.mobileWide} {
    width: 100%;
  }
`;

export const ComicStageMetaArea = styled(StageMetaArea)`
  @media ${DEVICE.mobileWide} {
    gap: 8px;
  }
`;

export const StageTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StagePromotionArea = styled.div`
  display: flex;
  padding-left: 0px;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  font-size: 12px;
  color: ${COLORS.white_75};
`;

export const OriginalIcon = styled(OriginalIconComponent)`
  height: 16px;
  @media ${DEVICE.mobileWide} {
    height: 12px;
  }
`;

export const OnlyOnIcon = styled(OnlyOnIconComponent)`
  height: 16px;
  @media ${DEVICE.mobileWide} {
    height: 12px;
  }
`;

export const StageSubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
`;

export const StageTitle = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  @media ${DEVICE.mobileWide} {
    font-size: 20px;
  }
`;

export const ComicStageTitle = styled(StageTitle)`
  @media ${DEVICE.mobileWide} {
    font-size: 16px;
  }
`;

export const StageCatch = styled.div`
  font-size: 14px;
  @media ${DEVICE.mobileWide} {
    font-size: 12px;
  }
`;

export const StageSubInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 12px;
  color: ${COLORS.white_75};

  @media ${DEVICE.mobileWide} {
    gap: 4px;
  }
`;

export const StageSubInfoText = styled.div<{ lineClamp?: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp ?? 2};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
`;

export const StageDescription = styled.div`
  font-size: 16px;
`;

export const EvaluationContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 76px;
  white-space: nowrap;
  font-size: 12px;

  @media ${DEVICE.mobileWide} {
    width: 58px;
    font-size: 10px;
  }
`;

export const StageSaleText = styled.div`
  font-size: 12px;
  color: ${COLORS.caution};
`;

export const StageButtonArea = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  pointer-events: auto; // 親要素（VideoStageSection）がポインターイベントの対象外（none）なので、ここで再び対象（auto）にする

  @media ${DEVICE.mobileWide} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BookStageButtonArea = styled(StageButtonArea)`
  @media ${DEVICE.tablet} {
    flex-wrap: wrap;
  }
`;

export const ComicStageButtonArea = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  @media ${DEVICE.mobileWide} {
    margin-top: 0;
    gap: 24px;
  }
`;

export const ComicTabsLayout = styled.div`
  position: relative;
  margin-bottom: 14px;
  display: flex;

  @media ${DEVICE.mobileWide} {
    width: 100%;
    justify-content: center;
    margin-bottom: 0px;
  }

  &:after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: -100px; // big enough to cut through the modal
    content: '';
    border-top: 2px solid white;
    width: calc(100vw + 100px); // big enough to cut through the modal
  }
`;

export const ComicTab = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 96px;
  padding: 14px 0;

  color: ${COLORS.white_90};
  text-align: center;
  font-size: 16px;

  &:hover {
    color: ${COLORS.white};
    font-weight: 600;
  }

  @media ${DEVICE.mobileWide} {
    width: 80px;
    padding: 12px 0;
    font-size: 14px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${COLORS.white};
      font-weight: 600;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 40px;
        height: 4px;
        border-radius: 3px 3px 0px 0px;
        background: ${COLORS.white};
      }
    `};
`;

export const ComicTabNewBadge = styled(RoundBadge)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const PlayButtonsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 240px;
  max-width: 320px;

  @media ${DEVICE.mobileWide} {
    max-width: unset;
    width: 100%;
  }
`;

export const PlayButton = styled(ButtonLookDiv)`
  width: 100%;
  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const LiveNowContainer = styled.div`
  display: flex;
  font-size: 12px;
`;

export const CautionText = styled.div`
  font-size: 12px;
  color: ${COLORS.caution};
`;

export const FlexColumnsCenter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ComicStarTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  @media ${DEVICE.mobileWide} {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

export const SaleStatusContainer = styled(FlexColumnsCenter)`
  width: 100%;
  border-radius: 10px;
  background: ${COLORS.white_10};
  box-sizing: border-box;
  padding: 8px;

  color: ${COLORS.white_75};
  font-size: 12px;

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }

  &:empty {
    display: none;
  }
`;

const ProgressBar = styled.div<{ progressPercent: number }>`
  flex-grow: 1;
  position: relative;
  height: 2px;
  border-radius: 100px;
  background: ${COLORS.white_30};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ progressPercent }) => `${progressPercent}%`};
    border-radius: 100px;
    background: ${COLORS.primary};
  }
`;

const SecondaryText = styled.div`
  font-size: 12px;
  color: ${COLORS.white_75};
`;

export const TertiaryText = styled.div`
  font-size: 12px;
  color: ${COLORS.white_50};
`;

const StageResumeAreaContainer = styled.div`
  @media ${DEVICE.mobileWide} {
    margin-top: 8px;
  }
`;

export const StageResumeArea: FC<{
  progressPercent?: number;
  remainingText?: string;
  limitDayText?: string;
}> = ({ progressPercent, remainingText, limitDayText }) => {
  return progressPercent || limitDayText ? (
    <StageResumeAreaContainer>
      {!!progressPercent && (
        <FlexColumnsCenter>
          <ProgressBar progressPercent={progressPercent} />
          {remainingText && <SecondaryText>{remainingText}</SecondaryText>}
        </FlexColumnsCenter>
      )}
      {limitDayText && <TertiaryText>{limitDayText}</TertiaryText>}
    </StageResumeAreaContainer>
  ) : null;
};
