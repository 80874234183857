import Link from 'next/link';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { PurchasedBadge } from '../../../../../shared/components/Common/Badge/PurchasedBadge';
import { RoundBadge } from '../../../../../shared/components/Common/Badge/RoundBadge';
import { ButtonLookDiv } from '../../../../../shared/components/Common/Button';
import ProgressBar from '../../../../../shared/components/Common/ProgressBar';
import TextEllipsis from '../../../../../shared/components/Common/TextEllipsis';
import PlayCard from '../../../../../shared/components/PlayCard';
import PointPriceBadge from '../../../../../shared/components/PointPriceBadge';
import Preorder from '../../../../../shared/components/Preorder';
import TitleLink from '../../../../../shared/components/TitleLink';
import { globalMessages } from '../../../../../shared/constants/messages';
import { DEVICE } from '../../../../../shared/styles';
import { COLORS } from '../../../../../shared/styles/theme';
import { handleOnlyOnEnter } from '../../../../../utils';
import {
  BadgeRow,
  Container,
  ImageContent,
  ImageContentFooter,
  ImageContentText,
  ImagePlaceholder,
  Introduction,
  TextContent,
  TextPlaceholder,
  Title,
  type StackedItemProps,
} from './Components';

const messages = defineMessages({
  sampleText: {
    id: 'title.book.sample.text',
    defaultMessage: 'サンプル',
    description: 'sample text',
  },
});

const BadgeSaleText = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: ${COLORS.white_90};

  @media ${DEVICE.mobileWide} {
    font-size: 10px;
  }
`;

const SampleButton = styled(ButtonLookDiv)`
  width: 100%;
`;

interface Props extends StackedItemProps {
  bookCodes: {
    bookCode: string;
    bookViewCode?: string;
    featurePieceCode?: string;
  };
  isSale: boolean;
  price: number;
  preorderableBookPublicDateText?: string;
}

const StackedBook: React.FC<Props> = (props) => {
  const {
    bookCodes,
    parentCode,
    isPurchased,
    isNew,
    isPreorderable,
    isPreordered,
    isFree,
    maxLines = 3,
    titleText,
    contentText,
    saleText,
    publicStartText,
    thumbnailUrl,
    progress = 0,
    limitDay,
    onPlayClick,
    playComponentName,
    detailComponentName,
    hasSample,
    isSale,
    price,
    preorderableBookPublicDateText,
    isComplete,
  } = props;

  const intl = useIntl();

  const readMoreText = [contentText, publicStartText]
    .filter(Boolean)
    .join('\n\n');

  const href = `/book/view/${parentCode}/${bookCodes.bookCode}`;

  return (
    <Container>
      <TextContent type="book" data-ucn={detailComponentName}>
        {(isNew || isPreorderable) && (
          <RoundBadge badgeType="promotion" mobileBadgeSize="small">
            {intl.formatMessage(
              isPreorderable ? globalMessages.preorder : globalMessages.new
            )}
          </RoundBadge>
        )}
        <Title>
          {parentCode ? (
            <TitleLink
              type="book"
              data-ucn="stackedBook-title-link"
              titleCode={parentCode}
              optionalCode={{
                bookCode: props.bookCodes.bookCode,
                bookViewCode: props.bookCodes.bookViewCode,
                featurePieceCode: props.bookCodes.featurePieceCode ?? undefined,
              }}
            >
              {titleText}
            </TitleLink>
          ) : (
            titleText
          )}
        </Title>
        <BadgeRow>
          {!isPreorderable && price > 0 && !isFree && (
            <PointPriceBadge
              isPoint
              hasMultiplePrice={false}
              minimumPrice={props.price}
              badgeSize="medium"
              mobileBadgeSize="small"
            />
          )}
          {isFree && (
            <RoundBadge badgeType="white" mobileBadgeSize="small">
              {intl.formatMessage(globalMessages.freeBadge)}
            </RoundBadge>
          )}
          {isSale && (
            <RoundBadge badgeType="white" mobileBadgeSize="small">
              {intl.formatMessage(globalMessages.sale)}
            </RoundBadge>
          )}
          {saleText && <BadgeSaleText>{saleText}</BadgeSaleText>}
          {isPurchased && <PurchasedBadge mobileBadgeSize="small" />}
        </BadgeRow>
        {readMoreText && (
          <Introduction>
            <TextEllipsis text={readMoreText} maxLine={maxLines} />
          </Introduction>
        )}
      </TextContent>
      <ImageContent type="book" data-ucn={playComponentName}>
        {isPreorderable ? (
          <>
            <Preorder
              bookCode={bookCodes.bookCode}
              style={{
                display: 'block',
                position: 'relative',
              }}
            >
              <PlayCard
                type="book"
                thumbnailUrl={thumbnailUrl || ''}
                titleName={titleText}
                isPreorderable
                isPreordered={isPreordered}
                isLazy
              />
            </Preorder>
            <ImageContentFooter>
              {preorderableBookPublicDateText && (
                <ImageContentText>
                  {preorderableBookPublicDateText}
                </ImageContentText>
              )}
            </ImageContentFooter>
          </>
        ) : (
          <>
            <Link
              style={{
                display: 'block',
                position: 'relative',
              }}
              role="button"
              data-ucn="stackedBook-playCard-link"
              tabIndex={-1}
              onKeyDown={(e) => {
                handleOnlyOnEnter(e, onPlayClick);
              }}
              onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
                onPlayClick?.(e)
              }
              legacyBehavior={false}
              href={href}
            >
              <PlayCard
                type="book"
                thumbnailUrl={thumbnailUrl || ''}
                titleName={titleText}
                isLazy
              />
            </Link>
            <ImageContentFooter>
              {(progress > 0 || isComplete) && (
                <ProgressBar
                  percentage={isComplete && progress === 0 ? 1 : progress}
                />
              )}
              {limitDay && <ImageContentText>{limitDay}</ImageContentText>}
              {hasSample && (
                <Link
                  role="button"
                  href={`/book/view/${parentCode}/${props.bookCodes.bookCode}?isSample=true`}
                  legacyBehavior={false}
                >
                  <SampleButton
                    buttonTheme="ghost"
                    buttonSize="small"
                    text={intl.formatMessage(messages.sampleText)}
                  />
                </Link>
              )}
            </ImageContentFooter>
          </>
        )}
      </ImageContent>
    </Container>
  );
};

export default StackedBook;

export const StackedBookPlaceholder = () => {
  return (
    <Container>
      <TextContent type="book">
        <TextPlaceholder widthPercent={80} />
        <TextPlaceholder widthPercent={60} />
      </TextContent>
      <ImageContent type="book">
        <ImagePlaceholder type="book" />
      </ImageContent>
    </Container>
  );
};
