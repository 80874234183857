import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
export const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const messages = defineMessages({
  showAll: {
    id: 'readMore.showAll',
    defaultMessage: 'すべて表示',
  },
  showLess: {
    id: 'readMore.showLess',
    defaultMessage: '少なく表示',
  },
});

const Indicator = styled.button`
  align-self: flex-end;
  margin-top: 6px;
  padding: 4px 10px;
  border-radius: 6px;
  border: 0.75px solid ${COLORS.white_75};
  background: ${COLORS.white_05};
  color: ${COLORS.white_75};
  font-size: 10px;
  font-weight: 600;

  &:hover {
    background-color: ${COLORS.white_30};
  }

  @media ${DEVICE.mobileWide} {
    margin-top: 4px;
  }
`;

const Container = styled.div<{ showPointer: boolean }>`
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-all;
  ${(props) => props.showPointer && `cursor: pointer`};
`;

interface ReflowHandlerEvent {
  clamped: boolean;
  text: string;
}
interface Props {
  text: string;
  maxLine: number;
  component?: string;
}

const ReadMore: React.FC<Props> = ({ maxLine, text }) => {
  const intl = useIntl();
  const [truncated, setTruncated] = useState(false); // whether the given text is long enough to be truncated
  const [shallTruncate, setShallTruncate] = useState(true); // whether the given text is currently truncated

  const handleReflow = ({ clamped }: ReflowHandlerEvent) => {
    setTruncated(clamped);
  };

  return (
    <Container
      showPointer={truncated}
      onClick={() => setShallTruncate(!shallTruncate)}
    >
      {shallTruncate ? (
        <ResponsiveEllipsis
          text={text}
          maxLine={maxLine}
          onReflow={handleReflow}
          basedOn="letters"
        />
      ) : (
        <div>{text}</div>
      )}
      {truncated && (
        <Indicator>
          {intl.formatMessage(
            shallTruncate ? messages.showAll : messages.showLess
          )}
        </Indicator>
      )}
    </Container>
  );
};

export default ReadMore;
