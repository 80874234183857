import Head from 'next/head';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ICON_UNEXT_144 from '../../../assets/icons/icon-unext-144.png';
import ogImage from '../../../assets/images/ogp.png';
import { GlobalConfig } from '../../constants';
import messages from '../../constants/meta/global';
import theme from '../../styles/theme';

interface GlobalMetaTagsProps {
  url: string;
}

const removeUrlParams = (url: string): string => {
  return url.replace(/\?.*$/, '');
};

const GlobalMetaTags: React.FC<GlobalMetaTagsProps> = ({ url }) => {
  const intl = useIntl();
  const hostname = GlobalConfig.URLS.HOST;

  const [canonicalUrl, setCanonicalUrl] = useState(
    removeUrlParams(`${hostname}${url}`)
  );

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      setCanonicalUrl(removeUrlParams(`${hostname}${url}`));
    };

    Router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [setCanonicalUrl, hostname]);

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{intl.formatMessage(messages.defaultTitle)}</title>
      <meta
        name="description"
        content={intl.formatMessage(messages.defaultDescription)}
      />
      <meta
        name="keywords"
        content={intl.formatMessage(messages.defaultKeywords)}
      />
      <meta name="fb:admins" content="100007482434935" />
      <meta name="fb:app_id" content="1615543762065866" />
      <meta name="twitter:card" content="photo" />
      <meta name="twitter:site" content="@watch_UNEXT" />
      <meta name="twitter:creator" content="@watch_UNEXT" />
      <meta name="locale" content={intl.locale} />
      <meta name="application-name" content="U-NEXT" />
      <meta name="apple-mobile-web-app-title" content="U-NEXT" />
      <meta name="msapplication-TileImage" content={ICON_UNEXT_144.src} />
      <meta name="msapplication-TileColor" content={theme.icon.background} />
      <meta
        property="og:site_name"
        content={intl.formatMessage(messages.siteName)}
        key="og:site_name"
      />
      <meta
        property="og:title"
        content={intl.formatMessage(messages.defaultTitle)}
        key="og:title"
      />
      <meta
        property="og:description"
        content={intl.formatMessage(messages.defaultDescription)}
        key="og:description"
      />
      <meta
        property="og:image"
        content={hostname + ogImage.src}
        key="og:image"
      />
      <meta property="og:url" content={canonicalUrl} key="og:url" />
      <meta property="og:type" content="website" key="og:type" />
      <link rel="canonical" href={canonicalUrl} key="canonical" />
      {/*
        Generally, we tend to set cache releated headers (monthes ~ 1year)
        to favicon as it would not be changed for a long time.

        Thus we may (will) get a trouble about caching invalidation for favicon
        which has been cached in UA. So we should add query parameter to evict a cache certainly.
      */}

      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link
        rel="icon alternate"
        sizes="32x32"
        type="image/png"
        href="/favicon.png"
      />

      {/*Add to homescreen on ios*/}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />

      {/*Add to homescreen on Android*/}
      <link rel="icon" sizes="192x192" href="/android-icon.png" />
    </Head>
  );
};

export default GlobalMetaTags;
