import React, { useContext, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { DEVICE } from '../../styles';
import { HEADER_HEIGHT, HEADER_HEIGHT_SD } from '../../styles/constants';
import { SmallBannerContext } from './SmallBannerContext';

const Container = styled(animated.div)<{ $displayOnSdOnly: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 256px;
  min-height: ${HEADER_HEIGHT}px;
  box-sizing: border-box;
  backdrop-filter: blur(12px);
  background-color: ${({ theme }) => theme.genre.banner.smallBackground};
  z-index: 100;
  padding: 16px 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  box-shadow: ${({ theme }) => `0px 8px 32px 0px ${theme.genre.banner.shadow}`};

  @media ${DEVICE.sd} {
    display: ${({ $displayOnSdOnly }) => ($displayOnSdOnly ? 'none' : 'flex')};
    gap: 8px;
    min-height: ${HEADER_HEIGHT_SD}px;
    left: 0;
    /* need to be larger than mobile header */
    z-index: 9004;
    padding: 8px 8px 8px 16px;
  }

  h1 {
    font-size: 22px;
    font-weight: 300;
    margin-right: 20px;

    @media ${DEVICE.sd} {
      font-size: 18px;
    }
  }

  h2 {
    color: ${({ theme }) => theme.genre.banner.leadText};
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    flex: 1;
  }

  button {
    width: auto;
    padding: 10px 20px;
  }
`;

const SmallBanner: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const localSmall = useRef<boolean>(false);
  const bannerContext = useContext(SmallBannerContext);
  const [isSmall, setIsSmall] = useState(false);
  const bannerHeight = containerRef.current?.clientHeight ?? HEADER_HEIGHT;
  const transitions = useTransition(isSmall, {
    from: { top: bannerHeight * -1 },
    enter: { top: 0 },
    leave: { top: bannerHeight * -1 },
    config: { tension: 500, velocity: 0.02, friction: 60 },
  });

  const displayOnSdOnly = bannerContext.options.displayOnSdOnly ?? true;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = Math.max(
        window.scrollY,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (
        scrollTop >= bannerContext.options.triggerHeight &&
        !localSmall.current
      ) {
        localSmall.current = true;
        setIsSmall(true);
      }

      if (
        scrollTop < bannerContext.options.triggerHeight &&
        localSmall.current
      ) {
        localSmall.current = false;
        setIsSmall(false);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bannerContext.options.triggerHeight]);

  return (
    <>
      {transitions(
        (props, item) =>
          item && (
            <Container
              ref={containerRef}
              style={props}
              $displayOnSdOnly={displayOnSdOnly}
            >
              {!bannerContext.options.controls && (
                <>
                  {bannerContext.options.title && (
                    <h1>{bannerContext.options.title}</h1>
                  )}
                  {bannerContext.options.subTitle && (
                    <h2>{bannerContext.options.subTitle}</h2>
                  )}
                </>
              )}
              {bannerContext.options.controls}
            </Container>
          )
      )}
    </>
  );
};

export default React.memo(SmallBanner);
