import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { messages } from '.';
import { DEVICE } from '../../../styles/constants';
import { COLORS } from '../../../styles/theme';
import { LiveOnAirStatus } from '../../LiveNow';
import PointPriceBadge from '../../PointPriceBadge';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
  IMAGE_ASPECT,
} from '../constants';
import LiveThumbnail, { Shadow } from './LiveThumbnail';

const desktopSize = BLOCK_VIEW_TITLE_CARD_SIZE.live;
const mobileSize = BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.live;

const Container = styled.div`
  position: relative;
  width: ${desktopSize.width}px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 200ms ease-in, z-index 100ms 100ms linear;
  background: ${COLORS.ink};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 1px solid ${COLORS.white_10};
    z-index: 1;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      z-index: 500;
      box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};

      ${Shadow} {
        opacity: 1;
      }
    }
  }

  @media ${DEVICE.mobileWide} {
    width: ${mobileSize.width}px;
  }
`;

const BottomContainer = styled.div`
  height: ${desktopSize.height -
  desktopSize.width /
    IMAGE_ASPECT.live}px; // カードの高さから画像の高さを引いた値
  padding: 8px 12px 0;
  box-sizing: border-box;

  @media ${DEVICE.mobileWide} {
    height: ${mobileSize.height - mobileSize.width / IMAGE_ASPECT.live}px;
  }
`;

const LiveInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Date = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const Catchup = styled.div`
  height: 1.4em;
  font-size: 10px;
  color: ${COLORS.white_75};
`;

interface LiveCardProps {
  name: string;
  thumbnailUrl: string;
  isPoint: boolean;
  liveOnAirStatus?: LiveOnAirStatus;
  hasCatchup: boolean;
  dateString: string;
  timeString: string;
  catchupEndTimeString: string;
  isPurchasedLive?: boolean;
  isLazy: boolean;
  isOnlyOn: boolean;
  minimumPrice?: number;
  hasMultiplePrice: boolean;
}

const LiveCard: React.FC<LiveCardProps> = ({
  thumbnailUrl,
  dateString,
  timeString,
  catchupEndTimeString,
  hasCatchup,
  minimumPrice,
  hasMultiplePrice,
  isPoint,
  liveOnAirStatus,
  name,
  isLazy,
  isPurchasedLive = false,
  isOnlyOn,
  ...props
}) => {
  const intl = useIntl();

  const thumbnailProps = {
    thumbnailUrl,
    // isPoint, Do not show P icon on Thumbnail for Live Card
    liveOnAirStatus,
    name,
    isLazy,
    isOnlyOn,
  };

  return (
    <Container {...props}>
      <LiveThumbnail {...thumbnailProps} />
      <BottomContainer>
        {isPurchasedLive ? (
          <>
            <Date>{`${dateString}${timeString}`}</Date>
            {catchupEndTimeString && <Catchup>{catchupEndTimeString}</Catchup>}
          </>
        ) : (
          <LiveInfoRow>
            <div>
              <Date>{`${dateString}${timeString}`}</Date>
              <Catchup>
                {hasCatchup && intl.formatMessage(messages.hasCatchup)}
              </Catchup>
            </div>
            <PointPriceBadge
              isPoint={isPoint}
              hasMultiplePrice={!!hasMultiplePrice}
              minimumPrice={minimumPrice}
              badgeSize="medium"
              mobileBadgeSize="small"
            />
          </LiveInfoRow>
        )}
      </BottomContainer>
    </Container>
  );
};

export default LiveCard;
