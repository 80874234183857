import React, { ImgHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';

interface ImageDimensions {
  width: number | 'no_resize';
  height?: number;
}

export interface SizeConfig {
  SD: ImageDimensions;
  MOBILE?: ImageDimensions;
  MOBILE_WIDE?: ImageDimensions;
  TABLET?: ImageDimensions;
  UHD?: ImageDimensions;
}

export interface AkamaiImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  availableSizes?: (number | 'no_resize')[];
  sizeConfig: SizeConfig;
  letterbox?: string;
  isLazy?: boolean;
  alt: string;
}

interface SourceSet {
  webp: string;
  jpg: string;
}

const AdjustedImg = styled.img`
  display: block;
`;

export const createAkamaiImageUrl = (
  src: string,
  size: ImageDimensions,
  type: 'webp' | 'jpg',
  letterbox?: string,
  blur?: string
): string => {
  const quality = type === 'webp' ? 60 : 70;
  let letterboxAppend = '';
  if (letterbox) {
    letterboxAppend = `&letterbox=${letterbox}&${
      blur ? `bgblur=${blur}` : 'bgblur=50,0.5'
    }`;
  }

  if (size.width === 'no_resize') {
    return `${src}?output-format=${type}&output-quality=${quality}${letterboxAppend}`;
  } else {
    return `${src}?output-format=${type}&output-quality=${quality}&resize=${
      size.width
    }:${size.height || '*'}${letterboxAppend}`;
  }
};

const createSourceSet = (
  src: string,
  size: ImageDimensions,
  letterbox?: string
): SourceSet => {
  if (size.width === 'no_resize') {
    return {
      webp: `${createAkamaiImageUrl(src, size, 'webp', letterbox)}`,
      jpg: `${createAkamaiImageUrl(src, size, 'jpg', letterbox)}`,
    };
  } else {
    return {
      webp: `${createAkamaiImageUrl(src, size, 'webp', letterbox)} ${
        size.width
      }w`,
      jpg: `${createAkamaiImageUrl(src, size, 'jpg', letterbox)} ${
        size.width
      }w`,
    };
  }
};

/**
 * Akamai Image Component
 *
 * Use this component anywhere we want to load images in a responsive manner. Images
 * will be loaded in a <picture> tag.
 * (ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture)
 *
 * Usage:
 *
 * This component acts (mostly) like a normal image tag. Pass in a src prop with your image URL
 * and any other props that normally exist on an image tag.
 *
 * sizeConfig is a special prop that lets you define at what breakpoints the expected image
 * size is. This will help the browser choose which size image to load for the space.
 *
 * ```
 * const sizeConfig = {
 *  SD: {
 *    width: 312,
 *  },
 *  TABLET: {
 *    width: 312,
 *  },
 *  MOBILE_WIDE: {
 *    width: 312,
 *  },
 *  MOBILE: {
 *    width: 312,
 *  },
 * };
 * ```
 */
const AkamaiImage: React.FC<AkamaiImageProps> = ({
  src,
  availableSizes = [256, 512],
  sizeConfig,
  letterbox,
  isLazy = false,
  ...props
}) => {
  const [showImage, setShowImage] = useState(!isLazy);
  const placeholder = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let observer: IntersectionObserver | null;
    if (isLazy && !showImage) {
      const callback: IntersectionObserverCallback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowImage(true);
            observer.disconnect();
          }
        });
      };

      observer = new IntersectionObserver(callback);
      if (placeholder.current) {
        observer.observe(placeholder.current);
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isLazy, showImage]);

  const sourceSets = availableSizes.map((size) =>
    createSourceSet(src, { width: size }, letterbox)
  );

  const sizes = [];

  if (sizeConfig.MOBILE && sizeConfig.MOBILE.width !== 'no_resize') {
    sizes.push(`${DEVICE.mobile} ${sizeConfig.MOBILE.width}px`);
  }
  if (sizeConfig.MOBILE_WIDE && sizeConfig.MOBILE_WIDE.width !== 'no_resize') {
    sizes.push(`${DEVICE.mobileWide} ${sizeConfig.MOBILE_WIDE.width}px`);
  }
  if (sizeConfig.TABLET && sizeConfig.TABLET.width !== 'no_resize') {
    sizes.push(`${DEVICE.tablet} ${sizeConfig.TABLET.width}px`);
  }
  if (sizeConfig.UHD && sizeConfig.UHD.width !== 'no_resize') {
    sizes.push(`${DEVICE.onlyUhd} ${sizeConfig.UHD.width}px`);
  }

  // it's ok to have no value for "sizes" attribute when every sizeConfig is 'no_resize',
  // the browser will use the default value "100vw" instead
  if (sizeConfig.SD && sizeConfig.SD.width !== 'no_resize') {
    sizes.push(`${sizeConfig.SD.width}px`);
  }

  if (showImage)
    return (
      <picture>
        <source
          type="image/webp"
          srcSet={sourceSets.map((s) => s.webp).join(', ')}
          sizes={sizes.join(', ')}
        />
        <source
          type="image/jpeg"
          srcSet={sourceSets.map((s) => s.jpg).join(', ')}
          sizes={sizes.join(', ')}
        />
        <AdjustedImg
          loading="lazy"
          src={createAkamaiImageUrl(
            src,
            { width: sizeConfig.SD.width },
            'jpg',
            letterbox
          )}
          {...props}
        />
      </picture>
    );

  // 1px height is needed for IntersectionObserver to properly work in Chrome
  return <div ref={placeholder} style={{ height: '1px' }} />;
};

export default React.memo(AkamaiImage);
