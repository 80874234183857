import { useMutation } from '@apollo/client';
import { useToasts } from '@u-next/react-toast-notifications';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  cosmo_getPostPlayQuery,
  cosmo_getVideoTitleFeatureQuery,
  cosmo_getVideoTitleQuery,
  cosmo_updateTitleEvaluationMutation,
  cosmo_updateTitleEvaluationMutationVariables,
  EvaluationTypes,
  ExclusiveBadgeCode,
} from '../../../__generated__/globalTypes';
import { SNS_SHARE_HOST, UrlQueryParams } from '../../../shared/constants';
import {
  errorMessages,
  globalMessages,
} from '../../../shared/constants/messages';
import { useVideoFavoriteMutation } from '../../../shared/hooks/useFavoriteMutation';
import { useUserInfo } from '../../../shared/hooks/useUserInfo';
import { handleOnlyOnEnter, mapNumberToWeekdayKey } from '../../../utils';
import type { Nullable } from '../../../utils/Nullable';
import {
  STAGE_MYLIST,
  STAGE_PLAY,
  TITLE_SPOT,
} from '../../Log/ComponentName/videoTitleDetail';
import { getKafkaClient } from '../../Log/kafkaClient';
import { useTreasureDataClient } from '../../Log/useTreasureDataClient';
import UserButtonsGroup from '../shared/UserButtonsGroup';
import { UPDATE_TITLE_EVALUATION } from './gql';
import messages from './messages';

import Link from 'next/link';
import { RoundBadge } from '../../../shared/components/Common/Badge/RoundBadge';
import {
  SubDubBadge,
  subDubType,
} from '../../../shared/components/Common/Badge/SubDubBadge';
import { Icons } from '../../../shared/components/Common/Icon';
import RatingStar from '../../../shared/components/Common/RatingStar';
import PointPriceBadge, {
  isPoint,
} from '../../../shared/components/PointPriceBadge';
import useMedia from '../../../shared/hooks/useMedia';
import { DEVICE } from '../../../shared/styles';
import type { VideoTitleDetailTitleSpotLog } from '../../Log/__types__/videoTitleDetail--titleSpot';
import type { VideoTitleDetailStageMylistLog } from '../../Log/__types__/videoTitleDetail-stage-myList';
import type { VideoTitleDetailStagePlayLog } from '../../Log/__types__/videoTitleDetail-stage-play';
import KeyArt, { KeyartLayout, KeyartLink } from '../shared/Keyart';
import { PaymentBadgeList } from '../shared/PaymentBadgeList';
import {
  EvaluationContainer,
  FlexColumnsCenter,
  OnlyOnIcon,
  OriginalIcon,
  PlayButton,
  PlayButtonsLayout,
  StageButtonArea,
  StageDescription,
  StageMetaArea,
  StagePromotionArea,
  StageResumeArea,
  StageSaleText,
  StageSubInfoArea,
  StageTitle,
  StageTitleArea,
  VideoStageSection,
} from '../shared/StageSection';
import { joinWithClipping } from './utils';

export const VideoStageMain = ({
  title,
}: {
  title:
    | NonNullable<
        cosmo_getVideoTitleQuery['webfront_title_stage'] &
          cosmo_getVideoTitleFeatureQuery['webfront_title_stage']
      >
    | NonNullable<
        NonNullable<
          cosmo_getPostPlayQuery['webfront_postPlay']
        >['recommendations']
      >[number];
}) => {
  const intl = useIntl();
  const {
    lastEpisode,
    productionYear,
    minimumPrice,
    productLineupCodeList,
    updateOfWeek,
    nextUpdateDateTime,
    country,
    hasMultiprice,
    hasSubtitle,
    hasDub,
    paymentBadgeList,
    nfreeBadge,
    exclusive,
    isOriginal,
    isNew,
    rate,
    publicMainEpisodeCount,
    __typename,
  } = title;

  const titleInfoTextArray = [];
  if (productionYear) {
    titleInfoTextArray.push(
      `${productionYear}${intl.formatMessage(globalMessages.year)}`
    );
  }

  const countries = country && country.split('/');
  if (countries) {
    titleInfoTextArray.push(`${joinWithClipping(countries, '・', 7)}`);
  }

  if (
    __typename === 'PostPlayItem' &&
    publicMainEpisodeCount &&
    publicMainEpisodeCount > 1
  ) {
    titleInfoTextArray.push(
      intl.formatMessage(messages.episodeSectionFinishedItemsCountText, {
        count: publicMainEpisodeCount,
      })
    );
  }

  const titleInfoLineText =
    titleInfoTextArray.length > 0 ? titleInfoTextArray.join('｜') : undefined;

  let subDub: subDubType | undefined = undefined;
  if (hasSubtitle && hasDub) {
    subDub = 'subdub';
  } else if (hasSubtitle) {
    subDub = 'sub';
  } else if (hasDub) {
    subDub = 'dub';
  }

  const updateTimeString = nextUpdateDateTime
    ? intl.formatDate(new Date(nextUpdateDateTime), {
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';
  const weekdayKey = mapNumberToWeekdayKey(updateOfWeek);
  const weekdayText = weekdayKey
    ? `${intl.formatMessage(globalMessages[weekdayKey])} ${updateTimeString}`
    : '';
  const updateWeekdayInfo =
    weekdayText &&
    intl.formatMessage(globalMessages.updateText, { updateText: weekdayText });

  const newEpisodeText = lastEpisode
    ? `${intl.formatMessage(globalMessages.new)} | ${lastEpisode}`
    : isNew
    ? intl.formatMessage(globalMessages.new)
    : undefined;

  return (
    <>
      <StageTitleArea>
        <StagePromotionArea>
          {isOriginal ? (
            <OriginalIcon />
          ) : exclusive.isOnlyOn ? (
            <>
              <OnlyOnIcon />
              {exclusive.typeCode === ExclusiveBadgeCode.EXCL_SVOD && (
                <RoundBadge badgeType="ghost" mobileBadgeSize="small">
                  {intl.formatMessage(globalMessages.exclsvod)}
                </RoundBadge>
              )}
            </>
          ) : (
            exclusive.typeCode && (
              <RoundBadge badgeType="ghost" mobileBadgeSize="small">
                {exclusive.typeCode === ExclusiveBadgeCode.EXCL &&
                  intl.formatMessage(globalMessages.excl)}
                {exclusive.typeCode === ExclusiveBadgeCode.EXCL_SVOD &&
                  intl.formatMessage(globalMessages.exclsvod)}
              </RoundBadge>
            )
          )}
          {newEpisodeText && (
            <RoundBadge badgeType="promotion" mobileBadgeSize="small">
              {newEpisodeText}
            </RoundBadge>
          )}
          {updateWeekdayInfo && <div>{updateWeekdayInfo}</div>}
        </StagePromotionArea>
        <StageTitle data-ucn="videoStageMain-title-text">
          {title.titleName}
        </StageTitle>
      </StageTitleArea>
      <StageSubInfoArea>
        {(isPoint(title) || paymentBadgeList.length > 0 || nfreeBadge) && (
          <FlexColumnsCenter>
            <PointPriceBadge
              isPoint={isPoint(title)}
              hasMultiplePrice={!!hasMultiprice}
              minimumPrice={minimumPrice ?? undefined}
              badgeSize="large"
              mobileBadgeSize="small"
            />
            {paymentBadgeList.length > 0 && (
              <PaymentBadgeList<
                NonNullable<
                  cosmo_getVideoTitleQuery['webfront_title_stage']
                >['paymentBadgeList'][number]
              >
                list={paymentBadgeList}
              />
            )}
            {nfreeBadge && (
              <RoundBadge
                key="nfree"
                badgeType="ghost_dark"
                mobileBadgeSize="small"
              >
                {nfreeBadge}
              </RoundBadge>
            )}
            {productLineupCodeList.includes('LNPS_NOD') && (
              <RoundBadge badgeType="ghost" badgeSize="medium">
                {intl.formatMessage(globalMessages.nodBadge)}
              </RoundBadge>
            )}
            {productLineupCodeList.includes('LNPS_FSCR') && (
              <RoundBadge badgeType="ghost" badgeSize="medium">
                {intl.formatMessage(globalMessages.foreignSoccerBadge)}
              </RoundBadge>
            )}
          </FlexColumnsCenter>
        )}
        <FlexColumnsCenter>
          <EvaluationContainer>
            <RatingStar rate={rate ?? undefined} iconWidth={12} />
          </EvaluationContainer>
          {titleInfoLineText && <div>{titleInfoLineText}</div>}
          {subDub && (
            <SubDubBadge
              badgeSize="medium"
              badgeType={subDub}
              mobileBadgeSize="small"
            />
          )}
        </FlexColumnsCenter>
      </StageSubInfoArea>
      {title.catchphrase && (
        <StageDescription>{title.catchphrase}</StageDescription>
      )}
    </>
  );
};

interface MainSectionProps {
  isLoggedIn: boolean;
  multiAccountId?: string;
  title: NonNullable<
    cosmo_getVideoTitleQuery['webfront_title_stage'] &
      cosmo_getVideoTitleFeatureQuery['webfront_title_stage']
  >;
}

const VideoMainSection = ({
  isLoggedIn,
  multiAccountId,
  title,
}: MainSectionProps) => {
  const intl = useIntl();
  const router = useRouter();
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );
  const mutateVideoFavorite = useVideoFavoriteMutation();
  const [evaluationFailed, setEvaluationFailed] = useState(false);
  const [updateTitleEvaluation] = useMutation<
    cosmo_updateTitleEvaluationMutation,
    cosmo_updateTitleEvaluationMutationVariables
  >(UPDATE_TITLE_EVALUATION, {
    onCompleted: () => setEvaluationFailed(false),
    onError: () => setEvaluationFailed(true),
  });

  const { data: userInfoData, loading: userInfoLoading } = useUserInfo({
    fetchPolicy: 'cache-first',
  });
  const treasureDataClient = useTreasureDataClient(
    {
      userInfo: userInfoData?.userInfo,
    },
    userInfoLoading
  );

  const { addToast, removeToast } = useToasts();
  const evaluationToastId = useRef<Nullable<string>>(null);
  useEffect(() => {
    if (evaluationToastId.current) {
      removeToast(evaluationToastId.current);
      evaluationToastId.current = null;
    }
    if (evaluationFailed) {
      addToast(
        intl.formatMessage(errorMessages.evaluationUpdateToast),
        { showCloseButton: true, appearance: 'error' },
        (id: string) => {
          evaluationToastId.current = id;
        }
      );
    }
  }, [intl, addToast, removeToast, evaluationToastId, evaluationFailed]);

  const {
    id: titleCode,
    saleText,
    rate,
    userRate,
    bookmarkStatus,
    thumbnail,
  } = title;

  const {
    id: episodeCode,
    displayDurationText,
    duration,
    interruption,
    completeFlag,
    playButtonName,
    purchaseEpisodeLimitday,
  } = title.currentEpisode || {};

  const trackVideoPlayButtonClick = async () => {
    await getKafkaClient().trackUserClickDimension0<VideoTitleDetailStagePlayLog>(
      STAGE_PLAY,
      {
        sakuhin_code: titleCode,
        episode_code: episodeCode ?? '',
      }
    );
  };

  const trackTitleSpotClick = useCallback(async () => {
    await getKafkaClient().trackUserClickDimension0<VideoTitleDetailTitleSpotLog>(
      TITLE_SPOT,
      {
        episode_code: title?.currentEpisode?.id ?? '',
      }
    );
  }, [title]);

  const progress =
    typeof interruption === 'number' && duration ? interruption / duration : 0;
  const progressPercent = completeFlag && progress === 0 ? 100 : progress * 100;
  const remainingMinute =
    typeof interruption === 'number' && duration
      ? intl.formatMessage(globalMessages.remainingMinute, {
          minute:
            completeFlag && interruption === 0
              ? 0
              : Math.ceil((duration - interruption) / 60),
        })
      : undefined;

  const playbackStatus = router.query[UrlQueryParams.PLAYBACK_STATUS_FLAG];
  const playerLink = playbackStatus
    ? `/play/${titleCode}/${episodeCode}?${UrlQueryParams.PLAYBACK_STATUS_FLAG}=${playbackStatus}`
    : `/play/${titleCode}/${episodeCode}`;

  return (
    <>
      {thumbnail?.secondary && (
        <KeyartLayout>
          <KeyartLink
            href={`/play/${titleCode}/${episodeCode}`}
            onClick={trackTitleSpotClick}
            role="button"
            data-ucn="videoMainSection-titleSpot"
            iconType="play"
            legacyBehavior={false}
          >
            <KeyArt backgroundImage={`//${thumbnail.secondary}`} />
          </KeyartLink>
        </KeyartLayout>
      )}
      <VideoStageSection>
        <StageMetaArea>
          <VideoStageMain title={title} />
          <StageResumeArea
            progressPercent={progressPercent}
            remainingText={remainingMinute}
            limitDayText={purchaseEpisodeLimitday ?? undefined}
          />
        </StageMetaArea>
        <StageButtonArea>
          <PlayButtonsLayout>
            <Link
              href={playerLink}
              role="button"
              tabIndex={-1}
              onKeyDown={(e) => {
                handleOnlyOnEnter(e, trackVideoPlayButtonClick);
              }}
              onClick={trackVideoPlayButtonClick}
              legacyBehavior={false}
            >
              <PlayButton
                buttonTheme="light"
                icon={Icons.PLAY}
                buttonSize="large"
                text={playButtonName || undefined}
                subText={displayDurationText ? `(${displayDurationText})` : ''}
                data-ucn={'videoMainSection-stage-play'}
              />
            </Link>
            {isMobile && saleText && <StageSaleText>{saleText}</StageSaleText>}
          </PlayButtonsLayout>
          <UserButtonsGroup
            contentType="video"
            isLoggedIn={isLoggedIn}
            shareUrl={`${SNS_SHARE_HOST}/video/title/${titleCode}`}
            name={title.titleName ?? ''}
            multiAccountId={multiAccountId}
            titleCode={titleCode}
            userRate={userRate ?? undefined}
            setEvaluation={(newUserRate: number) => {
              updateTitleEvaluation({
                variables: {
                  titleCode,
                  type: (newUserRate
                    ? `RATE_${newUserRate / 10}`
                    : 'RATE_0') as EvaluationTypes,
                },
                optimisticResponse: {
                  updateTitleEvaluation: {
                    __typename: 'TitleStage',
                    id: titleCode,
                    userRate: newUserRate,
                    rate,
                  },
                },
              }).then((response) => {
                if (response?.data && treasureDataClient) {
                  treasureDataClient.trackEvaluation(
                    { titleCode, titleName: title?.titleName || '' },
                    newUserRate / 10
                  );
                }
              });
            }}
            isFavorite={bookmarkStatus}
            toggleFavorite={() => {
              const newStatus = !bookmarkStatus;
              mutateVideoFavorite({
                newFavoriteStatus: newStatus,
                titleCode,
                titleName: title?.titleName ?? '',
                needsOptimisticResponse: true,
                onSuccess: () => {
                  getKafkaClient().trackUserClickDimension0<VideoTitleDetailStageMylistLog>(
                    STAGE_MYLIST,
                    {
                      sakuhin_code: titleCode,
                      bookmark_status: newStatus,
                    }
                  );
                },
              });
            }}
            mylistBtnComponentName={'videoMainSection-stage-mylist'}
            rateBtnComponentName={'videoMainSection-stage-rate'}
            shareBtnComponentName={'videoMainSection-stage-share'}
          />
        </StageButtonArea>
        {!isMobile && saleText && <StageSaleText>{saleText}</StageSaleText>}
      </VideoStageSection>
    </>
  );
};

export default VideoMainSection;
