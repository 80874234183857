import styled from 'styled-components';
import { COLORS } from '../../../../styles/theme';
import { Shadow } from '../Thumbnail';

const Container = styled.div`
  width: 100%;
  z-index: 100;
  cursor: pointer;
  position: relative;
  border: 1px solid ${COLORS.white_10};
  border-radius: 10px;
  box-sizing: border-box;
  background: linear-gradient(
    180deg,
    ${COLORS.charcoal} 0%,
    ${COLORS.ink} 100%
  );
  transform: scale(1);
  transition: transform 200ms ease-in, z-index 100ms 100ms linear;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      z-index: 500;

      ${Shadow} {
        opacity: 1;
      }
    }
  }

  /** this pseudo element is for controlling the card's box-shadow transition */
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 8px 32px 0px ${COLORS.jet_black};
    opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover:after {
      opacity: 1;
    }
  }
`;

export default Container;
