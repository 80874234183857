import { defineMessages } from 'react-intl';

const messages = defineMessages({
  preorderDialogTitle: {
    id: 'title.book.preorderDialog.title',
    defaultMessage: '配信時にお知らせしますか？',
  },
  preorderDialogCancelTitle: {
    id: 'title.book.preorderDialog.cancelTitle',
    defaultMessage: '通知予約を解除しますか？',
  },
  preorderDialogText: {
    id: 'title.book.preorderDialog.text',
    defaultMessage:
      '配信が開始されたときにメールと、iPhone・Androidアプリへの通知でお知らせします。',
  },
  preorderDialogCancelText: {
    id: 'title.book.preorderDialog.cancelText',
    defaultMessage:
      '配信が開始されたときにメールと、iPhone・Androidアプリへの通知によるお知らせが受け取れなくなります。',
  },
  preorderDialogButton: {
    id: 'title.book.preorderDialog.button',
    defaultMessage: '通知を予約する',
  },
  preorderDialogCancelButton: {
    id: 'title.book.preorderDialog.cancelButton',
    defaultMessage: '通知予約を解除',
  },
  preorderErrorTitle: {
    id: 'title.book.preorderError.title',
    defaultMessage: '通知予約',
  },
  preorderErrorButton: {
    id: 'title.book.preorderError.button',
    defaultMessage: '閉じる',
  },
  preorderErrorMessage: {
    id: 'title.book.preorderError.preorder',
    defaultMessage: '通知予約できませんでした。もう一度やり直してください。',
  },
  preorderCancelErrorMessage: {
    id: 'title.book.preorderError.cancel',
    defaultMessage:
      '通知予約解除できませんでした。もう一度やり直してください。',
  },
});

export default messages;
