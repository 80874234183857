import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { globalMessages } from '../../constants/messages';
import { FONT_FAMILY } from '../../styles/constants';
import { COLORS } from '../../styles/theme';
import Icon, { Icons } from '../Common/Icon';

const PointNum = styled.span`
  margin-right: 4px;
  font-weight: 510;
  font-family: ${FONT_FAMILY.MONOSPACE};
  color: ${COLORS.white};
`;

const PointIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  > div {
    width: 17.143px;
    height: 17.143px;
  }
`;

interface PointsProps {
  points?: number;
}

const Points: React.FC<PointsProps> = ({ points }) => {
  return (
    <>
      <PointIconContainer>
        <Icon name={Icons.POINT} />
      </PointIconContainer>
      <PointNum data-ucn="points-number-text">
        <FormattedNumber value={points ?? 0} />
      </PointNum>
      <FormattedMessage {...globalMessages.point} />
    </>
  );
};

export default Points;
