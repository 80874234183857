import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import {
  BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE,
  BLOCK_VIEW_TITLE_CARD_SIZE,
} from '../constants';
import VideoMetaInfo, { VideoMetaInfoProps } from './VideoMetaInfo';
import VideoThumbnail, { Shadow, VideoThumbnailProps } from './VideoThumbnail';

const TitleCardContainer = styled.div`
  width: 100%;
  transition: transform 200ms ease-in;
  cursor: pointer;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: ${COLORS.ink};
  transition: border-radius 200ms ease-in;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    border: 1px solid ${COLORS.white_10};
    z-index: 1;
  }
`;

const MetaInfoContainer = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease-in;

  > div {
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    background: ${COLORS.ink};
    border: 1px solid ${COLORS.white_10};
  }
`;

const Container = styled.div`
  position: relative;
  width: ${BLOCK_VIEW_TITLE_CARD_SIZE.video.width}px;
  z-index: 100;
  transition: z-index 100ms 100ms linear;

  @media ${DEVICE.mobileWide} {
    width: ${BLOCK_VIEW_MOBILE_TITLE_CARD_SIZE.video.width}px;
  }

  @media (hover: hover) {
    &:hover {
      z-index: 200;

      @media ${DEVICE.exceptMobileWide} {
        ${TitleCardContainer} {
          transform: translateY(-32px);
        }

        ${ThumbnailContainer} {
          border-radius: 10px 10px 0 0;
          &:before {
            border-bottom: none;
            border-radius: 10px 10px 0 0;
          }
        }

        ${MetaInfoContainer} {
          pointer-events: auto;
          opacity: 1;
        }

        ${Shadow} {
          opacity: 1;
        }
      }
    }
  }
`;

interface ExpandableProps {
  /**
   * Whether to hide invisible elements
   */
  isSimplified?: boolean;
}

const ExpandableVideoCard: React.FC<
  ExpandableProps & VideoThumbnailProps & VideoMetaInfoProps
> = ({
  titleName,
  year,
  rate,
  catchphrase,
  thumbnailUrl,
  updateInfo = '',
  nfreeBadge,
  hasSub = false,
  hasDub = false,
  isPoint = false,
  isNew = false,
  isSimplified = false,
  isLazy = false,
  isOnlyOn = false,
  price,
  hasMultiprice,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const containerHoverHandlers = useMemo(() => {
    return {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
      onFocus: () => setIsHovered(true),
      onBlur: () => setIsHovered(false),
    };
  }, []);

  return (
    <Container {...containerHoverHandlers}>
      <TitleCardContainer>
        <ThumbnailContainer>
          <VideoThumbnail
            titleName={titleName}
            thumbnailUrl={thumbnailUrl}
            isPoint={isPoint}
            isNew={isNew}
            isOnlyOn={isOnlyOn}
            isLazy={isLazy}
          />
        </ThumbnailContainer>
        {
          // only render MetaInfo on hover if this component is marked as "isSimplified"
          // otherwise MetaInfo will always be rendered
          (!isSimplified || isHovered) && (
            <MetaInfoContainer>
              <VideoMetaInfo
                catchphrase={catchphrase}
                hasSub={hasSub}
                hasDub={hasDub}
                nfreeBadge={nfreeBadge}
                year={year}
                rate={rate}
                isPoint={isPoint}
                price={price}
                updateInfo={updateInfo}
                hasMultiprice={hasMultiprice}
              />
            </MetaInfoContainer>
          )
        }
      </TitleCardContainer>
    </Container>
  );
};

export default ExpandableVideoCard;
