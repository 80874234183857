import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { HomeLiveBlockTitleCardLog } from '../../../../domain/Log/__types__/home-liveBlock-titleCard';
import type { LiveBlockDetailTitleCardLog } from '../../../../domain/Log/__types__/liveBlockDetail--titleCard';
import type { LiveSearchCategoryTitleListTitleCardLog } from '../../../../domain/Log/__types__/liveSearchCategoryTitleList--titleCard';
import type { LiveTitleDetailRelatedLiveTitleListTitleCardLog } from '../../../../domain/Log/__types__/liveTitleDetail-relatedLiveTitleList-titleCard';
import type { SearchFreewordLiveTitleListTitleCardLog } from '../../../../domain/Log/__types__/searchFreewordLiveTitleList--titleCard';
import type { SearchFreewordTitleListLiveTitleCardLog } from '../../../../domain/Log/__types__/searchFreewordTitleList-live-titleCard';
import type { VideoGenreTopLiveBlockTitleCardLog } from '../../../../domain/Log/__types__/videoGenreTop-liveBlock-titleCard';
import type { VideoSubjectTopLiveBlockTitleCardLog } from '../../../../domain/Log/__types__/videoSubjectTop-liveBlock-titleCard';
import { LiveOnAirStatus } from '../../LiveNow';
import ListLiveCardMobile from './ListLiveCardMobile';
import LiveCard from './LiveCard';

export const messages = defineMessages({
  deliveryStart: {
    id: 'liveTitleCard.deliveryStart',
    defaultMessage: '{time}配信開始',
  },
  performanceStart: {
    id: 'liveTitleCard.performanceStart',
    defaultMessage: '{time}開演',
  },
  hasCatchup: {
    id: 'liveTitleCard.hasCatchup',
    defaultMessage: '見逃しあり',
  },
  catchupEnd: {
    id: 'liveTitleCard.catchupEnd',
    defaultMessage: '見逃し配信 {time}まで視聴可',
  },
});

interface LiveCardProps {
  name: string;
  thumbnailUrl: string;
  isPoint: boolean;
  liveOnAirStatus?: LiveOnAirStatus;
  liveCatchupEndDateTime?: string;
  hasCatchup: boolean;
  deliveryStartDateTime: string;
  isPurchasedLive?: boolean;
  isLazy: boolean;
  isOnlyOn: boolean;
  minimumPrice?: number;
  hasMultiplePrice: boolean;
  isMobileList?: boolean;
  componentName?:
    | LiveTitleDetailRelatedLiveTitleListTitleCardLog['event']['target']
    | HomeLiveBlockTitleCardLog['event']['target']
    | VideoGenreTopLiveBlockTitleCardLog['event']['target']
    | VideoSubjectTopLiveBlockTitleCardLog['event']['target']
    | SearchFreewordLiveTitleListTitleCardLog['event']['target']
    | SearchFreewordTitleListLiveTitleCardLog['event']['target']
    | LiveSearchCategoryTitleListTitleCardLog['event']['target']
    | LiveBlockDetailTitleCardLog['event']['target'];
}

const LiveTitleCard: React.FC<LiveCardProps> = ({
  isMobileList = false,
  componentName,
  ...props
}) => {
  const intl = useIntl();
  const catchupEndTimeString = props.liveCatchupEndDateTime
    ? intl.formatMessage(messages.catchupEnd, {
        time: intl.formatDate(props.liveCatchupEndDateTime, {
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }),
      })
    : '';

  const dateString = intl.formatDate(props.deliveryStartDateTime, {
    month: 'long',
    day: 'numeric',
  });

  const timeString = intl.formatDate(props.deliveryStartDateTime, {
    hour: '2-digit',
    minute: '2-digit',
  });
  const catchupText = props.hasCatchup
    ? props.isPurchasedLive
      ? catchupEndTimeString
      : intl.formatMessage(messages.hasCatchup)
    : '';
  const deliveryStartTimeString = intl.formatMessage(messages.deliveryStart, {
    time: timeString,
  });

  return isMobileList ? (
    <ListLiveCardMobile
      dateString={dateString}
      deliveryStartTimeString={deliveryStartTimeString}
      catchupText={catchupText}
      data-ucn={componentName}
      {...props}
    />
  ) : (
    <LiveCard
      dateString={dateString}
      timeString={timeString}
      catchupEndTimeString={catchupEndTimeString}
      data-ucn={componentName}
      {...props}
    />
  );
};

export default LiveTitleCard;
