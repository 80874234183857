import React, { ForwardRefRenderFunction, HTMLAttributes } from 'react';
import styled from 'styled-components';

// English
// This is "LegacyIcon" component.
// This component is for Icon without bounding-box which you can take from abstaract.
// If you are using icon with bounding-box which you can take from figma, please use 'Icon' component.

// 日本語
// このコンポーネントは"LegacyIcon"です
// abstractから取得することができるアイコン(bounding-boxなし)で使うためのコンポーネントです
// figmaから取得することができるアイコン(bouding-boxあり)を使う場合には'Icon'を使ってください

const icons = require.context('../../../../assets/icons', true, /\.svg$/);

export enum LegacyIcons {
  // for Leanback
  LB_MYPAGE = 'icon_menu_mypage_bb.svg',
  LB_HOME = 'icon_menu_home_bb.svg',
  LIBRARY = 'icon_library.svg',
  VIDEO = 'icon_video.svg',
  BOOK = 'icon_book.svg',

  // for AppGuidance
  AG_APP = 'appguidance_app.svg',
  AG_CINEMA = 'appguidance_cinema.svg',
  AG_HNEXT = 'appguidance_hnext.svg',

  BUTTON_SIMPLE = 'guide_global_button_simple_standard.svg',
  BUTTON_SIMPLE_HOVER = 'guide_global_button_simple_standard_hover.svg',
  INFO = 'guide_global_Icon_Info.svg',
  INFO_OUTLINE = 'icon_information_info_outline_nobound.svg',
  INFO_FILL = 'icon_information_info_fill_nobound.svg',
  SEARCH = 'guide_global_Icon_search.svg',
  CLEAR_SEARCH = 'guide_global_Icon_clearsearch.svg',
  DETAIL_LINK = 'guide_global_Icon_detail.svg',
  CHECK = 'guide_global_Icon_mylistadded.svg',
  PLAY = 'global_button_play.svg',
  READ = 'icon_media_read.svg',
  BUTTON_RIGHT_ARROW = 'icon_navigation_half_arrow_right.svg',
  BUTTON_LEFT_ARROW = 'icon_navigation_half_arrow_left.svg',
  UP_ARROW = 'icon_navigation_half_arrow_up.svg',
  DOWN_ARROW = 'icon_navigation_half_arrow_down.svg',
  USER = 'Global_Button_MyPage.svg',
  COG = 'icon_player_settings.svg',
  POINT = 'icon_point_circle.svg',
  POINT_FULL = 'icon_point_without_bounding_box.svg',
  MYLIST_ADD = 'guide_global_Icon_mylist_add.svg',
  RATE = 'guide_global_icon_rate.svg',
  STAR_BLANK = 'icon_rating_blank_nobound.svg',
  STAR_HALF = 'icon_rating_half_nobound.svg',
  STAR = 'icon_rating_star_nobound.svg',
  SHARE = 'guide_global_icon_share.svg',
  ABJ_MARK = 'logo_thirdparty_abj-mark.svg',
  L_MARK = 'logo_thirdparty_l-mark.svg',
  NAV_CLOSE = 'icon_navigation_close.svg',
  NAV_NEW_TAB = 'icon_navigation_new_tab.svg',
  OVERFLOW_ELLIPSIS = 'icon_navigation_more_horiz.svg',
  GENRE_DROPDOWN = 'global_Genre_dropdown.svg',
  PLAYER_DROPDOWN = 'global_Icon_player_dropdown.svg',
  EDIT = 'icon_content_edit_nobound.svg',
  EDIT_UNSELECTED = 'pc_choice_chosen.svg',
  EDIT_SELECTED = 'pc_choice_check.svg',
  DELETE = 'icon_content_delete_nobound.svg',
  PLAYER_PLAY = 'icon_media_play.svg',
  PLAYER_REWIND = 'player_rewind.svg',
  PLAYER_FAST_FORWARD = 'player_fast_forward.svg',
  PLAYER_10S_REWIND = 'icon_player_10seconds_back.svg',
  PLAYER_10S_FAST_FORWARD = 'icon_player_10seconds_forward.svg',
  PLAYER_CAMERA = 'icon_player_cameraswitch.svg',
  PLAYER_EPISODE = 'icon_menu_episode.svg',
  PLAYER_NEXT_EPISODE = 'icon_player_nextepisode.svg',
  PLAYER_DUB = 'icon_player_dub.svg',
  PLAYER_SUB = 'icon_player_sub.svg',
  PLAYER_SPEED = 'icon_player_speed.svg',
  PLAYER_FULLSCREEN_OFF = 'icon_player_fullscreen_off.svg',
  PLAYER_FULLSCREEN = 'icon_player_fullscreenon.svg',
  PLAYER_PAUSE = 'icon_player_pause.svg',
  PLAYER_VOLUME_MUTE = 'icon_player_volume_mute.svg',
  PLAYER_VOLUME_ON = 'icon_player_volume_on.svg',
  PLAYER_PINP = 'icon_other_pinp.svg',
  VIEWER_BOOKMARK_ON = 'icon_bookviewer_bookmark_on.svg',
  VIEWER_BOOKMARK_OFF = 'icon_bookviewer_bookmark_off.svg',
  VIEWER_ZOOM_IN = 'icon_bookviewer_zoomin.svg',
  VIEWER_ZOOM_OUT = 'icon_bookviewer_zoomout.svg',
  VIEWER_TOC = 'icon_bookviewer_chapter.svg',
  VIEWER_TEXT_SIZE = 'icon_bookviewer_menu_fontsize_big.svg',
  INFORMATION_HELP = 'icon_information_help.svg',
  INFORMATION_HELP_BUTTON = 'icon_infomation_helpbutton_nobound.svg',
  TRENDING_UP = 'icon_rating_up.svg',
  TRENDING_DOWN = 'icon_rating_down.svg',
  PURCHASE = 'icon_user_purchase.svg',
  PURCHASE_INFO = 'icon_info.svg',
  QUESTION_MARK = 'icon_questionmark.svg',
  CAUTION = 'Icon_Caution.svg',
  ARROW_LEFT = 'icon_navigation_full-allow-left.svg',
  ARROW_RIGHT = 'icon_navigation_full-allow-right.svg',
  MORE_VERTICAL = 'icon_navigation_more_vert_nobound.svg',
  REPLAY = 'icon_navigation_replay.svg',
  ACCOUNT_HELP = 'account_help.svg',
  ICON_LOGO_MARK = 'icon_logo_mark.svg',
}

export enum GenreIcons {
  ADULT = 'genres/adult.svg',
  LIVE = 'genres/live.svg',
  LIVE_MUSIC_STAGE_EVENT = 'genres/music.svg',
  LIVE_MARTIAL_ARTS = 'genres/martial.svg',
  LIVE_SOCCER = 'genres/soccer.svg',
  LIVE_GOLF = 'genres/golf.svg',
  LIVE_BASEBALL = 'genres/baseball.svg',
  LIVE_OTHER_SPORTS = 'genres/othersports.svg',
  MNU0000001 = 'genres/anime.svg',
  MNU0000063 = 'genres/asiatvseries.svg',
  BOOK = 'genres/book.svg',
  COMIC = 'genres/comic.svg',
  DOCUMENT = 'genres/documentary.svg',
  MNU0000050 = 'genres/foreigntvseries.svg',
  MNU0000117 = 'genres/hollywood.svg',
  MNU0000035 = 'genres/japanesemovie.svg',
  MNU0000076 = 'genres/japanesetvseries.svg',
  MNU0000090 = 'genres/kids.svg',
  RANOBE = 'genres/lightnovel.svg',
  MAGAZINE = 'genres/magazine.svg',
  MNU0000018 = 'genres/movie.svg',
  MUSIC_IDOL = 'genres/music.svg',
  NEWS_SPORTS = 'genres/newssports.svg',
  MNU0000124 = 'genres/tvstation.svg',
  MNU0000102 = 'genres/variety.svg',
  STAGE_PLAY = 'genres/stage.svg',
  SOCCER = 'genres/soccer.svg',
  GOLF = 'genres/golf.svg',
  MARTIAL = 'genres/martial.svg',
  OTH_SPORTS = 'genres/baseball.svg',
  UNKNOWN = 'genres/unknown.svg',
}

const IconContainer = styled.div`
  display: inline-flex;
  text-align: center;
  align-self: center;
  width: 1em;
  height: 1em;

  > svg {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0.125em;
  }
`;

interface IconProps extends HTMLAttributes<HTMLDivElement> {
  name: LegacyIcons | GenreIcons;
}

const LegacyIcon: ForwardRefRenderFunction<HTMLDivElement, IconProps> = (
  { name, ...props },
  ref
) => {
  const LoadedIcon = icons(`./${name}`).default;
  return (
    <IconContainer {...props} ref={ref}>
      <LoadedIcon />
    </IconContainer>
  );
};

export default React.forwardRef<HTMLDivElement, IconProps>(LegacyIcon);
