import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../styles/theme';

type CheckboxType = 'normal' | 'caution';

type Props = {
  type: CheckboxType;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
};

const RootSvg = styled.svg<{ $disabled?: boolean }>`
  width: 24px;
  height: 24px;
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
`;

const Background = styled.rect<{ $type: CheckboxType; $checked: boolean }>`
  fill: ${({ $type, $checked = false }) =>
    !$checked
      ? 'transparent'
      : $type === 'caution'
      ? COLORS.caution
      : COLORS.white};
`;

const Checkmark = styled.path<{ $type: CheckboxType; $checked: boolean }>`
  fill: ${({ $type }) =>
    $type === 'caution' ? COLORS.white : COLORS.charcoal};
  opacity: ${({ $checked }) => ($checked ? 1 : 0)};
`;

const CheckboxIcon: React.FC<Props> = (props) => {
  const { type, checked = false, disabled = false, className } = props;

  return (
    <RootSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      $disabled={disabled}
      className={className}
    >
      <rect width="24" height="24" rx="6" fill="white" fillOpacity="0.2" />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="5.5"
        stroke="white"
        strokeOpacity="0.5"
      />
      <Background
        $type={type}
        $checked={checked}
        x="2.5"
        y="2.5"
        width="19"
        height="19"
        rx="3.5"
        strokeOpacity="0.5"
      />
      <Checkmark
        $type={type}
        $checked={checked}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6203 7L18.25 8.65469L10.6516 16.375L5.75 11.4125L7.37969 9.75781L10.6516 13.0672L16.6203 7Z"
      />
    </RootSvg>
  );
};

export default CheckboxIcon;
