import getConfig from 'next/config';
import { AppConfig } from './config/config';

export const GlobalConfig = getConfig().publicRuntimeConfig as AppConfig;

export enum UrlQueryParams {
  TITLE_DETAIL_ID = 'td',
  BOOK_TITLE_DETAIL_ID = 'btd',
  BOOK_CODE = 'bc',
  BOOK_VIEW_CODE = 'bvc',
  BOOK_EPISODE_LIST_FLAG = 'bel',
  LIVE_CODE = 'lc',
  MAIN_GENRE_ID = 'mgc', // this query param was called MAIN_GENRE_CODE, hence the query name
  PLAYBACK_STATUS_FLAG = 'ps',
  FEATURE_PIECE_CODE = 'fpc',
  FEATURE_CODE = 'feid',
  EPISODE_TAB = 'epi',
  ED_EPISODE_TAB = 'edepi',
  LABEL = 'lab',
  BOOK_ALT_PARAMS = 'bap',
  TRACKING_BLOCK_CODE = 'tbc',
  LEAGUE_CODE = 'lec',
  CALENDAR_DATE_OFFSET = 'cdo',
  CALENDAR_FILTER_GENRE = 'cfg',
}
/**
 * Used for matching friendly genre URL names (ex: /genre/movie)
 * to their key counterpart
 */
export enum GenreUrl {
  RANKING = 'ranking',
  YOUGA = 'movie',
  HOUGA = 'japanesemovie',
  FDRAMA = 'foreigntvseries',
  ADRAMA = 'asiatvseries',
  DRAMA = 'japanesetvseries',
  ANIME = 'anime',
  KIDS = 'kids',
  VARIETY = 'variety',
  DOCUMENT = 'documentary',
  MUSIC_IDOL = 'music',
  STAGE_PLAY = 'stageplay',
  SOCCER = 'soccer',
  GOLF = 'golf',
  MARTIAL = 'martial',
  OTH_SPORTS = 'othersports',

  KIDS_BOOK = 'kidsbook',
  FREE_COMIC = 'freecomic',
  MAGAZINE = 'magazine',
  COMIC = 'comic',
  RANOBE = 'lightnovel',
  BOOK = 'book',

  ADULT = 'semiadult',
}

export enum BookGenreUrl {
  KIDS_BOOK = 'kidsbook',
  FREE_COMIC = 'freecomic',
  MAGAZINE = 'magazine',
  COMIC = 'comic',
  RANOBE = 'lightnovel',
  BOOK = 'book',
}

export type LabelCode = 'paravi' | 'txbiz' | 'fsoccer' | 'max' | 'hbo' | 'nod';

export const LabelUrl: Record<LabelCode, string> = {
  paravi: 'paravi',
  txbiz: 'txbiz',
  fsoccer: 'fsoccer',
  max: 'max',
  hbo: 'hbo',
  nod: 'nhkondemand',
};

export const isValidGenreUrl = (id: string): id is keyof typeof GenreUrl => {
  return id in GenreUrl;
};

export enum SALE_TYPE_CODE {
  SOD = 'SOD',
  FOD = 'FOD',
  TOD = 'TOD',
  POD = 'POD',
  EST = 'EST',
}

export enum Bitrate {
  AUTO = 'bitrateAuto',
  LOW = 'bitrateLow',
  HIGH = 'bitrateHigh',
}

// API query params
// https://wiki.unext-info.jp/pages/viewpage.action?pageId=5525035
export const BitrateParams = {
  [Bitrate.AUTO]: { bitrateLow: 192, bitrateHigh: null },
  [Bitrate.LOW]: { bitrateLow: 192, bitrateHigh: 500 },
  [Bitrate.HIGH]: { bitrateLow: 1500, bitrateHigh: null },
};

export const LIST_VIEW_ITEMS_PER_PAGE = {
  DEFAULT: 20,
  KEYWORD_FILTERED_MYLIST: 150,
};

export enum PortalErrorCodes {
  VIDEO_TITLE_NOT_FOUND = 'GAN9900410',
  LIVE_TITLE_NOT_FOUND = 'GAN4000001',
  BOOK_TITLE_NOT_FOUND = 'BKW0000410',
}

export enum UserPlatformType {
  AMAZON = 'amazon',
  UNEXT = 'unext',
  YAMADA = 'yamada',
  BOS = 'bos',
}

export const LINEAR_INFO_URL = 'https://help.unext.jp/channels';
export const DAILY_FREE_HELP_URL =
  'https://video-static.unext.jp/book_daily_free';
export const FSOCCER_PACK_LP = 'https://www.video.unext.jp/lp/football_pack';
export const DAILY_FREE_LP = 'https://t.unext.jp/r/daily_free_comic';

export const APP_STORE_URL = 'https://itunes.apple.com/jp/app/id882477693';
export const GOOGLE_STORE_URL =
  'https://play.google.com/store/apps/details?id=jp.unext.mediaplayer';
export const AMAZON_STORE_URL =
  'https://www.amazon.co.jp/U-NEXT-Co-Ltd-U-NEXT%EF%BC%8F%E3%83%A6%E3%83%BC%E3%83%8D%E3%82%AF%E3%82%B9%E3%83%88-%E6%98%A0%E7%94%BB%E3%83%BB%E3%83%89%E3%83%A9%E3%83%9E%E3%83%BB%E3%82%A2%E3%83%8B%E3%83%A1%E3%82%92%E6%A5%BD%E3%81%97%E3%82%82%E3%81%86%EF%BC%81/dp/B01890I97S';

export const SNS_SHARE_HOST = 'https://video-share.unext.jp';
