import { useEffect, useRef } from 'react';

/**
 * Check if the component is unmounted.
 * Used to prevent async operations to set state on an unmounted component.
 */
export const useIsUnmounted = () => {
  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);
  const getIsUnmounted = () => unmountedRef.current;
  return getIsUnmounted;
};
