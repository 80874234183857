import { useMutation } from '@apollo/client';
import { useToasts } from '@u-next/react-toast-notifications';
import React, {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Nullable } from '../../../utils/Nullable';
import { globalMessages } from '../../constants/messages';
import useLoginUrl from '../../hooks/useLoginUrl';
import { useUserInfo } from '../../hooks/useUserInfo';
import { PreorderContext } from '../../PreorderContext';
import Modal from '../Modal';
import { CANCEL_BOOK_PREORDER, SET_BOOK_PREORDER } from './constants';
import Dialog from './Dialog';
import messages from './messages';

interface PreorderProps extends HTMLAttributes<HTMLButtonElement> {
  bookCode: string;
  setPreorderState?: (preorderState: boolean) => void;
}

enum PreorderErrorType {
  PREORDER,
  CANCEL,
}

const Preorder: React.FC<PreorderProps> = ({
  bookCode,
  children,
  onClick,
  onKeyDown,
  ...props
}) => {
  const intl = useIntl();
  const { data: userInfoData } = useUserInfo();
  const { getLoginUrl } = useLoginUrl();
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isPreorderDialogVisible, setPreorderDialogVisible] = useState(false);
  const [preorderError, setPreorderError] =
    useState<Nullable<PreorderErrorType>>();
  const toastId = useRef<Nullable<string>>();
  const { addToast, removeToast } = useToasts();
  const isLoggedIn = !!userInfoData?.userInfo?.id;

  const handleLoginRedirect = useCallback(() => {
    setLoginModalVisible(false);
    window.location.href = getLoginUrl(window.location.pathname);
  }, [setLoginModalVisible, getLoginUrl]);
  const { preorderStatus, updatePreorderStatus } = useContext(PreorderContext);

  const currentPreorderStatus = preorderStatus.get(bookCode);
  const isPreorderable = !!currentPreorderStatus?.isPreorderable;
  const isPreordered = !!currentPreorderStatus?.isPreordered;

  const [setPreorder] = useMutation(SET_BOOK_PREORDER, {
    onCompleted: () => {
      setPreorderDialogVisible(false);
      updatePreorderStatus({
        payload: [{ bookCode, isPreordered: true, isPreorderable }],
      });
    },
    onError: () => {
      updatePreorderStatus({
        payload: [{ bookCode, isPreordered: false, isPreorderable }],
      });
      setPreorderError(PreorderErrorType.PREORDER);
    },
  });

  const [cancelPreorder] = useMutation(CANCEL_BOOK_PREORDER, {
    onCompleted: () => {
      setPreorderDialogVisible(false);
      updatePreorderStatus({
        payload: [{ bookCode, isPreordered: false, isPreorderable }],
      });
    },
    onError: () => {
      updatePreorderStatus({
        payload: [{ bookCode, isPreordered: true, isPreorderable }],
      });
      setPreorderError(PreorderErrorType.CANCEL);
    },
  });

  useEffect(() => {
    const toastCallback = (id: string) => {
      toastId.current = id;
    };
    if (toastId.current) {
      removeToast(toastId.current);
      toastId.current = null;
    }
    let content;
    if (preorderError === PreorderErrorType.CANCEL) {
      content = intl.formatMessage(messages.preorderCancelErrorMessage);
    } else if (preorderError === PreorderErrorType.PREORDER) {
      content = intl.formatMessage(messages.preorderErrorMessage);
    }
    if (content) {
      addToast(
        content,
        { showCloseButton: true, appearance: 'error' },
        toastCallback
      );
    }
  }, [addToast, removeToast, toastId, preorderError, intl]);

  const togglePreorder = useCallback(() => {
    if (isPreordered) {
      cancelPreorder({
        variables: { bookCodeList: [bookCode] },
      });
    } else {
      setPreorder({
        variables: { bookCode },
      });
    }
  }, [isPreordered, cancelPreorder, setPreorder, bookCode]);

  return (
    <>
      <button
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }
          if (isLoggedIn) {
            setPreorderDialogVisible(true);
          } else {
            setLoginModalVisible(true);
          }
        }}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
          if (isLoggedIn) {
            setPreorderDialogVisible(true);
          } else {
            setLoginModalVisible(true);
          }
        }}
        {...props}
      >
        {children}
      </button>
      <Dialog
        visible={isPreorderDialogVisible}
        onClickOk={() => togglePreorder()}
        onCancel={() => setPreorderDialogVisible(false)}
        isPreordered={isPreordered}
      />
      <Modal
        visible={isLoginModalVisible}
        onCancel={() => setLoginModalVisible(false)}
        onOk={() => handleLoginRedirect()}
        okButtonType="attention"
        okText={intl.formatMessage(globalMessages.login)}
        // We disable the scroll lock for this modal as the title detail
        // will have already locked it
        disableScrollLock
        title={intl.formatMessage(globalMessages.loginRequiredModalTitle)}
      >
        {intl.formatMessage(globalMessages.loginRequiredModalText)}
      </Modal>
    </>
  );
};

export default Preorder;
