import { useIntl } from 'react-intl';
import styled from 'styled-components';
import OriginalLogoComponent from '../../../../../assets/icons/logo/Logo_U-NEXT_Original.svg';
import { globalMessages } from '../../../../constants/messages';
import { COLORS } from '../../../../styles/theme';
import { DailyFreeBadge } from '../../../Common/Badge/DaillyFreeBadge';
import { YomihodaiBadge } from '../../../Common/Badge/PaymentBadge';
import { RoundBadge } from '../../../Common/Badge/RoundBadge';
import RatingStar from '../../../Common/RatingStar';
import PointPriceBadge from '../../../PointPriceBadge';
import { BookMetaInfoProps, getRefinedMetaInfoProps } from './utils';

const MetaInfoContainer = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 3px;
  align-items: normal;
`;

const HorizontalFlexbox = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ gap }) => `gap: ${gap}px;`}
`;

const RatingStarContainer = styled.div`
  display: inline-block;
  width: 58px;
`;

const AuthorText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  color: ${COLORS.white_75};
`;

const UnreadChapter = styled.div`
  color: ${COLORS.primary};
  font-size: 12px;
  font-weight: 600;
`;

const TextM = styled.div`
  font-size: 12px;
  color: ${COLORS.white_75};
`;

const MetaInfoTitle = styled.h3`
  box-sizing: border-box;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
`;

const OriginalLogo = styled(OriginalLogoComponent)`
  width: 52px;
`;

const BookTypeText = styled.span`
  font-weight: 600;
`;

const BookMetaInfoMobile: React.FC<BookMetaInfoProps> = (props) => {
  const intl = useIntl();
  const {
    titleName,
    bookType,
    numberOfFree,
    minimumPrice,
    hasMultiPrice,
    rate,
    isOnSale,
    isPreorderable,
    isNew,
    isDailyFree,
    isDailyFreePlus,
    isOriginal = false,
    isYomihodai,
    hasUnreadChapter,
    authorText,
    bookTypeText,
    totalBookNumText,
    isEpisodeSpecified,
  } = getRefinedMetaInfoProps(props, intl);

  return (
    <MetaInfoContainer>
      <HorizontalFlexbox gap={6}>
        {isOriginal && <OriginalLogo />}
        {(isNew || isPreorderable) && (
          <RoundBadge badgeType="promotion" badgeSize="small">
            {isPreorderable
              ? intl.formatMessage(globalMessages.preorder)
              : intl.formatMessage(globalMessages.new)}
          </RoundBadge>
        )}
      </HorizontalFlexbox>
      <MetaInfoTitle>{titleName}</MetaInfoTitle>
      <HorizontalFlexbox gap={6}>
        <RatingStarContainer>
          <RatingStar rate={rate} iconWidth={10} />
        </RatingStarContainer>
        <TextM>
          <BookTypeText>{bookTypeText}</BookTypeText>
          {bookType !== 'MAGAZINE' && !isEpisodeSpecified && totalBookNumText}
        </TextM>
      </HorizontalFlexbox>
      <HorizontalFlexbox gap={3}>
        {(isDailyFree || isDailyFreePlus) && (
          <DailyFreeBadge
            badgeType={isDailyFreePlus ? 'plus' : 'normal'}
            badgeSize="small"
          />
        )}
        {isYomihodai && <YomihodaiBadge badgeSize="small" />}
        {!isYomihodai &&
          typeof minimumPrice === 'number' &&
          bookType !== 'MAGAZINE' && (
            <PointPriceBadge
              isPoint
              badgeSize="small"
              minimumPrice={minimumPrice}
              hasMultiplePrice={!!hasMultiPrice}
            />
          )}
        {isOnSale && (
          <RoundBadge badgeType="white" badgeSize="small">
            SALE
          </RoundBadge>
        )}
        {numberOfFree && (
          <RoundBadge badgeType="white" badgeSize="small">
            {numberOfFree}
          </RoundBadge>
        )}
      </HorizontalFlexbox>
      <AuthorText>{authorText}</AuthorText>
      {hasUnreadChapter && (
        <UnreadChapter>
          {intl.formatMessage(globalMessages.hasUnread)}
        </UnreadChapter>
      )}
    </MetaInfoContainer>
  );
};

export default BookMetaInfoMobile;
