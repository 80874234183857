import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  cosmo_authorOriginalBooksQuery,
  cosmo_authorOriginalBooksQueryVariables,
  cosmo_bookTitleDetailQuery,
} from '../../../__generated__/globalTypes';
import OriginalLogoComponent from '../../../assets/icons/logo/Logo_U-NEXT_Original.svg';
import BookThumbnail, {
  Shadow,
} from '../../../shared/components/TitleCard/Book/Thumbnail';
import TitleLink from '../../../shared/components/TitleLink';
import { createExtendedApolloError } from '../../../shared/ExtendedApolloError';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import { DEVICE } from '../../../shared/styles';
import { COLORS } from '../../../shared/styles/theme';
import { GET_AUTHOR_ORIGINAL_BOOKS } from './gql';
import messages from './messages';

const OriginalBookIcon = styled(OriginalLogoComponent)`
  display: block;
  height: 24px;
  @media ${DEVICE.mobileWide} {
    height: 22px;
  }
`;

const HeadingSubText = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${COLORS.white_75};

  @media ${DEVICE.mobileWide} {
    font-size: 13px;
  }
`;

const TitleListContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-flow: row wrap;
  gap: 12px;

  @media ${DEVICE.mobileWide} {
    margin-top: 12px;
  }
`;

const TitleCardContainer = styled.div`
  width: 154px;
  transition: transform 200ms ease-in;

  @media ${DEVICE.mobileWide} {
    width: 125px;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      z-index: 500;

      ${Shadow} {
        opacity: 1;
      }
    }
  }
`;

interface OriginalBooksSectionProps {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
}

const BookOriginalBooksSection: React.FC<OriginalBooksSectionProps> = ({
  bookSakuhin,
}) => {
  const intl = useIntl();

  const personCode = bookSakuhin.book?.credits?.[0]?.personCode;

  const { data: originalAuthorBooksData, error: originalAuthorBooksError } =
    useClientQuery<
      cosmo_authorOriginalBooksQuery,
      cosmo_authorOriginalBooksQueryVariables
    >(GET_AUTHOR_ORIGINAL_BOOKS, {
      variables: {
        personCode: personCode as string,
      },
      skip: !personCode,
    });
  // Exclude actual book from showing in others
  const authorOriginalBooks =
    originalAuthorBooksData?.originalBooks?.books.filter(
      (
        sakuhin: cosmo_authorOriginalBooksQuery['originalBooks']['books'][number]
      ) => sakuhin.sakuhinCode !== bookSakuhin.sakuhinCode
    ) ?? [];

  if (originalAuthorBooksError) {
    throw createExtendedApolloError(originalAuthorBooksError, {
      errorCode: '500',
    });
  }

  return (
    <>
      {authorOriginalBooks?.length > 0 && (
        <div>
          <OriginalBookIcon />
          <HeadingSubText>
            {intl.formatMessage(messages.authorOriginalBooks)}
          </HeadingSubText>
          <TitleListContainer>
            {authorOriginalBooks.map((bookSakuhin) => {
              if (!bookSakuhin.book) {
                return <></>;
              }
              return (
                <TitleCardContainer key={bookSakuhin.sakuhinCode}>
                  <TitleLink
                    type="book"
                    titleCode={bookSakuhin.sakuhinCode}
                    optionalCode={{
                      bookCode: bookSakuhin?.book?.code,
                      bookViewCode: bookSakuhin.bookViewCode,
                      featurePieceCode:
                        bookSakuhin.featurePieceCode ?? undefined,
                    }}
                  >
                    <BookThumbnail
                      titleName={bookSakuhin.book?.name}
                      thumbnailUrl={`//${bookSakuhin.book?.thumbnail.standard}`}
                      isLazy
                    />
                  </TitleLink>
                </TitleCardContainer>
              );
            })}
          </TitleListContainer>
        </div>
      )}
    </>
  );
};

export default BookOriginalBooksSection;
