import { gql } from '@apollo/client';
import { bookTitleCardFragment } from '../../../shared/fragments/books';
import { pageInfoFragment } from '../../../shared/fragments/pageInfo';
import { titleCardFragment } from '../../../shared/fragments/videos';

export const GET_VIDEO_TITLE = gql`
  query cosmo_getVideoTitle($code: ID!) {
    webfront_title_stage(id: $code) {
      id
      titleName
      rate
      userRate
      productionYear
      country
      catchphrase
      attractions
      story
      check
      seriesCode
      seriesName
      publicStartDate
      displayPublicEndDate
      restrictedCode
      copyright
      mainGenreId
      bookmarkStatus
      thumbnail {
        standard
        secondary
      }
      mainGenreName
      isNew
      exclusive {
        typeCode
        isOnlyOn
      }
      isOriginal
      lastEpisode
      updateOfWeek
      nextUpdateDateTime
      productLineupCodeList
      hasMultiprice
      minimumPrice
      country
      productionYear
      paymentBadgeList {
        name
        code
      }
      nfreeBadge
      hasDub
      hasSubtitle
      saleText
      currentEpisode {
        id
        interruption
        duration
        completeFlag
        displayDurationText
        existsRelatedEpisode
        playButtonName
        purchaseEpisodeLimitday
      }
      publicMainEpisodeCount
      comingSoonMainEpisodeCount
      missingAlertText
      sakuhinNotices
      hasPackRights
    }
  }
`;

export const GET_VIDEO_TITLE_FEATURE = gql`
  query cosmo_getVideoTitleFeature($code: ID!, $featureCode: ID) {
    webfront_title_stage(id: $code, featureCode: $featureCode) {
      id
      feature(featureCode: $featureCode) {
        id
        featureName
        titleComment
      }
    }
  }
`;

export const GET_VIDEO_TITLE_EPISODES = gql`
  query cosmo_getVideoTitleEpisodes($code: ID!, $page: Int, $pageSize: Int) {
    webfront_title_titleEpisodes(id: $code, page: $page, pageSize: $pageSize) {
      episodes {
        id
        episodeName
        purchaseEpisodeLimitday
        thumbnail {
          standard
        }
        duration
        displayNo
        interruption
        completeFlag
        saleTypeCode
        introduction
        saleText
        episodeNotices
        isNew
        hasPackRights
        minimumPrice
        hasMultiplePrices
        productLineupCodeList
        isPurchased
        purchaseEpisodeLimitday
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
`;

export const GET_VIDEO_TITLE_RELATED_STUFFS = gql`
  query cosmo_getVideoTitleRelatedStuffs(
    $code: ID!
    $creditsPage: Int
    $creditsPageSize: Int
  ) {
    webfront_title_credits(
      id: $code
      page: $creditsPage
      pageSize: $creditsPageSize
    ) {
      titleCredits {
        personNameCode
        personName
        characterName
        castTypeName
        personCode
      }
    }
    webfront_title_relatedTitles(id: $code) {
      id
      groupName
      titles {
        ...TitleCard
      }
    }
    webfront_title_relatedBooks(id: $code, pageSize: 20, page: 1) {
      pageInfo {
        ...PageInfo
      }
      books {
        ...BookTitleCard
      }
    }
    webfront_title_recommendedTitles(id: $code) {
      titles {
        ...TitleCard
      }
    }
  }

  ${pageInfoFragment}
  ${titleCardFragment}
  ${bookTitleCardFragment}
`;

export const UPDATE_TITLE_EVALUATION = gql`
  mutation cosmo_updateTitleEvaluation(
    $titleCode: ID!
    $type: EvaluationTypes!
  ) {
    updateTitleEvaluation: webfront_updateTitleStageEvaluation(
      id: $titleCode
      type: $type
    ) {
      id
      rate
      userRate
    }
  }
`;
