import React, { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { globalMessages } from '../../../constants/messages';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';

export const subDubTypes = ['subdub', 'sub', 'dub'] as const;

export type subDubType = (typeof subDubTypes)[number];

export const subDubBadgeSizes = ['small', 'medium'] as const;
export type subDubBadgeSize = (typeof subDubBadgeSizes)[number];

interface BadgeContainerProps {
  badgeSize: subDubBadgeSize;
  mobileBadgeSize?: subDubBadgeSize;
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;

  border-radius: 4px;
  border: 0.5px solid ${COLORS.white_05};
  background: ${COLORS.white_10};

  color: ${COLORS.white_75};
  font-size: 11px;
  line-height: 1.7;
  font-weight: 400;
  letter-spacing: -0.55px;

  ${({ badgeSize }) =>
    badgeSize === 'small' &&
    css`
      padding: 0 4px;
      line-height: 1.56;
      font-size: 9px;
    `};

  @media ${DEVICE.mobileWide} {
    ${({ mobileBadgeSize }) =>
      mobileBadgeSize === 'small' &&
      css`
        padding: 0 4px;
        line-height: 1.56;
        font-size: 9px;
      `};
  }
`;

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  badgeType: subDubType;
  badgeSize: subDubBadgeSize;
  mobileBadgeSize?: subDubBadgeSize;
}

export const SubDubBadge: React.FC<BadgeProps> = ({
  badgeType,
  children,
  ...props
}) => {
  const intl = useIntl();
  return (
    <BadgeContainer data-ucn="subDubBadge" {...props}>
      {intl.formatMessage(
        badgeType === 'subdub'
          ? globalMessages.subDub
          : badgeType === 'sub'
          ? globalMessages.sub
          : globalMessages.dub
      )}
    </BadgeContainer>
  );
};
