import debounce from 'just-debounce-it';
import { useEffect, useMemo, useRef, useState } from 'react';

type Options = {
  delay: number;
  rootMargin?: string;
};

const useIsOffScreen = (
  { delay, rootMargin }: Options = {
    delay: 100,
    rootMargin: '200px 0px 200px 0px',
  }
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOffScreen, setIsOffScreen] = useState(false);

  const intersectionCallback = useMemo(
    () =>
      debounce((entries: IntersectionObserverEntry[]) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          setIsOffScreen(false);
        } else {
          setIsOffScreen(true);
        }
      }, delay),
    [delay]
  );

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new IntersectionObserver(intersectionCallback, {
      rootMargin,
    });
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [intersectionCallback, rootMargin]);

  return { containerRef, isOffScreen };
};

export default useIsOffScreen;
