import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { cosmo_getLiveDetailQuery } from '../../../__generated__/globalTypes';
import { ButtonLookDiv } from '../../../shared/components/Common/Button';
import { Icons } from '../../../shared/components/Common/Icon';
import { DEVICE } from '../../../shared/styles';
import type { LiveTitleDetailStageTestPlayLog } from '../../Log/__types__/liveTitleDetail-stage-testPlay';
import { STAGE_TESTPLAY } from '../../Log/ComponentName/liveTitleDetail';
import { getKafkaClient } from '../../Log/kafkaClient';
import {
  FlexColumns,
  InfoContent,
  InfoHeading,
  InfoSection,
  InfoText,
} from '../shared/InfoSection';
import messages from '../Video/messages';

const SamplePlayButtonContainer = styled.div`
  flex-grow: 0;
  align-self: center;
  @media ${DEVICE.mobileWide} {
    align-self: flex-end;
  }
`;

interface LiveSamplePlaySectionProps {
  liveDetail: cosmo_getLiveDetailQuery['webfront_getLive'];
}

const LiveSamplePlaySection: React.FC<LiveSamplePlaySectionProps> = ({
  liveDetail,
}) => {
  const intl = useIntl();

  return (
    <InfoSection>
      <FlexColumns>
        <InfoContent>
          <InfoHeading>
            {intl.formatMessage(messages.livePurchaseAttentionTitle)}
          </InfoHeading>
          <InfoText>
            {intl.formatMessage(messages.livePurchaseAttentionText)}
          </InfoText>
        </InfoContent>
        <SamplePlayButtonContainer>
          <Link
            href={`/live/${liveDetail.testLiveId}`}
            onClick={async () => {
              await getKafkaClient().trackUserClickDimension0<LiveTitleDetailStageTestPlayLog>(
                STAGE_TESTPLAY,
                {
                  live_code: liveDetail.id,
                }
              );
            }}
            legacyBehavior={false}
            role="button"
          >
            <ButtonLookDiv
              data-ucn={STAGE_TESTPLAY}
              buttonTheme="ghost"
              buttonSize="large"
              buttonSizeMobile="small"
              icon={Icons.PLAY}
              text={intl.formatMessage(messages.playTestButtonText)}
            />
          </Link>
        </SamplePlayButtonContainer>
      </FlexColumns>
    </InfoSection>
  );
};

export default LiveSamplePlaySection;
