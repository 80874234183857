import { gql } from '@apollo/client';

export const SET_BOOK_PREORDER = gql`
  mutation cosmo_setBookPreorder($bookCode: ID!) {
    webfront_setBookPreorder(bookCode: $bookCode) {
      set
    }
  }
`;

export const CANCEL_BOOK_PREORDER = gql`
  mutation cosmo_cancelBookPreorder($bookCodeList: [ID!]!) {
    webfront_cancelBookPreorder(bookCodeList: $bookCodeList)
  }
`;
