import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { COMIC_CHAPTERS_MAX_NUM } from '.';
import {
  cosmo_bookTitleBooksQuery,
  cosmo_bookTitleBooksQueryVariables,
  cosmo_bookTitleDetailQuery,
} from '../../../__generated__/globalTypes';
import { UrlQueryParams } from '../../../shared/constants';
import { globalMessages } from '../../../shared/constants/messages';
import useClientQuery from '../../../shared/hooks/useClientQuery';
import useEpisodePagingTabList from '../../../shared/hooks/useEpisodePagingTabList';
import useMedia from '../../../shared/hooks/useMedia';
import { PreorderContextType } from '../../../shared/PreorderContext';
import { DEVICE } from '../../../shared/styles';
import { mergeQueryString } from '../../../utils/routeHelper';
import type { BookTitleDetailEpisodeListReadLog } from '../../Log/__types__/bookTitleDetail-episodeList-read';
import * as bookTitleDetail from '../../Log/ComponentName/bookTitleDetail';
import { getKafkaClient } from '../../Log/kafkaClient';
import { default as purchaseMessages } from '../../purchase/messages';
import StackedPager, {
  StackedBookGroup,
  StackedBookItem,
  StackedPagerPagingStyle,
} from '../shared/StackedPager';
import StackedBook, {
  StackedBookPlaceholder,
} from '../shared/StackedPager/StackedItem/StackedBook';
import StackedChapter from '../shared/StackedPager/StackedItem/StackedChapter';
import { GET_BOOK_TITLE_BOOKS } from './gql';
import { default as bookTitleMessages } from './messages';
import { getBookLimitDayText } from './utils';

const messages = defineMessages({
  bookListHeading: {
    id: 'title.book.books.heading',
    defaultMessage: 'エピソード',
  },
  bookNum: {
    id: 'title.book.books.bookNum',
    defaultMessage: '({count}冊)',
  },
  bookNumber: {
    id: 'title.book.books.bookNumber',
    defaultMessage: '{count}冊目',
  },
  showAll: {
    id: 'title.book.books.showAll',
    defaultMessage: 'エピソードを選択（{count}冊）',
  },
  chapterNum: {
    id: 'title.book.books.chapterNum',
    defaultMessage: '({count}話)',
  },
  showAllChapters: {
    id: 'title.book.books.showAllChapters',
    defaultMessage: 'エピソードを選択（{count}話）',
  },
  nextUpdate: {
    id: 'title.book.books.nextUpdate',
    defaultMessage: '{date}予定',
  },
});

type BookEpisodeListSectionProps = {
  bookSakuhin: cosmo_bookTitleDetailQuery['bookTitle'];
  pagingStyle?: StackedPagerPagingStyle;
  variant: 'normal' | 'comicDetail' | 'chapterListModal';
} & PreorderContextType;

const BookEpisodeListSection: React.FC<BookEpisodeListSectionProps> = ({
  bookSakuhin,
  updatePreorderStatus,
  preorderStatus,
  pagingStyle,
  variant,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const isMobile = useMedia(
    [DEVICE.mobileWide, DEVICE.exceptMobileWide],
    [true, false],
    false
  );
  const isComicDetail = variant === 'comicDetail';
  const isChapterListModal = variant === 'chapterListModal';
  const pageQuery = router.query[UrlQueryParams.EPISODE_TAB]
    ? Number(router.query[UrlQueryParams.EPISODE_TAB]) + 1
    : 1;

  const [totalItemCount, setTotalItemCount] = useState(
    bookSakuhin.totalBookNumIncludingPreorders ?? 0
  );

  const { itemsPerPage: booksPageSize } =
    useEpisodePagingTabList(totalItemCount);

  const {
    data: booksData,
    previousData: previousBooksData,
    loading: booksLoading,
  } = useClientQuery<
    cosmo_bookTitleBooksQuery,
    cosmo_bookTitleBooksQueryVariables
  >(GET_BOOK_TITLE_BOOKS, {
    variables: {
      bookSakuhinCode: bookSakuhin.sakuhinCode,
      booksPage: pageQuery,
      booksPageSize,
    },
    skip: isComicDetail,
    fetchPolicy: 'cache-and-network',
  });

  /**
   * There's possibility the actual item count from booksData is different from the previous one.
   * For example, purchased or rented items which already stopped publishing.
   * To be safe, update totalItemCount if necessary. In this case, an addition request will be made.
   */
  const totalItemCountFromBooksData =
    booksData?.bookTitle_books?.pageInfo?.results;
  useEffect(() => {
    if (
      typeof totalItemCountFromBooksData === 'number' &&
      totalItemCountFromBooksData !== totalItemCount
    ) {
      setTotalItemCount(totalItemCountFromBooksData);
    }
  }, [totalItemCount, totalItemCountFromBooksData]);

  const books = booksData?.bookTitle_books;
  const previousBooks = previousBooksData?.bookTitle_books;

  const bookItems = useMemo(() => {
    if (isComicDetail) {
      return bookSakuhin.bookList?.flatMap((item) => (item ? item : [])) || [];
    }
    return (
      (booksLoading
        ? previousBooksData?.bookTitle_books?.books
        : booksData?.bookTitle_books?.books) || []
    );
  }, [
    bookSakuhin.bookList,
    booksData?.bookTitle_books?.books,
    booksLoading,
    isComicDetail,
    previousBooksData?.bookTitle_books?.books,
  ]);

  useEffect(() => {
    if (!booksLoading) {
      const preorderableBooks = bookItems
        .filter((book) => book.isPreorderable)
        .map((book) => {
          return {
            bookCode: book.code,
            isPreorderable: !!book.isPreorderable,
            isPreordered: !!book.isPreordered,
          };
        });
      if (preorderableBooks && preorderableBooks.length > 0) {
        updatePreorderStatus({ payload: preorderableBooks });
      }
    }
  }, [bookItems, booksLoading, updatePreorderStatus]);

  const isChapter = bookSakuhin.isChapter;
  const stackedBookList: StackedBookItem[] = [...bookItems];
  let groupedBookItems: StackedBookGroup[] = [];
  if (isChapterListModal) {
    groupedBookItems = bookItems.reduce<StackedBookGroup[]>((prev, cur) => {
      const previousGroup = prev[prev.length - 1];
      if (!previousGroup || previousGroup.code !== cur.includedBook?.code) {
        // 1. 最初の単行本グループの場合、または、前の単行本グループと異なる単行本グループの場合、新しい単行本グループを追加
        prev.push(
          // 属する単行本が存在する場合、単行本の情報も定義。存在しない場合、単行本情報はnull
          cur.includedBook
            ? {
                code: cur.includedBook.code,
                info: {
                  title: intl.formatMessage(messages.bookNumber, {
                    count: cur.includedBook.bookNumber,
                  }),
                  image: cur.includedBook.thumbnail.standard
                    ? `//${cur.includedBook.thumbnail.standard}`
                    : '',
                  titleLinkProps: {
                    type: 'book',
                    titleCode: cur.includedBook.sakuhinCode,
                    optionalCode: { bookCode: cur.includedBook.code },
                  },
                },
                items: [cur],
              }
            : { code: undefined, info: null, items: [cur] }
        );
      } else {
        // 2. 前の単行本グループと同じ単行本グループの場合、前の単行本グループのitemsに追加
        prev[prev.length - 1].items.push(cur);
      }
      return prev;
    }, []);

    const isLastPage =
      pageQuery === booksData?.bookTitle_books?.pageInfo?.pages;
    if (bookSakuhin.nextReleaseDatetime && isLastPage) {
      // 「次回更新」用のグループをリストの最後に追加
      groupedBookItems.push({
        code: 'nextUpdate',
        info: null,
        items: [
          {
            updateText: intl.formatMessage(messages.nextUpdate, {
              date: intl.formatDate(bookSakuhin.nextReleaseDatetime, {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }),
            }),
          },
        ],
      });
    }
  } else if (isComicDetail && isChapter) {
    const lastBook = stackedBookList[bookItems.length - 1];
    if (
      stackedBookList.length < COMIC_CHAPTERS_MAX_NUM &&
      'isLatestBook' in lastBook &&
      lastBook.isLatestBook &&
      bookSakuhin.nextReleaseDatetime !== null
    ) {
      // 「次回更新」用のアイテムをリストの最後に追加
      stackedBookList.push({
        updateText: intl.formatMessage(messages.nextUpdate, {
          date: intl.formatDate(bookSakuhin.nextReleaseDatetime, {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
        }),
      });
    }
  }
  const showAllLink = mergeQueryString(router, {
    [UrlQueryParams.BOOK_EPISODE_LIST_FLAG]: 'true',
  }).path;
  const totalBookNumIncludingPreorders =
    bookSakuhin.totalBookNumIncludingPreorders ?? 0;

  return (isComicDetail && totalBookNumIncludingPreorders > 0) ||
    totalBookNumIncludingPreorders > 1 ? (
    <StackedPager
      {...(isChapterListModal
        ? { groups: groupedBookItems }
        : { items: stackedBookList })}
      page={pageQuery}
      totalItemsCount={
        books?.pageInfo?.results || previousBooks?.pageInfo?.results || 0
      }
      itemsCountText={
        bookSakuhin.totalBookNum > 0
          ? intl.formatMessage(
              isChapter ? messages.chapterNum : messages.bookNum,
              {
                count: bookSakuhin.totalBookNum,
              }
            )
          : undefined
      }
      purchasedItemsCount={bookSakuhin.purchaseBookNum ?? undefined}
      isBookChapter={isChapter}
      headingText={intl.formatMessage(messages.bookListHeading)}
      componentName={bookTitleDetail.EPISODE_LIST_PAGER_TAB}
      pagingStyle={pagingStyle}
      showAllLinkProps={
        isComicDetail && totalBookNumIncludingPreorders > 1
          ? {
              text: intl.formatMessage(
                isChapter ? messages.showAllChapters : messages.showAll,
                {
                  count: bookSakuhin.totalBookNum,
                }
              ),
              href: showAllLink,
            }
          : undefined
      }
      hideBoxUI={(isMobile && !isComicDetail) || isChapterListModal}
      cellRender={({ item, loading }) => {
        if (item && !loading) {
          if ('updateText' in item) {
            return <StackedChapter nextUpdateText={item.updateText} />;
          }
          if (isChapter) {
            return (
              <StackedChapter
                sakuhinCode={bookSakuhin.sakuhinCode}
                book={item}
                tickets={bookSakuhin.bookTickets}
                price={
                  item.bookContent?.productList?.[0]?.salePeriod?.price ?? 0
                }
                progress={item.bookContent?.mainBookFile?.resumePoint ?? 0}
                isComplete={item.bookContent?.mainBookFile?.completeFlg}
                limitDay={
                  item.rightsExpirationDatetime
                    ? intl.formatMessage(purchaseMessages.bookAvailableUntil, {
                        dateTime: intl.formatDate(
                          item.rightsExpirationDatetime,
                          {
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          }
                        ),
                      })
                    : undefined
                }
              />
            );
          }

          const trackEpisodePlay = async () => {
            if (bookSakuhin) {
              await getKafkaClient().trackUserClickDimension0<BookTitleDetailEpisodeListReadLog>(
                bookTitleDetail.EPISODE_LIST_READ,
                {
                  book_sakuhin_code: bookSakuhin.sakuhinCode,
                  book_code: item.code,
                }
              );
            }
          };
          const isPreorderable = !!preorderStatus.get(item.code)
            ?.isPreorderable;
          const isPreordered = !!preorderStatus.get(item.code)?.isPreordered;

          const preorderableBookPublicDateText =
            isPreorderable && item?.publicStartDateTime
              ? `${intl.formatDate(item.publicStartDateTime, {
                  month: 'long',
                  day: 'numeric',
                })}${intl.formatMessage(globalMessages.plannedPublicStart)}`
              : undefined;

          return (
            <StackedBook
              contentText={item.introduction}
              parentCode={bookSakuhin.sakuhinCode}
              bookCodes={{
                bookCode: item?.code,
                bookViewCode: 'BOOK',
              }}
              titleText={item.name}
              thumbnailUrl={`//${item.thumbnail.standard}`}
              isPurchased={!!item.isPurchased}
              isFree={!!item.isFree}
              isSale={!!item.isSale}
              isNew={!!item.isNew}
              isPreorderable={isPreorderable}
              isPreordered={isPreordered}
              publicStartText={
                item.originalSaleDate && !item.isPreorderable
                  ? `${intl.formatDate(item.originalSaleDate, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}${intl.formatMessage(bookTitleMessages.publicStart)}`
                  : null
              }
              progress={item.bookContent?.mainBookFile?.resumePoint}
              isComplete={item.bookContent?.mainBookFile?.completeFlg}
              playComponentName={bookTitleDetail.EPISODE_LIST_READ}
              onPlayClick={trackEpisodePlay}
              hasSample={!!item.bookContent?.sampleBookFile?.code}
              saleText={item.saleText}
              maxLines={4}
              price={item.bookContent?.productList?.[0]?.salePeriod?.price ?? 0}
              preorderableBookPublicDateText={preorderableBookPublicDateText}
              limitDay={
                item.rightsExpirationDatetime
                  ? getBookLimitDayText(item.rightsExpirationDatetime, intl)
                  : undefined
              }
            />
          );
        } else {
          return <StackedBookPlaceholder />;
        }
      }}
    />
  ) : (
    <></>
  );
};

export default BookEpisodeListSection;
