import { gql } from '@apollo/client';

// Fragment used for label
export const labelFragment = gql`
  fragment Label on Label {
    code
    name
    catch
    genreCode
    searchMenuList {
      id
      name
      menuTypeCode
      parentMenuCode
      parentMenuName
      defaultSortOrder
      captionDubFlag
    }
    thumbnail {
      standard
      secondary
      logo
    }
  }
`;
