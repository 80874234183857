import { gql } from '@apollo/client';
import { bookTitleCardFragment } from '../../shared/fragments/books';
import { labelFragment } from '../../shared/fragments/label';
import { liveCardFragment } from '../../shared/fragments/lives';
import { pageInfoFragment } from '../../shared/fragments/pageInfo';
import { titleCardFragment } from '../../shared/fragments/videos';

export const GET_SEARCH_GENRES = gql`
  query cosmo_getSearchGenres {
    webfront_searchGenres {
      displayCode
      name
      searchGenres {
        id
        name
        displayCode
        menuTypeCode
        searchCategories {
          name
          id
        }
      }
    }
  }
`;

export const GET_ALL_FREEWORD = gql`
  query cosmo_allFreewordSearch(
    $query: String!
    $page: Int
    $pageSize: Int
    $videoSortOrder: PortalSortOrder!
    $bookSortOrder: PortalSortOrder!
  ) {
    webfront_videoFreewordSearch(
      query: $query
      page: $page
      pageSize: $pageSize
      sortOrder: $videoSortOrder
    ) {
      titles {
        ...TitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
    webfront_bookFreewordSearch(
      query: $query
      page: $page
      pageSize: $pageSize
      sortOrder: $bookSortOrder
    ) {
      books {
        ...BookTitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
    webfront_personFreewordSearch(
      query: $query
      page: $page
      pageSize: $pageSize
    ) {
      people {
        personName
        personCode
        personNameCode
      }
      pageInfo {
        ...PageInfo
      }
    }
    liveFreewordSearch(query: $query, page: $page, pageSize: $pageSize) {
      liveList {
        ...LiveCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${liveCardFragment}
  ${titleCardFragment}
  ${bookTitleCardFragment}
`;

export const GET_FREEWORD_VIDEO = gql`
  query cosmo_videoFreewordSearch(
    $query: String!
    $page: Int
    $pageSize: Int
    $sortOrder: PortalSortOrder!
    $filterSaleType: SaleType
  ) {
    webfront_videoFreewordSearch(
      query: $query
      page: $page
      pageSize: $pageSize
      sortOrder: $sortOrder
      filterSaleType: $filterSaleType
    ) {
      titles {
        ...TitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${titleCardFragment}
`;

export const GET_FREEWORD_BOOK = gql`
  query cosmo_bookFreewordSearch(
    $query: String!
    $page: Int
    $pageSize: Int
    $sortOrder: PortalSortOrder!
    $filterSaleType: BookSaleType
  ) {
    webfront_bookFreewordSearch(
      query: $query
      page: $page
      pageSize: $pageSize
      sortOrder: $sortOrder
      filterSaleType: $filterSaleType
    ) {
      books {
        ...BookTitleCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${bookTitleCardFragment}
`;

export const GET_FREEWORD_LIVE = gql`
  query cosmo_liveFreewordSearch($query: String!, $page: Int, $pageSize: Int) {
    liveFreewordSearch(query: $query, page: $page, pageSize: $pageSize) {
      liveList {
        ...LiveCard
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
  ${liveCardFragment}
`;

export const GET_FREEWORD_PERSON = gql`
  query cosmo_personFreewordSearch(
    $query: String!
    $page: Int
    $pageSize: Int
  ) {
    webfront_personFreewordSearch(
      query: $query
      page: $page
      pageSize: $pageSize
    ) {
      people {
        personName
        personCode
        personNameCode
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${pageInfoFragment}
`;

export const GET_AUTO_SUGGESTION = gql`
  query autoSuggest($query: String!, $pageSize: Int!) {
    autoSuggest(query: $query, pageSize: $pageSize, suggesterType: GENERAL)
  }
`;

export const GET_ALL_LABEL = gql`
  query cosmo_all_label {
    labelBlock {
      name
      labelList {
        ...Label
      }
    }
  }
  ${labelFragment}
`;
