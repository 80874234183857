import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  videoPlayButtonText: {
    id: 'title.video.playButton.text',
    defaultMessage: '再生',
    description: 'playButton text',
  },
  preorderButtonText: {
    id: 'title.book.preorderButton.text',
    defaultMessage: '通知予約',
    description: 'preorder button text',
  },
  preorderedButtonText: {
    id: 'title.book.preorderedButton.text',
    defaultMessage: '予約済み',
    description: 'preordered button text',
  },
  signupPromotionText: {
    id: 'title.video.signupPromotion.text',
    defaultMessage:
      '映画、アニメ、ドラマがもりだくさん！\n日本最大級の動画サービス',
    description: 'signupPromotion text',
  },
  bookSignupPromotionText: {
    id: 'title.book.signupPromotion.text',
    defaultMessage:
      'マンガなどの購入に使える600円分の\nU-NEXTポイントをプレゼント中！',
    description: 'book signupPromotion text',
  },
  bookChapterSignupPromotionText: {
    id: 'title.book.chapter.signupPromotion.text',
    defaultMessage:
      '人気マンガが「毎日無料」で楽しめる！\n対象作品は、毎日追加！',
    description: 'book chapter signupPromotion text',
  },
  soccerPromotionText: {
    id: 'title.video.soccerPromotion.text',
    defaultMessage: '「サッカーパック」で世界最高峰の欧州サッカーを楽しもう！',
    description: 'soccerPromotion text',
  },
  signupPromotionButtonText: {
    id: 'title.video.signupPromotionButton.text',
    defaultMessage: '無料トライアルで今すぐ観る',
    description: 'signupPromotionButton text',
  },
  watchNow: {
    id: 'title.video.watchNow.text',
    defaultMessage: '今すぐ観る',
    description: 'watchNow button text',
  },
  bookSignupPromotionButtonText: {
    id: 'title.book.signupPromotionButton.text',
    defaultMessage: '無料トライアルで今すぐ読む',
    description: 'book signupPromotionButton text',
  },
  bookChapterSignupPromotionButtonText: {
    id: 'title.book.chapter.signupPromotionButton.text',
    defaultMessage: '最初から読む（無料）',
    description: 'book chapter signupPromotionButton text',
  },
});
