/**
 *  @see https://wiki.unext-info.jp/pages/viewpage.action?pageId=57624736
 */
export const HEADER_CLOSE = 'liveTitleDetail-header-close';
export const STAGE_PLAY = 'liveTitleDetail-stage-play';
export const STAGE_TESTPLAY = 'liveTitleDetail-stage-testPlay';
export const TITLE_SPOT = 'liveTitleDetail--titleSpot';

//TODO: WF6-446 Add below once feature is done
export const STAGE_SIGNUP = 'liveTitleDetail-stage-signup';

export const RELATED_LIVE_TITLE_LIST_TITLE_CARD =
  'liveTitleDetail-relatedLiveTitleList-titleCard';
export const RELATED_VIDEO_TITLE_LIST_TITLE_CARD =
  'liveTitleDetail-relatedVideoTitleList-titleCard';
export const CAST_LIST_NAME = 'liveTitleDetail-castList-name';
