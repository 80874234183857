import React, { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { globalMessages } from '../../../constants/messages';
import { DEVICE } from '../../../styles';
import { COLORS } from '../../../styles/theme';
import Icon, { Icons } from '../Icon';

export const purchasedBadgeSizes = ['small', 'medium'] as const;
export type purchasedBadgeSize = (typeof purchasedBadgeSizes)[number];

interface BadgeContainerProps {
  badgeSize?: purchasedBadgeSize;
  mobileBadgeSize?: purchasedBadgeSize;
}

const BadgeContainer = styled.div<BadgeContainerProps>`
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;

  color: ${COLORS.primary};

  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;

  > div {
    width: 18px;
  }

  ${({ badgeSize }) =>
    badgeSize === 'small' &&
    css`
      font-size: 10px;
      > div {
        width: 14px;
      }
    `};

  @media ${DEVICE.mobileWide} {
    ${({ mobileBadgeSize }) =>
      mobileBadgeSize === 'small' &&
      css`
        font-size: 10px;
        > div {
          width: 14px;
        }
      `};
  }
`;

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  badgeSize?: purchasedBadgeSize;
  mobileBadgeSize?: purchasedBadgeSize;
  iconOnly?: boolean;
  meta?: {
    purchasedNum: number;
    totalNum?: number;
  };
  isChapter?: boolean;
}

export const PurchasedBadge: React.FC<BadgeProps> = ({
  meta,
  isChapter,
  iconOnly,
  ...props
}) => {
  const intl = useIntl();
  return (
    <BadgeContainer data-ucn="purchasedBadge" {...props}>
      <Icon name={Icons.PURCHASE} />
      {iconOnly
        ? null
        : meta && meta.totalNum === undefined
        ? intl.formatMessage(
            isChapter
              ? globalMessages.purchasedChapters
              : globalMessages.purchasedBooks,
            {
              count: meta.purchasedNum,
            }
          )
        : meta && meta.totalNum !== 1
        ? intl.formatMessage(
            isChapter
              ? globalMessages.purchaseChapterNum
              : globalMessages.purchaseBookNum,
            {
              count: meta.purchasedNum,
              total: meta.totalNum,
            }
          )
        : intl.formatMessage(globalMessages.purchased)}
    </BadgeContainer>
  );
};
