import React, { useState } from 'react';
import styled from 'styled-components';
import { default as RatingStar } from '../../../../shared/components/Common/RatingStar';

const EvaluationContainer = styled.div`
  margin: 25px auto;
  width: 184px;
  height: 27px;
  font-size: 27px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;

  > div > div {
    margin: 8px;
  }
`;

interface Props {
  userRate: number;
  // @ts-ignore
  setEvaluation: (number) => void;
}
const UserEvaluation: React.FC<Props> = ({
  userRate,
  setEvaluation,
  ...props
}) => {
  const [newUserRate, setNewUserRate] = useState(null);

  // @ts-ignore
  const onMouseMove = (e) => {
    const { currentTarget } = e;
    const { offsetWidth } = e.currentTarget;
    const { left } = currentTarget.getBoundingClientRect();
    const relativeOffset = e.clientX - left;
    const offsetRatio = relativeOffset / offsetWidth;
    const newUserRate = Math.min(Math.floor(offsetRatio * 5) + 1, 5) * 10;
    if (newUserRate !== userRate) {
      // @ts-ignore
      setNewUserRate(newUserRate);
    }
  };

  const onMouseLeave = () => {
    setNewUserRate(null);
  };

  const onCommit = () => {
    setEvaluation(newUserRate);
  };

  const displayRate = newUserRate || userRate;
  return (
    <EvaluationContainer
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onClick={onCommit}
      {...props}
    >
      <RatingStar rate={displayRate} iconWidth={24} />
    </EvaluationContainer>
  );
};

export default UserEvaluation;
