import React from 'react';
import { FormattedMessage } from 'react-intl';
import { cosmo_getLiveDetailQuery } from '../../../__generated__/globalTypes';
import Icon, { Icons } from '../../../shared/components/Common/Icon';
import { globalMessages } from '../../../shared/constants/messages';
import {
  InfoContent,
  InfoHeading,
  InfoSection,
  InfoText,
} from '../shared/InfoSection';
import messages from '../Video/messages';

interface LiveNotesSectionProps {
  liveDetail: cosmo_getLiveDetailQuery['webfront_getLive'];
}

const LiveNotesSection: React.FC<LiveNotesSectionProps> = ({ liveDetail }) => {
  return liveDetail?.viewableDeviceText || liveDetail?.note ? (
    <InfoSection>
      {liveDetail.viewableDeviceText && (
        <InfoContent>
          <InfoHeading>
            <Icon name={Icons.CAUTION} />
            <FormattedMessage {...messages.liveCompatibleDevices} />
          </InfoHeading>
          <InfoText>{liveDetail.viewableDeviceText}</InfoText>
        </InfoContent>
      )}
      {liveDetail.note && (
        <InfoContent>
          <InfoHeading>
            <Icon name={Icons.CAUTION} />
            <FormattedMessage {...globalMessages.disclaimer} />
          </InfoHeading>
          <InfoText>{liveDetail.note}</InfoText>
        </InfoContent>
      )}
    </InfoSection>
  ) : null;
};

export default LiveNotesSection;
